import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { FontAwesome, MaterialCommunityIcons, Entypo, Octicons, FontAwesome5 } from '@expo/vector-icons'
import { CoDebtorDataRegisterV } from '../../components/CoDebtorDataRegister'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { filterKeyObject, getFileS3, hp, putFileS3, writeCache, validationErrors } from '../../validation'
import theme from '../../../ignore/Constants'
import { isLoggedVar } from '../../apollo/cache'
import { UPDATE_PAYROLL_COMPANY } from './queries'

export const CoDebtorDataRegisterC = ({ documents, dataUser, banner, handleNext, coDebtor, handleButton, refScroll, cirenio, navigation, ctCirenio }) => {
    const [values, setValues] = useState({})
    const [files, setFiles] = useState({})
    const [filePD, setFilePD] = useState('')
    const [fileFD, setFileFD] = useState('')
    const [fileRD, setFileRD] = useState('')
    const [fileSD, setFileSD] = useState('')
    const [fileBD, setFileBD] = useState('')
    const [fileLD, setFileLD] = useState('')
    const [loadPD, setLoadPD] = useState(true)
    const [loadFD, setLoadFD] = useState(true)
    const [loadRD, setLoadRD] = useState(true)
    const [loadSD, setLoadSD] = useState(true)
    const [loadBD, setLoadBD] = useState(true)
    const [loadLD, setLoadLD] = useState(true)
    const [quantity, setQuantity] = useState(0)
    const [loading, setLoading] = useState(false)
    const [errorForm, setErrorForm] = useState({})
    const [infoCamera, setInfoCamera] = useState({})
    const [payrollCompanyMutation, { loadUpd }] = useMutation(UPDATE_PAYROLL_COMPANY)

    const searchImage = async count => {
        /** actualiza todas las variables */
        setValues({ payrollCoDebtor: filterKeyObject(dataUser?.payrollCoDebtors?.[count], ['__typename']) })
        getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.payrollCoDebtors?.[count]?.pcdPerDoc}`).then(x => {
            setFilePD(x)
            setLoadPD(false)
        })
        getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.payrollCoDebtors?.[count]?.pcdFroDoc}`).then(x => {
            setFileFD(x)
            setLoadFD(false)
        })
        getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.payrollCoDebtors?.[count]?.pcdReaDoc}`).then(x => {
            setFileRD(x)
            setLoadRD(false)
        })
        getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.payrollCoDebtors?.[count]?.pcdSerDoc}`).then(x => {
            setFileSD(x)
            setLoadSD(false)
        })
        getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.payrollCoDebtors?.[count]?.pcdBanDoc}`).then(x => {
            setFileBD(x)
            setLoadBD(false)
        })
        getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.payrollCoDebtors?.[count]?.pcdLoaDoc}`).then(x => {
            setFileLD(x)
            setLoadLD(false)
        })
        setErrorForm({
            tiId: (coDebtor?.format?.cdTipIde && !dataUser?.payrollCoDebtors?.[count]?.tiId),
            pcdIdeNum: (coDebtor?.format?.cdIdeNum && !dataUser?.payrollCoDebtors?.[count]?.pcdIdeNum),
            pcdFirNam: (coDebtor?.format?.cdFirNam && !dataUser?.payrollCoDebtors?.[count]?.pcdFirNam),
            pcdFirLas: (coDebtor?.format?.cdFirLas && !dataUser?.payrollCoDebtors?.[count]?.pcdFirLas),
            pcdDatBir: (coDebtor?.format?.cdDatBir && !dataUser?.payrollCoDebtors?.[count]?.pcdDatBir),
            pcdPhoMob: (coDebtor?.format?.cdPhoMob && !dataUser?.payrollCoDebtors?.[count]?.pcdPhoMob),
            pcdPhoPer: (coDebtor?.format?.cdPhoPer && !dataUser?.payrollCoDebtors?.[count]?.pcdPhoPer),
            pcdEmail: (coDebtor?.format?.cdEmail && !dataUser?.payrollCoDebtors?.[count]?.pcdEmail),
            gId: (coDebtor?.format?.cdGender && !dataUser?.payrollCoDebtors?.[count]?.gId),
            cId: (coDebtor?.format?.cdLocation && !dataUser?.payrollCoDebtors?.[count]?.cId),
            taId: (coDebtor?.format?.cdLocation && !dataUser?.payrollCoDebtors?.[count]?.taId),
            pcdNumDir: (coDebtor?.format?.cdLocation && !dataUser?.payrollCoDebtors?.[count]?.pcdNumDir),
            pcdNum: (coDebtor?.format?.cdLocation && !dataUser?.payrollCoDebtors?.[count]?.pcdNum),
            pcdNumHou: (coDebtor?.format?.cdLocation && !dataUser?.payrollCoDebtors?.[count]?.pcdNumHou),
            alId: (coDebtor?.format?.cdAcaLev && !dataUser?.payrollCoDebtors?.[count]?.alId),
            msId: (coDebtor?.format?.cdMarSta && !dataUser?.payrollCoDebtors?.[count]?.msId),
            thId: (coDebtor?.format?.cdTypHou && !dataUser?.payrollCoDebtors?.[count]?.thId),
            dId: (coDebtor?.format?.cdDependents && !dataUser?.payrollCoDebtors?.[count]?.dId),
            pcdCompany: (coDebtor?.format?.cdCompany && !dataUser?.payrollCoDebtors?.[count]?.pcdCompany),
            pcdPosition: (coDebtor?.format?.cdPosition && !dataUser?.payrollCoDebtors?.[count]?.pcdPosition),
            tcId: (coDebtor?.format?.cdTypCon && !dataUser?.payrollCoDebtors?.[count]?.tcId),
            pcdDatSta: (coDebtor?.format?.cdTypCon && !dataUser?.payrollCoDebtors?.[count]?.pcdDatSta),
            pcdSalary: (coDebtor?.format?.cdSalary && !dataUser?.payrollCoDebtors?.[count]?.pcdSalary),
            pcdPerDoc: (coDebtor?.format?.cdPerDoc && !dataUser?.payrollCoDebtors?.[count]?.pcdPerDoc),
            pcdFroDoc: (coDebtor?.format?.cdFroDoc && !dataUser?.payrollCoDebtors?.[count]?.pcdFroDoc),
            pcdReaDoc: (coDebtor?.format?.cdReaDoc && !dataUser?.payrollCoDebtors?.[count]?.pcdReaDoc),
            pcdSerDoc: (coDebtor?.format?.cdSerDoc && !dataUser?.payrollCoDebtors?.[count]?.pcdSerDoc),
            pcdBanDoc: (coDebtor?.format?.cdBanDoc && !dataUser?.payrollCoDebtors?.[count]?.pcdBanDoc),
            pcdLoaDoc: (coDebtor?.format?.cdLoaDoc && !dataUser?.payrollCoDebtors?.[count]?.pcdLoaDoc)
        })
    }

    // Actualiza la información de un usuario
    useEffect(() => {
        searchImage(quantity)
    }, [dataUser])
    // Actualiza las fotos tomadas
    useEffect(() => {
        if (documents?.name && documents?.name !== 'pdPerDoc' && documents?.name !== 'pdFroDoc' && documents?.name !== 'pdReaDoc' && documents?.name !== 'pdSerDoc' && documents?.name !== 'pdPayRemDoc' && documents?.name !== 'pdBanDoc' && documents?.name !== 'pdConDoc' && documents?.name !== 'pdComActDoc' && documents?.name !== 'pdBudRecDoc' && documents?.name !== 'pdBudCerDoc') {
            setValues({ payrollCoDebtor: { ...values?.payrollCoDebtor, [documents.name]: `${new Date().getTime()}1.${documents.ext}` } })
            setFiles({ ...files, [documents.name]: documents.file, [documents.nameA]: documents.base64 })
            setErrorForm({ ...errorForm, [documents.name]: !documents.file })
        }
    }, [documents])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ payrollCoDebtor: { ...values.payrollCoDebtor, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
    }

    // coloca la información de la cámara
    const handleInfo = type => {
        setInfoCamera({
            title: 'Documentos personales',
            icon: type === 1 ? <FontAwesome name='vcard' size={hp(20)} color={theme.backgroundC} /> : type === 2 ? <MaterialCommunityIcons name='card-bulleted' size={hp(26)} color={theme.backgroundC} /> : type === 3 ? <Entypo name='v-card' size={hp(24)} color={theme.backgroundC} /> : type === 4 ? <Octicons name='file' size={hp(20)} color={theme.backgroundC} /> : type === 5 ? <FontAwesome5 name='file-invoice' size={hp(20)} color={theme.backgroundC} /> : <MaterialCommunityIcons name='file-cad-box' size={hp(20)} color={theme.backgroundC} />,
            steps: [
                { text: 'Sigues las instrucciones para capturar la foto.' },
                { text: '1. Capture dentro de los marcos señalados.' },
                { text: '2. La imagen debe ser visible y legible.' },
                ...[
                    type === 1 ? { text: '3. Enfoque la parte frontal de su documento de identidad .' }
                        : type === 2 ? { text: '3. Enfoque la parte reversa de su documento de identidad.' }
                            : type === 3 ? ({ text: '3. Enfoque su rostro sosteniendo el documento de identidad a la misma altura de la cabeza.' }, { text: '4. No utilizar accesorios.' })
                                : type === 4 ? { text: '3. Tome la foto de un servicio público (Agua, luz o gas).' }
                                    : type === 5 ? { text: '3. Tome la foto de un certificado bancario.' }
                                        : { text: '3. Tome la foto de un servicio público (Agua, luz o gas).' }
                ]
            ]
        })
    }

    const handleSubmit = async () => {
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        setLoading(true)
        files.pcdPerDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${values.payrollCoDebtor?.pcdPerDoc}`, files.pcdPerDoc)
        files.pcdFroDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${values.payrollCoDebtor?.pcdFroDoc}`, files.pcdFroDoc)
        files.pcdReaDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${values.payrollCoDebtor?.pcdReaDoc}`, files.pcdReaDoc)
        files.pcdSerDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${values.payrollCoDebtor?.pcdSerDoc}`, files.pcdSerDoc)
        files.pcdBanDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${values.payrollCoDebtor?.pcdBanDoc}`, files.pcdBanDoc)
        files.pcdLoaDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/codebtor/${values.payrollCoDebtor?.pcdLoaDoc}`, files.pcdLoaDoc)
        setLoading(false)

        const { data, error } = await payrollCompanyMutation({
            variables: { input: { ...values, payrollCoDebtor: { ...values?.payrollCoDebtor, pcdIdeNum: values?.payrollCoDebtor?.pcdIdeNum?.toString() } } }, update(store, { data: { setOrUpdatePayrollCompany } }) {
                writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: setOrUpdatePayrollCompany, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdatePayrollCompany) {
            // Actualización de variables
            setQuantity(quantity + 1)
            setErrorForm({})
            setValues({})
            setFiles({})
            searchImage(quantity + 1)

            // verifica si se necesitan más codeudores
            if ((quantity + 1) < coDebtor.quantity) {
                refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
                return isLoggedVar({ ...isLoggedVar(), message: `Favor ingresar la información del ${(quantity + 2)}° codeudor.` })
            }
            // termina enviá al proceso de el crédito
            if (ctCirenio === 1 && cirenio?.getCirenioParameter) {
                handleNext(8)
            } else {
                navigation.navigate('RequestCredit', { hash: null })
            }
        }
    }

    return (
        <CoDebtorDataRegisterV
            data={{ files, values, loading: (loading || loadUpd), infoCamera, banner, format: coDebtor?.format, fileBD, fileFD, fileLD, filePD, fileRD, fileSD, loadSD, loadRD, loadPD, loadLD, loadFD, loadBD }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleInfo={handleInfo}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleButton={handleButton}
        />
    )
}

CoDebtorDataRegisterC.propTypes = {
    documents: PropTypes.object,
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    coDebtor: PropTypes.object,
    cirenio: PropTypes.object,
    ctCirenio: PropTypes.object,
    handleButton: PropTypes.func,
    refScroll: PropTypes.object,
    format: PropTypes.object,
    navigation: PropTypes.object,
}