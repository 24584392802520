import { useMutation, useLazyQuery } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components, Utils, Queries
import { InformationAdditionalCreditSarlaftV } from '../../components/InformationAdditionalCreditSarlaft'
import { EDIT_CREDIT_PAYROLL, EDIT_CREDIT, EDIT_PAYROLL, ONE_CREDIT_PAYROLL_COMPANY, ONE_CREDIT_PAYROLL_FORMAT } from './queries'
import { filterKeyObject, validationErrors } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'

export const InformationAdditionalCreditSarlaftC = ({ navigation, route }) => {
    const [errorFormWork, setErrorFormWork] = useState({})
    const [valuesWork, setValuesWork] = useState({})
    const [payroll, setPayroll] = useState({})
    const [format, setFormat] = useState({})

    // Queries, Mutation
    const [getOneCreditPayrollCompany, { data: dataCPC, error: errCPC, called: calCPC, loading: loadCPC }] = useLazyQuery(ONE_CREDIT_PAYROLL_COMPANY, { fetchPolicy: 'cache-and-network' })
    const [getOneCreditPayrollFormat, { data: dataCPF, loading: loadCPF, called: calCPF, error: errCPF }] = useLazyQuery(ONE_CREDIT_PAYROLL_FORMAT, { fetchPolicy: 'cache-and-network' })
    const [editCreditPayrollInfo, { loading: loadMutation }] = useMutation(EDIT_CREDIT_PAYROLL)
    const [editPayrollInfo, { loading: loadMutationPayroll }] = useMutation(EDIT_PAYROLL)
    const [updateCredit, { loading: loadMutationCredit }] = useMutation(EDIT_CREDIT)

    // Activa un evento para buscar el perfil del usuario
    useEffect(() => {
        // crea un evento para consultar el perfil del usuario
        const unsubscribe = navigation.addListener('focus', () => {
            getOneCreditPayrollCompany({ variables: { cId: route?.params?.cId, state: [0, 1, 2, 3] } })
            getOneCreditPayrollFormat({ variables: { cId: route?.params?.cId } })
        })

        // elimina el evento
        return unsubscribe
    }, [navigation, getOneCreditPayrollCompany])

    // Obtiene la información de formato de usuario
    useEffect(() => {
        const res = dataCPF?.getCreditPayrollFormatById
        if (res && calCPF) setFormat(res)

        // verifica el error
        if (errCPF) {
            setFormat({})
            isLoggedVar({ ...isLoggedVar(), message: 'No se ha encontrado ningún formato disponible del crédito.', code: 404 })
        }
    }, [dataCPF, calCPF, errCPF])

    // Obtiene la información del usuario
    useEffect(() => {
        const res = dataCPC?.getCreditPayrollCompanyById
        if (res && calCPC && format?.cpfId) {
            setPayroll(res)
            setValuesWork(res.creditPayrollWork || {})
            setErrorFormWork({
                cpwActive: (format.cpfActive && (!res.creditPayrollWork?.cpwActive && res.creditPayrollWork?.cpwActive !== 0)),
                cpwPassive: (format.cpfPassive && (!res.creditPayrollWork?.cpwPassive && res.creditPayrollWork?.cpwPassive !== 0)),
                cpwHolPubOff: (format.cpfHolPubOff && !res.creditPayrollWork?.cpwHolPubOff),
                cpwHasPubRec: (format.cpfHasPubRec && !res.creditPayrollWork?.cpwHasPubRec),
                cpwHasRelPubExp: (format.cpfHasRelPubExp && !res.creditPayrollWork?.cpwHasRelPubExp),
                cpwCIIU: (format.cpfCIIU && !res.creditPayrollWork?.cpwCIIU),
                cpwManPubRes: (format.cpfManPubRes && !res.creditPayrollWork?.cpwManPubRes),
            })
        }

        // verifica el error
        if (errCPC) {
            setValuesWork({})
            setErrorFormWork({})
            isLoggedVar({ ...isLoggedVar(), message: 'No se ha encontrado la información del cliente.', code: 404 })
        }
    }, [dataCPC, calCPC, errCPC, format])

    /**
     * Evento de los select o input
     * @param {*} e Valores del evento
     * @return {void}
     */
    const handleChange = e => {
        setValuesWork({ ...valuesWork, [e.name]: e.value })
        setErrorFormWork({ ...errorFormWork, [e.name]: e.error })
    }

    /**
     * Registra o actualiza la información de sarlaft
     * @return {void}
     */
    const handleSubmit = async () => {
        if (loadCPC || loadCPF || loadMutation || loadMutationCredit || loadMutationPayroll) return isLoggedVar({ ...isLoggedVar(), message: 'Espere por favor.' })
        /** verifica si los campos están rellenos */
        if (validationErrors(errorFormWork)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        // Actualiza La información personal y la del seguro de vida del credito
        const { error: errCPI } = await editCreditPayrollInfo({
            variables: {
                cpcId: payroll.cpcId,
                input: filterKeyObject(valuesWork, ['__typename']),
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (errCPI) return isLoggedVar({ ...isLoggedVar(), message: errCPI.message })

        // Actualiza la información personal y la del seguro de vida del cliente
        await editPayrollInfo({
            variables: {
                pcId: payroll.pcId,
                input: {
                    pwActive: valuesWork.cpwActive,
                    pwPassive: valuesWork.cpwPassive,
                    pwHolPubOff: valuesWork.cpwHolPubOff,
                    pwHasPubRec: valuesWork.cpwHasPubRec,
                    pwHasRelPubExp: valuesWork.cpwHasRelPubExp,
                    pwCIIU: valuesWork.cpwCIIU,
                    pwManPubRes: valuesWork.cpwManPubRes,
                }
            }
        }).catch(e => ({ error: e }))

        // Actualiza el crédito informando que ya se actualizo
        await updateCredit({
            variables: {
                cId: route?.params?.cId,
                input: { cAddInf: 1 }
            }
        }).catch(e => ({ error: e }))

        /** resultado */
        navigation.navigate('Home')
        isLoggedVar({ ...isLoggedVar(), message: 'Se ha agregado la información.' })
    }

    return (
        <InformationAdditionalCreditSarlaftV
            data={{
                format,
                payroll,
                valuesWork
            }}
            loadings={{
                loadCPC,
                loadCPF,
                loadMutation: loadMutationPayroll || loadMutation || loadMutationCredit,
            }}
            errors={{
                errorFormWork
            }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

InformationAdditionalCreditSarlaftC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}