export const TerCon = () => [
    {
        id: 1,
        title: 'GM SERVICIOS FINANCIEROS',
        message: 'Identificada con el NIT 901479076-4; manifiesta, a través de este documento, su POLÍTICA DE MANEJO DE LA INFORMACIÓN Y PROTECCIÓN DE DATOS PERSONALES, adoptada en cumplimiento del régimen legal vigente contenido y desarrollado a partir de lo dispuesto en la Ley 79 de 1993, la Ley 1266 de 2008 y la Ley Estatutaria 1581 de 2012, reglamentada parcialmente por el Decreto 1377 de 2013, en lo que corresponde.'
    },
    {
        id: 2,
        title: '1. Definiciones',
        message: 'El dato personal es cualquier información vinculada o que pueda asociarse a una o varias personas naturales o jurídicas determinadas o determinables como el nombre, la edad, el domicilio, entre otros. Estos datos pueden almacenarse en cualquier soporte físico o electrónico y ser tratados de forma manual o automatizada.'
    },
    {
        id: 3,
        message: 'La Ley 1266 de 2008 y la ley 1581 de 2012 reglamentada por el decreto 1377 del 2013, el cual ha sido incorporado en el Decreto único Nacional del Sector Comercio, Industria y Turismo en el capítulo 25 (artículos 2.2.2.25.1.1 a 2.2.2.26.3.4) define los siguientes conceptos:'
    },
    {
        id: 4,
        title: '1.1. Autorización:',
        message: 'Es el consentimiento previo, expreso e informado del Titular para llevar a cabo el tratamiento de datos personales.'
    },
    {
        id: 5,
        title: '1.2. Autorizado:',
        message: 'Corresponde a la entidad pública o privada y personas bajo la responsabilidad de dicha entidad, que de acuerdo a la autorización dada por el Titular del dato y conforme a estas políticas, permiten el tratamiento de los datos personales recolectados, recibidos o entregados por medio de su autorización.'
    },
    {
        id: 6,
        title: '1.3. Base de datos:',
        message: 'Se refiere al conjunto organizado de datos personales que sea objeto de tratamiento por parte de una persona natural o jurídica, sin importar la forma de acceso, almacenamiento y organización.'
    },
    {
        id: 7,
        title: '1.4. Dato privado:',
        message: 'Es el dato que por su naturaleza íntima o reservada sólo es relevante para el Titular.'
    },
    {
        id: 8,
        title: '1.5. Dato semiprivado:',
        message: 'Es semiprivado el dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interésar no sólo a su Titular, sino a cierto sector o grupo de personas o a la sociedad en general.'
    },
    {
        id: 9,
        title: '1.6. Dato personal:',
        message: 'Cualquier información de cualquier tipo, vinculada o que puede asociarse a una o varias personas naturales o jurídicas determinadas o determinables.'
    },
    {
        id: 10,
        title: '1.7. Dato sensible:',
        message: 'Se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, organizaciones de derechos humanos o que promueva interéses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición, así como los datos relativos a la salud, a la vida sexual y los datos biométricos.'
    },
    {
        id: 11,
        title: '2.6 Principio de acceso y circulación restringida:',
        message: 'Los datos personales sólo pueden ser tratados por el personal de GM SERVICIOS FINANCIEROS que esté autorizado para ello, o quienes dentro de sus funciones tengan a cargo la realización de este tipo de actividades. En este sentido el tratamiento se sujeta a los límites que se deriven de la naturaleza de los datos personales. No podrán entregarse datos personales a quienes no cuenten con autorización para llevar a cabo su Tratamiento.'
    },
    {
        id: 12,
        message: 'El Titular directamente o a través de las personas debidamente autorizadas, podrá consultar sus datos personales en todo momento y especialmente, cada vez que existan modificaciones en las políticas de tratamiento de la información.'
    },
    {
        id: 13,
        title: '2.7. Principio de seguridad:',
        message: 'La información sujeta a Tratamiento por el Responsable o Encargado, será manejada con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros, evitando su adulteración, pérdida, consulta, uso o acceso no autorizado.'
    },
    {
        id: 14,
        title: '2.8. Principio de confidencialidad:',
        message: 'GM SERVICIOS FINANCIEROS deberá realizar el tratamiento usando las medidas técnicas, administrativas y humanas necesarias para mantener la confidencialidad del dato y para evitar que este pueda ser modificado, alterado, consultado, usado, eliminado o conocido por personas que no tienen autorización o que teniendo autorización, lo usen con fines no contemplados en la Ley y la presente Política.'
    },
    {
        id: 15,
        message: 'Todo dato que no sea dato público, debe tratarse por los responsables y encargado de su tratamiento como confidencial, aún cuando la relación entre el Titular del dato y GM SERVICIOS FINANCIEROS haya terminado.'
    },
    {
        id: 16,
        title: '2.9. Restricción del acceso:',
        message: 'GM SERVICIOS FINANCIEROS no hará disponibles datos personales para su acceso a través de Internet u otros medios masivos de comunicación, a menos que se trate de información pública o que se establezcan medidas técnicas que permitan controlar el acceso y restringirlo solo a las personas autorizadas por Ley o por el Titular.'
    },
    {
        id: 17,
        title: '2.10. Restricción de transferencia:',
        message: 'GM SERVICIOS FINANCIEROS no realizará transferencia de información relacionada con datos personales a países que no cuenten con los niveles adecuados de protección de datos, de acuerdo con los estándares que estén fijados en la misma Superintendencia de Industria y Comercio.'
    },
    {
        id: 18,
        title: '2.11. Confidencialidad y tratamiento posterior:',
        message: 'Cuando finalice alguna de las labores de tratamiento de datos personales por los servidores, contratistas o encargados del tratamiento, y aún después de finalizado su vínculo o relación contractual con GM SERVICIOS FINANCIEROS, éstos están obligados a mantener la reserva de la información de acuerdo con la normatividad vigente en la materia.'
    },
    {
        id: 19,
        message: 'GM SERVICIOS FINANCIEROS divulgará en estas Políticas, sus servidores, contratistas y terceros encargados del tratamiento y las obligaciones que tienen estos en relación con el tratamiento de datos personales.'
    },
    {
        id: 20,
        title: '2.12. Principio de individualidad:',
        message: 'GM SERVICIOS FINANCIEROS  mantendrá de manera separada las bases de datos que recopila en las actividades propias de sus negocios, frente a las registradas por medio del uso de las APPs.'
    },
    {
        id: 21,
        title: '2.13. Principio de temporalidad:',
        message: 'La información del titular solamente puede ser Tratada durante el tiempo y en la medida que el propósito de su Tratamiento lo justifique, lo anterior en concordancia con la duración de las actividades propias de sus negocios y la Actividad de las APPs.'
    },
    {
        id: 22,
        title: '2.14. Oportunidad:',
        message: 'Cuando a GM SERVICIOS FINANCIEROS le sea solicitada información, ya sea mediante una petición, consulta o reclamo por parte del Titular, sobre la manera cómo son utilizados sus datos personales, deberá entregar dicha información, previa verificación de los requisitos legales en los términos establecidos en la Ley.'
    },
    {
        id: 23,
        title: '3. Tratamiento y finalidades',
        message: '3.1. GM SERVICIOS FINANCIEROS de acuerdo con la Ley por medio de sus APPs y en desarrollo de su objeto social, es responsable del tratamiento de datos personales que contiene en sus bases de datos, los cuales son obtenidos mediante el registro que los usuarios realizan al momento acceder al uso de las APPs (G&M App y G&M App ); a la página web info@gmserviciosfinancieros.com.co; suscripción de cualquier contrato con GM SERVICIOS FINANCIEROS o al hacer solicitudes o registros en la página web www.G&M.com.co. En ese sentido, GM SERVICIOS FINANCIEROS , los encargados y terceros que tengan acceso a los datos personales conforme a las autorizaciones dadas por el Titular o por virtud de la Ley, dispondrán del tratamiento con el principal objetivo de prestar un mejor servicio en sus plataformas, APPs y en el desarrollo de su objeto social.'
    },
    {
        id: 24,
        message: 'Adicionalmente GM SERVICIOS FINANCIEROS podrá hacer el tratamiento de los datos de los Titulares que se han recolectado para las siguientes finalidades:'
    },
    {
        id: 25,
        message: '3.1.1 Gestionar, catalogar, ordenar, clasificar, separar y almacenar la información obtenida de los Titulares necesaria para el desarrollo operativo y administrativo de su objeto social, las APPs y páginas web asociadas a GM SERVICIOS FINANCIEROS , como info@gmserviciosfinancieros.com.co y www.G&M.com.co; administración, creación y eliminación de cuentas de usuario, así como realizar el soporte, mantenimiento, actualización, desarrollo y control de las cuentas de usuarios de las Plataformas y APPs.'
    },
    {
        id: 26,
        message: '3.1.1.1 En la APP G&M App adicional a lo anterior, será objeto de tratamiento de datos la información relacionada a la ubicación y dirección de los datos personales ofrecidos por los usuarios de la APPs, así como la información relacionada con su vida crediticia que el usuario desea ofrecer, tales como: nombre, teléfono, dirección, estado civil, referencias personales y faG&Mares etc. El Usuario que ofrece su información en la APP, es quien decide cuándo anunciar a la APPs G&M App y G&M App inversionista, este tipo de información solicitando la publicación de esta por medio de su cuenta de usuario.'
    },
    {
        id: 27,
        message: '3.1.1.2. En la APP G&M App adicional a la información anterior, será objeto de tratamiento la información relacionada con los datos inscritos por parte de los usuarios, la cual podrá ser: tipo de personal, entre otras. Este tipo de información estará a disposición de GM SERVICIOS FINANCIEROS como responsable del tratamiento y solo de usuarios registrados en la APP G&M App con quienes previamente el titular de la información a solicitado una reserva y recibido una confirmación del servicio. Todo lo anterior, con el fin de que el usuario receptor de dicha información identifique la persona que va a ser uso de los servicios ofrecidos por éste, conforme a lo indicado en su publicación o anuncio.'
    },
    {
        id: 28,
        message: '3.1.1.3 Las APPs reciben información de sus usuarios referente a las actividades y servicios que han realizado o adquirido dentro de esta, la cual queda recopilada en la base de datos de las APPs o en la metadata de las mismas. Este tipo de información comprenderá aspectos como solicitud de créditos, servicios utilizados dentro de la APPs, tiempos de uso de los servicios, PQR´s realizadas y en general información relacionada a la actividad que el usuario realiza en las APPs.'
    },
    {
        id: 29,
        message: '3.1.1.4. Las APPs no recopilan ni realizan tratamiento de la información relacionada con tarjeta de crédito, con las cuales los usuarios realizan el pago de los servicios dentro de la plataforma. Esta información será recopilada directamente con la plataforma de la pasarela de pagos PlaceToPay www.G&M.com.co, la cual únicamente remitirá la constancia de los pagos efectuados a GM SERVICIOS FINANCIEROS . Todas las medidas de seguridad y tratamiento de dicha información se regirá por la políticas estipuladas en dicha pasarela de pagos y en las que contemple la Ley.'
    },
    {
        id: 30,
        message: '3.1.2. Desarrollar actividades de verificaciones de los usuarios registrados en las APPs y utilizar los datos personales recolectados y autorizados por los Titulares de dichas aplicaciones, con fines de comercialización, mercadeo, conocimiento de nuevos servicios, ofertas y publicidades de estas APPs o de terceros con quienes GM SERVICIOS FINANCIEROS tenga convenios. Este tipo de actividades se realizarán por medios como correos electrónicos, notificaciones vía SMS y ofertas de servicios puestas a disposición en la página web de cada una de las plataformas y aplicaciones móviles.'
    },
    {
        id: 31,
        message: '3.1.3. Ofrecer recomendaciones de servicios dentro de las plataformas y aplicaciones móviles, de acuerdo a las necesidades específicas de los usuarios de estas.'
    },
    {
        id: 32,
        message: '3.1.4 Efectuar encuestas de satisfacción de los servicios ofrecidos.'
    },
    {
        id: 33,
        message: '3.1.5 Realizar estrategias de mercado mediante el estudio del comportamiento de los usuarios respecto de los servicios ofrecidos en las plataformas y en las aplicaciones móviles.'
    },
    {
        id: 34,
        message: '3.1.6. Cumplir con los procesos operativos y administrativos internos para el mejoramiento de la plataforma, de las aplicaciones móviles y actualización de los términos y condiciones de uso.'
    },
    {
        id: 35,
        message: '3.1.7.Realizar procesos al interior de GM SERVICIOS FINANCIEROS con fines de desarrollo administrativo y operativo.'
    },
    {
        id: 36,
        message: '3.1.8. En los procesos de archivo, registro histórico, actualización, administración y custodia de la información contenida en las plataformas, páginas web, aplicaciones móviles y archivos digitales y físicos que tenga GM SERVICIOS FINANCIEROS.'
    },
    {
        id: 37,
        message: '3.1.9. La transmisión de datos a terceros con los cuales se hayan celebrado contratos con dicho fin, para el desarrollo de actividades comerciales, publicitarias, de mercadeo, operativas y administrativas de las APPs conforme a las leyes vigentes.'
    },
    {
        id: 38,
        message: '3.1.10. Mantener y procesar por medios electrónicos u otros, cualquier tipo de información realizada con las actividades de los usuarios registrados en el desarrollo y uso de las aplicaciones móviles, plataformas y páginas web de las cuales GM SERVICIOS FINANCIEROS sea responsable, con el fin de brindar los servicios, nuevos servicios y productos pertinentes en relación con las actividades desarrolladas en estas aplicaciones móviles y plataformas.'
    },
    {
        id: 39,
        message: '3.1.11. Coordinar y tramitar la atención y respuestas a peticiones, quejas, reclamos, relacionadas con la ley de protección de datos personales. Igualmente para coordinar, tramitar la atención y respuestas, quejas, reclamos, de los usuarios, trabajadores, contratistas, proveedores y demás personas de las cuales se haya recolectado datos personales por los canales indicados en la presente política de tratamiento de datos personales.'
    },
    {
        id: 40,
        message: '3.1.12. Presentar los requerimientos solicitados por las autoridades de inspección, vigilancia y control, así como demás entidades administrativas o judiciales.'
    },
    {
        id: 41,
        message: '3.1.13. Las demás finalidades que determinen GM SERVICIOS FINANCIEROS y Responsables en proceso de obtención de datos personales para su tratamiento y que sean comunicados a los Titulares en el momento de la recolección de los datos personales.'
    },
    {
        id: 42,
        message: '3.1.14. Las demás finalidades que determinen los Responsables en procesos de obtención de datos personales para su Tratamiento, con el fin de cumplir obligaciones legales, judiciales, regulatorias, así como de las Políticas de GM SERVICIOS FINANCIEROS y de las APPs.'
    },
    {
        id: 43,
        message: '3.1.15. Para la transferencia o transmisión de datos nacional o internacionalmente a proveedores, contratistas y empresas aliadas con los que GM SERVICIOS FINANCIEROS desarrolle actividades en cumplimiento de su objeto social. Lo anterior conforme con las disposiciones de la normativa colombiana.'
    },
    {
        id: 44,
        message: '3.2. GM SERVICIOS FINANCIEROS es Responsable del tratamiento de datos personales que contiene en sus bases de datos y que ha obtenido por parte de sus empleados, candidatos a empleo, contratistas, proveedores, y demás empresas aliadas, quienes han suscrito cualquier contrato laboral, de servicios, convenios, solicitudes para una convocatoria laboral o de servicios, entre otros. Dicha información tendrá las siguientes finalidades:'
    },
    {
        id: 45,
        message: '3.2.1. Cumplir con deberes legales a los que esté o pueda estar obligado GM SERVICIOS FINANCIEROS , así como requerimientos de las entidades de inspección, vigilancia y control, administrativas y judiciales.'
    },
    {
        id: 46,
        message: '3.2.2 Verificar y obtener información de las referencias personales, laborales y comerciales, así como comparar y evaluar portafolios de servicios y hojas de vida.'
    },
    {
        id: 47,
        message: '3.2.3 Realizar la gestión de recursos humanos, en lo relacionado con prestaciones sociales y económicas, riesgos laborales, selección de personal, control de horario, elaboración de certificaciones laborales, de ingreso y salida, de retenciones y en general cualquier asunto relacionado con la planta de personal.'
    },
    {
        id: 48,
        message: '3.2.4 Realizar las solicitudes de información sobre cuentas bancarias, depósitos, gestión de fondos pensionales para el cumplimiento de las obligaciones que GM SERVICIOS FINANCIEROS tenga como empleador respecto a su planta de personal y a los contratistas y proveedores de servicios.'
    },
    {
        id: 49,
        message: '3.2.5. Almacenar, ordenar, clasificar y archivar los datos personales de las personas con quienes GM SERVICIOS FINANCIEROS ha suscrito un contrato o realizado un proceso de selección.'
    },
    {
        id: 50,
        message: '3.2.6 Realizar declaraciones tributarias o gestión de información tributaria.'
    },
    {
        id: 51,
        message: '3.2.7. Mantener un registro de la información de proveedores, contratistas y planta de personal.'
    },
    {
        id: 52,
        message: '3.2.8 Realizar la gestión de cobros, pagos con proveedores, contratistas y planta de personal.'
    },
    {
        id: 53,
        message: '3.2.9. Para la transferencia o transmisión de datos a nivel nacional o internacional cuando sea procedente, para los fines relacionados con el objeto social, la operación de sus actividades y/o en cumplimiento de requisitos legales de GM SERVICIOS FINANCIEROS , conforme a los requisitos establecidos en la normatividad colombiana.'
    },
    {
        id: 54,
        title: '4. Derechos del titular del dato',
        message: 'La Ley 1581 de 2012 establece que los Titulares de los datos personales tendrán los siguientes derechos:'
    },
    {
        id: 55,
        message: '4.1. Conocer, actualizar y rectificar sus datos personales frente a los Responsables o Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros, frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.'
    },
    {
        id: 56,
        message: '4.2. Solicitar prueba de la autorización otorgada al Responsable del Tratamiento, salvo cuando expresamente se exceptúa como requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de la citada ley.'
    },
    {
        id: 57,
        message: '4.3. Ser informado por el Responsable o el Encargado del Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos personales.'
    },
    {
        id: 58,
        message: '4.4. Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la citada ley y las demás normas que la modifiquen, adicionen o complementen.'
    },
    {
        id: 59,
        message: '4.5. Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento, el Responsable o Encargado han incurrido en conductas contrarias a la ley y a la Constitución.'
    },
    {
        id: 60,
        message: '4.6. Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento. Adicionalmente, el Decreto reglamentario 1377 de 2013 define que los Responsables deberán conservar prueba de la autorización otorgada por los Titulares de datos personales para el tratamiento de los mismos.'
    },
    {
        id: 61,
        title: '5. Forma de recolectar los datos de los titulares'
    },
    {
        id: 62,
        message: '5.1. La recolección de datos personales de usuarios inscritos en las plataformas y aplicaciones móviles bajo el manejo, control y/o administración de GM SERVICIOS FINANCIEROS , así como del personal de trabajo, contratistas, clientes directos, y demás personas, se realizará de la siguiente manera:'
    },
    {
        id: 63,
        message: '5.1.1 Mediante almacenamiento automático de los datos de los usuarios que se registran y/o acceden a la plataforma por medio de las APPs G&M App y G&M App o en el uso de la página web www. G&M.com.co, por el uso de cookies, tales como URL´s, navegador usado y dirección de IP.'
    },
    {
        id: 64,
        message: '5.1.2. En el uso y acceso a páginas web de GM SERVICIOS FINANCIEROS.'
    },
    {
        id: 65,
        message: '5.1.3. En el registro y uso de las APPs G&M App y G&M App al crear cuenta de usuario y registro de contraseña o clave de acceso.'
    },
    {
        id: 66,
        message: '5.1.4. En el envío e intercambio de correos electrónicos.'
    },
    {
        id: 67,
        message: '5.1.5. Mediante la transmisión o transferencia por parte de terceros, con los que GM SERVICIOS FINANCIEROS tenga convenios y/o alianzas.'
    },
    {
        id: 68,
        message: '5.2. La recolección de datos personales de empleados se puede ejercer de las siguientes maneras:'
    },
    {
        id: 69,
        message: '5.2.1. Por medio de los contratos de trabajo que GM SERVICIOS FINANCIEROS suscriba con dicho personal, así como los correspondientes formularios de inscripción en seguridad social, en salud, pensiones y riesgos laborales.'
    },
    {
        id: 70,
        message: '5.2.2. En el proceso de recolección de datos de personal interésado en trabajar o prestar servicios para GM SERVICIOS FINANCIEROS , tales como hojas de vida, solicitudes de empleo, convocatorias, entrevistas, entre otros. Esta información podrá ser obtenida directamente o por intermedio de empresas que tengan la facultad de proveer personal de trabajo o de servicios.'
    },
    {
        id: 71,
        message: '5.3. Recolección de datos personales de proveedores de bienes y servicios, así como de contratistas de servicios, se puede realizar de las siguientes maneras:'
    },
    {
        id: 72,
        message: '5.3.1. Por medio de ofertas de servicios, portafolios de servicios, suscripción de contratos de servicios, contratos de cooperación, convenios, alianzas, suministro, entre otros.'
    },
    {
        id: 73,
        message: '5.3.2 Por el intercambio de correo electrónicos, reuniones o comunicaciones telefónicas.'
    },
    {
        id: 74,
        title: '6. Encargado de protección de datos personales',
        message: 'GM SERVICIOS FINANCIEROS ha designado esta actividad a la dependencia denominada Atención al Usuario, como responsable de la recepción y atención de peticiones, quejas, reclamos y consultas de cualquier índole que esté relacionado con los datos personales. La persona encargada de la Atención al Usuario tramitará las consultas, peticiones y reclamaciones relacionadas con datos personales de conformidad con la Ley y estas políticas.'
    },
    {
        id: 75,
        message: 'Para efectos de determinar algunas funciones se exponen las siguientes:'
    },
    {
        id: 76,
        message: '6.1. Recibir las solicitudes de los Titulares de datos personales, tramitar y responder aquellas peticiones que tengan fundamento en la Ley o en estas políticas, tales como actualización, corrección o supresión de datos personales, solicitudes de información que versen sobre el uso dado a sus datos personales, solicitudes de prueba de la autorización otorgada, cuando ella hubiere procedido según la Ley.'
    },
    {
        id: 77,
        message: '6.2. Dar respuesta a los Titulares de los datos personales sobre aquellas peticiones que no procedan de acuerdo con la Ley.',
    },
    {
        id: 78,
        message: '6.3. Los datos de contacto de Atención al Usuario son: Dirección electrónica: info@gmserviciosfinancieros.com.co; Área Responsable: Atención al Usuario; Link: info@gmserviciosfinancieros.com.co para temas relacionados con datos recolectados por medio de las APPs G&M App y G&M App.'
    },
    {
        id: 79,
        message: '6.4. Para asuntos relacionados con datos recolectados por otras plataformas o en desarrollo de las actividades propias de GM SERVICIOS FINANCIEROS , los datos de Atención al Usuario son: Dirección electrónica: info@gmserviciosfinancieros.com.co; Área Responsable: Atención al usuario.'
    },
    {
        id: 80,
        title: '7. Procedimiento'
    },
    {
        id: 81,
        message: '7.1. Solicitudes. GM SERVICIOS FINANCIEROS dispondrá de mecanismos para que el Titular, sus representantes, apoderados, causahabientes o por estipulación a favor de otro o para otro, formulen consultas respecto de cuáles son los datos personales del Titular que reposan en las bases de datos de las plataformas y de las APPs.'
    },
    {
        id: 82,
        message: '7.1.1. Podrán realizar solicitudes y formular consultas conforme con la Ley, las personas que sean Titulares del dato, sus representantes, apoderados, causahabientes o por estipulación a favor de otro o para otro. GM SERVICIOS FINANCIEROS una vez verificada la calidad del solicitante conforme a la Ley, revisará y dará contestación en los mismos términos.'
    },
    {
        id: 83,
        message: '7.1.2. El Responsable de atender la solicitud dará respuesta dentro de los diez (10) días hábiles contados a partir de la fecha en la que la solicitud fue recibida y se remitirá por el mismo medio electrónico.'
    },
    {
        id: 84,
        message: '7.1.3. Si la solicitud no puede ser atendida por falta de información en la misma o esta es confusa, borrosa, u por otra razón, se contactará al solicitante para comunicarle los motivos por los cuales la solicitud se encuentra en trámite o para que la aclare. Para ello, se utilizará el mismo medio o uno similar que haya usado por el solicitante al comunicar la solicitud a GM SERVICIOS FINANCIEROS.'
    },
    {
        id: 85,
        message: '7.1.4. La respuesta final a la solicitud sea positiva o negativa no tardará más de quince (15) días hábiles desde la fecha en que la misma fue recibida al correo electrónico info@gmserviciosfinancieros.com.co o info@gmserviciosfinancieros.com.co.'
    },
    {
        id: 86,
        message: '7.1.5. Toda solicitud relacionada con datos personales que GM SERVICIOS FINANCIEROS haya obtenido en el uso de las APPs, tendrá que ser tramitada al correo electrónico info@gmserviciosfinancieros.com.co. De igual manera toda solicitud relacionada con datos personales que GM SERVICIOS FINANCIEROS haya obtenido en el uso de otras plataformas y en el desarrollo de sus actividades propias, tendrá que ser tramitada al correo electrónico info@gmserviciosfinancieros.com.co. Solicitudes enviadas a otras direcciónes electrónicas o físicas, se entenderán por no enviadas o no puestas debidamente en conocimiento a GM SERVICIOS FINANCIEROS.'
    },
    {
        id: 87,
        message: '7.2. RECLAMOS. GM SERVICIOS FINANCIEROS dispone de medios electrónicos para que el Titular, sus representantes, apoderados, causahabientes o por estipulación a favor de otro o para otro, formulen reclamos respecto de datos personales tratados por GM SERVICIOS FINANCIEROS que deban ser objeto de corrección, actualización o supresión, o respecto al presunto incumplimiento de las disposiciones legales por parte de esta. El procedimiento que a continuación se indica está en concordancia con la Ley 1582 de 2012, artículo 15 en los siguientes términos.'
    },
    {
        id: 88,
        message: '7.2.1 El único mecanismo disponible por GM SERVICIOS FINANCIEROS será el correo electrónico info@gmserviciosfinancieros.com.co y info@gmserviciosfinancieros.com.co. El reclamo deberá ser representado por el Titular, sus representantes, apoderados acreditados, sus causahabientes o quienes tengan dicha facultad conforme a los requisitos contemplados en la ley 1581 de 2012 y el decreto 1377 de 2012 y demás normas concordantes. Dicha solicitud deberá contener como mínimo lo siguiente: i) deberá dirigirse a GM SERVICIOS FINANCIEROS por vía electrónica a la dirección info@gmserviciosfinancieros.com.co o info@gmserviciosfinancieros.com.co; ii) deberá indicar el nombre y documento de identificación del Titular; iii) contendrá una descripción de los hechos que den lugar al reclamo y la finalidad del mismo, como actualización, corrección, supresión del dato o cumplimiento de disposiciones o deberes legales o de la política de tratamiento de datos; iv) deberá indicar la dirección y datos de contacto e identificación del reclamante; v) acompañará toda la documentación que el reclamante quiera hacer valer.'
    },
    {
        id: 89,
        message: '7.2.2. GM SERVICIOS FINANCIEROS antes de atender el reclamo, verificará la identidad del Titular del dato personal, su apoderado, representante o causahabiente o de la estipulación a favor de otro o para otro, así como la documentación de identificación del titular y los poderes o autorizaciones específicos que sean exigibles para cada caso.'
    },
    {
        id: 90,
        message: '7.2.3. Si el reclamo presentado o la documentación adicional están incompletos, GM SERVICIOS FINANCIEROS requerirá al reclamante por una sola vez, dentro de los cinco (5) días hábiles siguientes a la recepción del reclamo para que corrija las fallas. Si el reclamante no presenta la documentación e información requerida dentro de los dos (2) meses siguientes a la fecha del reclamo inicial, se entenderá que se desistió del reclamo.'
    },
    {
        id: 91,
        message: '7.2.4. En caso de que quien reciba el reclamo no sea competente para resolverlo, se dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interésado.'
    },
    {
        id: 92,
        message: '7.2.5. Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga “reclamo en trámite” y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.'
    },
    {
        id: 93,
        message: '7.2.6. El tiempo máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando por algún motivo no fuera posible atender el reclamo en ese término, se informará al interésado los motivos de la tardanza y la fecha en que se atenderá el reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.'
    },
    {
        id: 94,
        title: '8. Autorización del titular'
    },
    {
        id: 95,
        message: '6.4.Para asuntos relacionados con datos recolectados por otras plataformas o en desarrollo de las actividades propias de GM SERVICIOS FINANCIEROS , los datos de Atención al Usuario son: Dirección electrónica: info@bancaliv.com; Área Responsable: Atención al usuario.'
    },
    {
        id: 96,
        title: '7. Procedimiento',
    },
    {
        id: 97,
        message: '7.1. Solicitudes. GM SERVICIOS FINANCIEROS  dispondrá de mecanismos para que el Titular, sus representantes, apoderados, causahabientes o por estipulación a favor de otro o para otro, formulen consultas respecto de cuáles son los datos personales del Titular que reposan en las bases de datos de las plataformas y de las APPs.'
    },
    {
        id: 98,
        message: 'Estos mecanismos serán electrónicos, por medio del correo de atención al usuario info@bancaliv.com y info@bancaliv.com, en donde se recibirán las peticiones, quejas y reclamos. GM SERVICIOS FINANCIEROS  guardará prueba de las solicitudes tramitadas por este medio.'
    },
    {
        id: 99,
        message: '7.1.1. Podrán realizar solicitudes y formular consultas conforme con la Ley, las personas que sean Titulares del dato, sus representantes, apoderados, causahabientes o por estipulación a favor de otro o para otro. GM SERVICIOS FINANCIEROS  una vez verificada la calidad del solicitante conforme a la Ley, revisará y dará contestación en los mismos términos.'
    },
    {
        id: 100,
        message: '7.1.2. El Responsable de atender la solicitud dará respuesta dentro de los diez (10) días hábiles contados a partir de la fecha en la que la solicitud fue recibida y se remitirá por el mismo medio electrónico.'
    },
    {
        id: 101,
        message: '7.1.3. Si la solicitud no puede ser atendida por falta de información en la misma o esta es confusa, borrosa, u por otra razón, se contactará al solicitante para comunicarle los motivos por los cuales la solicitud se encuentra en trámite o para que la aclare. Para ello, se utilizará el mismo medio o uno similar que haya usado por el solicitante al comunicar la solicitud a GM SERVICIOS FINANCIEROS.'
    },
    {
        id: 102,
        message: '7.1.4. La respuesta final a la solicitud sea positiva o negativa no tardará más de quince (15) días hábiles desde la fecha en que la misma fue recibida al correo electrónico info@bancaliv.com o info@bancaliv.com..'
    },
    {
        id: 103,
        message: '7.1.5. Toda solicitud relacionada con datos personales que GM SERVICIOS FINANCIEROS  haya obtenido en el uso de las APPs, tendrá que ser tramitada al correo electrónico info@bancaliv.com. De igual manera toda solicitud relacionada con datos personales que GM SERVICIOS FINANCIEROS  haya obtenido en el uso de otras plataformas y en el desarrollo de sus actividades propias, tendrá que ser tramitada al correo electrónico info@bancaliv.com. Solicitudes enviadas a otras direcciónes electrónicas o físicas, se entenderán por no enviadas o no puestas debidamente en conocimiento a GM SERVICIOS FINANCIEROS.'
    },
    {
        id: 104,
        message: '7.2. RECLAMOS. GM SERVICIOS FINANCIEROS  dispone de medios electrónicos para que el Titular, sus representantes, apoderados, causahabientes o por estipulación a favor de otro o para otro, formulen reclamos respecto de datos personales tratados por GM SERVICIOS FINANCIEROS  que deban ser objeto de corrección, actualización o supresión, o respecto al presunto incumplimiento de las disposiciones legales por parte de esta. El procedimiento que a continuación se indica está en concordancia con la Ley 1582 de 2012, artículo 15 en los siguientes términos.'
    },
    {
        id: 105,
        message: '7.2.1 El único mecanismo disponible por GM SERVICIOS FINANCIEROS  será el correo electrónico info@bancaliv.com y info@bancaliv.com. El reclamo deberá ser representado por el Titular, sus representantes, apoderados acreditados, sus causahabientes o quienes tengan dicha facultad conforme a los requisitos contemplados en la ley 1581 de 2012 y el decreto 1377 de 2012 y demás normas concordantes. Dicha solicitud deberá contener como mínimo lo siguiente: i) deberá dirigirse a GM SERVICIOS FINANCIEROS  por vía electrónica a la dirección info@bancaliv.com o info@bancaliv.com; ii) deberá indicar el nombre y documento de identificación del Titular; iii) contendrá una descripción de los hechos que den lugar al reclamo y la finalidad del mismo, como actualización, corrección, supresión del dato o cumplimiento de disposiciones o deberes legales o de la política de tratamiento de datos; iv) deberá indicar la dirección y datos de contacto e identificación del reclamante; v) acompañará toda la documentación que el reclamante quiera hacer valer.'
    },
    {
        id: 106,
        message: '7.2.2. GM SERVICIOS FINANCIEROS  antes de atender el reclamo, verificará la identidad del Titular del dato personal, su apoderado, representante o causahabiente o de la estipulación a favor de otro o para otro, así como la documentación de identificación del titular y los poderes o autorizaciones específicos que sean exigibles para cada caso.'
    },
    {
        id: 107,
        message: '7.2.3. Si el reclamo presentado o la documentación adicional están incompletos, GM SERVICIOS FINANCIEROS  requerirá al reclamante por una sola vez, dentro de los cinco (5) días hábiles siguientes a la recepción del reclamo para que corrija las fallas. Si el reclamante no presenta la documentación e información requerida dentro de los dos (2) meses siguientes a la fecha del reclamo inicial, se entenderá que se desistió del reclamo.'
    },
    {
        id: 108,
        message: '7.2.4. En caso de que quien reciba el reclamo no sea competente para resolverlo, se dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interésado.'
    },
    {
        id: 109,
        message: '7.2.5. Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga “reclamo en trámite” y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.'
    },
    {
        id: 110,
        message: '7.2.6. El tiempo máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando por algún motivo no fuera posible atender el reclamo en ese término, se informará al interésado los motivos de la tardanza y la fecha en que se atenderá el reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.'
    },
    {
        id: 111,
        title: '8. Autorización del titular',
        message: 'Cualquier persona que desee acceder a las plataformas de las aplicaciones móviles G&M App y G&M App o que desee hacer uso de las pagina web info@gmserviciosfinancieros.com.co y www.G&M.com.co, debe regístrese y autorizar el tratamiento de los datos personales, para poder hacer uso de los servicios que estas aplicaciones móviles y plataformas ofrecen, por tal razón en cualquiera de las plataformas encontrará al momento del registro una casilla con la frase igual o similar a -Conozco y acepto los términos, condiciones”, y/o “Conozco y acepto las Políticas de Tratamiento de Datos Personales” y/o “Acepto la Política”-. Esta casilla contiene el link de las presentes políticas y debe ser leída y aceptada. En el caso de contratistas, proveedores, clientes directos, demás empresas aliadas de GM SERVICIOS FINANCIEROS y aquellas personas vinculadas laboralmente con GM SERVICIOS FINANCIEROS , quienes han suscrito cualquier contrato laboral, de servicios, convenios, solicitudes para una convocatoria laboral o de servicios, entre otros, debe autorizar el tratamiento de los datos personales mediante la firma y envío del formato “POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES” que contiene estas políticas para ser leídas, y el espacio correspondiente para ser firmada y aceptada'
    },
    {
        id: 112,
        title: '9. Vigencia',
        message: 'Esta Política de Tratamiento de datos rige a partir del día 1 del mes de agosto del año 2020. Los Datos Personales que sean almacenados, utilizados o transmitidos permanecerán en la base de datos de GM SERVICIOS FINANCIEROS , en concordancia al criterio de temporalidad y necesidad durante el tiempo que sea necesario para las finalidades mencionadas en esta Política, para las cuales fueron recolectados.'
    }
]