import Constants from 'expo-constants'

export const ShadowBox = {
    color: '#000',
    shadowOffset: {
        width: 0,
        height: 0
    },
    shadowOpacity: 0.1,
    shadowRadius: 5.2,
    elevation: Constants.platform.android ? 2 : 0
}