import React from 'react'
import PropTypes from 'prop-types'
import Constants from 'expo-constants'
import { AntDesign, Ionicons } from '@expo/vector-icons'
import { ScrollView } from 'react-native'
import { wp } from '../../validation'
import { Modal } from '../Modal'
import theme from '../../../ignore/Constants'
import { Container, Cancel, Title, BoxInfo, Info, BoxCamera, ButtonCamera } from './styled'

export const ModalBannerInfo = ({ isVisible, handleCancel, title, steps = [], icon, handleButton }) => (
    <Modal isVisible={isVisible} animationIn='fadeIn' animationOut='fadeOutDown' style={{ ...(Constants.platform.web ? { marginHorizontal: 'auto', marginVertical: 0 } : { marginBottom: 0, marginHorizontal: 0 }) }}>
        <Container>
            <Cancel onPress={handleCancel}>
                <AntDesign name='closecircleo' size={wp(6.5)} color={theme.backgroundC} />
            </Cancel>
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ alignItems: 'center' }}>
                <Title>{title}</Title>
                {icon}
                <BoxInfo>
                    {!!steps.length && steps.map((x, i) => <Info key={i}>{x.text}</Info>)}
                </BoxInfo>
            </ScrollView>
            {handleButton &&
                <BoxCamera>
                    <ButtonCamera testID='a006d321-43f3-4d1e-ac51-fbd65b7af978' onPress={handleButton}>
                        <Ionicons name='arrow-redo-sharp' size={wp(10)} color={theme.backgroundC} />
                    </ButtonCamera>
                    <Info>Continuar</Info>
                </BoxCamera>
            }
        </Container>
    </Modal>
)

ModalBannerInfo.propTypes = {
    isVisible: PropTypes.bool,
    handleCancel: PropTypes.func,
    title: PropTypes.string,
    steps: PropTypes.array,
    icon: PropTypes.node,
    handleButton: PropTypes.func
}