
import Constants from 'expo-constants'
import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { mq } from '../../validation'

export const Title = styled.Text`
    font-family: PFontMedium;
    font-size: ${mq(13)}px;
    margin: 0 ${mq(5)}px 0 ${mq(10)}px;
    color: ${theme.onSurfaceV};
`
export const ButtonSelect = styled.TouchableOpacity`
    width: 100%;
    /* border: ${mq(1)}px solid ${({ borderColor }) => borderColor}; */
    border-radius: ${mq(10)}px;
    padding: ${({ value }) => Constants.platform.web ? (value ? mq(8) : mq(15)) : mq(10)}px ${mq(20)}px;
    background-color: ${theme.backgroundP};
`
export const SubTitle = styled.Text`
    font-family: ${({ fFamily }) => fFamily || 'PFontRegular'};
    font-size: ${({ size }) => size || mq(13)}px;
    color: ${({ color }) => color || theme.onVariantSurfC};
`
export const TextError = styled.Text`
    font-family: PFontRegular;
    font-size: ${(13)}px;
    position: absolute;
    margin: 0 ${(2)}px 0 ${(10)}px;
    bottom: -${(1)}px;
    color: ${theme.errorC};
`

// Diseño de modal del select
export const ContainerModal = styled.View`
    background-color: ${theme.backgroundC};
    bottom: 0;
    height: 60%;
    border-top-left-radius: ${mq(20)}px;
    border-top-right-radius: ${mq(20)}px;
`
export const ContainerTitle = styled.View`
    padding: ${mq(18)}px;
    margin-bottom: ${mq(8)}px;
    align-items: center;
    border-bottom-color: ${theme.onVariantSurfC}55;
    border-bottom-width: 1px;
`
export const Option = styled.TouchableOpacity`
    padding: ${mq(10)}px ${mq(30)}px;
    flex-direction: row;
    border-bottom-color: ${theme.onVariantSurfC}55;
    border-bottom-width: 1px;
    justify-content: space-between;
`
export const ContainerCheck = styled.View`
    border: 2px solid ${({ borderColor }) => borderColor};
    border-radius: ${mq(200)}px;
    width: ${mq(20)}px;
    height: ${mq(20)}px;
    padding: ${mq(2)}px;
`
export const Check = styled.View`
    width: 100%;
    height: 100%;
    border-radius: ${mq(200)}px;
    background-color: ${({ bgColor }) => bgColor};
`

// Boton de cancelar
export const ButtonCancel = styled.TouchableOpacity`
    width: 40%;
    align-items: center;
    justify-content: center;
    padding: ${mq(5)}px ${mq(30)}px;
    margin: ${mq(10)}px ${mq(20)}px;
    border: 2px solid ${theme.primaryC};
    border-radius: ${mq(20)}px;
`
export const ButtonText = styled.Text`
    font-family: PFontBold;
    font-size: ${mq(15)}px;
    color: #555;
`

// Barra lateral sobre el input
export const LeftBorder = styled.View`
    height: 45%;
    width: 2px;
    position: absolute;
    bottom: 10%;
    left: ${mq(8)}px;
    background-color: ${theme.primaryC};
    z-index: 99;
`