/* eslint-disable no-undef */
import parsePhoneNumber from 'libphonenumber-js'
import moment from 'moment'
import AWS from 'aws-sdk'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen'
import theme from '../../ignore/Constants'
import * as DocumentPicker from 'expo-document-picker'
import Constants from 'expo-constants'
import * as Notifications from 'expo-notifications'
import * as Permissions from 'expo-permissions'
import { isLoggedVar } from '../apollo/cache'
import { Dimensions } from 'react-native'
import { brandUrls } from '../lib/vars'

/**
 * busca en el local store la información
 * @version 0.0.1
 * @param {*} key clave de búsqueda
 * @param {boolean} keyParse si se quiere parsear o no
 * @return {object} valor de búsqueda o si hay un error una alerta
 */
export const getData = async (key, keyParse) => {
    try {
        const jsonValue = await AsyncStorage.getItem(key)
        return keyParse ? (jsonValue ? JSON.parse(jsonValue) : null) : jsonValue
    } catch (e) {
        isLoggedVar({ ...isLoggedVar(), message: 'Ha ocurrido un error intente más tarde' })
    }
}

/**
 * Busca un documento para retornarlo
 * @version 0.0.1
 * @param {object} options opciones del método de document picker
 * @return {object} la información de un documento
 */
export const SelectDocument = async options => await DocumentPicker.getDocumentAsync(options)

/**
 * guarda en el local store la información
 * @version 0.0.1
 * @param {string} por porcentaje para el wp
 * @return {number} tamaño del width solicitado
 */
export const wp = por => {
    if (widthPercentageToDP(100) < 768) return widthPercentageToDP(por)
    if (!Constants.platform.web) return widthPercentageToDP(por)
    return (375 * (parseFloat(isNaN(por) ? por.replace('%', '') : por) / 100))
}

export const hp = por => {
    if (widthPercentageToDP(100) < 768) return heightPercentageToDP(por)
    if (!Constants.platform.web) return heightPercentageToDP(por)
    return (812 * (parseFloat(isNaN(por) ? por.replace('%', '') : por) / 100))
}

/**
 * Calculate width value
 * @param {number} val Width value
 * @returns {number} Val render
 */
export const mq = val => {
    if (Dimensions.get('screen').width > 768 && Constants.platform.web && val === '100%') return 425
    else if (Dimensions.get('screen').width > 768) {
        if (!isNaN(val)) return Constants.platform.web ? val : val + 10

        return val
    }
    return val
}

/**
 * guarda en el local store la información
 * @version 0.0.1
 * @param {*} key clave de storage
 * @param {*} value valor a guardar
 * @param {boolean} keyParse si se quiere parsear o no
 * @return {null} sin retorno o si hay un error una alerta
 */
export const setData = async (key, value, keyParse) => {
    try {
        const jsonValue = keyParse ? JSON.stringify(value) : value
        await AsyncStorage.setItem(key, jsonValue)
    } catch (e) {
        isLoggedVar({ ...isLoggedVar(), message: 'Ha ocurrido un error intente más tarde' })
    }
}

export const validationErrors = values => {
    let error = false
    for (const key in values) {
        if (values[key]) error = true
    }
    return error
}

/**
 * elimina en el local store la información
 * @version 0.0.1
 * @param {*} key clave de storage
 * @return {null} sin retorno o si hay un error una alerta
 */
export const removeData = async key => {
    try {
        await AsyncStorage.removeItem(key)
    } catch (e) {
        isLoggedVar({ ...isLoggedVar(), message: 'Ha ocurrido un error intente más tarde' })
    }
}

/**
 * limpia el cache
 * @version 0.0.1
 * @return {null} sin retorno o si hay un error una alerta
 */
export const clearData = async () => {
    try {
        await AsyncStorage.clear()
    } catch (e) {
        isLoggedVar({ ...isLoggedVar(), message: 'Ha ocurrido un error intente más tarde' })
    }
}

/**
 * Busca la extension del archivo
 * @version 0.0.1
 * @param {string} filename nombre del archivo con la extension
 * @return {string} nombre de la extension
 */
export const extFile = filename => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

/**
 * Verifica que contenga un valor
 * @version 0.0.1
 * @param {*} value valor
 * @return {boolean} true o false
 */
export const isNull = value => {
    if (!!value || value === 0) return false
    return true
}

/**
 * Verifica que sea números
 * @version 0.0.1
 * @param {*} value valor
 * @return {boolean} true o false
 */
export const isNumeric = value => {
    if (!isNaN(value)) return false
    return true
}

/**
 * Verifica que sea letras
 * @version 0.0.1
 * @param {*} value valor
 * @return {boolean} true o false
 */
export const onlyLetters = value => {
    const validation = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g
    if (validation.test(value) || value.length === 0) return false
    return true
}

/**
 * Verifica que se encuentre en la cantidad del rango de caracteres
 * @version 0.0.1
 * @param {*} value valor
 * @param {*} min mínimo
 * @param {*} max máximo
 * @return {boolean} true o false
 */
export const rangeLength = (value, min, max) => {
    if (((value.length >= min) && (value.length <= max)) || value.length === 0) return false
    return true
}

/**
 * Verifica que sea un correo electrónico
 * @version 0.0.1
 * @param {*} value valor
 * @return {boolean} true o false
 */
export const isEmail = value => {
    const validation = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
    if (validation.test(value) || value.length === 0) return false
    return true
}

/**
 * Se conecta a Aws3
 * @version 0.0.1
 * @return {boolean} la conexión
 */
export const AWS3 = () => new AWS.S3({
    accessKeyId: Constants.manifest.extra.accessKey,
    secretAccessKey: Constants.manifest.extra.secretKey
})
/**
 * Busca un documento en S3
 * @param {String} Key variable de búsqueda
 * @return {String} base64 del documento
 */
export const getFileUrlS3 = async (Key) => {
    const client = brandUrls();
    const S3 = AWS3();
    const params = { Bucket: client.bt || 'pruebaawow', Prefix: Key };
    try {
        const response = await S3.listObjectsV2(params).promise();

        const objectKeys = response?.Contents?.map(obj => obj.Key);
        const exists = objectKeys?.includes(Key) ?? false;
        if (!exists) return null;
        const res = await S3.getSignedUrlPromise('getObject', {
            Bucket: brandUrls().bt || 'pruebaawow',
            Key,
        });
        return res;
    } catch (error) {
        return undefined;
    }
};

/**
 * Busca un documento en S3
 * @param {String} Key variable de búsqueda
 * @return {String} base64 del documento
 */
export const getFileS3 = async Key => {
    const S3 = AWS3()
    const res = await S3.getObject({ Bucket: brandUrls()?.bt, Key }).promise().catch(() => undefined)
    return res && `data:image/jpeg;base64,${res.Body.toString('base64')}`
}

/**
 * guarda un documento en S3
 * @param {String} Key variable de búsqueda
 * @param {Array} Body Array de buffer del documento
 * @return {String} respuesta del servidor
 */
export const putFileS3 = async (Key, Body) => {
    const S3 = AWS3()
    const res = await S3.putObject({ Bucket: brandUrls()?.bt, Key, Body }).promise().catch(() => undefined)
    return res
}

/**
 * Obtiene el token de la notificación
 * @param {String} name nombre del token
 * @return {String} Token
 */
export const tokenNotification = async name => {
    let token

    // verifica si es un dispositivo
    if (!Constants.platform.web) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync()
        let finalStatus = existingStatus

        // verifica si existe permiso
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync()
            finalStatus = status
        }

        // verifica si no dieron permisos
        if (finalStatus !== 'granted') return undefined
    } else {
        // verifica si existe permiso
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS)

        // verifica si no dieron permisos
        if (status !== 'granted') return undefined
    }

    // obtiene el token
    try {
        token = (await Notifications.getExpoPushTokenAsync()).data
    } catch (error) {
        return undefined
    }

    if (Constants.platform.android) {
        Notifications.setNotificationChannelAsync(name || 'default', {
            name: name || 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: theme.primaryC,
        })
    }

    return token
}

/**
 * Transforma una cantidad de números en formato dinero
 * @version 0.0.1
 * @param {numeric} value valor en números
 * @return {boolean} true o false
 */
export const numberFormat = value => {
    if (value) {
        if (parseInt(value)) return new Intl.NumberFormat('de-DE').format(parseFloat(`${value}`.replace(/\./g, '')))
        else return 0
    } else {
        if (isNaN(value)) return ''
        else return 0
    }
}

/**
 * Transforma una fecha en ingles a formato español
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato de fecha
 */
export const dateFormat = value => moment(value).format('DD/MM/YYYY')

/**
 * Transforma un numero en formato de teléfono
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato en teléfono
 */
export const phoneFormat = value => !!value && parsePhoneNumber(`${value}`, 'US')?.formatNational()

/**
 * Transforma una hora en ingles a formato español
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato en hora
 */
export const hourFormat = value => moment(value).format('LT')

/**
 * Calcula el dígito de verificación
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {numeric} el dígito de verificación
 */
export const CalcularDígitoVerificación = value => {
    // variables necesarias
    let nit = `${value}`
    const vpri = new Array(undefined, 3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71)

    // Se limpia el Nit
    nit = nit.replace(/\s/g, ''); // Espacios
    nit = nit.replace(/,/g, ''); // Comas
    nit = nit.replace(/\./g, ''); // Puntos
    nit = nit.replace(/-/g, ''); // Guiones

    // Se valida el nit
    if (isNaN(nit)) return ''

    // Procedimiento
    let x = 0
    let y = 0
    let i = 0
    const z = nit.length

    for (i; i < z; i++) {
        y = nit.substr(i, 1)

        x += (y * vpri[z - i])
    }

    y = x % 11

    return (y > 1) ? 11 - y : y
}

/**
 * transforma todo de base64 a arraybuffer
 * @version 0.0.1
 * @param {string} base64 valor
 * @return {array} array en bytes
 */
export const base64ToArrayBuffer = base64 => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
    const lookup = new Uint8Array(256)

    for (let i = 0; i < chars.length; i++) {
        lookup[chars.charCodeAt(i)] = i
    }

    let bufferLength = base64.length * 0.75
    const len = base64.length
    let i
    let p = 0
    let encoded1, encoded2, encoded3, encoded4

    if (base64[base64.length - 1] === '=') {
        bufferLength--
        if (base64[base64.length - 2] === '=') bufferLength--
    }

    const arraybuffer = new ArrayBuffer(bufferLength)
    const bytes = new Uint8Array(arraybuffer)

    for (i = 0; i < len; i += 4) {
        encoded1 = lookup[base64.charCodeAt(i)]
        encoded2 = lookup[base64.charCodeAt(i + 1)]
        encoded3 = lookup[base64.charCodeAt(i + 2)]
        encoded4 = lookup[base64.charCodeAt(i + 3)]

        bytes[p++] = (encoded1 << 2) | (encoded2 >> 4)
        bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2)
        bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63)
    }

    return bytes
}

/**
 * transforma todo de base64 a arraybuffer
 * @version 0.0.1
 * @param {string} base64 valor
 * @return {array} array en bytes
 */
export const base64ToArrayBufferAdo = base64 => {
    if (!base64) return false;
    const binaryString = window.atob(base64.substring(base64.indexOf('base64,') + 7, base64.length));
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString?.charCodeAt(i);
    }
    return bytes;
};

/**
 * actualizar cache de apollo
 * @param {object} params parámetros para actualizar el cachet de apollo
 * @returns {null} no hay retorno
 */
export const updateCache = async ({ cache, query, nameFun, dataNew = {}, id, array, variables }) => {
    return cache.modify({
        fields: {
            [nameFun](dataOld = {}) {
                let data
                if (array) {
                    if (dataOld?.length) {
                        const findData = dataOld.find(x => x[id] === dataNew[id])
                        if (findData) data = dataOld.map(x => x[id] === dataNew[id] ? { ...x, ...dataNew } : x)
                        else data = [...dataOld, dataNew]
                    } else data = [dataNew]
                } else data = { ...dataOld, ...dataNew }
                return cache.writeQuery({ query, data, variables: variables || {} })
            }
        }
    })
}

/**
 * Actualiza el cache de apollo
 * @author Luis Cuevas
 * @version 1.0
 * @property {Object} Args
 * @param {Object} Args Argumentos necesarios
 * @param {Object} Args.store chace de apolo
 * @param {any} Args.query consulta a actualizar
 * @param {string} Args.name nombre de la query
 * @param {Object|Array} Args.dataNew los nuevos campos a actualizar
 * @param {boolean|undefined} [Args.array] si es un array (Opcional)
 * @param {Object|undefined} [Args.variables] variables de la query (Opcional)
 * @param {string|undefined} Args.id nombre del id a comparar (Opcional)
 * @param {string} [Args.typeName] Nombre de la clave en cache
 * @param {string|undefined} [Args.deleteRow] si desea eliminar el registro (Opcional)
 * @returns {void}
 */
export const writeCache = ({ store, query, name, dataNew = {}, array, variables, id, typeName, deleteRow }) => {
    // busca la query en el cache
    const dataOld = store.readQuery({ query, variables })

    // actualiza la query
    if (array) {
        // verifica si los valores devueltos es para comparar y actualizar o agregar
        if (id) {
            const findRQ = (dataOld?.[name] || []).find(x => x[id] === dataNew[id])
            // verifica si eliminara
            if (deleteRow && findRQ) store.writeQuery({ query, data: { [name]: dataOld[name].filter(x => x[id] !== dataNew[id]) }, variables })
            // verifica si agregara o actualizara
            else {
                // actualiza el registro
                if (findRQ) store.writeQuery({ query, data: { [name]: dataOld[name].map(x => x[id] === dataNew[id] ? { ...x, ...dataNew } : x) }, variables })
                // agrega un valor nuevo
                else store.writeQuery({ query, data: { [name]: [...(dataOld?.[name] || []), { ...dataNew, __typename: typeName }] }, variables })
            }
            // actualiza el array completo
        } else store.writeQuery({ query, data: { [name]: (dataNew || []).map(x => ({ ...x, __typename: typeName })) }, variables })
        // actualiza el objecto
    } else store.writeQuery({ query, data: { [name]: { ...(dataOld?.[name] || {}), ...dataNew, __typename: typeName } }, variables })
}

/**
 *
 * @param {Object} data objeto a filtrar
 * @param {Array} filters array a comparar o claves del objeto a excluir
 * @return {Object} devuelve un objeto con los datos filtrados
 */
export const filterKeyObject = (data, filters) => {
    let values = {}
    for (const elem in data) {
        let coincidence = false
        for (let i = 0; i < filters.length; i++) if (elem === filters[i]) coincidence = true
        if (!coincidence) values = { ...values, [elem]: data[elem] }
    }
    return values
}

/**
 * Obtiene los valores del token
 * @param {object} token valores a buscar
 * @returns {object} nuevos valores decodificados
 */
export const getValueToken = token => token && JSON.parse(token.split('.').map(part => Buffer.from(part.replace(/-/g, '+').replace(/_/g, '/'), 'base64').toString())[1])

/**
 * Obtiene los valores del token
 * @param {object} name nombre del archivo
 * @returns {object} nuevos valores decodificados
 */
export const isImage = name => !!['jpeg', 'jpg', 'png', 'gif', 'bmp', 'tif', 'tiff'].find(x => x === extFile(name))

export const diffInDays = (to, from) => {
    // Convertimos las fechas a objetos Date
    const dataTo = new Date(to);
    const dateFrom = new Date(from);

    // Convertimos las fechas a días
    const daysTo = dataTo.getFullYear() * 360 + dataTo.getMonth() * 30 + dataTo.getDate();
    const daysFrom = dateFrom.getFullYear() * 360 + dateFrom.getMonth() * 30 + dateFrom.getDate();

    // Calculamos la diferencia en días
    const difference = Math.abs(daysFrom - daysTo);

    return difference;
}