import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { PersonalDataRegisterV } from '../../components/PersonalDataRegister'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { filterKeyObject, writeCache, validationErrors } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'
import { UPDATE_PAYROLL_COMPANY } from './queries'

export const PersonalDataRegisterC = ({ dataUser, banner, handleNext, format, refScroll }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [payrollCompanyMutation, { loading }] = useMutation(UPDATE_PAYROLL_COMPANY)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({
            pcDatExp: dataUser?.pcDatExp,
            cName: dataUser?.pcDomExp,
            payrollStaff: filterKeyObject(dataUser?.payrollStaff, ['__typename'])
        })
        setErrorForm({
            pcDatExp: (format?.ufDatExpDoc && !dataUser?.pcDatExp),
            cName: (format?.ufDomExpDoc && !dataUser?.pcDomExp),
            psDatBir: (format?.ufDatBir && !dataUser?.payrollStaff?.psDatBir),
            psFirNam: (format?.ufFirNam && !dataUser?.payrollStaff?.psFirNam),
            psFirLas: (format?.ufFirLas && !dataUser?.payrollStaff?.psFirLas),
            gId: (format?.ufGender && !dataUser?.payrollStaff?.gId),
            cId: (format?.ufLocBir && !dataUser?.payrollStaff?.cId)
        })
    }, [dataUser])

    // Cambio en los valores
    const handleChange = (e, type) => {
        if (type === 1) setValues({ ...values, payrollStaff: { ...values.payrollStaff, [e.name]: e.value } })
        else setValues({ ...values, [e.name]: e.value })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data: dataPC, error } = await payrollCompanyMutation({ variables: {
            input: {
                payrollStaff: {
                    cId: values?.payrollStaff?.cId,
                    gId: values?.payrollStaff?.gId,
                    psDatBir: values?.payrollStaff?.psDatBir,
                    psEmail: values?.payrollStaff?.psEmail,
                    psFirLas: values?.payrollStaff?.psFirLas,
                    psFirNam: values?.payrollStaff?.psFirNam,
                    psHeight: values?.payrollStaff?.psHeight,
                    psId: values?.payrollStaff?.psId,
                    psPhoMob: values?.payrollStaff?.psPhoMob,
                    psPhoPer: values?.payrollStaff?.psPhoPer,
                    psSecLas: values?.payrollStaff?.psSecLas,
                    psSecNam: values?.payrollStaff?.psSecNam,
                    psSons: values?.payrollStaff?.psSons,
                    psState: values?.payrollStaff?.psState,
                    psWeight: values?.payrollStaff?.psWeight,
                }, pcDomExp: values?.cName, pcDatExp: values?.pcDatExp } }, update(store, { data: { setOrUpdatePayrollCompany } }) {
            writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: setOrUpdatePayrollCompany, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
        } }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (dataPC?.setOrUpdatePayrollCompany) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(1.5)
        }
    }
    return (
        <PersonalDataRegisterV
            data={{ values: { ...values, tiId: dataUser?.typeIdentity?.tiId, pcIdeNum: dataUser?.pcIdeNum }, errors, format, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleChangeStaff={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

PersonalDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    refScroll: PropTypes.object
}