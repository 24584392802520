import React from 'react'
import Svg, { G, Path, Polygon, Rect } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
// eslint-disable-next-line react/prop-types
export const LogoLogin = props => (
    <Svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="272.8px"
        height="128.3px"
        viewBox="0 0 272.8 128.3"
        style={{
            enableBackground: 'new 0 0 272.8 128.3',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <G>
            <Rect x={117.7} y={59.3} className="st0" width={1.2} height={16.2} />
            <Polygon
                className="st0"
                points="123.7,59.3 132.6,74.3 132.6,59.3 133.9,59.3 133.9,75.5 131.9,75.5 123.4,61.1 123.4,75.5  122.2,75.5 122.2,59.3  "
            />
            <Path
                className="st0"
                d="M141.6,75.6l-5.8-16.3h1.3l5.5,15.5l5.4-15.5h1.3c-1.9,5.4-3.8,10.9-5.7,16.3H141.6z"
            />
            <Polygon
                className="st0"
                points="159,60.5 152.2,60.5 152.2,66.8 158.9,66.8 158.9,67.9 152.2,67.9 152.2,74.2 159,74.2 159,75.4  151,75.4 151,59.3 159,59.3  "
            />
            <Path
                className="st0"
                d="M172.3,75.5h-1.6l-5.3-6.9h-2.2v6.9h-1.2V59.3h4.9c2.9,0,5.2,1.6,5.2,4.7c0,3-2.1,4.5-5.1,4.6L172.3,75.5z  M166.8,67.5c2.4,0,4-1.1,4-3.5c0-2.4-1.7-3.5-4-3.5h-3.7v7H166.8z"
            />
            <Path
                className="st0"
                d="M178.9,66.5c2.3,0.6,4.8,1.8,4.7,5.1c-0.2,2.7-2.7,4.1-4.9,4.1c-1.9,0-3.9-1.1-4.8-3.1c0.3-0.2,0.7-0.4,1-0.5 c0.7,1.5,2.1,2.5,3.8,2.5c2.1,0,3.6-1.2,3.7-3c0.2-2.5-2.1-3.4-3.8-3.8c-2-0.6-4.5-1.5-4.4-4.5c0.1-2.8,2.3-4.1,4.5-4.1 c1.8,0,3.7,1,4.5,2.8l-1,0.5c-0.7-1.3-2.2-2.1-3.5-2.1c-1.6,0-3.2,0.9-3.4,3C175.3,65.5,177.5,66.1,178.9,66.5"
            />
            <Rect x={186.2} y={59.3} className="st0" width={1.2} height={16.2} />
            <Path
                className="st0"
                d="M198.3,59c4.7,0,8,3.9,8,8.4c0,5.5-4,8.3-8,8.3c-4,0-8-2.8-8-8.3C190.3,63,193.5,59,198.3,59 M191.6,67.4 c0,4.7,3.3,7.1,6.7,7.1c3.4,0,6.8-2.3,6.8-7.1C205.1,57.8,191.6,57.9,191.6,67.4z"
            />
            <Polygon
                className="st0"
                points="210.7,59.3 219.6,74.3 219.6,59.3 220.8,59.3 220.8,75.5 218.9,75.5 210.4,61.1 210.4,75.5  209.1,75.5 209.1,59.3  "
            />
            <Polygon
                className="st0"
                points="232,60.5 225.2,60.5 225.2,66.8 231.9,66.8 231.9,67.9 225.2,67.9 225.2,74.2 232,74.2 232,75.4  224,75.4 224,59.3 232,59.3  "
            />
            <Path
                className="st0"
                d="M238.8,66.5c2.3,0.6,4.8,1.8,4.7,5.1c-0.2,2.7-2.7,4.1-4.9,4.1c-1.9,0-3.9-1.1-4.8-3.1c0.3-0.2,0.7-0.4,1-0.5 c0.7,1.5,2.1,2.5,3.8,2.5c2.1,0,3.6-1.2,3.7-3c0.2-2.5-2.1-3.4-3.8-3.8c-2-0.6-4.5-1.5-4.4-4.5c0.1-2.8,2.3-4.1,4.5-4.1 c1.8,0,3.7,1,4.5,2.8l-1,0.5c-0.7-1.3-2.2-2.1-3.5-2.1c-1.6,0-3.2,0.9-3.4,3C235.2,65.5,237.4,66.1,238.8,66.5"
            />
            <Path
                className="st0"
                d="M125.7,91.3l1.4-2.2c0.5,0.2,0.6,0.3,1,0.6l-1.6,2.5l3,3.5H128l-2.2-2.5c-1.3,1.7-2.6,2.8-4.6,2.8 c-1.7,0-3.2-0.8-4-2.2c-1.5-2.7-0.1-5.1,2.8-6.8c-0.9-1-1.8-2.3-1.8-3.6c0-2.2,1.8-3.5,3.8-3.5c1.8,0,3.4,1.3,3.4,3.3 c0,2.1-1.8,3.3-3.5,4.2L125.7,91.3z M120.9,87.7c-2,1-3.8,3.1-2.6,5.3c0.6,1.1,1.9,1.8,3.2,1.8c1.5,0,2.7-1.3,3.6-2.5L120.9,87.7z  M119.5,83.3c0,1,1,2.3,1.7,3.2c1.2-0.6,3.1-1.7,3.1-3.4c0-1.2-1-2.3-2.3-2.3C120.6,80.9,119.5,82,119.5,83.3z"
            />
            <Polygon
                className="st0"
                points="137.5,79.8 146.4,94.7 146.4,79.8 147.7,79.8 147.7,96 145.7,96 137.2,81.6 137.2,96 136,96 136,79.8  "
            />
            <Polygon
                className="st0"
                points="158.9,80.9 152.1,80.9 152.1,87.2 158.8,87.2 158.8,88.4 152.1,88.4 152.1,94.7 158.9,94.7  158.9,95.9 150.8,95.9 150.8,79.7 158.9,79.7  "
            />
            <Path
                className="st0"
                d="M174.7,82.1l-0.9,0.8c-1.2-1.4-3.1-2.2-5-2.2c-4.3,0-6.7,3.9-6.7,7.2c0,3.4,2.4,7.1,6.7,7.1 c2.9,0,6.1-2.4,6.4-5.7h-7.3v-1.2h8.7c-0.1,4.5-3.2,8.1-7.9,8.1c-5.2,0-8-4.2-8-8.3c0-4.1,2.8-8.4,8-8.4 C171,79.5,173.2,80.5,174.7,82.1"
            />
            <Path
                className="st0"
                d="M186.4,79.5c4.7,0,8,3.9,8,8.4c0,5.5-4,8.3-8,8.3c-4,0-8-2.8-8-8.3C178.4,83.4,181.5,79.5,186.4,79.5  M179.6,87.9c0,4.7,3.3,7.1,6.7,7.1c3.4,0,6.8-2.3,6.8-7.1C193.1,78.3,179.6,78.4,179.6,87.9z"
            />
            <Path
                className="st0"
                d="M208.7,82.6c-1.2-1.2-2.9-1.9-4.7-1.9c-4.4,0.1-6.7,3.8-6.7,7.2c0,3.4,2.4,7.1,6.7,7.1c1.8,0,3.8-0.9,5.1-2.2 l0.9,0.7c-1.5,1.6-3.7,2.7-6,2.7c-5.2,0-8-4.3-8-8.3c0-4.1,2.8-8.4,8-8.4c2.1,0,4.1,0.9,5.5,2.2L208.7,82.6z"
            />
            <Rect x={212.7} y={79.8} className="st0" width={1.2} height={16.2} />
            <Path
                className="st0"
                d="M224.8,79.5c4.7,0,8,3.9,8,8.4c0,5.5-4,8.3-8,8.3s-8-2.8-8-8.3C216.8,83.4,220,79.5,224.8,79.5 M218.1,87.9 c0,4.7,3.3,7.1,6.7,7.1c3.4,0,6.8-2.3,6.8-7.1C231.6,78.3,218.1,78.4,218.1,87.9z"
            />
            <Path
                className="st0"
                d="M239.5,87c2.3,0.6,4.8,1.8,4.7,5.1c-0.2,2.7-2.7,4.1-4.9,4.1c-1.9,0-3.9-1.1-4.8-3.1c0.3-0.2,0.7-0.4,1-0.5 c0.7,1.5,2.1,2.5,3.8,2.5c2.1,0,3.6-1.2,3.7-3c0.2-2.5-2.1-3.4-3.8-3.8c-2-0.6-4.5-1.5-4.4-4.5c0.1-2.8,2.3-4.1,4.5-4.1 c1.8,0,3.7,1,4.5,2.8l-1,0.5c-0.7-1.3-2.2-2.1-3.5-2.1c-1.6,0-3.2,0.9-3.4,3C235.8,86,238,86.6,239.5,87"
            />
            <Path
                className="st0"
                d="M121.5,107.5c2.3,0.6,4.8,1.8,4.7,5.1c-0.2,2.7-2.7,4.1-4.9,4.1c-1.9,0-3.9-1.1-4.8-3.1c0.3-0.2,0.7-0.4,1-0.5 c0.7,1.5,2.1,2.5,3.8,2.5c2.1,0,3.6-1.2,3.7-3c0.2-2.5-2.1-3.4-3.8-3.8c-2-0.6-4.5-1.5-4.4-4.5c0.1-2.8,2.3-4.1,4.5-4.1 c1.8,0,3.7,1,4.5,2.8l-1,0.5c-0.7-1.3-2.1-2.1-3.5-2.1c-1.6,0-3.2,0.9-3.4,3C117.9,106.4,120.1,107.1,121.5,107.5"
            />
            <Rect x={128.8} y={114.7} className="st0" width={1.5} height={1.8} />
            <Path
                className="st0"
                d="M137.7,100.1h1.9c1.9,5.3,3.9,11,5.8,16.3h-1.3l-1.9-5.4h-7l-1.9,5.4H132L137.7,100.1z M141.8,109.9l-3.1-9 l-3,9H141.8z"
            />
            <Rect x={146.9} y={114.7} className="st0" width={1.5} height={1.8} />
            <Path
                className="st0"
                d="M105.1,110L105.1,110V37.7V18.6c0-1.9-0.8-3.6-2-4.8c-1.2-1.2-2.9-2-4.8-2c-3.8,0-6.8,3.1-6.8,6.8v19.1v47.2 l-34.8-60h0l-0.1-0.1c2.8-0.6,4.9-3.1,4.9-6v-1.4c0-3.4-2.8-6.1-6.1-6.1H34.8c-3.4,0-6.1,2.8-6.1,6.1v1.4c0,3.4,2.8,6.1,6.1,6.1 h3.4v78.5h-3.4c-3.4,0-6.1,2.8-6.1,6.1v1.4c0,3.4,2.8,6.1,6.1,6.1h20.5c3.4,0,6.1-2.8,6.1-6.1v-1.4c0-3.4-2.8-6.1-6.1-6.1h-3.4 V43.9l0,0l39.6,68.1l0.9,1.6c0.2,0.3,0.3,0.5,0.5,0.8c0.2,0.2,0.4,0.5,0.6,0.7c0.5,0.5,1.2,1,1.8,1.3c0.3,0.1,0.5,0.2,0.8,0.3 c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.9,0.2,1.9,0.2,2.8,0c0.7-0.1,1.3-0.4,2-0.8c0.3-0.2,0.5-0.3,0.8-0.5h0 c0.2-0.2,0.5-0.4,0.7-0.6c0.5-0.5,1-1.2,1.3-1.8c0.1-0.3,0.2-0.5,0.3-0.8c0.2-0.7,0.3-1.3,0.3-2v0c0-0.1,0-0.1,0-0.2v0 C105.2,110.1,105.2,110,105.1,110"
            />
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const Logo = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

Logo.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}