import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { RequestCreditV } from '../../components/RequestCredit';
import { getData, removeData } from '../../validation';
import { CREATE_CREDIT, CREATE_CREDIT_GROUP } from './queries';
import { isLoggedVar } from '../../apollo/cache';

export const RequestCreditC = ({ navigation, route }) => {
    const [request, setRequest] = useState(false);
    const [createCredit, { loading: loadRC }] = useMutation(CREATE_CREDIT);
    const [createCreditGroup, { loading: loadRGC }] =
        useMutation(CREATE_CREDIT_GROUP);

    const { hash } = route?.params || {};

    const requestC = async () => {
        setRequest(false);
        const iC = await getData('infCredit');
        if (iC && !loadRC && !loadRGC) {
            removeData('infCredit');
            const infCredit = JSON.parse(iC);
            if (infCredit?.group) {
                const { data, error } = await createCreditGroup({
                    variables: { input: infCredit },
                }).catch(e => ({ error: e }));

                // verifica si hay error
                if (error)
                {return isLoggedVar({
                    ...isLoggedVar(),
                    message: error.message,
                });}
                if (data?.createCreditGroup) setRequest(true);
            } else {
                const { data, error } = await createCredit({
                    variables: { input: { ...infCredit, hashCirenio: hash || null } },
                }).catch(e => ({ error: e }));

                // verifica si hay error
                if (error)
                {return isLoggedVar({
                    ...isLoggedVar(),
                    message: error.message,
                });}
                if (data?.createCredit) setRequest(true);
            }
        } //else navigation.navigate('Home')
    };

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => requestC());
        return unsubscribe;
    }, []);

    return (
        <RequestCreditV
            request={request}
            loading={loadRC || loadRGC}
            navigation={navigation}
        />
    );
};

RequestCreditC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
};