import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Title, Row, BoxConsecutive, styles } from './styled'
import { FontAwesome, MaterialCommunityIcons, MaterialIcons, AntDesign } from '@expo/vector-icons'
import theme from '../../../ignore/Constants'
import { dateFormat, wp, phoneFormat } from '../../validation'
import { HeadTitle } from '../HeadTitle'
import { ProfileCardV } from '../ProfileCard'

export const ProfileV = ({ data, navigation, loading, consecutive }) => (
    <Container>
        <HeadTitle icon={<FontAwesome name='user' size={wp(8)} color={theme.primaryC} />} title='PERFIL' />
        <ProfileCardV data={data} navigation={navigation} loading={loading} />
        <Box style={styles.shadow}>
            <Row>
                <MaterialIcons name='location-city' size={wp(5)} color={theme.onVariantSurfC} />
                <Title numberOfLines={1}>{data ? data?.payrollResidence?.city?.cName : ''}</Title>
            </Row>
            <Row>
                <AntDesign name='calendar' size={wp(5)} color={theme.onVariantSurfC} />
                <Title>{data?.payrollStaff?.psDatBir ? dateFormat(data?.payrollStaff?.psDatBir) : 'N/A'}</Title>
            </Row>
            <Row>
                <FontAwesome name='phone' size={wp(5)} color={theme.onVariantSurfC} />
                <Title>{data ? phoneFormat(data?.payrollStaff?.psPhoMob) : ''}</Title>
            </Row>
            <Row>
                <MaterialCommunityIcons name='email' size={wp(5)} color={theme.onVariantSurfC} />
                <Title numberOfLines={1}>{data ? data?.payrollStaff?.psEmail : ''}</Title>
            </Row>
        </Box>
        {!!consecutive &&
            <BoxConsecutive>
                <Title>{consecutive}</Title>
            </BoxConsecutive>
        }
    </Container>
)

ProfileV.propTypes = {
    data: PropTypes.object,
    navigation: PropTypes.object,
    loading: PropTypes.bool,
    consecutive: PropTypes.string
}