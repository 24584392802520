import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { filterKeyObject, updateCache, validationErrors } from '../../validation'
import { EDIT_CREDIT_PAYROLL_LIFE_INSURANCE, PREGNANCY_TIME } from './queries'
import { LifeInsuranceDataRegisterV } from '../../components/CreditEditData/LifeInsuranceDataRegister'

export const LifeInsuranceDataRegisterC = ({ dataUser, banner, handleNext, format, refScroll }) => {
    const [values, setValues] = useState({})
    const [values1, setValues1] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const { data: pregnacyTimes } = useQuery(PREGNANCY_TIME, {
        fetchPolicy: 'network-only',
    });
    const [creditPayrollLifeInsuranceMutation, { loading }] = useMutation(EDIT_CREDIT_PAYROLL_LIFE_INSURANCE)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({ creditPayrollLifeInsurance: filterKeyObject(dataUser?.creditPayrollLifeInsurance, ['__typename']) })
        setErrorForm({})
    }, [dataUser])
    useEffect(() => {
        setValues1({
            creditPayrollLifeInsurance: {
                ...filterKeyObject(values?.creditPayrollLifeInsurance, ['__typename']),
                cpliCancer: values?.creditPayrollLifeInsurance?.cpliCancer === 1 ? values?.creditPayrollLifeInsurance?.cpliCancer : 2,
                cpliVIH: values?.creditPayrollLifeInsurance?.cpliVIH === 1 ? values?.creditPayrollLifeInsurance?.cpliVIH : 2,
                cpliStroke: values?.creditPayrollLifeInsurance?.cpliStroke === 1 ? values?.creditPayrollLifeInsurance?.cpliStroke : 2,
                cpliArtHyp: values?.creditPayrollLifeInsurance?.cpliArtHyp === 1 ? values?.creditPayrollLifeInsurance?.cpliArtHyp : 2,
                cpliDiabetes: values?.creditPayrollLifeInsurance?.cpliDiabetes === 1 ? values?.creditPayrollLifeInsurance?.cpliDiabetes : 2,
                cpliCorArtDis: values?.creditPayrollLifeInsurance?.cpliCorArtDis === 1 ? values?.creditPayrollLifeInsurance?.cpliCorArtDis : 2,
                cpliHeaAtt: values?.creditPayrollLifeInsurance?.cpliHeaAtt === 1 ? values?.creditPayrollLifeInsurance?.cpliHeaAtt : 2,
                cpliHasConAcqPhyMenLim: values?.creditPayrollLifeInsurance?.cpliHasConAcqPhyMenLim === 1 ? values?.creditPayrollLifeInsurance?.cpliHasConAcqPhyMenLim : 2,
                cpliDiaTreDisOthThoMenAbo: values?.creditPayrollLifeInsurance?.cpliDiaTreDisOthThoMenAbo === 1 ? values?.creditPayrollLifeInsurance?.cpliDiaTreDisOthThoMenAbo : 2,
                cpliIsPre: values?.creditPayrollLifeInsurance?.cpliIsPre === 1 ? values?.creditPayrollLifeInsurance?.cpliIsPre : 2,
                cpliPrimitive: values?.creditPayrollLifeInsurance?.cpliIsPre === 1 ? values?.creditPayrollLifeInsurance?.cpliPrimitive : 2,
                ptId: values?.creditPayrollLifeInsurance?.cpliIsPre === 1 ? values?.creditPayrollLifeInsurance?.ptId : null,
                cpliDisabilityPensioner: values?.creditPayrollLifeInsurance?.cpliDisabilityPensioner === 1 ? values?.creditPayrollLifeInsurance?.cpliDisabilityPensioner : 2,
                cpliUseDrugsAlcohol: values?.creditPayrollLifeInsurance?.cpliUseDrugsAlcohol === 1 ? values?.creditPayrollLifeInsurance?.cpliUseDrugsAlcohol : 2,
                cpliTreatment: values?.creditPayrollLifeInsurance?.cpliTreatment === null ? 'NINGUNO' : values?.creditPayrollLifeInsurance?.cpliTreatment,
                cpliActSta: values?.creditPayrollLifeInsurance?.cpliActSta === null ? 'NINGUNO' : values?.creditPayrollLifeInsurance?.cpliActSta,
                cpliDiseaseName: values?.creditPayrollLifeInsurance?.cpliDiseaseName === null ? 'NINGUNO' : values?.creditPayrollLifeInsurance?.cpliDiseaseName,
                cpliConsequences: values?.creditPayrollLifeInsurance?.cpliConsequences === null ? 'NINGUNO' : values?.creditPayrollLifeInsurance?.cpliConsequences,
                cpliObservations: values?.creditPayrollLifeInsurance?.cpliObservations === null ? 'NINGUNO' : values?.creditPayrollLifeInsurance?.cpliObservations,
                cpliCurrentTreatment: values?.creditPayrollLifeInsurance?.cpliCurrentTreatment === null ? 'NINGUNO' : values?.creditPayrollLifeInsurance?.cpliCurrentTreatment,
                cpliTreatmentOrSurgery: values?.creditPayrollLifeInsurance?.cpliTreatmentOrSurgery === null ? 'NINGUNO' : values?.creditPayrollLifeInsurance?.cpliTreatmentOrSurgery,
            }
        })
    }, [values])
    // Cambio en los valores
    const handleChange = e => {
        setValues({ creditPayrollLifeInsurance: { ...values.creditPayrollLifeInsurance, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await creditPayrollLifeInsuranceMutation({
            variables: { input: values1?.creditPayrollLifeInsurance }, update(cache, { data: { setOrUpdateCreditPayrollLifeInsurance } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollLifeInsurance })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdateCreditPayrollLifeInsurance) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(5)
        }
    }

    return (
        <LifeInsuranceDataRegisterV
            data={{ values, errors, format, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            pregnacyTimes={pregnacyTimes?.pregnancyTimes}
        />
    )
}

LifeInsuranceDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    refScroll: PropTypes.object
}