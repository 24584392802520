import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { LostPassV } from '../../components/LostPass'
import { MethodC } from '../../containers/LostPass/Method'
import { ChangePassC } from '../../containers/LostPass/ChangePass'
import { InfoCodeC } from '../../containers/LostPass/InfoCode'

export const LostPass = ({ navigation }) => {
    const [typeInfo, setTypeInfo] = useState(1)
    const [method, setMethod] = useState(1)
    const [user, setUser] = useState(null)
    const refScroll = useRef()

    const infoCredit = async () => {
        setTypeInfo(1)
    }

    useEffect(() => {
        navigation.addListener('focus', () => infoCredit())
    }, [])

    return (
        <LostPassV navigation={navigation} refs={refScroll}>
            {typeInfo === 1 ?
                <MethodC
                    handleNext={value => setTypeInfo(value)}
                    handleMethod={value => setMethod(value)}
                />
                :
                typeInfo === 2 ?
                    <InfoCodeC
                        method={method}
                        handleUser={values => setUser(values)}
                        handleNext={value => setTypeInfo(value)}
                    />
                    :
                    typeInfo === 3 ?
                        <ChangePassC
                            user={user}
                            navigation={navigation}
                        />
                        :
                        <></>
            }
        </LostPassV>
    )
}

LostPass.propTypes = {
    navigation: PropTypes.object
}