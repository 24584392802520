// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { FlatList } from 'react-native-gesture-handler'
import { AntDesign, MaterialIcons } from '@expo/vector-icons'

// Styles and Utils
import { mq } from '../../validation'
import { ShadowBox } from '../../generalStyles'
import { Container, ViewCredit, Title, Footer, Button, HeaderContainer } from './styled'
import { HomeS } from '../../skeleton/HomeS'
import { View } from 'react-native-web'

/**
 * Credit Types Component
 * @returns {ReactElement} Component Of Credit Types
 */
export const CreditTypes = ({
    data: {
        list,
        theme,
        loading,
        applyCredit,
        credits,
        // onCreditLine,
    }
}) => {

    const newList = list?.filter(x => !credits?.find(y => x?.ctName === y?.cName && y?.cPending === 1))

    return (
        <Container>
            {/** Header */}
            <View style={{
                padding: '14px',
                margin: '0 15%' }}>
                {/* <Button
                    style={{ width: '30px' }}
                    bgColor='none'
                    onPress={() => onCreditLine()}
                >
                    <AntDesign name="left" size={mq(20)} color={theme.PColor} />
                </Button> */}
                <HeaderContainer>
                    <Title paddingBottom='0' size={mq(22)} fontFamily='PFontBold'>Líneas de Crédito</Title>
                    <Title textAlign='center'>Solicita tu crédito en 15 minutos.</Title>
                </HeaderContainer>
            </View>
            {/** Content, flat list */}
            {loading ?
                <HomeS style={{ alignSelf: 'center' }} />
                :
                !newList?.length ?
                    <View style={{
                        padding: '14px',
                        marginTop: '50%' }}>
                        <Title title textAlign='center'>No se encontraron líneas de crédito</Title>
                    </View>
                    :
                    <FlatList
                        data={newList}
                        keyExtractor={item => item.ctId}
                        showsVerticalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                            <ViewCredit style={ShadowBox}>
                                <Title
                                    size={mq(15)}
                                    color={theme.PColor}
                                    fontFamily='PFontBold'
                                >
                                    {item.ctName}
                                </Title>
                                <Title>{item.ctDescription}</Title>
                                <Footer>
                                    <Button
                                        bgColor={theme.PColor}
                                        testID={`EkIlYXl856LK6Itp7gdK7${index}`}
                                        onPress={() => applyCredit(item)}
                                    >
                                        <AntDesign name="creditcard" size={mq(14)} color={theme.BGColor} />
                                        <Title
                                            size={mq(10)}
                                            paddingBottom='0'
                                            paddingLeft={mq(8)}
                                            color={theme.BGColor}
                                        >
                                    SOLICITA TU CRÉDITO
                                        </Title>
                                    </Button>
                                    {!!item.ctCatalogue &&
                                <Button bgColor={theme.BGColor}>
                                    <Title
                                        size={mq(12)}
                                        paddingBottom='0'
                                        paddingLeft={mq(8)}
                                        color={theme.SFColor}
                                    >
                                        ver catálogos
                                    </Title>
                                    <MaterialIcons name="keyboard-arrow-right" size={mq(16)} color={theme.SFColor} />
                                </Button>
                                    }
                                </Footer>
                            </ViewCredit>
                        )}
                    />
            }
        </Container>
    )}

CreditTypes.propTypes = {
    data: PropTypes.object.isRequired
}