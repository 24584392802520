import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { wp } from '../validation'

export const FormCreditS = props => (
    <ContentLoader
        speed={1}
        width={wp(90)}
        height={500}
        viewBox='0 0 350 500'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
    >
        <Rect x='10' y='75' rx='10' ry='10' width='330' height='30' />
        <Rect x='20' y='65' rx='5' ry='5' width='120' height='5' />
        <Rect x='80' y='470' rx='15' ry='15' width='200' height='30' />
        <Rect x='10' y='20' rx='0' ry='0' width='330' height='35' />
        <Rect x='10' y='190' rx='10' ry='10' width='330' height='30' />
        <Rect x='20' y='180' rx='5' ry='5' width='130' height='5' />
        <Rect x='10' y='130' rx='10' ry='10' width='160' height='30' />
        <Rect x='20' y='120' rx='5' ry='5' width='130' height='5' />
        <Rect x='180' y='130' rx='10' ry='10' width='160' height='30' />
        <Rect x='190' y='120' rx='5' ry='5' width='130' height='5' />
        <Rect x='10' y='250' rx='10' ry='10' width='160' height='30' />
        <Rect x='19' y='240' rx='5' ry='5' width='100' height='5' />
        <Rect x='180' y='250' rx='10' ry='10' width='160' height='30' />
        <Rect x='190' y='240' rx='5' ry='5' width='130' height='5' />
        <Rect x='10' y='310' rx='10' ry='10' width='160' height='30' />
        <Rect x='20' y='300' rx='5' ry='5' width='100' height='5' />
        <Rect x='180' y='310' rx='10' ry='10' width='160' height='30' />
        <Rect x='190' y='300' rx='5' ry='5' width='130' height='5' />
        <Rect x='10' y='370' rx='10' ry='10' width='160' height='30' />
        <Rect x='20' y='359' rx='5' ry='5' width='140' height='5' />
        <Rect x='180' y='370' rx='10' ry='10' width='160' height='30' />
        <Rect x='190' y='360' rx='5' ry='5' width='140' height='5' />
        <Rect x='10' y='430' rx='10' ry='10' width='330' height='30' />
        <Rect x='19' y='420' rx='5' ry='5' width='90' height='5' />
        <Rect x='10' y='4' rx='40' ry='40' width='330' height='30' />
    </ContentLoader>
)