
import styled, { css } from 'styled-components/native'
import Constants from 'expo-constants'
import { mq } from '../../validation'

export const Container = styled.View`
    flex: 1;
    position: relative;
    background-color: ${props => props.theme.BGSColor};
`
// export const BoxBackground = styled.View`
//     background-color: ${variantPrimaryC};
//     position: absolute;
//     top: 0;
//     height: ${hp(60)}px;
//     width: ${wp(100)}px;
//     border-bottom-left-radius: ${wp(7.5)}px;
//     border-bottom-right-radius: ${wp(7.5)}px;
//     z-index: -1;
// `
export const Box = styled.View`
    align-items: center;
    padding: ${mq(4)}px ${mq(12)}px 0 ${mq(12)}px;
    flex-direction: ${({ direction }) => direction || 'column'};
`
export const BoxIcon = styled.View`
    width: ${({ width }) => width ? `${width}px` : '100%'};
    height: ${({ height }) => height ? height : mq(40)}px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    background-color: ${({ bgColor }) => bgColor};
    ${({ border, theme }) => border && css`border: 1px solid ${theme.PColor};`}
`
export const BoxContainer = styled.View`
    flex-direction: 'columns';
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 10px 0;
`
export const BoxContainerInputHook = styled.View`
    flex-direction: 'columns';
    align-items: center;
    width: 50%;
`
export const BoxDescription = styled.View`
    align-items: center;
    flex: 1;
`
export const HeaderContainer = styled.View`
    align-items: center;
    margin: 0 15%;
`
export const HeadCard = styled.View`
    flex-direction: row;
    background-color: ${props => props.theme.BGColor};
    border: 1px solid ${props => props.theme.PColor};
    border-radius: ${mq(16)}px;
    padding: ${mq(10)}px ${mq(20)}px;
    margin: ${mq(18)}px 0;
    width: 90%;
    max-width: 768px;
    align-self: center;
`
export const Title = styled.Text`
    font-size: ${({ size }) => size || mq(12)}px;
    font-family: ${({ fontFamily }) => fontFamily || 'PFontMedium'};
    text-align: ${({ textAlign }) => textAlign || 'left'};
    padding: 0 ${mq(2)}px;
    padding-bottom: ${({ paddingBottom }) => paddingBottom || mq(10)}px;
    padding-left: ${({ paddingLeft }) => paddingLeft || 0}px;
    color: ${({ color, theme }) => color || theme.SFColor};
`
export const Button = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${mq(5)}px ${mq(8)}px;
    color: ${({ color, theme }) => color || theme.SFColor};
    background-color: ${({ bgColor, theme }) => bgColor || theme.PColor};
    border-radius: ${mq(8)}px;
`
// export const SubTitle = styled.Text`
//     font-size: ${wp(3)}px;
//     padding: 0 ${wp(2)}px;
//     color: ${backgroundC};
//     text-align: left;
//     font-family: PFontRegular;
// `
export const Body = styled.View`
    border-radius: ${mq(17)}px;
    background-color: ${props => props.theme.BGColor};
    width: 90%;
    padding: ${mq(15)}px ${mq(10)}px ${mq(2)}px ${mq(10)}px;
    margin-bottom: ${mq(10)}px;
    position: relative;
    max-width: 768px;
`
export const Label = styled.Text`
    font-size: ${({ size }) => size || mq(13)}px;
    color: ${({ color, theme }) => color || theme.PColor};
    font-family: ${props => props.fFamily || 'PFontBold'};
    ${({ align }) => align && css`text-align: ${align};`}
`

export const ButtonMount = styled.TouchableOpacity`
    font-size: ${({ size }) => size || mq(13)}px;
    color: ${({ color, theme }) => color || theme.PColor};
    font-family: ${props => props.fFamily || 'PFontBold'};
    ${({ align }) => align && css`text-align: ${align};`}
    outline: none;
    border: none;
`

export const BoxInput = styled.View`
    border-bottom-color: ${props => props.theme.SFSColor};
    border-bottom-width: 1px;
    margin-top: ${mq(1)}px;
    padding: ${mq(2)}px;
`
export const BoxInfo = styled.View`
    width: 85%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    align-self: center;
    border-radius: ${mq(6)}px;
    background-color: ${props => props.theme.BGColor};
    margin: ${({ mrg }) => mrg || `${mq(4)}px 0 ${mq(6)}px 0`};
    padding: ${mq(8)}px;
    ${({ justify }) => justify && css`justify-content: ${justify};`}
`
export const Center = styled.View`
    align-items: center;
`
export const CheckCredit = styled.Text`
    font-size: 13px;
    max-width: 150px;
    text-align: center;
    font-family: PFontBold;
    color: rgb(0, 149, 135);
`
export const CheckInputBox = styled.View`
    text-align: center;
    align-items: center;
    justify-content: center;
`

export const TextFooter = styled.Text`
    color: ${props => props.theme.SFColor};
    font-family: PFontRegular;
    font-size: ${mq(13)}px;
    margin: 0 ${mq(8)}px;
`
export const TextFee = styled.Text`
    font-size: ${mq(16)}px;
    padding-bottom: ${mq(4)}px;
    color: ${props => props.theme.SFColor};
`
export const styles = {
    slider: {
        height: mq(30),
        marginTop: mq(2),
        marginHorizontal: Constants.platform.android ? -mq(5) : 0
    },
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: mq(2)
        },
        shadowOpacity: 0.12,
        shadowRadius: 8.5,
        elevation: 7,
    },
}

// styled modal card
export const ContainerCard = styled.View`
    height: 60%;
    background-color: ${props => props.theme.BGColor};
    margin: auto ${mq(10)}px;
    border-radius: ${mq(10)}px;
    padding-bottom: ${mq(6)}px;
`
export const BoxHead = styled.View`
    flex-direction: row;
    justify-content: space-between;
    background-color: ${props => props.theme.PColor};
    padding: ${mq(8)}px ${mq(4)}px;
    border-top-right-radius: ${mq(10)}px;
    border-top-left-radius: ${mq(10)}px;
`
export const TitleHead = styled.Text`
    font-size: ${mq(10)}px;
    font-family: PFontBold;
    color: ${props => props.theme.BGColor};
`
export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
`
export const BoxInputs = styled.View`
    width: 90%;
`
export const ButtonIcon = styled.TouchableOpacity`
    justify-content: flex-end;
    align-items: flex-end;
`
export const Card = styled.View`
    margin: ${mq(3)}px;
    padding: ${mq(2)}px ${mq(5)}px;
`
export const BorderBottom = styled.View`
    height: 1px;
    background-color: ${props => props.theme.PColor};
    width: ${mq(140)}px;
    justify-content: center;
    align-items: center;
`

// estilo de modal alert
export const ContainerAlert = styled.View`
    height: ${mq(150)}px;
    background-color: ${props => props.theme.BGPColor};
    margin: auto ${mq(10)}px;
    padding: ${mq(8)}px;
    border-radius: ${mq(6)}px;
`
export const TitleAlert = styled.Text`
    font-size: ${mq(15)}px;
    color: ${props => props.theme.PColor};
    font-family: PFontBold;
    text-align: center;
    margin: ${mq(4)}px;
`
export const SubTitleAlert = styled.Text`
    font-size: ${mq(13)}px;
    color: ${props => props.theme.SFColor};
    margin-top: ${mq(4)}px;
    margin-bottom: ${mq(10)}px;
    font-family: PFontRegular;
    text-align: center;
`