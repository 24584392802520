import React from 'react'
import PropTypes from 'prop-types'
import { BoxInput, Label, styles } from './styled'
import { mq, numberFormat } from '../../validation'
import Slider from '@react-native-community/slider'
import { InputHook } from '../InputHook'

export const ViewSlider = ({ values, onChangeMoney, theme, handleCalculator }) => (
    <>
        <BoxInput>
            <Label
                fFamily='PFontMedium'
                color={theme.SFSColor}
                size={mq(13)}
            >
                $ {numberFormat(values.vcDefVal >= 0 ? values.vcDefVal : 500000)} COP
            </Label>
        </BoxInput>
        <Slider
            onSlidingComplete={e => handleCalculator({ months: values?.mDefVal, money: e })}
            step={10000}
            style={styles.slider}
            onValueChange={onChangeMoney}
            thumbTintColor={theme.PColor}
            value={values.vcDefVal || 500000}
            minimumTrackTintColor={theme.PColor}
            maximumTrackTintColor={theme.SFVColor}
            maximumValue={values.ctMaxCap || 1000000}
            testID='cdbcf5e2-a9d8-47dc-acab-5d96db77f1f8'
            minimumValue={values.ctMinCap >= 0 ? values.ctMinCap : 1}
        />
    </>
)

ViewSlider.propTypes = {
    values: PropTypes.object.isRequired,
    onChangeMoney: PropTypes.func.isRequired,
    theme: PropTypes.any,
    handleCalculator: PropTypes.func,
}

export const ViewInput = ({ values, onChangeMoney, theme, handleCalculator }) => (
    <>
        <BoxInput>
            <Label
                fFamily='PFontMedium'
                color={theme.SFSColor}
                size={mq(13)}
            >
                $ {numberFormat(values.vcDefVal >= 0 ? values.vcDefVal : 500000)} COP
            </Label>
        </BoxInput>
        <InputHook title='Monto a Solicitar' keyboardType='numeric' value={values?.vcDefVal} onBlur={() => handleCalculator({ months: values?.mDefVal, money: values?.vcDefVal })} name='vcDefVal' maximum={values.ctMaxCap || 1000000} minimum={values.ctMinCap >= 0 ? values.ctMinCap : 1} numeric format range={[1, 15]} onChangeText={onChangeMoney} />
    </>
)

ViewInput.propTypes = {
    values: PropTypes.object.isRequired,
    onChangeMoney: PropTypes.func.isRequired,
    theme: PropTypes.any,
    handleCalculator: PropTypes.func,
}