import styled from 'styled-components/native'
import { mq } from '../../validation'

export const Container = styled.View`
    margin: auto;
    width: 96%;
    padding: ${mq(12)}px ${mq(14)}px;
    margin-bottom: ${mq(14)};
    border-radius:${mq(15)}px;
    align-self: center;
    background-color: ${props => props.theme.BGColor};
    max-width: 768px;
`
export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${mq(2)}px 0;
`
export const Title = styled.Text`
    font-size: ${({ size }) => size || mq(13.5)}px;
    font-family: PFontBold;
    color: ${({ color, theme }) => color || theme.SFColor};
`
export const SubTitle = styled.Text`
    color: ${({ color, theme }) => color || theme.SFColor};
    font-family: ${({ family }) => family || 'PFontRegular'};
    font-size: ${({ size }) => size || mq(13.2)}px;
`
// export const ViewIcon = styled.View`
//     background-color: ${grayLight};
//     border-radius: ${mq(12)}px;
//     padding: ${mq(11.5)}px;
// `
// export const IconBox = styled.View`
//     height: ${mq(18)}px;
//     width: ${mq(18)}px;
//     justify-content: center;
//     align-items: center;
//     border-radius: ${mq(115)}px;
//     background-color: ${props => props.theme.PColor};
// `
// Texto
export const TextEye = styled.Text`
    font-size: ${mq(13.5)}px;
    font-family: PFontBold;
    padding: 0 ${mq(12)}px;
    color: ${props => props.theme.SFColor};
`
export const BtnContent = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
`