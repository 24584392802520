import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, Row } from './styled'
import { View } from 'react-native-web'
import { SelectHook } from '../SelectHook'

export const OtherInformationDataRegisterV = ({ data: { banner, errors, loading, values }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            <Row>
                <InputHook flex={100} title='Número afiliación:' name='cpoiAffiliationNumber' error={errors.cpoiAffiliationNumber} range={[1, 12]} value={values?.creditPayrollOtherInformation?.cpoiAffiliationNumber} numeric onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <View style={{ paddingTop: '2px' }}>
                <SelectHook
                    required
                    nameAtt='title'
                    name='cpoiUsNationality'
                    title='¿Cómo persona natural, posee usted nacionalidad estadounidense?'
                    titleModal='Seleccionar Opción'
                    value={values?.creditPayrollOtherInformation?.cpoiUsNationality}
                    error={errors.cpoiUsNationality}
                    handleSelect={e => handleChange(e)}
                    data={[{ cpoiUsNationality: 1, title: 'SI' }, { cpoiUsNationality: 2, title: 'NO' }]}
                />
            </View>
            <SelectHook
                required
                nameAtt='title'
                name='cpoiUsAccount'
                title='¿Usted transfiere o da instrucciones permanentes para transferir fondos a una cuenta de estados unidos?'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiUsAccount}
                error={errors.cpoiUsAccount}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiUsAccount: 1, title: 'SI' }, { cpoiUsAccount: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiUsInvestmentAssets'
                title='¿Del 50% o más de sus activos totales y/ o los ingresos, provienen de activos de inversión en estados unidos?'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiUsInvestmentAssets}
                error={errors.cpoiUsInvestmentAssets}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiUsInvestmentAssets: 1, title: 'SI' }, { cpoiUsInvestmentAssets: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiAmericanGreenCard'
                title='¿Usted posee tarjeta de residencia americana (Green Card)?'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiAmericanGreenCard}
                error={errors.cpoiAmericanGreenCard}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiAmericanGreenCard: 1, title: 'SI' }, { cpoiAmericanGreenCard: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiBornUS'
                title='Nació en estados unidos'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiBornUS}
                error={errors.cpoiBornUS}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiBornUS: 1, title: 'SI' }, { cpoiBornUS: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiLivedUS'
                title='Vivió las 2/3 partes del año anterior en estados unidos'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiLivedUS}
                error={errors.cpoiLivedUS}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiLivedUS: 1, title: 'SI' }, { cpoiLivedUS: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiForeignCurrency'
                title='Realiza transacciones en moneda extranjera'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiForeignCurrency}
                error={errors.cpoiForeignCurrency}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiForeignCurrency: 1, title: 'SI' }, { cpoiForeignCurrency: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiActivity'
                title='Realiza o ejerce las siguientes actividades'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiActivity}
                error={errors.cpoiActivity}
                handleSelect={e => handleChange(e)}
                data={[
                    { cpoiActivity: 'IMPORTACIONES', title: 'IMPORTACIONES' },
                    { cpoiActivity: 'EXPORTACIONES', title: 'EXPORTACIONES' },
                    { cpoiActivity: 'INVERSIONES', title: 'INVERSIONES' },
                    { cpoiActivity: 'TRANSFERENCIAS', title: 'TRANSFERENCIAS' },
                    {
                        cpoiActivity: 'PRÉSTAMOS EN MONEDA EXTRANJERA',
                        title: 'PRÉSTAMOS EN MONEDA EXTRANJERA',
                    },
                    { cpoiActivity: 'PAGOS DE SERVICIOS', title: 'PAGOS DE SERVICIOS' },
                    { cpoiActivity: 'OTROS', title: 'OTROS' },
                    { cpoiActivity: 'NINGUNO', title: 'NINGUNO' },
                ]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiTaxObligations'
                title='Es sujeto de obligaciones tributarias en otro país'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiTaxObligations}
                error={errors.cpoiTaxObligations}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiTaxObligations: 1, title: 'SI' }, { cpoiTaxObligations: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiBiddingActivities'
                title='Profesión u oficio es lícita'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiBiddingActivities}
                error={errors.cpoiBiddingActivities}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiBiddingActivities: 1, title: 'SI' }, { cpoiBiddingActivities: 2, title: 'NO' }]}
            />
            {values?.creditPayrollOtherInformation?.cpoiBiddingActivities === 1 &&
                <Row>
                    <InputHook flex={100} title='Especifique profesión:' name='cpoiInfoBiddingActivities' error={errors.cpoiInfoBiddingActivities} value={values?.creditPayrollOtherInformation?.cpoiInfoBiddingActivities} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
                </Row>
            }
            <SelectHook
                required
                nameAtt='title'
                name='cpoiAcceptInformation'
                title='La información que he suministrado en este formato es veraz y verificable'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiAcceptInformation}
                error={errors.cpoiAcceptInformation}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiAcceptInformation: 1, title: 'SI' }, { cpoiAcceptInformation: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiBreachContract'
                title='El incumplimiento de la obligación establecida faculta a la Cooperativa para revocar unilateralmente el contrato.'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiBreachContract}
                error={errors.cpoiBreachContract}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiBreachContract: 1, title: 'SI' }, { cpoiBreachContract: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiCriminallyDeclared'
                title='Manifiesto que no he sido declarado responsable judicialmente por la comisión de delitos'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiCriminallyDeclared}
                error={errors.cpoiCriminallyDeclared}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiCriminallyDeclared: 1, title: 'SI' }, { cpoiCriminallyDeclared: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiTruthfulStatements'
                title='Las declaraciones contenidas en estos documentos son exactas, completas y verídicas'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiTruthfulStatements}
                error={errors.cpoiTruthfulStatements}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiTruthfulStatements: 1, title: 'SI' }, { cpoiTruthfulStatements: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpoiAcceptDocument'
                title='Manifiesto de manera voluntaria haber leído y entendido todo lo contenido en este documento'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiAcceptDocument}
                error={errors.cpoiAcceptDocument}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiAcceptDocument: 1, title: 'SI' }, { cpoiAcceptDocument: 2, title: 'NO' }]}
            />
            <Row>
                <InputHook flex={100} title='Solicitud incremento del valor asegurado a:' name='cpoiInsuredValue' error={errors.cpoiInsuredValue} value={values?.creditPayrollOtherInformation?.cpoiInsuredValue} numeric onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook flex={100} title='Valor de prima' name='cpoiPremiumValue' error={errors.cpoiPremiumValue} value={values?.creditPayrollOtherInformation?.cpoiPremiumValue} numeric onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <SelectHook
                required
                nameAtt='title'
                name='cpoiDangerHisLife'
                title='¿En alguna ocasión ha estado en peligro su vida?'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiDangerHisLife}
                error={errors.cpoiDangerHisLife}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiDangerHisLife: 1, title: 'SI' }, { cpoiDangerHisLife: 2, title: 'NO' }]}
            />
            {values?.creditPayrollOtherInformation?.cpoiDangerHisLife === 1 &&
                <Row>
                    <InputHook flex={100} title='Especifique el peligro ocasionado:' name='cpoiInfoDangerHisLife' error={errors.cpoiInfoDangerHisLife} value={values?.creditPayrollOtherInformation?.cpoiInfoDangerHisLife} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
                </Row>
            }
            <SelectHook
                required
                nameAtt='title'
                name='cpoiTakesMedicine'
                title='¿Consume actualmente algún medicamento?'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiTakesMedicine}
                error={errors.cpoiTakesMedicine}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiTakesMedicine: 1, title: 'SI' }, { cpoiTakesMedicine: 2, title: 'NO' }]}
            />
            {values?.creditPayrollOtherInformation?.cpoiTakesMedicine === 1 &&
                <Row>
                    <InputHook flex={100} title='Especifique medicamentos:' name='cpoiInfoTakesMedicine' error={errors.cpoiInfoTakesMedicine} value={values?.creditPayrollOtherInformation?.cpoiInfoTakesMedicine} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
                </Row>
            }
            <Row>
                <InputHook flex={100} title='EPS afiliado:' name='cpoiEPS' error={errors.cpoiEPS} value={values?.creditPayrollOtherInformation?.cpoiEPS} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook flex={100} title='IPS afiliado:' name='cpoiIPS' error={errors.cpoiIPS} value={values?.creditPayrollOtherInformation?.cpoiIPS} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <SelectHook
                required
                nameAtt='title'
                name='cpoiTakeDrug'
                title='Fuma o consume sustancias alucinógenas'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollOtherInformation?.cpoiTakeDrug}
                error={errors.cpoiTakeDrug}
                handleSelect={e => handleChange(e)}
                data={[{ cpoiTakeDrug: 1, title: 'SI' }, { cpoiTakeDrug: 2, title: 'NO' }]}
            />
            {values?.creditPayrollOtherInformation?.cpoiTakeDrug === 1 &&
                <Row>
                    <InputHook flex={100} title='Especifique:' name='cpoiInfoTakeDrug' error={errors.cpoiInfoTakeDrug} value={values?.creditPayrollOtherInformation?.cpoiInfoTakeDrug} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
                </Row>
            }
            <BoxButton>
                <ButtonSubmit testID='181ec7bf-bafd-4301-b0e3-9977b42b77e4' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

OtherInformationDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    userParameters: PropTypes.object,
}