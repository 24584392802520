import React from 'react'
import PropTypes from 'prop-types'
import { FlatList } from 'react-native'
import { Entypo } from '@expo/vector-icons'
import { ModalCamera } from '../ModalCamera'
import { ButtonSubmit } from '../ButtonSubmit'
import { InputHook } from '../InputHook'
import { numberFormat, wp } from '../../validation'
import theme from '../../../ignore/Constants'
import { Container, BoxHead, Title, Card, Row, BoxInputs, ButtonIcon, styles } from './styled'

export const DocumentCardV = ({ data: { isVisibleCamera, navigation, credit }, handleClose, handleConfirm, handleDocument }) => (
    <Container>
        <ModalCamera isVisible={isVisibleCamera} navigation={navigation} data={{ screen: 'DocumentCard' }} handleClose={handleClose} />
        <BoxHead>
            <Title>Documentos Cartera</Title>
        </BoxHead>
        <FlatList
            data={credit.creditCards || []}
            showsVerticalScrollIndicator={false}
            keyExtractor={item => item.ccId}
            ListFooterComponent={<>
                <ButtonSubmit onPress={handleConfirm} title='Guardar' />
            </>}
            renderItem={({ item }) => (
                <Card style={styles.shadow}>
                    <Row>
                        <BoxInputs>
                            <InputHook title='Empresa' editable={false} value={item?.ccName} />
                        </BoxInputs>
                    </Row>
                    <Row>
                        <BoxInputs>
                            <Row>
                                <InputHook flex={.47} title='Cuota' editable={false} value={numberFormat(item?.ccFee)} />
                                <InputHook flex={.47} title='Saldo' editable={false} value={numberFormat(item?.ccBalance)} />
                            </Row>
                        </BoxInputs>
                        <ButtonIcon onPress={() => handleDocument(item)}>
                            <Entypo name='upload-to-cloud' size={wp(10)} color={item.file ? theme.primaryC : theme.onSurfaceC} />
                        </ButtonIcon>
                    </Row>
                </Card>
            )}
        />
    </Container>
)

DocumentCardV.propTypes = {
    data: PropTypes.object,
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleDocument: PropTypes.func
}