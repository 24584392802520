import { gql } from '@apollo/client'

export const ALL_INFO_CLIENT = gql`
    query getPayrollCompanyById($state: [Int]) {
        getPayrollCompanyById(state: $state) {
            pcId
            cId
            payrollStaff {
                psId
                psTerCon
                psState
            }
            payrollWork {
                pwSalary
                pwNet
            }
            payrollCompanyCategory {
                ccId
                pccState
                companyCategory {
                    ccId
                    ccState
                    companyCategoriesCreditTypes {
                        ctId
                        ccctState
                    }
                }
            }
            companyCreditTypes {
                ctId
                creditType {
                    ctId
                    ctName
                    ctMultiple
                    ctReTanking
                    ctOptPay
                    ctGroup
                    mId
                    valueCredit {
                        vcId
                        vcMinVal
                        vcMaxVal
                        vcDefVal
                        mId
                        month {
                            mValue
                        }
                    }
                    rId
                    rate {
                        rPor
                    }
                    month {
                        mValue
                    }
                    ctMaxCap
                    ctDescription
                }
            }
        }
        credits(state: $state, order: [["cName", "ASC"]]) {
            cId
            cName
            cValCre
            cValCou
            cBalance
            cState
            cRatVal
            cModule
            cDayCou
            creditAttributeFees {
                cafAmount
            }
        }
        groupCredits {
            gcId
            gcName
            gcMinFee
            gcMaxFee
            gcMinFeeSal
            gcMaxFeeSal
            gcMinWal
            gcMaxWal
            gcSalary
            gcSeeValReq
            gcSeeValCre
            gcSeeValFee
            gcSalPam
            gcState
            groupCreditTypes {
                gctId
                ctId
                gctOrder
                gctType
                gctIntFee
                gctState
            }
        }
    }
`;

export const ALL_LINE_BY_ID = gql`
  query getGroupCreditLineDetails($gclId: ID, $state: [Int]) {
    getGroupCreditLineDetails(gclId: $gclId, state: $state) {
      ctId
      gclId
      gcldId
      gcldState
      creditType {
        ctId
        ctName
        ctMultiple
        ctReTanking
        ctOptPay
        ctGroup
        mId
        ctMaxCap
        ctMinCap
        valueCredit {
          vcId
          vcMinVal
          vcMaxVal
          vcDefVal
          mId
          month {
            mValue
          }
        }
        rId
        rate {
          rPor
        }
        month {
          mId
          mValue
        }
      }
    }
  }
`;