import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, Row } from './styled'
import { SelectHook } from '../SelectHook'
import { InputDateWeb } from '../InputDateWeb'

export const LifeInsuranceV = ({ data: { banner, errors, loading, values }, handleBlur, handleChange, handleSubmit, pregnacyTimes }) => (
    <Container>
        {banner}
        <Form>
            <SelectHook
                required
                nameAtt='title'
                name='pliCancer'
                title='Cancer'
                testID ='54fbd570-951b-11ee-8415-0d454077741b'
                titleModal='Seleccionar Opción'
                value={values?.payrollLifeInsurance?.pliCancer}
                error={errors.pliCancer}
                handleSelect={e => handleChange(e)}
                data={[{ pliCancer: 1, title: 'SI' }, { pliCancer: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliVIH'
                title='Sida'
                testID ='61ac4700-951b-11ee-9d03-13a7a1912283'
                titleModal='Seleccionar Opción'
                value={values?.payrollLifeInsurance?.pliVIH}
                error={errors.pliVIH}
                handleSelect={e => handleChange(e)}
                data={[{ pliVIH: 1, title: 'SI' }, { pliVIH: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliStroke'
                title='Accidente cerebrovascular'
                testID ='831aa658-b481-4bd7-981c-101875d5104f'
                titleModal='Seleccionar Opción'
                value={values?.payrollLifeInsurance?.pliStroke}
                error={errors.pliStroke}
                handleSelect={e => handleChange(e)}
                data={[{ pliStroke: 1, title: 'SI' }, { pliStroke: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliArtHyp'
                title='Hipertensión arterial'
                testID ='9ab15cb4-94d0-473d-af0c-6a8a0b9d2518'
                titleModal='Seleccionar Opción'
                value={values?.payrollLifeInsurance?.pliArtHyp}
                error={errors.pliArtHyp}
                handleSelect={e => handleChange(e)}
                data={[{ pliArtHyp: 1, title: 'SI' }, { pliArtHyp: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliDiabetes'
                title='Diabetes'
                testID ='6f297e70-951b-11ee-b1a3-cd39ab11661c'
                titleModal='Seleccionar Opción'
                value={values?.payrollLifeInsurance?.pliDiabetes}
                error={errors.pliDiabetes}
                handleSelect={e => handleChange(e)}
                data={[{ pliDiabetes: 1, title: 'SI' }, { pliDiabetes: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliCorArtDis'
                title='Enfermedades de las arterias coronarias'
                titleModal='Seleccionar Opción'
                testID ='54c5a5f2-d4e4-46fa-abfa-1915c19877a0'
                value={values?.payrollLifeInsurance?.pliCorArtDis}
                error={errors.pliCorArtDis}
                handleSelect={e => handleChange(e)}
                data={[{ pliCorArtDis: 1, title: 'SI' }, { pliCorArtDis: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliHeaAtt'
                title='Infarto'
                titleModal='Seleccionar Opción'
                testID ='d118c0a0-951b-11ee-9bae-2fce1fdda825'
                value={values?.payrollLifeInsurance?.pliHeaAtt}
                error={errors.pliHeaAtt}
                handleSelect={e => handleChange(e)}
                data={[{ pliHeaAtt: 1, title: 'SI' }, { pliHeaAtt: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliHasConAcqPhyMenLim'
                title='Tiene alguna limitación física o mental congénita o adquirida'
                titleModal='Seleccionar Opción'
                testID ='991cdc8c-a8ba-4fa9-b376-34455d21478a'
                value={values?.payrollLifeInsurance?.pliHasConAcqPhyMenLim}
                error={errors.pliHasConAcqPhyMenLim}
                handleSelect={e => handleChange(e)}
                data={[{ pliHasConAcqPhyMenLim: 1, title: 'SI' }, { pliHasConAcqPhyMenLim: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliDiaTreDisOthThoMenAbo'
                title='Le han diagnosticado o tratado alguna enfermedad diferente a las citadas arriba'
                titleModal='Seleccionar Opción'
                testID ='dd737110-951b-11ee-bde5-933ed14d0f52'
                value={values?.payrollLifeInsurance?.pliDiaTreDisOthThoMenAbo}
                error={errors.pliDiaTreDisOthThoMenAbo}
                handleSelect={e => handleChange(e)}
                data={[{ pliDiaTreDisOthThoMenAbo: 1, title: 'SI' }, { pliDiaTreDisOthThoMenAbo: 2, title: 'NO' }]}
            />
            <Row>
                <InputDateWeb testID='327aa03c-6426-47b5-b7b6-faab1e981e19' flex={100} title='Fecha de diagnóstico *' name='pliDatDia' error={errors.pliDatDia} value={values?.payrollLifeInsurance?.pliDatDia} onChangeText={handleChange} />
            </Row>
            <Row>
                <InputHook testID ='2524a678-651c-46a7-b851-4e139e971378' flex={100} title='Tratamiento' name='pliTreatment' error={errors.pliTreatment} value={values?.payrollLifeInsurance?.pliTreatment} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook testID ='e937d4f0-951b-11ee-9653-d93811e76659' flex={100} title='Estado actual' name='pliActSta' error={errors.pliActSta} value={values?.payrollLifeInsurance?.pliActSta} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <SelectHook
                required
                nameAtt='title'
                name='pliIsPre'
                title='Está embarazada'
                testID ='cabeee08-41d4-41fe-8f4c-8b22a8c2155a'
                titleModal='Seleccionar Opción'
                value={values?.payrollLifeInsurance?.pliIsPre}
                error={errors.pliIsPre}
                handleSelect={e => handleChange(e)}
                data={[{ pliIsPre: 1, title: 'SI' }, { pliIsPre: 2, title: 'NO' }]}
            />
            {values?.payrollLifeInsurance?.pliIsPre === 1 && (
                <>
                    <SelectHook
                        required
                        nameAtt='title'
                        name='pliPrimitive'
                        title='Es primigestante'
                        titleModal='Seleccionar Opción'
                        testID ='f88d75e0-951b-11ee-a02d-c54912905039'
                        value={values?.payrollLifeInsurance?.pliPrimitive}
                        error={errors.pliPrimitive}
                        handleSelect={e => handleChange(e)}
                        data={[{ pliPrimitive: 1, title: 'SI' }, { pliPrimitive: 2, title: 'NO' }]}
                    />
                    <SelectHook
                        required
                        nameAtt='title'
                        name='ptId'
                        title='Tiempo de gestación'
                        titleModal='Seleccionar Opción'
                        testID ='be79b900-1a73-4ee4-9659-0e60a5dc6744'
                        value={values?.payrollLifeInsurance?.ptId}
                        error={errors.ptId}
                        handleSelect={e => handleChange(e)}
                        data={pregnacyTimes?.map(x => { return { ptId: x?.ptId, title: x?.ptName } }) || []}
                    />
                </>
            )
            }
            <SelectHook
                required
                nameAtt='title'
                name='pliDisabilityPensioner'
                title='¿Eres pensionado por invalidez?'
                titleModal='Seleccionar Opción'
                testID ='02683230-951c-11ee-88bf-b3288ad2718b'
                value={values?.payrollLifeInsurance?.pliDisabilityPensioner}
                error={errors.pliDisabilityPensioner}
                handleSelect={e => handleChange(e)}
                data={[{ pliDisabilityPensioner: 1, title: 'SI' }, { pliDisabilityPensioner: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='pliUseDrugsAlcohol'
                title='¿Consume bebidas alcohólicas mas de 3 veces por semana o alguna sustancia psicoactiva?'
                titleModal='Seleccionar Opción'
                testID ='41b2b2e3-15b3-44e4-b644-9b2cc8c6813c'
                value={values?.payrollLifeInsurance?.pliUseDrugsAlcohol}
                error={errors.pliUseDrugsAlcohol}
                handleSelect={e => handleChange(e)}
                data={[{ pliUseDrugsAlcohol: 1, title: 'SI' }, { pliUseDrugsAlcohol: 2, title: 'NO' }]}
            />
            <Row>
                <InputHook testID ='0b6ab6f0-951c-11ee-a8bd-c74db45df4a9' flex={100} title='Nombre de la Enfermedad' name='pliDiseaseName' error={errors?.pliDiseaseName} value={values?.payrollLifeInsurance?.pliDiseaseName} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook testID ='7c8d3ca2-f4f6-435a-8b80-2cee24164312' flex={100} title='Secuelas o condiciones' name='pliConsequences' error={errors.pliConsequences} value={values?.payrollLifeInsurance?.pliConsequences} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook testID ='159f5310-951c-11ee-83da-294e082a5025' flex={100} title='Observaciones o comentarios adicionales' name='pliObservations' error={errors.pliObservations} value={values?.payrollLifeInsurance?.pliObservations} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook testID ='c05d5e2f-ad52-4586-976f-95d3cc3e6b0a' flex={100} title='Tratamiento Actual' name='pliCurrentTreatment' error={errors.pliCurrentTreatment} value={values?.payrollLifeInsurance?.pliCurrentTreatment} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook testID ='1d9d3910-951c-11ee-abd4-39b4afd10174' flex={100} title='Tratamientos médicos o cirugías realizadas' name='pliTreatmentOrSurgery' error={errors.pliTreatmentOrSurgery} value={values?.payrollLifeInsurance?.pliTreatmentOrSurgery} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>

            <BoxButton>
                <ButtonSubmit testID='181ec7bf-bafd-4301-b0e3-9977b42b77e4' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

LifeInsuranceV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    userParameters: PropTypes.object,
    pregnacyTimes: PropTypes.array,
}