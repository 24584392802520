import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { USE_ALL_TYPE_CONTRACTS } from '../../queries/TypeContracts'
import { SelectHook } from '../SelectHook'

export const TypeContracts = ({ testID, value, handleSelect, handleCancel, use = {}, disabled, error }) => {
    const [res, setRes] = useState([])
    const { data, loading } = USE_ALL_TYPE_CONTRACTS({ order: [['tcName', 'ASC']], ...use })

    useEffect(() => {
        if (data?.typeContracts && !loading) setRes(data?.typeContracts || [])
    }, [data])

    return (
        <SelectHook
            data={res}
            testID={testID}
            error={error}
            loading={loading}
            title='T. Contratos *'
            value={value}
            name='tcId'
            nameAtt='tcName'
            titleModal='Seleccionar Contrato'
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
        />
    )
}

TypeContracts.propTypes = {
    value: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool
}