import React from 'react'
import PropTypes from 'prop-types'
import { ImageBackground, ScrollView, Text, TouchableOpacity } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { TypeIdentities } from '../../components/Selects/TypeIdentities'
import { InputHook } from '../../components/InputHook'
import { ButtonSubmit } from '../../components/ButtonSubmit'
import { mq } from '../../validation'
import theme from '../../../ignore/Constants'
import { Container, BoxLogo, Title, BoxCheck, SubTitle, BtnContainers, BtnLogin } from './styled'
//import { Logo } from '../../../ignore/Logo'
import { ShadowBox } from '../../generalStyles'
import { Form } from '../Login/styled'
import image from '../../../assets/img/image'
//import Fondo from '../../../assets/img/fondo-login.png'

// const Fondo = image('FondoLogin')
const Logo = image('Logo')

export const RegisterUserV = ({ data: { errors, values, loading, navigation, showFooter, selectDisabled }, handleChange, handleBlur, handleSignIn, handleCheck, getDataPayrollCompanies }) => (
    <ImageBackground imageStyle={{ backgroundColor: theme.backgroundColor }} resizeMode='stretch' style={{ width: '100%', height: '100%' }}>
        <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ padding: mq(8) }}
            keyboardShouldPersistTaps='handled'
        >
            <Container>
                {/** Logo App */}
                {showFooter &&
                    <BoxLogo>
                        <Logo color={theme.primaryC} width='100%' height='100%' />
                    </BoxLogo>
                }

                {/** Button Component */}
                <BtnContainers style={!showFooter ? { marginTop: mq(50) } : {}}>
                    <BtnLogin testID='f2cd66a1-7ed5-411b-a4f1-caa4a1ffec77' onPress={() => navigation?.navigate('Login')}>
                        <Title family='PFontBold' color={theme.onSurfaceC}>Iniciar Sesión</Title>
                    </BtnLogin>
                    <BtnLogin active style={ShadowBox}>
                        <Title family='PFontBold'>Regístrate</Title>
                    </BtnLogin>
                </BtnContainers>

                {/** Formulary */}
                <Form style={[ShadowBox]}>
                    <TypeIdentities testID='88c07d04-5b6c-4fe3-99f1-225c097a8d17' disabled={selectDisabled} value={values?.tiId} error={errors?.tiId} handleSelect={handleChange} />
                    <InputHook testID='cfdfa229-cf36-4eaf-b9ab-2e04de19096c' title='Número de Identificación *' keyboardType='numeric' error={errors?.uIdeNum} value={values?.uIdeNum} name='uIdeNum' required numeric format range={[5, 15]} onBlur={getDataPayrollCompanies} onChangeText={handleChange} />
                    <InputHook testID='002d6c27-3973-411c-a5fd-fa65c9ac8908' title='Número de Teléfono *' keyboardType='numeric' error={errors?.psPhoMob} value={values?.psPhoMob} name='psPhoMob' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook testID='8b87fbc0-4ac9-4b76-9838-ece66dc3f22a' title='Correo Electrónico *' error={errors?.psEmail} value={values?.psEmail} name='psEmail' required email range={[5, 100]} onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook testID='512b42e9-9367-41f4-bff8-01411bd581f7' title='Asigna tu contraseña *' secureTextEntry error={errors?.uPass} value={values?.uPass} name='uPass' required onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook testID='9ce8a6b0-cc4e-4dd1-8707-f6a676055892' title='Confirmar Contraseña *' secureTextEntry error={errors?.conPass} value={values?.conPass} name='conPass' equalTo={{ value: values?.uPass, errorMessage: 'Las contraseñas no coinciden.' }} required onBlur={handleBlur} onChangeText={handleChange} />
                    <BoxCheck>
                        <TouchableOpacity testID='e4b84ceb-9e9e-4e0e-a3e0-29d7911fba02' onPress={handleCheck}>{values.terCon ? <MaterialIcons name='check-box' size={mq(25)} color={theme.primaryC} /> : <MaterialIcons name='check-box-outline-blank' size={mq(25)} color={theme.onSurfaceV} />}</TouchableOpacity>
                        <SubTitle>He leído todos los <Text style={{ textDecorationLine: 'underline' }} onPress={() => navigation.navigate('Terms', { register: true })}>términos y condiciones.</Text></SubTitle>
                    </BoxCheck>
                    <BoxCheck>
                        <ButtonSubmit testID='daddf932-d994-4b73-8c14-2ce97de9e4ec' onPress={handleSignIn} title='Regístrese' loading={loading} />
                    </BoxCheck>
                </Form>
            </Container>
            {/* {!Constants.platform.web && <Footer />} */}
        </ScrollView>
    </ImageBackground>
)

RegisterUserV.propTypes = {
    data: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSignIn: PropTypes.func,
    handleCheck: PropTypes.func,
    showFooter: PropTypes.bool,
    getDataPayrollCompanies: PropTypes.func,
}