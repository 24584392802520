// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import { AntDesign } from '@expo/vector-icons'

// Components
import { StatesBarHeadC } from '../../containers/Home/stateBarHead'

// Styles and Utils
// import { Context } from '../../../Context'
import { mq, numberFormat } from '../../validation'
import theme from '../../../ignore/Constants'
import { TitleHead, BoxHead, Content, BoxIcon, /* Figura */ ButtonNew, PaymentButton, Title } from './styled'
import { ShadowBox } from '../../generalStyles'
import { ActivityIndicator } from 'react-native-web'
import { selectClientName } from '../../../ignore/utils'
// import Constants from 'expo-constants'

/**
 * Head Component
 * @param {any} props Head properties
 * @returns {ReactElement} Head component
 */
export const Head = ({ navigation, data: { credits, disabledCredit, transactionStatus, dataResponseAdo, adoParameters, obligation, loadObligation }, handleEnrollment, recharge, dataUserById, payroll }) => {
    // States and Variables
    // const { balance } = useContext(Context)
    const client = selectClientName();
    return (
        <>
            <View style={{ marginTop: mq(30) }} >
                <TitleHead
                    align='center'
                    size={mq(18)}
                    family='PFontRegular'
                    color={theme.onSurfaceC}
                >
                    Saldo obligación a hoy
                </TitleHead>
                {loadObligation ?
                    <View style={{ paddingTop: '5px' }}>
                        <ActivityIndicator size="large" color={theme.primaryC} />
                    </View>
                    :
                    <TitleHead
                        align='center'
                        family='PFontMedium'
                        marginTop={mq(6)}
                        size={mq(35)}
                        color={theme.onSurfaceC}
                    >
                        $ {numberFormat(obligation || 0)}
                    </TitleHead>
                }
            </View>
            <BoxHead style={ShadowBox}>
                <Content
                    width='100%'
                >
                    <ButtonNew
                        style={{ marginVertical: mq(15) }}
                        onPress={() => navigation.navigate('Credits', { credits })}
                    >
                        <BoxIcon
                            border
                            width={mq(40)}
                            height={mq(40)}
                            bgColor={`${theme.primaryC}05`}
                            style={{ marginTop: mq(15) }}
                            testID='721b4f41-7eb4-4526-a7a6-d426ffbc0543'
                        >
                            <AntDesign
                                name='plus'
                                size={mq(30)}
                                color={theme.primaryC}
                            />
                        </BoxIcon>
                        <TitleHead color={theme.onSurfaceC} style={{ marginTop: mq(5) }}>Solicitar</TitleHead>
                    </ButtonNew>
                    <Content
                        width='82%'
                        style={{ paddingHorizontal: mq(10), marginBottom: '6px' }}
                    >
                        {(client === 'credipress' || client === 'local' || client === 'appqa' || client === 'appqa2' || client === 'lenddi' || client === 'mili') && <StatesBarHeadC dataUserById={dataUserById} active360={true} client={client} payroll={payroll}/>}
                    </Content>
                </Content>
            </BoxHead>
            <>
                {
                    adoParameters?.idAP &&
                    <>
                        {disabledCredit ?
                            <>
                                {dataResponseAdo ? <Content justify='center' direction='column'>
                                    <TitleHead
                                        align='center'
                                        size={mq(13)}
                                        color='#F15A24'
                                    >
                                        <AntDesign
                                            name='exclamationcircle'
                                            size={mq(15)}
                                            color='#F15A24'
                                            style={{ marginRight: '5px' }}
                                        />
                                        Validación de identidad con estado:
                                    </TitleHead>
                                    <TitleHead
                                        align='center'
                                        size={mq(13)}
                                        color='#F15A24'
                                        marginTop='10'
                                    >
                                        {transactionStatus?.StateName}
                                    </TitleHead>
                                    <TitleHead
                                        marginTop='10'
                                        align='center'
                                        size={mq(13)}
                                        color='#F15A24'
                                    >
                                        {
                                            transactionStatus?.IdState === '1'
                                                ? 'Su proceso de validación de identidad se encuentra en proceso, por favor espere unos minutos y consulte más tarde.'
                                                : transactionStatus?.IdState === '2' ? '' : 'Por favor, vuelva a validar su identidad.'
                                        }
                                    </TitleHead>
                                </Content> : <Content justify='center' direction='column'>
                                    <TitleHead
                                        align='center'
                                        size={mq(13)}
                                        color='#F15A24'
                                    >
                                        <AntDesign
                                            name='exclamationcircle'
                                            size={mq(15)}
                                            color='#F15A24'
                                        />
                                        Su usuario no ha sido validado, por favor valide su identidad.
                                    </TitleHead>
                                </Content>

                                }
                                <Content justify='center'>
                                    {
                                        transactionStatus?.IdState === '1' ? <PaymentButton
                                            style={{ marginVertical: mq(15) }}
                                            onPress={() => recharge()}
                                        >
                                            <Title size={mq(13)} style={{ padding: 0, color: '#ffff' }}>Consultar</Title>
                                        </PaymentButton> :
                                            transactionStatus?.IdState === '2' ? null :
                                                <PaymentButton
                                                    style={{ marginVertical: mq(15) }}
                                                    onPress={() => handleEnrollment()}
                                                >
                                                    <Title size={mq(13)} style={{ padding: 0, color: '#ffff' }}>Validar aquí</Title>
                                                </PaymentButton>
                                    }
                                </Content>
                            </> :
                            <Content justify='center' direction='column'>
                                {
                                    dataResponseAdo ? <TitleHead
                                        marginTop='10'
                                        align='center'
                                        size={mq(13)}
                                        color='#2c801d'
                                    >
                                        <AntDesign
                                            name='checkcircle'
                                            size={mq(15)}
                                            color='#2c801d'
                                            style={{ marginRight: '8px' }}
                                        />
                                        Usuario validado
                                    </TitleHead> : null
                                }
                            </Content>
                        }
                    </>
                }
            </>

        </>
    )
}

// PropTypes validations
Head.propTypes = {
    dataUserById: PropTypes.object,
    navigation: PropTypes.object,
    data: PropTypes.object,
    handleEnrollment: PropTypes.func,
    recharge: PropTypes.func,
    payroll: PropTypes.object,
}