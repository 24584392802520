import React from 'react'
import PropTypes from 'prop-types'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { IconProcessing } from '../../../assets/icons'
import { wp } from '../../validation'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Title } from './styled'
import theme from '../../../ignore/Constants'

export const RequestCreditV = ({ request, navigation }) => (
    <Container>
        <Title>CRÉDITO {request ? 'SOLICITADO' : 'EN PROGRESO'}</Title>
        {request ? <MaterialCommunityIcons name='check-all' size={wp(40.5)} color={theme.secondaryC} /> : <IconProcessing width={wp(40.5)} height={wp(40.5)} color='#FFFF00' />}
        <Title>{request ? 'Estamos trabajando para hacer tu sueño realidad.' : 'Su crédito ha sido solicitado.'}</Title>
        {request ? <ButtonSubmit title='OK' onPress={() => navigation.navigate('Home', { request: true })} bgColor={theme.secondaryC} /> : <Title color='#FFFF00'>Espere mientras se solicita el crédito.</Title>}
    </Container>
)

RequestCreditV.propTypes = {
    request: PropTypes.bool,
    navigation: PropTypes.object
}