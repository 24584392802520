import styled from 'styled-components/native'
import Constants from 'expo-constants'
import { hp, wp } from '../../validation'
import theme from '../../../ignore/Constants'
import { widthPercentageToDP } from 'react-native-responsive-screen'

export const Container = styled.View`
    flex: 1;
    width: 100%;
    background-color: ${theme.primaryC};
    margin: ${hp((Constants?.platform.web && widthPercentageToDP('100%') > 768) ? 20 : 7.5)}px auto 0;
    border-top-right-radius: ${wp(5)}px;
    border-top-left-radius: ${wp(5)}px;
    position: relative;
`
export const Cancel = styled.TouchableOpacity`
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 15px;
`
export const Title = styled.Text`
    font-size: ${wp(4.5)}px;
    text-align: center;
    color: ${theme.backgroundC};
    font-family: PFontBold;
    padding: ${wp(1.5)}px;
    margin: ${hp(1.5)}px 0 ${hp(2.5)}px;
`
export const BoxInfo = styled.View`
    border: 1px solid ${theme.backgroundC};
    width: 90%;
    margin-top: ${hp(2)}px;
    padding: ${wp(5)}px;
    border-radius: ${wp(3)}px;
`
export const Info = styled.Text`
    color: ${theme.backgroundC};
    font-family: PFontRegular;
    text-align: justify;
    font-size: ${wp(3.8)}px;
    margin-bottom: 15px;
`
export const BoxCamera = styled.View`
    align-items: center;
    justify-content: center;
    padding: 0 ${wp(8)}px ${wp(5)}px ${wp(8)}px;
`
export const ButtonCamera = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${theme.variantPrimaryC};
    border-radius: ${wp(50)}px;
    margin: ${hp(.8)}px 0;
    padding: 0;
    width: ${wp(15)}px;
    height: ${wp(15)}px;
`