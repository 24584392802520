import { gql } from '@apollo/client'

export const EDIT_CREDIT_PAYROLL = gql`
    mutation setOrUpdateCreditPayrollInfo($cpcId: ID, $inputStaff: ISetOrUpdateCreditPayrollStaff!, $inputLife: ISetOrUpdateCreditPayrollLifeInsurance!){
        setOrUpdateCreditPayrollStaff(cpcId: $cpcId, input: $inputStaff){
            cpsId
            cpcId
        }
        setOrUpdateCreditPayrollLifeInsurance(cpcId: $cpcId, input: $inputLife){
            cpliId
            cpcId
        }
    }
`

export const EDIT_PAYROLL = gql`
    mutation setOrUpdatePayrollInfo($pcId: ID, $inputStaff: ISetOrUpdatePayrollStaff!, $inputLife: ISetOrUpdatePayrollLifeInsurance!){
        setOrUpdatePayrollStaff(pcId: $pcId, input: $inputStaff){
            psId
            pcId
        }
        setOrUpdatePayrollLifeInsurance(pcId: $pcId, input: $inputLife){
            pliId
            pcId
        }
    }
`
export const ONE_CREDIT_PAYROLL_COMPANY = gql`
    query getCreditPayrollCompanyById($cId: ID) {
        getCreditPayrollCompanyById(cId: $cId) {
            cpcId
            pcId
            tiId
            typeIdentity {
                tiId
                tiName
            }
            cpcIdeNum
            creditPayrollStaff {
                cpsId
                cpsSons
                cpsMotHeaHou
                cpsWeight
                cpsHeight
            }
            creditPayrollLifeInsurance {
                cpliId
                cpliCancer
                cpliVIH
                cpliStroke
                cpliRenIns
                cpliArtHyp
                cpliDiabetes
                cpliCorArtDis
                cpliHeaAtt
                cpliHasConAcqPhyMenLim
                cpliDiaTreDisOthThoMenAbo
                cpliDatDia
                cpliTreatment
                cpliActSta
                cpliIsPre
                ptId
            }
        }
    }
`
export const ONE_CREDIT_PAYROLL_FORMAT = gql`
    query getCreditPayrollFormatById($cId: ID, $state: [Int]) {
        getCreditPayrollFormatById(cId: $cId, state: $state) {
            cpfId
            cpfSons
            cpfMotHeaHou
            cpfWeight
            cpfHeight
            cpfCancer
            cpfVIH
            cpfStroke
            cpfRenIns
            cpfArtHyp
            cpfDiabetes
            cpfCorArtDis
            cpfHeaAtt
            cpfHasConAcqPhyMenLim
            cpfDiaTreDisOthThoMenAbo
            cpfDatDia
            cpfTreatment
            cpfActSta
            cpfIsPre
            cpfPregnancy
            cpfState
        }
    }
`