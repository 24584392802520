import React from 'react'
import PropTypes from 'prop-types'
import { BoxButton, Container, InfoMessage, Text } from './styled'
import { HeadTitle } from '../HeadTitle'
import { MaterialIcons } from '@expo/vector-icons'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { wp } from '../../validation'
import theme from '../../../ignore/Constants'
import { View } from 'react-native'
import { ButtonSubmit } from '../ButtonSubmit'
import { ScrollView } from 'react-native'

export const InboxDetailsV = ({ notification, comment, credit, creditReportDocuments, handleDownload, crLink }) => {
    return (
        <Container >
            <HeadTitle color={theme.primaryC} title='DETALLE DEL MENSAJE' icon={<MaterialIcons name='message' size={wp(8)} color={theme.primaryC} />} />
            <ScrollView showsVerticalScrollIndicator={false} >
                <View style={{ width: '90%', alignSelf: 'center' }}>
                    <Text style={{ fontWeight: 'bold' }}>CRÉDITO</Text>
                    <InfoMessage>{credit?.cConsecutive} - {credit?.cName}</InfoMessage>
                    <Text style={{ fontWeight: 'bold' }}>NOTIFICACIÓN</Text>
                    <InfoMessage>{notification}</InfoMessage>
                    <Text style={{ fontWeight: 'bold' }}>COMENTARIO</Text>
                    <InfoMessage>{comment}</InfoMessage>
                    { crLink ? <ButtonSubmit margin='20px auto' onPress={() => window.open(crLink)} title='Validar Identidad' /> : null }
                </View>
                {
                    (creditReportDocuments?.length >= 1) &&
                    <View style={{ width: '90%', alignSelf: 'center' }}>
                        <Text style={{ fontWeight: 'bold' }}>DOCUMENTOS ADJUNTOS</Text>
                        {
                            creditReportDocuments?.map(x => {
                                return (
                                    <View key={x?.crId} style={{ width: '100%', alignSelf: 'center' }}>
                                        <a
                                            id={`downloadFile${x?.crdId}`}
                                            target="_blank"
                                            href="downloadFile"
                                            download
                                            style={{ display: 'none' }}>
                                            downloadFile
                                        </a>
                                        <BoxButton style={{ width: '100%', alignItems: 'center' }}>
                                            <ButtonSubmit radius='none' color='none' width='15px' bgColor='none' onPress={() => handleDownload(x?.crdId, x?.crdFileName)} title={<MaterialCommunityIcons name="file-download" size={70} color={theme.primaryC} />} />
                                            <Text style={{ fontWeight: 'normal', padding: 'none' }}>{x?.crdFileName}</Text>
                                        </BoxButton>
                                    </View>
                                )
                            })
                        }
                    </View>
                }

            </ScrollView>
        </Container>
    )
}

InboxDetailsV.propTypes = {
    notification: PropTypes.string,
    comment: PropTypes.string,
    credit: PropTypes.object,
    creditReportDocuments: PropTypes.array,
    handleDownload: PropTypes.func,
    crLink: PropTypes.string,
}