import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ size, color, color2, color3, color4, opacity }) => <Svg x='0px' y='0px' width={size} height={size} viewBox='0 0 383 384'>
    <G>
        <G>
            <Path opacity={opacity || '0.1'} fill={color3 || '#009587'} d='M380.1,193c0,103.9-84.2,188.1-188.1,188.1C88.2,381.1,4,296.9,4,193C4,89.1,88.2,4.9,192,4.9C295.9,4.9,380.1,89.1,380.1,193z' />
            <Path opacity={opacity || '0.2'} fill={color4 || '#009587'} d='M339.7,193c0,81.5-66.1,147.7-147.7,147.7c-81.6,0-147.7-66.1-147.7-147.7S110.5,45.3,192,45.3C273.6,45.3,339.7,111.4,339.7,193z' />
            <Path fill={color2 || '#FFFFFF'} d='M306.2,193c0,63.1-51.1,114.2-114.2,114.2C129,307.2,77.8,256.1,77.8,193C77.8,129.9,129,78.8,192,78.8C255.1,78.8,306.2,129.9,306.2,193z' />
        </G>
    </G>
    <Path fill={color || '#009587'} d='M246.3,141.3c-5.7-6.8-13.9-10.1-24.6-10.1c-6.8,0-12.4,1.3-17,3.9c-4.6,2.6-8.9,6.9-12.9,13c-6.6-11.2-16-16.9-28.2-16.9c-10.3,0-18.7,3.4-25.2,10.1c-6.5,6.8-9.7,15.5-9.7,26.1v86H164v-77.6c0-6.3,1.8-9.5,5.2-9.5c1.9,0,3.1,0.6,3.8,1.9c0.6,1.3,1,3.8,1,7.6v43.3c6.9,1.2,20.4,1.6,35.5-4.3v-39.6c0-5.9,1.7-8.9,5.2-8.9c1.9,0,3.1,0.6,3.7,1.9c0.6,1.3,0.9,3.8,0.9,7.6v34.3c4.7-2.7,9.5-6,14.2-10.3c2.2-2.4,4.1-4.9,5.7-7.3c3.7-5.8,5.5-11.2,5.4-14.9c1,6.5,0.3,12.8-1.7,18.6c-1.1,3.3-2.6,6.4-4.5,9.4c-4.5,7.3-11.1,13.7-19,18.8v28.9h35.5v-82.9C254.8,157.8,252,148.1,246.3,141.3z M182.6,237c0,0-4.1,0.5-8.6,0.4v16.1h11.9c13,0,23.6-10.5,23.6-23.6C201.2,233.7,192,236.2,182.6,237z' />
</Svg>

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    color2: PropTypes.any,
    color3: PropTypes.any,
    color4: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}