import React from 'react'
import { ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { TravelRouteV } from '../TravelRoute'
import { Container, Box } from './styled'
import { ModalCamera } from '../ModalCamera'
import { mq } from '../../validation'
import { ShadowBox } from '../../generalStyles'

export const RequestCreditDataV = ({
    data: {
        typeInfo,
        navigation,
        isVisibleOption,
        params
    },
    refs,
    children,
    handleClose,
    handleTravel
}) => (
    <Container>
        <ModalCamera data={params} navigation={navigation} handleClose={handleClose} isVisible={isVisibleOption} />
        <TravelRouteV travelComplete={typeInfo} options={6} handleClick={handleTravel} />
        <Box style={[ShadowBox]} paddingHorizontal={(typeInfo >= 5 && typeInfo < 6)? mq(5) : mq(15)}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps='handled' ref={refs}>
                {children}
            </ScrollView>
        </Box>
    </Container>
)

RequestCreditDataV.propTypes = {
    data: PropTypes.object,
    refs: PropTypes.object,
    children: PropTypes.node,
    handleClose: PropTypes.func,
    handleTravel: PropTypes.func
}