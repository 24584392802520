import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SelectHook } from '../SelectHook'
import { ALL_DEPARTMENTS } from '../../queries/Departments'

export const Departments = ({ testID, title, value, name, handleSelect, handleCancel, use = {}, disabled, error }) => {
    const [res, setRes] = useState([])
    const { data, loading } = ALL_DEPARTMENTS({ order: [['dName', 'ASC']], ...use })

    useEffect(() => {
        if (data?.departments && !loading) {
            setRes(data?.departments?.map(x => {
                return {
                    dIdDepartment: x?.dId,
                    dName: (x?.dName)?.toUpperCase()
                }
            }) || [])
        }
    }, [data])

    return (
        <SelectHook
            data={res}
            error={error}
            loading={loading}
            title={title || 'Departamento *'}
            value={value}
            name={name || 'dIdDepartment'}
            nameAtt='dName'
            titleModal='Seleccionar Departamento'
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
            testID={testID}
        />
    )
}

Departments.propTypes = {
    testID: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool
}