import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { wp } from '../../validation'

export const Container = styled.View`
    flex: 1;
    border-top-left-radius: ${wp(7)}px;
    border-top-right-radius: ${wp(7)}px;
`
export const Form = styled.View`
    padding: ${wp(2.5)}px;
    justify-content: center;
`
export const BoxButton = styled.View`
    margin: ${wp(3)}px 0;
    align-items: center;
`
export const Label = styled.Text`
    font-size: ${wp(3.2)}px;
    color: ${({ color }) => color || theme.onSurfaceC};
    font-family: ${({ family }) => family || 'PFontRegular'};
`
export const BoxLabel = styled.View`
    width: ${wp(80)}px;
    margin: ${wp(3)}px 0;
`
export const Row = styled.View`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`