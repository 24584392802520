import moment from 'moment'
import 'moment/locale/es';

/**
 * calcula el plan de amortización
 * @param {object} args argumentos
 * @return {object} nuevos argumentos
 */
export function calculateRepayment ({ valCre, valCou, fees, rate, month }) {
    let startBalance = valCre
    let endBalance = valCre
    let totalAtt = 0
    let capital = 0
    let repaymentPlan = [
        { name: 'Fecha', data: [] },
        { name: 'Saldo I', data: [] },
        { name: 'Capital', data: [] },
        { name: 'Interés', data: [] },
    ]

    // recorre los atributos de la cuota
    for (let i = 0; i < fees?.length; i++) {
        const { name, value } = fees[i]
        totalAtt += value
        repaymentPlan = [...repaymentPlan, { name, data: [] }]
    }

    // agrega la ultima opción del plan
    repaymentPlan = [...repaymentPlan, { name: 'Saldo F', data: [] }]

    // recorre según la cantidad de pagos restantes
    for (let i = 0; i < month; i ++) {
        // calcula el interés y el capital
        const interest = Math.round(endBalance * (rate / 100))
        capital = Math.round(valCou - ((endBalance * (rate / 100)) + totalAtt))

        // suma el total de balance final y suma un mes
        endBalance -= capital
        const dateFirFee = repaymentPlan[0].data.length ? moment(repaymentPlan[0].data?.[repaymentPlan[0].data.length - 1]?.format('YYYY-MM-DD')).add(1, 'months') : moment()

        // registra el plan de amortización
        repaymentPlan[0].data = [...repaymentPlan[0].data, i === 0 ? moment() : dateFirFee]
        repaymentPlan[1].data = [...repaymentPlan[1].data, i === 0 ? valCre : startBalance > 10 ? startBalance : 0]
        repaymentPlan[2].data = [...repaymentPlan[2].data, capital]
        repaymentPlan[3].data = [...repaymentPlan[3].data, interest]

        // recorre los atributos de la cuota para agregarlo al plan
        for (let ii = 0; ii < fees?.length; ii++) {
            const { value } = fees[ii]
            repaymentPlan[3 + ii + 1].data = [...repaymentPlan[3 + ii + 1].data, value]
        }

        // agrega el balance final
        repaymentPlan[3 + fees?.length + 1].data = [...repaymentPlan[3 + fees?.length + 1].data, endBalance > 10 ? endBalance : 0]

        // suma el total de balance inicial
        startBalance -= capital
    }
    return repaymentPlan
}