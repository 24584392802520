import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { Cities } from '../Selects/Cities'
import { TypeAddress } from '../Selects/TypeAddress'
import { Relationships } from '../Selects/Relationships'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, Row, Box, Title } from './styled'
import { TypeIdentities } from '../Selects/TypeIdentities'
import { Genders } from '../Selects/Genders'

export const ReferenceDataRegisterV = ({ data: { banner, errors, values, loading, format }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            {!!format?.ufRefFam &&
                <>
                    <Box>
                        <Title>Referencia familiar</Title>
                    </Box>
                    <Row>
                        <InputHook testID='cde40be2-410c-4ab7-a538-f5e514b5f491' flex={.47} title='Nombre *' returnKeyType='next' error={errors?.prNamFam} value={values?.payrollReference?.prNamFam} name='prNamFam' required letters range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook testID='cde40be3-410d-4ab7-a539-f5e514b5f492' flex={.47} title='Apellido *' returnKeyType='next' error={errors?.prLasFam} value={values?.payrollReference?.prLasFam} name='prLasFam' required letters range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    </Row>
                    <Relationships testID='a86405b0-985a-11ee-b16b-430dd7ebe2cd' error={errors?.rIdFam} value={values?.payrollReference?.rIdFam} handleSelect={e => handleChange({ name: 'rIdFam', value: e.value, error: e.error })} />
                    <InputHook testID='2d0963ba-cea3-4d02-99a9-07a9a4d8cf34' title='Número teléfono *' returnKeyType='next' keyboardType='numeric' error={errors?.prPhoFam} value={values?.payrollReference?.prPhoFam} name='prPhoFam' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                    <Cities testID='258df270-f36d-4a93-9ac1-4fb5ff85e3c3' error={errors?.cIdFam} value={values?.payrollReference?.cIdFam} handleSelect={e => handleChange({ name: 'cIdFam', value: e.value, error: e.error })} />
                    <TypeAddress testID='ca649458-6dae-4d93-b00d-2b50237fd360' error={errors?.taIdFam} value={values?.payrollReference?.taIdFam} handleSelect={e => handleChange({ name: 'taIdFam', value: e.value, error: e.error })} />
                    <Row>
                        <InputHook testID='52f77db0-e832-4894-9507-a5d12f7ebe84' flex={.40} title='No. Dirección *' returnKeyType='next' error={errors?.prNumDirFam} value={values?.payrollReference?.prNumDirFam} name='prNumDirFam' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook testID='6240f6a6-7e53-45a7-8553-74fd45be6dbe' flex={.25} title='No. *' returnKeyType='next' error={errors?.prNumFam} value={values?.payrollReference?.prNumFam} name='prNumFam' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook testID='2c61f937-20b2-47bb-82b7-9285fee06c8d' flex={.25} title='No. Casa *' returnKeyType='next' error={errors?.prNumHouFam} value={values?.payrollReference?.prNumHouFam} name='prNumHouFam' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    </Row>
                    <InputHook testID='d2e6ac3e-8c36-4a49-ab64-9fb102112c3c' title='Información Residencial (Opcional)' returnKeyType='next' value={values?.payrollReference?.prInfFam} name='prInfFam' range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />
                </>
            }
            {!!format?.ufRefPar &&
                <>
                    <Box>
                        <Title>Referencia particular</Title>
                    </Box>
                    <Row>
                        <InputHook testID='8f1bdab0-985a-11ee-8f30-453652e7b4d0' flex={.47} title='Nombre *' returnKeyType='next' error={errors?.prNamPar} value={values?.payrollReference?.prNamPar} name='prNamPar' required letters range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook testID='97d83d19-5db4-4c8b-902c-e08e477c6769' flex={.47} title='Apellido *' returnKeyType='next' error={errors?.prLasPar} value={values?.payrollReference?.prLasPar} name='prLasPar' required letters range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    </Row>
                    <InputHook testID='b0398907-acce-4879-8824-86a9a3bc1d7d' title='Número teléfono *' returnKeyType='next' keyboardType='numeric' error={errors?.prPhoPar} value={values?.payrollReference?.prPhoPar} name='prPhoPar' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                    <Cities testID='05317c8d-6bb7-4282-9ef0-4bb11011d8d8' error={errors?.cIdPar} value={values?.payrollReference?.cIdPar} handleSelect={e => handleChange({ name: 'cIdPar', value: e.value, error: e.error })} />
                    <TypeAddress testID='ab61b4f5-e3cf-46e1-959c-d1902afb8540' error={errors?.taIdPar} value={values?.payrollReference?.taIdPar} handleSelect={e => handleChange({ name: 'taIdPar', value: e.value, error: e.error })} />
                    <Row>
                        <InputHook testID='46548d58-419f-4839-b661-43fe24fcdffb' flex={.40} title='No. Dirección *' returnKeyType='next' error={errors?.prNumDirPar} value={values?.payrollReference?.prNumDirPar} name='prNumDirPar' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook testID='0981c95c-2053-4de3-8672-0f7c3c8cb812' flex={.25} title='No. *' returnKeyType='next' error={errors?.prNumPar} value={values?.payrollReference?.prNumPar} name='prNumPar' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook testID='61eb21d9-16dd-4f0a-97bf-82b92e55d418' flex={.25} title='No. Casa *' returnKeyType='next' error={errors?.prNumHouPar} value={values?.payrollReference?.prNumHouPar} name='prNumHouPar' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    </Row>
                    <InputHook testID='326c62ec-7f4c-43d0-bea4-b31aeb506f45' title='Información Residencial (Opcional)' returnKeyType='next' value={values?.payrollReference?.prInfPar} name='prInfPar' range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />
                </>
            }

            {!!format?.ufRefStu && <>
                <Box>
                    <Title>Referencia Estudiantil</Title>
                </Box>
                <TypeIdentities testID ='f2374940-9517-11ee-8bf2-8f77b423a5e5' error={errors?.tiIdStu} value={values?.payrollReference?.tiIdStu} handleSelect={e => handleChange({  name: 'tiIdStu', value: e.value, error: e.error })} />

                <InputHook testID ='1b1fee96-85bd-466f-bb4b-32137255fc95' title='Número de Identificación *' error={errors?.prIdeNumStu} value={values?.payrollReference?.prIdeNumStu} name='prIdeNumStu' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                <InputHook testID ='c1a98490-9518-11ee-8165-f9ca7b08435c' title='Nombres estudiante *' error={errors?.prNamStu} value={values?.payrollReference?.prNamStu} name='prNamStu' required range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />
                <InputHook testID ='e656f6cf-24a4-4802-b751-e436a6bc4d1d' title='Apellidos estudiante *' error={errors?.prLasStu} value={values?.payrollReference?.prLasStu} name='prLasStu' required range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />

                <Genders testID ='cbb8e570-9518-11ee-8182-81d19edb7afd' error={errors?.gIdStu} value={values?.payrollReference?.gIdStu} handleSelect={e => handleChange({ name: 'gIdStu', value: e.value, error: e.error })}/>

                <InputHook testID ='9b668f31-66ef-457e-9502-2d83782913b8' title='Número de Teléfono *' keyboardType='numeric' error={errors?.prPhoStu} value={values?.payrollReference?.prPhoStu} name='prPhoStu' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                <InputHook testID ='d79a4730-9518-11ee-885f-ff2729d4c4a7' title='Correo Electrónico *' error={errors?.prEmailStu} value={values?.payrollReference?.prEmailStu} name='prEmailStu' required email range={[5, 100]} onBlur={handleBlur} onChangeText={handleChange} />
                <InputHook testID ='54dade49-a942-4958-9201-9a90557a89c4' title='Facultad *' error={errors?.prFacultyStu} value={values?.payrollReference?.prFacultyStu} name='prFacultyStu' required range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />

                <Cities testID ='e4bd50b0-9518-11ee-b474-55446f9114af' error={errors?.cIdStu} value={values?.payrollReference?.cIdStu} handleSelect={e => handleChange({ name: 'cIdStu', value: e.value, error: e.error })} />
                <TypeAddress testID ='12dbdb0a-d723-4e58-ade4-c3da0746964c' error={errors?.taIdStu} value={values?.payrollReference?.taIdStu} handleSelect={e => handleChange({ name: 'taIdStu', value: e.value, error: e.error })} />
                <Row>
                    <InputHook testID ='ee41afa0-9518-11ee-91aa-dd953b8f555b' flex={.40} title='No. Dirección *' returnKeyType='next' error={errors?.prNumDirStu} value={values?.payrollReference?.prNumDirStu} name='prNumDirStu' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook testID ='e86b458e-be49-4209-a7c7-f610b669d2af' flex={.25} title='No. *' returnKeyType='next' error={errors?.prNumStu} value={values?.payrollReference?.prNumStu} name='prNumStu' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook testID ='f79e8960-9518-11ee-9da4-29f96b09e52b' flex={.25} title='No. Casa *' returnKeyType='next' error={errors?.prNumHouStu} value={values?.payrollReference?.prNumHouStu} name='prNumHouStu' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                </Row>
                <InputHook testID ='5a63771e-c0d4-4f0d-a257-184d01c249b2' title='Información adicional de residencia *' error={errors?.prInfStu} value={values?.payrollReference?.prInfStu} name='prInfStu' required range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />
            </>}

            <BoxButton>
                <ButtonSubmit testID='921ae5eb-1de3-4e45-b2dd-e49ff3b8461f' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

ReferenceDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
}