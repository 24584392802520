import React from 'react'
import PropTypes from 'prop-types'
import { Animated } from 'react-native'
import { wp } from '../../validation'
import { Button, Title, LoadingButton } from './styled'

export const ButtonSubmit = ({ size, margin, width, color, title, onPress, loading, bgColor, styleButton, radius, padding, testID, disabled }) => {
    const aWidth = new Animated.Value(0)
    Animated.loop(
        Animated.timing(aWidth, {
            toValue: wp('40%'),
            duration: 1000,
            useNativeDriver: false
        }),
        { iterations: Infinity }
    ).start()

    return (
        <Button activeOpacity={0.5} margin={margin} radius={radius} padding={padding} disabled={disabled || loading} onPress={onPress} width={width} style={styleButton} bgColor={bgColor} testID={testID}>
            {loading ?
                <>
                    <Title size={size} color={color}>Cargando...</Title>
                    <LoadingButton style={{ width: aWidth }} bgColor={color} />
                </>
                :
                <Title size={size} color={color}>{title}</Title>
            }
        </Button>
    )
}

ButtonSubmit.propTypes = {
    size: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.any,
    onPress: PropTypes.func,
    loading: PropTypes.bool,
    bgColor: PropTypes.string,
    margin: PropTypes.string,
    styleButton: PropTypes.object,
    radius: PropTypes.number,
    padding: PropTypes.number,
    testID: PropTypes.string,
    disabled: PropTypes.bool,
}