import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { filterKeyObject, writeCache, validationErrors } from '../../validation'
import { LifeInsuranceV } from '../../components/LifeInsurance'
import { PREGNANCY_TIME, UPDATE_PAYROLL_COMPANY } from './queries'

export const LifeInsuranceC = ({ dataUser, banner, handleNext, format, refScroll }) => {
    const [values, setValues] = useState({})
    const [values1, setValues1] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [payrollCompanyMutation, { loading }] = useMutation(UPDATE_PAYROLL_COMPANY)
    const { data: pregnacyTimes } = useQuery(PREGNANCY_TIME, {
        fetchPolicy: 'network-only',
    });
    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({
            payrollLifeInsurance:{ ...filterKeyObject(dataUser?.payrollLifeInsurance, ['__typename']),
                pliCancer: dataUser?.payrollLifeInsurance?.pliCancer === 1 ? dataUser?.payrollLifeInsurance?.pliCancer : 2,
                pliVIH: dataUser?.payrollLifeInsurance?.pliVIH === 1 ? dataUser?.payrollLifeInsurance?.pliVIH : 2,
                pliStroke: dataUser?.payrollLifeInsurance?.pliStroke === 1 ? dataUser?.payrollLifeInsurance?.pliStroke : 2,
                pliArtHyp: dataUser?.payrollLifeInsurance?.pliArtHyp === 1 ? dataUser?.payrollLifeInsurance?.pliArtHyp : 2,
                pliDiabetes: dataUser?.payrollLifeInsurance?.pliDiabetes === 1 ? dataUser?.payrollLifeInsurance?.pliDiabetes : 2,
                pliCorArtDis: dataUser?.payrollLifeInsurance?.pliCorArtDis === 1 ? dataUser?.payrollLifeInsurance?.pliCorArtDis : 2,
                pliHeaAtt: dataUser?.payrollLifeInsurance?.pliHeaAtt === 1 ? dataUser?.payrollLifeInsurance?.pliHeaAtt : 2,
                pliHasConAcqPhyMenLim: dataUser?.payrollLifeInsurance?.pliHasConAcqPhyMenLim === 1 ? dataUser?.payrollLifeInsurance?.pliHasConAcqPhyMenLim : 2,
                pliDiaTreDisOthThoMenAbo: dataUser?.payrollLifeInsurance?.pliDiaTreDisOthThoMenAbo === 1 ? dataUser?.payrollLifeInsurance?.pliDiaTreDisOthThoMenAbo : 2,
                pliIsPre: dataUser?.payrollLifeInsurance?.pliIsPre === 1 ? dataUser?.payrollLifeInsurance?.pliIsPre : 2,
                pliPrimitive: dataUser?.payrollLifeInsurance?.pliIsPre === 1 ? dataUser?.payrollLifeInsurance?.pliPrimitive : 2,
                ptId: dataUser?.payrollLifeInsurance?.pliIsPre === 1 ? dataUser?.payrollLifeInsurance?.ptId : null,
                pliDisabilityPensioner: dataUser?.payrollLifeInsurance?.pliDisabilityPensioner === 1 ? dataUser?.payrollLifeInsurance?.pliDisabilityPensioner : 2,
                pliUseDrugsAlcohol: dataUser?.payrollLifeInsurance?.pliUseDrugsAlcohol === 1 ? dataUser?.payrollLifeInsurance?.pliUseDrugsAlcohol : 2,
                pliTreatment: !dataUser?.payrollLifeInsurance?.pliTreatment ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.pliTreatment,
                pliActSta: !dataUser?.payrollLifeInsurance?.pliActSta ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.pliActSta,
                pliDiseaseName: !dataUser?.payrollLifeInsurance?.pliDiseaseName ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.pliDiseaseName,
                pliConsequences: !dataUser?.payrollLifeInsurance?.pliConsequences ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.pliConsequences,
                pliObservations: !dataUser?.payrollLifeInsurance?.pliObservations ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.pliObservations,
                pliCurrentTreatment: !dataUser?.payrollLifeInsurance?.pliCurrentTreatment ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.pliCurrentTreatment,
                pliTreatmentOrSurgery: !dataUser?.payrollLifeInsurance?.pliTreatmentOrSurgery ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.pliTreatmentOrSurgery,
                ieId: !dataUser?.payrollLifeInsurance?.ieId ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.ieId
            }
        })
        setErrorForm({
        })
    }, [dataUser])
    useEffect(() => {
        setValues1({
            payrollLifeInsurance: {
                ...filterKeyObject(values?.payrollLifeInsurance, ['__typename']),
                pliCancer: values?.payrollLifeInsurance?.pliCancer === 1 ? values?.payrollLifeInsurance?.pliCancer : 2,
                pliVIH: values?.payrollLifeInsurance?.pliVIH === 1 ? values?.payrollLifeInsurance?.pliVIH : 2,
                pliStroke: values?.payrollLifeInsurance?.pliStroke === 1 ? values?.payrollLifeInsurance?.pliStroke : 2,
                pliArtHyp: values?.payrollLifeInsurance?.pliArtHyp === 1 ? values?.payrollLifeInsurance?.pliArtHyp : 2,
                pliDiabetes: values?.payrollLifeInsurance?.pliDiabetes === 1 ? values?.payrollLifeInsurance?.pliDiabetes : 2,
                pliCorArtDis: values?.payrollLifeInsurance?.pliCorArtDis === 1 ? values?.payrollLifeInsurance?.pliCorArtDis : 2,
                pliHeaAtt: values?.payrollLifeInsurance?.pliHeaAtt === 1 ? values?.payrollLifeInsurance?.pliHeaAtt : 2,
                pliHasConAcqPhyMenLim: values?.payrollLifeInsurance?.pliHasConAcqPhyMenLim === 1 ? values?.payrollLifeInsurance?.pliHasConAcqPhyMenLim : 2,
                pliDiaTreDisOthThoMenAbo: values?.payrollLifeInsurance?.pliDiaTreDisOthThoMenAbo === 1 ? values?.payrollLifeInsurance?.pliDiaTreDisOthThoMenAbo : 2,
                pliIsPre: values?.payrollLifeInsurance?.pliIsPre === 1 ? values?.payrollLifeInsurance?.pliIsPre : 2,
                pliPrimitive: values?.payrollLifeInsurance?.pliIsPre === 1 ? values?.payrollLifeInsurance?.pliPrimitive : 2,
                ptId: values?.payrollLifeInsurance?.pliIsPre === 1 ? values?.payrollLifeInsurance?.ptId : null,
                pliDisabilityPensioner: values?.payrollLifeInsurance?.pliDisabilityPensioner === 1 ? values?.payrollLifeInsurance?.pliDisabilityPensioner : 2,
                pliUseDrugsAlcohol: values?.payrollLifeInsurance?.pliUseDrugsAlcohol === 1 ? values?.payrollLifeInsurance?.pliUseDrugsAlcohol : 2,
                pliTreatment: values?.payrollLifeInsurance?.pliTreatment === null ? 'NINGUNO' : values?.payrollLifeInsurance?.pliTreatment,
                pliActSta: values?.payrollLifeInsurance?.pliActSta === null ? 'NINGUNO' : values?.payrollLifeInsurance?.pliActSta,
                pliDiseaseName: values?.payrollLifeInsurance?.pliDiseaseName === null ? 'NINGUNO' : values?.payrollLifeInsurance?.pliDiseaseName,
                pliConsequences: values?.payrollLifeInsurance?.pliConsequences === null ? 'NINGUNO' : values?.payrollLifeInsurance?.pliConsequences,
                pliObservations: values?.payrollLifeInsurance?.pliObservations === null ? 'NINGUNO' : values?.payrollLifeInsurance?.pliObservations,
                pliCurrentTreatment: values?.payrollLifeInsurance?.pliCurrentTreatment === null ? 'NINGUNO' : values?.payrollLifeInsurance?.pliCurrentTreatment,
                pliTreatmentOrSurgery: values?.payrollLifeInsurance?.pliTreatmentOrSurgery === null ? 'NINGUNO' : values?.payrollLifeInsurance?.pliTreatmentOrSurgery,
                ieId: !dataUser?.payrollLifeInsurance?.ieId === null ? 'NINGUNO' : dataUser?.payrollLifeInsurance?.ieId
            }
        })
    }, [values])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ ...values, payrollLifeInsurance: { ...values?.payrollLifeInsurance, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }
    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })
        const { data, error } = await payrollCompanyMutation({
            variables: { input: values1 }, update(store, { data: { setOrUpdatePayrollCompany } }) {
                writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: setOrUpdatePayrollCompany, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdatePayrollCompany) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(5)
        }
    }
    return (
        <LifeInsuranceV
            data={{ values, errors, loading, format, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleChangeResidence={handleChange}
            handleSubmit={handleSubmit}
            pregnacyTimes={pregnacyTimes?.pregnancyTimes}
        />
    )
}

LifeInsuranceC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    refScroll: PropTypes.object
}