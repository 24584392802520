import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView } from 'react-native'
import { hp } from '../../validation'
import { Modal } from '../Modal'
import { ModalTerms } from '../ModalTerms'
import { Container, BoxBackground, Box } from './styled'
import { Cards } from './Cards'
import { Head } from './Head'
import { Body } from './Body'
import { Fee } from './Fee'

export const GroupCreditV = ({ data, handleCheck, handleConfirmCheck, onChange, handleConfirmCard, handleIcon, handleCardClose, handleFeeActive, handleFeeClose, handleConfirmFee, handleCardActive, handleCreditType, handleSeePlan, handleSeeDetail, handleConfirm }) => (
    <Container>
        <Modal isVisible={data?.isVisibleTerm} marginVertical={hp(10)}>
            <ModalTerms data={data} handleCheck={handleCheck} handleConfirm={handleConfirmCheck}/>
        </Modal>
        <Modal isVisible={data?.isVisibleFee}>
            <Fee data={data} handleConfirm={handleConfirmFee} onChange={e => onChange(e, null, 'fee')} handleClose={handleFeeClose} />
        </Modal>
        <Modal isVisible={data?.isVisibleCard}>
            <Cards data={data} handleConfirm={handleConfirmCard} onChange={(e, index) => onChange(e, index, 'card')} handleIcon={handleIcon} handleClose={handleCardClose} />
        </Modal>
        <BoxBackground />
        <ScrollView nestedScrollEnabled showsVerticalScrollIndicator={false}>
            <Box>
                <Head data={data} handleFeeActive={handleFeeActive} handleCard={handleCardActive} />
                <Body data={data} handleCreditType={handleCreditType} handleSeePlan={handleSeePlan} handleSeeDetail={handleSeeDetail} handleConfirm={handleConfirm} />
            </Box>
        </ScrollView>
    </Container>
)

GroupCreditV.propTypes = {
    data: PropTypes.object,
    handleCheck: PropTypes.func,
    handleConfirmCheck: PropTypes.func,
    onChange: PropTypes.func,
    handleConfirmCard: PropTypes.func,
    handleIcon: PropTypes.func,
    handleCardClose: PropTypes.func,
    handleFeeActive: PropTypes.func,
    handleFeeClose: PropTypes.func,
    handleConfirmFee: PropTypes.func,
    handleCardActive: PropTypes.func,
    handleCreditType: PropTypes.func,
    handleSeePlan: PropTypes.func,
    handleSeeDetail: PropTypes.func,
    handleConfirm: PropTypes.func
}