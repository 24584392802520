import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { DrawerContent } from './DrawerContent'
import { RequestCredit } from '../src/pages/RequestCredit'
import { RequestCreditEdit } from '../src/pages/RequestCreditEdit'
import { RequestCreditData } from '../src/pages/RequestCreditData'
import { HeaderNavigator } from '../ignore/HeaderNavigator'
import { ScreenCamera } from '../src/Utils/containers/Camera'
import { InBox } from '../src/pages/Inbox'
import { Profile } from '../src/pages/Profile'
import { RepaymentPlan } from '../src/pages/RepaymentPlan'
import { CreditEditData } from '../src/pages/CreditEditData'
import theme from '../ignore/Constants'
import { LoginStackNavigator } from './StackNavigator'
import { DocumentCard } from '../src/pages/DocumentCard'
import { GroupCredit } from '../src/pages/GroupCredit'
import { CreditTypes } from '../src/pages/CreditTypes'
import { Credits } from '../src/pages/Credits'
import { Terms } from '../src/pages/Terms'
import { Home } from '../src/pages/Home'
import { mq } from '../src/validation'
import { CreditTypeRepaymentPlan } from '../src/pages/CreditTypeRepaymentPlan'
import { InformationAdditionalCreditLifeInsurance } from '../src/pages/InformationAdditionalCreditLifeInsurance'
import { InformationAdditionalCreditSarlaft } from '../src/pages/InformationAdditionalCreditSarlaft'
import { PaymentSummaryP } from '../src/pages/PaymentSummary'
import { Support } from '../src/pages/Support'
import { InBoxDetails } from '../src/pages/InboxDetails'
import { Cirenio } from '../src/pages/test/cirenio'
import { PayZenFormLyra } from '../src/containers/Lyra/lyra'
import { CreditsLine } from '../src/pages/CreditsLine'
import { CreditGroupLine } from '../src/pages/CreditGroupLine'

const Drawer = createDrawerNavigator()

export default () => (
    <Drawer.Navigator
        initialRouteName='Login'
        drawerStyle={{ width: '90%', borderTopRightRadius: mq(12), borderBottomRightRadius: mq(12), maxWidth: 760 }}
        drawerContent={props => <DrawerContent {...props} />}
        screenOptions={{ headerShown: true, header: HeaderNavigator }}
    >
        <Drawer.Screen name='Login' title={`${theme.nameApp} - Inicio de Sesión`} component={LoginStackNavigator} />
        <Drawer.Screen name='Home' title={`${theme.nameApp} - ${theme.sLogan}`} component={Home} />
        <Drawer.Screen name='RepaymentPlan' title={`${theme.nameApp} - Ver Crédito`} component={RepaymentPlan} />
        <Drawer.Screen name='CreditTypeRepaymentPlan' title={`${theme.nameApp} - Detalle de Solicitud del Crédito`} component={CreditTypeRepaymentPlan} />
        <Drawer.Screen name='Profile' title={`${theme.nameApp} - Perfil`} component={Profile} />
        <Drawer.Screen name='InBox' title={`${theme.nameApp} - Bandeja de Mensaje`} component={InBox} />
        <Drawer.Screen name='InBoxDetails' title={`${theme.nameApp} - Detalle de mensajes`} component={InBoxDetails} />
        <Drawer.Screen name='Terms' title={`${theme.nameApp} - Términos y Condiciones`} component={Terms} />
        <Drawer.Screen name='cirenio' title={`${theme.nameApp} - CIRENIO`} component={Cirenio} />
        <Drawer.Screen name='lyra' title={`${theme.nameApp} - LYRA`} component={PayZenFormLyra} />
        <Drawer.Screen name='Support' title={`${theme.nameApp} - Soporte técnico`} component={Support} />
        <Drawer.Screen name='CreditsLine' title={`${theme.nameApp} - ${theme.sLogan}`} component={CreditTypes} />
        <Drawer.Screen name='CreditGroupLine' title={`${theme.nameApp} - ${theme.sLogan}`} component={CreditGroupLine} />
        <Drawer.Screen name='CreditsRequest' title={`${theme.nameApp} - ${theme.sLogan}`} component={Credits} />
        <Drawer.Screen name='Credits' title={`${theme.nameApp} - ${theme.sLogan}`} component={CreditsLine} />
        <Drawer.Screen name='RequestCreditData' title={`${theme.nameApp} - Solicitud de Crédito`} component={RequestCreditData} />
        <Drawer.Screen name='RequestCredit' title={`${theme.nameApp} - Solicitud de Crédito`} component={RequestCredit} />
        <Drawer.Screen name='RequestCreditEdit' title={`${theme.nameApp} - Solicitud de Crédito`} component={RequestCreditEdit} />
        <Drawer.Screen name='EditCredit' title={`${theme.nameApp} - Editar información`} component={CreditEditData} />
        <Drawer.Screen name='Camera' title={`${theme.nameApp} - Cámara`} component={ScreenCamera} />
        <Drawer.Screen name='DocumentCard' title={`${theme.nameApp} - Cargar Documentos de la Cartera`} component={DocumentCard} />
        <Drawer.Screen name='GroupCredit' title={`${theme.nameApp} - Crédito Combinados`} component={GroupCredit} />
        <Drawer.Screen name='InformationAdditionalCreditLifeInsurance' title={`${theme.nameApp} - Información del Seguro de Vida del Crédito `} component={InformationAdditionalCreditLifeInsurance} />
        <Drawer.Screen name='InformationAdditionalCreditSarlaft' title={`${theme.nameApp} - Declaración del Crédito `} component={InformationAdditionalCreditSarlaft} />
        <Drawer.Screen name='PaymentSummary' title={`${theme.nameApp} - Resumen del Pago`} component={PaymentSummaryP} />
    </Drawer.Navigator>
)