import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { TypeIdentities } from '../Selects/TypeIdentities'
import { Genders } from '../Selects/Genders'
import { ButtonSubmit } from '../ButtonSubmit'
import { InputDateWeb } from '../InputDateWeb'
import { Container, Form, Row, BoxButton } from './styled'
import { Cities } from '../Selects/Cities'

export const PersonalDataRegisterV = ({ data: { banner, values, errors, format, loadCPS, loadCPC }, handleBlur, handleChange, handleChangeStaff, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            <TypeIdentities value={values?.tiId} disabled />
            <Row>
                <InputHook flex={format?.cpfDatExpDoc ? .47 : 1} title='Número de identificación' value={values?.cpcIdeNum} editable={false} />
                {!!format?.cpfDatExpDoc && <InputDateWeb flex={.47} title='Fecha de Expedición *' name='cpcDatExp' error={errors?.cpcDatExp} value={values?.cpcDatExp} onChangeText={handleChange} />}
            </Row>
            {!!format?.cpfDomExpDoc && <Cities title='Lugar de expedición *' error={errors?.cName} value={values?.cName} name='cName' nameAtt='cName' handleSelect={handleChange} />}
            <Row>
                <InputHook flex={.47} title='Primer Nombre' name='cpsFirNam' error={errors?.cpsFirNam} value={values?.creditPayrollStaff?.cpsFirNam} letters required range={[2, 30]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />
                <InputHook flex={.47} title='Segundo Nombre' name='cpsSecNam' error={errors?.cpsSecNam} value={values?.creditPayrollStaff?.cpsSecNam} letters range={[2, 30]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />
            </Row>
            <Row>
                <InputHook flex={.47} title='Primer Apellido' name='cpsFirLas' error={errors?.cpsFirLas} value={values?.creditPayrollStaff?.cpsFirLas} letters required range={[2, 30]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />
                <InputHook flex={.47} title='Segundo Apellido' name='cpsSecLas' error={errors?.cpsSecLas} value={values?.creditPayrollStaff?.cpsSecLas} letters range={[2, 30]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />
            </Row>
            <Row>
                {!!format?.cpfDatBir && <InputDateWeb flex={format?.cpfPhoPer ? .47 : 1} title='Fecha de Nacimiento *' name='cpsDatBir' less error={errors?.cpsDatBir} value={values?.creditPayrollStaff?.cpsDatBir} onChangeText={e => handleChangeStaff(e, 1)} />}
                {!!format?.cpfPhoPer && <InputHook flex={format?.cpfDatBir ? .47 : 1} title='Número Teléfono Fijo' returnKeyType='next' keyboardType='numeric' error={errors?.cpsPhoPer} value={values?.creditPayrollStaff?.cpsPhoPer} name='cpsPhoPer' numeric range={[5, 15]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />}
            </Row>
            {!!format?.cpfLocBir && <Cities testID='8aac29f3-413f-4d59-9736-618c52bac7cq' title='Lugar de Nacimiento' error={errors?.creditPayrollStaff?.cId} value={values?.creditPayrollStaff?.cId} handleSelect={e => handleChangeStaff(e, 1)} />}
            {!!format?.cpfGender && <Genders error={errors?.gId} value={values?.creditPayrollStaff?.gId} handleSelect={e => handleChangeStaff(e, 1)} />}
            <BoxButton>
                <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loadCPS || loadCPC} />
            </BoxButton>
        </Form>
    </Container>
)

PersonalDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleChangeStaff: PropTypes.func,
    handleSubmit: PropTypes.func
}