/**
 * calcula los valores de los atributos
 * @param {object} args argumentos
 * @return {object} nuevos argumentos
 */
export function calculatorAttribute ({ valCre, reqVal, mon, iva, attributes }) {
    /** variables necesarias */
    let credits = []
    let fees = []
    let tlm
    /** verifica que existan atributos del crédito */
    for (let i = 0; i < attributes?.length; i++) {
        /** almacena en una variable más corta los valores del crédito */
        const { typeAttribute: ta } = attributes[i]
        if (ta?.taType === 3) continue
        /** formulas */
        if (ta.taFormula === 1) {
            /** Calcula el valor */
            const value = ta.taValue * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** iva o sin  iva */
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value }]
            else fees = [...fees, { name: ta.taName, value }]
        } else if (ta.taFormula === 2) {
            /** Calcula el valor */
            const value = ((ta.typeBond.bonds[0].bPor / 100) * reqVal) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value }]
            else fees = [...fees, { name: ta.taName, value }]
        } else if (ta.taFormula === 3) {
            /** Calcula el valor */
            const value = (ta.salary.sValue * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value }]
            else fees = [...fees, { name: ta.taName, value }]
        } else if (ta.taFormula === 4) tlm = ta
        else if (ta.taFormula === 5) {
            /** Calcula el valor */
            const value = ((reqVal * ta.taValue) / ta.taValue2) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value }]
            else fees = [...fees, { name: ta.taName, value }]
        } else if (ta.taFormula === 6) {
            /** Calcula el valor */
            const value = (reqVal * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value }]
            else fees = [...fees, { name: ta.taName, value }]
        } else if (ta.taFormula === 7) {
            /** Calcula el valor */
            let value = (reqVal * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            const minimum = ta.taMinimum * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            value = value > minimum ? value : minimum
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value }]
            else fees = [...fees, { name: ta.taName, value }]
        } else if (ta.taFormula === 8) {
            /** Calcula el valor */
            const value = (valCre * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** Busca el % comparado al desembolso */
            const rate = value / valCre
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value: rate * valCre }]
            else fees = [...fees, { name: ta.taName, value: rate * valCre }]
        } else if (ta.taFormula === 9) {
            /** Calcula el valor */
            const value = ((valCre) * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            const minimum = ta.taMinimum * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** Busca el % comparado al desembolso */
            const rate = value > minimum ? (value / valCre) : (minimum / valCre)
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value: rate * valCre }]
            else fees = [...fees, { name: ta.taName, value: rate * valCre }]
        } else if (ta.taFormula === 10) {
            /** Calcula el valor */
            const value = ((ta.typeBond.bonds[0].bPor / 100) * valCre) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** Busca el % comparado al desembolso */
            const rate = value / valCre
            if (ta.taType === 1) credits = [...credits, { name: ta.taName, value: rate * valCre }]
            else fees = [...fees, { name: ta.taName, value: rate * valCre }]
        }
    }

    /** el seguro de vida, necesita el valor del crédito final por lo que es de ultimo el proceso */
    if (tlm) {
        const lm = tlm.typeLifeMillion.lifeMillions[0]
        if (tlm.typeLifeMillion.tlmFormula === 1) {
            const value = (((valCre / lm.lmValue) * lm.lmValueTwo) * mon) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (tlm.taType === 1) credits = [...credits, { name: tlm.taName, value }]
            else fees = [...fees, { name: tlm.taName, value }]
        } else if (tlm.typeLifeMillion.tlmFormula === 2) {
            const value = ((lm.lmPor / 100) * valCre * mon) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (tlm.taType === 1) credits = [...credits, { name: tlm.taName, value }]
            else fees = [...fees, { name: tlm.taName, value }]
        } else if (tlm.typeLifeMillion.tlmFormula === 3) {
            const value = lm.lmValue * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (tlm.taType === 1) credits = [...credits, { name: tlm.taName, value }]
            else fees = [...fees, { name: tlm.taName, value }]
        } else if (tlm.typeLifeMillion.tlmFormula === 4) {
            const value = ((lm.lmPor / 100) * valCre) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (tlm.taType === 1) credits = [...credits, { name: tlm.taName, value }]
            else fees = [...fees, { name: tlm.taName, value }]
        }
    }

    /** retorna los valores */
    return { credits, fees }
}