import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { InputDateWeb } from '../InputDateWeb'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, Label, BoxLabel, Row } from './styled'
import { dateFormat, numberFormat } from '../../validation'
import theme from '../../../ignore/Constants'

export const OPSDataRegisterV = ({ data: { banner, errors, values, loading, reCalculator, reTanking, optPay }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            <InputHook testID='3cc93861-3152-4131-8ab7-95aa95660ecb' title='Contratista *' returnKeyType='next' error={errors?.poContractor} value={values?.payrollOPS?.poContractor} name='poContractor' required range={[1, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            <InputHook testID='02d2652d-d42b-4623-9d98-01a6d70f4cca' title='Objeto *' returnKeyType='next' error={errors?.poObject} value={values?.payrollOPS?.poObject} name='poObject' required range={[1, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            <InputHook testID='2001f7dd-58d3-4d2e-96cd-aec61a3aa3ac' title='Valor del Contrato *' returnKeyType='next' keyboardType='numeric' error={errors?.poValCon} value={values?.payrollOPS?.poValCon} name='poValCon' required numeric format range={[1, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            <InputDateWeb testID='1fd40c2e-6fee-4330-9de0-03f1ba1bf619' title='Vigencia Desde *' returnKeyType='next' error={errors?.poValDatSta} value={values?.payrollOPS?.poValDatSta} name='poValDatSta' required onBlur={handleBlur} onChangeText={handleChange} />
            <InputDateWeb testID='ff854f66-09db-479a-9d27-37108d2c7c1d' title='Vigencia Hasta *' returnKeyType='next' error={errors?.poValDatEnd} value={values?.payrollOPS?.poValDatEnd} name='poValDatEnd' required onBlur={handleBlur} onChangeText={handleChange} />
            <BoxLabel>
                {reCalculator.state && <>
                    <Label>Su crédito ha sido calculado por su contrato.</Label>
                    <Row>
                        <Label>Monto del desembolso:</Label>
                        <Label family='PFontBold' color={theme.primaryC}>$ {numberFormat(reCalculator.cReqVal)}.</Label>
                    </Row>
                    {reCalculator.dateEnd ?
                        <Row>
                            <Label>Fecha de Pago:</Label>
                            <Label family='PFontBold' color={theme.primaryC}>$ {dateFormat(reCalculator.dateEnd)}</Label>
                        </Row>
                        :
                        <>
                            <Row>
                                <Label>Plazo del crédito:</Label>
                                <Label family='PFontBold' color={theme.primaryC}>{reCalculator.cMonVal}</Label>
                            </Row>
                            {reTanking === 1 &&
                                <Row>
                                    <Label>Retanqueo:</Label>
                                    <Label family='PFontBold' color={theme.primaryC}>Activo</Label>
                                </Row>
                            }
                            {optPay === 1 &&
                                <Row>
                                    <Label>Refinanciación:</Label>
                                    <Label family='PFontBold' color={theme.primaryC}>Activo</Label>
                                </Row>
                            }
                        </>
                    }
                    <Row>
                        <Label>Valor de la cuota:</Label>
                        <Label family='PFontBold' color={theme.primaryC}>$ {numberFormat(reCalculator.valCou)}</Label>
                    </Row>
                </>}
            </BoxLabel>
            <BoxButton>
                <ButtonSubmit testID='4dc777a3-fb2d-47d4-9f3a-de3656ab7bf3' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

OPSDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
}