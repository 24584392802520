import React from 'react'
import PropTypes from 'prop-types'
import { MethodV } from '../../components/LostPass/Method'

export const MethodC = ({ handleNext, handleMethod }) => {
    // selecciona el método de recuperación
    const handleSubmit = async method => {
        handleNext(2)
        handleMethod(method)
    }

    return (
        <MethodV handleSubmit={handleSubmit} />
    )
}

MethodC.propTypes = {
    handleMethod: PropTypes.func,
    handleNext: PropTypes.func
}