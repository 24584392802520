import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView, TouchableOpacity } from 'react-native'
//import { LogoLogin } from '../../../assets/icons'
import { Box, BoxLogo, Container, Footer, Title } from './styled'
import image from '../../../assets/img/image'
import theme from '../../../ignore/Constants'

const LogoLogin = image('LogoLogin')
export const LostPassV = ({ navigation, children, refs }) => (
    <>
        <Container>
            <Box>
                <BoxLogo>
                    <LogoLogin color={theme.primaryC} size='100%' />
                </BoxLogo>
                <ScrollView showsVerticalScrollIndicator={ false } contentContainerStyle={{ alignItems:'center' }} keyboardShouldPersistTaps='handled' ref={refs}>
                    {children}
                </ScrollView>
            </Box>
        </Container>
        <Footer>
            <TouchableOpacity onPress={() => navigation?.navigate('Login')}>
                <Title testID='b3fbcda0-9520-11ee-a169-37d3bf9df3fc' family='PFontRegular'>¿Deseas Iniciar sesión?</Title>
            </TouchableOpacity>
        </Footer>
    </>
)

LostPassV.propTypes = {
    navigation: PropTypes.object,
    children: PropTypes.object,
    refs: PropTypes.object
}