import { gql, useQuery } from '@apollo/client'

export const USE_ALL_CITIES = ({ state, order, limit }) => useQuery(gql`
    query getAllCities($state: [Int], $order: [[String]], $limit: [Int]) {
        cities(state: $state, order: $order, limit: $limit) {
            cId
            cName
        }
    }
`, {
    variables: { state, order, limit }
})