// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { FlatList, View } from 'react-native'

// Components
import { Head } from './Head'

// Utils and Styles
import { numberFormat, wp } from '../../validation'
import { Container, InfoPlan, BoxList, BoxHeadList, Label } from './styled'

/**
 * CreditTypeRepaymentPlanV
 * @param {any} props Properties CreditTypeRepaymentPlanV
 * @returns {ReactElement} Component Repayment
 */
export const CreditTypeRepaymentPlanV = ({ data: { credit, see }, handleOpen }) => {
    // States and Variables
    let indice = 0

    return (
        <Container>
            <Head data={{ credit, see }} handleOpen={handleOpen} />
            {credit.typeFormatCredit?.tfcRepPlaBef === 1 &&
                <InfoPlan>
                    <FlatList
                        data={credit.repaymentPlan}
                        horizontal
                        keyExtractor={item => item.name}
                        renderItem={({ item, index: indexHead }) => (
                            <View>
                                <BoxHeadList><Label family='PFontBold' numberOfLines={1} size={wp(3.8)} align='center'>{item.name}</Label></BoxHeadList>
                                <FlatList
                                    data={item.data}
                                    showsVerticalScrollIndicator={false}
                                    keyExtractor={() => `keyExtractorListData${indice++}`}
                                    renderItem={({ item: itemTwo, index }) => <BoxList borderBottom={index < item.data?.length}><Label align={indexHead ? 'right' : 'center'} size={wp(3.3)}>{indexHead ? numberFormat(Math.round(itemTwo)) : itemTwo.format('MM-YYYY')}</Label></BoxList>}
                                />
                            </View>
                        )}
                    />
                </InfoPlan>
            }
        </Container>
    )
}

CreditTypeRepaymentPlanV.propTypes = {
    data: PropTypes.object,
    changeTable: PropTypes.func,
    handleOpen: PropTypes.func,
}