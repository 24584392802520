import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AntDesign } from '@expo/vector-icons'
import { Container, Title, Box, Image, BoxButton } from './styled'
import theme from '../../../ignore/Constants'
import { ModalBannerInfo } from '../ModalBannerInfo'
import { DocumentS } from '../../skeleton/DocumentS'
import { isImage } from '../../validation'

export const ViewPhoto = ({ title, loadingDoc, icon, uri, nameFile, iconFile, handleInfo, titleModal, steps, iconModal, handleButton, testID }) => {
    const [isVisible, setIsVisible] = useState(false)

    // Activar modal
    const handleInfoButton = () => {
        setIsVisible(true)
        handleInfo && handleInfo()
    }

    const handleButtonInfo = e => {
        setIsVisible(false)
        handleButton(e)
    }
    return (
        <Container>
            {loadingDoc ? <DocumentS />
                : <>
                    <Title>{title}</Title>
                    <Box>
                        {uri ? isImage(nameFile) ? <Image source={{ uri }} /> : iconFile : icon}
                        <BoxButton testID={testID} onPress={handleInfoButton}>
                            <AntDesign name='camerao' size={20} color={theme.backgroundC} />
                        </BoxButton>
                    </Box>
                </>
            }
            <ModalBannerInfo isVisible={isVisible} handleCancel={() => setIsVisible(false)} title={titleModal} steps={steps} icon={iconModal} handleButton={handleButtonInfo} />
        </Container>
    )
}

ViewPhoto.propTypes = {
    title: PropTypes.string,
    loadingDoc: PropTypes.bool,
    icon: PropTypes.node,
    nameFile: PropTypes.string,
    iconFile: PropTypes.node,
    uri: PropTypes.string,
    handleInfo: PropTypes.func,
    titleModal: PropTypes.string,
    steps: PropTypes.array,
    iconModal: PropTypes.node,
    handleButton: PropTypes.func
}