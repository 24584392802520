import { calculator } from './calculator'

/**
 * cambia la cantidad de meses del crédito
 * @version 0.0.1
 * @param {object} args argumentos para el calculo del crédito
 * @returns {object} valores del crédito
 */
export const onChangeMonth = ({ month, values, params }) => {

    /** variables necesarias */
    const { vcDefVal } = values
    const { ctModule, vat, rate, creditTypeAttributes, ctType } = params
    const mon = month
    let ratMon = 1

    if (ctModule === 2 && ctType === 2){
        ratMon = (7 / 30) * 7
    }
    // calcula el valor del crédito
    const { valCre, valCou } = calculator({
        attributes: creditTypeAttributes,
        money: vcDefVal,
        mon,
        iva: vat.vPor,
        ctModule,
        rPor: rate.rPor,
        ratMon
    })

    /** actualiza las variables */
    return {
        mDefVal: month,
        valCre: Math.round(valCre),
        valCou: valCou > 0 ? Math.round(valCou) : 0
    }
}