import styled from 'styled-components/native'
import { Animated } from 'react-native'
import { mq } from '../../validation'

export const Container = styled.View`
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    border-top-left-radius: ${mq(14)}px;
    border-top-right-radius: ${mq(14)}px;
    padding-top: ${mq(8)}px;
    padding-bottom: ${mq(4)}px;
`
export const BoxIcon = styled.View`
    padding-left: ${mq(8)}px;
`
export const Title = styled.Text`
    width: 100%;
    font-size: ${({ size }) => size || mq(16)}px;
    font-family: ${({ family }) => family || 'PFontBold'};
    color: ${props => props.theme.PColor};
    text-align: center;
`
export const BoxInfo = styled.View`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: ${mq(36)}px;
    height: ${mq(36)}px;
    right: 0;
`
export const Button = styled.TouchableOpacity`
    justify-content: flex-end;
    position: absolute;
    background-color: ${props => props.theme.BGColor};
    border-radius: ${mq(300)}px;
    z-index: 2;
`
export const BoxEffect = styled(Animated.View)`
    position: absolute;
    background-color: ${props => props.theme.PColor}88;
    border-radius: ${mq(100)}px;
    width: ${({ width }) => width || mq(34)}px;
    height: ${({ height }) => height || mq(34)}px;
    z-index: 1;
`