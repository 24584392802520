import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { wp } from '../validation'

export const HomeS = props => (
    <ContentLoader
        speed={1}
        width={wp(90)}
        height={225}
        viewBox='0 0 350 200'
        backgroundColor='#d1d1d1'
        foregroundColor='#ecebeb'
        {...props}
    >
        <Rect x='335' y='0' rx='5' ry='5' width='2' height='200' />
        <Rect x='10' y='0' rx='5' ry='5' width='325' height='2' />
        <Rect x='10' y='200' rx='5' ry='5' width='325' height='2' />
        <Rect x='10' y='0' rx='5' ry='5' width='2' height='200' />
        <Rect x='35' y='25' rx='5' ry='5' width='45' height='45' />
        <Rect x='32' y='90' rx='5' ry='5' width='150' height='7' />
        <Rect x='32' y='115' rx='5' ry='5' width='50' height='5' />
        <Rect x='32' y='155' rx='5' ry='5' width='20' height='10' />
        <Rect x='245' y='112' rx='5' ry='5' width='81' height='7' />
        <Rect x='275' y='56' rx='5' ry='5' width='40' height='40' />
        <Rect x='170' y='28' rx='5' ry='5' width='155' height='5' />
        <Rect x='62' y='158' rx='5' ry='5' width='80' height='5' />
    </ContentLoader>
)