/* eslint-disable react/prop-types */
import React from 'react'
import Svg, { G, Path } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
export const LogoLogin = ({ width, height, style, props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={style}
        viewBox="0 0 446.000000 472.000000"
        preserveAspectRatio="xMidYMid meet"
        {...props}
    >
        <G
            transform="translate(0.000000,472.000000) scale(0.100000,-0.100000)"
            fill="#0071c0"
            stroke="none"
        >
            <Path d="M2017 4710 c-412 -37 -730 -203 -953 -499 -79 -105 -180 -303 -223 -437 -17 -54 -62 -245 -99 -424 l-69 -325 -99 -17 c-198 -34 -332 -111 -445 -257 -117 -152 -160 -323 -89 -361 11 -6 119 -10 259 -10 188 0 241 -3 241 -13 0 -7 -70 -361 -155 -787 -85 -426 -160 -811 -165 -856 -18 -146 19 -305 101 -430 112 -172 348 -311 468 -276 47 15 96 67 111 119 5 21 107 524 225 1118 118 594 217 1090 220 1101 4 20 13 22 107 26 179 8 349 83 490 216 137 128 239 328 199 390 -16 26 -88 32 -383 32 -164 0 -298 2 -298 5 0 19 114 541 126 580 46 138 132 269 223 339 113 87 195 113 400 126 252 17 423 90 581 249 88 89 109 155 71 217 -35 57 -219 127 -414 158 -123 20 -309 26 -430 16z" />
            <Path d="M3030 3347 c-183 -62 -334 -179 -446 -348 -75 -112 -131 -234 -168 -363 -32 -110 -33 -153 -3 -197 51 -77 135 -86 269 -30 245 103 449 341 582 677 58 147 44 232 -44 276 -49 25 -81 22 -190 -15z" />
            <Path fill="#ff4400" d="M3314 2359 c-10 -17 6 -27 112 -67 259 -98 446 -273 568 -533 81 -172 108 -310 82 -422 -46 -196 -193 -342 -380 -376 -39 -7 -272 -11 -658 -11 -506 0 -603 -2 -627 -15 -69 -35 -53 -122 45 -241 55 -67 169 -138 282 -176 92 -31 104 -32 252 -32 130 -1 172 3 260 24 143 33 285 34 390 1 92 -28 172 -68 236 -118 41 -31 57 -38 96 -38 62 0 96 32 154 150 44 89 46 96 186 813 139 709 142 724 131 789 -21 119 -119 227 -233 252 -64 15 -887 15 -896 0z" />
            <Path d="M2090 2336 c-185 -41 -313 -116 -449 -262 -110 -117 -197 -273 -236 -422 -38 -148 -295 -1451 -295 -1497 0 -67 30 -115 85 -134 79 -27 269 17 391 90 117 70 252 216 330 356 74 132 97 222 249 975 80 397 149 744 152 769 14 114 -69 160 -227 125z" />
            <Path fill="#ff4400" d="M2812 1840 c-197 -27 -352 -133 -431 -295 -38 -78 -40 -127 -6 -160 l24 -25 499 0 c555 0 532 -3 551 66 17 61 -58 197 -157 285 -111 99 -310 152 -480 129z" />
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}