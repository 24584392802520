import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { ReferenceDataRegisterV } from '../../components/CreditEditData/ReferenceDataRegister'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { filterKeyObject, updateCache, validationErrors } from '../../validation'
import { EDIT_CREDIT_PAYROLL_REFERENCE } from './queries'

export const ReferenceDataRegisterC = ({ dataUser, banner, handleNext, format, refScroll, userParameters, handleStepDocuments }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [creditPayrollReferenceMutation, { loading }] = useMutation(EDIT_CREDIT_PAYROLL_REFERENCE)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({ creditPayrollReference: filterKeyObject(dataUser?.creditPayrollReference, ['__typename']) })
        setErrorForm({
            cprNamFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.cprNamFam),
            cprLasFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.cprLasFam),
            rIdFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.rIdFam),
            cprPhoFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.cprPhoFam),
            cIdFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.cIdFam),
            taIdFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.taIdFam),
            cprNumDirFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.cprNumDirFam),
            cprNumFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.cprNumFam),
            cprNumHouFam: (format?.cpfRefFam && !dataUser?.creditPayrollReference?.cprNumHouFam),
            cprNamPar: (format?.cpfRefPar && !dataUser?.creditPayrollReference?.cprNamPar),
            cprLasPar: (format?.cpfRefPar && !dataUser?.creditPayrollReference?.cprLasPar),
            cprPhoPar: (format?.cpfRefPar && !dataUser?.creditPayrollReference?.cprPhoPar),
            cIdPar: (format?.cpfRefPar && !dataUser?.creditPayrollReference?.cIdPar),
            taIdPar: (format?.cpfRefPar && !dataUser?.creditPayrollReference?.taIdPar),
            cprNumDirPar: (format?.cpfRefPar && !dataUser?.creditPayrollReference?.cprNumDirPar),
            cprNumPar: (format?.cpfRefPar && !dataUser?.creditPayrollReference?.cprNumPar),
            cprNumHouPar: (format?.cpfRefPar && !dataUser?.creditPayrollReference?.cprNumHouPar),
        })
    }, [dataUser])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ creditPayrollReference: { ...values.creditPayrollReference, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await creditPayrollReferenceMutation({
            variables: { input: values.creditPayrollReference }, update(cache, { data: { setOrUpdateCreditPayrollReference } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollReference })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdateCreditPayrollReference) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(userParameters?.lifeInsurance === 1 && format?.cpfLifeInsurance === 1 || format?.cpfOtherInformation === 1 ? 4.5 : handleStepDocuments())
        }
    }

    return (
        <ReferenceDataRegisterV
            data={{ values, errors, format, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleChangeResidence={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

ReferenceDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    refScroll: PropTypes.object,
    userParameters: PropTypes.array,
    handleStepDocuments: PropTypes.number
}