import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, Row } from './styled'
import { SelectHook } from '../SelectHook'
import { InputDateWeb } from '../InputDateWeb'

export const LifeInsuranceDataRegisterV = ({ data: { banner, errors, loading, values }, handleBlur, handleChange, handleSubmit, pregnacyTimes }) => (
    <Container>
        {banner}
        <Form>
            <SelectHook
                required
                nameAtt='title'
                name='cpliCancer'
                title='Cancer'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliCancer}
                error={errors.cpliCancer}
                handleSelect={e => handleChange(e)}
                data={[{ cpliCancer: 1, title: 'SI' }, { cpliCancer: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliVIH'
                title='Sida'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliVIH}
                error={errors.cpliVIH}
                handleSelect={e => handleChange(e)}
                data={[{ cpliVIH: 1, title: 'SI' }, { cpliVIH: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliStroke'
                title='Accidente cerebrovascular'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliStroke}
                error={errors.cpliStroke}
                handleSelect={e => handleChange(e)}
                data={[{ cpliStroke: 1, title: 'SI' }, { cpliStroke: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliArtHyp'
                title='Hipertensión arterial'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliArtHyp}
                error={errors.cpliArtHyp}
                handleSelect={e => handleChange(e)}
                data={[{ cpliArtHyp: 1, title: 'SI' }, { cpliArtHyp: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliDiabetes'
                title='Diabetes'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliDiabetes}
                error={errors.cpliDiabetes}
                handleSelect={e => handleChange(e)}
                data={[{ cpliDiabetes: 1, title: 'SI' }, { cpliDiabetes: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliCorArtDis'
                title='Enfermedades de las arterias coronarias'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliCorArtDis}
                error={errors.cpliCorArtDis}
                handleSelect={e => handleChange(e)}
                data={[{ cpliCorArtDis: 1, title: 'SI' }, { cpliCorArtDis: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliHeaAtt'
                title='Infarto'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliHeaAtt}
                error={errors.cpliHeaAtt}
                handleSelect={e => handleChange(e)}
                data={[{ cpliHeaAtt: 1, title: 'SI' }, { cpliHeaAtt: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliHasConAcqPhyMenLim'
                title='Tiene alguna limitación física o mental congénita o adquirida'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliHasConAcqPhyMenLim}
                error={errors.cpliHasConAcqPhyMenLim}
                handleSelect={e => handleChange(e)}
                data={[{ cpliHasConAcqPhyMenLim: 1, title: 'SI' }, { cpliHasConAcqPhyMenLim: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliDiaTreDisOthThoMenAbo'
                title='Le han diagnosticado o tratado alguna enfermedad diferente a las citadas arriba'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliDiaTreDisOthThoMenAbo}
                error={errors.cpliDiaTreDisOthThoMenAbo}
                handleSelect={e => handleChange(e)}
                data={[{ cpliDiaTreDisOthThoMenAbo: 1, title: 'SI' }, { cpliDiaTreDisOthThoMenAbo: 2, title: 'NO' }]}
            />
            <Row>
                <InputDateWeb testID='327aa03c-6426-47b5-b7b6-faab1e981e19' flex={100} title='Fecha de diagnóstico *' name='cpliDatDia' error={errors.cpliDatDia} value={values?.creditPayrollLifeInsurance?.cpliDatDia} onChangeText={handleChange} />
            </Row>
            <Row>
                <InputHook flex={100} title='Tratamiento' name='cpliTreatment' error={errors.cpliTreatment} value={values?.creditPayrollLifeInsurance?.cpliTreatment} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook flex={100} title='Estado actual' name='cpliActSta' error={errors.cpliActSta} value={values?.creditPayrollLifeInsurance?.cpliActSta} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <SelectHook
                required
                nameAtt='title'
                name='cpliIsPre'
                title='Está embarazada'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliIsPre}
                error={errors.cpliIsPre}
                handleSelect={e => handleChange(e)}
                data={[{ cpliIsPre: 1, title: 'SI' }, { cpliIsPre: 2, title: 'NO' }]}
            />
            {values?.creditPayrollLifeInsurance?.cpliIsPre === 1 && (
                <>
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpliPrimitive'
                        title='Es primigestante'
                        titleModal='Seleccionar Opción'
                        value={values?.creditPayrollLifeInsurance?.cpliPrimitive}
                        error={errors.cpliPrimitive}
                        handleSelect={e => handleChange(e)}
                        data={[{ cpliPrimitive: 1, title: 'SI' }, { cpliPrimitive: 2, title: 'NO' }]}
                    />
                    <SelectHook
                        required
                        nameAtt='title'
                        name='ptId'
                        title='Tiempo de gestación'
                        titleModal='Seleccionar Opción'
                        value={values?.creditPayrollLifeInsurance?.ptId}
                        error={errors.ptId}
                        handleSelect={e => handleChange(e)}
                        data={pregnacyTimes?.map(x => { return { ptId: x?.ptId, title: x?.ptName } }) || []}
                    />
                </>
            )}
            <SelectHook
                required
                nameAtt='title'
                name='cpliDisabilityPensioner'
                title='¿Eres pensionado por invalidez?'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliDisabilityPensioner}
                error={errors.cpliDisabilityPensioner}
                handleSelect={e => handleChange(e)}
                data={[{ cpliDisabilityPensioner: 1, title: 'SI' }, { cpliDisabilityPensioner: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='cpliUseDrugsAlcohol'
                title='¿Consume bebidas alcohólicas mas de 3 veces por semana o alguna sustancia psicoactiva?'
                titleModal='Seleccionar Opción'
                value={values?.creditPayrollLifeInsurance?.cpliUseDrugsAlcohol}
                error={errors.cpliUseDrugsAlcohol}
                handleSelect={e => handleChange(e)}
                data={[{ cpliUseDrugsAlcohol: 1, title: 'SI' }, { cpliUseDrugsAlcohol: 2, title: 'NO' }]}
            />
            <Row>
                <InputHook flex={100} title='Nombre de la Enfermedad' name='cpliDiseaseName' error={errors?.cpliDiseaseName} value={values?.creditPayrollLifeInsurance?.cpliDiseaseName} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook flex={100} title='Secuelas o condiciones' name='cpliConsequences' error={errors.cpliConsequences} value={values?.creditPayrollLifeInsurance?.cpliConsequences} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook flex={100} title='Observaciones o comentarios adicionales' name='cpliObservations' error={errors.cpliObservations} value={values?.creditPayrollLifeInsurance?.cpliObservations} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook flex={100} title='Tratamiento Actual' name='cpliCurrentTreatment' error={errors.cpliCurrentTreatment} value={values?.creditPayrollLifeInsurance?.cpliCurrentTreatment} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook flex={100} title='Tratamientos médicos o cirugías realizadas' name='cpliTreatmentOrSurgery' error={errors.cpliTreatmentOrSurgery} value={values?.creditPayrollLifeInsurance?.cpliTreatmentOrSurgery} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>

            <BoxButton>
                <ButtonSubmit testID='181ec7bf-bafd-4301-b0e3-9977b42b77e4' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

LifeInsuranceDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    userParameters: PropTypes.object,
    pregnacyTimes: PropTypes.array,
}