import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_1"
        data-name="Capa 1"
        viewBox="0 0 392 392"
        {...props}
    >
        <Circle
            cx={196}
            cy={196.29}
            r={190.5}
            style={{
                fill: '#83beea',
                strokeWidth: 0,
            }}
        />
        <Circle
            cx={196}
            cy={196.91}
            r={155.8}
            style={{
                fill: '#1e679e',
                strokeWidth: 0,
            }}
        />
        <Circle
            cx={196}
            cy={196.91}
            r={119.3}
            style={{
                strokeWidth: 0,
                fill: '#002449',
            }}
        />
        <Path
            d="m120.03 202.86 15.15-27.45 55.85 90.88h-32.19l-38.81-63.43zM138.97 169.73l8.52 13.25s5.54-9.32 7.57-12.31c.81-1.19 4.22-2.24 5.68 0 12.31 18.93 55.85 90.88 55.85 90.88l17.04.95 7.57-14.2s-49.7-87.56-68.63-115.96c-1.41-2.11-6.63-3.79-10.13 0-7.37 7.95-23.47 37.39-23.47 37.39ZM199.55 165.94l8.52 14.2s6.01-11.69 8.05-14.67c.81-1.19 2.94-2.83 4.73 0 14.67 23.19 35.5 58.22 35.5 58.22l15.62-27.93s-21.3-36.45-39.29-65.79c-1.78-2.91-10.77-3.76-13.73.47-6.63 9.47-19.41 35.5-19.41 35.5Z"
            className="cls-2"
            style={{
                strokeWidth: 0,
                fill: '#ffff',
            }}
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}