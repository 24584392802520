import React from 'react'
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'
import { wp } from '../validation'

export const SelectS = props => (
    <ContentLoader
        speed={1}
        width={wp(100)}
        height={500}
        viewBox='0 0 350 500'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
    >
        <Rect x='10' y='10' rx='5' ry='5' width='200' height='10' />
        <Circle cx='320' cy='15' r='10' />
        <Rect x='0' y='40' rx='0' ry='0' width='350' height='2' />
        <Rect x='10' y='65' rx='5' ry='5' width='250' height='10' />
        <Circle cx='320' cy='70' r='10' />
        <Rect x='0' y='95' rx='0' ry='0' width='350' height='2' />
        <Rect x='10' y='165' rx='5' ry='5' width='120' height='10' />
        <Circle cx='320' cy='170' r='10' />
        <Rect x='0' y='195' rx='0' ry='0' width='350' height='2' />
        <Rect x='10' y='115' rx='5' ry='5' width='80' height='10' />
        <Circle cx='320' cy='120' r='10' />
        <Rect x='0' y='145' rx='0' ry='0' width='350' height='2' />
        <Rect x='10' y='270' rx='5' ry='5' width='200' height='10' />
        <Circle cx='320' cy='275' r='10' />
        <Rect x='0' y='300' rx='0' ry='0' width='350' height='2' />
        <Rect x='10' y='220' rx='5' ry='5' width='220' height='10' />
        <Circle cx='320' cy='225' r='10' />
        <Rect x='0' y='250' rx='0' ry='0' width='350' height='2' />
        <Rect x='10' y='320' rx='5' ry='5' width='90' height='10' />
        <Circle cx='320' cy='325' r='10' />
        <Rect x='0' y='350' rx='0' ry='0' width='350' height='2' />
        <Rect x='10' y='425' rx='5' ry='5' width='210' height='10' />
        <Circle cx='320' cy='430' r='10' />
        <Rect x='0' y='455' rx='0' ry='0' width='350' height='2' />
        <Rect x='10' y='375' rx='5' ry='5' width='200' height='10' />
        <Circle cx='320' cy='380' r='10' />
        <Rect x='0' y='405' rx='0' ry='0' width='350' height='2' />
    </ContentLoader>
)