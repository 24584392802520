import { gql } from '@apollo/client';

export const ALL_GROUP_LINE = gql`
    query GetGroupCreditLine($state: [Int]) {
    getGroupCreditLine(state: $state) {
        gclId
        gclName
        gclState
        groupCreditLineDetails {
            ctId
            gclId
            gcldId
            creditType {
                ctId
                ctName
                ctMultiple
                ctReTanking
                ctOptPay
                ctGroup
                mId
                ctMaxCap
                ctMinCap
                valueCredit {
                    vcId
                    vcMinVal
                    vcMaxVal
                    vcDefVal
                    mId
                    month {
                        mValue
                    }
                }
                rId
                rate {
                    rPor
                }
                month {
                    mId
                    mValue
                }
            }
        }
    }
    }
`;

export const ALL_INFO_CLIENT = gql`
    query getPayrollCompanyById($state: [Int]) {
        getPayrollCompanyById(state: $state) {
            pcId
            cId
            companyCreditTypes {
                ctId
                creditType {
                    ctId
                    ctName
                    ctMultiple
                    ctReTanking
                    ctOptPay
                    ctGroup
                    mId
                    valueCredit {
                        vcId
                        vcMinVal
                        vcMaxVal
                        vcDefVal
                        mId
                        month {
                            mValue
                        }
                    }
                    rId
                    rate {
                        rPor
                    }
                    month {
                        mValue
                    }
                    ctMaxCap
                    ctDescription
                }
            }
        }
    }
`;