import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { G, LinearGradient, Path, Stop } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ size, props }) => (
    <Svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        size={size}
        viewBox="0 0 392 392"
        style={{
            enableBackground: 'new 0 0 392 392',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <Path
            className="st0"
            fill="#C3CDED"
            d="M387.6,195.1c0,103.9-84.2,188.1-188.1,188.1c-103.8,0-188-84.2-188-188.1c0-103.8,84.2-188,188-188 C303.4,7.1,387.6,91.3,387.6,195.1z"
        />
        <Path
            className="st1"
            fill="#A7ADE2"
            d="M347.2,195.1c0,81.5-66.1,147.7-147.7,147.7S51.8,276.7,51.8,195.1S118,47.5,199.5,47.5 C281.1,47.5,347.2,113.6,347.2,195.1z"
        />
        <Path
            className="st2"
            fill="#ffffff"
            d="M313.7,195.1c0,63.1-51.1,114.2-114.2,114.2S85.3,258.2,85.3,195.1S136.4,80.9,199.5,80.9 S313.7,132.1,313.7,195.1z"
        />
        <G>
            <G>
                <LinearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1={172.1557}
                    y1={160.97}
                    x2={172.1557}
                    y2={221.07}
                    gradientTransform="matrix(1 0 0 -1 0 393.2756)"
                >
                    <Stop
                        offset={0}
                        style={{
                            stopColor: '#113DBF',
                        }}
                    />
                    <Stop
                        offset={0.3126}
                        style={{
                            stopColor: '#1139BF',
                        }}
                    />
                    <Stop
                        offset={0.6359}
                        style={{
                            stopColor: '#112CBF',
                        }}
                    />
                    <Stop
                        offset={0.9344}
                        style={{
                            stopColor: '#111ABF',
                        }}
                    />
                </LinearGradient>
                <Path
                    className="st3"
                    d="M181.3,172.2v60.1H163v-57.7C169.2,174.2,175.4,173.4,181.3,172.2z"
                />
                <LinearGradient
                    id="SVGID_00000039845399316314369180000009039675173290280864_"
                    gradientUnits="userSpaceOnUse"
                    x1={198.1557}
                    y1={161.07}
                    x2={198.1557}
                    y2={229.77}
                    gradientTransform="matrix(1 0 0 -1 0 393.2756)"
                >
                    <Stop
                        offset={0}
                        style={{
                            stopColor: '#113DBF',
                        }}
                    />
                    <Stop
                        offset={0.2956}
                        style={{
                            stopColor: '#1139BF',
                        }}
                    />
                    <Stop
                        offset={0.6013}
                        style={{
                            stopColor: '#112CBF',
                        }}
                    />
                    <Stop
                        offset={0.8835}
                        style={{
                            stopColor: '#111ABF',
                        }}
                    />
                </LinearGradient>
                <Path
                    style={{
                        fill: 'url(#SVGID_00000039845399316314369180000009039675173290280864_)',
                    }}
                    d="M207.3,163.5v68.7H189v-61.9 C195.3,168.5,201.4,166.3,207.3,163.5z"
                />
                <LinearGradient
                    id="SVGID_00000074411440444693779930000004136655552551654318_"
                    gradientUnits="userSpaceOnUse"
                    x1={224.1557}
                    y1={160.97}
                    x2={224.1557}
                    y2={246.17}
                    gradientTransform="matrix(1 0 0 -1 0 393.2756)"
                >
                    <Stop
                        offset={0}
                        style={{
                            stopColor: '#113DBF',
                        }}
                    />
                    <Stop
                        offset={0.2877}
                        style={{
                            stopColor: '#1139BF',
                        }}
                    />
                    <Stop
                        offset={0.5853}
                        style={{
                            stopColor: '#112CBF',
                        }}
                    />
                    <Stop
                        offset={0.86}
                        style={{
                            stopColor: '#111ABF',
                        }}
                    />
                </LinearGradient>
                <Path
                    style={{
                        fill: 'url(#SVGID_00000074411440444693779930000004136655552551654318_)',
                    }}
                    d="M233.3,147.1v85.2H215v-72.6 C221.5,156,227.6,151.8,233.3,147.1z"
                />
            </G>
            <LinearGradient
                id="SVGID_00000033362485497941455190000003139148972379195280_"
                gradientUnits="userSpaceOnUse"
                x1={123.0057}
                y1={211.12}
                x2={254.4057}
                y2={211.12}
                gradientTransform="matrix(1 0 0 -1 0 393.2756)"
            >
                <Stop
                    offset={0}
                    style={{
                        stopColor: '#113DBF',
                    }}
                />
                <Stop
                    offset={1}
                    style={{
                        stopColor: '#27DEF2',
                    }}
                />
            </LinearGradient>
            <Path
                style={{
                    fill: 'url(#SVGID_00000033362485497941455190000003139148972379195280_)',
                }}
                d="M254.4,149.6 c-12.7-13.7-30.9-22.3-51.1-22.3c-38.4,0-69.5,31.1-69.5,69.5c0,20.2,8.6,38.4,22.4,51.1C136.3,235.2,123,213,123,187.6 c0-39.3,31.9-71.2,71.2-71.2C219.6,116.4,241.8,129.6,254.4,149.6z"
            />
            <LinearGradient
                id="SVGID_00000150100493758075348740000013325117682993752463_"
                gradientUnits="userSpaceOnUse"
                x1={121.5057}
                y1={167.9756}
                x2={288.5057}
                y2={167.9756}
                gradientTransform="matrix(1 0 0 -1 0 393.2756)"
            >
                <Stop
                    offset={0}
                    style={{
                        stopColor: '#27DEF2',
                    }}
                />
                <Stop
                    offset={1}
                    style={{
                        stopColor: '#111ABF',
                    }}
                />
            </LinearGradient>
            <Path
                style={{
                    fill: 'url(#SVGID_00000150100493758075348740000013325117682993752463_)',
                }}
                d="M288.5,203.1l-7.6-28.1L263,198l9.6,1.9 l0,0c-1.5,8.1-4.3,16.1-8.5,23.7c-20.8,37.4-68,51-105.4,30.2c-19-10.6-31.9-27.9-37.2-47.4c3.1,24.1,17.3,46.5,40.2,59.2 c38.3,21.3,86.7,7.4,107.9-30.9c5.9-10.6,9.1-22,9.8-33.4L288.5,203.1z"
            />
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}