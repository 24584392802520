import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InboxDetailsV } from '../../components/InboxDetails/index.jsx'
import { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREDIT_REPORTS, SET_SEEN_CREDIT_REPORT } from './queries.jsx';
import { getFileUrlS3 } from '../../validation/index.js';

export const InboxDetailsC = ({ route }) => {
    const [state, setState] = useState([])
    const { crId, notification, comment, payroll, credit, creditReportDocuments, cId, crLink } = route.params;
    const [updateCreditReports] = useMutation(SET_SEEN_CREDIT_REPORT)
    const [getCreditReports] = useLazyQuery(CREDIT_REPORTS, { fetchPolicy: 'cache-and-network' })
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await updateCreditReports({ variables: { crId } });
                if (data) {
                    await getCreditReports({ variables: { pcId: payroll?.getPayrollCompanyById?.pcId, order: [['crSeen', 'ASC']] } });
                }
            } catch (error) {
                return error
            }
        };

        fetchData();
    }, [crId, payroll, updateCreditReports, getCreditReports, creditReportDocuments]);
    const handleDownload = async (value, fileName) => {
        const uri =
            (await getFileUrlS3(
                `credits/${cId}/report/${fileName}`
            )) || '';
        const elemA = document.getElementById(`downloadFile${value}`);

        if (elemA) {
            elemA.setAttribute('href', uri);
            elemA.click();
        }
    };
    useEffect(() => {
        setState([])
        setTimeout(() => {
            setState(creditReportDocuments)
        }, [])
    }, [creditReportDocuments])
    return (
        <InboxDetailsV
            notification={notification}
            comment={comment}
            credit={credit}
            creditReportDocuments={state}
            handleDownload={handleDownload}
            crLink={crLink}
        />
    )
}

InboxDetailsC.propTypes = {
    route: PropTypes.object,
    creditReportDocuments: PropTypes.array,
}