import { gql, useQuery } from '@apollo/client'

export const USE_ALL_PREGNANCY_TIMES = ({ state, order, limit }) => useQuery(gql`
    query pregnancyTimes($state: [Int], $order: [[String]], $limit: [Int]) {
        pregnancyTimes(state: $state, order: $order, limit: $limit) {
            ptId
            ptName
        }
    }
`, {
    variables: { state, order, limit }
})