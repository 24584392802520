import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { USE_ALL_DEPENDENTS } from '../../queries/Dependents'
import { SelectHook } from '../SelectHook'

export const Dependents = ({ testID, value, handleSelect, handleCancel, use = {}, disabled, error }) => {
    const [res, setRes] = useState([])

    const { data, loading } = USE_ALL_DEPENDENTS({ order: [['dName', 'ASC']], ...use })

    useEffect(() => {
        if (data?.dependents && !loading) setRes(data?.dependents || [])
    }, [data])

    return (
        <SelectHook
            data={res}
            error={error}
            loading={loading}
            title='Personas a Cargo *'
            value={value}
            name='dId'
            nameAtt='dName'
            titleModal='Seleccionar Personas a Cargo'
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
            testID={testID}
        />
    )
}

Dependents.propTypes = {
    value: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool
}