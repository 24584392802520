import React from 'react'
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'
import { wp } from '../validation'

export const ProfileHeaderS = props => (
    <ContentLoader
        speed={2}
        width={wp(88)}
        height={50}
        viewBox='0 0 450 50'
        backgroundColor='#e6e6e6'
        foregroundColor='#ffffff'
        {...props}
    >
        <Rect x='10' y='5' rx='5' ry='5' width='300' height='10' />
        <Rect x='10' y='20' rx='5' ry='5' width='172' height='10' />
        <Rect x='10' y='35' rx='5' ry='5' width='218' height='10' />
        <Circle cx='400' cy='26' r='24' />
    </ContentLoader>
)