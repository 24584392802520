import { gql } from '@apollo/client'

export const CREATE_CREDIT = gql`
    mutation createCredit($input: ICreateCredit!) {
        createCredit(input: $input) {
            cId
        }
    }
`

export const CREATE_CREDIT_GROUP = gql`
    mutation createCreditGroup($input: ICreateCreditGroup!) {
        createCreditGroup(input: $input) {
            cgId
        }
    }
`