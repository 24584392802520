import React from 'react'
import Svg, { Path } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
export const LogoLogin = ({ width, height, color, style }) => (
    <Svg x='0px' y='0px' width={width} height={height} style={style} viewBox='0 0 292 158.97'>
        <Path fill={color} d="M142.6,18Q132.46,5.59,113.4,5.58q-12,0-20.2,4.75T77.86,26.15Q66.16,5.59,44.41,5.58,26.08,5.58,14.58,18T3.08,49.81V154.68H45.13V60q0-11.61,6.22-11.61,3.32,0,4.45,2.34T56.94,60v52.77c8.19,1.51,24.23,1.93,42.06-5.21V59.3q0-10.87,6.22-10.87,3.32,0,4.39,2.29c.73,1.53,1.09,4.63,1.09,9.32v41.85a95.82,95.82,0,0,0,16.78-12.5,68.48,68.48,0,0,0,6.71-9c4.38-7,6.56-13.66,6.34-18.12a48.7,48.7,0,0,1-2,22.75,55,55,0,0,1-5.31,11.45,73.79,73.79,0,0,1-22.56,22.87v35.3h42.06V53.54q0-23.24-10.16-35.59M67.19,134.61a75.42,75.42,0,0,1-10.25.44v19.63H71.07C86.49,154.68,99,141.82,99,126a96.18,96.18,0,0,1-31.81,8.65" />
        <Path fill={color} d="M168.78,154.68h27.5V79.26h-27.5Zm22-92.27a13.67,13.67,0,0,1-13.06-15.94,13.76,13.76,0,0,0-4.82,3.18,13.65,13.65,0,0,0-.22,19.25,14.82,14.82,0,0,0,19.77,0,12.52,12.52,0,0,0,3.74-7.68,12.74,12.74,0,0,1-5.41,1.19" />
        <Path fill={color} d="M217.78,51.43h-5.32V154.68H240V74.24c0-12.6-9.92-22.81-22.17-22.81" />
        <Path fill={color} d="M256.05,154.67h27.5V79.26h-27.5Zm23.49-105a13.56,13.56,0,0,0-19.33,0A13.67,13.67,0,0,0,260,68.9a14.83,14.83,0,0,0,19.78,0,13.65,13.65,0,0,0-.22-19.25" />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}