import { Logo } from './Logo'
import Splash from './splash.png';
import { LogoLogin } from './LogoLoginPass'
import { LogoSecond } from './LogoSecond'

export const finanzas = {
    Logo,
    Splash,
    LogoLogin,
    LogoSecond
}