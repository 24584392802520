
import styled, { css } from 'styled-components/native'
import { Animated } from 'react-native'
import theme from '../../../ignore/Constants'
import { mq } from '../../validation'

export const Button = styled.TouchableOpacity`
    align-items: center;
    align-self: center;
    justify-content: center;
    border-radius: ${({ radius }) => radius || mq(25)}px;
    padding: ${({ padding }) => padding || mq(10)}px;
    position: relative;
    background-color: ${({ bgColor }) => bgColor || theme.primaryC};
    width: ${({ width }) => width ? `${width}px` : '60%'};
    ${({ margin }) => margin && css`margin: ${margin};`}
`
export const Title = styled.Text`
    font-size: ${({ size }) => size || mq(13)}px;
    font-family: PFontBold;
    color: ${({ color }) => color || theme.backgroundC};
`
export const LoadingButton = styled(Animated.View)`
    position: absolute;
    bottom: 0px;
    height: 3px;
    background-color: ${({ bgColor }) => bgColor || theme.backgroundC};
    width: ${({ width }) => width || '80%'};
`