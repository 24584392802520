// Modules
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Animated } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { useTheme } from 'styled-components/native'

// Components
import { ModalBannerInfo } from '../ModalBannerInfo'

// Styles and Utils
import { mq } from '../../validation'
import { Container, Title, BoxInfo, Button, BoxEffect } from './styled'

/**
 * Banner Info Component
 * @param {any} props Properties Banner Info
 * @returns {ReactElement} BannerInfoData
 */
export const BannerInfoData = ({
    steps,
    title,
    iconModal,
    handleInfo,
    titleModal,
    disabledInfo,
    handleCamera,
    handleDocument,
}) => {
    // States and Variables
    const [isVisible, setIsVisible] = useState(false)
    const opaOut = new Animated.Value(.3)
    const opaIn = new Animated.Value(.5)
    const theme = useTheme()

    if (!disabledInfo) {
        Animated.loop(
            Animated.parallel([
                Animated.timing(opaOut, {
                    toValue: 0.05,
                    duration: 1000,
                    useNativeDriver: false
                }),
                Animated.timing(opaIn, {
                    toValue: 0.1,
                    duration: 1200,
                    useNativeDriver: false
                }),
            ]),
            { iterations: Infinity }
        ).start()
    }

    // Activar modal
    const handleInfoButton = () => {
        setIsVisible(true)
        handleInfo && handleInfo()
    }

    return (
        <Container>
            <Title>{title}</Title>
            <BoxInfo>
                {!disabledInfo &&
                    <>
                        <Button onPress={handleInfoButton}>
                            <AntDesign name='infocirlceo' size={mq(26)} color={`${theme.PColor}88`} />
                        </Button>
                        <BoxEffect style={{ opacity: opaOut }} />
                        <BoxEffect style={{ opacity: opaIn }} width={mq(32)} height={mq(32)} />
                    </>
                }
            </BoxInfo>
            <ModalBannerInfo isVisible={isVisible} handleCancel={() => setIsVisible(false)} title={titleModal} steps={steps} icon={iconModal} handleCamera={handleCamera} handleDocument={handleDocument} />
        </Container>
    )
}

BannerInfoData.propTypes = {
    title: PropTypes.string,
    handleInfo: PropTypes.func,
    disabledInfo: PropTypes.bool,
    handleCamera: PropTypes.func,
    handleDocument: PropTypes.func,
    iconModal: PropTypes.node,
    steps: PropTypes.array,
    titleModal: PropTypes.string
}