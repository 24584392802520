import { gql } from '@apollo/client'

export const UPDATE_PAYROLL_COMPANY = gql`
  mutation UpdatePayrollOtherInformation($input: IUpdatePayrollOtherInformation!, $pcId: ID) {
    updatePayrollOtherInformation(input: $input, pcId: $pcId) {
      poiId
      pcId
      poiAffiliationNumber
    }
  }
`