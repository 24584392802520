
import Constants from 'expo-constants'
import styled, { css } from 'styled-components/native'
import { mq } from '../../validation'
import theme from '../../../ignore/Constants'

export const Input = styled.TextInput`
    font-family: ${({ family }) => family};
    font-size: ${mq(13)}px;
    text-align: ${({ align }) => align || 'left'};
    width: 100%;
    padding: ${Constants.platform.ios ? mq(6) : Constants.platform.android ? mq(5) : mq(8) }px ${mq(20)}px;
    border-radius: ${mq(10)}px;
    color: ${props => props.theme.SFSColor};
    ${Constants.platform.web && css`outline-width: 0;`}
    background-color: ${({ bgColor }) => bgColor || theme.backgroundP };
`
export const Container = styled.View`
    position: relative;
    padding-top: ${mq(4)}px;
    margin-bottom: ${mq(8)}px;
    width: 100%;
    ${({ flex }) => flex && css`flex: ${flex};`}
`
export const Title = styled.Text`
    font-family: PFontMedium;
    font-size: ${mq(13)}px;
    margin: 0 ${mq(5)}px 0 ${mq(10)}px;
    color: ${theme.onSurfaceV};
`
export const TextError = styled.Text`
    font-family: PFontRegular;
    font-size: ${mq(12)}px;
    position: absolute;
    margin: 0 ${mq(5)}px 0 ${mq(10)}px;
    bottom: -${mq(15)}px;
    color: ${props => props.theme.EColor};
`
export const LeftBorder = styled.View`
    height: 45%;
    width: 2px;
    position: absolute;
    bottom: 10%;
    left: ${mq(8)}px;
    background-color: ${ theme.primaryC };
    z-index: 99;
`