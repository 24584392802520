import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1366 1376"
        style={{
            enableBackground: 'new 0 0 1366 1376',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <Circle
            cx={695}
            cy={688}
            r={647}
            style={{
                opacity: 0.17,
                fill: '#0071bc',
            }}
        />
        <Circle
            cx={695}
            cy={688}
            r={498}
            style={{
                opacity: 0.62,
                fill: '#fff',
            }}
        />
        <Path
            d="M369.4 692.7v-1.8c0-181.3 136.7-329.8 332.6-329.8 120.3 0 192.3 40.1 251.5 98.4l-89.3 103c-49.2-44.6-99.3-72-163.1-72-107.5 0-185 89.3-185 198.6v1.8c0 109.3 75.6 200.4 185 200.4 72.9 0 117.5-29.2 167.6-74.7l89.3 90.2c-65.6 70.2-138.5 113.9-261.5 113.9-187.7 0-327.1-144.9-327.1-328"
            style={{
                fill: '#0071bc',
            }}
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    color2: PropTypes.any,
    color3: PropTypes.any,
    color4: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}