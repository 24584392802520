import { gql } from '@apollo/client'

export const USE_ONE_PAYROLL_COMPANY = gql`
    query getOnePayrollCompany($state: [Int]) {
        getPayrollCompanyById(state: $state) {
            pcId
            tiId
            typeIdentity {
                tiName
            }
            pcIdeNum
            pcState
            payrollStaff {
                psFirNam
                psSecNam
                psFirLas
                psSecLas
                psEmail
            }
        }
    }
`

export const CREDIT_REPORTS = gql`
    query GetCreditReportsByPcId($pcId: ID, $order: [[String]]) {
        getCreditReportsByPcId(pcId: $pcId, order: $order) {
          crId
          cId
          nId
          credit {
            cConsecutive
            cName
          }
          creditReportDocuments {
            crdState
            crdId
            crdFileName
            crId
        }
          crType
          crDatPen
          crComment
          crSeen
          crState
          crLink
          crEdit
          notification {
            nId
            nCode
            nName
            nTitle
            nMessage
            nState
          }
        }
    }
`