import React from 'react'
import PropTypes from 'prop-types'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { wp } from '../../validation'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Title } from './styled'
import theme from '../../../ignore/Constants'

export const RequestCreditEditV = ({ navigation }) => (
    <Container style={{ width: '100%' }}>
        <Title>DATOS ACTUALIZADOS CON ÉXITO</Title>
        <MaterialCommunityIcons name='square-edit-outline' size={wp(40.5)} color={theme.primaryC} />
        <ButtonSubmit title='VOLVER AL INICIO' onPress={() => navigation.navigate('Home')} />
    </Container>
)

RequestCreditEditV.propTypes = {
    request: PropTypes.bool,
    navigation: PropTypes.object
}