// Modules
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Animated, Dimensions } from 'react-native'
import { useTheme } from 'styled-components/native'
import Constants from 'expo-constants'
import { Fontisto, FontAwesome5, MaterialCommunityIcons, Ionicons } from '@expo/vector-icons'

// Styles and Utils
import { mq } from '../../validation'
import { ShadowBox } from '../../generalStyles'
import { BoxIcon, ContainerIcon, TitleHead, /* Figura */ } from './styled'

/**
 * State Bar Component
 * @param {any} props Properties States Bar
 * @returns {FC} StateBar Component
 */
export const StatesBar = ({ check, cState, cCard, cPending, cEdit, widthRow, cIs360 }) => {
    // States and Variables
    const theme = useTheme()
    const animStateSize = useRef(new Animated.Value(0)).current
    const animState = useRef(new Animated.ValueXY(0, 0)).current
    const [stateValidation, setStateValidation] = useState({ pending: false, review: false, document: false, finish: false, cancel: false })

    /**
     * Function animate box State
     * @param {number} xVal x-axis value
     * @param {number} yVal y-axis value
     * @param {number} size size indicator
     * @return {void}
     */
    const moveState = (xVal, yVal, size) => {
        Animated.parallel([
            Animated.timing(animState, {
                toValue: {
                    x: xVal,
                    y: yVal
                },
                duration: 200,
                useNativeDriver: true,
            }),
            Animated.timing(animStateSize, {
                toValue: size,
                duration: 200,
                useNativeDriver: false
            })
        ]).start()
    }

    // Effect to active validation state
    useEffect(() => {
        const pending = (cState === 10 || (cState === 3 && cCard === 1) || cPending === 1 || cEdit === 1)
        setStateValidation({
            pending: pending && (cState !== 1 && cState !== 0),
            review: !pending && (cState === 2 || (cState === 3 && cCard !== 1) || cState === 7 || cState === 8 || cState === 9),
            document: !pending && cState === 4,
            finish: cState === 1 || cState === 5 || cState === 6 || cState === 13,
            cancel: cState === 0,
            approve360: cState === 13,
        })
    }, [cState, cCard, cPending, cEdit])

    // Effect to animation box state
    useEffect(() => {
        if (check) {
            moveState(
                mq((Dimensions.get('screen').width > 600 && Constants.platform.ios) ? widthRow - 190 : widthRow - 80),
                -mq((Dimensions.get('screen').width > 600 && Constants.platform.ios) ? 35 : 20),
                1
            )
        }
        if (!check) moveState(0, 0, 0)
    }, [check])

    return (
        <>
            {!(check && !stateValidation.pending) &&
                <BoxIconComponent
                    check={check}
                    theme={theme}
                    color={theme.WColor}
                    title='Pendiente'
                    animState={animState}
                    animStateSize={animStateSize}
                    stateValidation={stateValidation.pending}
                >
                    <MaterialCommunityIcons
                        name="progress-clock"
                        size={mq(20)}
                        color={stateValidation.pending ? theme.BGColor : `${theme.PColor}77`}
                    />
                </BoxIconComponent>
            }

            {!(check && !stateValidation.review) &&
            <BoxIconComponent
                check={check}
                theme={theme}
                title= {cIs360 === 1 ? 'Revisión 360' : 'Revisión'}
                animState={animState}
                animStateSize={animStateSize}
                stateValidation={stateValidation.review}
            >
                <Fontisto
                    name='preview'
                    size={mq(15)}
                    color={stateValidation.review ? theme.BGColor : `${theme.PColor}77`}
                />
            </BoxIconComponent>}

            {!(check && !stateValidation.document) &&
            <BoxIconComponent
                check={check}
                theme={theme}
                title='Firma'
                animState={animState}
                animStateSize={animStateSize}
                stateValidation={stateValidation.document}
            >
                <FontAwesome5
                    name="signature"
                    size={mq(15)}
                    color={stateValidation.document ? theme.BGColor : `${theme.PColor}77`}
                />
            </BoxIconComponent>}

            {stateValidation.cancel ?
                !(check && !stateValidation.cancel) &&
                    <BoxIconComponent
                        check={check}
                        theme={theme}
                        title='Cancelado'
                        color={theme.EAColor}
                        animState={animState}
                        animStateSize={animStateSize}
                        stateValidation={stateValidation.cancel}
                    >
                        <MaterialCommunityIcons
                            name="cancel"
                            size={mq(20)}
                            color={theme.BGColor}
                        />
                    </BoxIconComponent>
                : !(check && !stateValidation.finish) &&
                <BoxIconComponent
                    check={check}
                    theme={theme}
                    title={cState === 1 ? 'Finalizado' : cState === 13 ? 'Aprobado 360' : cState === 5 ? 'Desembolsar' : 'Desembolsado'}
                    animState={animState}
                    animStateSize={animStateSize}
                    stateValidation={stateValidation.finish}
                >
                    <Ionicons
                        name="checkmark-done-outline"
                        size={mq(20)}
                        color={stateValidation.finish ? theme.BGColor : `${theme.PColor}77`}
                    />
                </BoxIconComponent>
            }
        </>
    )
}

// PropTypes validations
StatesBar.propTypes = {
    check: PropTypes.bool,
    cEdit: PropTypes.number,
    cCard: PropTypes.number,
    cState: PropTypes.number,
    cIs360: PropTypes.number,
    cPending: PropTypes.number,
    widthRow: PropTypes.number,
}

// Reusable Component for this view
export const BoxIconComponent = ({ check, animStateSize, animState, stateValidation, theme, title, color, children }) => {
    return (
        <ContainerIcon
            marginR={'3%'}
            width={stateValidation ? '37%' : '18%'}
            style={{
                height: check ? 0 : 'auto',
                transform: [{ translateY: mq(animState.y) }, { translateX: mq(animState.x) }]
            }}
        >
            <BoxIcon
                style={[
                    ShadowBox,
                    {
                        borderRadius: check > 0 ? mq(500) : mq(6),
                        width: animStateSize.interpolate({
                            inputRange: [0, 1],
                            outputRange: ['100%', '50%']
                        }),
                        height: animStateSize.interpolate({
                            inputRange: [0, 1],
                            outputRange: [mq(40), mq(25)]
                        })
                    }
                ]}
                bgColor={stateValidation ? color || theme.PColor : theme.BGColor}
            >
                {children}
                {(stateValidation && !check) &&
                    <TitleHead ellipsizeMode='tail' numberOfLines={1}>{title}</TitleHead>
                }
            </BoxIcon>
        </ContainerIcon>
    )
}

// PropTypes validations
BoxIconComponent.propTypes = {
    check: PropTypes.bool,
    theme: PropTypes.any,
    title: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.node,
    animState: PropTypes.any,
    animStateSize: PropTypes.any,
    stateValidation: PropTypes.any,
}