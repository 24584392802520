import React from 'react'
import PropTypes from 'prop-types'
import { ContainerAlert, Row, TitleAlert, SubTitleAlert } from './styled'
import { ButtonSubmit } from '../ButtonSubmit'
import { wp } from '../../validation'
import theme from '../../../ignore/Constants'

export const Alert = ({ alertInfo: { title, message }, handleAlert }) => (
    <ContainerAlert>
        <TitleAlert>{title}</TitleAlert>
        <SubTitleAlert>{message}</SubTitleAlert>
        <Row style={{ justifyContent: 'center', marginTop: wp(3) }}>
            <ButtonSubmit styleButton={{ marginHorizontal: wp(2) }} width={wp(20)} onPress={() => handleAlert(1)} title='SI' />
            <ButtonSubmit styleButton={{ marginHorizontal: wp(2) }} bgColor={theme.errorC} width={wp(20)} onPress={() => handleAlert(0)} title='NO' />
        </Row>
    </ContainerAlert>
)

Alert.propTypes = {
    alertInfo: PropTypes.object,
    handleAlert: PropTypes.func
}