import { mq } from '../../validation'
import styled, { css } from 'styled-components/native'

/* Estilos */
export const Container = styled.View`
    flex: 1;
    padding: ${mq(12)}px;
    background-color: ${props => props.theme.BGSColor};
    align-items: center;
`
export const Row = styled.View`
    flex-direction: row;
    justify-content: ${({ justify }) => justify || 'space-between'};
    align-items: center;
    ${({ padding }) => padding && css`padding: ${padding}px;`}
`
export const TitleInfo = styled.Text`
    font-size: ${({ size }) => size || mq(13)}px;
    font-family: PFontBold;
    ${({ width }) => width && css`width: ${width};`}
    ${({ marginBottom }) => marginBottom && css`margin-bottom: ${marginBottom}px;`}
    ${({ align }) => align && css`text-align: ${align};`}
    color: ${({ color, theme }) => color || theme.PVColor };
`
export const BoxList = styled.View`
    flex: 1;
    max-width: 768px;
    margin: auto;
    width: 96%;
    margin-bottom: ${mq(12)}px;
    border-radius: ${mq(13)}px;
    background-color: ${props => props.theme.BGColor};
`
export const ButtonIconOption = styled.TouchableOpacity`
    width: ${mq(25)}px;
    height: ${mq(25)}px;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.PVColor};
    border-radius: ${mq(1100)}px;
`
export const BodyList = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: ${mq(14)}px ${({ paddingHorizontal }) => paddingHorizontal || 0}px;
    ${({ bgColor }) => bgColor && css`background-color: ${bgColor};`}
    ${({ borderBottom, theme }) => borderBottom && css`
        border-bottom-width: 1px;
        border-bottom-color: ${theme.PLColor};
    `}
`
export const TextList = styled.Text`
    font-size: ${mq(12)}px;
    font-family: PFontMedium;
    text-align: ${({ align }) => align || 'right'};
    width: ${({ width }) => width || `${mq(120)}px`};
    color: ${props => props.theme.SFColor};
`
export const BodyOptionList = styled.View`
    /* height: 120px; */
    width: 100%;
    padding: ${mq(12)}px ${mq(7)}px;
    ${({ borderBottom }) => borderBottom && css`
        border-bottom-width: 1px;
        border-bottom-color: ${props => props.theme.PLColor};
    `}
`
export const TextEmpty = styled.Text`
    font-size: ${mq(15)}px;
    font-family: PFontBold;
    text-align: center;
    color: #B5B4B7;
`