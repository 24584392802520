import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Constants from 'expo-constants'
import * as FileSystem from 'expo-file-system'
import { DocumentCardV } from '../../components/DocumentCard'
import { base64ToArrayBuffer, extFile, putFileS3, writeCache } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'
import { useMutation } from '@apollo/client'
import { UPDATE_CREDIT } from './queries'
import { USE_ALL_CREDITS } from '../Home/queries'

export const DocumentCardC = ({ navigation, route }) => {
    const [ccId, setCCId] = useState()
    const [isVisibleCamera, setIsVisibleCamera] = useState(false)
    const [credit, setCredit] = useState(route.params)
    const [updateCredit, { loading }] = useMutation(UPDATE_CREDIT)

    // evento a la escucha de los documentos
    const onPicture = async ({ event, nameFile }) => {
        // verifica si viene documento para continuar
        if (!event) return

        // variables necesarias
        const ext = nameFile ? extFile(nameFile) : 'jpeg'
        let file, base64

        // verifica si es web o nativo
        if (Constants.platform.web) {
            base64 = event
            file = base64ToArrayBuffer(event.substring(event.indexOf('base64,') + 7, event.length))
        } else {
            base64 = await FileSystem.readAsStringAsync(event, { encoding: FileSystem.EncodingType.Base64 })
            file = base64ToArrayBuffer(base64)
            base64 = event
        }

        setCredit({ ...credit, creditCards: credit?.creditCards?.map(x => x.ccId === ccId ? { ...x, file, name: `${new Date().getTime()}1.${ext}`, base64 } : x) || [] })
    }

    // Activa un evento para buscar de los créditos
    useEffect(() => {
        if (route.params?.event) onPicture({ event: route.params.event, type: route.params.type, typeA: route.params.typeA, nameFile: route.params.nameFile })
    }, [route])

    /**
     * activa el modal de buscar los documentos
     * @param {object} item valores de la cartera seleccionada
     * @return {void}
     */
    const handleDocument = item => {
        setIsVisibleCamera(true)
        setCCId(item.ccId)
    }

    const handleConfirm = async () => {
        // verifica todo los documentos de la cartera
        for (let i = 0; i < credit?.creditCards?.length; i++) {
            const { name } = credit.creditCards[i]
            if (!name) return isLoggedVar({ ...isLoggedVar(), message: 'Es necesario cargar todo los documentos requeridos.' })
        }

        const { data, error } = await updateCredit({ variables: { cId: credit?.cId, input: { cCarDoc: 1, creditCards: credit?.creditCards.map(x => ({ ccId: x.ccId, ccDocument: x.name })) } }, async update(store) {
            // actualiza el cache
            writeCache({ store, query: USE_ALL_CREDITS, array: true, id: 'cId', name: 'credits', typeName: 'Credit', variables: { state: [0, 1, 2, 3, 4, 5, 6, 7, 13], order: [['cDatCre', 'DESC']] }, dataNew: { cId: credit?.cId, cCarDoc: 1 } })

            // registra los documentos
            for (let i = 0; i < credit?.creditCards?.length; i++) {
                const { file, name } = credit.creditCards[i]
                await putFileS3(`credits/${credit?.cId}/cards/${name}`, file)
            }
        } }).catch(e => ({ error: e }))

        // verifica que no haya error
        if (error) isLoggedVar({ ...isLoggedVar(), message: error.message })

        // verifica si al respuesta fue exitosa
        if (data?.updateCredit?.cId) {
            isLoggedVar({ ...isLoggedVar(), message: 'Ha enviado los documentos de la compra de cartera exitosamente.' })
            navigation.navigate('Home')
        }
    }

    return (
        <DocumentCardV
            data={{ credit, isVisibleCamera, navigation, loading }}
            handleClose={() => setIsVisibleCamera(false)}
            handleConfirm={handleConfirm}
            handleDocument={handleDocument}
        />
    )
}

DocumentCardC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}