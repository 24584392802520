import React from 'react'
import { FontAwesome5 } from '@expo/vector-icons'
import { wp } from '../../validation'
import { Box, Title, ViewInfo, ViewTitle, Text } from './styled'
import theme from '../../../ignore/Constants'

export const DisabledV = () => (
    <ViewTitle>
        <ViewInfo>
            <FontAwesome5 name='user-alt-slash' size={wp('6%')} color={theme.errorC} />
        </ViewInfo>
        <Box>
            <Title>¡Cuenta Desactivada!</Title>
            <Text>Su cuenta ha sido desactivada por lo que no es posible procesar un nuevo crédito.</Text>
        </Box>
    </ViewTitle>
)