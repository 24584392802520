// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components/native'
import { Ionicons, MaterialIcons } from '@expo/vector-icons'

// Components
import { HeadTitle } from '../../HeadTitle'

// Styles and Utils
import { ShadowBox } from '../../../generalStyles'
import { numberFormat, mq } from '../../../validation'
import { BtnContent, Container, SubTitle, TextEye, Title, Row } from './styled'

/**
 * Head Repayment Plan Component
 * @param {any} props Properties RepaymentPlan
 * @returns {ReactElement} Head
 */
export const Head = ({ data: { credit, see }, handleOpen }) => {
    // States and Variables
    const theme = useTheme()

    return (
        <>
            <HeadTitle title='CRÉDITO' icon={<MaterialIcons name='attach-money' size={mq(26)} color={theme.PColor} />} />
            <Container style={ShadowBox}>
                <Row>
                    <Title color={theme.PColor}>{credit.ctName}</Title>
                </Row>
                {see &&
                <>
                    <Row>
                        <SubTitle>V. Desembolso</SubTitle>
                        <SubTitle>$ {numberFormat(credit?.calculator?.CalculatorCredit?.valReq)}</SubTitle>
                    </Row>
                    <Row>
                        <SubTitle>Plazo</SubTitle>
                        <SubTitle>{credit.mDefVal}</SubTitle>
                    </Row>
                    {credit.typeFormatCredit?.tfcRatBef === 1 &&
                        <Row>
                            <SubTitle>Tasa de Interés</SubTitle>
                            <SubTitle>{(credit?.calculator?.CalculatorCredit?.rate || 0).toFixed(2)} %</SubTitle>
                        </Row>
                    }
                    {credit.typeFormatCredit?.tfcValCreBef === 1 &&
                        <Row>
                            <SubTitle>V. Crédito</SubTitle>
                            <SubTitle>$ {numberFormat(Math.round(credit?.calculator?.CalculatorCredit?.valCre))}</SubTitle>
                        </Row>
                    }
                    <Row>
                        <SubTitle>V. Cuota </SubTitle>
                        <SubTitle>$ {numberFormat(Math.round(credit?.calculator?.CalculatorCredit?.valCou))}</SubTitle>
                    </Row>
                    {credit.typeFormatCredit?.tfcAttBef === 1 && credit.cres?.map((x, i) => (
                        <Row key={`${i}keyCreditCresRepaymentPlan`}>
                            <SubTitle>{x.name}</SubTitle>
                            <SubTitle>$ {numberFormat(Math.round(x.value))}</SubTitle>
                        </Row>
                    ))}
                </>
                }
                <BtnContent onPress={handleOpen}>
                    <Ionicons name={see ? 'md-eye-off' : 'md-eye'} size={mq(15)} color={theme.SColor} />
                    <TextEye >{see ? 'Ver menos' : 'Ver más'}</TextEye>
                </BtnContent>
            </Container>
        </>
    )
}

Head.propTypes = {
    data: PropTypes.object,
    handleOpen: PropTypes.func,
}