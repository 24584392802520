import { mq } from '../../validation'
import styled, { css } from 'styled-components/native'

/* Estilos */
export const Container = styled.View`
    flex: 1;
    padding: ${mq(12)}px;
    background-color: ${props => props.theme.BGSColor};
    align-items: center;
`
export const InfoPlan = styled.ScrollView`
    flex: 1;
    border-radius: ${mq(10)}px;
    background-color: ${props => props.theme.BGColor};
    width: 96%;
    margin: auto;
`
export const BoxList = styled.View`
    padding: 8px;
    width: 96px;
    ${({ bgColor }) => bgColor && css`background-color: ${bgColor};`}
    ${({ borderBottom, theme }) => borderBottom && css`
        border-bottom-width: 1px;
        border-bottom-color: ${theme.PLColor};
    `}
`
export const BoxHeadList = styled.View`
    padding: 10px;
    width: 96px;
    background-color: ${({ bgColor, theme }) => bgColor || theme.PLColor};
`
export const Label = styled.Text`
    font-size: ${({ size }) => size || mq(12)}px;
    color: ${({ color, theme }) => color || theme.SFColor};
    font-family: ${({ family }) => family || 'PFontRegular'};
    ${({ align }) => align && css`text-align: ${align};`}
    ${({ lineHeight }) => lineHeight && css`line-height: ${lineHeight}px;`}
`