import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { USE_ALL_ACADEMIC_LEVELS } from '../../queries/AcademicLevels'
import { SelectHook } from '../SelectHook'

export const AcademicLevels = ({ testID, value, handleSelect, handleCancel, use = {}, disabled, error }) => {
    const [res, setRes] = useState([])
    const { data, loading } = USE_ALL_ACADEMIC_LEVELS({ order: [['alName', 'ASC']], ...use })

    useEffect(() => {
        if (data?.academicLevels && !loading) setRes(data?.academicLevels || [])
    }, [data])

    return (
        <SelectHook
            data={res}
            error={error}
            loading={loading}
            title='Nivel Académico *'
            value={value}
            name='alId'
            nameAtt='alName'
            titleModal='Seleccionar Nivel Académico'
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
            testID={testID}
        />
    )
}

AcademicLevels.propTypes = {
    value: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool
}