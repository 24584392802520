import React from 'react'
import PropTypes from 'prop-types'
import { FlatList, View } from 'react-native'
import theme from '../../../ignore/Constants'
import { InfoPlan, Label, styles, Row, HeadPlan, ButtonCredit, BodyPlan, LabelPlan, BoxList, BoxHeadList } from './styled'
import { numberFormat, wp } from '../../validation'
import { ButtonSubmit } from '../ButtonSubmit'
let indice = 0

export const Body = ({ data: { values, infoGroup, params, allCreditTypes, oneCreditType, seePlan, seeDetail, seeCard }, handleCreditType, handleSeePlan, handleSeeDetail, handleConfirm }) => (
    <InfoPlan style={styles.shadow}>
        {!seeDetail && <BodyPlan>
            <Row>
                <LabelPlan numberOfLines={1}>Crédito Combinado</LabelPlan>
                <LabelPlan numberOfLines={1} align='right' family='PFontBold' width={wp(50)}>{params?.gcName}</LabelPlan>
            </Row>
            <Row>
                <LabelPlan numberOfLines={1}>Plazo</LabelPlan>
                <LabelPlan numberOfLines={1} align='right' family='PFontBold'>{infoGroup?.month || 0}</LabelPlan>
            </Row>
            {seeCard &&
                <Row>
                    <LabelPlan numberOfLines={1}>Vr. Cartera</LabelPlan>
                    <LabelPlan numberOfLines={1} align='right' family='PFontBold'>$ {numberFormat(Math.round(values?.card) || 0)}</LabelPlan>
                </Row>
            }
            {!!params?.gcSeeValReq &&
                <Row>
                    <LabelPlan numberOfLines={1}>Vr. Desembolso</LabelPlan>
                    <LabelPlan numberOfLines={1} align='right' family='PFontBold'>$ {numberFormat(Math.round(infoGroup?.totalReq) || 0)}</LabelPlan>
                </Row>
            }
            {!!params?.gcSeeValCre &&
                <Row>
                    <LabelPlan numberOfLines={1}>Vr. Crédito</LabelPlan>
                    <LabelPlan numberOfLines={1} align='right' family='PFontBold'>$ {numberFormat(Math.round(infoGroup?.totalCredit) || 0)}</LabelPlan>
                </Row>
            }
            {!!params?.gcSeeValFee &&
                <Row>
                    <LabelPlan numberOfLines={1}>Vr. Cuota</LabelPlan>
                    <LabelPlan numberOfLines={1} align='right' family='PFontBold'>$ {numberFormat(Math.round(values?.fee) || 0)}</LabelPlan>
                </Row>
            }
            <Row>
                <LabelPlan numberOfLines={1}>Mas Detalles</LabelPlan>
                <Label numberOfLines={1} align='right' family='PFontBold' color={theme.primaryC} size={wp(3.5)} onPress={handleSeeDetail}>{seePlan ? 'Ocultar' : 'Ver'}</Label>
            </Row>
        </BodyPlan>}
        {seeDetail && <>
            <HeadPlan>
                <Row>
                    {allCreditTypes?.map((x, i) => (
                        <ButtonCredit key={`keyMapCreditTypeBodyGroupCredit${i}`} active={x.active} onPress={() => handleCreditType(x)}>
                            <Label family='PFontBold' color={x.active ? theme.backgroundC : `${theme.backgroundC}CA`} numberOfLines={1}>{x.ctName}</Label>
                        </ButtonCredit>
                    ))}
                </Row>
            </HeadPlan>
            <BodyPlan>
                <Row>
                    <LabelPlan numberOfLines={1}>Línea de Crédito</LabelPlan>
                    <LabelPlan numberOfLines={1} align='right' family='PFontBold'>{oneCreditType?.ctName}</LabelPlan>
                </Row>
                <Row>
                    <LabelPlan numberOfLines={1}>Plazo</LabelPlan>
                    <LabelPlan numberOfLines={1} align='right' family='PFontBold'>{oneCreditType?.month}</LabelPlan>
                </Row>
                {!!oneCreditType?.gctSeeRat &&
                    <Row>
                        <LabelPlan numberOfLines={1}>Tasa de Interés</LabelPlan>
                        <LabelPlan numberOfLines={1} align='right' family='PFontBold'>{(oneCreditType?.rate || 0).toFixed(2)}%</LabelPlan>
                    </Row>
                }
                {!!oneCreditType?.gctSeeValReq &&
                    <Row>
                        <LabelPlan numberOfLines={1}>{oneCreditType?.gctType === 'CARTERA' ? 'V. Cartera' : 'V. Desembolso'}</LabelPlan>
                        <LabelPlan numberOfLines={1} align='right' family='PFontBold'>$ {numberFormat(Math.round(oneCreditType?.reqVal))}</LabelPlan>
                    </Row>
                }
                {!!oneCreditType?.gctSeeValCre &&
                    <Row>
                        <LabelPlan numberOfLines={1}>V. Crédito</LabelPlan>
                        <LabelPlan numberOfLines={1} align='right' family='PFontBold'>$ {numberFormat(Math.round(oneCreditType?.valCre))}</LabelPlan>
                    </Row>
                }
                {!!oneCreditType?.gctSeeAtt && oneCreditType?.credits?.map((x, i) => (
                    <Row key={`keyCreditTypeAttribute${i}`}>
                        <LabelPlan numberOfLines={1}>{x.name}</LabelPlan>
                        <LabelPlan numberOfLines={1} align='right' family='PFontBold'>$ {numberFormat(Math.round(x?.value))}</LabelPlan>
                    </Row>
                ))}
                {!!oneCreditType?.gctSeeValFee &&
                    <Row>
                        <LabelPlan numberOfLines={1}>V. Cuota</LabelPlan>
                        <LabelPlan numberOfLines={1} align='right' family='PFontBold'>$ {numberFormat(Math.round(oneCreditType?.valCou))}</LabelPlan>
                    </Row>
                }
                {!!oneCreditType?.gctSeeMon &&
                    <Row>
                        <LabelPlan numberOfLines={1}>Plan de Amortización</LabelPlan>
                        <Label numberOfLines={1} align='right' family='PFontBold' color={theme.primaryC} size={wp(3.5)} onPress={handleSeePlan}>{seePlan ? 'Ocultar' : 'Ver'}</Label>
                    </Row>
                }
            </BodyPlan>
        </>}
        {seePlan &&
            <FlatList
                data={oneCreditType.repaymentPlan}
                horizontal
                keyExtractor={item => item.name}
                renderItem={({ item, index: indexHead }) => (
                    <View>
                        <BoxHeadList><Label family='PFontBold' numberOfLines={1} size={wp(3.8)} align='center'>{item.name}</Label></BoxHeadList>
                        <FlatList
                            data={item.data}
                            showsVerticalScrollIndicator={false}
                            keyExtractor={() => `keyExtractorListData${indice++}`}
                            renderItem={({ item: itemTwo, index }) => <BoxList borderBottom={index < item.data?.length}><Label align={indexHead ? 'right' : 'center'} size={wp(3.3)}>{indexHead ? numberFormat(Math.round(itemTwo)) : itemTwo.format('MM-YYYY')}</Label></BoxList>}
                        />
                    </View>
                )}
            />
        }
        <ButtonSubmit onPress={handleConfirm} title='Solicitar' />
    </InfoPlan>
)

Body.propTypes = {
    data: PropTypes.object,
    handleCreditType: PropTypes.func,
    handleSeePlan: PropTypes.func,
    handleSeeDetail: PropTypes.func,
    handleConfirm: PropTypes.func
}