import React from 'react'
import Svg, { Path, G, Defs, ClipPath, Use, Rect } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
export const LogoLogin = ({ width, height, color, style, idOne, idTwo, idThree, idFour, idFive, idSix, idSeven, idEight }) => (
    <Svg version="1.1" id="Capa_1" width={width} height={height} x="0px" y="0px" viewBox="0 0 262 72" style={style}>
        <Path fill={color} d="M49.6,57.3V42c-2.6-0.5-5-1.8-6.7-3.8c-1.6-2-2.4-4.6-2.3-7.2c0-2.5,0.8-5,2.4-7c1.7-2.1,4-3.6,6.6-4.3v-3.4h1.9v3c0.6-0.1,1.1-0.2,1.7-0.2c0.5,0,0.9-0.1,1.4-0.1h0.8c0.3,0.1,0.7,0.1,1.1,0.1v-2.9h1.9v3c2.4,0.4,4.5,1.5,6.3,3.1c1.4,1.4,2.3,3.2,2.3,5.2c0,1-0.3,2-1,2.8c-0.6,0.7-1.6,1.1-2.5,1c-0.9,0.1-1.9-0.3-2.6-0.9c-0.7-0.6-1.1-1.5-1-2.4c0-1.1,0.4-2.2,1.3-3c0.9-0.7,1.3-1.2,1.3-1.4c0-0.5-0.4-1-1.1-1.5c-0.9-0.6-1.9-1-3-1.2v14c2.9,0.6,5.6,2.1,7.8,4.1c1.7,1.9,2.7,4.4,2.6,7c0.1,2.9-0.8,5.8-2.6,8.1c-2,2.3-4.7,3.8-7.7,4.4v5.9h-1.9v-5.6c-0.8,0.1-1.6,0.2-2.3,0.2c-0.6,0-1.1,0.1-1.7,0.1h-0.9v5.4h-1.9v-5.5c-3.5-0.3-6-1.2-7.7-2.6c-1.7-1.6-2.7-3.8-2.5-6.1c-0.1-1.3,0.4-2.7,1.2-3.7c0.8-0.9,2-1.4,3.3-1.3c1-0.1,2,0.3,2.7,1c0.7,0.8,1.1,1.9,1,3c0,0.7-0.1,1.4-0.4,2c-0.4,0.6-0.9,1.1-1.6,1.5l-0.7,0.4c-0.7,0.3-1,0.6-1,1c0,0.6,0.5,1.2,1.6,1.8C46.8,56.7,48.2,57.1,49.6,57.3z M49.6,32.9V21.4c-1.6,0.4-3,1.2-4.1,2.4c-1,1.1-1.5,2.5-1.4,3.9c0,1.2,0.4,2.3,1.3,3.2C46.6,31.9,48.1,32.6,49.6,32.9z M51.5,33.5l4.9,1.1V20.8c-0.4-0.1-0.8-0.1-1.1-0.1h-0.8c-0.5,0-1,0-1.5,0.1c-0.5,0-1,0.1-1.5,0.2L51.5,33.5z M51.5,57.5c1.1,0,2,0,2.8-0.1s1.6-0.1,2.2-0.2V43.6l-4.9-1.2V57.5zM58.2,56.9c1.8-0.4,3.5-1.3,4.8-2.6c1.1-1.2,1.7-2.8,1.6-4.4c0-1.4-0.5-2.7-1.5-3.6c-1.4-1.1-3.1-1.9-4.8-2.3L58.2,56.9z" />
        <G>
            <Defs>
                <Path id={idOne} d="M49.6,57.3V42c-2.6-0.5-5-1.8-6.7-3.8c-1.6-2-2.4-4.6-2.3-7.2c0-2.5,0.8-5,2.4-7c1.7-2.1,4-3.6,6.6-4.3v-3.4h1.9v3c0.6-0.1,1.1-0.2,1.7-0.2c0.5,0,0.9-0.1,1.4-0.1h0.8c0.3,0.1,0.7,0.1,1.1,0.1v-2.9h1.9v3c2.4,0.4,4.5,1.5,6.3,3.1c1.4,1.4,2.3,3.2,2.3,5.2c0,1-0.3,2-1,2.8c-0.6,0.7-1.6,1.1-2.5,1c-0.9,0.1-1.9-0.3-2.6-0.9c-0.7-0.6-1.1-1.5-1-2.4c0-1.1,0.4-2.2,1.3-3c0.9-0.7,1.3-1.2,1.3-1.4c0-0.5-0.4-1-1.1-1.5c-0.9-0.6-1.9-1-3-1.2v14c2.9,0.6,5.6,2.1,7.8,4.1c1.7,1.9,2.7,4.4,2.6,7c0.1,2.9-0.8,5.8-2.6,8.1c-2,2.3-4.7,3.8-7.7,4.4v5.9h-1.9v-5.6c-0.8,0.1-1.6,0.2-2.3,0.2c-0.6,0-1.1,0.1-1.7,0.1h-0.9v5.4h-1.9v-5.5c-3.5-0.3-6-1.2-7.7-2.6c-1.7-1.6-2.7-3.8-2.5-6.1c-0.1-1.3,0.4-2.7,1.2-3.7c0.8-0.9,2-1.4,3.3-1.3c1-0.1,2,0.3,2.7,1c0.7,0.8,1.1,1.9,1,3c0,0.7-0.1,1.4-0.4,2c-0.4,0.6-0.9,1.1-1.6,1.5l-0.7,0.4c-0.7,0.3-1,0.6-1,1c0,0.6,0.5,1.2,1.6,1.8C46.8,56.7,48.2,57.1,49.6,57.3z M49.6,32.9V21.4c-1.6,0.4-3,1.2-4.1,2.4c-1,1.1-1.5,2.5-1.4,3.9c0,1.2,0.4,2.3,1.3,3.2C46.6,31.9,48.1,32.6,49.6,32.9z M51.5,33.5l4.9,1.1V20.8c-0.4-0.1-0.8-0.1-1.1-0.1h-0.8c-0.5,0-1,0-1.5,0.1c-0.5,0-1,0.1-1.5,0.2L51.5,33.5z M51.5,57.5c1.1,0,2,0,2.8-0.1s1.6-0.1,2.2-0.2V43.6l-4.9-1.2V57.5zM58.2,56.9c1.8-0.4,3.5-1.3,4.8-2.6c1.1-1.2,1.7-2.8,1.6-4.4c0-1.4-0.5-2.7-1.5-3.6c-1.4-1.1-3.1-1.9-4.8-2.3L58.2,56.9z" />
            </Defs>
            <ClipPath id={idTwo}>
                <Use xlinkHref={`#${idOne}`} overflow='visible' />
            </ClipPath>
            <G clipPath={`url(#${idTwo})`}>
                <Rect x="-208" y="-304.9" fill={color} width="834.1" height="461" />
            </G>
        </G>
        <Path fill={color} d="M10.6,19.2h26.7V25h-20v10.2h17.6v5.5H17.4v17.2h-6.8V19.2z M72.9,19.2h18.8c6.9,0,11.5,3.1,11.5,9.6c0.1,3.7-2.1,7-5.5,8.2v0.1c4.7,1,7.3,4.7,7.3,10c0,6.1-4.2,10.8-14.3,10.8H72.9L72.9,19.2z M79.7,35.4h11.1c3.3,0,5.7-1.9,5.7-5.4c0-3.9-2-5.3-5.7-5.3H79.7L79.7,35.4z M79.7,52.4h12c4.1,0,6.5-2.2,6.5-6.1s-2.4-6-6.5-6h-12V52.4z M121.4,19.2h7.2l14.9,38.7h-7.3l-3.6-10.2h-15.4l-3.6,10.2h-7L121.4,19.2z M119,42.5h11.8L125,25.8h-0.2L119,42.5z M147,19.2h7.2l17.7,28.5h0.1V19.2h6.4v38.7h-7.1l-17.7-28.5h-0.2v28.5H147V19.2z" />
        <G>
            <Defs>
                <Path id={idThree} d="M10.6,19.2h26.7V25h-20v10.2h17.6v5.5H17.4v17.2h-6.8V19.2z M72.9,19.2h18.8c6.9,0,11.5,3.1,11.5,9.6c0.1,3.7-2.1,7-5.5,8.2v0.1c4.7,1,7.3,4.7,7.3,10c0,6.1-4.2,10.8-14.3,10.8H72.9L72.9,19.2z M79.7,35.4h11.1c3.3,0,5.7-1.9,5.7-5.4c0-3.9-2-5.3-5.7-5.3H79.7L79.7,35.4z M79.7,52.4h12c4.1,0,6.5-2.2,6.5-6.1s-2.4-6-6.5-6h-12V52.4zM121.4,19.2h7.2l14.9,38.7h-7.3l-3.6-10.2h-15.4l-3.6,10.2h-7L121.4,19.2z M119,42.5h11.8L125,25.8h-0.2L119,42.5z M147,19.2h7.2l17.7,28.5h0.1V19.2h6.4v38.7h-7.1l-17.7-28.5h-0.2v28.5H147V19.2z" />
            </Defs>
            <ClipPath id={idFour}>
                <Use xlinkHref={`#${idThree}`} overflow="visible" />
            </ClipPath>
            <G clipPath={`url(#${idFour})`}>
                <Rect x="-208" y="-304.9" fill={color} width="834.1" height="461" />
            </G>
        </G>
        <Path fill={color} d="M212.5,31.5c-1.1-4.4-4-7.7-9.6-7.7c-8.3,0-11.8,7.3-11.8,14.7s3.5,14.7,11.8,14.7c6,0,9.3-4.5,9.9-10.1h6.6c-0.5,9.3-7.1,15.7-16.5,15.7c-11.6,0-18.5-9.2-18.5-20.3s6.9-20.3,18.5-20.3c8.7,0,15.6,4.9,16.4,13.2L212.5,31.5z M235.9,19.2h7.2L258,57.9h-7.3l-3.6-10.2h-15.5L228,57.9h-7L235.9,19.2z M233.5,42.5h11.8l-5.8-16.6h-0.2L233.5,42.5z" />
        <G>
            <Defs>
                <Path id={idFive} d="M212.5,31.5c-1.1-4.4-4-7.7-9.6-7.7c-8.3,0-11.8,7.3-11.8,14.7s3.5,14.7,11.8,14.7c6,0,9.3-4.5,9.9-10.1h6.6c-0.5,9.3-7.1,15.7-16.5,15.7c-11.6,0-18.5-9.2-18.5-20.3s6.9-20.3,18.5-20.3c8.7,0,15.6,4.9,16.4,13.2L212.5,31.5zM235.9,19.2h7.2L258,57.9h-7.3l-3.6-10.2h-15.5L228,57.9h-7L235.9,19.2z M233.5,42.5h11.8l-5.8-16.6h-0.2L233.5,42.5z" />
            </Defs>
            <ClipPath id={idSix}>
                <Use xlinkHref={`#${idFive}`} overflow="visible" />
            </ClipPath>
            <G clipPath={`url(#${idSix})`}>
                <Rect x="-208" y="-304.9" fill={color} width="834.1" height="461" />
            </G>
        </G>
        <Path fillRule='evenodd' clipRule='evenodd' fill={color} d="M54,5.2c2.8,0,5.1,2.3,5.1,5.1s-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1l0,0C48.9,7.5,51.2,5.2,54,5.2" />
        <G>
            <Defs>
                <Path id={idSeven} d="M54,5.2c2.8,0,5.1,2.3,5.1,5.1s-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1l0,0C48.9,7.5,51.2,5.2,54,5.2" />
            </Defs>
            <ClipPath id={idEight}>
                <Use xlinkHref={`#${idSeven}`} overflow="visible" />
            </ClipPath>
            <G clipPath={`url(#${idEight})`}>
                <Rect x="48.8" y="5.2" fill={color} width="10.3" height="10.3" />
            </G>
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const Logo = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idThree={`${Math.random() * 10000}`}
        idFour={`${Math.random() * 10000}`}
        idFive={`${Math.random() * 10000}`}
        idSix={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idSeven={`${Math.random() * 10000}`}
        idEight={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    idOne: PropTypes.string,
    idTwo: PropTypes.string,
    idThree: PropTypes.string,
    idFour: PropTypes.string,
    idFive: PropTypes.string,
    idSix: PropTypes.string,
    idSeven: PropTypes.string,
    idEight: PropTypes.string
}

Logo.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}