import { useQuery } from '@apollo/client';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react'
import { View, Text, Platform } from "react-native";
import { CIRENIO_HANDLE_EVENT, CIRENIO_PARAMETERS } from './queries';
import { apolloClient } from '../../apollo/client';

export const Cirenio = props => {

    const [ipAddress, setIpAddress] = useState(null)
    const { data, loading } = useQuery(CIRENIO_PARAMETERS)

    const createUrl = ({ env, uuId, publicKey }) => {
        if (env?.toLowerCase() === 'stage') {
            return `https://app.cirenio.com?public_key=${publicKey}&external_id=${uuId}`
        }

        return ''
    }

    const external = nanoid();

    // console.log(external, data, loading, ipAddress)

    // funcion que ejecuta la mutacion
    const handleEventMut = (data, type) => {

        apolloClient.mutate({
            mutation: CIRENIO_HANDLE_EVENT,
            variables: {
                input: { event: data, eventType: type, externalId: external, hash: data?.hash, ipAddress }
            }
        }).catch(err => console.log(err))
    }

    // api que obtiene la ip del cliente
    fetch('https://api.ipify.org?format=json', {
        method: 'GET'
    }).then(res => res.json()).then(res => setIpAddress(res?.ip || null)).catch()

    // manejo de eventos
    const cirenioEvents = {
        'flow_done': data => handleEventMut(data, 'flow_done'),
        'consents_granted': (data) => handleEventMut(data, 'consents_granted'),
        'banks_listed': data => handleEventMut(data, 'banks_listed'),
        'bank_selected': data => handleEventMut(data, 'bank_selected'),
        'login_requested': data => handleEventMut(data, 'login_requested'),
        'login_failed': data => handleEventMut(data, 'login_failed'),
        'missing_bank_form': data => handleEventMut(data, 'missing_bank_form'),
        'missing_bank_sent': data => handleEventMut(data, 'missing_bank_sent'),
    };
    Object.keys(cirenioEvents).forEach(event => {
        window.Cirenio.on(event, cirenioEvents[event]);
    });

    // renderizado de vista
    if (Platform.OS === 'web' && !loading) {
        return (
            <iframe
                title="Cirenio"
                src={createUrl({ env: data?.getCirenioParameter?.env, uuId: external, publicKey: data?.getCirenioParameter?.cpPublicKey })}
                width="100%"
                height="100%"
                style={{ border: 'none' }}
            />);
    } else {
        return (
            <View>
                <Text>El contenido web no está disponible en esta plataforma.</Text>
            </View>
        );
    }
}