import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import { FontAwesome, MaterialCommunityIcons, Entypo, Octicons, FontAwesome5 } from '@expo/vector-icons'
/** personal imports */
import { InputHook } from '../InputHook'
import { InputDateWeb } from '../InputDateWeb'
import { ButtonSubmit } from '../ButtonSubmit'
import { AcademicLevels } from '../Selects/AcademicLevels'
import { TypeIdentities } from '../Selects/TypeIdentities'
import { Genders } from '../Selects/Genders'
import { Dependents } from '../Selects/Dependents'
import { MaritalStatus } from '../Selects/MaritalStatus'
import { TypeHousings } from '../Selects/TypeHousings'
import { TypeAddress } from '../Selects/TypeAddress'
import { TypeContracts } from '../Selects/TypeContracts'
import { Cities } from '../Selects/Cities'
import { Container, Form, Row, BoxButton } from './styled'
import { ViewPhoto } from '../ViewPhoto'
import theme from '../../../ignore/Constants'
import { hp } from '../../validation'

export const CoDebtorDataRegisterV = ({ data: { banner, values, infoCamera, files, loading, format, fileBD, fileFD, fileLD, filePD, fileRD, fileSD, loadSD, loadRD, loadPD, loadLD, loadFD, loadBD }, handleInfo, handleSubmit, handleButton, handleChange, handleBlur }) => (
    <Container>
        {banner}
        <Form>
            {!!format?.cdTipIde && <TypeIdentities testID='258d93ec-7883-4fcb-ac1c-46b0228634b5' value={values?.payrollCoDebtor?.tiId} handleSelect={handleChange} />}
            {!!format?.cdIdeNum && <InputHook testID='d20bf58c-8fd3-44d9-a61b-5b3b0101bcec' title='Número de identificación *' returnKeyType='next' keyboardType='numeric' value={values?.payrollCoDebtor?.pcdIdeNum} name='pcdIdeNum' required numeric format range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
            {(!!format?.cdDateExp || !!format?.cdPlaceExp) &&
                <Row>
                    {!!format?.cdDateExp && <InputDateWeb testID='3a18e0f9-a652-47b9-837c-9d51dbef6b48' flex={format?.cdDateExp ? .47 : 1} title='Fecha de expedición documento *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdDateExp} name='pcdDateExp' required letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cdPlaceExp && <InputHook testID='6507ec02-54ad-4f0a-ad9f-c3d821a9cf97' flex={format?.cdSecLas ? .47 : 1} title='Lugar de expedición documento *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdPlaceExp} name='pcdPlaceExp' letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {(!!format?.cdFirNam || !!format?.cdSecNam) &&
                <Row>
                    {!!format?.cdFirNam && <InputHook testID='9a90d4f1-f6f5-494d-bfd9-1972e2dbaddb' flex={format?.cdSecNam ? .47 : 1} title='Primer Nombre *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdFirNam} name='pcdFirNam' required letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cdSecNam && <InputHook testID='23370347-f408-4998-9665-3bcc9e62ea4a' flex={format?.cdFirNam ? .47 : 1} title='Segundo Nombre' returnKeyType='next' value={values?.payrollCoDebtor?.pcdSecNam} name='pcdSecNam' letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {(!!format?.cdFirLas || !!format?.cdSecLas) &&
                <Row>
                    {!!format?.cdFirLas && <InputHook testID='3a18e0f9-a652-47b9-837c-9d51dbef6b48' flex={format?.cdSecLas ? .47 : 1} title='Primer Apellido *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdFirLas} name='pcdFirLas' required letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cdSecLas && <InputHook testID='6507ec02-54ad-4f0a-ad9f-c3d821a9cf97' flex={format?.cdFirLas ? .47 : 1} title='Segundo Apellido' returnKeyType='next' value={values?.payrollCoDebtor?.pcdSecLas} name='pcdSecLas' letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {(!!format?.cdDatBir || !!format?.cdGender) &&
                <Row>
                    {!!format?.cdDatBir && <InputDateWeb testID='085173d1-3d25-4bba-bd1f-d922a4cda946' flex={format?.cdGender ? .47 : 1} title='Fecha de Nacimiento *' name='pcdDatBir' required value={values?.payrollCoDebtor?.pcdDatBir} onChangeText={handleChange} />}
                    {!!format?.cdGender && <View style={{ flex: format?.cdDatBir ? .47 : 1 }}><Genders testID='988cd00f-6540-4816-a900-d2014ac37b47' value={values?.payrollCoDebtor?.gId} handleSelect={handleChange} /></View>}
                </Row>
            }
            {(!!format?.cdPhoMob || !!format?.cdPhoPer) &&
                <Row>
                    {!!format?.cdPhoMob && <InputHook testID='76738369-e446-4228-b6ba-e90b71b75bd2' flex={format?.cdPhoPer ? .47 : 1} title='Número Teléfono Móvil *' keyboardType='numeric' returnKeyType='next' value={values?.payrollCoDebtor?.pcdPhoMob} name='pcdPhoMob' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cdPhoPer && <InputHook testID='bfd12e07-b967-4e3c-8171-b913f499b011' flex={format?.cdPhoMob ? .47 : 1} title='Número Teléfono Fijo *' keyboardType='numeric' returnKeyType='next' value={values?.payrollCoDebtor?.pcdPhoPer} name='pcdPhoPer' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {!!format?.cdEmail && <InputHook testID='8b130661-7599-4f65-b80b-bfc5e84fdb31' title='Correo Electrónico *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdEmail} name='pcdEmail' keyboardType='email-address' required email range={[5, 100]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cdLocation && <>
                <Row>
                    <View style={{ flex: .47 }}><Cities testID='e7759320-933f-45bc-bbcf-cdf6675b2a93' value={values?.payrollCoDebtor?.cId} handleSelect={handleChange} /></View>
                    <View style={{ flex: .47 }}><TypeAddress testID='d342677d-0b7e-494e-9452-faf822cb050e' value={values?.payrollCoDebtor?.taId} handleSelect={handleChange} /></View>
                </Row>
                <Row>
                    <InputHook testID='7737a980-1b31-4cf2-a0fc-d40dc9935458' flex={.4} title='No. Dirección *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdNumDir} name='pcdNumDir' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook testID='012195ea-57e0-4474-a020-ba4be460d3c9' flex={.25} title='No. *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdNum} name='pcdNum' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook testID='add11fed-b4c8-42d9-9cdc-2ce93f5d6eee' flex={.25} title='No. Casa *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdNumHou} name='pcdNumHou' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                </Row>
                <InputHook testID='1e4d7828-7693-4796-a041-1bd90aa22588' title='Información Residencial (Opcional)' returnKeyType='next' value={values?.payrollCoDebtor?.pcdInf} name='pcdInf' range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            </>}
            {(!!format?.cdAcaLev || !!format?.cdMarSta) &&
                <Row>
                    {!!format?.cdAcaLev && <View style={{ flex: format?.cdMarSta ? .47 : 1 }}><AcademicLevels testID='58087d0d-daff-4864-a5f7-91c9d04fdced' value={values?.payrollCoDebtor?.alId} handleSelect={handleChange} /></View>}
                    {!!format?.cdMarSta && <View style={{ flex: format?.cdAcaLev ? .47 : 1 }}><MaritalStatus testID='0d543143-27f7-488f-8b7d-dfffd7582ccb' value={values?.payrollCoDebtor?.msId} handleSelect={handleChange} /></View>}
                </Row>
            }
            {(!!format?.cdTypHou || !!format?.cdDependents) &&
                <Row>
                    {!!format?.cdTypHou && <View style={{ flex: format?.cdDependents ? .47 : 1 }}><TypeHousings testID='e9def57f-471c-4940-b6d9-97fd47b9034a' value={values?.payrollCoDebtor?.thId} handleSelect={handleChange} /></View>}
                    {!!format?.cdDependents && <View style={{ flex: format?.cdTypHou ? .47 : 1 }}><Dependents testID='eda726aa-b20f-4c10-b62c-1ae1a5573070' value={values?.payrollCoDebtor?.dId} handleSelect={handleChange} /></View>}
                </Row>
            }
            {!!format?.cdCompany && <InputHook testID='d35fdeef-aff8-4c64-bd6a-b6a7bdbfba9e' title='Nombre de la Empresa *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdCompany} name='pcdCompany' required range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cdPosition && <InputHook testID='458e0e19-a469-4d4c-be2c-fec61e3efce4' title='Cargo en la Empresa *' returnKeyType='next' value={values?.payrollCoDebtor?.pcdPosition} name='pcdPosition' required range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cdTypCon && <TypeContracts testID='05e3c37d-ba2a-4f10-9892-2983a9413375' value={values?.payrollCoDebtor?.tcId} handleSelect={handleChange} />}
            {(!!format?.cdTypCon) &&
                <Row>
                    <InputDateWeb testID='6b8c7e29-506c-4a33-80aa-8cfb96d85c06' flex={.47} title='Fecha de Inicio del Contrato *' name='pcdDatSta' required value={values?.payrollCoDebtor?.pcdDatSta} onChangeText={handleChange} />
                    <InputDateWeb testID='e660369b-cfea-4887-a89b-438b91ccc03b' flex={.47} title='Fecha de Final del Contrato (Opcional)' name='pcdDatEnd' value={values?.payrollCoDebtor?.pcdDatEnd} onChangeText={handleChange} />
                </Row>
            }
            {(!!format?.cdSalary || !!format?.cdOthInc) &&
                <Row>
                    {!!format?.cdSalary && <InputHook testID='0ceff385-55e2-4e6a-afdc-3af31f81d685' flex={format?.cdOthInc ? .47 : 1} title='Salario *' returnKeyType='next' keyboardType='numeric' value={values?.payrollCoDebtor?.pcdSalary} name='pcdSalary' required numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cdOthInc && <InputHook testID='086ba21b-f9a5-4de8-9140-5605eeb5a253' flex={format?.cdSalary ? .47 : 1} title='Otros Ingresos (Opcional)' returnKeyType='next' keyboardType='numeric' value={values?.payrollCoDebtor?.pcdOthInc} name='pcdOthInc' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {(!!format?.cdFinExp || !!format?.cdPerExp) &&
                <Row>
                    {!!format?.cdFinExp && <InputHook testID='c455ce1c-8f79-44df-851a-a66a63240529' flex={format?.cdPerExp ? .47 : 1} title='Gastos Financieros (Opcional)' returnKeyType='next' keyboardType='numeric' value={values?.payrollCoDebtor?.pcdFinExp} name='pcdFinExp' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cdPerExp && <InputHook testID='ed3d7d28-8e1a-4a0c-9601-9f0f795e7223' flex={format?.cdFinExp ? .47 : 1} title='Gastos Personales (Opcional)' returnKeyType='next' keyboardType='numeric' value={values?.payrollCoDebtor?.pcdPerExp} name='pcdPerExp' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {!!format?.cdFroDoc && <ViewPhoto
                title='Cédula frontal'
                loadingDoc={loadFD}
                nameFile={values?.payrollCoDebtor?.pcdFroDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.pcdFroDocA || fileFD}
                icon={<FontAwesome name='vcard' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(1)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pcdFroDoc', 'pcdFroDocA')}
                testID='3f1ac971-dfc7-4747-b916-40dc32168d47'
            />}
            {!!format?.cdReaDoc && <ViewPhoto
                title='Cédula por detrás'
                loadingDoc={loadRD}
                nameFile={values?.payrollCoDebtor?.pcdReaDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.pcdReaDocA || fileRD}
                icon={<MaterialCommunityIcons name='card-bulleted' size={hp(26)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(2)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pcdReaDoc', 'pcdReaDocA')}
                testID='499e21e1-9ff3-42ef-a0ca-b68f8f8def54'
            />}
            {!!format?.cdPerDoc && <ViewPhoto
                title='Selfie con Cédula frontal'
                loadingDoc={loadPD}
                nameFile={values?.payrollCoDebtor?.pcdPerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.pcdPerDocA || filePD}
                icon={<Entypo name='v-card' size={hp(24)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(3)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pcdPerDoc', 'pcdPerDocA')}
                testID='f2c50a73-2c57-4d08-95be-65e7b30a895f'
            />}
            {!!format?.cdSerDoc && <ViewPhoto
                title={`Servicios Públicos${'\n'} (Luz, Agua o Gas)`}
                loadingDoc={loadSD}
                nameFile={values?.payrollCoDebtor?.pcdSerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.pcdSerDocA || fileSD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(4)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pcdSerDoc', 'pcdSerDocA')}
                testID='6c4b7f97-0569-4c0e-b56f-70a1e800fdb6'
            />}
            {!!format?.cdBanDoc && <ViewPhoto
                title='Certificado Bancario'
                loadingDoc={loadBD}
                nameFile={values?.payrollCoDebtor?.pcdBanDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.pcdBanDocA || fileBD}
                icon={<FontAwesome5 name='file-invoice' size={hp(17)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(5)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pcdBanDoc', 'pcdBanDocA')}
                testID='2618cde8-ad13-4c7a-98fa-11abb4f0ab74'
            />}
            {!!format?.cdLoaDoc && <ViewPhoto
                title='Certificado Laboral'
                loadingDoc={loadLD}
                nameFile={values?.payrollCoDebtor?.pcdLoaDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.pcdLoaDocA || fileLD}
                icon={<MaterialCommunityIcons name='file-cad-box' size={hp(23)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(6)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pcdLoaDoc', 'pcdLoaDocA')}
                testID='f6765725-222c-47d1-9b3b-e69e5d496770'
            />}
            <BoxButton>
                <ButtonSubmit testID='f6865725-422c-47d1-9b3b-e69f5d4g6780' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

CoDebtorDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleButton: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func
}