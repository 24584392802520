import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Circle, ClipPath, Defs, Ellipse, G, LinearGradient, Path, RadialGradient, Stop, Use } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ size, style, idOne, idTwo, idsvTwo, idsvOne, idsvThree }) => (
    <Svg version="1.1" id="Capa_1" width={size} height={size} x="0px" y="0px" viewBox="0 0 512 512" style={style}>
        <G>
            <G>
                <Circle opacity='0.30' fill='#FFE5A4' cx="256" cy="256" r="188.8" />
                <Circle opacity='0.30' fill='#F9D478' cx="256" cy="256" r="148.8" />
                <Circle fill='#FFFFFF' cx="256" cy="256" r="114.2" />
            </G>
            <G>
                <LinearGradient id={idOne} gradientUnits="userSpaceOnUse" x1="297.0046" y1="243.7941" x2="373.8049" y2="159.3381" gradientTransform="matrix(1.0648 0.2356 -0.3031 1.0639 -62.8264 -82.6339)">
                    <Stop offset="0.1117" stopColor='#FECE42' />
                    <Stop offset="0.2392" stopColor='#FFFFFF' />
                    <Stop offset="0.3855" stopColor='#FECE46' />
                    <Stop offset="0.5922" stopColor='#FECE46' />
                    <Stop offset="0.7151" stopColor='#FFFFFF' />
                    <Stop offset="0.8513" stopColor='#FECE46' />
                </LinearGradient>
                <Path fillRule='evenodd' clipRule='evenodd' fill={`url(#${idOne})`} d="M278.3,191.5c-30-25.4-74.2-22-98.7,7.6c-20.8,25.1-20.7,60.9-2,86.7l0,0l-0.5-1.3l-0.2-0.5l-0.4-0.9l-0.2-0.5l-0.5-1.3l-0.2-0.5l-0.3-0.9c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.4-0.3-0.8-0.4-1.2l-0.2-0.5l-0.3-1.1l-0.2-0.6l-0.2-1l-0.1-0.5l-0.3-1.4l-0.1-0.5l-0.2-1L173,271l-0.2-1.4l-0.1-0.5l-0.2-1l-0.1-0.5l-0.2-1.2c0-0.2-0.1-0.4-0.1-0.5l-0.1-1.1l0-0.6l-0.1-1l0-0.5l-0.1-1.4l0-0.5l0-1l0-0.5l0-1.4l0-0.4l0-1.1l0.1-0.5l0-1.3l0-0.3l0.1-1.4l0-0.5l0.1-1.1l0-0.4c0-0.5,0.1-1,0.1-1.4l0.1-0.5l0.1-1.1l0.1-0.5c0.1-0.5,0.1-1,0.2-1.4l0.1-0.3l0.2-1.2l0.1-0.5c0.1-0.4,0.2-0.8,0.3-1.3l0.1-0.2c0.1-0.5,0.2-1,0.3-1.4l0.1-0.4l0.3-1.1l0.1-0.4c0.1-0.5,0.3-1,0.4-1.4l0.1-0.4l0.3-1.1l0.1-0.4l0.5-1.4l0.1-0.2l0.5-1.3c0.1-0.1,0.1-0.3,0.2-0.4l0.5-1.2l0.1-0.2c0.2-0.5,0.4-0.9,0.6-1.4l0.2-0.4l0.5-1.1l0.2-0.3c0.2-0.5,0.4-0.9,0.7-1.4l0.2-0.3c0.2-0.4,0.4-0.7,0.6-1.1l0.2-0.4c0.2-0.5,0.5-0.9,0.7-1.3l0.1-0.1c0.2-0.4,0.5-0.8,0.7-1.3c0.1-0.1,0.1-0.2,0.2-0.4c0.2-0.4,0.5-0.8,0.7-1.1l0.1-0.2c0.3-0.4,0.6-0.9,0.9-1.3c0.1-0.1,0.1-0.2,0.2-0.3l0.7-1c0.1-0.1,0.2-0.2,0.2-0.3c0.3-0.4,0.6-0.9,0.9-1.3c0.1-0.1,0.1-0.2,0.2-0.2c0.3-0.4,0.5-0.7,0.8-1.1c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.4,0.7-0.8,1-1.2c24.5-29.6,68.7-33,98.7-7.6l1.4,1.2l0,0l1.3,1.2l0,0.1l1.2,1.2l0.1,0.1c0.4,0.4,0.8,0.8,1.1,1.1l0.2,0.2l1,1.1l0.2,0.3l0.9,1l0.3,0.4l0.8,1l0.3,0.5l0.7,0.9l1.5,2l0.6,0.8l0,0C293.7,208,286.9,198.8,278.3,191.5" />
                <G>
                    <Defs>
                        <Path id={idsvThree} d="M240.8,194.8c-41.9,3.9-71.6,38-66.4,76.2c4.4,32.3,32.5,57.1,66.4,61.5l0,0l-1.4-0.5l-0.6-0.2l-1-0.4l-0.6-0.2l-1.4-0.6l-0.5-0.2l-0.9-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.4-0.2-0.8-0.4-1.2-0.6l-0.5-0.2l-1.1-0.5l-0.6-0.3l-1-0.5l-0.4-0.2l-1.3-0.7l-0.5-0.3l-0.9-0.5l-0.5-0.3l-1.3-0.8l-0.4-0.3l-0.9-0.6l-0.5-0.3l-1.1-0.7c-0.2-0.1-0.3-0.2-0.5-0.3l-1-0.7l-0.5-0.4l-0.9-0.6l-0.4-0.3l-1.2-0.9l-0.4-0.3l-0.8-0.7l-0.4-0.3l-1.1-1l-0.3-0.3l-0.8-0.8l-0.3-0.4l-1-0.9l-0.2-0.2l-1-1l-0.4-0.4l-0.8-0.8l-0.3-0.3c-0.3-0.4-0.7-0.7-1-1.1l-0.3-0.4l-0.7-0.8l-0.3-0.4c-0.3-0.4-0.6-0.8-1-1.1l-0.2-0.3l-0.8-1l-0.3-0.4c-0.3-0.3-0.5-0.7-0.8-1l-0.1-0.2c-0.3-0.4-0.6-0.8-0.9-1.2l-0.3-0.4l-0.7-1l-0.2-0.3c-0.3-0.4-0.6-0.9-0.8-1.3l-0.2-0.3l-0.6-1l-0.2-0.4l-0.8-1.3l-0.1-0.2l-0.6-1.2c-0.1-0.1-0.1-0.3-0.2-0.4l-0.6-1.2l-0.1-0.2c-0.2-0.5-0.4-0.9-0.7-1.4l-0.2-0.4l-0.5-1.1l-0.1-0.3c-0.2-0.5-0.4-0.9-0.6-1.4l-0.1-0.3c-0.2-0.4-0.3-0.8-0.4-1.2l-0.2-0.4c-0.2-0.5-0.3-1-0.5-1.5l0-0.1c-0.2-0.5-0.3-0.9-0.5-1.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.4-0.3-0.9-0.4-1.3l-0.1-0.3c-0.1-0.5-0.3-1-0.4-1.5c0-0.1-0.1-0.3-0.1-0.4l-0.3-1.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.5-0.2-1-0.3-1.5c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.5-0.2-1-0.2-1.6c-5.2-38.1,24.5-72.3,66.4-76.2l1.9-0.2l0,0l1.9-0.1l0.1,0l1.8-0.1l0.2,0c0.6,0,1.2,0,1.7,0l0.3,0l1.6,0l0.4,0l1.5,0l0.5,0.1l1.4,0.1l0.6,0.1l1.2,0.1l2.7,0.3l1.1,0.1l0,0C265,195.2,252.8,193.7,240.8,194.8" />
                    </Defs>
                    <Use xlinkHref={`#${idsvThree}`} overflow='visible' fillRule='evenodd' clipRule='evenodd' fill='#0B1941' />
                    <ClipPath id={idTwo}>
                        <Use xlinkHref={`#${idsvThree}`} overflow='visible'/>
                    </ClipPath>
                    <Ellipse transform="matrix(0.1045 -0.9945 0.9945 0.1045 -34.8676 491.3965)" clipPath={`url(#${idTwo})`} fillRule='evenodd' clipRule='evenodd' fill='#0B1941' cx="255.4" cy="265.1" rx="69.8" ry="81.8" />
                </G>
                <Path fillRule='evenodd' clipRule='evenodd' fill='#0B1941' d="M252.7,198.6c0,0,42.5-3.3,68.8,35.5c0,0,25.7,38.3-8.1,76.9c0,0-13.3,15.6-40.1,22.8c0,0,25.5,1.2,49.9-18c0,0,27.6-19.6,24.8-57c0,0-0.8-25.3-26.1-45.6c0,0-22-16.9-47.5-16.8C274.4,196.5,261.6,196.3,252.7,198.6z" />
                <LinearGradient id={idsvTwo} gradientUnits="userSpaceOnUse" x1="217.71" y1="217.884" x2="303.6675" y2="327.228">
                    <Stop offset="0.2432" stopColor='#FECE46' />
                    <Stop offset="0.3382" stopColor='#FECE42' />
                    <Stop offset="0.485" stopColor='#FFFFFF' />
                    <Stop offset="0.6369" stopColor='#FECE46' />
                    <Stop offset="0.743" stopColor='#FECE46' />
                </LinearGradient>
                <Circle fill={`url(#${idsvTwo})`} cx="257.1" cy="268" r="69.5" />
                <RadialGradient id={idsvOne} cx="280.9606" cy="252.8146" r="61.0535" gradientUnits="userSpaceOnUse">
                    <Stop offset="0.144" stopColor='#FFFFFF' />
                    <Stop offset="0.5728" stopColor='#FECE46' />
                </RadialGradient>
                <Circle fill={`url(#${idsvOne})`} cx="253.2" cy="265" r="61.1" />
                <G>
                    <Path fill='#0B1941' d="M280.1,258.7c0,0-2.1-1.4-5.8-2.6c8.3-3.2,9.5-8.2,9.5-8.2c4.4-16.2-12.6-17.5-12.6-17.5h-29.7v1.4l2.5-0.1c5.2,0.6,5.6,8.2,5.6,8.2v16.1l-0.1,0l0,18.4c0,0,0.3,8.3-6.2,8.8l-1.9,0l0,1.5l29.3,0c0,0,12.7-0.1,15.4-10.5C286.2,274.2,289.1,265.1,280.1,258.7z M257.4,231.6h8.2c6.4-0.4,8.3,2.8,8.3,2.8c4.1,6.7,1.3,15.1,1.3,15.1c-1.8,6.3-9.4,6.3-9.4,6.3h-8.4V231.6z M276.1,277.4c-2.6,6.3-9.3,6-9.3,6h-9.2v-26.2l-0.1-0.1h1.8h4.6l3,0.1c6.2,0.2,8.8,5,8.8,5C279.8,270.4,276.1,277.4,276.1,277.4z" />
                    <Path fill='#0B1941' d="M222.4,239.4h45.9v13.3h-2.2c0,0-0.4-6.3-3.4-8c0,0-1.3-1.5-6.3-1.8l-14,0.1c0,0-3.7-0.6-3.6,3l0,20.8h11.4c0,0,7.1,0.1,9.3-3.5c0,0,1.6-2.2,1.4-5.5h1.8v22h-1.8c0,0,0.3-5-3.4-7.5c0,0-2.7-1.9-7.3-1.7h-11.4l-0.1,20.7l0.1,2.4c0,0-0.3,3.8,3.2,5c0,0,2,1.1,4.5,0.8v1.8h-24.1v-1.8c0,0,2.9,0.2,4.6-0.8c0,0,3-1.3,3-5l-0.1-48.2c0,0-0.3-3.4-3.9-4.3c0,0-2.3-0.7-3.6-0.3V239.4z" />
                </G>
            </G>
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvThree: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}