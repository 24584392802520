import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Components, Icons, Utiles, Queries
import { RequestCreditDataV } from '../../components/RequestCreditData'
import { InformationAdditionalCreditLifeInsuranceC } from '../../containers/InformationAdditionalCreditLifeInsurance'

export const InformationAdditionalCreditLifeInsurance = ({ navigation, route }) => {
    const [typeInfo, setTypeInfo] = useState(1)

    return (
        <RequestCreditDataV
            data={{
                typeInfo,
                navigation
            }}
            handleTravel={value => setTypeInfo(value)}
        >
            <InformationAdditionalCreditLifeInsuranceC
                navigation={navigation}
                route={route}
            />
        </RequestCreditDataV>
    )
}

InformationAdditionalCreditLifeInsurance.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}