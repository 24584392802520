import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={381}
        height={381}
        fill="none"
        viewBox="0 0 980 380"
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fill="#8481AE"
                d="M190.711 381.38c105.21 0 190.5-85.29 190.5-190.5S295.921.38 190.711.38.211 85.67.211 190.88s85.29 190.5 190.5 190.5Z"
            />
            <Path
                fill="#1C1D3C"
                d="M190.71 346.68c86.046 0 155.8-69.754 155.8-155.8s-69.754-155.8-155.8-155.8-155.8 69.754-155.8 155.8 69.754 155.8 155.8 155.8Z"
            />
            <Path
                fill="#180F2B"
                d="M190.3 310.6c65.887 0 119.3-53.412 119.3-119.3S256.187 72 190.3 72C124.412 72 71 125.412 71 191.3s53.412 119.3 119.3 119.3Z"
            />
            <Path
                fill="#fff"
                d="M219.756 125.993c-5.118 18.314-10.356 36.598-15.384 54.942-1.053 3.833-2.93 5.349-7.044 5.309-18.555-.181-37.11-.03-55.665-.051-6.041-.01-7.105-1.435-5.489-7.275 5.038-18.163 10.216-36.287 15.073-54.5 1.134-4.235 3.211-5.54 7.426-5.409 9.192.271 18.384.08 27.576.08v.03c9.363 0 18.725-.03 28.088 0 5.851.02 6.954 1.395 5.419 6.874ZM239.194 148.683c5.138 0 10.286-.01 15.424 0 3.211.01 3.823.762 2.98 3.773-2.81 10.065-5.69 20.11-8.449 30.185-.582 2.108-1.606 2.94-3.864 2.92-10.195-.1-20.381-.02-30.577-.03-3.321 0-3.903-.793-3.01-3.994 2.76-9.975 5.61-19.939 8.279-29.944.622-2.328 1.766-3.041 4.084-2.971 5.048.151 10.095.05 15.153.05l-.02.011ZM210.504 195.295c9.362 0 18.725-.02 28.078 0 5.85.02 6.954 1.385 5.419 6.864-5.118 18.314-10.356 36.608-15.384 54.942-1.054 3.833-2.92 5.348-7.045 5.308-18.554-.18-37.109-.03-55.664-.05-6.051 0-7.105-1.435-5.489-7.275 5.027-18.164 10.216-36.287 15.072-54.501 1.124-4.234 3.212-5.539 7.426-5.418 9.182.27 18.385.08 27.577.08v.03l.01.02ZM149.872 196.028c5.138 0 10.286-.01 15.424 0 3.211.01 3.823.763 2.98 3.773-2.81 10.065-5.69 20.11-8.449 30.186-.582 2.107-1.606 2.94-3.864 2.92-10.195-.101-20.381-.02-30.577-.03-3.321 0-3.903-.793-3.01-3.994 2.76-9.975 5.61-19.94 8.279-29.945.622-2.328 1.766-3.04 4.084-2.97 5.048.15 10.095.05 15.153.05l-.02.01Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h381v381H0z" />
            </ClipPath>
        </Defs>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}