// Modules
import React from 'react'
import PropTypes from 'prop-types'

// Styles and Utils
import { ShadowBox } from '../../generalStyles'
import { TitleContainer, Title } from './styled'

/**
 * Head Title Component
 * @param {any} props Properties HeadTitle
 * @return {ReactElement} React Components
 */
export const HeadTitle = ({ title, icon, bgColor, color, width, margin }) => (
    <TitleContainer style={ShadowBox} bgColor={bgColor} width={width} margin={margin}>
        {icon}
        <Title tColor={color} >{title}</Title>
    </TitleContainer>
)

HeadTitle.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    bgColor: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.number,
    margin: PropTypes.string,
}