import React from 'react'
import Svg, { ClipPath, Defs, G, Path, Use } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
export const LogoLogin = ({ width, height, props }) => (
    <Svg
        width={width} height={height}
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x={0}
        y={0}
        viewBox="0 0 1366 406"
        style={{
            enableBackground: 'new 0 0 1366 406',
            paddingTop:'25px'
        }}
        xmlSpace="preserve"
        {...props}
    >
        <Defs>
            <Path fill='#1d98d4' id="SVGID_1_" d="M73 36.7h1221v329.4H73z" />
        </Defs>
        <ClipPath id="SVGID_00000086693600616071970480000005080835549528960424_">
            <Use
                xlinkHref="#SVGID_1_"
                style={{
                    overflow: 'visible',
                }}
            />
        </ClipPath>
        <G
            id="Grupo_301"
            style={{
                clipPath: 'url(#SVGID_00000086693600616071970480000005080835549528960424_)',
            }}
        >
            <Path
                fill='#1d98d4'
                id="Trazado_1262"
                className="st1"
                d="M73 163.5v-.6c0-61.3 46.2-111.6 112.5-111.6 40.7 0 65 13.6 85.1 33.3l-30.2 34.8c-16.6-15.1-33.6-24.3-55.2-24.3-36.4 0-62.6 30.2-62.6 67.2v.6c0 37 25.6 67.8 62.6 67.8 24.7 0 39.8-9.9 56.7-25.3l30.2 30.5c-22.2 23.7-46.8 38.5-88.5 38.5-63.4.1-110.6-48.9-110.6-110.9"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1263"
                className="st1"
                d="M287.5 105.6h46.8v33.3c9.6-22.8 25-37.6 52.7-36.4v49h-2.5c-31.1 0-50.2 18.8-50.2 58.3v61H327c-21.8 0-39.5-17.7-39.6-39.5V105.6z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1264"
                className="st1"
                d="M397.2 189.1v-.6c0-47.2 33.6-86 81.7-86 55.2 0 80.4 42.8 80.4 89.7 0 3.7-.3 8-.6 12.3h-115c4.6 21.3 19.4 32.4 40.4 32.4 15.7 0 27.1-4.9 40.1-17l26.8 23.7c-15.4 19.1-37.6 30.8-67.5 30.8-49.6.1-86.3-34.8-86.3-85.3m116.5-13.9c-2.8-21-15.1-35.1-34.8-35.1-19.4 0-32.1 13.9-35.8 35.1h70.6z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1265"
                className="st1"
                d="M574.4 188.5v-.6c0-55.2 36.1-85.4 75.2-85.4 25 0 40.4 11.4 51.5 24.7V45.8H748v185.4c0 21.8-17.7 39.6-39.6 39.6h-7.3V247c-11.4 15.4-27.1 26.8-51.5 26.8-38.5.1-75.2-30.2-75.2-85.3m127.3 0v-.6c0-27.4-18.2-45.6-40.1-45.6-21.9 0-40.4 17.9-40.4 45.6v.6c0 27.4 18.5 45.6 40.4 45.6s40.1-18.2 40.1-45.6"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1266"
                className="st1"
                d="M780.6 105.6h46.8v165.2h-7.3c-21.8 0-39.6-17.7-39.6-39.6l.1-125.6z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1267"
                className="st1"
                d="M829.5 62.1c0 14-11.4 25.4-25.4 25.4-14 0-25.4-11.4-25.4-25.4 0-14 11.4-25.4 25.4-25.4 14 0 25.4 11.4 25.4 25.4"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1268"
                className="st1"
                d="M873.4 223.9v-78.3h-19.7v-40.1h19.7V63.3h46.8v42.2H959v40.1h-38.8v70.6c0 10.8 4.6 16 15.1 16 8.1 0 16-2 23.1-5.9V264c-9.9 5.9-21.3 9.6-37 9.6-28.6-.1-48-11.4-48-49.7"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1269"
                className="st1"
                d="M981.6 189.1v-.6c0-47.5 38.2-86 89.7-86 51.2 0 89.1 37.9 89.1 85.4v.6c0 47.5-38.2 86-89.7 86-51.2 0-89.1-37.9-89.1-85.4m132.5 0v-.6c0-24.3-17.6-45.6-43.4-45.6-26.8 0-42.8 20.6-42.8 45v.6c0 24.3 17.6 45.6 43.4 45.6 26.8 0 42.8-20.7 42.8-45"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1270"
                className="st1"
                d="M1204.9 102.5c-19.4-.2-38.3 5.7-54.2 16.8 9.9 10.7 17.3 23.4 21.7 37.3 8-9.1 19.7-14.1 31.8-13.8 25.9 0 43.4 21.3 43.4 45.6v.6c0 24.4-16 45-42.8 45-12.4.2-24.3-5.2-32.4-14.7-4.5 14.3-12.1 27.3-22.3 38.3 15.8 11.2 34.7 17 54.1 16.8 51.5 0 89.7-38.5 89.7-86v-.6c.1-47.4-37.8-85.3-89-85.3"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1271"
                className="st1"
                d="M1088.9 305.4h12l20.1 47.1c1.9 4.6-.2 9.9-4.8 11.8-1.1.5-2.3.7-3.5.7l-5.4-13.3h-25l-5.4 13.3c-4.9 0-8.8-3.9-8.8-8.8 0-1.2.2-2.4.7-3.4l20.1-47.4zm13.7 34.9-7.9-19.2-7.9 19.2h15.8z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1272"
                className="st1"
                d="m1130.5 356.4 7.7-9.2c5.3 4.4 10.9 7.2 17.7 7.2 5.3 0 8.5-2.1 8.5-5.6v-.2c0-3.3-2-5-11.9-7.5-11.9-3-19.6-6.3-19.6-18.1v-.2c0-10.7 8.6-17.9 20.7-17.9 8-.1 15.8 2.5 22 7.5l-6.8 9.8c-5.2-3.6-10.4-5.8-15.4-5.8s-7.6 2.3-7.6 5.2v.2c0 3.9 2.5 5.2 12.8 7.8 12 3.1 18.8 7.4 18.8 17.8v.2c0 11.8-9 18.4-21.7 18.4-9.3-.1-18.2-3.4-25.2-9.6"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1273"
                className="st1"
                d="M189.6 305.8h45.1c0 6.5-5.3 11.8-11.8 11.8h-20.2v12.6H231c0 6.5-5.3 11.8-11.8 11.8h-16.4v22.9c-7.2 0-13-5.8-13-13l-.2-46.1z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1274"
                className="st1"
                d="M238.9 352v-.2c0-9.9 7.5-14.5 18.3-14.5 3.8 0 7.5.6 11.1 1.9v-.8c0-5.3-3.3-8.3-9.7-8.3-4.3 0-8.5.8-12.5 2.5l-3.2-9.8c5.5-2.5 11.5-3.8 17.6-3.6 7 0 12.1 1.9 15.3 5.1 3.4 3.4 4.9 8.4 4.9 14.5v26.3h-12.4v-4.9c-3.5 3.9-8.5 6-13.7 5.8-8.6-.1-15.7-5-15.7-14m29.6-2.9v-2.3c-2.6-1.1-5.4-1.7-8.2-1.7-5.5 0-8.9 2.2-8.9 6.3v.2c0 3.5 2.9 5.5 7 5.5 6-.1 10.1-3.4 10.1-8m-3.1-49.3 11.1 4.8-11 10.2H256l9.4-15z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1275"
                className="st1"
                d="M304.6 359.1v5.9c-7.1 0-12.9-5.8-12.9-12.9v-48.9h12.9v22.9c3.2-4.7 8.5-7.5 14.1-7.4 10.6 0 20.6 8.3 20.6 23.4v.2c0 15.1-9.9 23.4-20.6 23.4-5.5.3-10.7-2.2-14.1-6.6m21.9-16.6v-.2c0-7.5-5.1-12.5-11.1-12.5s-11 5-11 12.5v.2c0 7.5 5 12.5 11 12.5s11.1-4.9 11.1-12.5"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1276"
                className="st1"
                d="M348.8 319.7h12.9v9.1c2.6-6.3 6.9-10.3 14.5-10v13.5h-.7c-8.5 0-13.8 5.2-13.8 16v16.8c-7.1 0-12.9-5.8-12.9-12.9v-32.5z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1277"
                className="st1"
                d="M407.6 342.6v-.2c-.2-12.9 10.1-23.4 23-23.6h.8c8.5 0 13.9 2.9 18.1 7.6l-7.9 8.5c-2.5-3.1-6.3-5-10.3-5-6.4 0-11 5.7-11 12.4v.2c0 6.9 4.5 12.5 11.5 12.5 4-.1 7.8-1.8 10.4-4.8l7.5 7.6c-4.5 5.5-11.4 8.6-18.5 8.3-12.8.3-23.4-9.9-23.6-22.7v-.8"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1278"
                className="st1"
                d="M455.4 352v-.2c0-9.9 7.5-14.5 18.3-14.5 3.8 0 7.5.6 11.1 1.9v-.8c0-5.3-3.3-8.3-9.7-8.3-4.3 0-8.5.8-12.5 2.5l-3.2-9.8c5.5-2.5 11.5-3.8 17.6-3.6 7 0 12.1 1.9 15.3 5.1 3.4 3.4 4.9 8.4 4.9 14.5v13.9c0 6.9-5.6 12.4-12.4 12.4v-4.9c-3.5 3.9-8.5 6-13.7 5.8-8.7-.1-15.7-5-15.7-14m29.5-2.9v-2.3c-2.6-1.1-5.4-1.7-8.2-1.7-5.5 0-8.9 2.2-8.9 6.3v.2c0 3.5 2.9 5.5 7 5.5 6-.1 10.1-3.4 10.1-8"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1279"
                className="st1"
                d="M531.5 342.5v-.2c0-15.1 9.9-23.4 20.6-23.4 5.5-.1 10.8 2.4 14.1 6.8v-22.3h12.9v48.9c0 7.1-5.8 12.9-12.9 12.9v-6.5c-3.2 4.7-8.5 7.5-14.1 7.4-10.5-.2-20.6-8.5-20.6-23.6m35 0v-.2c0-7.5-5-12.5-11-12.5s-11.1 4.9-11.1 12.5v.2c0 7.5 5.1 12.5 11.1 12.5 5.9 0 11-5 11-12.5"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1280"
                className="st1"
                d="M588.4 342.6v-.2c0-12.9 9.2-23.6 22.4-23.6 15.1 0 22.1 11.8 22.1 24.6 0 1-.1 2.2-.2 3.4h-31.6c.8 5.4 5.6 9.2 11.1 8.9 4.2.1 8.1-1.6 11-4.7l7.4 6.5c-4.5 5.6-11.4 8.7-18.5 8.5-13.6.1-23.7-9.5-23.7-23.4m32-3.8c-.8-5.8-4.1-9.6-9.6-9.6-5.3 0-8.8 3.8-9.8 9.6h19.4z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1281"
                className="st1"
                d="M666.6 335.6v-.2c-.3-16.6 12.9-30.3 29.6-30.6h1.3c11.2 0 17.9 3.7 23.4 9.1l-8.3 9.6c-4.6-4.1-9.2-6.7-15.1-6.7-10 0-17.2 8.3-17.2 18.4v.2c0 10.2 7 18.6 17.2 18.6 6.8 0 10.9-2.7 15.6-6.9l8.3 8.4c-6 7.1-15 11-24.3 10.6-16.5.3-30.1-12.9-30.4-29.4-.1-.4-.1-.7-.1-1.1"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1282"
                className="st1"
                d="M730.3 319.7h12.9v9.1c2.6-6.3 6.9-10.3 14.5-10v13.5h-.7c-8.5 0-13.8 5.2-13.8 16v16.8c-7.1 0-12.9-5.8-12.9-12.9v-32.5z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1283"
                className="st1"
                d="M762.2 342.6v-.2c0-12.9 9.2-23.6 22.4-23.6 15.1 0 22.1 11.8 22.1 24.6 0 1-.1 2.2-.2 3.4H775c.8 5.4 5.6 9.2 11.1 8.9 4.2.1 8.1-1.6 11-4.7l7.4 6.5c-4.5 5.6-11.4 8.7-18.5 8.5-13.7.1-23.8-9.5-23.8-23.4m32-3.8c-.8-5.8-4.1-9.6-9.6-9.6-5.3 0-8.8 3.8-9.8 9.6h19.4zm-5.4-39.1 11.1 4.8-11 10.2h-9.5l9.4-15z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1284"
                className="st1"
                d="M814.3 342.5v-.2c0-15.1 9.9-23.4 20.6-23.4 5.5-.1 10.8 2.4 14.1 6.8v-22.3h12.9v48.9c0 7.1-5.8 12.9-12.9 12.9v-6.5c-3.2 4.7-8.5 7.5-14.1 7.4-10.6-.2-20.6-8.5-20.6-23.6m34.9 0v-.2c0-7.5-5-12.5-11-12.5s-11.1 4.9-11.1 12.5v.2c0 7.5 5.1 12.5 11.1 12.5s11-5 11-12.5"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1285"
                className="st1"
                d="M901.1 352.2v-21.5h-5.4v-11h5.4v-11.6H914v11.6h10.7v11H914v19.4c0 3 1.3 4.4 4.1 4.4 2.2 0 4.4-.5 6.3-1.6v10.3c-3.1 1.8-6.6 2.7-10.2 2.6-7.8 0-13.1-3.1-13.1-13.6"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1286"
                className="st1"
                d="M930.6 342.6v-.2c0-13 10.5-23.6 24.6-23.6 14 0 24.4 10.4 24.4 23.4v.2c0 13-10.5 23.6-24.6 23.6-14 .1-24.4-10.3-24.4-23.4m36.4 0v-.2c.3-6.6-4.9-12.3-11.6-12.5h-.4c-7.4 0-11.8 5.7-11.8 12.4v.2c-.3 6.6 4.9 12.3 11.6 12.5h.4c7.4 0 11.8-5.7 11.8-12.4"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1287"
                className="st1"
                d="m1011.6 356.4 7.7-9.2c5.3 4.4 10.9 7.2 17.7 7.2 5.3 0 8.5-2.1 8.5-5.6v-.2c0-3.3-2-5-11.9-7.5-11.9-3-19.6-6.3-19.6-18.1v-.2c0-10.7 8.6-17.9 20.7-17.9 8-.1 15.8 2.5 22 7.5l-6.8 9.8c-5.2-3.6-10.4-5.8-15.4-5.8s-7.6 2.3-7.6 5.2v.2c0 3.9 2.5 5.2 12.8 7.8 12 3.1 18.8 7.4 18.8 17.8v.2c0 11.8-9 18.4-21.7 18.4-9.3-.1-18.2-3.4-25.2-9.6"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1288"
                className="st1"
                d="M384.8 319.7h12.9V365c-7.1 0-12.9-5.8-12.9-12.9v-32.4zm6.4-16.3c-3.1 0-5.6 2.5-5.6 5.6 0 3.1 2.5 5.6 5.6 5.6s5.6-2.5 5.6-5.6c.1-3.1-2.5-5.6-5.6-5.6"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1289"
                className="st1"
                d="M874 319.7h12.9V365c-7.1 0-12.9-5.8-12.9-12.9v-32.4z"
            />
            <Path
                fill='#1d98d4'
                id="Trazado_1290"
                className="st1"
                d="M880.4 303.4c-3.1 0-5.6 2.5-5.6 5.6s2.5 5.6 5.6 5.6 5.6-2.5 5.6-5.6c0-3.1-2.5-5.6-5.6-5.6"
            />
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const Logo = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    props: PropTypes.any
}

Logo.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}