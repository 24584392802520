import React from 'react'
import Svg, { Defs, Ellipse, Path } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
// eslint-disable-next-line react/prop-types
export const LogoLogin = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_1"
        data-name="Capa 1"
        viewBox="0 0 528.91 255.11"
        {...props}
    >
        <Defs></Defs>
        <Path
            d="M386.19 108.1V3.47c.03-1.83.27-2.1 2.05-2.49 4.61-1 9.31-1.21 13.95-.77 8.66.82 16.51 3.92 23.35 9.39 10.09 8.07 15.01 18.8 16.12 31.46.22 2.51.18 5.03.18 7.54v92.04c0 17.82-.06 35.63.07 53.45.08 11.35-.62 22.67-.51 34.02.03 3.44.28 6.85 1.17 10.2 1.76 6.65 5.65 11.62 11.64 14.99.52.29 1.06.56 1.58.86.2.12.47.26.39.53-.07.25-.35.3-.6.31-.24.01-.48.02-.72.02-8.83-.01-17.67.07-26.5-.06-19.32-.28-36.04-13.67-40.68-32.43-1.51-6.11-1.58-12.33-1.52-18.57 0-.6.04-1.2.04-1.8v-94.08ZM0 222.41c0-1.37.01-2.73.02-4.1.21-8.33 3.52-15.19 9.38-21.1 13.7-13.82 27.24-27.78 40.83-41.71 10.68-10.95 21.33-21.94 31.99-32.92l1.92-1.98c.2-.2.45-.41.3-.72-.13-.28-.44-.33-.72-.33-.72 0-1.44.03-2.16.03H3.5c-3.08 0-3.32.29-3.35-3.16C.09 109.7.11 103 1.95 96.46 6.6 79.98 22.06 66.75 39.08 64.7c1.98-.24 3.98-.33 5.98-.33h105.52c14.8 0 26.5 11.2 27.27 26.01.41 7.97-2.25 14.74-7.76 20.41-10.95 11.25-21.93 22.47-32.89 33.71-11.8 12.1-23.59 24.2-35.37 36.31-11.2 11.51-22.38 23.04-33.6 34.53-9.44 9.66-8.57 21.64-3.61 29.83 2.32 3.83 5.72 6.52 9.63 8.59.28.15.57.29.85.44.21.11.5.2.39.5-.08.24-.34.32-.6.32-.28 0-.56.01-.84.01-14.19 0-28.38-.11-42.57.03-12.64.12-21.74-5.61-27.95-16.32-2.14-3.7-2.95-7.81-3.23-12.04-.09-1.43-.2-2.87-.3-4.3Z"
            className="cls-1"
            fill='#003478'
        />
        <Path
            d="M120.52 255H98.34c-8.62 0-14.95-4.49-17.88-12.62-2.58-7.17-1.21-13.5 3.91-19.07 4.46-4.86 9.12-9.51 13.74-14.22 5.09-5.19 10.17-10.39 15.21-15.63 6.53-6.8 14.37-11.04 23.79-11.96 10.33-1.01 19.57 1.85 27.45 8.65 6.77 5.84 11.42 13.02 12.85 21.98 1.18 7.39-.14 14.49-3.08 21.29-5.15 11.91-14.53 18.47-26.98 21.1-1.77.37-3.55.52-5.36.51-7.15-.02-14.31 0-21.46 0V255Z"
            style={{
                fill: '#dfdf01',
                strokeWidth: 0,
            }}
        />
        <Path
            d="M470.3 151.39v-45.42c0-6.55-.06-13.1-.07-19.65 0-3.59.04-7.19.07-10.78.02-1.94.2-2.18 2.08-2.58 24.31-5.27 48.72 9.13 54.09 34.88.99 4.78.78 9.61.67 14.44-.07 3.03-.1 6.07-.14 9.1-.31 21.61-.19 43.22-.19 64.83 0 8.27.05 16.54.13 24.8.04 4.4.02 8.78-1.05 13.09-2.66 10.69-12.06 19.05-22.96 20.67-17.58 2.6-29.56-10-31.89-22.43-.7-3.75-.7-7.55-.79-11.34-.25-10.74-.33-21.49-.28-32.23.05-11.14.18-22.29.27-33.43.01-1.32 0-2.63 0-3.95h.07Z"
            className="cls-1"
            fill='#003478'
        />
        <Ellipse cx={498.91} cy={29.73} className="cls-1" rx={30} ry={29.5} fill='#003478'/>
        <Path
            d="M371.87 253.85c-.17-.1-.36-.16-.54-.25-3.11-1.54-5.9-3.51-8.21-6.13-4.09-4.64-5.71-10.16-5.71-16.23v-115.9c0-3.76-.41-7.49-1.25-11.16-5.44-23.65-26.3-40.24-50.61-40.25-15.11 0-30.22-.01-45.33.01-1.79 0-3.6.06-5.38.29-16.28 2.12-29.01 9.89-38.02 23.72-5.47 8.4-7.82 17.66-8.25 27.52-.06 1.5.55 2.09 2.09 2.15.76.03 1.52 0 2.28 0h81.9c.44 0 .88 0 1.32.02 4.92.27 8.26 3.51 8.64 8.41.15 1.87.09 3.76-.14 5.62-.48 3.97-2.89 6.51-6.81 7.35-1.14.24-2.3.3-3.45.3h-36.45c-9.89 0-19.37 1.95-28.41 5.93-14.71 6.47-24.45 17.35-28.82 32.87-3.68 13.04-2.63 25.93 1.44 38.68 2.7 8.47 7.08 15.97 13.53 22.16 7.55 7.24 16.64 11.61 26.69 14.12 8.59 2.14 17.31 2.33 26.08 1.61 8.25-.68 15.88-3.16 22.68-7.93 4.4-3.08 8.35-6.69 12.01-10.62 1.39-1.49 1.85-1.47 3.01.2 1.12 1.61 2.37 3.12 3.77 4.5 9.24 9.16 20.51 13.69 33.4 14.07 9.42.28 18.86.1 28.29.11.4 0 1.01.13 1.1-.43.07-.45-.5-.55-.83-.75Zm-71.05-62.3c-1.11 6.35-4.56 11.03-10.47 13.65-7.89 3.51-16.11 3.9-24.36 1.56-7.96-2.26-12.68-10.88-10.18-18.38 1.92-5.77 6.27-8.85 12.06-10.09 2-.43 4.03-.59 6.07-.59 3.83-.02 7.67 0 11.5 0h13.9c1.98 0 2.35.33 2.33 2.29-.04 3.87-.18 7.74-.85 11.57Z"
            className="cls-1"
            fill='#003478'
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color }) => (
    <LogoLogin
        width={100}
        height={40}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}