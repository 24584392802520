import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { Relationships } from '../Selects/Relationships'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, ButtonPlus, TitlePlus, BoxBorder } from './styled'

export const RelationDataRegisterV = ({ data: { banner, values, errors, loading }, handleBlur, handleChange, handleSubmit, handlePlusRelation }) => (
    <Container>
        {banner}
        <ButtonPlus onPress={handlePlusRelation}>
            <TitlePlus>Más Parientes</TitlePlus>
        </ButtonPlus>
        <Form>
            {!!values?.creditPayrollRelationships?.length && values.creditPayrollRelationships.map((x, i) => (
                <React.Fragment key={i}>
                    <Relationships value={x?.rId} handleSelect={e => handleChange(e, i)} error={errors?.creditPayrollRelationships?.length === values?.creditPayrollRelationships?.length && errors?.creditPayrollRelationships[i]?.rId} />
                    <InputHook title='Nombres y Apellidos *' returnKeyType='next' value={x?.cprName} error={errors?.creditPayrollRelationships?.length === values?.creditPayrollRelationships?.length && errors?.creditPayrollRelationships[i]?.cprName} name='cprName' required letters range={[2, 50]} onBlur={handleBlur} onChangeText={e => handleChange(e, i)} />
                    <InputHook title='Porcentaje del beneficiario *' returnKeyType='next' value={x?.cprPercentage} error={errors?.creditPayrollRelationships?.length === values?.creditPayrollRelationships?.length && errors?.creditPayrollRelationships[i]?.cprPercentage} name='cprPercentage' required numeric onBlur={handleBlur} onChangeText={e => handleChange(e, i)} />
                    <BoxBorder style={{ borderBottomWidth: (i + 1) < values.creditPayrollRelationships.length ? 1 : 0 }} />
                </React.Fragment>
            ))}
            <BoxButton>
                <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

RelationDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handlePlusRelation: PropTypes.func,
    handleSubmit: PropTypes.func
}