// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { FlatList } from 'react-native'
import { useTheme } from 'styled-components/native'
import { FontAwesome, MaterialIcons } from '@expo/vector-icons'

// Components
import { RepaymentPlanHeadV } from '../RepaymentPlanHead'
import { MoneyReportsS } from '../../skeleton/MoneyReportsS'

// Utils and Styles
import { ShadowBox } from '../../generalStyles'
import { numberFormat, mq } from '../../validation'
import { IconConfirmPayment, IconPendingPayment } from '../../../assets/icons'
import { Container, TextEmpty, Row, TitleInfo, BoxList, ButtonIconOption, TextList, BodyList, BodyOptionList } from './styled'

/**
 * RepaymentPlanV
 * @param {any} props Properties RepaymentPlanV
 * @returns {ReactElement} Component Repayment
 */
export const RepaymentPlanV = ({ data: { credit, table, loading, see, calculateFee }, changeTable, handleOpen }) => {
    // States and Variables
    const theme = useTheme()

    return (
        <Container>
            <RepaymentPlanHeadV data={{ credit, see, calculateFee }} handleOpen={handleOpen} />
            {credit?.creditFormat?.cfPlaAmoAft !== 0 &&
                <BoxList style={ShadowBox}>
                    {loading ?
                        <>
                            <Row padding={mq(14)}>
                                <TitleInfo color={theme.SFColor} size={mq(15)} numberOfLines={1}>Plan de Amortización</TitleInfo>
                                <ButtonIconOption onPress={changeTable}>
                                    {table ? <MaterialIcons name='view-compact' size={mq(15)} color={theme.BGColor} /> : <FontAwesome name='th-list' size={mq(15)} color={theme.BGColor} />}
                                </ButtonIconOption>
                            </Row>
                            <BodyList bgColor={theme.PLColor}>
                                <TitleInfo>{(credit?.cModule === 2 && credit?.cType === 2) ? 'S.' : 'M.'}</TitleInfo>
                                <TitleInfo>Saldo I.</TitleInfo>
                                <TitleInfo>Capital</TitleInfo>
                                <TitleInfo>Interés</TitleInfo>
                                <TitleInfo>Saldo F.</TitleInfo>
                            </BodyList>
                            <MoneyReportsS />
                        </> :
                        <FlatList
                            data={credit.repaymentPlan}
                            ListHeaderComponent={
                                <>
                                    <Row padding={mq(4)}>
                                        <TitleInfo color={theme.SFColor} size={mq(15)} numberOfLines={1}>Plan de Amortización</TitleInfo>
                                        <ButtonIconOption onPress={changeTable}>
                                            {table ? <MaterialIcons name='view-compact' size={mq(15)} color={theme.BGColor} /> : <FontAwesome name='th-list' size={mq(15)} color={theme.BGColor} />}
                                        </ButtonIconOption>
                                    </Row>
                                    {!!table &&
                                    <BodyList bgColor={theme.PLColor}>
                                        <TitleInfo align='center' width={'16%'} >{(credit?.cModule === 2 && credit?.cType === 2) ? 'S.' : 'M.'}</TitleInfo>
                                        <TitleInfo align='center' width={'21%'} >Saldo I.</TitleInfo>
                                        <TitleInfo align='center' width={'21%'} >Capital</TitleInfo>
                                        <TitleInfo align='center' width={'21%'} >Interés</TitleInfo>
                                        <TitleInfo align='center' width={'21%'} >Saldo F.</TitleInfo>
                                    </BodyList>
                                    }
                                </>
                            }
                            ListEmptyComponent={<TextEmpty>No se ha creado la tabla de amortización.</TextEmpty>}
                            keyExtractor={item => `${item.index}`}
                            showsVerticalScrollIndicator={false}
                            renderItem={({ item, index }) => table ?
                                <BodyList borderBottom={index < credit.repaymentPlan?.length} paddingHorizontal={mq(2)} bgColor={item.fee ? '#0CFF1750' : (item.arrears && '#FF3B3B50')}>
                                    <TextList width={'16%'} align='center' numberOfLines={1}>{item.date}</TextList>
                                    <TextList width={'21%'} numberOfLines={1}>$ {numberFormat(item.startBalance)}</TextList>
                                    <TextList width={'21%'} numberOfLines={1}>$ {numberFormat(item.capital)}</TextList>
                                    <TextList width={'21%'} numberOfLines={1}>$ {numberFormat(item.interest)}</TextList>
                                    <TextList width={'21%'} numberOfLines={1}>$ {numberFormat(item.endBalance)}</TextList>
                                </BodyList>
                                :
                                <BodyOptionList borderBottom={index < credit.repaymentPlan?.length}>
                                    <Row padding={mq(.5)}>
                                        <TitleInfo width={'18%'} numberOfLines={1}>Cuota</TitleInfo>
                                        <TitleInfo width={'41%'} align='right' numberOfLines={1}>Saldo Inicial</TitleInfo>
                                        <TitleInfo width={'41%'} align='right' numberOfLines={1}>Capital</TitleInfo>
                                    </Row>
                                    <Row>
                                        <TextList align='left' width='18%'>{item.date}</TextList>
                                        <TextList numberOfLines={1} width='41%'>$ {numberFormat(item.startBalance)}</TextList>
                                        <TextList numberOfLines={1} width='41%'>$ {numberFormat(item.capital)}</TextList>
                                    </Row>
                                    <Row padding={mq(.5)}>
                                        <TitleInfo width={'18%'} marginBottom={-mq(6)}>
                                            {item.fee ? <IconConfirmPayment width={mq(20)} height={mq(18)} color={theme.BGColor} /> : <IconPendingPayment width={mq(25)} height={mq(25)} color={theme.BGColor} color2={theme.PVColor} /> }
                                        </TitleInfo>
                                        <TitleInfo width={'41%'} align='right' numberOfLines={1}>Interés</TitleInfo>
                                        <TitleInfo width={'41%'} align='right' numberOfLines={1}>Saldo Final</TitleInfo>
                                    </Row>
                                    <Row>
                                        <TextList width={'18%'}/>
                                        <TextList numberOfLines={1} width={'41%'}>$ {numberFormat(item.interest)}</TextList>
                                        <TextList numberOfLines={1} width={'41%'}>$ {numberFormat(item.endBalance)}</TextList>
                                    </Row>
                                </BodyOptionList>
                            }
                        />
                    }
                </BoxList>
            }
        </Container>
    )
}

RepaymentPlanV.propTypes = {
    data: PropTypes.object,
    changeTable: PropTypes.func,
    handleOpen: PropTypes.func,
}