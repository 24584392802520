import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Circle, Defs, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ props }) => (
    <Svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 392 392"
        {...props}
    >
        <Defs></Defs>
        <Circle
            cx={194.92}
            cy={197.08}
            r={190.53}
            style={{
                isolation: 'isolate'
            }}
            opacity={0.19}
            fill="#ec7234"
        />
        <Circle
            cx={194.92}
            cy={197.08}
            r={155.77}
            style={{
                isolation: 'isolate'
            }}
            opacity={0.43}
            fill="#ec7234"
        />
        <Circle cx={194.92} cy={197.08} r={119.33} fill="#ec7234" />
        <Path
            className="cls-1"
            fill="#ffffff"
            d="M197.59 181.67v-38.11c0-.91.97-1.5 1.78-1.07l72.97 38.99a6.237 6.237 0 013.32 5.5v35.29l-78.08-40.6zM275.67 233.46v15.47c0 2.2-2.4 3.59-4.35 2.52l-71.89-39.39a3.528 3.528 0 01-1.84-3.11v-16.59l78.08 41.09z"
        />
        <G>
            <Path
                fill="#ffffff"
                className="cls-1"
                d="M192.24 181.67v-38.11c0-.91-.97-1.5-1.78-1.07l-72.97 38.99a6.237 6.237 0 00-3.32 5.5v35.29l78.08-40.6zM114.17 233.46v15.47c0 2.2 2.4 3.59 4.35 2.52l71.89-39.39a3.528 3.528 0 001.84-3.11v-16.59l-78.08 41.09z"
            />
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}