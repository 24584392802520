import React from 'react'
import PropTypes from 'prop-types'
import { ImageBackground } from 'react-native'
import ImageManipulator from './ImagenPicker.jsx'
import { hp, wp } from '../../validation'

export const ImageEditV = ({ photo, onToggleModal, onPicture }) => {
    return (
        photo.uri ?
            <ImageBackground
                resizeMode='contain'
                style={{ zIndex: 999999, display: 'flex', justifyContent: 'center', padding: 20, alignItems: 'center', height: hp(100), width: wp(100), backgroundColor: 'black' }}
                source={{ uri: photo.uri }}
            >
                <ImageManipulator
                    photo={{ uri: photo.uri }}
                    resizeMode='contain'
                    btnTexts={{ 'crop': 'Cortar', 'done': 'Listo', 'processing': 'Procesando, espere.' }}
                    isVisible
                    onPictureChoosed={({ uri }) => onPicture(uri)}
                    onToggleModal={onToggleModal}
                />
            </ImageBackground>
            :
            <ImageBackground resizeMode='contain' style={{ zIndex: 999999, display: 'flex', justifyContent: 'center', padding: 20, alignItems: 'center', height: hp(100), width: wp(100), backgroundColor: 'black' }} />
    )
}

ImageEditV.propTypes = {
    photo: PropTypes.object,
    onToggleModal: PropTypes.func,
    onPicture: PropTypes.func
}