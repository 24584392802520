import { gql } from '@apollo/client'

export const GET_CREDIT_BY_ID = gql`
  query GetCreditById($state: [Int], $cId: ID) {
    getCreditById(state: $state, cId: $cId) {
      cConsecutive
      cId
      pcId
      cName
      cValCou
      cFirFee
      cValCre
      cReqVal
      payrollCompany {
        typeIdentity {
          tiName
          tiPrefix
          tiId
        }
        payrollStaff {
          pcId
          psFirNam
          psSecNam
          psFirLas
          psSecLas
          pcIdeNum
          psEmail
          psPhoMob
        }
        tiId
        pcIdeNum
        pcId
        payrollResidence {
          taId
          prNumDir
          prNum
          prNumHou
          prInformation
        }
      }
      creditOrigins {
        coId
        coApplied
        toId
        coAmount
        coDate
        icId
        cId
        coState
      }
    }
  }
`
export const CREATE_CREDIT_ELECTRONIC_PAYMENT = gql`
mutation CreateCreditElectronicPayment($input: ICreditElectronicPayment!) {
  createCreditElectronicPayment(input: $input) {
    idCEP
    cId
    ticketId
    applied
    invoice
    state
  }
}
`
export const GET_ONE_CREDIT_ELECTRONIC_PAYMENT = gql`
query CreditElectronicPaymentById($idCep: ID) {
  creditElectronicPaymentById(idCEP: $idCep) {
    idCEP
    cId
    ticketId
    applied
    invoice
    state
  }
}
`
export const GET_CONSECUTIVE_ELECTRONIC_PAYMENT = gql`
query GetConsecutiveById($cId: ID) {
  getConsecutiveById(cId: $cId) {
    cId
    cName
    cInitial
    cConsecutive
    cState
  }
}
`

export const CALCULATE_FEE = gql`
    query CalculateFee($cId: String, $feeQuantity: Int) {
        CalculateFee(cId: $cId, feeQuantity: $feeQuantity) {
          totalInterestArrears
          totalCollectionCostFee
          totalFee
          totalArrearsCredit
        }
    }
`