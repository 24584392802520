import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container, HeaderContainer, Title } from '../CreditTypes/styled'
import { ScrollView, View } from 'react-native-web'
import { mq } from '../../validation'
import { useTheme } from 'styled-components/native'
import { AntDesign } from '@expo/vector-icons'

export const CreditsLineV = ({
    listGroupLine,
    onCreditLine,
})=> {
    const theme = useTheme();
    return (<Container>
        <HeaderContainer>
            <Title paddingBottom='0' size={mq(20)} fontFamily='PFontBold'>Grupo de Líneas de Crédito</Title>
            <Title textAlign='center'>Solicita tu crédito en 15 minutos.</Title>
        </HeaderContainer>
        <ScrollView showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps='handled'
        >
            {!listGroupLine?.length ?
                <View style={{
                    padding: '14px',
                    marginTop: '50%' }}>
                    <Title title textAlign='center'>No se encontraron grupos de líneas de crédito</Title>
                </View>
                :
                listGroupLine?.map((x, index) => {
                    return (
                        <View
                            style={{
                                padding: '10px',
                                flexWrap: 'wrap',
                                alignContent: 'space-around' }}
                            key={`EkIlYXl856LK6Itp7gdK7${index}`}
                            showsVerticalScrollIndicator>
                            <Button
                                bgColor={theme.PColor}
                                style={{ width:'90%', height: '100%' }}
                                onPress={() => onCreditLine(x?.gclId)}
                            >
                                <AntDesign
                                    style={{ width: '10%' }}
                                    name="bars" size={mq(20)}
                                    color={theme.BGColor} />
                                <View style={{ padding: '10px', width: '90%', textAlign: 'center' }}>
                                    <Title
                                        size={mq(15)}
                                        paddingBottom='0'
                                        paddingLeft={mq(8)}
                                        color={theme.BGColor}
                                    >
                                        {x?.gclName?.toUpperCase()}
                                    </Title>
                                </View>
                            </Button>
                        </View>
                    )
                })
            }
        </ScrollView>
    </Container>)
}
CreditsLineV.propTypes = {
    listGroupLine: PropTypes.array,
    onCreditLine: PropTypes.func
}