import { useLazyQuery, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { OPSDataRegisterV } from '../../components/OPSDataRegister'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { filterKeyObject, writeCache, validationErrors, getData, setData, numberFormat } from '../../validation'
import { calculatorCreditSelect } from '../Credits/calculatorCreditSelect'
import { USE_ONE_CREDIT_TYPE } from '../Credits/queries'
import { UPDATE_PAYROLL_COMPANY } from './queries'

export const OPSDataRegisterC = ({ group, payment, dataUser, banner, handleNext, refScroll, reTanking, optPay, credits, permission }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [reCalculator, setReCalculator] = useState({ state: false })
    const [infCredit, setInfCredit] = useState({})
    const [payrollCompanyMutation, { loading }] = useMutation(UPDATE_PAYROLL_COMPANY)
    const [getOneCrediType, { data: dataCT, loading: loadCT }] = useLazyQuery(USE_ONE_CREDIT_TYPE)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({
            payrollOPS: filterKeyObject(dataUser?.payrollOPS, ['__typename'])
        })
        setErrorForm({
            poContractor: !dataUser?.payrollOPS?.poContractor,
            poObject: !dataUser?.payrollOPS?.poObject,
            poValCon: !dataUser?.payrollOPS?.poValCon,
            poValDatSta: !dataUser?.payrollOPS?.poValDatSta,
            poValDatEnd: !dataUser?.payrollOPS?.poValDatEnd
        })
        setInfCredit({})
        setReCalculator({ state: false })
        getData('infCredit', true).then(x => {
            if (x) {
                setInfCredit(x)
                getOneCrediType({ variables: { ctId: x.ctId } })
            }
        })
    }, [dataUser])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ payrollOPS: { ...values.payrollOPS, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
        setReCalculator({ ...reCalculator, state: false })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        // verifica que la petición se resuelva primero
        if (loadCT) return isLoggedVar({ ...isLoggedVar(), message: 'Espere un momento.' })

        if (!reCalculator.state && !group) {
            // busca el ultimo dia del mes de la fecha final del contrato
            const dateEnd = moment(values?.payrollOPS?.poValDatEnd)
            const dateNewEnd = moment([dateEnd.format('YYYY'), Number(dateEnd.format('MM')) - 1, new Date(dateEnd.format('YYYY'), dateEnd.add(1, 'm').format('MM'), 0).getDate()])
            let dateNew = moment()

            // verifica si su contrato vence en el mismo mes que se encuentra
            if (dateNew.format('MM-YYYY') === dateEnd.format('MM-YYYY')) return isLoggedVar({ ...isLoggedVar(), message: 'No es posible continuar por que su contrato finalizara en el mes presente.' })

            // guarda la nueva fecha disponible
            if (dateEnd.format('DD-MM-YYYY') === dateNewEnd.format('DD-MM-YYYY')) dateNew = moment(dateNewEnd)
            else dateNew = moment(dateNewEnd.subtract(1, 'months'))

            // calcula el total de meses de su contrato
            const monEnd = dateNew.diff(moment(), 'month')

            // verifica si hay mes disponible
            if (monEnd <= 0) return isLoggedVar({ ...isLoggedVar(), message: 'No es posible continuar por que su contrato no tiene meses disponibles.' })

            if (monEnd > 0 && monEnd < infCredit?.cMonVal) {
                // recalcula el cre´dito
                const resultCCS = calculatorCreditSelect({ payment, newMoney: infCredit?.cReqVal, params: dataCT?.getCreditTypeById, payroll: dataUser, newMonth: monEnd })

                // verifica si hay un error del calculo
                if (resultCCS.error) return isLoggedVar({ ...isLoggedVar(), message: resultCCS.message })

                // verifica que la fecha de pago no sea mayor a la fecha del contrato
                if (!!resultCCS?.data?.dateEnd && moment(resultCCS?.data?.dateEnd) > dateEnd) return isLoggedVar({ ...isLoggedVar(), message: 'No es posible continuar por que su contrato es menor a la fecha de pago, seleccione otro crédito.' })

                // verifica si se activo el retanqueo
                if (reTanking === 1 || optPay === 1) {
                    let valTanking = resultCCS?.data?.vcDefVal
                    let payCou = payment
                    const { payrollWork } = dataUser

                    // verifica que el monto de desembolso sea suficiente para pagar
                    credits?.forEach(x => {
                        if (valTanking >= x.cBalance && x.cState === 6) {
                            valTanking -= x.cBalance
                            payCou -= x.cValCou
                        }
                    })

                    // Verifica si se cancela un crédito con el monto de solicitud
                    if (valTanking === resultCCS?.data?.vcDefVal) return isLoggedVar({ ...isLoggedVar(), message: reTanking === 1 ? 'El monto de desembolso no es posible usarse como retanqueo por que no cancela la deuda completa del crédito anterior.' : 'El monto de desembolso no es posible usarse como pago de un crédito por que no cumple el monto suficiente para un crédito.' })

                    // verifica que el monto de la cuota disponible alcance a pagar
                    if (((payrollWork?.pwNet || 0) / 2) <= (payCou + resultCCS?.data?.valCou) && !permission) return isLoggedVar({ ...isLoggedVar(), message: `Su cuota no puede ser mayor a $ ${numberFormat(Math.round((payrollWork?.pwNet / 2) - payment))}.` })
                }
                // reinicia los estados
                const newValues = { cMonVal: resultCCS?.data?.mDefVal, cReqVal: resultCCS?.data?.vcDefVal, ctId: infCredit.ctId, dateEnd: resultCCS?.data?.dateEnd, cReTanking: reTanking, cpsSellerCode:null }
                setData('infCredit', JSON.stringify(newValues))
                setInfCredit(newValues)
                isLoggedVar({ ...isLoggedVar(), message: 'Su crédito sera calculado a base de su contrato.' })
                return setReCalculator({ state: true, ...newValues, valCou: resultCCS?.data?.valCou })
            }
        }

        const { data, error } = await payrollCompanyMutation({ variables: { input: values }, update(store, { data: { setOrUpdatePayrollCompany } }) {
            writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: setOrUpdatePayrollCompany, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
        } }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdatePayrollCompany) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(4)
        }
    }

    return (
        <OPSDataRegisterV
            data={{ values, errors, loading, banner, reCalculator, reTanking, optPay }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

OPSDataRegisterC.propTypes = {
    payment: PropTypes.number,
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    refScroll: PropTypes.object,
    credits: PropTypes.array,
    reTanking: PropTypes.number,
    optPay: PropTypes.number,
    permission: PropTypes.bool,
    group: PropTypes.bool
}