import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { hp, wp } from '../../validation'

export const Container = styled.View`
    flex: 1;
    border-top-left-radius: ${wp(7)}px;
    border-top-right-radius: ${wp(7)}px;
`
export const Form = styled.View`
    padding: ${wp(2.5)}px;
    justify-content: center;
`
export const BoxButton = styled.View`
    margin: ${wp(3)}px 0;
    align-items: center;
`
export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`
export const Box = styled.View`
    padding: ${hp(1.2)}px 0 ${hp(.7)}px;
`
export const Title = styled.Text`
    font-family: PFontBold;
    font-size: ${wp(4.5)}px;
    color: ${theme.primaryC};
    text-align: center;
`