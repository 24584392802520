import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Animated, Easing } from 'react-native'
import { Container, Title } from './styled'
import { hp, wp } from '../../validation'

const TopInAnimate = ({ duration, onHidden, style, children }) => {
    const topIn = useRef(new Animated.Value(-hp(10))).current

    useEffect(() => {
        Animated.stagger(duration, [
            Animated.timing(
                topIn,
                {
                    toValue: 0,
                    easing: Easing.back(),
                    duration: 300,
                    useNativeDriver: true
                }
            ),
            Animated.timing(
                topIn,
                {
                    toValue: -hp(10),
                    duration: 200,
                    useNativeDriver: true
                }
            )
        ]).start(({ finished }) => {
            if (finished && onHidden) onHidden()
        })
    }, [topIn])

    return (
        <Animated.View
            style={{
                ...style,
                transform: [{ translateY: topIn }]
            }}
        >
            {children}
        </Animated.View>
    )
}

export const BannerNotification = ({ duration, onHidden, message }) => {
    return (
        <Container>
            <TopInAnimate style={{
                with: wp(100),
                height: hp(10),
                backgroundColor: '#14110fc7',
                padding: 16,
                justifyContent: 'center',
                alignContent: 'center'
            }}
            duration={duration || 2500}
            onHidden={onHidden}
            >
                <Title>{message}</Title>
            </TopInAnimate>
        </Container>
    )
}

TopInAnimate.propTypes = {
    duration: PropTypes.number,
    onHidden: PropTypes.func,
    style: PropTypes.object,
    children: PropTypes.node
}

BannerNotification.propTypes = {
    duration: PropTypes.number,
    onHidden: PropTypes.func,
    message: PropTypes.string
}