import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { BorderBottom, Title } from './styled'
import { wp } from '../../validation'

export const ChangePassV = ({ data: { errors, values, loading }, handleBlur, handleChange, handleSubmit }) => (
    <>
        <Title size={wp(5)}>Cambiar Contraseña</Title>
        <BorderBottom/>
        <InputHook title='Contraseña *' secureTextEntry error={errors?.uPass} value={values?.uPass} name='uPass' required onBlur={handleBlur} onChangeText={handleChange} />
        <InputHook title='Confirmar Contraseña *' secureTextEntry error={errors?.conPass} value={values?.conPass} name='conPass' equalTo={{ value: values?.uPass, errorMessage: 'Las contraseñas no coinciden.' }} required onBlur={handleBlur} onChangeText={handleChange} />
        <ButtonSubmit onPress={handleSubmit} title='Cambiar Contraseña' loading={loading} />
    </>
)

ChangePassV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
}