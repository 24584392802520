import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { RelationDataRegisterV } from '../../components/CreditEditData/RelationDataRegister'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { updateCache } from '../../validation'
import { EDIT_CREDIT_PAYROLL_RELATION } from './queries'

export const RelationDataRegisterC = ({ dataUser, banner, handleNext, refScroll }) => {
    const [values, setValues] = useState({ creditPayrollRelationships: [] })
    const [errors, setErrors] = useState({ creditPayrollRelationships: [] })
    const [errorForm, setErrorForm] = useState({ creditPayrollRelationships: [] })
    const [creditPayrollRelationMutation, { loading }] = useMutation(EDIT_CREDIT_PAYROLL_RELATION)

    // Cambio en los valores
    const handleChange = (e, index) => {
        setValues({ creditPayrollRelationships: values.creditPayrollRelationships?.length && values.creditPayrollRelationships.map((x, i) => i === index ? ({ ...x, [e.name]: e.value }) : x) })
        setErrorForm({ creditPayrollRelationships: errorForm.creditPayrollRelationships?.length && errorForm.creditPayrollRelationships.map((x, i) => i === index ? ({ ...x, [e.name]: e.error }) : x) })
        setErrors({ creditPayrollRelationships: errors.creditPayrollRelationships?.length && errors.creditPayrollRelationships.map((x, i) => i === index ? ({ ...x, [e.name]: e.error }) : x) })
    }

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({ creditPayrollRelationships: dataUser?.creditPayrollRelationships?.length ? dataUser.creditPayrollRelationships.map(x => ({ cprId: x?.cprId, rId: x?.rId, cprName: x?.cprName, cprPercentage: Number(x?.cprPercentage || 0) })) : [{ rId: undefined, cprName: undefined, cprPercentage: undefined }] })
        setErrorForm({ creditPayrollRelationships: dataUser?.creditPayrollRelationships?.length ? dataUser.creditPayrollRelationships.map(x => ({ rId: !x?.rId, cprName: !x?.cprName, cprPercentage: !x?.cprPercentage })) : [{ rId: true, cprName: true, cprPercentage: true }] })
    }, [dataUser])

    const handleSubmit = async () => {
        setErrors(errorForm)
        let error = false

        // valida que la información de los parentesco
        for (let i = 0; i < errorForm?.creditPayrollRelationships?.length; i++) {
            const { rId, cprName, cprPercentage } = errorForm?.creditPayrollRelationships[i]
            if (rId || cprName || cprPercentage) error = true
        }

        /** verifica si los campos están rellenos */
        if (error) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        values.creditPayrollRelationships.forEach(async (x, i) => {
            const errorMutation = await creditPayrollRelationMutation({
                variables: { input: { cprId: x?.cprId, rId: x?.rId, cprName: x?.cprName, cprPercentage: Number(x?.cprPercentage), cpcId: dataUser?.cpcId } }, update(cache, { data: { setOrUpdateCreditPayrollRelationship } }) {
                    updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollRelationship })
                }
            }).then(() => {
                if (i === values.creditPayrollRelationships.length - 1) {
                    refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
                    setErrorForm({})
                    handleNext(2)
                }
            }).catch(e => ({ error: e }))

            // verifica si hay error
            if (errorMutation?.error) return isLoggedVar({ ...isLoggedVar(), message: errorMutation?.error?.message })
        })
    }

    // agrega otro parentesco
    const handlePlusRelation = () => {
        setValues({ creditPayrollRelationships: [...values.creditPayrollRelationships, { rId: undefined, cprName: undefined, cprPercentage: undefined }] })
        setErrorForm({ creditPayrollRelationships: [...errorForm.creditPayrollRelationships, { rId: true, cprName: true, cprPercentage: true }] })
        setErrors({ creditPayrollRelationships: [...errors.creditPayrollRelationships, { rId: true, cprName: true, cprPercentage: true }] })
    }
    return (
        <RelationDataRegisterV
            data={{ values, errors, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e?.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handlePlusRelation={handlePlusRelation}
        />
    )
}

RelationDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    refScroll: PropTypes.object
}