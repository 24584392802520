import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { wp, hp } from '../../validation'

export const Container = styled.View`
    flex: 1;
    width: ${wp(100)}px;
    height: ${hp(100)}px;
    align-items: center;
    justify-content: center;
    background-color: ${theme.variantPrimaryC};
`
export const ButtonContainer = styled.View`
    width: ${wp(90)}px;
    margin: ${hp(2)}px 0;
    align-items: center;
`
export const Title = styled.Text`
    margin: ${hp(2)}px 0;
    padding: 0 ${wp(10)}px;
    text-align: center;
    font-family: PFontBold;
    font-size: ${wp(6)}px;
    color: ${({ color }) => color || theme.backgroundC};
`