import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CreditsLineV } from '../../components/CreditsLine'
import { useLazyQuery } from '@apollo/client'
import { ALL_GROUP_LINE } from './queries'

export const CreditsLineC = ({ navigation, route }) => {
    const { credits } = route.params;
    const [listGroupLine, setListGroupLine] = useState([]);

    const [
        getGroupLine,
        {
            data: dataAllGroupLine,
            loading: loadingAllGroupLine,
            called: calledAllGroupLine,
        },
    ] = useLazyQuery(ALL_GROUP_LINE, {
        errorPolicy: 'ignore',
        fetchPolicy: 'cache-and-network',
    });
    useEffect(() => {
        // crea un evento para consultar de los créditos
        const unsubscribe = navigation.addListener('focus', () => (
            getGroupLine({
                variables: { state: [1] },
            })
        )
        );

        // elimina el evento
        return unsubscribe;
    }, [navigation, getGroupLine]);

    useEffect(() => {
        if (calledAllGroupLine) {
            setListGroupLine(
                dataAllGroupLine?.getGroupCreditLine?.map(x => {
                    return {
                        gclId: x?.gclId,
                        gclName: x?.gclName,
                        gclState: x?.gclState,
                    }
                })
            )
        }
    }, [dataAllGroupLine, loadingAllGroupLine, calledAllGroupLine])

    const onCreditLine = gclId => {
        navigation.navigate('CreditsLine', { gclId, credits });
    }
    return <CreditsLineV onCreditLine={onCreditLine} listGroupLine={listGroupLine}/>
}
CreditsLineC.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
}