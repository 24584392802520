
import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { hp, wp } from '../../validation'

export const Container = styled.View`
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: ${hp(1.2)}px 0;
`
export const Title = styled.Text`
    font-size: ${wp(4.5)}px;
    font-family: PFontBold;
    text-align: center;
    color: ${theme.primaryC};
`
export const Box = styled.View`
    position: relative;
    justify-content: center;
    align-items: center;
    border: ${wp(1.5)}px solid ${theme.onVariantSurfC};
    width: ${wp(60)}px;
    height: ${hp(20)}px;
    border-radius: ${wp(5)}px;
    margin-top: ${hp(.8)}px;
`
export const Image = styled.Image`
    height: 100%;
    width: 100%;
    border-radius: ${wp(3)}px;
`
export const BoxButton = styled.TouchableOpacity`
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background-color: ${theme.primaryC};
`