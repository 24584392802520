import { gql } from '@apollo/client'

export const UPDATE_PAYROLL_COMPANY = gql`
mutation setOrUpdatePayrollCompany($pcId: ID, $input: ISetOrUpdatePayrollCompany!) {
    setOrUpdatePayrollCompany(pcId: $pcId, input: $input) {
        pcId
        payrollDocument {
            pdId
            pdPerDoc
            pdFroDoc
            pdReaDoc
            pdSerDoc
            pdPayRemDoc
            pdBanDoc
            pdConDoc
            pdComActDoc
            pdBudRecDoc
            pdBudCerDoc
            pdRut
            pdComChamber
            pdInstitutionPic
            pdWorkCertificate
            pdBankStatement
            pdFinancialStatements
            pdIncomeTaxReturn
            pdBoardDirectors
            pdTradeBalance
            pdRealEstateRegistration
            pdVehicleOwnershipCard
            pdCommercialReference
            pdBankReference
            pdIncomeWithholdings
            pdCadastreAndValuation
            pdCommercialAppraisal
            pdState
        }
    }
}
`