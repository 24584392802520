import React, { useEffect, useState } from 'react';
import { PaymentSummaryV } from '../../components/PaymentSummary';
import PropTypes from 'prop-types'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_CREDIT_BY_ID, CREATE_CREDIT_ELECTRONIC_PAYMENT, GET_ONE_CREDIT_ELECTRONIC_PAYMENT, CALCULATE_FEE } from './queries';
import moment from 'moment';
import { diffInDays } from '../../validation';
import { brandUrls } from '../../lib/vars';

export const PaymentSummaryC = ({ navigation, route }) => {
    const { cId, cState, paymentProvider } = route.params;
    const [credit, setCredit] = useState({});
    const [dataValues, setDataValues] = useState({ paymentMethod: 'fee' });
    const [creditWompi, setCreditWompi] = useState({});
    const constructorName = values => values?.filter(x => !!x)?.join(' ');

    const [getCreditPayment, { data: creditData, called: creditCalled }] = useLazyQuery(GET_CREDIT_BY_ID,
        { variables: { cId, state: [cState] }, fetchPolicy: 'cache-and-network' }
    )
    const [getCreditById, { data: creditById }] = useLazyQuery(GET_ONE_CREDIT_ELECTRONIC_PAYMENT,
        { fetchPolicy: 'cache-and-network' }
    )

    const [createCreditElectronic, { data: dataMutation }] = useMutation(CREATE_CREDIT_ELECTRONIC_PAYMENT)
    const { data: dataCalculateFee, loading: loadCalculateFee } = useQuery(CALCULATE_FEE, { variables: { cId, feeQuantity: 1 }, fetchPolicy: 'no-cache' })

    //MESES VENCIDOS
    const currentDate = moment();
    const currentDays = moment().format('DD');
    const meses = route?.params?.creditFees?.map(x => x?.cfMonth)
    const cfMonthPay = meses?.length ? Math.max(...meses) : 0
    const cfMonth = cfMonthPay < 0 ? 0 : cfMonthPay
    const firstPay = moment(route?.params?.cFirFee)

    const lastPayment = route?.params?.creditFees?.map(x => x?.cfDatCre)
    const maxDate = lastPayment?.length ? lastPayment?.reduce((a, b) => a > b ? a : b) : firstPay;
    //Días transcurridos desde la primera cuota
    const days = diffInDays(currentDate, firstPay) + 30
    const validate = currentDate < firstPay ? 0 : days
    const daysLastPayment = diffInDays(currentDate, maxDate)
    // Cuotas pagadas a días
    const pays = cfMonth * 30
    // Restamos los días pagados
    const daysExpired = (validate === 0 ? currentDays - pays : validate - pays) + 1
    // Dias transcurridos en meses
    const oveFeeDays = Math.ceil(daysExpired / 30)
    //Tasa
    const rate = daysExpired < 0 ? daysLastPayment / 30 : daysExpired / 30

    //Tasa
    const interest = Math.round(route?.params?.cBalance * ((route?.params?.cRatVal / 100) * rate))

    //ATRIBUTOS
    let totalAttributes = 0
    route?.params?.creditAttributeFees?.forEach(creditAttributeFee => {
        const { cafAmount } = creditAttributeFee
        const totalAttribute = dataValues?.paymentMethod === 'balance' ? (cafAmount * 0) : (cafAmount * oveFeeDays)
        totalAttributes += totalAttribute
    });
    //SALDO FINAL0
    const totalBalance = Math.round(route?.params?.cBalance + interest + totalAttributes)
    const total = totalBalance + dataCalculateFee?.CalculateFee?.totalArrearsCredit + dataCalculateFee?.CalculateFee?.totalCollectionCostFee

    useEffect(() => {
        if (cId) {
            getCreditPayment({ variables: { cId, state: [cState] } })
        }
    }, [cId]);

    const idMutation = dataMutation?.createCreditElectronicPayment?.idCEP;

    useEffect(() => {
        if (idMutation && cId) {
            getCreditById({ variables: { idCep: idMutation } })
        }
    }, [idMutation, cId]);

    const getTypeAddress = value => {
        if (value === '1') {
            return 'CALLE'
        }
        if (value === '2') {
            return 'CARRERA'
        }
        if (value === '3') {
            return 'KILÓMETRO'
        }
        if (value === '4') {
            return 'VIA'
        }
        if (value === '5') {
            return 'TRANSVERSAL'
        }
        if (value === '6') {
            return 'DIAGONAL'
        }
        if (value === '7') {
            return 'AVENIDA'
        }
        if (value === '8') {
            return 'VEREDA'
        }
        if (value === '9') {
            return 'SECTOR'
        }
        if (value === '10') {
            return 'MANZANA'
        }
        if (value === '11') {
            return 'CHALET'
        }
        if (value === '12') {
            return 'URBANIZACIÓN'
        }
        if (value === '13') {
            return 'CORREGIMIENTO'
        }
        if (value === '14') {
            return 'BARRIO'
        }
        if (value === '15') {
            return 'OTROS'
        }
        if (value === '16') {
            return 'ETAPA'
        }
        return 'CONJUNTO'
    }

    const handleChangeSelect = event => {
        const { name, value } = event
        setDataValues({ ...dataValues, [name]: value })
    }
    const paymentMethod = (dataValues?.paymentMethod === 'fee' && cState === 6) && 'fee' || (dataValues?.paymentMethod === 'total' && cState === 6) && 'total' || (cState === 2 || cState === 3 || cState === 4 || cState === 5) && 'origin'

    const creditCreate = () => {
        const newCredit = createCreditElectronic({
            variables: {
                input: {
                    ticketId: '',
                    cId,
                    paymentMethod
                }
            }
        })
        return newCredit;
    }

    const cancel = async e => {
        e.preventDefault();
        navigation.goBack()
        setDataValues({ paymentMethod: 'fee' })
    }

    useEffect(() => {
        const infoCredit = creditData?.getCreditById;
        const staffInfo = creditData?.getCreditById?.payrollCompany?.payrollStaff;
        if (creditData?.getCreditById && creditCalled) {
            creditCreate();
            const dataCredit = {
                name_billing: constructorName([staffInfo?.psFirNam, staffInfo?.psSecNam, staffInfo?.psFirLas, staffInfo?.psSecLas])?.toUpperCase(),
                type_doc_billing: infoCredit?.payrollCompany?.typeIdentity?.tiPrefix,
                number_doc_billing: infoCredit?.payrollCompany?.pcIdeNum,
                mobilephone_billing: staffInfo?.psPhoMob,
                email: staffInfo?.psEmail,
                name: infoCredit?.cName?.toUpperCase(),
                // description: constructorName([descriptionMethod(), infoCredit?.cName?.toUpperCase(), infoCredit?.cConsecutive]),
                amount: 0,
                address_billing: constructorName([
                    getTypeAddress(infoCredit?.payrollCompany?.payrollResidence?.taId),
                    infoCredit?.payrollCompany?.payrollResidence?.prNumDir,
                    '#',
                    infoCredit?.payrollCompany?.payrollResidence?.prNum,
                    '-',
                    infoCredit?.payrollCompany?.payrollResidence?.prNum,
                    infoCredit?.payrollCompany?.payrollResidence?.prInformation,
                ]),
            }
            const dataCreditWompi = {
                currency: 'COP',
                amountInCents: 0,
                redirectUrl: `${brandUrls()?.admin}/payment/response/wompi/`,
                customerData: { // Opcional
                    email: staffInfo?.psEmail,
                    fullName: constructorName([staffInfo?.psFirNam, staffInfo?.psSecNam, staffInfo?.psFirLas, staffInfo?.psSecLas])?.toUpperCase(),
                    phoneNumber: staffInfo?.psPhoMob || '55555555',
                    phoneNumberPrefix: '+57',
                    legalId: infoCredit?.payrollCompany?.pcIdeNum,
                    legalIdType: infoCredit?.payrollCompany?.typeIdentity?.tiPrefix
                }
            }
            if (dataValues?.paymentMethod === 'fee' && cState === 6) {
                setCredit({ ...dataCredit, amount: dataCalculateFee?.CalculateFee?.totalFee, paymentMethod: 'fee', description: constructorName(['PAGO DE CUOTA', infoCredit?.cName?.toUpperCase(), infoCredit?.cConsecutive]) });
                setCreditWompi({ ...dataCreditWompi, amountInCents: (dataCalculateFee?.CalculateFee?.totalFee || 0) * 100, description: constructorName(['PAGO DE CUOTA', infoCredit?.cName?.toUpperCase(), infoCredit?.cConsecutive]) })
            } else if (dataValues?.paymentMethod === 'total' && cState === 6) {
                setCredit({ ...dataCredit, amount: total, paymentMethod: 'total', description: constructorName(['PAGO TOTAL', infoCredit?.cName?.toUpperCase(), infoCredit?.cConsecutive]), })
                setCreditWompi({ ...dataCreditWompi, amountInCents: (total || 0) * 100, description: constructorName(['PAGO TOTAL', infoCredit?.cName?.toUpperCase(), infoCredit?.cConsecutive]) })
            } else if (cState === 2 || cState === 3 || cState === 4 || cState === 5) {
                setCredit({ ...dataCredit, amount: infoCredit?.creditOrigins[0]?.coAmount, paymentMethod: 'origin', description: constructorName(['PAGO DE ATRIBUTO DE ORIGEN', infoCredit?.cName?.toUpperCase(), infoCredit?.cConsecutive]) })
                setCreditWompi({ ...dataCreditWompi, amountInCents: (infoCredit?.creditOrigins[0]?.coAmount || 0) * 100, description: constructorName(['PAGO DE ATRIBUTO DE ORIGEN', infoCredit?.cName?.toUpperCase(), infoCredit?.cConsecutive]) })
            }
        }
    }, [creditData?.getCreditById, creditCalled, dataValues?.paymentMethod, dataCalculateFee])

    const openCheckout = async e => {
        e.preventDefault();

        if (paymentProvider[0]?.name?.toLowerCase() === 'epayco') {
            const handler = await window.ePayco.checkout.configure({
                key: paymentProvider[0]?.key,
                test: !!(brandUrls()?.epaycoTest || false),
            });
            const data = {
                ...credit,
                invoice: creditById?.creditElectronicPaymentById?.invoice,
                currency: 'cop',
                tax_base: '0',
                tax: '0',
                country: 'co',
                lang: 'es',
                //Onpage="false" - Standard="true"
                external: 'false',
                //Atributos opcionales
                confirmation: `${brandUrls()?.admin}/payment/response/epayco/`,
                response: `${brandUrls()?.admin}/payment/response/epayco/`,
                methodsDisable: ['SP', 'CASH', 'DP'],
            };

            if (creditById?.creditElectronicPaymentById?.invoice) await handler.open(data);
        } else {
            const checkout = new window.WidgetCheckout({
                ...creditWompi,
                reference: creditById?.creditElectronicPaymentById?.invoice,
                publicKey: paymentProvider[0]?.key,
            })
            if (creditById?.creditElectronicPaymentById?.invoice) {
                await checkout.open(async result => {
                    const transaction = await result.transaction
                    if (transaction.redirectUrl) {
                        // redirect with window location
                        window.location.href = `${transaction.redirectUrl}?id=${transaction.id}`
                    }
                })
            }
        }
    }

    return (
        <PaymentSummaryV data={{ ...navigation, dataInvoice: creditById?.creditElectronicPaymentById?.invoice, total, totalBalance, loadCalculateFee, calculateFee: dataCalculateFee?.CalculateFee, }} route={route} openCheckout={openCheckout} handleChangeSelect={handleChangeSelect} cancel={cancel} dataValues={dataValues} />
    )
}

PaymentSummaryC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}