import { gql } from '@apollo/client'

export const EDIT_CREDIT_PAYROLL_COMPANY = gql`
    mutation setOrUpdateCreditPayrollCompany($cpcId: ID, $input: ISetOrUpdateCreditPayrollCompany!){
        setOrUpdateCreditPayrollCompany(cpcId: $cpcId, input: $input){
            cpcId
        }
    }
`
export const EDIT_CREDIT_PAYROLL_STAFF = gql`
    mutation setOrUpdateCreditPayrollStaff($cpcId: ID, $cpsId: ID, $input: ISetOrUpdateCreditPayrollStaff!){
        setOrUpdateCreditPayrollStaff(cpcId: $cpcId, cpsId: $cpsId, input: $input){
            cpsId
            cpcId
        }
    }
`
export const EDIT_CREDIT_PAYROLL_REFERENCE = gql`
    mutation setOrUpdateCreditPayrollReference($cpcId: ID, $cprId: ID, $input: ISetOrUpdateCreditPayrollReference!){
        setOrUpdateCreditPayrollReference(cpcId: $cpcId, cprId: $cprId, input: $input){
            cprId
            cpcId
        }
    }
`
export const EDIT_CREDIT_PAYROLL_RESIDENCE = gql`
    mutation setOrUpdateCreditPayrollResidence($cpcId: ID, $cprId: ID, $input: ISetOrUpdateCreditPayrollResidence!){
        setOrUpdateCreditPayrollResidence(cpcId: $cpcId, cprId: $cprId, input: $input){
            cprId
            cpcId
        }
    }
`
export const EDIT_CREDIT_PAYROLL_WORK = gql`
    mutation setOrUpdateCreditPayrollWork($cpcId: ID, $cpwId: ID, $input: ISetOrUpdateCreditPayrollWork!){
        setOrUpdateCreditPayrollWork(cpcId: $cpcId, cpwId: $cpwId, input: $input){
            cpwId
            cpcId
        }
    }
`
export const EDIT_CREDIT_PAYROLL_OPS = gql`
    mutation setOrUpdateCreditPayrollOPS($cpcId: ID, $cpoId: ID, $input: ISetOrUpdateCreditPayrollOPS!){
        setOrUpdateCreditPayrollOPS(cpcId: $cpcId, cpoId: $cpoId, input: $input){
            cpoId
            cpcId
        }
    }
`
export const EDIT_CREDIT_PAYROLL_RELATION = gql`
    mutation setOrUpdateCreditPayrollRelationship($cpcId: ID, $cprId: ID, $input: ISetOrUpdateCreditPayrollRelationship!){
        setOrUpdateCreditPayrollRelationship(cpcId: $cpcId, cprId: $cprId, input: $input){
            cprId
            cpcId
        }
    }
`
export const EDIT_CREDIT_PAYROLL_DOCUMENT = gql`
    mutation setOrUpdateCreditPayrollDocument($cpcId: ID, $cpdId: ID, $input: ISetOrUpdateCreditPayrollDocument!){
        setOrUpdateCreditPayrollDocument(cpcId: $cpcId, cpdId: $cpdId, input: $input){
            cpdId
            cpcId
        }
    }
`
export const EDIT_CREDIT_PAYROLL_CODEBTORS = gql`
    mutation setOrUpdateCreditPayrollCoDebtor($cpcId: ID, $cpcdId: ID, $input: ISetOrUpdateCreditPayrollCoDebtor!){
        setOrUpdateCreditPayrollCoDebtor(cpcId: $cpcId, cpcdId: $cpcdId, input: $input){
            cpcdId
            cpcId
        }
    }
`

export const EDIT_CREDIT_PAYROLL_LIFE_INSURANCE = gql`
    mutation SetOrUpdateCreditPayrollLifeInsurance($input: ISetOrUpdateCreditPayrollLifeInsurance!) {
        setOrUpdateCreditPayrollLifeInsurance(input: $input) {
            cpliId
            cpcId
        }
    }
`
export const EDIT_CREDIT_ACTIONS = gql`
    mutation CreateNotificationClient($input: ICreditAction!) {
    createNotificationClient(input: $input) {
        asId
        cId
        caSeen
        uId
    }
}
`
export const DOCUMENT_NEW = gql`
 mutation SetUpdatePayrollOtherDocuments($input: [ISetCreditPayrollCompanyOtherDocuments]!) {
  SetUpdatePayrollOtherDocuments(input: $input) {
    cpcId
    cpcodFileName
  }
}
`

export const EDIT_CREDIT_PAYROLL_OTHER_INFORMATION = gql`
    mutation UpdateCreditPayrollOtherInformation($input: IUpdateCreditPayrollOtherInformation!, $cpoiId: ID) {
        updateCreditPayrollOtherInformation(input: $input, cpoiId: $cpoiId) {
            cpoiId
            cpcId
        }
    }
`

export const EDIT_CREDIT_PAYROLL_ECONOMY_ACTIVITIES = gql`
    mutation UpdateCreditPayrollEconomyActivities($input: IUpdateCreditPayrollEconomyActivities!, $cpcId: ID) {
      updateCreditPayrollEconomyActivities(input: $input, cpcId: $cpcId) {
        cpeaId
        cpcId
      }
    }
`

export const PREGNANCY_TIME = gql`
  query PregnancyTimes {
    pregnancyTimes {
      ptName
      ptId
    }
  }
`;

export const CREDIT_CLIENT_MESSAGE = gql`
  mutation CreateCreditClientMessage($input: ICreditClientMessage!) {
  createCreditClientMessage(input: $input) {
    cId
    ccmId
  }
}
`