import styled, { css } from 'styled-components/native'
import { hp, wp } from '../../validation'
import theme from '../../../ignore/Constants'

export const Container = styled.View`
    flex: 1;
    position: relative;
    background-color: ${theme.backgroundP};
`
export const BoxBackground = styled.View`
    background-color: ${theme.variantPrimaryC};
    position: absolute;
    top: 0;
    height: ${hp(50)}px;
    width: ${wp(100)}px;
    border-bottom-left-radius: ${wp(7.5)}px;
    border-bottom-right-radius: ${wp(7.5)}px;
    z-index: -1;
`
export const Box = styled.View`
    align-items: center;
    padding: 0 ${wp(5)}px;
`
export const BoxTitle = styled.View`
    align-items: center;
    padding: ${hp(3)}px 0;
`
export const InfoPlan = styled.View`
    border-radius: ${wp(4)}px;
    background-color: ${theme.backgroundC};
    width: ${wp(90)}px;
    margin-bottom: ${hp(5)}px;
    padding: ${wp(4)}px 0;
`
export const Label = styled.Text`
    font-size: ${({ size }) => size || wp(3)}px;
    color: ${({ color }) => color || theme.onSurfaceC};
    font-family: ${({ family }) => family || 'PFontRegular'};
    ${({ align }) => align && css`text-align: ${align};`}
    ${({ lineHeight }) => lineHeight && css`line-height: ${lineHeight}px;`}
`
export const Row = styled.View`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    display: flex;
`

/** Diseño de Botón de cartera */
export const ButtonCard = styled.TouchableOpacity`
    border-radius: ${wp(2)}px;
    border: 1px solid ${theme.grayLight};
    background-color: ${theme.backgroundC};
    padding: ${wp(2)}px ${wp(4)}px;
    margin: ${wp(2)}px 0px ${wp(6)}px;
    height: 55px;
    width: ${wp(95)}px;
    position: relative;
`
export const LineCard = styled.View`
    position: absolute;
    left: -1px;
    height: 42px;
    width: 3px;
    top: 17%;
    background-color: ${({ bgColor }) => bgColor};
`
export const BoxInfoCard = styled.TouchableOpacity`
    background-color: ${({ bgColor }) => bgColor};
    padding: ${wp(1.5)}px ${wp(2.5)}px;
    border-radius: ${wp(3)}px;
`

/** Información Detallada */
export const HeadPlan = styled.View`
    background-color: ${theme.variantPrimaryC}80;
    height: 40px;
    width: ${wp(78)}px;
    align-self: center;
    border-radius: ${wp(3)}px;
    padding: ${wp(1)}px;
`
export const BodyPlan = styled.View`
    align-self: center;
    padding: ${wp(5)}px ${wp(1)}px ${wp(3)}px ${wp(1)}px;
    width: ${wp(80)}px;
`
export const LabelPlan = styled.Text`
    font-size: ${wp(3.5)}px;
    color: ${theme.onSurfaceC};
    font-family: ${({ family }) => family || 'PFontRegular'};
    line-height: 22px;
    ${({ align }) => align && css`text-align: ${align};`}
    ${({ width }) => width && css`width: ${width}px;`}
`
export const ButtonCredit = styled.TouchableOpacity`
    width: ${wp(24)}px;
    background-color: ${({ active }) => active ? theme.variantPrimaryC : 'transparent'};
    height: 32px;
    border-radius: ${wp(3)}px;
    justify-content: center;
    align-items: center;
`
export const BoxList = styled.View`
    padding: 8px;
    width: 96px;
    ${({ bgColor }) => bgColor && css`background-color: ${bgColor};`}
    ${({ borderBottom }) => borderBottom && css`
        border-bottom-width: 1px;
        border-bottom-color: ${theme.grayLight};
    `}
`
export const BoxHeadList = styled.View`
    padding: 10px;
    width: 96px;
    background-color: ${({ bgColor }) => bgColor || theme.grayLight};
`

/** Diseño de Modales */
export const ContainerCard = styled.View`
    height: 400px;
    background-color: ${theme.backgroundP};
    margin: auto ${wp(5)}px;
    border-radius: ${wp(5)}px;
    padding-bottom: ${wp(2)}px;
`
export const Card = styled.View`
    margin: ${wp(1.5)}px;
    padding: ${wp(1)}px ${wp(2.5)}px;
`
export const ContainerFee = styled.View`
    height: 200px;
    background-color: ${theme.backgroundP};
    margin: auto ${wp(5)}px;
    border-radius: ${wp(5)}px;
    padding-bottom: ${wp(2)}px;
`
export const BoxHead = styled.View`
    flex-direction: row;
    justify-content: space-between;
    background-color: ${theme.primaryC};
    padding: ${wp(4)}px ${wp(2)}px;
    border-top-right-radius: ${wp(5)}px;
    border-top-left-radius: ${wp(5)}px;
`
export const ButtonIcon = styled.TouchableOpacity`
    justify-content: flex-end;
    align-items: flex-end;
`
export const BoxInputs = styled.View`
    width: ${wp(70)}px;
`
export const BoxInputFee = styled.View`
    margin: ${wp(1)}px ${wp(4)}px;
    justify-content: center;
    align-items: center;
`
export const BorderBottom = styled.View`
    height: 1px;
    background-color: ${theme.primaryC};
    width: ${wp(70)}px;
    justify-content: center;
    align-items: center;
`

export const styles = {
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.15,
        shadowRadius: 8.5,
        elevation: 7,
    }
}