import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components/native';
import { CreditTypes } from '../../components/CreditTypes';
import { useLazyQuery } from '@apollo/client';
import { ALL_INFO_CLIENT, ALL_LINE_BY_ID } from './queries';
import { isLoggedVar } from '../../apollo/cache';

export const CreditTypesC = ({ navigation, route }) => {
    // States and Variables
    const [list, setList] = useState([]);
    const theme = useTheme();
    const { credits } = route.params;
    const { gclId } = route.params;
    // Queries
    const [
        getInfoClient,
        {
            data: dataAllInfoClient,
            loading: loadingAllInfoClient,
            called: calledAllInfoClient,
        },
    ] = useLazyQuery(ALL_INFO_CLIENT, {
        errorPolicy: 'ignore',
        fetchPolicy: 'cache-and-network',
    });
    const [
        getInfoGroupLine,
        {
            data: dataGroupLine,
        },
    ] = useLazyQuery(ALL_LINE_BY_ID, {
        errorPolicy: 'ignore',
        fetchPolicy: 'cache-and-network',
    });
    // Activa un evento para buscar de los créditos
    useEffect(() => {
        // crea un evento para consultar de los créditos
        const unsubscribe = navigation.addListener('focus', () => getInfoClient({
            variables: { state: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13] },
        })
        );

        // elimina el evento
        return unsubscribe;
    }, [navigation, getInfoClient]);
    useEffect(() => {
        getInfoGroupLine({
            variables: { gclId, state: [1] },
        })
    }, [gclId, getInfoGroupLine]);

    useEffect

    // pendiente del resultado de la búsqueda de la información del usuario
    useEffect(() => {
        if (calledAllInfoClient) {
            const resPayrollCompany = dataAllInfoClient?.getPayrollCompanyById;

            const dataGroupCredits = dataAllInfoClient?.groupCredits;

            let resPayrollCompanyCategory = {};

            if (resPayrollCompany?.payrollCompanyCategory?.pccState === 1) {
                resPayrollCompanyCategory = {
                    ...resPayrollCompany?.payrollCompanyCategory,
                };
            }

            const resCompanyCreditTypes = resPayrollCompany?.companyCreditTypes;

            // anterior
            if (resCompanyCreditTypes) {
                // busca las lineas de créditos activas
                const validCreditTypes =
                    resCompanyCreditTypes.filter(
                        x => x?.creditType?.ctId && x?.creditType?.ctGroup !== 1
                    ) || [];

                const categoriesCreditTypes =
                resPayrollCompanyCategory?.companyCategory?.ccState === 1
                    ? resPayrollCompanyCategory?.companyCategory
                        ?.companyCategoriesCreditTypes
                    : [];

                // busca si uno de las lineas de créditos son multiples y si el usuario ya lo solicito una ves
                const newCreditTypes = validCreditTypes.filter(creditType => {

                    // verifica si tiene lineas de créditos personalizadas
                    if (categoriesCreditTypes?.length) {
                        // busca si existe la linea de crédito
                        const categoryCreditType = categoriesCreditTypes.find(
                            y => y?.ctId === creditType?.creditType?.ctId
                        );

                        if (categoryCreditType) {
                            return true;
                        }
                        return false;
                    }
                    return true;

                });

                // manda una alerta por si no hay ninguna linea de crédito disponible
                if (newCreditTypes?.length) {
                    // verifica si existen grupos
                    for (let i = 0; i < dataGroupCredits?.length; i++) {
                        const { groupCreditTypes, ...rest } =
                            dataGroupCredits[i];
                        let validationGroup = true;

                        // recorre las líneas de crédito del grupo
                        for (let ii = 0; ii < groupCreditTypes?.length; ii++) {
                            const { ctId } = groupCreditTypes[ii];
                            if (validationGroup) {
                                validationGroup = !!resCompanyCreditTypes.find(
                                    x => x?.ctId === ctId
                                );
                            }
                        }

                        // agrega el grupo para ser usado
                        if (validationGroup) {
                            newCreditTypes.push({
                                creditType: {
                                    ctId: rest?.gcId,
                                    ctName: rest?.gcName,
                                    ...rest,
                                    groupCreditTypes,
                                },
                            });
                        }
                    }
                } else {
                    isLoggedVar({
                        ...isLoggedVar(),
                        message:
                            'No se ha encontrado ninguna linea de crédito disponible.',
                    });
                }
                let arrayNew = []
                for (let index = 0; index < newCreditTypes?.length; index++) {
                    for (let item = 0; item < dataGroupLine?.getGroupCreditLineDetails?.length; item++) {
                        if (newCreditTypes[index]?.ctId === dataGroupLine?.getGroupCreditLineDetails[item]?.ctId) {
                            arrayNew = [...arrayNew, newCreditTypes[index]]
                        }

                    }
                }

                // actualiza el estado
                setList(
                    arrayNew.map(creditType => {
                        let { ctDescription } = creditType.creditType;
                        const { valueCredit, ctMaxCap, month, rate } =
                            creditType.creditType;
                        const { pwSalary } = resPayrollCompany.payrollWork;
                        const salaryMin = pwSalary * valueCredit?.vcMinVal;
                        const salaryMax = pwSalary * valueCredit?.vcMaxVal;

                        // Actualiza la descripción con las variables
                        ctDescription = `${ctDescription || ''}`
                            .replace(
                                '{amountMinimum}',
                                salaryMin > ctMaxCap ? ctMaxCap : salaryMin
                            )
                            .replace(
                                '{amountMaximum}',
                                salaryMax > ctMaxCap ? ctMaxCap : salaryMax
                            )
                            .replace(
                                '{monthMinimum}',
                                valueCredit?.month?.mValue
                            )
                            .replace('{monthMaximum}', month?.mValue)
                            .replace('{valueMaximum}', ctMaxCap)
                            .replace('{rate}', `${rate?.rPor}%`);
                        return { ...creditType.creditType, ctDescription };
                    })
                );
            }

            // verifica si hay error
            if (!resCompanyCreditTypes?.length && resPayrollCompany?.pcId) {
                setList([]);
                return isLoggedVar({
                    ...isLoggedVar(),
                    message:
                        'No se ha encontrado ninguna linea de crédito disponible.',
                });
            }
        }
    }, [dataAllInfoClient, loadingAllInfoClient, calledAllInfoClient, dataGroupLine]);

    const applyCredit = item => {
        const resCredits = dataAllInfoClient?.credits;

        const isCreditTypeMultiple = item?.ctMultiple === 1;

        const notHaveReTankingOrRefinancing =
            item?.ctReTanking !== 1 || item?.ctOptPay !== 1;

        const creditInPhase = resCredits?.find(
            credit => credit?.cName === item?.ctName &&
                credit?.cState !== 1 &&
                credit?.cState !== 0 &&
                credit?.cState !== 6
        );

        if (
            isCreditTypeMultiple &&
            notHaveReTankingOrRefinancing &&
            creditInPhase
        ) {
            isLoggedVar({
                ...isLoggedVar(),
                message:
                    'Actualmente, tiene una solicitud de crédito en proceso. Por favor, espere a recibir una respuesta antes de solicitar un nuevo crédito.',
            });
            return;
        }
        navigation.navigate('CreditsRequest', item);
    };
    const onCreditLine = () => {
        navigation.navigate('Credits', { credits });
    }
    return (
        <CreditTypes
            data={{
                list,
                theme,
                applyCredit,
                navigation,
                loading: !list?.length && loadingAllInfoClient,
                credits,
                onCreditLine,
            }}
        />
    );
};

CreditTypesC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
};