import { gql } from '@apollo/client'

export const RESEND_USER_ACTIVE = gql`
    mutation reSendUserActive($uId: ID) {
        reSendUserActive(uId: $uId) {
            uId
        }
    }
`
export const ADO_PARAMETERS = gql`
query AdoParameters($state: [Int], $onRegister: [Int]) {
  adoParameters(state: $state, onRegister: $onRegister) {
    idAP
    projectName
    apiKey
    secretKey
    urlTest
    urlProd
    isProd
    brand
    onRegister
    state
  }
}
`