import styled, { css } from 'styled-components/native'
import { mq } from '../../validation'
import theme from '../../../ignore/Constants'

export const Container = styled.View`
    max-width: 900px;
    width: 96%;
    margin-bottom: ${mq(30)}px;
    align-self: center;
`
export const BoxLogo = styled.View`
    align-self: center;
    width: 40%;
    height: ${mq(150)}px;
    margin-top: 20%;
    margin-bottom: ${mq(28)}px;
`
/**
 * -- Buttons Styles
 *
 */
export const BtnContainers = styled.View`
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    padding: ${mq(6)}px ${mq(10)}px;
    border-radius: ${mq(18)}px;
    background-color: ${theme.backgroundP};
    border: 1px solid #EDEDEF;
    margin-bottom: ${mq(28)}px;
    align-self: center;
`
export const BtnLogin = styled.TouchableOpacity`
    background-color: ${theme.backgroundP};
    flex-direction: row;
    background-color: ${({ active }) => active ? theme.backgroundC : 'transparent'};
    padding: ${mq(16)}px ${mq(4)}px;
    width: 49%;
    border-radius: ${mq(16)}px;
    justify-content: center;
`
export const Title = styled.Text`
    font-family: ${({ family }) => family || 'PFontBold'};
    font-size: ${({ size }) => size || mq(13)}px;
    color: ${({ color }) => color || theme.primaryC};
    ${({ margin }) => margin && css`margin: ${margin};`}
`
export const BoxCheck = styled.View`
    flex-direction: row;
    margin: ${mq(12)}px 0;
    align-items: center;
    justify-content: center;
`
export const SubTitle = styled.Text`
    color: ${theme.onSurfaceC};
    font-family: ${({ family }) => family || 'PFontLight'};
    font-size: ${({ size }) => size || mq(14)}px;
`