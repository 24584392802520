import { gql } from '@apollo/client';

export const CIRENIO_PARAMETERS = gql`
query GetCirenioParameter {
        getCirenioParameter {
            cpId
          cpName
          cpPublicKey
          cpXApiKey
          cpPrivateKey
          env
          cpState
        }
      }`

export const CIRENIO_HANDLE_EVENT = gql`
    mutation HandleCirenioEvent($input: HandleCirenioInput) {
  handleCirenioEvent(input: $input) {
    ctId
    ctExternalId
    cId
    uId
    ctIpAddress
    ctHash
    ctData
    consentsGranted
    consentsGrantedData
    banksListed
    banksListedData
    bankSelected
    bankSelectedData
    loginRequested
    loginRequestedData
    loginFailed
    loginFailedData
    loginSucceeded
    loginSucceededData
    missingBankForm
    missingBankFormData
    missingBankSent
    missingBankSentData
    flowDone
    flowDoneData
    ctState
  }
}
`

export const CREATE_CREDIT = gql`
    mutation createCredit($input: ICreateCredit!) {
        createCredit(input: $input) {
            cId
        }
    }
`

export const CREATE_FORM_TOKEN_LYRA = gql`
  mutation CreateLyraFormToken($input: ILyraFormToken) {
    createLyraFormToken(input: $input) {
      webService
      version
      applicationVersion
      status
      ticket
      answer {
        formToken
        _type
      }
      serverDate
      applicationProvider
      mode
      serverUrl
      _type
    }
  }
`

export const CREATE_PAYROLL_LYRA_TRANSACTION = gql`
    mutation CreatePayrollLyraTransaction($input: IPayrollLyraTransaction) {
    createPayrollLyraTransaction(input: $input) {
      pltId
      pcId
      pltData
    }
  }
`

export const LYRA_PARAMETERS = gql `
  query GetLyraParameters {
    getLyraParameters {
      lpId
      lpPublicKey
      lpApiKey
      lpVersion
      lpState
    }
  }
`