import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { wp } from '../../validation'

export const ViewTitle = styled.View`
    flex-direction: row;
    align-items: center;
    margin: ${wp('3%')}px;
    padding: ${wp('1%')}px;
    background-color: ${theme.backgroundC};
    border-radius: ${wp('3%')}px;
`
export const Box = styled.View`
    width: ${wp('70%')}px;
`
export const ViewInfo = styled.View`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: ${wp('18%')}px;
    height: ${wp('15%')}px;
`
export const ButtonInfo = styled.View`
    justify-content: flex-end;
    position: absolute;
    background-color: ${theme.backgroundC};
    border-radius: ${wp('100%')}px;
    z-index: 10;
    padding: ${wp('1.5%')}px;
`
export const Title = styled.Text`
    font-family: PFontBold;
    font-size: ${wp('4%')}px;
    color: ${theme.errorC};
`
export const Text = styled.Text`
    font-family: PFontRegular;
    font-size: ${wp('3%')}px;
    color: ${theme.onSurfaceC};
`