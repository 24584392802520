import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Keyboard } from 'react-native'
import { useLazyQuery, useMutation } from '@apollo/client'
import { setData, validationErrors, tokenNotification, clearData, extFile, putFileS3, base64ToArrayBufferAdo } from '../../validation'
import {
    ADO_PARAMETERS,
    CREATE_ADO_USER_ENROLLMENT,
    GET_BY_ID_USER_ENROLLMENT,
    LOGIN_USER,
    UPDATE_PAYROLL_DOCUMENTS
} from './queries'
import { LoginV } from '../../components/Login'
import { isLoggedVar } from '../../apollo/cache'
import Constants from 'expo-constants'
import axios from 'axios'
import moment from 'moment'
import * as Linking from 'expo-linking'
import { selectClientName } from '../../../ignore/utils'

export const LoginC = ({ navigation }) => {
    const [showFooter, setShowFooter] = useState(true)
    const [token, setToken] = useState(undefined)
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({ tiId: true, uIdeNum: true, uPass: true })
    const [loginMutation, { loading: loadLog }] = useMutation(LOGIN_USER)
    const params = new URL(window.location.href).searchParams.get('params');
    const response = new URL(window.location.href).searchParams.get('_Response');
    const parseParams = JSON.parse(response);
    const [newObjectParams, setNewObjectParams] = useState({});
    const [dataResult, setDataResult] = useState({});
    const [documentType, setDocumentType] = useState('');
    const [selectedDocuments, setSelectedDocuments] = useState();
    const [adoParameters, setAdoParameters] = useState({});

    // mutations
    const [
        createAdoUserEnrollment,
        { data: dataCreateAdoEnrollment, loading: loadingCreateAdoUser },
    ] = useMutation(CREATE_ADO_USER_ENROLLMENT);
    const [updatePayrollDocuments, { data: dataUpdatePayrollDocuments }] =
    useMutation(UPDATE_PAYROLL_DOCUMENTS);

    // useLazyQuery
    const [getAllAdoParameters, { data: dataAdoParameters, called: calledAdoParameters }] =
    useLazyQuery(ADO_PARAMETERS, {
        fetchPolicy: 'cache-and-network',
    });
    const [byIdAdoParameters, { data: dataByIdAdoParameters }] = useLazyQuery(
        GET_BY_ID_USER_ENROLLMENT,
        {
            fetchPolicy: 'cache-and-network',
        }
    );
    const client = selectClientName().toLowerCase()

    useEffect(() => {
        // solicita el permiso de notificación
        tokenNotification().then(x => setToken(x))
        clearData()
        isLoggedVar({ state: false, expired: false })
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => setShowFooter(false))
        const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => setShowFooter(true))

        return () => {
            setValues({})
            setErrorForm({})
            setToken(undefined)
            setShowFooter(false)
            keyboardDidShowListener.remove()
            keyboardDidHideListener.remove()
        }
    }, [])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ ...values, [e.name]: e.value })
        setErrorForm({ ...errorForm, [e.name]: e.error })
    }
    // suelta focus en los inputs
    const handleBlur = e => setErrorForm({ ...errorForm, [e.name]: e.error })

    // Inicio de Sesión
    const handleLogIn = async () => {
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        /** iniciar sesión */
        const { data, error } = await loginMutation({ variables: { input: { ...values, uIdeNum: `${values.uIdeNum}`, token } } }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.userPayrollLogin) {
            // verifica si al cuenta no ha sido activada
            if (data.userPayrollLogin.uState === 2) {
                navigation.navigate('Login')
                isLoggedVar({ ...isLoggedVar(), message: 'No ha activado su cuenta, revise su correo para activar la cuenta.' })
            }

            setValues({})
            setErrorForm({})
            values?.uIdeNum === 123123 && setData('consecutive', `${Constants.manifest.extra.consecutive}`)
            await setData('tokenAuth', data?.userPayrollLogin?.tokenAuth)
            isLoggedVar({ state: true, expired: false })
            navigation.navigate('Home', { uId: data?.userPayrollLogin?.uId })
        }
    }

    const returnApp = () => {
        Linking.openURL(window.location.origin)
    }

    // Obtengo las credenciales ADO
    useEffect(() => {
        if (parseParams) {
            getAllAdoParameters({ variables: { state: [1], onRegister: [1] } });
        }
    }, []);

    useEffect(() => {
        if (dataAdoParameters?.adoParameters && calledAdoParameters) {
            setAdoParameters(
                dataAdoParameters?.adoParameters?.find(
                    x => x?.brand?.toLowerCase() === client
                )
            );
        }
    }, [dataAdoParameters?.adoParameters, calledAdoParameters]);

    // Consulto api ADO en base a los parámetros de la transacción
    useEffect(() => {
        if (adoParameters?.idAP && calledAdoParameters) {
            // separa en pares por cada slash encontrado, devuelve un array
            const pares = params.split('/');
            const newObject = {};
            pares.forEach(element => {
                const [clave, valor] = element.split('=');
                newObject[clave] = valor;
            });
            setNewObjectParams(newObject);

            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://adocolombia-qa.ado-tech.com/${newObject?.name}/api/${newObject?.name}/Validation/${parseParams?.TransactionId}?returnImages=true`,
                headers: {
                    'Content-Type': 'application/json',
                    apiKey: adoParameters?.apiKey,
                },
                data: Number(parseParams?.TransactionId),
            };
            axios
                .request(config)
                .then(res => {
                    const images = res?.data?.Images?.filter(
                        x => x?.ImageTypeId === 1 || x?.ImageTypeId === 2 || x?.ImageTypeId === 3
                    );
                    setSelectedDocuments(
                        images?.map(x => {
                            return {
                                file: base64ToArrayBufferAdo(`data:image/jpg;base64,${x?.Image}`),
                                document: `${new Date().getTime()}${x?.Id}.${extFile('.jpg')}`,
                            };
                        })
                    );
                    const data = {
                        state: res?.data?.Extras?.IdState,
                        stateName: res?.data?.Extras?.StateName?.toUpperCase(),
                        documentNumber: res?.data?.IdNumber,
                        transactionId: res?.data?.TransactionId,
                        transactionTypeName: res?.data?.TransactionTypeName === 'Enroll' ? 'ENROLAMIENTO' : 'VERIFICACIÓN',
                        names: `${res?.data?.FirstName || ''} ${res?.data?.SecondName || ''}`,
                        surnames: `${res?.data?.FirstSurname || ''} ${res?.data?.SecondSurname || ''}`,
                        images,
                    };
                    setDataResult(data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [adoParameters, calledAdoParameters]);

    useEffect(() => {
        if (dataResult?.transactionId && !dataCreateAdoEnrollment?.createAdoUserEnrollment?.idAUE) {
            let value = '';
            switch (parseParams?.DocumentType) {
            case 1:
                value = 'CÉDULA DE CIUDADANÍA';
                break;
            case 2:
                value = 'PASAPORTE';
                break;
            case 3:
                value = 'CÉDULA DE CIUDADANÍA ECUATORIANA';
                break;
            case 4:
                value = 'CÉDULA DE EXTRANJERÍA';
                break;
            case 5:
                value = 'TARJETA DE IDENTIDAD';
                break;
            case 6:
                value = 'CARD ID ISRAEL';
                break;
            case 7:
                value = 'CÉDULA DE CIUDADANÍA PANAMEÑA';
                break;
            default:
                value = 'OTRO';
                break;
            }
            setDocumentType(value);
            createAdoUserEnrollment({
                variables: {
                    input: {
                        transactionId: Number(parseParams?.TransactionId),
                        date: moment(parseParams?.StartingDate).format(),
                        uId: newObjectParams?.uId,
                        idAP: Number(newObjectParams?.idAP),
                        adoStateId: Number(dataResult?.state),
                        uIdeNum: parseParams?.IdNumber,
                    },
                },
            })
                .then(() => {
                    isLoggedVar({ ...isLoggedVar(), message: `Registro exitoso, con estado: ${dataResult?.stateName}.` })
                })
                .catch(error => {
                    isLoggedVar({ ...isLoggedVar(), message: error })
                });
        }
    }, [dataResult, dataCreateAdoEnrollment?.createAdoUserEnrollment]);

    useEffect(() => {
        if (dataCreateAdoEnrollment?.createAdoUserEnrollment) {
            byIdAdoParameters({
                variables: { idAue: Number(dataCreateAdoEnrollment?.createAdoUserEnrollment?.idAUE) },
            });
        }
    }, [dataCreateAdoEnrollment?.createAdoUserEnrollment]);

    useEffect(() => {
        if (dataByIdAdoParameters?.adoUserEnrollmentById) {
            const payrollCompany = dataByIdAdoParameters?.adoUserEnrollmentById;
            updatePayrollDocuments({
                variables: {
                    pcId: payrollCompany?.pcId,
                    input: {
                        pdFroDoc: selectedDocuments?.[0]?.document,
                        pdReaDoc: selectedDocuments?.[1]?.document,
                        pdPerDoc: selectedDocuments?.[2]?.document,
                    },
                },
            });
        }
    }, [dataByIdAdoParameters?.adoUserEnrollmentById]);

    useEffect(() => {
        if (dataUpdatePayrollDocuments?.updatePayrollDocuments) {
            const payrollCompany = dataByIdAdoParameters?.adoUserEnrollmentById?.payrollCompany;
            selectedDocuments?.map(x => {
                return putFileS3(
                    `companys/${payrollCompany?.cId}/payroll/${payrollCompany?.pcId}/documents/${x?.document}`,
                    x?.file
                );
            });
        }
    }, [dataUpdatePayrollDocuments?.updatePayrollDocuments]);

    return (
        <LoginV
            data={{ navigation, showFooter, values, loadLog, parseParams, documentType, loadingCreateAdoUser, dataResult, returnApp }}
            navigation={navigation}
            handleChange={handleChange}
            handleLogIn={handleLogIn}
            handleBlur={handleBlur}
        />
    )
}

LoginC.propTypes = {
    navigation: PropTypes.object
}