import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { RelationDataRegisterV } from '../../components/RelationDataRegister'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { writeCache } from '../../validation'
import { UPDATE_PAYROLL_COMPANY } from './queries'

export const RelationDataRegisterC = ({ dataUser, banner, handleNext, refScroll }) => {
    const [values, setValues] = useState({ payrollRelationships: [] });
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [errors, setErrors] = useState({ payrollRelationships: [] });
    const [errorForm, setErrorForm] = useState({ payrollRelationships: [] });
    const [payrollCompanyMutation, { loading }] = useMutation(UPDATE_PAYROLL_COMPANY);

    // Cambio en los valores
    const handleChange = (e, index) => {
        setValues({ payrollRelationships: values.payrollRelationships?.length && values.payrollRelationships.map((x, i) => i === index ? ({ ...x, [e.name]: e.value }) : x) })
        setErrorForm({ payrollRelationships: errorForm.payrollRelationships?.length && errorForm.payrollRelationships.map((x, i) => i === index ? ({ ...x, [e.name]: e.error }) : x) })
        setErrors({ payrollRelationships: errors.payrollRelationships?.length && errors.payrollRelationships.map((x, i) => i === index ? ({ ...x, [e.name]: e.error }) : x) })
    }

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({ payrollRelationships: dataUser?.payrollRelationships?.length ? dataUser.payrollRelationships.map(x => ({ prId: x?.prId, rId: x?.rId, prName: x?.prName, prPercentage: Number(x?.prPercentage || 0) })) : [{ rId: undefined, prName: undefined, prPercentage: undefined }] })
        setErrorForm({ payrollRelationships: dataUser?.payrollRelationships?.length ? dataUser.payrollRelationships.map(x => ({ rId: !x?.rId, prName: !x?.prName, prPercentage: !x?.prPercentage })) : [{ rId: true, prName: true, prPercentage: true }] })
    }, [dataUser])

    const dataRelationShips = values?.payrollRelationships?.map(x => ({ prId: x?.prId, rId: x?.rId, prName: x?.prName, prPercentage: Number(x?.prPercentage) }))

    useEffect(() => {
        setTotalPercentage(values?.payrollRelationships?.reduce((sum, item) => sum + parseFloat(item.prPercentage || 0), 0) || 0);
    }, [values])

    const handleSubmit = async () => {
        setErrors(errorForm)
        let error = false
        let errorPercentage = false
        // valida que la información de los parentesco
        for (let i = 0; i < errorForm?.payrollRelationships?.length; i++) {
            const { rId, prName, prPercentage } = errorForm?.payrollRelationships[i]
            if (rId || prName || prPercentage) error = true
        }
        for (let item = 0; item < values?.payrollRelationships.length; item++) {
            const element = values?.payrollRelationships[item];
            if (element?.prName?.length < 1) return error = true
        }

        // const totalPercentage = values?.payrollRelationships?.reduce((sum, item) => sum + parseFloat(item.prPercentage), 0);
        if (totalPercentage > 100) {
            errorPercentage = true
            return isLoggedVar({ ...isLoggedVar(), message: ' La suma total del porcentaje de los beneficiario no debe superar 100%' })
        } else {
            errorPercentage = false
        }
        /** verifica si los campos están rellenos */
        if (error) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error: errorMutation } = !errorPercentage && !error && await payrollCompanyMutation({
            variables: { input: { payrollRelationships: dataRelationShips } }, update(store, { data: { setOrUpdatePayrollCompany } }) {
                writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: setOrUpdatePayrollCompany, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (errorMutation) return isLoggedVar({ ...isLoggedVar(), message: errorMutation.message })

        /** resultado */
        if (data?.setOrUpdatePayrollCompany) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(2)
        }
    }

    // agrega otro parentesco
    const handlePlusRelation = () => {
        setValues({ payrollRelationships: [...values.payrollRelationships, { rId: undefined, prName: undefined, prPercentage: undefined }] })
        setErrorForm({ payrollRelationships: [...errorForm.payrollRelationships, { rId: true, prName: true, prPercentage: true }] })
        setErrors({ payrollRelationships: [...errors.payrollRelationships, { rId: true, prName: true, prPercentage: true }] })
    }

    return (
        <RelationDataRegisterV
            data={{ values, errors, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            totalPercentage={totalPercentage}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handlePlusRelation={handlePlusRelation}
        />
    )
}

RelationDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    refScroll: PropTypes.object
}