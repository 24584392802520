import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { ChangePassV } from '../../components/LostPass/ChangePass'
import { CHANGE_PASS } from './queries'
import { validationErrors } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'

export const ChangePassC = ({ user, navigation }) => {
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [changePassUser, { loading, }] = useMutation(CHANGE_PASS)

    // Cambio en los valores
    const handleChange = e => {
        setValues({ ...values, [e.name]: e.value })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)

        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await changePassUser({ variables: { input: { uId: user, uPass: values.uPass } } }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.changePassUser) {
            /** inicia sesión */
            setErrorForm({})
            isLoggedVar({ ...isLoggedVar(), message: 'Su Contraseña ha sido cambiada con éxito.' })
            navigation.navigate('Login')
        }
    }

    return (
        <ChangePassV
            data={{ values, errors, loading }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

ChangePassC.propTypes = {
    user: PropTypes.string,
    navigation: PropTypes.object
}