import { gql, useQuery } from '@apollo/client'

export const USE_ALL_DEPENDENTS = ({ state, order, limit }) => useQuery(gql`
    query getAllDependents($state: [Int], $order: [[String]], $limit: [Int]) {
        dependents(state: $state, order: $order, limit: $limit) {
            dId
            dName
        }
    }
`, {
    variables: { state, order, limit }
})