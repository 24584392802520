// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components/native'

// styles
import { Container, BoxTravel, Box, ButtonOption, Title, Line } from './styled'
import { ShadowBox } from '../../generalStyles'

/**
 * Travel Route Component
 * @param {any} props Properties Travel Route
 * @returns {ReactElement} TravelRouteV
 */
export const TravelRouteV = ({ travelComplete, options, handleClick }) => {
    // States and Variables
    const theme = useTheme()

    return (
        <Container>
            <BoxTravel>
                {options >= 1 &&
                <Box justify={options > 5 ? 'center' : 'flex-start'}>
                    <ButtonOption style={ShadowBox} onPress={() => travelComplete > 1 && handleClick && handleClick && handleClick(1)} activeOpacity={0.8} bgColor={travelComplete >= 1 ? theme.PColor : theme.BGColor}>
                        <Title color={travelComplete >= 1 ? theme.BGColor : theme.SFColor}>1</Title>
                    </ButtonOption>
                    {options > 1 && <>
                        <Line style={ShadowBox} onPress={() => travelComplete > 1.5 && handleClick && handleClick && handleClick(1.5)} bgColor={travelComplete >= 1.5 ? theme.PColor : theme.BGColor}/>
                    </>}
                </Box>
                }
                {options >= 2 &&
                <Box justify={options > 5 ? 'center' : 'flex-start'}>
                    <ButtonOption style={ShadowBox} onPress={() => travelComplete > 2 && handleClick && handleClick && handleClick(2)} activeOpacity={0.8} bgColor={travelComplete >= 2 ? theme.PColor : theme.BGColor}>
                        <Title color={travelComplete >= 2 ? theme.BGColor : theme.SFColor}>2</Title>
                    </ButtonOption>
                    {options > 2 && <>
                        <Line style={ShadowBox} onPress={() => travelComplete > 2.5 && handleClick && handleClick && handleClick(2.5)} bgColor={travelComplete >= 2.5 ? theme.PColor : theme.BGColor}/>
                    </>}
                </Box>
                }
                {options >= 3 &&
                <Box justify={options > 5 ? 'center' : 'flex-start'}>
                    <ButtonOption style={ShadowBox} onPress={() => travelComplete > 3 && handleClick && handleClick && handleClick(3)} activeOpacity={0.8} bgColor={travelComplete >= 3 ? theme.PColor : theme.BGColor}>
                        <Title color={travelComplete >= 3 ? theme.BGColor : theme.SFColor}>3</Title>
                    </ButtonOption>
                    {options > 3 && <>
                        <Line style={ShadowBox} onPress={() => travelComplete > 3.5 && handleClick && handleClick && handleClick(3.5)} bgColor={travelComplete >= 3.5 ? theme.PColor : theme.BGColor}/>
                    </>}
                </Box>
                }
                {options >= 4 &&
                <Box justify={options > 5 ? 'center' : 'flex-start'}>
                    <ButtonOption style={ShadowBox} onPress={() => travelComplete > 4 && handleClick && handleClick && handleClick(4)} activeOpacity={0.8} bgColor={travelComplete >= 4 ? theme.PColor : theme.BGColor}>
                        <Title color={travelComplete >= 4 ? theme.BGColor : theme.SFColor}>4</Title>
                    </ButtonOption>
                    {options > 4 && <>
                        <Line style={ShadowBox} onPress={() => travelComplete > 4.5 && handleClick && handleClick && handleClick(4.5)} bgColor={travelComplete >= 4.5 ? theme.PColor : theme.BGColor}/>
                    </>}
                </Box>
                }
                {options >= 5 &&
                <Box justify={options > 5 ? 'center' : 'flex-start'}>
                    <ButtonOption style={ShadowBox} onPress={() => travelComplete > 5 && handleClick && handleClick && handleClick(5)} activeOpacity={0.8} bgColor={travelComplete >= 5 ? theme.PColor : theme.BGColor}>
                        <Title color={travelComplete >= 5 ? theme.BGColor : theme.SFColor}>5</Title>
                    </ButtonOption>
                    {options > 5 &&
                        <Line style={ShadowBox} onPress={() => travelComplete > 5.5 && handleClick && handleClick && handleClick(5.5)} bgColor={travelComplete >= 6 ? theme.PColor : theme.BGColor}/>
                    }
                </Box>
                }
                {options >= 6 &&
                <Box style={{ justifyContent: 'flex-start' }}>
                    <ButtonOption style={ShadowBox} onPress={() => travelComplete > 6 && handleClick && handleClick && handleClick(6)} activeOpacity={0.8} bgColor={travelComplete >= 6 ? theme.PColor : theme.BGColor}>
                        <Title color={travelComplete >= 6 ? theme.BGColor : theme.SFColor}>6</Title>
                    </ButtonOption>
                </Box>
                }
            </BoxTravel>
        </Container>
    )
}

TravelRouteV.propTypes = {
    travelComplete: PropTypes.number,
    options: PropTypes.number,
    handleClick: PropTypes.func
}