import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Circle, Defs, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_1"
        data-name="Capa 1"
        viewBox="0 0 392 392"
        {...props}
    >
        <Defs></Defs>
        <Circle
            cx={196}
            cy={196.5}
            r={190.5}
            style={{
                fill: '#8ec1ef',
                strokeWidth: 0,
            }}
        />
        <Circle
            cx={196}
            cy={197.12}
            r={155.8}
            style={{
                fill: '#3e78cc',
                strokeWidth: 0,
            }}
        />
        <Circle
            cx={196}
            cy={197.12}
            r={119.3}
            style={{
                fill: '#003478',
                strokeWidth: 0,
            }}
        />
        <Path
            d="M143.05 233.32v-2.44c.13-4.96 2.1-9.04 5.58-12.56 8.15-8.23 16.22-16.54 24.3-24.83 6.36-6.52 12.7-13.06 19.04-19.6.38-.39.76-.79 1.14-1.18.12-.12.26-.24.18-.43-.08-.17-.26-.2-.43-.2-.43 0-.86.02-1.28.02h-46.47c-1.84 0-1.97.17-1.99-1.88-.04-4-.02-7.99 1.07-11.88 2.77-9.81 11.97-17.69 22.1-18.91 1.18-.14 2.37-.2 3.56-.2h62.82c8.81 0 15.78 6.67 16.23 15.49.25 4.74-1.34 8.77-4.62 12.15-6.52 6.7-13.06 13.38-19.58 20.07-7.02 7.2-14.04 14.41-21.06 21.62-6.67 6.85-13.32 13.72-20 20.55-5.62 5.75-5.1 12.88-2.15 17.76 1.38 2.28 3.4 3.88 5.73 5.12.17.09.34.17.51.26.12.06.3.12.23.3-.05.14-.2.19-.36.19h-.5c-8.45 0-16.9-.06-25.34.02-7.53.07-12.94-3.34-16.64-9.72-1.28-2.2-1.76-4.65-1.92-7.17l-.18-2.56h.02Z"
            className="cls-1"
            fill='#FFFFFF'
        />
        <Path
            d="M214.8 252.72h-13.21c-5.13 0-8.9-2.67-10.65-7.51-1.54-4.27-.72-8.04 2.33-11.36 2.65-2.89 5.43-5.66 8.18-8.47 3.03-3.09 6.06-6.19 9.05-9.31 3.89-4.05 8.55-6.57 14.16-7.12 6.15-.6 11.65 1.1 16.34 5.15 4.03 3.48 6.8 7.75 7.65 13.09.7 4.4-.08 8.63-1.83 12.68-3.07 7.09-8.65 11-16.06 12.56-1.05.22-2.11.31-3.19.31h-12.78v-.02Z"
            className="cls-1"
            fill='#DFDF00'
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}