import styled from 'styled-components/native'
import { mq } from '../../validation'

export const Container = styled.View`
    flex: 1;
    border-top-left-radius: ${mq(16)}px;
    border-top-right-radius: ${mq(16)}px;
    max-width: 768px;
`
export const Form = styled.View`
    padding: ${mq(5)}px;
    justify-content: center;
`
export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`
export const BoxButton = styled.View`
    margin: ${mq(10)}px 0;
    align-items: center;
`