import { gql, useQuery } from '@apollo/client'

export const USE_ALL_MARITAL_STATUS = ({ state, order, limit }) => useQuery(gql`
    query getAllMaritalStatus($state: [Int], $order: [[String]], $limit: [Int]) {
        maritalStatus(state: $state, order: $order, limit: $limit) {
            msId
            msName
        }
    }
`, {
    variables: { state, order, limit }
})