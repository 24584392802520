import { StyleSheet } from 'react-native'
import styled, { css } from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { wp } from '../../validation'

export const ContainerModal = styled.View`
    margin-bottom: 0;
    width: ${wp(100)}px;
    padding: ${wp(5)}px;
    background-color: ${theme.backgroundC};
`
export const Row = styled.View`
    flex-direction: row;
    align-items: center;
    ${({ justify }) => justify && css`justify-content: ${justify};`}
    ${({ paddingTop }) => paddingTop && css`padding-top: ${paddingTop}px;`}
`
export const BoxButton = styled.View`
    align-items: center;
    justify-content: center;
    ${({ marginLeft }) => marginLeft && css`margin-left: ${marginLeft}px;`}
    ${({ marginRight }) => marginRight && css`margin-right: ${marginRight}px;`}
`
export const ButtonOption = styled.TouchableOpacity`
    width: ${wp(11)}px;
    height: ${wp(11)}px;
    border-radius: ${wp(100)}px;
    justify-content: center;
    align-items: center;
    background-color: ${({ bgColor }) => bgColor || theme.variantPrimaryC};
`
export const Title = styled.Text`
    font-size: ${({ size }) => size || wp(3.2)}px;
    font-family: ${({ family }) => family || 'PFontRegular'};
    color: ${theme.onSurfaceC};
`
export const styles = StyleSheet.create({
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1
        },
        shadowOpacity: 0.08,
        shadowRadius: 8.5,
        elevation: 7
    }
})