import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { OPSDataRegisterV } from '../../components/CreditEditData/OPSDataRegister'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { filterKeyObject, updateCache, validationErrors } from '../../validation'
import { EDIT_CREDIT_PAYROLL_OPS } from './queries'

export const OPSDataRegisterC = ({ dataUser, banner, handleNext, refScroll }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [creditPayrollOPSMutation, { loading }] = useMutation(EDIT_CREDIT_PAYROLL_OPS)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({
            creditPayrollOPS: filterKeyObject(dataUser?.creditPayrollOPS, ['__typename'])
        })
        setErrorForm({
            cpoContractor: !dataUser?.creditPayrollOPS?.cpoContractor,
            cpoObject: !dataUser?.creditPayrollOPS?.cpoObject,
            cpoValCon: !dataUser?.creditPayrollOPS?.cpoValCon,
            cpoValDatSta: !dataUser?.creditPayrollOPS?.cpoValDatSta,
            cpoValDatEnd: !dataUser?.creditPayrollOPS?.cpoValDatEnd
        })
    }, [dataUser])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ creditPayrollOPS: { ...values.creditPayrollOPS, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await creditPayrollOPSMutation({
            variables: { input: values.creditPayrollOPS }, update(cache, { data: { setOrUpdateCreditPayrollOPS } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollOPS })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdateCreditPayrollOPS) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(4)
        }
    }

    return (
        <OPSDataRegisterV
            data={{ values, errors, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

OPSDataRegisterC.propTypes = {
    payment: PropTypes.number,
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    refScroll: PropTypes.object
}