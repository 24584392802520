import styled from 'styled-components/native'
import { mq } from '../../validation'

export const Container = styled.View`
    flex: 1;
    background-color: ${ props => props.theme.BGSColor };
`
export const ViewCredit = styled.View`
    background-color: ${props => props.theme.BGColor};
    border: 1px solid ${ props => props.theme.PColor };
    border-radius: ${mq(16)}px;
    padding: ${mq(10)}px ${mq(20)}px;
    margin: ${mq(18)}px 0;
    width: 90%;
    max-width: 768px;
    align-self: center;
`
export const HeaderContainer = styled.View`
    align-items: center;
    margin: 0 15%;
`
export const Title = styled.Text`
    font-size: ${({ size }) => size || mq(12)}px;
    font-family: ${({ fontFamily }) => fontFamily || 'PFontMedium'};
    text-align: ${({ textAlign }) => textAlign || 'left'};
    padding: 0 ${mq(2)}px;
    padding-bottom: ${({ paddingBottom }) => paddingBottom || mq(10) }px;
    padding-left: ${({ paddingLeft }) => paddingLeft || 0 }px;
    color: ${({ color, theme }) => color || theme.SFColor};
`
export const Footer = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
export const Button = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${mq(5)}px ${mq(8)}px;
    color: ${({ color, theme }) => color || theme.SFColor};
    background-color: ${({ bgColor, theme }) => bgColor || theme.PColor};
    border-radius: ${mq(8)}px;
`