import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Capa 1"
        viewBox="0 0 381 381"
        {...props}
    >
        <Circle
            cx={190.71}
            cy={190.88}
            r={190.5}
            style={{
                fill: '#f4c1e1',
                strokeWidth: 0,
            }}
        />
        <Circle
            cx={190.71}
            cy={190.88}
            r={155.8}
            style={{
                fill: '#d864b1',
                strokeWidth: 0,
            }}
        />
        <Circle
            cx={190.71}
            cy={190.88}
            r={119.3}
            style={{
                fill: '#a1005d',
                strokeWidth: 0,
            }}
        />
        <Path
            d="M152.75 194.4c0-12.37.1-24.73-.05-37.1-.06-5.35 4.17-9.57 9.54-9.53 14.41.09 28.82.03 43.23.03 11.1 0 20.11 8.02 21.21 19.02.39 3.86 0 7.71-1.31 11.41-1.75 4.91-5.1 8.29-9.91 10.25-.33.14-.68.25-1.01.4-.21.1-.57.09-.51.44.04.25.33.24.53.3 1.49.46 2.94 1 4.32 1.73 4.2 2.23 7.03 5.64 8.41 10.15 1.98 6.48 2.23 13-.59 19.34-3.36 7.56-9.42 11.6-17.46 12.93-1.29.21-2.59.22-3.89.22h-50.82q-1.7 0-1.71-1.73v-37.85Zm.82 6.58c.48.96.93 1.94 1.43 2.88 3.28 6.14 7.98 10.82 14.34 13.76 6.82 3.15 13.97 3.92 21.36 2.82 3.54-.53 6.66-1.89 8.93-4.78 2.34-2.99 3.32-6.41 3.21-10.18-.1-3.7-2.53-6.14-6.22-6.15-5.67-.01-11.34.02-17.01-.02-1.07 0-1.46.29-1.44 1.4.06 3.64 0 7.28.03 10.92 0 .9-.3 1.21-1.22 1.11-5.32-.62-10.25-2.34-14.82-5.11-3.1-1.88-5.87-4.17-8.59-6.64Zm33.83-34.06c-2.71 0-5.42.02-8.13-.01-.79 0-1.09.26-1.08 1.06.03 4.07.03 8.15 0 12.22 0 .84.28 1.13 1.13 1.13 5.35-.03 10.69 0 16.04-.03 1.76 0 3.21-.74 4.26-2.19 1.38-1.9 1.68-4.05 1.37-6.28-.57-4.12-2.69-5.9-6.86-5.9h-6.72Z"
            style={{
                strokeWidth: 0,
                fill: '#fff',
            }}
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    color2: PropTypes.any,
    color3: PropTypes.any,
    color4: PropTypes.any,
    opacity: PropTypes.any,
    props: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}