/* eslint-disable no-shadow */
import { useQuery, useMutation } from '@apollo/client';
import { nanoid } from 'nanoid';
import React, { useState } from 'react'
import { View, Text, Platform } from 'react-native';
import { CIRENIO_HANDLE_EVENT, CIRENIO_PARAMETERS, CREATE_FORM_TOKEN_LYRA, CREATE_PAYROLL_LYRA_TRANSACTION, LYRA_PARAMETERS } from './queries';
import { apolloClient } from '../../apollo/client';
import { BoxButton } from './styled';
import { ButtonSubmit } from '../../components/ButtonSubmit';
import PropTypes from 'prop-types';

export const Cirenio = ({ handleNext, navigation, payroll, ctLyra, ctCirenio }) => {

    const [ipAddress, setIpAddress] = useState(null)
    const { data, loading } = useQuery(CIRENIO_PARAMETERS)
    const { data: dataLyra } = useQuery(LYRA_PARAMETERS)
    const [createFormToken] = useMutation(CREATE_FORM_TOKEN_LYRA)
    const [createLyraTransaction] = useMutation(CREATE_PAYROLL_LYRA_TRANSACTION)

    const createUrl = ({ env, uuId, publicKey }) => {
        if (env?.toLowerCase() === 'stage') {
            return `https://app.cirenio.com?public_key=${publicKey}&external_id=${uuId}`
        }

        return ''
    }

    const external = nanoid();
    const krPublicKey = dataLyra?.getLyraParameters?.lpPublicKey

    //FUNCIÓN QUE PERMITE GUARDAR LA TRANSACCIÓN DEL FORM TOKEN
    const handleLyraTransaction = (pcId, data) => {
        createLyraTransaction({
            variables: {
                input: {
                    pcId,
                    pltData: data
                }
            }
        })
    }

    //FUNCIÓN QUE PERMITE CREAR EL FORM TOKEN PARA LYRA
    const handleFormTokenLyra = (data, hash) => {
        createFormToken({
            variables: {
                input: {
                    currency: 'COP',
                    orderId: data?.pcId,
                    customer: {
                        email: data?.payrollStaff?.psEmail,
                        reference: data?.pcIdeNum,
                    },
                }
            }
        }).then(data => {
            handleLyraTransaction(payroll?.pcId, data?.data)
            localStorage.setItem('lyra', JSON.stringify({ formToken: data?.data?.createLyraFormToken?.answer?.formToken, krPublicKey, hash }));
            handleNext(9)
        }).catch(( error) => console.log(error))
    }

    // funcion que ejecuta la mutacion
    const handleEventMut = async (data, type) => {
        await apolloClient.mutate({
            mutation: CIRENIO_HANDLE_EVENT,
            variables: {
                input: { event: data, eventType: type, externalId: external, hash: data?.hash, ipAddress }
            }
        })

        if (type === 'flow_done') {
            if (ctCirenio === 1 && ctLyra === 1) {
                handleFormTokenLyra(payroll, data?.hash)
            } else {
                navigation.navigate('RequestCredit', { hash: data?.hash })
            }
        }
    }
    // api que obtiene la ip del cliente
    fetch('https://api.ipify.org?format=json', {
        method: 'GET'
    }).then(res => res.json()).then(res => setIpAddress(res?.ip || null)).catch()

    // manejo de eventos
    const cirenioEvents = {
        'flow_done': data => handleEventMut(data, 'flow_done'),
        'consents_granted': data => handleEventMut(data, 'consents_granted'),
        'banks_listed': data => handleEventMut(data, 'banks_listed'),
        'bank_selected': data => handleEventMut(data, 'bank_selected'),
        'login_requested': data => handleEventMut(data, 'login_requested'),
        'login_failed': data => handleEventMut(data, 'login_failed'),
        'missing_bank_form': data => handleEventMut(data, 'missing_bank_form'),
        'missing_bank_sent': data => handleEventMut(data, 'missing_bank_sent'),
    };
    Object.keys(cirenioEvents).forEach(event => {
        window.Cirenio.on(event, cirenioEvents[event]);
    });

    const handleSubmit = () => {
        navigation.navigate('RequestCredit', { hash: null })
    }

    // renderizado de vista
    if (Platform.OS === 'web' && !loading) {
        return (
            <>
                <iframe
                    title="Cirenio"
                    src={createUrl({ env: data?.getCirenioParameter?.env, uuId: external, publicKey: data?.getCirenioParameter?.cpPublicKey })}
                    width="100%"
                    height="700px"
                    style={{ border: 'none' }}
                />
                {ctCirenio === 1 && ctLyra === 0 &&
                    <BoxButton>
                        <ButtonSubmit onPress={handleSubmit} title='Continuar sin CIRENIO' />
                    </BoxButton>
                }
            </>
        );
    } else {
        return (
            <View>
                <Text>El contenido web no está disponible en esta plataforma.</Text>
            </View>
        );
    }
}

Cirenio.propTypes = {
    handleNext: PropTypes.func,
    navigation: PropTypes.object,
    payroll:  PropTypes.object,
    ctLyra: PropTypes.object,
    ctCirenio: PropTypes.object,
};