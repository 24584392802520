import React from 'react'
import Svg, { Path } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
// eslint-disable-next-line react/prop-types
export const LogoLogin = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_1"
        data-name="Capa 1"
        viewBox="0 0 272.8 128.3"
        {...props}
    >
        <Path
            d="m4.77 66.55 6.09-11.05 22.48 36.57H20.39L4.77 66.55zM12.39 53.21l3.43 5.33s2.23-3.75 3.05-4.95c.33-.48 1.7-.9 2.29 0 4.95 7.62 22.48 36.58 22.48 36.58l6.86.38 3.05-5.71s-20-35.24-27.62-46.67c-.57-.85-2.67-1.52-4.08 0-2.96 3.2-9.45 15.05-9.45 15.05ZM36.77 51.69l3.43 5.71s2.42-4.7 3.24-5.91c.33-.48 1.18-1.14 1.9 0 5.91 9.33 14.29 23.43 14.29 23.43l6.29-11.24S57.35 49.01 50.11 37.2c-.72-1.17-4.33-1.51-5.52.19-2.67 3.81-7.81 14.29-7.81 14.29Z"
            className="cls-1"
        />
        <Path
            d="M99.52 73V60.48l-7 12.52h-2.13l-7.02-12.5V73h-2.4V55.11h2.42l8.17 14.61 8.13-14.61h2.23V73h-2.4ZM122.66 73l-6.98-14.41-7.1 14.41h-2.85l9-17.89h1.87L125.54 73h-2.89ZM143.26 73l-5.91-7.14-5.87 7.14h-3.27l7.56-8.94-7.56-8.94h3.25l5.89 7.16 5.89-7.16h3.22l-7.51 8.82 7.54 9.07h-3.24ZM155.87 73c-3.7 0-5.11-1.75-5.11-4.32v-9.25c0-2.57 1.41-4.32 5.11-4.32h13.1v2.64h-13.1c-1.39 0-2.03.58-2.03 1.65v9.3c0 1.07.65 1.65 2.03 1.65h13.1v2.64h-13.1ZM188.02 73.02l-9.08-7.98-2.61-.02V73h-2.93V55.11h13.17c3.52 0 4.87 1.75 4.87 4.32v1.27c.09 1.8.09 5.06-9.06 4.34l9.46 7.98h-3.81Zm.48-13.61c0-1.07-.62-1.65-1.94-1.65h-10.24v4.62h10.24c1.32 0 1.94-.58 1.94-1.65v-1.32ZM196.22 73V54.73h18.47v2.7h-15.3V62h15.3v2.7h-15.3v5.6h15.3V73h-18.47ZM219.36 73V54.73h12.69c3.92 0 5.12 1.79 5.12 5.4v7.65c0 3.42-1.2 5.22-5.12 5.22h-12.69Zm15.19-12.87c0-2.1-.55-2.7-2.5-2.7h-10.08V70.3h10.08c1.95 0 2.5-.6 2.5-2.54v-7.63ZM242.35 73V54.73h2.99V73h-2.99ZM257.58 73V57.43h-7.84v-2.7h18.29v2.7h-7.63V73h-2.82Z"
            className="cls-2"
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}