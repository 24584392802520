import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import theme from '../../../ignore/Constants'
import { numberFormat, wp } from '../../validation'
import { Box, IconContainer, Title, styles } from './styled'
import { ProfileHeaderS } from '../../skeleton/ProfileHeaderS'

export const ProfileCardV = ({ data, navigation, loading }) => {
    return (
        <Box style={styles.shadow}>
            {loading ? <ProfileHeaderS /> : <>
                <View style={{ flex: .8 }}>
                    <Title numberOfLines={1}>{data?.payrollStaff?.psFirNam || ''} {data?.payrollStaff?.psSecNam || ''} {data?.payrollStaff?.psFirLas || ''} {data?.payrollStaff?.psSecLas || ''} </Title>
                    <Title numberOfLines={1}>{numberFormat(data?.pcIdeNum)}</Title>
                    <Title family='PFontLight' numberOfLines={1}>{data?.typeIdentity?.tiName}</Title>
                </View>
                <IconContainer onPress={() => navigation.navigate('Profile')}>
                    <FontAwesome name='user' size={wp(7)} color={theme.onVariantSurfC} />
                </IconContainer>
            </>}
        </Box>
    )
}

ProfileCardV.propTypes = {
    data: PropTypes.object,
    navigation: PropTypes.object,
    loading: PropTypes.bool
}