import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Defs, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 392 392"
        style={{
            enableBackground: 'new 0 0 392 392',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <Path
            d="M387.6 195.1c0 103.9-84.2 188.1-188.1 188.1-103.8 0-188-84.2-188-188.1 0-103.8 84.2-188 188-188 103.9 0 188.1 84.1 188.1 188z"
            style={{
                opacity: 0.26,
                fill: '#30704b',
                enableBackground: 'new',
            }}
        />
        <Path
            d="M347.2 195.1c0 81.5-66.1 147.7-147.7 147.7S51.8 276.6 51.8 195.1 118 47.4 199.5 47.4c81.6.1 147.7 66.2 147.7 147.7z"
            style={{
                fill: '#30704b',
                enableBackground: 'new',
            }}
        />
        <Path
            d="M313.7 195.1c0 63.1-51.1 114.2-114.2 114.2S85.3 258.1 85.3 195.1 136.4 80.9 199.5 80.9s114.2 51.2 114.2 114.2z"
            style={{
                fill: '#fff',
            }}
        />
        <Path
            d="M220.8 21.9 122.1-97c-1-1.2-1.5-1.5-3.2-1.5-2.3 0-3 1.5-3 3.9V33.8c0 2.6 1.4 4 4 4h105.2c2.1 0 3.3-1 3.6-3V-94.1c0-2.6-1.4-3.9-4-3.9s-3.9 1.2-3.9 3.9v116zm-97-104.4 92.1 112.4h-92.1V-82.5z"
            style={{
                fill: '#008037',
            }}
            transform="translate(25.892 222.75)"
        />
        <Defs>
            <Path id="a" d="M169.5 124.3h66.8v32.8h-66.8z" />
        </Defs>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}