import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { LocationDataRegisterV } from '../../components/CreditEditData/LocationDataRegister'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { filterKeyObject, updateCache, validationErrors } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'
import { EDIT_CREDIT_PAYROLL_RESIDENCE } from './queries'

export const LocationDataRegisterC = ({ dataUser, banner, handleNext, refScroll }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [creditPayrollResidenceMutation, { loading }] = useMutation(EDIT_CREDIT_PAYROLL_RESIDENCE)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({ creditPayrollResidence: { ...filterKeyObject(dataUser?.creditPayrollResidence, ['__typename']), cpcId: dataUser?.cpcId } })
        setErrorForm({
            cId: !dataUser?.creditPayrollResidence?.cId,
            taId: !dataUser?.creditPayrollResidence?.taId,
            cprNumDir: !dataUser?.creditPayrollResidence?.cprNumDir,
            cprNum: !dataUser?.creditPayrollResidence?.cprNum,
            cprNumHou: !dataUser?.creditPayrollResidence?.cprNumHou,
            cprStratum: !dataUser?.creditPayrollResidence?.cprStratum
        })
    }, [dataUser])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ creditPayrollResidence: { ...values.creditPayrollResidence, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await creditPayrollResidenceMutation({
            variables: { input: { ...values.creditPayrollResidence, cpcId: dataUser?.cpcId } }, update(cache, { data: { setOrUpdateCreditPayrollResidence } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollResidence })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdateCreditPayrollResidence) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(3)
        }
    }

    return (
        <LocationDataRegisterV
            data={{ values, errors, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

LocationDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    refScroll: PropTypes.object
}