import { gql } from '@apollo/client'

export const UPDATE_PAYROLL_COMPANY = gql`
mutation setOrUpdatePayrollCompany($pcId: ID, $input: ISetOrUpdatePayrollCompany!) {
    setOrUpdatePayrollCompany(pcId: $pcId, input: $input) {
        pcId
        payrollReference {
            prId
            prNamFam
            prLasFam
            rIdFam
            prPhoFam
            cIdFam
            taIdFam
            prNumDirFam
            prNumFam
            prNumHouFam
            prInfFam
            prNamPar
            prLasPar
            prPhoPar
            cIdPar
            taIdPar
            prNumDirPar
            prNumPar
            prNumHouPar
            prInfPar
            prState
            tiIdStu
            cIdStu
            taIdStu
            gIdStu
            prIdeNumStu
            prNamStu
            prLasStu
            prPhoStu
            prEmailStu
            prFacultyStu
            prNumDirStu
            prNumStu
            prNumHouStu
            prInfStu
        }
    }
}
`