import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { TypeAddress } from '../Selects/TypeAddress'
import { Cities } from '../Selects/Cities'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, Row, BoxButton } from './styled'
import { StratumType } from '../Selects/StratumType'

export const LocationDataRegisterV = ({ data: { banner, errors, values, loading }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            <Cities testID='c4169ee0-06ba-434a-8d85-bc622ad03893' error={errors?.cId} value={values?.payrollResidence?.cId} handleSelect={handleChange} />
            <TypeAddress testID='4961f456-f90f-48e1-811b-c4fb49760786' error={errors?.taId} value={values?.payrollResidence?.taId} handleSelect={handleChange} />
            <InputHook testID='17562340-4c3e-4f31-a5f6-6a7a598986c5' title='No. vía *' returnKeyType='next' error={errors?.prNumDir} value={values?.payrollResidence?.prNumDir} name='prNumDir' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
            <Row>
                <InputHook flex={.47} testID='7698adde-9af0-47d5-8cf1-d30894a1511b' title='Nomenclatura. *' returnKeyType='next' error={errors?.prNum} value={values?.payrollResidence?.prNum} name='prNum' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                <InputHook flex={.47} testID='506441f4-6ab3-442f-857c-5274ff5eaa98' returnKeyType='next' value={values?.payrollResidence?.prNumHou} name='prNumHou' range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
            </Row>
            <InputHook testID='06dcabd3-2f20-4f2e-b41e-f6bad7fdfcb6' title='Información Residencial (Opcional)' returnKeyType='next' error={errors?.prInformation} value={values?.payrollResidence?.prInformation} name='prInformation' range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            {/* <InputHook testID='06dcabd3-2f20-4f2e-b41e-f6bad7f4dfq5cb6' title='Estrato assa*' returnKeyType='next' error={errors?.prStratum} value={values?.payrollResidence?.prStratum} name='prStratum' required range={[1, 6]} onBlur={handleBlur} onChangeText={handleChange} /> */}
            <StratumType testID='614ff010-9474-11ee-b0cc-a90808db3a99' error={errors?.prStratum} value={values?.payrollResidence?.prStratum} onBlur={handleBlur} handleSelect={e => handleChange(e)} />
            <BoxButton>
                <ButtonSubmit testID='ca893f54-fc1a-44dd-ac00-0e5a0ce32a0b' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

LocationDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
}