import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { wp, hp } from '../../validation'

export const Container = styled.View`
    background-color: ${props => props.theme.BGSColor};
    padding: ${wp(2.5)}px ${wp(6)}px;
    flex: 1;
`
export const Box = styled.View`
    background-color: ${theme.backgroundC};
    padding: ${wp(2)}px ${wp(5)}px;
    width: 90%;
    justify-content: space-between;
    align-self: center;
    border-bottom-left-radius: ${wp(3)}px;
    border-bottom-right-radius: ${wp(3)}px;
`
export const Title = styled.Text`
    padding-left: ${wp(3)}px;
    font-family: PFontRegular;
    font-size: ${wp(3.5)}px;
    color: ${theme.onSurfaceC};
`
export const Row = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 ${wp(2)}px;
`
export const BoxConsecutive = styled.View`
    position: absolute;
    bottom: 5px;
    right: 5px;
`
export const styles = StyleSheet.create({
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: hp(1)
        },
        shadowOpacity: 0.08,
        shadowRadius: 8.5,
        elevation: 7
    }
})