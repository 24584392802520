import styled from 'styled-components/native'
import { mq } from '../../validation'

export const Container = styled.View`
    background-color: ${props => props.theme.BGSColor};
    flex: 1;
    overflow: hidden;
    align-items: center;
`
export const Box = styled.View`
    overflow: hidden;
    flex: 1;
    width: 90%;
    max-width: 768px;
    padding: ${mq(5)}px ${({ paddingHorizontal }) => paddingHorizontal}px 0 ${({ paddingHorizontal }) => paddingHorizontal}px ;
    background-color: ${props => props.theme.BGColor};
    border-top-right-radius: ${mq(20)}px;
    border-top-left-radius: ${mq(20)}px;
`