import React from 'react'
import PropTypes from 'prop-types'
import { Entypo, AntDesign } from '@expo/vector-icons'
import { numberFormat, wp } from '../../validation'
import { ContainerFee, BoxHead, ButtonIcon, Label, BoxInputFee } from './styled'
import theme from '../../../ignore/Constants'
import { ButtonSubmit } from '../ButtonSubmit'
import { InputHook } from '../InputHook'

export const Fee = ({ data: { fee, messageErrorFee }, onChange, handleConfirm, handleClose }) => (
    <ContainerFee>
        <BoxHead>
            <Entypo name='credit-card' size={wp(6.5)} color={theme.backgroundC} style={{ marginRight: wp(4) }} />
            <Label size={wp(4.5)} color={theme.backgroundC} family='PFontBold'>Cuota a Incorporar</Label>
            <ButtonIcon onPress={handleClose}>
                <AntDesign name='close' size={wp(5)} color={theme.backgroundC} />
            </ButtonIcon>
        </BoxHead>
        <BoxInputFee>
            <InputHook
                returnKeyType='go'
                keyboardType='numeric'
                value={numberFormat(fee) || ''}
                name='fee'
                required
                numeric
                format
                onChangeText={onChange}
            />
        </BoxInputFee>
        {!!messageErrorFee && <Label align='center' color={theme.errorC} >{messageErrorFee}</Label>}
        <ButtonSubmit onPress={handleConfirm} title='Calcular' />
    </ContainerFee>
)

Fee.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleClose: PropTypes.func
}