import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome, MaterialCommunityIcons, Entypo, Octicons } from '@expo/vector-icons'
/** personal imports */
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton } from './styled'
import { ViewPhoto } from '../ViewPhoto'
import theme from '../../../ignore/Constants'
import { hp } from '../../validation'

export const DocumentDataRegisterV = ({ data: { banner, infoCamera, values, files, loading, format, loadPD, loadFD, loadRD, loadSD, loadPRD, loadBD, loadCD, loadBCD, loadBRD, loadCAD, loadRut, loadCC, loadIP, filePD, fileFD, fileRD, fileSD, filePRD, fileBD, fileCD, fileBRD, fileCAD, fileBCD, fileRut, fileCC, fileIP }, handleInfo, handleSubmit, handleButton }) => (
    <Container>
        {banner}
        <Form>
            {!!format?.ufFroDoc && <ViewPhoto
                loadingDoc={loadFD}
                testID='ce2cb3ae-5e3f-46a4-b938-a755a5b3c00c'
                nameFile={values?.payrollDocument?.pdFroDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Cédula frontal'
                uri={files?.pdFroDocA || fileFD}
                icon={<FontAwesome name='vcard' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(1)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdFroDoc', 'pdFroDocA')}
            />}
            {!!format?.ufReaDoc && <ViewPhoto
                loadingDoc={loadRD}
                testID='16b1d799-f054-473a-bae3-acb69dd6f220'
                nameFile={values?.payrollDocument?.pdReaDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Cédula por detrás'
                uri={files?.pdReaDocA || fileRD}
                icon={<MaterialCommunityIcons name='card-bulleted' size={hp(26)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(2)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdReaDoc', 'pdReaDocA')}
            />}
            {!!format?.ufPerDoc && <ViewPhoto
                loadingDoc={loadPD}
                testID='340787e1-717d-4c2f-af6b-27a249c6c32b'
                nameFile={values?.payrollDocument?.pdPerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Selfie con Cédula frontal'
                uri={files?.pdPerDocA || filePD}
                icon={<Entypo name='v-card' size={hp(24)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(3)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdPerDoc', 'pdPerDocA')}
            />}
            {!!format?.ufSerDoc && <ViewPhoto
                loadingDoc={loadSD}
                testID='c7ab39d3-e2d8-4694-ade7-c55ecbdb79e0'
                nameFile={values?.payrollDocument?.pdSerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title={`Servicios Públicos${'\n'} (Luz, Agua o Gas)`}
                uri={files?.pdSerDocA || fileSD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(4)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdSerDoc', 'pdSerDocA')}
            />}
            {!!format?.ufPayRemDoc && <ViewPhoto
                loadingDoc={loadPRD}
                testID='85d83a44-cbb7-4cf4-b9d4-55020d5a7d36'
                nameFile={values?.payrollDocument?.pdPayRemDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Desprendible de nómina'
                uri={files?.pdPayRemDocA || filePRD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(5)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdPayRemDoc', 'pdPayRemDocA')}
            />}
            {!!format?.ufBanDoc && <ViewPhoto
                loadingDoc={loadBD}
                nameFile={values?.payrollDocument?.pdBanDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Certificado Bancario'
                uri={files?.pdBanDocA || fileBD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(6)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdBanDoc', 'pdBanDocA')}
            />}
            {!!format?.ufConDoc && <ViewPhoto
                loadingDoc={loadCD}
                nameFile={values?.payrollDocument?.pdConDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Contrato Laboral'
                uri={files?.pdConDocA || fileCD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(7)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdConDoc', 'pdConDocA')}
            />}
            {!!format?.ufComActDoc && <ViewPhoto
                loadingDoc={loadCAD}
                nameFile={values?.payrollDocument?.pdComActDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Acta de Inicio'
                uri={files?.pdComActDocA || fileCAD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(8)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdComActDoc', 'pdComActDocA')}
            />}
            {!!format?.ufBudRecDoc && <ViewPhoto
                loadingDoc={loadBRD}
                nameFile={values?.payrollDocument?.pdBudRecDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Registro de Presupuesto'
                uri={files?.pdBudRecDocA || fileBRD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(9)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdBudRecDoc', 'pdBudRecDocA')}
            />}
            {!!format?.ufBudCerDoc && <ViewPhoto
                loadingDoc={loadBCD}
                nameFile={values?.payrollDocument?.pdBudCerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Certificado de Presupuesto'
                uri={files?.pdBudCerDocA || fileBCD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(10)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdBudCerDoc', 'pdBudCerDocA')}
            />}
            {!!format?.ufRut && <ViewPhoto
                loadingDoc={loadRut}
                nameFile={values?.payrollDocument?.pdRut}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Rut'
                uri={files?.pdRutA || fileRut}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(11)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdRut', 'pdRutA')}
            />}
            {!!format?.ufComChamber && <ViewPhoto
                loadingDoc={loadCC}
                nameFile={values?.payrollDocument?.pdComChamber}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Camara de Comercio'
                uri={files?.pdComChamberA || fileCC}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(12)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdComChamber', 'pdComChamberA')}
            />}
            {!!format?.ufInstitutionPic && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdInstitutionPic}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Foto de la Institución'
                uri={files?.pdInstitutionPicA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(13)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdInstitutionPic', 'pdInstitutionPicA')}
            />}
            {!!format?.ufWorkCertificate && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdWorkCertificate}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Certificado laboral'
                uri={files?.pdWorkCertificateA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(7)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdWorkCertificate', 'pdWorkCertificateA')}
            />}
            {!!format?.ufBankStatement && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdBankStatement}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Flujo de caja - Extracto bancario'
                uri={files?.pdBankStatementA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(14)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdBankStatement', 'pdBankStatementA')}
            />}
            {!!format?.ufFinancialStatements && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdFinancialStatements}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Estados financieros'
                uri={files?.pdFinancialStatementsA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(15)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdFinancialStatements', 'pdFinancialStatementsA')}
            />}
            {!!format?.ufIncomeTaxReturn && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdIncomeTaxReturn}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Declaración de renta'
                uri={files?.pdIncomeTaxReturnA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(16)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdIncomeTaxReturn', 'pdIncomeTaxReturnA')}
            />}
            {!!format?.ufBoardDirectors && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdBoardDirectors}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Acta junta directiva'
                uri={files?.pdBoardDirectorsA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(17)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdBoardDirectors', 'pdBoardDirectorsA')}
            />}
            {!!format?.ufTradeBalance && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdTradeBalance}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Balance comercial'
                uri={files?.pdTradeBalanceA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(18)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdTradeBalance', 'pdTradeBalanceA')}
            />}
            {!!format?.ufRealEstateRegistration && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdRealEstateRegistration}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Matricula inmobiliaria'
                uri={files?.pdRealEstateRegistrationA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(19)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdRealEstateRegistration', 'pdRealEstateRegistrationA')}
            />}
            {!!format?.ufVehicleOwnershipCard && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdVehicleOwnershipCard}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Tarjeta propiedad vehículo'
                uri={files?.pdVehicleOwnershipCardA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(20)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdVehicleOwnershipCard', 'pdVehicleOwnershipCardA')}
            />}
            {!!format?.ufCommercialReference && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdCommercialReference}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Referencia comercial'
                uri={files?.pdCommercialReferenceA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(21)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdCommercialReference', 'pdCommercialReferenceA')}
            />}
            {!!format?.ufBankReference && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdBankReference}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Referencia bancaria'
                uri={files?.pdBankReferenceA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(22)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdBankReference', 'pdBankReferenceA')}
            />}
            {!!format?.ufIncomeWithholdings && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdIncomeWithholdings}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Certificado de ingresos y retenciones'
                uri={files?.pdIncomeWithholdingsA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(23)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdIncomeWithholdings', 'pdIncomeWithholdingsA')}
            />}
            {!!format?.ufCadastreAndValuation && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdCadastreAndValuation}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Paz salvo catastro y valorización'
                uri={files?.pdCadastreAndValuationA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(24)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdCadastreAndValuation', 'pdCadastreAndValuationA')}
            />}
            {!!format?.ufCommercialAppraisal && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.pdCommercialAppraisal}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Avaluó comercial'
                uri={files?.pdCommercialAppraisalA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(25)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdCommercialAppraisal', 'pdCommercialAppraisalA')}
            />}
            <BoxButton>
                <ButtonSubmit testID='74811d9a-0d37-42ae-8618-8f4e1f96d6a6' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

DocumentDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleButton: PropTypes.func
}