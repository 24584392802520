import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { hp, wp } from '../../validation'

export const Container = styled.View`
    flex: 1;
    border-top-left-radius: ${wp(7)}px;
    border-top-right-radius: ${wp(7)}px;
`
export const Form = styled.View`
    padding: ${wp(2.5)}px;
    justify-content: center;
`
export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`
export const BoxButton = styled.View`
    margin: ${wp(3)}px 0;
    align-items: center;
`
export const BoxButtonDocument = styled.View`
    /* margin: ${wp(1)}px 0; */
    align-items: center;
`
export const ButtonPlus = styled.TouchableOpacity`
    width: ${wp(40)}px;
    background-color: ${theme.secondaryC};
    padding: ${wp(1)}px ${wp(5)}px;
    align-self: flex-end;
    align-items: center;
    border-bottom-left-radius: ${wp(2)}px;
    border-bottom-right-radius: ${wp(2)}px;
`
export const TitlePlus = styled.Text`
    color: ${theme.backgroundC};
    font-size: ${wp(3.5)}px;
    font-family: PFontBold;
`
export const BoxBorder = styled.View`
    border-bottom-color: ${theme.onBackgroundC};
    width: 60%;
    align-self: center;
    border-radius: 1px;
    margin-bottom: ${wp(3)}px;
`
export const Label = styled.Text`
    font-size: ${wp(3.2)}px;
    color: ${({ color }) => color || theme.onSurfaceC};
    font-family: ${({ family }) => family || 'PFontRegular'};
`
export const BoxLabel = styled.View`
    width: ${wp(80)}px;
    margin: ${wp(3)}px 0;
`
export const Box = styled.View`
    padding: ${hp(1.2)}px 0 ${hp(.7)}px;
`
export const Title = styled.Text`
    font-family: PFontBold;
    font-size: ${wp(4.5)}px;
    color: ${theme.primaryC};
    text-align: center;
`