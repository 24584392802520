import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { InfoCodeV } from '../../components/LostPass/InfoCode'
import { GET_CODE_PASS, HANDLE_CODE_PASS } from './queries'
import { filterKeyObject, validationErrors } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'

export const InfoCodeC = ({ handleUser, handleNext, method }) => {
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [getCode, setGetCode] = useState(false)
    const [reSend, setReSend] = useState(false)
    const [errorForm, setErrorForm] = useState({ tiId: true, pcIdeNum: true })
    const [codePass, { loading: loadGet }] = useMutation(GET_CODE_PASS)
    const [handleCodePass, { loading: loadHan }] = useMutation(HANDLE_CODE_PASS)

    // Cambio en los valores
    const handleChange = e => {
        setValues({ ...values, [e.name]: e.value })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    // Busca el código del usuario
    const handleCode = async reSendCode => {
        setErrors(filterKeyObject(errorForm, ['code']))
        if (reSend) return isLoggedVar({ ...isLoggedVar(), message: 'No es posible enviar de nuevo el código solicitado' })

        /** verifica si los campos están rellenos */
        if (validationErrors(filterKeyObject(errorForm, ['code']))) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await codePass({ variables: { input: { method, tiId: values?.tiId, pcIdeNum: values?.pcIdeNum, psEmail: values?.psEmail, psPhoMob: values?.psPhoMob } } }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.getCodePass) {
            setErrorForm({ code: true })
            setReSend(reSendCode)
            setGetCode(true)
            setValues({ ...values, uId: data.getCodePass.uId })
            isLoggedVar({ ...isLoggedVar(), message: 'Se ha enviado un código.' })
        }
    }

    const handleSubmit = async () => {
        setErrors(errorForm)

        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data } = await handleCodePass({ variables: { input: { uId: values.uId, code: values.code } } })

        /** resultado */
        if (data?.handleCodePass) {
            /** inicia sesión */
            handleUser(values.uId)
            setErrorForm({})
            setValues({})
            isLoggedVar({ ...isLoggedVar(), message: 'El código ha sido confirmado, puede proceder al cambio de su contraseña.' })
            handleNext(3)
        }
    }

    return (
        <InfoCodeV
            data={{ method, getCode, values, errors, loading: loadGet || loadHan }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleCode={handleCode}
        />
    )
}

InfoCodeC.propTypes = {
    handleUser: PropTypes.func,
    handleNext: PropTypes.func,
    method: PropTypes.number
}