import React from 'react'
import PropTypes from 'prop-types'
import { Entypo, MaterialIcons } from '@expo/vector-icons'
import { BoxMethod, Title } from './styled'
import theme from '../../../ignore/Constants'
import { wp } from '../../validation'

export const MethodV = ({ handleSubmit }) => (
    <>
        <Title size={wp(5)}>Olvidaste tu contraseña?</Title>
        <Title family='PFontLight'>Seleccione el método de recuperación</Title>
        {/* <BoxMethod onPress={() => handleSubmit(2)} marginTop={wp(8)}>
            <Entypo name='phone' size={wp(7)} color={theme.backgroundC} />
            <Title color={theme.backgroundC} family='PFontMedium'>Mensaje de Texto</Title>
            <Entypo name='chevron-right' size={wp(6)} color={theme.backgroundC} />
        </BoxMethod> */}
        <BoxMethod onPress={() => handleSubmit(1)} marginTop={wp(6)} marginBottom={wp(8)}>
            <MaterialIcons name='email' size={wp(7)} color={theme.backgroundC} />
            <Title testID='18dc63e3-3ec5-42dc-9ddd-dee1c5841f9e' color={theme.backgroundC} family='PFontMedium'>Correo Electrónico</Title>
            <Entypo name='chevron-right' size={wp(6)} color={theme.backgroundC} />
        </BoxMethod>
    </>
)

MethodV.propTypes = {
    handleSubmit: PropTypes.func
}