import styled, { css } from 'styled-components/native'
import Constants from 'expo-constants'
import { mq } from '../../validation'

export const Container = styled.View`
    margin-top: ${mq(10)}px;
    margin-bottom: ${mq(10)}px;
    align-items: center;
    justify-content: center;
    padding: ${mq(12)}px ${mq(15)}px;
    max-width: 768px;
    width: 100%;
`
export const BoxTravel = styled.View`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
`
export const Box = styled.View`
    flex: ${Constants.platform.ios ? 0.29 : 0.3};
    flex-direction: row;
    align-items: center;
    ${({ justify }) => justify && css`justify-content: ${justify};`};
`
export const ButtonOption = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    width: ${mq(38)}px;
    height: ${mq(38)}px;
    border-radius: ${mq(100)}px;
    background-color: ${({ bgColor }) => bgColor};
`
export const Title = styled.Text`
    text-align: center;
    font-size: ${mq(15)}px;
    font-family: PFontMedium;
    color: ${({ color }) => color};
`
export const Line = styled.TouchableOpacity`
    flex: 1;
    height: 6px;
    background-color: ${({ bgColor }) => bgColor};
    margin: 0;
`