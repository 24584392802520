import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { isLoggedVar } from '../../apollo/cache'
import { StatesBarHead } from '../../components/Home/stateBarHead';
import { brandUrls } from '../../lib/vars';
import * as jwt from 'jsonwebtoken';

export const StatesBarHeadC = ({ active360, dataUserById, client, payroll }) => {
    const brans = brandUrls();

    const secretKey = process.env.EXPO_JWT_AUTHO_SECRET || 'test-key'
    const payload = {
        miliPcId: payroll?.pcId,
        miliUid: dataUserById?.uId,
        email: payroll?.payrollStaff?.psEmail
    };

    const tokenAuth = jwt.sign(
        payload,
        secretKey,
        {
            expiresIn: process.env.NODE_ENV !== 'production' ? 9000 * 9000 : 60 * 480,
        }
    );

    const [loading, setLoading] = useState(false);

    const handleGoto360 = () => {
        setLoading(true);
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${brans.bk}apiClient/verifyUserState/${dataUserById?.uIdeNum || ''}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        axios.request(config).then(res => {
            setLoading(false);
            const { data } = res;

            if (data?.clientCreditStatus === 'activo') {
                window.location.href = `${data?.route}/${data?.token}`
            }
        }).catch(err => {
            setLoading(false);
            isLoggedVar({ ...isLoggedVar(), message: `${err?.response?.data || 'ocurrió una incidencia'}` })
        });
    };

    const handleLoginCredipress360 = () => {
        setLoading(true);
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://qa360.credipress.com/api/security/validateTokenMiliForNew360Token/${tokenAuth}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        axios.request(config).then(res => {
            setLoading(false);
            if (res?.request?.responseURL) {
                window.location.href = `${res?.request?.responseURL}`
            }
        }).catch(() => {
            setLoading(false);
            isLoggedVar({ ...isLoggedVar(), message: 'Ocurrió una incidencia, usuario no encontrado.' })
        });
    }

    return (
        <StatesBarHead
            active360={active360}
            handleGoto360={handleGoto360}
            handleLoginCredipress360={handleLoginCredipress360}
            loading={loading}
            client={client}
            dataUserById={dataUserById}
            payroll={payroll}
        />
    )
}

// PropTypes validations
StatesBarHeadC.propTypes = {
    dataUserById: PropTypes.object,
    active360: PropTypes.bool,
    client: PropTypes.string,
    payroll: PropTypes.object,
}