import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { wp, hp } from '../../validation'

export const Container = styled.View`
    background-color: ${theme.backgroundP};
    padding: ${wp(2.5)}px ${wp(8)}px ${wp(3.5)}px;
    flex: 1;
`
export const Title = styled.Text`
    text-align: justify;
    padding-top: ${hp(.3)}px;
    font-family: PFontBold;
    font-size: ${wp(3.5)}px;
    color: ${theme.onSurfaceC};
`
export const Text = styled.Text`
    text-align: justify;
    padding-top: ${hp(.3)}px;
    font-family: PFontRegular;
    font-size: ${wp(3)}px;
    color: ${theme.onSurfaceC};
`
export const ButtonContainer = styled.View`
    margin-top: ${wp(3)}px;
    margin-bottom: ${wp(3)}px;
    align-items: center;
    position: relative;
`