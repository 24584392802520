import React from 'react'
import PropTypes from 'prop-types'
import { mq } from '../../validation'
import { useTheme } from 'react-navigation';
import { MaterialIcons } from '@expo/vector-icons'
import { PaymentButton, Title } from './styled'
import { ActivityIndicator, View } from 'react-native-web';

export const StatesBarHead = ({ active360, handleGoto360, handleLoginCredipress360, loading, client, payroll, dataUserById }) => {

    const theme = useTheme()

    return (
        <>
            {active360 &&
                <PaymentButton disabled={loading || !payroll || !dataUserById } style={{ borderRadius: '6px', width: '35%', height: '40px' }} onPress={client === 'lenddi' ? handleGoto360 : handleLoginCredipress360}>
                    <MaterialIcons name="wallet-membership" size={mq(15)} color={theme.BGColor} style={{ marginRight: '8px', color: '#ffff' }} />
                    {loading ?
                        <View style={{ paddingTop: '5px' }}>
                            <ActivityIndicator style={{ color: '#ffff' }} size='small' color={theme.primaryC}/>
                        </View>:
                        <Title size={mq(12)} style={{ padding: 0, color: '#ffff' }}>360</Title>}
                </PaymentButton>

            }
        </>
    )
}

// PropTypes validations
StatesBarHead.propTypes = {
    check: PropTypes.bool,
    handleGoto360: PropTypes.func,
    handleLoginCredipress360: PropTypes.func,
    active360: PropTypes.bool,
    loading: PropTypes.bool,
    client: PropTypes.string,
    payroll: PropTypes.object,
    dataUserById: PropTypes.object,
}