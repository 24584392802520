import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { InputDateWeb } from '../InputDateWeb'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton } from './styled'

export const OPSDataRegisterV = ({ data: { banner, errors, values, loading }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            <InputHook title='Contratista *' returnKeyType='next' error={errors?.cpoContractor} value={values?.creditPayrollOPS?.cpoContractor} name='cpoContractor' required range={[1, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            <InputHook title='Objeto *' returnKeyType='next' error={errors?.cpoObject} value={values?.creditPayrollOPS?.cpoObject} name='cpoObject' required range={[1, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            <InputHook title='Valor del Contrato *' returnKeyType='next' keyboardType='numeric' error={errors?.cpoValCon} value={values?.creditPayrollOPS?.cpoValCon} name='cpoValCon' required numeric format range={[1, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            <InputDateWeb title='Vigencia Desde *' returnKeyType='next' error={errors?.cpoValDatSta} value={values?.creditPayrollOPS?.cpoValDatSta} name='cpoValDatSta' required onBlur={handleBlur} onChangeText={handleChange} />
            <InputDateWeb title='Vigencia Hasta *' returnKeyType='next' error={errors?.cpoValDatEnd} value={values?.creditPayrollOPS?.cpoValDatEnd} name='cpoValDatEnd' required onBlur={handleBlur} onChangeText={handleChange} />
            <BoxButton>
                <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

OPSDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
}