import React from 'react'
import PropTypes from 'prop-types'
import { SelectHook } from '../SelectHook'

export const StratumType = ({ value, handleSelect, handleCancel, disabled, error, testID }) => {
    const res = [
        { prStratum: '1', eId: '1' },
        { prStratum: '2', eId: '2' },
        { prStratum: '3', eId: '3' },
        { prStratum: '4', eId: '4' },
        { prStratum: '5', eId: '5' },
        { prStratum: '6', eId: '6' },
    ]
    return (
        <SelectHook
            testID={testID}
            data={res}
            error={error}
            // loading={!res?.length && loading}
            title='Estrato *'
            value={value}
            name='prStratum'
            nameAtt='eId'
            titleModal='Seleccionar estrato'
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
        />
    )
}

StratumType.propTypes = {
    value: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    testID: PropTypes.string
}