import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { wp } from '../../validation'

export const Container = styled.View`
    flex: 1;
    justify-content: center;
    background-color: ${theme.backgroundP};
    padding: ${wp(2.5)}px ${wp(8)}px ${wp(3.5)}px;
`
export const Box = styled.View`
    width: 100%;
    position: relative;
    padding: ${wp(5)}px;
    align-items: center;
    border-radius: ${wp(2)}px;
`
export const Title = styled.Text`
    color: ${theme.onSurfaceC};
    margin: ${wp(5)}px 0;
    font-size: ${wp(4)}px;
    font-family: PFontBold;
`
export const Parr = styled.Text`
    color: ${theme.onSurfaceC};
    font-size: ${wp(3)}px;
    font-family: PFontRegular;
`
export const Footer = styled.View`
    bottom: 10px;
    position: absolute;
    text-align: center;
    width: ${wp(60)}px;
`
export const styles = {
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.12,
        shadowRadius: 8.5,
        elevation: 7,
    },
}