import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ProfileV } from '../../components/Profile'
import { useLazyQuery } from '@apollo/client'
import { USE_ONE_PAYROLL_COMPANY } from './queries'
import { getData } from '../../validation'

export const ProfileC = ({ navigation }) => {
    const [getOnePayroll, { data, loading, called }] = useLazyQuery(USE_ONE_PAYROLL_COMPANY, { fetchPolicy: 'cache-and-network' })
    const [consecutive, setConsecutive] = useState('')
    const [profile, setProfile] = useState({})

    // Activa un evento para buscar el perfil del usuario
    useEffect(() => {
        // crea un evento para consultar el perfil del usuario
        const unsubscribe = navigation.addListener('focus', () => getOnePayroll({ variables: { state: [0, 1, 2] } }))
        getData('consecutive').then(x => setConsecutive(x))

        // elimina el evento
        return unsubscribe
    }, [navigation, getOnePayroll])

    useEffect(() => {
        const res = data?.getPayrollCompanyById
        if (called) setProfile(res)
    }, [data, called])

    return (
        <ProfileV
            navigation={navigation}
            data={profile}
            loading={!profile && loading}
            consecutive={consecutive}
        />
    )
}

ProfileC.propTypes = {
    navigation: PropTypes.object
}