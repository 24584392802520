import React from 'react'
import pkg from './../../../package.json'
import theme from '../../../ignore/Constants'
import { HeadTitle } from '../HeadTitle'
import { Container, Text } from './styled'
import { MaterialIcons } from '@expo/vector-icons'
import { ButtonSubmit } from '../ButtonSubmit'
import { wp } from '../../validation'
import { View } from 'react-native'

// eslint-disable-next-line react/prop-types
const ButtonMailto = ({ mailto, label }) => {
    return (
        <Text
            style={{ color: theme.primaryC, fontWeight: 'bold' }}
            to='#'
            onClick={e => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Text>
    );
};

const handleClick = () => {
    window.open('https://soporte.wowdesarrollos.com')
}

export const SupportV = () => {
    return (
        <Container>
            <HeadTitle color={theme.primaryC} title='SOPORTE TÉCNICO' icon={<MaterialIcons name='contact-support' size={wp(8)} color={theme.primaryC} />} />
            <View style={{ width: '90%', alignSelf: 'center' }}>
                <Text>
                Para ayudarle con sus productos y realizar una solicitud o petición
                puede comunicarse vía correo electrónico a
                    <ButtonMailto mailto='mailto:soporte@wowdesarrollos.com' label=" soporte@wowdesarrollos.com" /> o ingresando a nuestro sitio web de soporte dando click al siguiente botón:
                </Text>
                <ButtonSubmit margin='15px auto' onPress={handleClick} title='Soporte Técnico WOW' />
                <Text style={{ fontWeight: 'bold' }}>Producto Versión: {pkg.version}</Text>
            </View>
        </Container>
    )
}