import LoginFondo from './fondo-login.png';
import { Logo } from './Logo'
import Splash from './splash.png';
import { LogoLogin } from './LogoLoginPass'
import { LogoSecond } from './LogoSecond'

export const fibanca = {
    FondoLogin: LoginFondo,
    Logo,
    Splash,
    LogoLogin,
    LogoSecond
}