import Constants from 'expo-constants'
import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { cache, isLoggedVar } from './cache'
import { typeDefs } from './schema'
import { getData, removeData } from '../validation'
import { brandUrls } from '../lib/vars'

const httpLink = createHttpLink({
    uri: brandUrls().gq,
});

// Busca el id del dispositivo
const getDeviceId = async () => {
    if (Constants.platform.web) {
        const fp = await FingerprintJS.load()
        const result = await fp.get()
        return result.visitorId
    }
    return Constants.sessionId
}

const authLink = setContext(async (_, { headers }) => ({
    headers: {
        ...headers,
        authorization: await getData('tokenAuth') || '',
        deviceid: 'hola' || await getDeviceId() || '',
        client: 'ml.app.investor'
    }
}))

const errorHandler = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(err => {
            const { code } = err.extensions
            if (code === 'UNAUTHENTICATED' || code === 403 || code === 'FORBIDDEN') {
                removeData('tokenAuth').then(() => isLoggedVar({ state: false, expired: code === 403 }))
            }
        })
    }
})

export const apolloClient = new ApolloClient({
    cache,
    link: from([
        errorHandler,
        authLink,
        httpLink
    ]),
    typeDefs
})