import React from 'react'
import Svg, { Defs, Path } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
// eslint-disable-next-line react/prop-types
export const LogoLogin = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_1"
        data-name="Capa 1"
        viewBox="0 0 272.82 128.29"
        {...props}
    >
        <Defs></Defs>
        <Path
            fill='#243E38'
            d="M166.1 50.6v28.36h-4.9v-4.28h-.29c-2.2 4.79-5.73 4.94-8.46 4.94-5.31 0-9.35-4.53-9.35-11.35-.08-6.82 4-11.29 9.35-11.35 3.6-.04 5.85 1.38 7.03 3.13h.27V50.6h6.35Zm-6.07 17.66c0-3.65-2.1-6.06-5.29-6.06s-5.24 2.42-5.24 6.06 2.1 6.06 5.24 6.06 5.29-2.42 5.29-6.06ZM172.61 62.26h-2.72l.01-4.51h9.06l-.02 21.15h-6.33V62.26zM171.46 52.42c0-1.94 1.64-3.58 3.58-3.58s3.58 1.64 3.58 3.58-1.64 3.54-3.58 3.54-3.58-1.6-3.58-3.54ZM204.54 64.51v14.45h-6.23V66.24c0-2.25-1.86-3.76-3.95-3.76s-3.47 1.51-3.47 3.76v12.72h-6.46V57.57h5.08l.02 4.83h.2c.04-1.29 1.14-5.49 7.35-5.49 4.49 0 7.47 3.02 7.47 7.59h-.01ZM229.17 57.57l-5.8 21.39h-9.6l-6.53-21.39h6.59l4.38 16.2h1.04l3.47-16.2h6.45ZM252.08 69.9h-15.33c.45 2.78 2.17 4.52 4.91 4.52 1.92 0 3.56-.78 4.14-2.21h6.04c-.73 4.61-4.82 7.4-10.16 7.4-6.49 0-10.82-4.53-10.82-11.35s4.25-11.35 10.61-11.35 10.61 4.53 10.61 11.35v1.63Zm-15.42-3.48h9.62c-.54-2.74-2.2-4.22-4.81-4.22s-4.27 1.48-4.81 4.22ZM269.37 57.4v5.47h-2c-1.41 0-2.79.54-3.76 1.56-.89.94-1.08 1.86-1.08 3.95v10.58h-6.58V57.4h5.24v6.09h.25c.71-4.33 3.75-6.1 6.07-6.09h1.86ZM140.38 69.9h-15.33c.45 2.78 2.17 4.52 4.91 4.52 1.92 0 3.56-.78 4.14-2.21h6.04c-.73 4.61-4.82 7.4-10.16 7.4-6.49 0-10.82-4.53-10.82-11.35s4.25-11.35 10.61-11.35 10.61 4.53 10.61 11.35v1.63Zm-15.42-3.48h9.62c-.54-2.74-2.2-4.22-4.81-4.22s-4.27 1.48-4.81 4.22ZM117.39 57.4v5.47h-2c-1.41 0-2.79.54-3.76 1.56-.89.94-1.08 1.86-1.08 3.95v10.58h-6.58V57.4h5.24v6.09h.25c.71-4.33 3.75-6.1 6.07-6.09h1.86ZM86.03 74.42c-3.23-.03-5.49-3.01-5.34-6.15s1.19-4.97 3.5-5.61v-5.74c-6.12 1.12-9.85 4.58-9.85 11.35s4.5 11.16 11.03 11.35c6.78.19 11.99-3.79 12.33-9.45h-6.33c-.41 1.47-1.73 4.29-5.35 4.25Z"
            className="cls-1"
        />
        <Path
            d="M88.22 55.07c6.64.3 11.16 6.69 11.35 10.81h-7.63c-.2-1.68-1.8-3.78-3.72-4.11v-6.7Z"
            fill='#243E38'
            className="cls-1"
        />
        <Path
            fill='#EC7234'
            d="M25.53 59.79V49.01a.34.34 0 0 0-.5-.3L4.4 59.73c-.58.31-.94.91-.94 1.56v9.98l22.07-11.48ZM3.45 74.43v4.37c0 .62.68 1.02 1.23.71L25 68.37c.32-.18.52-.51.52-.88V62.8L3.45 74.42ZM27.04 59.79V49.01c0-.26.28-.42.5-.3l20.63 11.02c.58.31.94.91.94 1.56v9.98L27.04 59.79ZM49.11 74.43v4.37c0 .62-.68 1.02-1.23.71L27.56 68.37c-.32-.18-.52-.51-.52-.88V62.8l22.07 11.62Z"
            className="cls-2"
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}