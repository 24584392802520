import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { AcademicLevels } from '../Selects/AcademicLevels'
import { Dependents } from '../Selects/Dependents'
import { MaritalStatus } from '../Selects/MaritalStatus'
import { TypeHousings } from '../Selects/TypeHousings'
import { Cities } from '../Selects/Cities'
import { TypeContracts } from '../Selects/TypeContracts'
import { Departments } from '../Selects/Departments'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton } from './styled'
import { SelectHook } from '../SelectHook'
import { InsuranceTypes } from '../Selects/InsuranceTypes'

export const EconomicDataRegisterV = ({ data: { banner, errors, loadPCE, format, values }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            {!!format?.cpfTypHou && <TypeHousings error={errors?.thId} value={values?.creditPayrollResidence?.thId} handleSelect={e => handleChange(e, 1)} />}
            {!!format?.cpfDependents && <Dependents error={errors?.dId} value={values?.creditPayrollResidence?.dId} handleSelect={e => handleChange(e, 1)} />}
            {!!format?.cpfAcaLev && <AcademicLevels error={errors?.alId} value={values?.creditPayrollResidence?.alId} handleSelect={e => handleChange(e, 1)} />}
            {!!format?.cpfMarSta && <MaritalStatus error={errors?.msId} value={values?.creditPayrollResidence?.msId} handleSelect={e => handleChange(e, 1)} />}
            {!!format?.cpfOthInc && <InputHook title='Otros ingresos (Opcional)' returnKeyType='next' keyboardType='numeric' error={errors?.cpwOthInc} value={values?.creditPayrollWork?.cpwOthInc} name='cpwOthInc' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cpfFinExp && <InputHook title='Gastos financieros (Opcional)' returnKeyType='next' keyboardType='numeric' error={errors?.cpwFinExp} value={values?.creditPayrollWork?.cpwFinExp} name='cpwFinExp' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cpfPerExp && <InputHook title='Gastos personales (Opcional)' returnKeyType='next' keyboardType='numeric' error={errors?.cpwPerExp} value={values?.creditPayrollWork?.cpwPerExp} name='cpwPerExp' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cpfEconomyActivities &&(
                <>
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpeaActivity'
                        title='Actividad principal'
                        titleModal='Seleccionar Opción'
                        value={values?.creditPayrollEconomyActivities?.cpeaActivity}
                        error={errors.cpeaActivity}
                        handleSelect={e => handleChange(e, 2)}
                        data={[{ cpeaActivity: 'EMPLEADO', title: 'EMPLEADO' }, { cpeaActivity: 'INDEPENDIENTE', title: 'INDEPENDIENTE' }, { cpeaActivity: 'PENSIONADO', title: 'PENSIONADO' }, { cpeaActivity: 'ESTUDIANTE', title: 'ESTUDIANTE' }]}
                    />
                    {values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' &&
                        <>
                            <InputHook title='Nombre de la empresa' error={errors?.cpeaCompanyName} value={values?.creditPayrollEconomyActivities?.cpeaCompanyName} name='cpeaCompanyName' onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <InputHook title='Dirección de la empresa' error={errors?.cpeaAddress} value={values?.creditPayrollEconomyActivities?.cpeaAddress} name='cpeaAddress' onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <SelectHook
                                required
                                nameAtt='title'
                                name='cpeaTypeCompany'
                                title='Tipo de empresa'
                                titleModal='Seleccionar Opción'
                                value={values?.creditPayrollEconomyActivities?.cpeaTypeCompany}
                                error={errors.cpeaTypeCompany}
                                handleSelect={e => handleChange(e, 2)}
                                data={[{ cpeaTypeCompany: 'PRIVADA', title: 'PRIVADA' }, { cpeaTypeCompany: 'PÚBLICA', title: 'PÚBLICA' }, { cpeaTypeCompany: 'MIXTA', title: 'MIXTA' }]}
                            />
                            <InputHook title='Teléfono laboral' error={errors?.cpeaWorkPhone} value={values?.creditPayrollEconomyActivities?.cpeaWorkPhone} name='cpeaWorkPhone' required numeric onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <InputHook title='Correo electrónico laboral' error={errors?.cpeaEmail} value={values?.creditPayrollEconomyActivities?.cpeaEmail} required name='cpeaEmail' email onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <Departments title='Departamento laboral' error={errors?.dIdDepartment} value={values?.creditPayrollEconomyActivities?.dIdDepartment} required handleSelect={e => handleChange(e, 2)} />
                            <Cities title='Ciudad laboral' error={errors?.cId} value={values?.creditPayrollEconomyActivities?.cId} required handleSelect={e => handleChange(e, 2)} />
                            <InputHook title='Cargo actual' error={errors?.cpeaPosition} value={values?.creditPayrollEconomyActivities?.cpeaPosition} name='cpeaPosition' letters required onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <TypeContracts title='Tipo contrato' error={errors?.tcId} value={values?.creditPayrollEconomyActivities?.tcId} required handleSelect={e => handleChange(e, 2)} />
                            <InputHook title='Antigüedad en meses' error={errors?.cpeaAntiquity} value={values?.creditPayrollEconomyActivities?.cpeaAntiquity} name='cpeaAntiquity' numeric required onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                        </>
                    }
                    {values?.creditPayrollEconomyActivities?.cpeaActivity === 'INDEPENDIENTE' &&
                        <>
                            <InputHook title='Actividad comercial' error={errors?.cpeaBussiness} value={values?.creditPayrollEconomyActivities?.cpeaBussiness} name='cpeaBussiness' letters required onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <InputHook title='Antigüedad en meses de actividad comercial' error={errors?.cpeaAntiquityIndependents} value={values?.creditPayrollEconomyActivities?.cpeaAntiquityIndependents} name='cpeaAntiquityIndependents' numeric required onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <SelectHook
                                required
                                nameAtt='title'
                                name='cpeaChamberCommerce'
                                title='¿Tiene cámara de comercio?'
                                titleModal='Seleccionar Opción'
                                value={values?.creditPayrollEconomyActivities?.cpeaChamberCommerce}
                                error={errors.cpeaChamberCommerce}
                                handleSelect={e => handleChange(e, 2)}
                                data={[{ cpeaChamberCommerce: 1, title: 'SI' }, { cpeaChamberCommerce: 2, title: 'NO' }]}
                            />
                            <SelectHook
                                required
                                nameAtt='title'
                                name='cpeaRUT'
                                title='¿Tiene RUT?'
                                titleModal='Seleccionar Opción'
                                value={values?.creditPayrollEconomyActivities?.cpeaRUT}
                                error={errors.cpeaRUT}
                                handleSelect={e => handleChange(e, 2)}
                                data={[{ cpeaRUT: 1, title: 'SI' }, { cpeaRUT: 2, title: 'NO' }]}
                            />
                        </>
                    }
                    {values?.creditPayrollEconomyActivities?.cpeaActivity === 'PENSIONADO' &&
                        <>
                            <InputHook title='Fondo de pensión' error={errors?.cpeaPensionFund} value={values?.creditPayrollEconomyActivities?.cpeaPensionFund} name='cpeaPensionFund' letters required onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <SelectHook
                                required
                                nameAtt='title'
                                name='cpeaTypePension'
                                title='Tipo de pensión'
                                titleModal='Seleccionar Opción'
                                value={values?.creditPayrollEconomyActivities?.cpeaTypePension}
                                error={errors.cpeaTypePension}
                                handleSelect={e => handleChange(e, 2)}
                                data={[{ cpeaTypePension: 'VEJEZ', title: 'VEJEZ' }, { cpeaTypePension: 'INVALIDEZ', title: 'INVALIDEZ' }, { cpeaTypePension: 'SOBREVIVENCIA', title: 'SOBREVIVENCIA' }, { cpeaTypePension: 'RIESGOS LABORALES', title: 'RIESGOS LABORALES' }]}
                            />
                        </>
                    }
                    {values?.creditPayrollEconomyActivities?.cpeaActivity === 'ESTUDIANTE' &&
                        <>
                            <InputHook title='Universidad' error={errors?.cpeaUniversity} value={values?.creditPayrollEconomyActivities?.cpeaUniversity} name='cpeaUniversity' letters required onBlur={handleBlur} onChangeText={e => handleChange(e, 2)} />
                            <SelectHook
                                required
                                nameAtt='title'
                                name='cpeaGenerateIncome'
                                title='¿Genera algún ingreso?'
                                titleModal='Seleccionar Opción'
                                value={values?.creditPayrollEconomyActivities?.cpeaGenerateIncome}
                                error={errors.cpeaGenerateIncome}
                                handleSelect={e => handleChange(e, 2)}
                                data={[{ cpeaGenerateIncome: 1, title: 'SI' }, { cpeaGenerateIncome: 2, title: 'NO' }]}
                            />
                        </>
                    }
                </>
            )}
            {!!format?.cpfInsuranceEntities &&
            <InsuranceTypes error={errors?.ieId} value={values?.creditPayrollLifeInsurance?.ieId} handleSelect={e => handleChange(e, 5)} />
            }
            <BoxButton>
                <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loadPCE} />
            </BoxButton>
        </Form>
    </Container>
)

EconomicDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
}