import React from 'react'
import PropTypes from 'prop-types'
import { Entypo } from '@expo/vector-icons'
import { ImageBackground, TouchableOpacity, ScrollView } from 'react-native'
import { Container, BoxLogo, Title, Row, SubTitle, BoxFooter, BoxFile, ButtonPrint, InfoView } from './styled'
import { mq, wp } from '../../validation'
import { InputHook } from '../../components/InputHook'
import { TypeContracts } from '../../components/Selects/TypeContracts'
import { InputDateWeb } from '../../components/InputDateWeb'
import { SelectHook } from '../../components/SelectHook'
import { ButtonSubmit } from '../../components/ButtonSubmit'
import theme from '../../../ignore/Constants'
//import Fondo from '../../../assets/img/fondo-login.png'
//import { Logo } from '../../../ignore/Logo'
import { ShadowBox } from '../../generalStyles'
import { Form } from '../Login/styled'
import { ModalCamera } from '../ModalCamera'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { AlertA } from './Alerta/alerta'
import image from '../../../assets/img/image'

export const RegisterUserPayrollV = ({
    data: {
        enrollmentReceipt,
        isVisible,
        document,
        errors,
        values,
        loading,
        navigation,
        categories,
        showFooter,
        dataUP
    },
    handleBlur,
    handleBlurCode,
    handleChange,
    handleSignIn,
    handleDocument,
    dataM,
    dataCompanies
}) => {
    // const Fondo = image('FondoLogin')
    const Logo = image('Logo')
    return (
        <ImageBackground imageStyle={{ backgroundColor: theme.backgroundColor }} resizeMode='stretch' style={{ width: '100%', height: '100%' }}>
            <ModalCamera isVisible={isVisible} navigation={navigation} data={{ screen: 'RegisterPayroll' }} handleClose={handleDocument} />
            <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ padding: mq(8) }}
                keyboardShouldPersistTaps='handled'
            >
                <Container>
                    {/** Logo App */}
                    {showFooter &&
                        <BoxLogo>
                            {/* <Logo color={theme.primaryC} size='100%' /> */}
                            <Logo color={theme.primaryC} width='100%' height='100%' />
                        </BoxLogo>
                    }
                    <Title>Finalizar Registro</Title>
                    <Form style={[ShadowBox]}>
                        <InfoView>
                            {
                                dataM?.getMutualById?.mCompanies === 1 ?
                                    <SelectHook testID='564a1ee4-b615-4ea1-864a-7bc7980979228' data={dataCompanies} title='Seleccionar empresa *' error={errors?.cCode} value={values?.cCode} name='cCode' nameAtt='name' titleModal='Seleccionar Empresa' handleSelect={handleChange} />
                                    :
                                    <InputHook testID='c8bba9a4-252f-4f63-a174-fccadd2f541d' styleContainer={{ width: '90%' }} title='Código de empresa *' keyboardType='numeric' error={errors?.cCode} value={values?.cCode} name='cCode' required numeric range={[1, 4]} onBlur={handleBlurCode} onChangeText={handleChange} />
                            }
                            <ButtonPrint onPress={AlertA}>
                                <FontAwesomeIcon size='1x' icon={faInfo} color={'#fff'} />
                            </ButtonPrint>
                        </InfoView>
                        <Row>
                            <InputHook testID='e2a830cd-44b6-4039-8c03-26d4d50e03b1' flex={.47} title='Primer Nombre *' error={errors?.psFirNam} value={values?.psFirNam} name='psFirNam' required range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />
                            <InputHook testID='c8a9905f-5c36-4350-8269-fab4d062e884' flex={.47} title='Segundo Nombre' error={errors?.psSecNam} value={values?.psSecNam} name='psSecNam' range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />
                        </Row>
                        <Row>
                            <InputHook testID='978be40a-c02f-4147-bbc9-49659fdf448c' flex={.47} title='Primer Apellido *' error={errors?.psFirLas} value={values?.psFirLas} name='psFirLas' required range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />
                            <InputHook testID='078aa858-5b80-4e8f-b6e1-f2d200a8d486' flex={.47} title='Segundo Apellido' error={errors?.psSecLas} value={values?.psSecLas} name='psSecLas' range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />
                        </Row>
                        <InputHook testID='9df287e0-db87-4260-8ebc-73ec9ffabf7a' title='Ocupación *' error={errors?.pwPosition} value={values?.pwPosition} name='pwPosition' required range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />
                        <TypeContracts testID='32848c2e-f5e8-4cf7-b47f-36c72195af77' error={errors?.tcId} value={values?.tcId} handleSelect={handleChange} />
                        <Row>
                            <InputDateWeb testID='e6d48cec-189b-41b5-8d14-547c60758559' flex={values?.tcName?.toLowerCase() === 'temporal' ? .47 : 1} title='Fecha de inicio de contrato *' name='pwDatSta' error={errors?.pwDatSta} value={values?.pwDatSta} onChangeText={handleChange} />
                            {values?.tcName?.toLowerCase() === 'temporal' && <InputDateWeb flex={.47} title='Fecha final del contrato *' name='pwDatEnd' error={errors?.pwDatEnd} value={values?.pwDatEnd} onChangeText={handleChange} />}
                        </Row>
                        <Row>
                            <InputHook testID='3dc6cd64-1b6c-4d4a-93b7-8131227381e9' flex={.47} title='Salario *' keyboardType='numeric' value={values?.pwSalary} name='pwSalary' error={errors?.pwSalary} required format numeric range={[3, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                            <InputHook testID='61fa4448-7489-421f-8c34-12d6878a293c' flex={.47} title='Neto *' keyboardType='numeric' value={values?.pwNet} name='pwNet' error={errors?.pwNet} onBlur={handleBlur} required format numeric range={[1, 15]} onChangeText={handleChange} />
                        </Row>
                        <SelectHook testID='564a1ee4-b615-4ea1-864a-7bc798097922' data={[{ pwEmbargoes: 'SI' }, { pwEmbargoes: 'NO' }]} title='Embargo *' error={errors?.pwEmbargoes} value={values?.pwEmbargoes} name='pwEmbargoes' nameAtt='pwEmbargoes' titleModal='Seleccionar Embargo' handleSelect={handleChange} />
                        {!!categories?.length && <SelectHook title='Categorías de la empresa' data={categories} error={errors.ccId} value={values.ccId} name='ccId' nameAtt='ccName' titleModal='Seleccionar Categoría' handleSelect={handleChange} />}
                        {enrollmentReceipt === 1 &&
                            <BoxFile borderColor={document && theme.primaryC} onPress={handleDocument}>
                                <Entypo name='text-document-inverted' size={24} color={document ? theme.primaryC : theme.onSurfaceC} />
                                <SubTitle testID='dfb197cb-55e6-41b8-b29a-ac3fa2907114' margin={`0 ${wp(2)}px`} family='PFontBold' color={document ? theme.primaryC : theme.onSurfaceC}>{dataUP?.userParameters?.[0]?.nameButton}</SubTitle>
                            </BoxFile>
                        }
                        <ButtonSubmit testID='eaca16fc-7a53-435c-8b89-b9ad8d5c5084' onPress={handleSignIn} title='Regístrese' loading={loading} />
                        <BoxFooter>
                            <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                                <SubTitle>Si estás registrado <SubTitle family='PFontBold' size={wp(3.8)}>inicia sesión</SubTitle></SubTitle>
                            </TouchableOpacity>
                        </BoxFooter>
                    </Form>
                </Container>
            </ScrollView>
        </ImageBackground>
    )
}

RegisterUserPayrollV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleBlurCode: PropTypes.func,
    handleChange: PropTypes.func,
    handleSignIn: PropTypes.func,
    handleDocument: PropTypes.func,
    dataM: PropTypes.object,
    dataCompanies: PropTypes.array,

}