import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { ALL_TYPE_IDENTITIES } from '../../queries/TypeIdentities'
import { SelectHook } from '../SelectHook'

export const TypeIdentities = ({ value, handleSelect, handleCancel, use = {}, disabled, error, testID }) => {
    const [res, setRes] = useState([])
    const [getAllTypeIdentities, { data, loading, called, error: err }] = useLazyQuery(ALL_TYPE_IDENTITIES, { fetchPolicy: 'cache-and-network' })

    useEffect(() => {
        getAllTypeIdentities({ variables: { order: [['tiName', 'ASC']], ...use } })
    }, [])
    useEffect(() => {
        if (data?.typeIdentities && called) setRes(data?.typeIdentities || [])
        if (err) getAllTypeIdentities({ variables: { order: [['tiName', 'ASC']], ...use } })
    }, [data, called, err])

    return (
        <SelectHook
            testID={testID}
            data={res}
            error={error}
            loading={!res?.length && loading}
            title='T. Identificación *'
            value={value}
            name='tiId'
            nameAtt='tiName'
            titleModal='Seleccionar Identificación'
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
        />
    )
}

TypeIdentities.propTypes = {
    value: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    testID: PropTypes.string
}