import styled from 'styled-components/native'
import { mq } from '../../validation'

export const Container = styled.View`
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.NoAuthorize};
`
export const ButtonContainer = styled.View`
    width: 90%;
    align-items: center;
    margin: ${mq(10)}px 0;
`
export const Title = styled.Text`
    text-align: center;
    margin: ${mq(10)}px 0;
    padding: 0 ${mq(20)}px;
    font-family: PFontBold;
    font-size: ${mq(18)}px;
    color: ${({ color, theme }) => color || theme.White};
`