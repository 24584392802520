import React from 'react'
import PropTypes from 'prop-types'
import ModalNative from 'react-native-modal'
import { wp } from '../../validation'

export const Modal = ({ isVisible, children, style, animationOut, animationIn, animationInTiming, animationOutTiming, marginVertical = 0 }) => (
    <ModalNative
        style={[{ marginVertical, padding: 0, justifyContent: 'flex-end', width: wp(100), marginHorizontal: 'auto' }, style]}
        isVisible={isVisible}
        animationIn={animationIn || 'zoomIn'}
        animationInTiming={animationInTiming || 800}
        animationOut={animationOut || 'zoomOut'}
        animationOutTiming={animationOutTiming || 800}
    >
        {children}
    </ModalNative>
)

Modal.propTypes = {
    isVisible: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object,
    animationOut: PropTypes.string,
    animationIn: PropTypes.string,
    animationInTiming: PropTypes.string,
    animationOutTiming: PropTypes.string,
    marginVertical: PropTypes.number
}