import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { USE_ALL_CITIES } from '../../queries/Cities';
import { SelectHook } from '../SelectHook';

export const Cities = ({
    testID,
    title,
    value,
    name,
    handleSelect,
    handleCancel,
    use = {},
    disabled,
    error,
}) => {
    const [res, setRes] = useState([]);
    const { data, loading } = USE_ALL_CITIES({
        order: [['cName', 'ASC']],
        ...use,
    });

    useEffect(() => {
        if (data?.cities && !loading) {
            let isNameInKeysCity = false;

            const city = data?.cities?.find(x => x);

            for (const key in city) {
                if (key === name) {
                    isNameInKeysCity = true;
                }
            }

            if (name && !isNameInKeysCity) {
                setRes(data?.cities?.map(x => ({ ...x, [name]: x?.cId })));
            } else {
                setRes(data?.cities || []);
            }
        }
    }, [data]);

    return (
        <SelectHook
            data={res}
            error={error}
            loading={loading}
            title={title || 'Ciudad *'}
            value={value}
            name={name || 'cId'}
            nameAtt="cName"
            titleModal="Seleccionar Ciudad"
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
            testID={testID}
        />
    );
};

Cities.propTypes = {
    testID: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
};