import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import { FontAwesome, MaterialCommunityIcons, Entypo, Octicons, FontAwesome5 } from '@expo/vector-icons'
/** personal imports */
import { InputHook } from '../InputHook'
import { InputDateWeb } from '../InputDateWeb'
import { ButtonSubmit } from '../ButtonSubmit'
import { AcademicLevels } from '../Selects/AcademicLevels'
import { TypeIdentities } from '../Selects/TypeIdentities'
import { Genders } from '../Selects/Genders'
import { Dependents } from '../Selects/Dependents'
import { MaritalStatus } from '../Selects/MaritalStatus'
import { TypeHousings } from '../Selects/TypeHousings'
import { TypeAddress } from '../Selects/TypeAddress'
import { TypeContracts } from '../Selects/TypeContracts'
import { Cities } from '../Selects/Cities'
import { Container, Form, Row, BoxButton } from './styled'
import { ViewPhoto } from '../ViewPhoto'
import theme from '../../../ignore/Constants'
import { hp } from '../../validation'

export const CoDebtorDataRegisterV = ({ data: { banner, values, infoCamera, files, loading, format, fileBD, fileFD, fileLD, filePD, fileRD, fileSD, loadSD, loadRD, loadPD, loadLD, loadFD, loadBD }, handleInfo, handleSubmit, handleButton, handleChange, handleBlur }) => (
    <Container>
        {banner}
        <Form>
            {!!format?.cpfcdTipIde && <TypeIdentities value={values?.creditPayrollCoDebtors?.tiId} handleSelect={handleChange} />}
            {!!format?.cpfcdIdeNum && <InputHook title='Número de identificación *' returnKeyType='next' keyboardType='numeric' value={values?.creditPayrollCoDebtors?.cpcdIdeNum} name='cpcdIdeNum' required numeric format range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
            {(!!format?.cpfcdFirNam || !!format?.cdSecNam) &&
                <Row>
                    {!!format?.cpfcdFirNam && <InputHook flex={format?.cpfcdFirNam ? .47 : 1} title='Primer Nombre *' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdFirNam} name='cpcdFirNam' required letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cpfcdSecNam && <InputHook flex={format?.cpfcdSecNam ? .47 : 1} title='Segundo Nombre' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdSecNam} name='cpcdSecNam' letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {(!!format?.cpfcdFirLas || !!format?.cpfcdSecLas) &&
                <Row>
                    {!!format?.cpfcdFirLas && <InputHook flex={format?.cpfcdSecLas ? .47 : 1} title='Primer Apellido *' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdFirLas} name='cpcdFirLas' required letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cpfcdSecLas && <InputHook flex={format?.cpfcdFirLas ? .47 : 1} title='Segundo Apellido' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdSecLas} name='cpcdSecLas' letters range={[3, 50]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {(!!format?.cpfcdDatBir || !!format?.cpfcdGender) &&
                <Row>
                    {!!format?.cpfcdDatBir && <InputDateWeb flex={format?.cpfcdGender ? .47 : 1} title='Fecha de Nacimiento *' name='cpcdDatBir' required value={values?.creditPayrollCoDebtors?.cpcdDatBir} onChangeText={handleChange} />}
                    {!!format?.cpfcdGender && <View style={{ flex: format?.cpfcdDatBir ? .47 : 1 }}><Genders value={values?.creditPayrollCoDebtors?.gId} handleSelect={handleChange} /></View>}
                </Row>
            }
            {(!!format?.cpfcdPhoMob || !!format?.cpfcdPhoPer) &&
                <Row>
                    {!!format?.cpfcdPhoMob && <InputHook flex={format?.cpfcdPhoPer ? .47 : 1} title='Número Teléfono Móvil *' keyboardType='numeric' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdPhoMob} name='cpcdPhoMob' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cpfcdPhoPer && <InputHook flex={format?.cpfcdPhoMob ? .47 : 1} title='Número Teléfono Fijo *' keyboardType='numeric' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdPhoPer} name='cpcdPhoPer' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {!!format?.cpfcdEmail && <InputHook title='Correo Electrónico *' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdEmail} name='cpcdEmail' keyboardType='email-address' required email range={[5, 100]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cpfcdLocation && <>
                <Row>
                    <View style={{ flex: .47 }}><Cities value={values?.creditPayrollCoDebtors?.cId} handleSelect={handleChange} /></View>
                    <View style={{ flex: .47 }}><TypeAddress value={values?.creditPayrollCoDebtors?.taId} handleSelect={handleChange} /></View>
                </Row>
                <Row>
                    <InputHook flex={.4} title='No. Dirección *' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdNumDir} name='cpcdNumDir' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook flex={.25} title='No. *' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdNum} name='cpcdNum' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    <InputHook flex={.25} title='No. Casa *' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdNumHou} name='cpcdNumHou' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                </Row>
                <InputHook title='Información Residencial (Opcional)' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdInf} name='cpcdInf' range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            </>}
            {(!!format?.cpfcdAcaLev || !!format?.cpfcdMarSta) &&
                <Row>
                    {!!format?.cpfcdAcaLev && <View style={{ flex: format?.cpfcdMarSta ? .47 : 1 }}><AcademicLevels value={values?.creditPayrollCoDebtors?.alId} handleSelect={handleChange} /></View>}
                    {!!format?.cpfcdMarSta && <View style={{ flex: format?.cpfcdAcaLev ? .47 : 1 }}><MaritalStatus value={values?.creditPayrollCoDebtors?.msId} handleSelect={handleChange} /></View>}
                </Row>
            }
            {(!!format?.cpfcdTypHou || !!format?.cpfcdDependents) &&
                <Row>
                    {!!format?.cpfcdTypHou && <View style={{ flex: format?.cpfcdDependents ? .47 : 1 }}><TypeHousings value={values?.creditPayrollCoDebtors?.thId} handleSelect={handleChange} /></View>}
                    {!!format?.cpfcdDependents && <View style={{ flex: format?.cpfcdTypHou ? .47 : 1 }}><Dependents value={values?.creditPayrollCoDebtors?.dId} handleSelect={handleChange} /></View>}
                </Row>
            }
            {!!format?.cpfcdCompany && <InputHook title='Nombre de la Empresa *' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdCompany} name='cpcdCompany' required range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cpfcdPosition && <InputHook title='Cargo en la Empresa *' returnKeyType='next' value={values?.creditPayrollCoDebtors?.cpcdPosition} name='cpcdPosition' required range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />}
            {!!format?.cpfcdTypCon && <TypeContracts value={values?.creditPayrollCoDebtors?.tcId} handleSelect={handleChange} />}
            {(!!format?.cpfcdTypCon) &&
                <Row>
                    <InputDateWeb flex={.47} title='Fecha de Inicio del Contrato *' name='cpcdDatSta' required value={values?.creditPayrollCoDebtors?.cpcdDatSta} onChangeText={handleChange} />
                    <InputDateWeb flex={.47} title='Fecha de Final del Contrato (Opcional)' name='cpcdDatEnd' value={values?.creditPayrollCoDebtors?.cpcdDatEnd} onChangeText={handleChange} />
                </Row>
            }
            {(!!format?.cpfcdSalary || !!format?.cpfcdOthInc) &&
                <Row>
                    {!!format?.cpfcdSalary && <InputHook flex={format?.cpfcdOthInc ? .47 : 1} title='Salario *' returnKeyType='next' keyboardType='numeric' value={values?.creditPayrollCoDebtors?.cpcdSalary} name='cpcdSalary' required numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cpfcdOthInc && <InputHook flex={format?.cpfcdSalary ? .47 : 1} title='Otros Ingresos (Opcional)' returnKeyType='next' keyboardType='numeric' value={values?.creditPayrollCoDebtors?.cpcdOthInc} name='cpcdOthInc' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {(!!format?.cpfcdFinExp || !!format?.cpfcdPerExp) &&
                <Row>
                    {!!format?.cpfcdFinExp && <InputHook flex={format?.cpfcdPerExp ? .47 : 1} title='Gastos Financieros (Opcional)' returnKeyType='next' keyboardType='numeric' value={values?.creditPayrollCoDebtors?.cpcdFinExp} name='cpcdFinExp' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                    {!!format?.cpfcdPerExp && <InputHook flex={format?.cpfcdFinExp ? .47 : 1} title='Gastos Personales (Opcional)' returnKeyType='next' keyboardType='numeric' value={values?.creditPayrollCoDebtors?.cpcdPerExp} name='cpcdPerExp' numeric format range={[1, 15]} onBlur={handleBlur} onChangeText={handleChange} />}
                </Row>
            }
            {!!format?.cpfcdFroDoc && <ViewPhoto
                title='Cédula frontal'
                loadingDoc={loadFD}
                nameFile={values?.creditPayrollCoDebtors?.cpcdFroDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.cpcdFroDocA || fileFD}
                icon={<FontAwesome name='vcard' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(1)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpcdFroDoc', 'cpcdFroDocA')}
            />}
            {!!format?.cpfcdReaDoc && <ViewPhoto
                title='Cédula por detrás'
                loadingDoc={loadRD}
                nameFile={values?.creditPayrollCoDebtors?.cpcdReaDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.cpcdReaDocA || fileRD}
                icon={<MaterialCommunityIcons name='card-bulleted' size={hp(26)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(2)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpcdReaDoc', 'cpcdReaDocA')}
            />}
            {!!format?.cpfcdPerDoc && <ViewPhoto
                title='Selfie con Cédula frontal'
                loadingDoc={loadPD}
                nameFile={values?.creditPayrollCoDebtors?.cpcdPerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.cpcdPerDocA || filePD}
                icon={<Entypo name='v-card' size={hp(24)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(3)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpcdPerDoc', 'cpcdPerDocA')}
            />}
            {!!format?.cpfcdSerDoc && <ViewPhoto
                title={`Servicios Públicos${'\n'} (Luz, Agua o Gas)`}
                loadingDoc={loadSD}
                nameFile={values?.creditPayrollCoDebtors?.cpcdSerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.cpcdSerDocA || fileSD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(4)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpcdSerDoc', 'cpcdSerDocA')}
            />}
            {!!format?.cpfcdBanDoc && <ViewPhoto
                title='Certificado Bancario'
                loadingDoc={loadBD}
                nameFile={values?.creditPayrollCoDebtors?.cpcdBanDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.cpcdBanDocA || fileBD}
                icon={<FontAwesome5 name='file-invoice' size={hp(17)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(5)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpcdBanDoc', 'cpcdBanDocA')}
            />}
            {!!format?.cpfcdLoaDoc && <ViewPhoto
                title='Certificado Laboral'
                loadingDoc={loadLD}
                nameFile={values?.creditPayrollCoDebtors?.cpcdLoaDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                uri={files?.cpcdLoaDocA || fileLD}
                icon={<MaterialCommunityIcons name='file-cad-box' size={hp(23)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(6)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpcdLoaDoc', 'cpcdLoaDocA')}
            />}
            <BoxButton>
                <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

CoDebtorDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleButton: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func
}