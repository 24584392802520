import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Capa_1"
        x={0}
        y={0}
        style={{
            enableBackground: 'new 0 0 392 392',
        }}
        viewBox="0 0 392 392"
        {...props}
    >
        <Circle
            cx={196}
            cy={196}
            r={190.5}
            style={{
                opacity: 0.2,
                fill: '#7c11c2',
                enableBackground: 'new',
            }}
        />
        <Circle
            cx={196}
            cy={196}
            r={155.8}
            style={{
                opacity: 0.4,
                fill: '#5716bb',
                enableBackground: 'new',
            }}
        />
        <Circle
            cx={196}
            cy={196}
            r={119.3}
            style={{
                fill: '#7c11c2',
            }}
        />
        <Path
            d="M159.2 206.5v-21c0-.4.2-.8.5-1.1s.7-.5 1.1-.5h29.6c.4 0 .8.2 1.1.5s.5.7.5 1.1v1.6c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-24.8v4.9h22.8c.4 0 .8.2 1.1.5s.5.7.5 1.1v1.6c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-22.8v5h24.8c.4 0 .8.2 1.1.5s.5.7.5 1.1v1.6c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-29.5c-.4 0-.8-.2-1.1-.5-.5-.4-.6-.8-.6-1.2M105.9 206.5v-21c0-.4.2-.8.5-1.1s.7-.5 1.1-.5h19.4c.4 0 .8.2 1.1.5.3.3.5.7.5 1.1v1.6c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-14.7v5.9h11.9c.4 0 .8.2 1.1.5.3.3.5.7.5 1.1v1.6c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-11.9v7.1c0 .5-.2.8-.5 1.2-.3.3-.7.5-1.1.5h-3.2c-.4 0-.8-.2-1.1-.5-.3-.4-.4-.8-.4-1.2M137.6 194.5h7.2c1.2 0 2.2-.2 3-.7.9-.5 1.3-1.2 1.3-2.2s-.4-1.7-1.3-2.2c-.9-.5-1.9-.7-3-.7h-7.2v5.8zm18.7 12.9c0 .5-.3.7-.9.7h-4c-.4 0-.9-.2-1.4-.5s-.9-.7-1.2-1.1L146 202c-1.2-1.8-2.6-2.8-4.3-2.8h-4.1v7.2c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-3.2c-.4 0-.8-.2-1.1-.5s-.5-.7-.5-1.1v-21c0-.4.2-.8.5-1.1s.7-.5 1.1-.5h13.9c2.6 0 4.7.7 6.3 2.1 1.7 1.4 2.5 3.2 2.5 5.6 0 3.3-2.1 5.3-6.4 6.1.7.2 1.3.7 1.9 1.3.6.6 1.2 1.5 2 2.6l3.1 4.9c.2.4.2.7.2 1M231.2 206.5v-21c0-.4.2-.8.5-1.1s.7-.5 1.1-.5h5c.4 0 .8.2 1.2.5s.8.7 1.1 1.1l10.2 15.7v-15.7c0-.4.2-.8.5-1.1s.7-.5 1.1-.5h2.9c.4 0 .8.2 1.1.5s.5.7.5 1.1v21c0 .4-.2.8-.5 1.1-.3.3-.7.5-1.1.5h-5.6c-.4 0-.9-.2-1.3-.5-.5-.3-.8-.7-1.1-1.1l-9.3-14.5v14.5c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-3c-.4 0-.8-.2-1.1-.5-.4-.3-.6-.7-.6-1.1M267.5 203.3h4.5c3 0 5.1-.6 6.2-1.7 1.1-1.1 1.6-3 1.6-5.6 0-2.7-.5-4.6-1.6-5.7-1.1-1.1-3.1-1.7-6.2-1.7h-4.5v14.7zm-6.4 3.2v-21c0-.5.2-.8.5-1.2.3-.3.7-.5 1.1-.5h9.4c4.9 0 8.4 1 10.6 2.9s3.3 5 3.3 9.2-1.1 7.2-3.3 9.1c-2.2 1.9-5.8 2.9-10.6 2.9h-9.4c-.4 0-.8-.1-1.1-.4-.3-.2-.5-.5-.5-1M195.1 206.5v-21c0-.4.2-.8.5-1.1s.7-.5 1.1-.5h29.6c.4 0 .8.2 1.1.5s.5.7.5 1.1v1.6c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-24.8v4.9h22.8c.4 0 .8.2 1.1.5s.5.7.5 1.1v1.6c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-22.8v5h24.8c.4 0 .8.2 1.1.5s.5.7.5 1.1v1.6c0 .4-.2.8-.5 1.1s-.7.5-1.1.5h-29.5c-.4 0-.8-.2-1.1-.5-.4-.4-.6-.8-.6-1.2"
            className="st3"
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}