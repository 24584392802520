import React, { useState, useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import moment from 'moment'
import { RepaymentPlanV } from '../../components/RepaymentPlan'
import { USE_ONE_CREDIT_INFO, CALCULATE_FEE } from './queries'

export const RepaymentPlanC = ({ navigation, route }) => {
    const [credit, setCredit] = useState({})
    const [see, setSee] = useState(false)
    const [table, setTable] = useState(true)
    const [getOneCredit, { data, loading, called }] = useLazyQuery(USE_ONE_CREDIT_INFO, { fetchPolicy: 'cache-and-network' })
    const { data: dataCalculateFee } = useQuery(CALCULATE_FEE, { variables: { cId: route?.params?.cId, feeQuantity: 1 }, fetchPolicy: 'no-cache' })

    // Activa un evento para buscar el perfil del usuario
    useEffect(() => {
        // crea un evento para consultar el perfil del usuario
        const unsubscribe = navigation.addListener('focus', () => getOneCredit({ variables: { cId: route?.params?.cId, state: [route?.params?.cState] } }))

        // elimina el evento
        return unsubscribe
    }, [navigation, getOneCredit, route])

    useEffect(() => {
        if (called) {
            const res = data?.getCreditById
            if (res) {
                // variables
                let totalAtt = 0
                let repaymentPlan = []
                let startBalance = res.cValCre
                let endBalance = res.cValCre
                let capital = 0
                let month = res.creditFees.length

                // calcula el % de interés del inversionista
                const porCre = res.cModule === 1 ? ((res.cDayCou || 30) / 30) * (res.cRatVal / 100) : res.cRatVal / 100
                const porVal = (res.cRatVal / 100)
                const por = porVal >= porCre ? porCre : porCre - (porCre - porVal)

                // calcula el total de los atributos
                res.creditAttributeFees.forEach(x => (totalAtt += x.cafAmount))
                const firDate = moment(res.cFirFee)

                // Recorre todo los pagos de las cuotas
                res.creditFees.forEach((x, i) => {
                    // suma el total de balance final
                    endBalance -= x.cfCapital
                    const dateFirFee = (res?.cModule === 2 && res?.cType === 2) ? moment(res.cFirFee).add('weeks', i) : moment(res.cFirFee).add('months', i)

                    // registra el plan de amortización
                    repaymentPlan = [...repaymentPlan, {
                        index: i,
                        moment: i === 0 ? firDate.format('YYYY-MM-DD') : dateFirFee.format('YYYY-MM-DD'),
                        date: i === 0 ? firDate.format((res?.cModule === 2 && res?.cType === 2) ? 'YYYY-MM-DD' : 'YYYY-MM-DD')?.toUpperCase() : dateFirFee.format((res?.cModule === 2 && res?.cType === 2) ? 'YYYY-MM-DD' : 'YYYY-MM-DD')?.toUpperCase(),
                        startBalance: i === 0 ? res.cValCre : startBalance > 10 ? startBalance : 0,
                        month: x.cfMonth || 'Abono',
                        capital: x.cfCapital,
                        interest: x.creditFeeAttributes.find(y => res.creditAttributes.find(z => z.caId === y.caId && !z.caFormula && !z.caType))?.cfaAmount || 0,
                        endBalance: endBalance > 10 ? endBalance : 0,
                        fee: true
                    }]

                    // suma el total de balance inicial
                    startBalance -= x.cfCapital
                })

                // recorre según la cantidad de pagos restantes
                for (let i = (res.cValCre - startBalance); i < (res.cValCre - 10); i += capital) {
                    // calcula el interés y el capital
                    const interest = Math.round(endBalance * por)
                    capital = Math.round(res.cValCou - ((endBalance * porCre) + totalAtt))

                    // suma el total de balance final y suma un mes
                    endBalance -= capital
                    month++
                    const dateFirFee = (res?.cModule === 2 && res?.cType === 2) ? moment((repaymentPlan.length && repaymentPlan[repaymentPlan.length - 1].moment) || res.cFirFee).add(repaymentPlan.length ? 1 : 0, 'weeks') : moment((repaymentPlan.length && repaymentPlan[repaymentPlan.length - 1].moment) || res.cFirFee).add(repaymentPlan.length ? 1 : 0, 'months')

                    // registra el plan de amortización
                    repaymentPlan = [...repaymentPlan, {
                        index: (repaymentPlan.length ? repaymentPlan[repaymentPlan.length -1].index : 0) + 1,
                        moment: i === 0 ? firDate.format('YYYY-MM-DD') : dateFirFee.format('YYYY-MM-DD'),
                        date: i === 0 ? firDate.format((res?.cModule === 2 && res?.cType === 2) ? 'YYYY-MM-DD' : 'YYYY-MM-DD')?.toUpperCase() : dateFirFee.format((res?.cModule === 2 && res?.cType === 2) ? 'YYYY-MM-DD' : 'YYYY-MM-DD')?.toUpperCase(),
                        startBalance: i === 0 ? res.cValCre : startBalance > 10 ? startBalance : 0,
                        arrears: dateFirFee.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'),
                        month,
                        capital,
                        interest,
                        endBalance: endBalance > 10 ? endBalance : 0
                    }]

                    // suma el total de balance inicial
                    startBalance -= capital
                }
                setCredit({
                    cModule: res.cModule,
                    cType: res.cType,
                    cName: res.cName,
                    cReqVal: res.cReqVal,
                    cRatVal: res.cRatVal,
                    cConsecutive: res.cConsecutive,
                    cValCre: res.cValCre,
                    cValCou: res.cValCou,
                    cState: res.cState,
                    por,
                    cFirFee: res.cFirFee,
                    feeLength: `${res.creditFees.length < 10 ? `0${res.creditFees.length}` : res.creditFees.length}/${res?.cMon < 10 ? `0${res?.cMon}` : res?.cMon}`,
                    repaymentPlan,
                    creditFormat: res.creditFormat
                })
            }
        }
    }, [data, called])

    return (
        <RepaymentPlanV
            data={{
                see,
                table,
                credit,
                calculateFee: dataCalculateFee?.CalculateFee,
                loading: route?.params?.cId !== credit?.cId && loading,
            }}
            handleOpen={() => setSee(!see)}
            changeTable={() => setTable(!table)}
        />
    )
}

RepaymentPlanC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}