import { gql, useQuery } from '@apollo/client'

export const USE_ALL_ACADEMIC_LEVELS = ({ state, order, limit }) => useQuery(gql`
    query getAllAcademicLevels($state: [Int], $order: [[String]], $limit: [Int]) {
        academicLevels(state: $state, order: $order, limit: $limit) {
            alId
            alName
        }
    }
`, {
    variables: { state, order, limit }
})