import { useMutation, useLazyQuery } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components, Utils, Queries
import { InformationAdditionalCreditLifeInsuranceV } from '../../components/InformationAdditionalCreditLifeInsurance'
import { EDIT_CREDIT_PAYROLL, EDIT_PAYROLL, ONE_CREDIT_PAYROLL_COMPANY, ONE_CREDIT_PAYROLL_FORMAT } from './queries'
import { filterKeyObject, validationErrors } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'

export const InformationAdditionalCreditLifeInsuranceC = ({ navigation, route }) => {
    const [errorFormStaff, setErrorFormStaff] = useState({})
    const [errorFormLife, setErrorFormLife] = useState({})
    const [valuesStaff, setValuesStaff] = useState({})
    const [valuesLife, setValuesLife] = useState({})
    const [payroll, setPayroll] = useState({})
    const [format, setFormat] = useState({})

    // Queries, Mutation
    const [getOneCreditPayrollCompany, { data: dataCPC, error: errCPC, called: calCPC, loading: loadCPC }] = useLazyQuery(ONE_CREDIT_PAYROLL_COMPANY, { fetchPolicy: 'cache-and-network' })
    const [getOneCreditPayrollFormat, { data: dataCPF, loading: loadCPF, called: calCPF, error: errCPF }] = useLazyQuery(ONE_CREDIT_PAYROLL_FORMAT, { fetchPolicy: 'cache-and-network' })
    const [editCreditPayrollInfo, { loading: loadMutation }] = useMutation(EDIT_CREDIT_PAYROLL)
    const [editPayrollInfo, { loading: loadMutationPayroll }] = useMutation(EDIT_PAYROLL, { errorPolicy: 'ignore' })

    // Activa un evento para buscar el perfil del usuario
    useEffect(() => {
        // crea un evento para consultar el perfil del usuario
        const unsubscribe = navigation.addListener('focus', () => {
            getOneCreditPayrollCompany({ variables: { cId: route?.params?.cId, state: [0, 1, 2, 3] } })
            getOneCreditPayrollFormat({ variables: { cId: route?.params?.cId } })
        })

        // elimina el evento
        return unsubscribe
    }, [navigation, getOneCreditPayrollCompany])

    // Obtiene la información de formato de usuario
    useEffect(() => {
        const res = dataCPF?.getCreditPayrollFormatById
        if (res && calCPF) {
            if (res.cpfSons === 1 || res.cpfMotHeaHou === 1 || res.cpfWeight === 1 || res.cpfHeight === 1 || res.cpfCancer === 1 || res.cpfVIH === 1 ||
                res.cpfStroke === 1 || res.cpfRenIns === 1 || res.cpfArtHyp === 1 || res.cpfDiabetes === 1 || res.cpfCorArtDis === 1 || res.cpfHeaAtt === 1 ||
                res.cpfHasConAcqPhyMenLim === 1 || res.cpfDiaTreDisOthThoMenAbo === 1 || res.cpfSons === 1 || res.cpfDatDia === 1 || res.cpfTreatment === 1 ||
                res.cpfActSta === 1 || res.cpfIsPre === 1 || res.cpfPregnancy === 1) setFormat(res)
            else navigation.navigate('InformationAdditionalCreditSarlaft', route.params)
        }

        // verifica el error
        if (errCPF) {
            setFormat({})
            isLoggedVar({ ...isLoggedVar(), message: 'No se ha encontrado ningún formato disponible del crédito.', code: 404 })
        }
    }, [dataCPF, calCPF, errCPF])

    // Obtiene la información del usuario
    useEffect(() => {
        const res = dataCPC?.getCreditPayrollCompanyById
        if (res && calCPC && format?.cpfId) {
            setPayroll(res)
            setValuesStaff(res.creditPayrollStaff || {})
            setValuesLife(res.creditPayrollLifeInsurance || {})
            setErrorFormStaff({
                cpsSons: (format.cpfSons && !res.creditPayrollStaff?.cpsSons),
                cpsWeight: (format.cpfWeight && !res.creditPayrollStaff?.cpsWeight),
                cpsHeight: (format.cpfHeight && !res.creditPayrollStaff?.cpsHeight),
                cpsMotHeaHou: (format.cpfMotHeaHou && !res.creditPayrollStaff?.cpsMotHeaHou),
            })
            setErrorFormLife({
                cpliVIH: (format.cpfVIH && !res.creditPayrollLifeInsurance?.cpliVIH),
                cpliCancer: (format.cpfCancer && !res.creditPayrollLifeInsurance?.cpliCancer),
                cpliStroke: (format.cpfStroke && !res.creditPayrollLifeInsurance?.cpliStroke),
                cpliRenIns: (format.cpfRenIns && !res.creditPayrollLifeInsurance?.cpliRenIns),
                cpliArtHyp: (format.cpfArtHyp && !res.creditPayrollLifeInsurance?.cpliArtHyp),
                cpliHeaAtt: (format.cpfHeaAtt && !res.creditPayrollLifeInsurance?.cpliHeaAtt),
                cpliDiabetes: (format.cpfDiabetes && !res.creditPayrollLifeInsurance?.cpliDiabetes),
                cpliCorArtDis: (format.cpfCorArtDis && !res.creditPayrollLifeInsurance?.cpliCorArtDis),
                cpliHasConAcqPhyMenLim: (format.cpfHasConAcqPhyMenLim && !res.creditPayrollLifeInsurance?.cpliHasConAcqPhyMenLim),
                cpliDiaTreDisOthThoMenAbo: (format.cpfDiaTreDisOthThoMenAbo && !res.creditPayrollLifeInsurance?.cpliDiaTreDisOthThoMenAbo),
            })
        }

        // verifica el error
        if (errCPC) {
            setValuesLife({})
            setValuesStaff({})
            setErrorFormLife({})
            setErrorFormStaff({})
            isLoggedVar({ ...isLoggedVar(), message: 'No se ha encontrado la información del cliente.', code: 404 })
        }
    }, [dataCPC, calCPC, errCPC, format])

    /**
     * Evento de los select o input
     * @param {*} e Valores del evento
     * @param {*} type Tipo de formulario
     * @return {void}
     */
    const handleChange = (e, type) => {
        if (type === 'staff') {
            setValuesStaff({ ...valuesStaff, [e.name]: e.value })
            setErrorFormStaff({ ...errorFormStaff, [e.name]: e.error })
        } else if (type === 'life') {
            setValuesLife({ ...valuesLife, [e.name]: e.value })
            setErrorFormLife({ ...errorFormLife, [e.name]: e.error })
        }
    }

    /**
     * Registra o actualiza la información del seguro de vida
     * @return {void}
     */
    const handleSubmit = async () => {
        if (loadCPC || loadCPF || loadMutation || loadMutationPayroll) return isLoggedVar({ ...isLoggedVar(), message: 'Espere por favor.' })
        /** verifica si los campos están rellenos */
        if (validationErrors(errorFormStaff) || validationErrors(errorFormLife)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        // Actualiza La información personal y la del seguro de vida del credito
        const { error: errCPI } = await editCreditPayrollInfo({
            variables: {
                cpcId: payroll.cpcId,
                inputStaff: filterKeyObject(valuesStaff, ['__typename']),
                inputLife: filterKeyObject(valuesLife, ['__typename'])
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (errCPI) return isLoggedVar({ ...isLoggedVar(), message: errCPI.message })

        // Actualiza la información personal y la del seguro de vida del cliente
        await editPayrollInfo({
            variables: {
                pcId: payroll.pcId,
                inputStaff: {
                    psSons: valuesStaff.cpsSons,
                    psWeight: valuesStaff.cpsWeight,
                    psHeight: valuesStaff.cpsHeight,
                    psMotHeaHou: valuesStaff.cpsMotHeaHou,
                },
                inputLife: {
                    ptId: valuesLife.ptId,
                    pliVIH: valuesLife.cpliVIH,
                    pliIsPre: valuesLife.cpliIsPre,
                    pliCancer: valuesLife.cpliCancer,
                    pliStroke: valuesLife.cpliStroke,
                    pliRenIns: valuesLife.cpliRenIns,
                    pliArtHyp: valuesLife.cpliArtHyp,
                    pliHeaAtt: valuesLife.cpliHeaAtt,
                    pliDatDia: valuesLife.cpliDatDia,
                    pliActSta: valuesLife.cpliActSta,
                    pliDiabetes: valuesLife.cpliDiabetes,
                    pliCorArtDis: valuesLife.cpliCorArtDis,
                    pliTreatment: valuesLife.cpliTreatment,
                    pliPrimitive: valuesLife.cpliPrimitive,
                    pliHasConAcqPhyMenLim: valuesLife.cpliHasConAcqPhyMenLim,
                    pliDiaTreDisOthThoMenAbo: valuesLife.cpliDiaTreDisOthThoMenAbo,
                }
            }
        }).catch(e => ({ error: e }))

        /** resultado */
        navigation.navigate('InformationAdditionalCreditSarlaft', route.params)
        isLoggedVar({ ...isLoggedVar(), message: 'Se ha agregado la información de seguro de vida.' })
    }

    return (
        <InformationAdditionalCreditLifeInsuranceV
            data={{
                format,
                payroll,
                valuesLife,
                valuesStaff
            }}
            loadings={{
                loadCPC,
                loadCPF,
                loadMutation: loadMutationPayroll || loadMutation,
            }}
            errors={{
                errorFormLife,
                errorFormStaff
            }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

InformationAdditionalCreditLifeInsuranceC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}