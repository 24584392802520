import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { wp } from '../../validation'

export const Container = styled.View`
    flex: 1;
    background-color: ${theme.backgroundP};
    margin: ${wp(5)}px;
    border-top-right-radius: ${wp(5)}px;
    border-top-left-radius: ${wp(5)}px;
`
export const BoxHead = styled.View`
    flex-direction: row;
    background-color: ${theme.primaryC};
    padding: ${wp(4)}px ${wp(2)}px;
    border-top-right-radius: ${wp(5)}px;
    border-top-left-radius: ${wp(5)}px;
`
export const TitleHead = styled.Text`
    font-size: ${wp(5)}px;
    font-family: PFontBold;
    color: ${theme.backgroundC};
`
export const BoxMargin = styled.View`
    margin: ${wp(4)}px 0;
`
export const Title = styled.Text`
    font-size: ${wp(4.5)}px;
    font-family: PFontBold;
    color: ${theme.onSurfaceC};
    text-align: justify;
`
export const SubTitle = styled.Text`
    font-size: ${wp(3.5)}px;
    font-family: ${({ family }) => family || 'PFontRegular'};
    color: ${({ color }) => color || theme.onSurfaceC};
    text-align: ${({ align }) => align || 'justify'};
`
export const ButtonCheck = styled.TouchableOpacity`
    flex-direction: row;
    margin-bottom: ${wp(4)}px;
    align-items: center;
    justify-content: center;
`
export const Check = styled.View`
    padding: ${wp(3)}px;
    margin-right: ${wp(2)}px;
    border: 2px solid ${theme.onSurfaceC};
    border-radius: ${wp(2)}px;
`