/**
 * calcula el valor del crédito de cartera
 * @param {object} args argumentos
 * @return {object} nuevos argumentos
 */
export function calculatorCredit ({ attributes, valCouTot, iva, mon, rPor }) {
    const por = rPor / 100
    let valCou = valCouTot
    let tlm
    let credits = []
    let fees = []

    /** verifica los atributos de la cuota */
    for (let i = 0; i < attributes?.length; i++) {
        /** almacena en una variable más corta los valores del crédito */
        const { typeAttribute: ta } = attributes[i]
        if (ta?.taType === 3) continue
        /** formulas */
        if (ta.taFormula === 1) {
            /** Calcula el valor */
            const value = ta.taValue * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** iva o sin  iva */
            if (ta.taType === 2) {
                valCou -= value
                fees = [...fees, { name: ta.taName, value }]
            }
        } if (ta.taFormula === 3) {
            /** Calcula el valor */
            const value = (ta.salary.sValue * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 2) {
                valCou -= value
                fees = [...fees, { name: ta.taName, value }]
            }
        }
    }

    /** calcula el valor del crédito */
    const valCre = (valCou * (1 - Math.pow(1 + por, -mon))) / por
    let reqVal = valCre

    /** verifica los atributos del crédito */
    for (let i = 0; i < attributes?.length; i++) {
        /** almacena en una variable más corta los valores del crédito */
        const { typeAttribute: ta } = attributes[i]
        /** formulas */
        if (ta.taFormula === 1) {
            /** Calcula el valor */
            const value = ta.taValue * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** iva o sin  iva */
            if (ta.taType === 1) {
                reqVal -= value
                credits = [...credits, { name: ta.taName, value }]
            }
        } else if (ta.taFormula === 3) {
            /** Calcula el valor */
            const value = (ta.salary.sValue * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) {
                reqVal -= value
                credits = [...credits, { name: ta.taName, value }]
            }
        } else if (ta.taFormula === 4) tlm = ta
        else if (ta.taFormula === 8) {
            /** Calcula el valor */
            const value = (valCre * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) {
                reqVal -= value
                credits = [...credits, { name: ta.taName, value }]
            }
        } else if (ta.taFormula === 9) {
            /** Calcula el valor */
            const value = ((valCre) * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            const minimum = ta.taMinimum * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** Busca el % comparado al desembolso */
            if (ta.taType === 1) {
                reqVal -= value > ta.taMinimum ? value : minimum
                credits = [...credits, { name: ta.taName, value: value > ta.taMinimum ? value : minimum }]
            }
        } else if (ta.taFormula === 10) {
            /** Calcula el valor */
            const value = ((ta.typeBond.bonds[0].bPor / 100) * valCre) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** Busca el % comparado al desembolso */
            if (ta.taType === 1) {
                reqVal -= value
                credits = [...credits, { name: ta.taName, value }]
            }
        }
    }

    /** el seguro de vida, necesita el valor del crédito final por lo que es de ultimo el proceso */
    if (tlm && tlm.taType === 1) {
        const lm = tlm.typeLifeMillion.lifeMillions[0]
        if (tlm.typeLifeMillion.tlmFormula === 1) {
            const value = (((valCre / lm.lmValue) * lm.lmValueTwo) * mon) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            reqVal -= value
            credits = [...credits, { name: tlm.taName, value }]
        } else if (tlm.typeLifeMillion.tlmFormula === 2) {
            const value = ((lm.lmPor / 100) * valCre * mon) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            reqVal -= value
            credits = [...credits, { name: tlm.taName, value }]
        } else if (tlm.typeLifeMillion.tlmFormula === 3) {
            const value = lm.lmValue * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            reqVal -= value
            credits = [...credits, { name: tlm.taName, value }]
        } else if (tlm.typeLifeMillion.tlmFormula === 4) {
            const value = ((lm.lmPor / 100) * valCre) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            reqVal -= value
            credits = [...credits, { name: tlm.taName, value }]
        }
    }

    return { reqVal, valCre, valCou: valCouTot, fees, credits }
}