import React from 'react'
import PropTypes from 'prop-types'
import { RequestCreditEditV } from '../../components/RequestCreditEdit'

export const RequestCreditEditC = ({ navigation }) => {
    return (
        <RequestCreditEditV navigation={navigation} />
    )
}

RequestCreditEditC.propTypes = {
    navigation: PropTypes.object
}