import React from 'react'
import Context from './Context'
import Constants from 'expo-constants'
// import * as Linking from 'expo-linking'
import DrawerNavigator from './navigation/DrawerNavigator'
import { NavigationContainer } from '@react-navigation/native'
import { BannerNotification } from './src/components/BannerNotification'

export default () => {
    // const prefix = Linking.createURL('/');
    // const linking = {
    //     prefixes: ['https://app.mili.com.co/', 'exp://app.mili.com.co', 'miliapp://'],
    //     config: {
    //         Home: 'home',
    //         Camera: {
    //             path: 'Camera/:screen',
    //             params: {
    //                 screen: 'Home'
    //             }
    //         }
    //     }
    // };
    if (!Constants.manifest.extra.accessKey || !Constants.manifest.extra.secretKey) console.log('Revisar variables de entorno')
    return (
        <NavigationContainer>
            <Context.Consumer>
                {({ expired, message }) => {
                    return (<>
                        <DrawerNavigator />
                        {message && <BannerNotification message={message} />}
                        {expired && <BannerNotification message='Su sesión ha expirado.' />}
                    </>)
                }}
            </Context.Consumer>
        </NavigationContainer>
    )
}