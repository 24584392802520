import { gql, useQuery } from '@apollo/client'

export const USE_ALL_TYPE_BANKS = ({ state, order, limit }) => useQuery(gql`
    query getAllTypeBanks($state: [Int], $order: [[String]], $limit: [Int]) {
        typeBanks(state: $state, order: $order, limit: $limit) {
            tbId
            tbName
        }
    }
`, {
    variables: { state, order, limit }
})