import { calculatorCredit } from './calculatorCredit'
import { calculateRepayment } from './calculateRepayment'

/**
 * calcula el valor del crédito de cartera
 * @param {object} args argumentos
 * @return {object} nuevos argumentos
 */
export function calculateCreditFee ({ month, vat, rate, creditTypeAttributes, valCou }) {
    // calcula el valor del crédito y los atributos
    const data = calculatorCredit({ attributes: creditTypeAttributes, valCouTot: valCou, mon: month?.mValue, iva: vat?.vPor, ctModule: 2, rPor: rate?.rPor, ratMon: 1 })

    // calcula el plan de amortización
    const repaymentPlan = calculateRepayment({ valCre: data?.valCre, valCou: data?.valCou, fees: data?.fees, rate: rate?.rPor, month: month?.mValue })

    return { ...data, month: month?.mValue, rate: rate?.rPor, repaymentPlan }
}