import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { hp, wp } from '../../validation'

/** Global Container */
export const Container = styled.View`
    background-color: ${theme.backgroundP};
    flex: 1;
`
export const BoxHead = styled.View`
    flex-direction: row;
    justify-content: center;
    padding: ${wp(4)}px ${wp(2)}px;
    border-bottom-color: ${theme.primaryC};
    border-bottom-width: 1px;
`
export const Title = styled.Text`
    font-size: ${wp(4.5)}px;
    font-family: PFontBold;
    color: ${theme.primaryC};
`
export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
export const BoxInputs = styled.View`
    width: ${wp(70)}px;
`
export const ButtonIcon = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
`
export const Card = styled.View`
    margin: ${wp(3)}px;
    border-radius: ${wp(2)}px;
    background-color: ${theme.backgroundC};
    padding: ${wp(1)}px ${wp(2.5)}px;
`
export const styles = {
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: hp(1)
        },
        shadowOpacity: 0.12,
        shadowRadius: 8.5,
        elevation: 7,
    },
}