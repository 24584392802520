import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { mq } from '../../validation'

export const Container = styled.View`
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    overflow: hidden;
    height: ${mq(70)}px;
    width: ${mq('100%')};
`
export const Title = styled.Text`
    font-family: PFontBold;
    font-size: ${mq(14)}px;
    color: ${theme.backgroundC};
`