import { gql, useQuery } from '@apollo/client'

export const ALL_DEPARTMENTS = ({ state, order }) => useQuery(gql`
    query departments($state: [Int], $order: [[String]]) {
        departments(state: $state, order: $order) {
            dId
            dName
            dState
        }
    }
`, {
    variables: { state, order }
})