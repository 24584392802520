import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Linking from 'expo-linking'
import { ConfirmEmailV } from '../../components/ConfirmEmail'
import { ADO_PARAMETERS, RESEND_USER_ACTIVE } from './queries'
import { useLazyQuery, useMutation } from '@apollo/client'
import { isLoggedVar } from '../../apollo/cache'
import { selectClientName } from '../../../ignore/utils'

export const ConfirmEmailC = ({ navigation, route }) => {
    const [timeQuery, setTimeQuery] = useState(0)
    const [countQuery, setCountQuery] = useState(1)
    const [resendUserActive, { loading, error }] = useMutation(RESEND_USER_ACTIVE)
    const [getAllAdoParameters, { data: dataAdoParameters, called: calledAdoParameters }] = useLazyQuery(ADO_PARAMETERS, { fetchPolicy: 'cache-and-network' })
    const [adoParameters, setAdoParameters] = useState({});
    const client = selectClientName().toLowerCase()

    /**
     * Reenvia el correo al cliente
     * @return {void}
     */
    const handleReSend = () => {
        if (loading) return isLoggedVar({ ...isLoggedVar(), message: 'Espere por favor.' })

        // verifica si hay tiempo activo.
        if (timeQuery) return isLoggedVar({ ...isLoggedVar(), message: 'Espere a que el tiempo se acabe para poder reenviar el mensaje.' })

        // reenvia el mensaje.
        resendUserActive({ variables: { uId: route?.params?.uId } })
            .then(() => {
                isLoggedVar({ ...isLoggedVar(), message: 'Correo reenviado.' })
                setTimeQuery(countQuery * 60 * countQuery)
                setCountQuery(countQuery + 1)
            })
            .catch(x => ({ ...isLoggedVar(), message: x.message }))
    }

    useEffect(() => {
        getAllAdoParameters({ variables: { state: [1], onRegister: [1] } })
    }, [])

    useEffect(() => {
        if (dataAdoParameters?.adoParameters && calledAdoParameters) {
            setAdoParameters(
                dataAdoParameters?.adoParameters?.find(
                    x => x?.brand?.toLowerCase() === client
                )
            );
        }
    }, [dataAdoParameters?.adoParameters, calledAdoParameters]);

    const handleEnrollment = () => {
        const urlCallback = window.location.href;
        const uId = route?.params?.uId;
        // const tiId = route?.params?.tiId;
        // const uIdeNum = route?.params?.uIdeNum;

        // Enrolamiento - Validar
        Linking.openURL(`https://adocolombia-qa.ado-tech.com/${adoParameters?.projectName}/validar-persona?callback=${urlCallback}integrationAdo/?params=uId=${uId}/idAP=${adoParameters?.idAP}/name=${adoParameters?.projectName}&key=${adoParameters?.apiKey}&projectName=${adoParameters?.projectName}&product=1`)

        // Verificación
        // Linking.openURL(`https://adocolombia-qa.ado-tech.com/${adoParameters?.projectName}/verificar-persona?callback=${urlCallback}integrationAdo/?params=uId=${uId}/idAP=${adoParameters?.idAP}/name=${adoParameters?.projectName}/documentType=${tiId}&identificationNumber=${uIdeNum}&key=${adoParameters?.apiKey}&projectName=${adoParameters?.projectName}`)
    }

    useEffect(() => {
        if (timeQuery > 0) {
            const _interval = setInterval(() => setTimeQuery(timeQuery - 1), 1000)
            return () => {
                clearInterval(_interval)
            }
        }
    }, [timeQuery])

    return (
        <ConfirmEmailV
            data={{
                error,
                loading,
                timeQuery,
                email: route?.params?.psEmail,
                adoParameters,
            }}
            handleReSend={handleReSend}
            handleEnrollment={handleEnrollment}
            handleSignUp={() => navigation.push('Login')}
            handleBack={() => navigation.push('Register', { editEmail: true })}
            handleEmail={() => Linking.openURL('https://mail.google.com/mail')}
        />
    )
}

ConfirmEmailC.propTypes = {
    navigation: PropTypes.object,
    route:  PropTypes.object,
}