import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, Row } from './styled'
import { SelectHook } from '../SelectHook'
import { View } from 'react-native-web'

export const OtherInformationV = ({ data: { banner, errors, loading, values }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            <Row>
                <InputHook testID ='6217d2b0-951e-11ee-8349-c17838d7fed7' flex={100} title='Número afiliación:' name='poiAffiliationNumber' error={errors.poiAffiliationNumber} range={[1, 12]} value={values?.poiAffiliationNumber} numeric onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <View style={{ paddingTop: '2px' }}>
                <SelectHook
                    required
                    nameAtt='title'
                    name='poiUsNationality'
                    title='¿Cómo persona natural, posee usted nacionalidad estadounidense?'
                    testID ='6b31f390-951d-11ee-8c7b-33a801a13b69'
                    titleModal='Seleccionar Opción'
                    value={values?.poiUsNationality}
                    error={errors.poiUsNationality}
                    handleSelect={e => handleChange(e)}
                    data={[{ poiUsNationality: 1, title: 'SI' }, { poiUsNationality: 2, title: 'NO' }]}
                />
            </View>
            <SelectHook
                required
                nameAtt='title'
                name='poiUsAccount'
                testID ='4ca40353-9626-478f-99f1-d90ca40dd5dc'
                title='¿Usted transfiere o da instrucciones permanentes para transferir fondos a una cuenta de estados unidos?'
                titleModal='Seleccionar Opción'
                value={values?.poiUsAccount}
                error={errors.poiUsAccount}
                handleSelect={e => handleChange(e)}
                data={[{ poiUsAccount: 1, title: 'SI' }, { poiUsAccount: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiUsInvestmentAssets'
                title='¿Del 50% o más de sus activos totales y/ o los ingresos, provienen de activos de inversión en estados unidos?'
                testID ='78b4d050-951d-11ee-8965-514d2b3bc688'
                titleModal='Seleccionar Opción'
                value={values?.poiUsInvestmentAssets}
                error={errors.poiUsInvestmentAssets}
                handleSelect={e => handleChange(e)}
                data={[{ poiUsInvestmentAssets: 1, title: 'SI' }, { poiUsInvestmentAssets: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiAmericanGreenCard'
                title='¿Usted posee tarjeta de residencia americana (Green Card)?'
                titleModal='Seleccionar Opción'
                testID ='b2fb35eb-8a86-43a4-8c81-0e7d41f1f00d'
                value={values?.poiAmericanGreenCard}
                error={errors.poiAmericanGreenCard}
                handleSelect={e => handleChange(e)}
                data={[{ poiAmericanGreenCard: 1, title: 'SI' }, { poiAmericanGreenCard: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiBornUS'
                title='Nació en estados unidos'
                titleModal='Seleccionar Opción'
                testID ='846277e0-951d-11ee-8642-19ddd2307b7e'
                value={values?.poiBornUS}
                error={errors.poiBornUS}
                handleSelect={e => handleChange(e)}
                data={[{ poiBornUS: 1, title: 'SI' }, { poiBornUS: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiLivedUS'
                title='Vivió las 2/3 partes del año anterior en estados unidos'
                testID ='4c6569b3-1de8-4c33-9701-1507a4f93457'
                titleModal='Seleccionar Opción'
                value={values?.poiLivedUS}
                error={errors.poiLivedUS}
                handleSelect={e => handleChange(e)}
                data={[{ poiLivedUS: 1, title: 'SI' }, { poiLivedUS: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiForeignCurrency'
                title='Realiza transacciones en moneda extranjera'
                titleModal='Seleccionar Opción'
                testID ='90f3a1a0-951d-11ee-882a-1b0e7bb0d3d9'
                value={values?.poiForeignCurrency}
                error={errors.poiForeignCurrency}
                handleSelect={e => handleChange(e)}
                data={[{ poiForeignCurrency: 1, title: 'SI' }, { poiForeignCurrency: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiActivity'
                title='Realiza o ejerce las siguientes actividades'
                titleModal='Seleccionar Opción'
                testID ='f6cf4b39-5752-4599-9866-b84d352f8652'
                value={values?.poiActivity}
                error={errors.poiActivity}
                handleSelect={e => handleChange(e)}
                data={[
                    { poiActivity: 'IMPORTACIONES', title: 'IMPORTACIONES' },
                    { poiActivity: 'EXPORTACIONES', title: 'EXPORTACIONES' },
                    { poiActivity: 'INVERSIONES', title: 'INVERSIONES' },
                    { poiActivity: 'TRANSFERENCIAS', title: 'TRANSFERENCIAS' },
                    {
                        poiActivity: 'PRÉSTAMOS EN MONEDA EXTRANJERA',
                        title: 'PRÉSTAMOS EN MONEDA EXTRANJERA',
                    },
                    { poiActivity: 'PAGOS DE SERVICIOS', title: 'PAGOS DE SERVICIOS' },
                    { poiActivity: 'OTROS', title: 'OTROS' },
                    { poiActivity: 'NINGUNO', title: 'NINGUNO' },
                ]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiTaxObligations'
                title='Es sujeto de obligaciones tributarias en otro país'
                titleModal='Seleccionar Opción'
                testID ='9f687f80-951d-11ee-ac32-b36e81bdbaa1'
                value={values?.poiTaxObligations}
                error={errors.poiTaxObligations}
                handleSelect={e => handleChange(e)}
                data={[{ poiTaxObligations: 1, title: 'SI' }, { poiTaxObligations: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiBiddingActivities'
                title='Profesión u oficio es lícita'
                titleModal='Seleccionar Opción'
                testID ='f375969e-ad73-4f7d-a02b-f7cd38fbcae9'
                value={values?.poiBiddingActivities}
                error={errors.poiBiddingActivities}
                handleSelect={e => handleChange(e)}
                data={[{ poiBiddingActivities: 1, title: 'SI' }, { poiBiddingActivities: 2, title: 'NO' }]}
            />
            {values?.poiBiddingActivities === 1 &&
                <Row>
                    <InputHook testID ='ab075a00-951d-11ee-a61e-b9152f695665' flex={100} title='Especifique profesión:' name='poiInfoBiddingActivities' error={errors.poiInfoBiddingActivities} value={values?.poiInfoBiddingActivities} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
                </Row>
            }
            <SelectHook
                required
                nameAtt='title'
                name='poiAcceptInformation'
                title='La información que he suministrado en este formato es veraz y verificable'
                titleModal='Seleccionar Opción'
                testID ='0abb514b-9acf-436c-a647-78ba70b217f9'
                value={values?.poiAcceptInformation}
                error={errors.poiAcceptInformation}
                handleSelect={e => handleChange(e)}
                data={[{ poiAcceptInformation: 1, title: 'SI' }, { poiAcceptInformation: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiBreachContract'
                title='El incumplimiento de la obligación establecida faculta a la Cooperativa para revocar unilateralmente el contrato.'
                titleModal='Seleccionar Opción'
                testID ='b48db4c0-951d-11ee-8701-89ad28db7d67'
                value={values?.poiBreachContract}
                error={errors.poiBreachContract}
                handleSelect={e => handleChange(e)}
                data={[{ poiBreachContract: 1, title: 'SI' }, { poiBreachContract: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiCriminallyDeclared'
                title='Manifiesto que no he sido declarado responsable judicialmente por la comisión de delitos'
                titleModal='Seleccionar Opción'
                testID ='470b07a4-a040-4d20-893f-0f21296b19a2'
                value={values?.poiCriminallyDeclared}
                error={errors.poiCriminallyDeclared}
                handleSelect={e => handleChange(e)}
                data={[{ poiCriminallyDeclared: 1, title: 'SI' }, { poiCriminallyDeclared: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiTruthfulStatements'
                title='Las declaraciones contenidas en estos documentos son exactas, completas y verídicas'
                titleModal='Seleccionar Opción'
                testID ='c05748c0-951d-11ee-b5ba-132ac2dc8266'
                value={values?.poiTruthfulStatements}
                error={errors.poiTruthfulStatements}
                handleSelect={e => handleChange(e)}
                data={[{ poiTruthfulStatements: 1, title: 'SI' }, { poiTruthfulStatements: 2, title: 'NO' }]}
            />
            <SelectHook
                required
                nameAtt='title'
                name='poiAcceptDocument'
                title='Manifiesto de manera voluntaria haber leído y entendido todo lo contenido en este documento'
                titleModal='Seleccionar Opción'
                testID ='e930781f-024f-41a9-bebf-e5a7a4055ddd'
                value={values?.poiAcceptDocument}
                error={errors.poiAcceptDocument}
                handleSelect={e => handleChange(e)}
                data={[{ poiAcceptDocument: 1, title: 'SI' }, { poiAcceptDocument: 2, title: 'NO' }]}
            />
            <Row>
                <InputHook testID ='b6fa5d8c-331e-4fc8-9562-f774b65ee39f' flex={100} title='Solicitud incremento del valor asegurado a:' name='poiInsuredValue' error={errors.poiInsuredValue} value={values?.poiInsuredValue} numeric onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook testID ='d2fd4d30-951d-11ee-8ae0-3d87b0eccdca' flex={100} title='Valor de prima' name='poiPremiumValue' error={errors.poiPremiumValue} value={values?.poiPremiumValue} numeric onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <SelectHook
                required
                nameAtt='title'
                name='poiDangerHisLife'
                title='¿En alguna ocasión ha estado en peligro su vida?'
                titleModal='Seleccionar Opción'
                testID ='cb9eb470-951d-11ee-ad45-0d35a37d2157'
                value={values?.poiDangerHisLife}
                error={errors.poiDangerHisLife}
                handleSelect={e => handleChange(e)}
                data={[{ poiDangerHisLife: 1, title: 'SI' }, { poiDangerHisLife: 2, title: 'NO' }]}
            />
            {values?.poiDangerHisLife === 1 &&
                <Row>
                    <InputHook testID ='e11b7f90-951d-11ee-abf9-b1890474be12' flex={100} title='Especifique el peligro ocasionado:' name='poiInfoDangerHisLife' error={errors.poiInfoDangerHisLife} value={values?.poiInfoDangerHisLife} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
                </Row>
            }
            <SelectHook
                required
                nameAtt='title'
                name='poiTakesMedicine'
                title='¿Consume actualmente algún medicamento?'
                titleModal='Seleccionar Opción'
                testID ='9861734e-5d92-4d11-9e19-eb4c4c40d6cc'
                value={values?.poiTakesMedicine}
                error={errors.poiTakesMedicine}
                handleSelect={e => handleChange(e)}
                data={[{ poiTakesMedicine: 1, title: 'SI' }, { poiTakesMedicine: 2, title: 'NO' }]}
            />
            {values?.poiTakesMedicine === 1 &&
                <Row>
                    <InputHook testID ='8b5007fb-f676-4e0e-ad28-b2719b7447df' flex={100} title='Especifique medicamentos:' name='poiInfoTakesMedicine' error={errors.poiInfoTakesMedicine} value={values?.poiInfoTakesMedicine} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
                </Row>
            }
            <Row>
                <InputHook testID ='24061540-951e-11ee-9881-ffaf7ac7af5c' flex={100} title='EPS afiliado:' name='poiEPS' error={errors.poiEPS} value={values?.poiEPS} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <Row>
                <InputHook testID ='b7548920-cdcd-4357-a847-6185e8f6aee8' flex={100} title='IPS afiliado:' name='poiIPS' error={errors.poiIPS} value={values?.poiIPS} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
            </Row>
            <SelectHook
                required
                nameAtt='title'
                name='poiTakeDrug'
                title='Fuma o consume sustancias alucinógenas'
                titleModal='Seleccionar Opción'
                testID ='33149b10-951e-11ee-950a-e1004ac2f55d'
                value={values?.poiTakeDrug}
                error={errors.poiTakeDrug}
                handleSelect={e => handleChange(e)}
                data={[{ poiTakeDrug: 1, title: 'SI' }, { poiTakeDrug: 2, title: 'NO' }]}
            />
            {values?.poiTakeDrug === 1 &&
                <Row>
                    <InputHook testID ='bbd0efd2-087a-49e0-aae0-56c36672abe9' flex={100} title='Especifique:' name='poiInfoTakeDrug' error={errors.poiInfoTakeDrug} value={values?.poiInfoTakeDrug} letters onBlur={handleBlur} onChangeText={e => handleChange(e)} />
                </Row>
            }
            <BoxButton>
                <ButtonSubmit testID='181ec7bf-bafd-4301-b0e3-9977b42b77e4' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

OtherInformationV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    userParameters: PropTypes.object,
}