import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { FontAwesome, MaterialCommunityIcons, Entypo, Octicons } from '@expo/vector-icons'
import { DocumentDataRegisterV } from '../../components/CreditEditData/DocumentDataRegister'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { getFileS3, hp, putFileS3, updateCache, validationErrors } from '../../validation'
import theme from '../../../ignore/Constants'
import { isLoggedVar } from '../../apollo/cache'
import { CREDIT_CLIENT_MESSAGE, DOCUMENT_NEW, EDIT_CREDIT_ACTIONS, EDIT_CREDIT_PAYROLL_DOCUMENT } from './queries'

export const DocumentDataRegisterC = ({ documents, dataUser, banner, handleNext, format, handleButton, handleAsd, refScroll, handleStepCodebtor }) => {
    const [values, setValues] = useState({})
    const [valuesMessage, setValuesMessage] = useState({})
    const [dataDocument, setDataDocument] = useState([])
    const [files, setFiles] = useState({})
    const [filePD, setFilePD] = useState('')
    const [fileFD, setFileFD] = useState('')
    const [fileRD, setFileRD] = useState('')
    const [fileSD, setFileSD] = useState('')
    const [filePRD, setFilePRD] = useState('')
    const [fileBD, setFileBD] = useState('')
    const [fileCD, setFileCD] = useState('')
    const [fileCAD, setFileCAD] = useState('')
    const [fileBRD, setFileBRD] = useState('')
    const [fileBCD, setFileBCD] = useState('')
    const [fileRut, setFileRut] = useState('')
    const [fileCC, setFileCC] = useState('')
    const [fileIP, setFileIP] = useState('')
    const [fileND, setFileND] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadPD, setLoadPD] = useState(true)
    const [loadFD, setLoadFD] = useState(true)
    const [loadRD, setLoadRD] = useState(true)
    const [loadSD, setLoadSD] = useState(true)
    const [loadPRD, setLoadPRD] = useState(true)
    const [loadBD, setLoadBD] = useState(true)
    const [loadCD, setLoadCD] = useState(true)
    const [loadCAD, setLoadCAD] = useState(true)
    const [loadBRD, setLoadBRD] = useState(true)
    const [loadBCD, setLoadBCD] = useState(true)
    const [loadRut, setLoadRut] = useState(true)
    const [loadCC, setLoadCC] = useState(true)
    const [loadIP, setLoadIP] = useState(true)
    const [loadND, setLoadND] = useState(true)
    const [errorForm, setErrorForm] = useState({})
    const [infoCamera, setInfoCamera] = useState({})
    const [creditPayrollDocumentMutation, { loadUpd }] = useMutation(EDIT_CREDIT_PAYROLL_DOCUMENT)
    const [creditActionState] = useMutation(EDIT_CREDIT_ACTIONS)
    const [setDocumentNew] = useMutation(DOCUMENT_NEW)
    const [setMessage] = useMutation(CREDIT_CLIENT_MESSAGE)

    // Actualiza la información de un usuario
    useEffect(() => {
        const searchImage = async () => {
            /** actualiza todas las variables */
            setValues({
                creditPayrollDocument: {
                    cpdId: dataUser?.creditPayrollDocument?.cpdId,
                    cpdPerDoc: dataUser?.creditPayrollDocument?.cpdPerDoc,
                    cpdFroDoc: dataUser?.creditPayrollDocument?.cpdFroDoc,
                    cpdReaDoc: dataUser?.creditPayrollDocument?.cpdReaDoc,
                    cpdSerDoc: dataUser?.creditPayrollDocument?.cpdSerDoc,
                    cpdPayRemDoc: dataUser?.creditPayrollDocument?.cpdPayRemDoc,
                    cpdBanDoc: dataUser?.creditPayrollDocument?.cpdBanDoc,
                    cpdConDoc: dataUser?.creditPayrollDocument?.cpdConDoc,
                    cpdComActDoc: dataUser?.creditPayrollDocument?.cpdComActDoc,
                    cpdBudRecDoc: dataUser?.creditPayrollDocument?.cpdBudRecDoc,
                    cpdBudCerDoc: dataUser?.creditPayrollDocument?.cpdBudCerDoc,
                    cpdRut: dataUser?.payrollDocument?.cpdRut,
                    cpdComChamber: dataUser?.payrollDocument?.cpdComChamber,
                    cpdInstitutionPic: dataUser?.payrollDocument?.cpdInstitutionPic,
                    cpdWorkCertificate: dataUser?.payrollDocument?.cpdWorkCertificate,
                    cpdBankStatement: dataUser?.payrollDocument?.cpdBankStatement,
                    cpdFinancialStatements: dataUser?.payrollDocument?.cpdFinancialStatements,
                    cpdIncomeTaxReturn: dataUser?.payrollDocument?.cpdIncomeTaxReturn,
                    cpdBoardDirectors: dataUser?.payrollDocument?.cpdBoardDirectors,
                    cpdTradeBalance: dataUser?.payrollDocument?.cpdTradeBalance,
                    cpdRealEstateRegistration: dataUser?.payrollDocument?.cpdRealEstateRegistration,
                    cpdVehicleOwnershipCard: dataUser?.payrollDocument?.cpdVehicleOwnershipCard,
                    cpdCommercialReference: dataUser?.payrollDocument?.cpdCommercialReference,
                    cpdBankReference: dataUser?.payrollDocument?.cpdBankReference,
                    cpdIncomeWithholdings: dataUser?.payrollDocument?.cpdIncomeWithholdings,
                    cpdCadastreAndValuation: dataUser?.payrollDocument?.cpdCadastreAndValuation,
                    cpdCommercialAppraisal: dataUser?.payrollDocument?.cpdCommercialAppraisal

                }
            })
            setDataDocument(dataUser?.creditPayrollCompanyOtherDocuments?.map(x => {
                return {
                    cpcodId: x?.cpcodId,
                    cpcodName: x?.cpcodName,
                    cpcodFileName: x?.cpcodFileName,
                    cpcodState: x?.cpcodState,
                }
            }))
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdPerDoc}`).then(x => {
                setFilePD(x)
                setLoadPD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdFroDoc}`).then(x => {
                setFileFD(x)
                setLoadFD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdReaDoc}`).then(x => {
                setFileRD(x)
                setLoadRD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdSerDoc}`).then(x => {
                setFileSD(x)
                setLoadSD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdPayRemDoc}`).then(x => {
                setFilePRD(x)
                setLoadPRD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdBanDoc}`).then(x => {
                setFileBD(x)
                setLoadBD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdConDoc}`).then(x => {
                setFileCD(x)
                setLoadCD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdComActDoc}`).then(x => {
                setFileCAD(x)
                setLoadCAD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdBudRecDoc}`).then(x => {
                setFileBRD(x)
                setLoadBRD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdBudCerDoc}`).then(x => {
                setFileBCD(x)
                setLoadBCD(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdRut}`).then(x => {
                setFileRut(x)
                setLoadRut(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdComChamber}`).then(x => {
                setFileCC(x)
                setLoadCC(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdInstitutionPic}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdBankStatement}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdFinancialStatements}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdIncomeTaxReturn}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdBoardDirectors}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdTradeBalance}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdRealEstateRegistration}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdVehicleOwnershipCard}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdCommercialReference}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdBankReference}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdIncomeWithholdings}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdCadastreAndValuation}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdCommercialAppraisal}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })

            const loadData = async () => {
                for (const document of dataUser?.creditPayrollCompanyOtherDocuments || []) {
                    try {
                        const x = await getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser?.pcId}/documents/${document?.cpcodFileName}`);
                        setFileND(prevFileND => [...prevFileND, x]);
                        setLoadND(false);
                    } catch (error) {
                        console.error(`Error loading document: ${error}`);
                        // Si ocurre un error al cargar un documento, simplemente continúa con el siguiente.
                    }
                }
            };

            loadData();
            getFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.creditPayrollDocument?.cpdWorkCertificate}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            setErrorForm({
                cpdPerDoc: (format?.cpfPerDoc && !dataUser?.creditPayrollDocument?.cpdPerDoc),
                cpdFroDoc: (format?.cpfFroDoc && !dataUser?.creditPayrollDocument?.cpdFroDoc),
                cpdReaDoc: (format?.cpfReaDoc && !dataUser?.creditPayrollDocument?.cpdReaDoc),
                cpdSerDoc: (format?.cpfSerDoc && !dataUser?.creditPayrollDocument?.cpdSerDoc),
                cpdPayRemDoc: (format?.cpfPayRemDoc && !dataUser?.creditPayrollDocument?.cpdPayRemDoc),
                cpdBanDoc: (format?.cpfBanDoc && !dataUser?.creditPayrollDocument?.cpdBanDoc),
                cpdConDoc: (format?.cpfConDoc && !dataUser?.creditPayrollDocument?.cpdConDoc),
                cpdComActDoc: (format?.cpfComActDoc && !dataUser?.creditPayrollDocument?.cpdComActDoc),
                cpdBudRecDoc: (format?.cpfBudRecDoc && !dataUser?.creditPayrollDocument?.cpdBudRecDoc),
                cpdBudCerDoc: (format?.cpfBudCerDoc && !dataUser?.creditPayrollDocument?.cpdBudCerDoc),
                cpdRut: (format?.cpfRut && !dataUser?.creditPayrollDocument?.cpdRut),
                cpdComChamber: (format?.cpfComChamber && !dataUser?.creditPayrollDocument?.cpdComChamber),
                cpdInstitutionPic: (format?.cpfInstitutionPic && !dataUser?.creditPayrollDocument?.cpdInstitutionPic),
                cpdWorkCertificate: (format?.cpfWorkCertificate && !dataUser?.creditPayrollDocument?.cpdWorkCertificate),
                cpdBankStatement: (format?.cpfBankStatement && !dataUser?.creditPayrollDocument?.cpdBankStatement),
                cpdFinancialStatements: (format?.cpfFinancialStatements && !dataUser?.creditPayrollDocument?.cpdFinancialStatements),
                cpdIncomeTaxReturn: (format?.cpfIncomeTaxReturn && !dataUser?.creditPayrollDocument?.cpdIncomeTaxReturn),
                cpdBoardDirectors: (format?.cpfBoardDirectors && !dataUser?.creditPayrollDocument?.cpdBoardDirectors),
                cpdTradeBalance: (format?.cpfTradeBalance && !dataUser?.creditPayrollDocument?.cpdTradeBalance),
                cpdRealEstateRegistration: (format?.cpfRealEstateRegistration && !dataUser?.creditPayrollDocument?.cpdRealEstateRegistration),
                cpdVehicleOwnershipCard: (format?.cpfVehicleOwnershipCard && !dataUser?.creditPayrollDocument?.cpdVehicleOwnershipCard),
                cpdCommercialReference: (format?.cpfCommercialReference && !dataUser?.creditPayrollDocument?.cpdCommercialReference),
                cpdBankReference: (format?.cpfBankReference && !dataUser?.creditPayrollDocument?.cpdBankReference),
                cpdIncomeWithholdings: (format?.cpfIncomeWithholdings && !dataUser?.creditPayrollDocument?.cpdIncomeWithholdings),
                cpdCadastreAndValuation: (format?.cpfCadastreAndValuation && !dataUser?.creditPayrollDocument?.cpdCadastreAndValuation),
                cpdCommercialAppraisal: (format?.cpfCommercialAppraisal && !dataUser?.creditPayrollDocument?.cpdCommercialAppraisal)
            })
        }
        searchImage()
    }, [dataUser])

    // Actualiza las fotos tomadas
    useEffect(() => {
        if (documents?.name) {
            setValues(state => ({ creditPayrollDocument: { ...state?.creditPayrollDocument, [documents.name]: `${new Date().getTime()}1.${documents.ext}` } }))
            setFiles(state => ({ ...state, [documents.name]: documents.file, [documents.nameA]: documents.base64 }))
            setErrorForm(state => ({ ...state, [documents.name]: false }))
        }
    }, [documents])
    // coloca la información de la cámara
    const handleInfo = type => {
        let stepsInf = []
        if (type === 1) stepsInf = [{ text: '2. Enfoque la parte frontal de su documento de identidad .' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 2) stepsInf = [{ text: '2. Enfoque la parte reversa de su documento de identidad.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 3) stepsInf = [{ text: '2. Enfoque su rostro sosteniendo el documento de identidad a la misma altura de la cabeza.' }, { text: '3. No utilizar accesorios.' }]
        else if (type === 4) stepsInf = [{ text: '2. Tome la foto de un servicio público (Agua, luz o gas).' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 5) stepsInf = [{ text: '2. Tome la foto del desprendible de nómina.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 6) stepsInf = [{ text: '2. Tome la foto del certificado bancario.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 7) stepsInf = [{ text: '2. Tome la foto del contrato laboral.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 8) stepsInf = [{ text: '2. Tome la foto del acta de inicio.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 9) stepsInf = [{ text: '2. Tome la foto del registro de presupuesto.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 10) stepsInf = [{ text: '2. Tome la foto del certificado de presupuesto.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 11) stepsInf = [{ text: '2. Tome la foto del RUT.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 12) stepsInf = [{ text: '2. Tome la foto de la Camara de Comercio.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 13) stepsInf = [{ text: '2. Tome la foto del Establecimiento.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 14) stepsInf = [{ text: '2. Anexe foto o archivo de flujo de caja - Extracto bancario.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 15) stepsInf = [{ text: '2. Anexe foto o archivo de estados financieros.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 16) stepsInf = [{ text: '2. Anexe foto o archivo de declaración de renta.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 17) stepsInf = [{ text: '2. Anexe foto o archivo de acta junta directiva.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 18) stepsInf = [{ text: '2. Anexe foto o archivo de balance comercial.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 19) stepsInf = [{ text: '2. Anexe foto o archivo de matricula inmobiliaria.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 20) stepsInf = [{ text: '2. Anexe foto o archivo de tarjeta propiedad vehículo.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 21) stepsInf = [{ text: '2. Anexe foto o archivo de referencia comercial.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 22) stepsInf = [{ text: '2. Anexe foto o archivo de referencia bancaria.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 23) stepsInf = [{ text: '2. Anexe foto o archivo de certificado de ingresos y retenciones.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 24) stepsInf = [{ text: '2. Anexe foto o archivo de paz salvo catastro y valorización.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 25) stepsInf = [{ text: '2. Anexe foto o archivo de avaluó comercial.' }, { text: '3. La imagen debe ser visible y legible.' }]
        setInfoCamera({
            title: 'Documentos personales',
            icon: type === 1 ? <FontAwesome name='vcard' size={hp(20)} color={theme.backgroundC} /> : type === 2 ? <MaterialCommunityIcons name='card-bulleted' size={hp(26)} color={theme.backgroundC} /> : type === 3 ? <Entypo name='v-card' size={hp(24)} color={theme.backgroundC} /> : <Octicons name='file' size={hp(20)} color={theme.backgroundC} />,
            steps: [
                { text: 'Sigues las instrucciones para capturar la foto.' },
                { text: '1. Capture dentro de los marcos señalados.' },
                ...stepsInf
            ]
        })
    }
    const handleChange = e => {
        setValuesMessage({ ...valuesMessage, [e.name]: e.value })
    }
    const handleSubmit = async () => {
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        setLoading(true)
        files.cpdPerDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdPerDoc}`, files.cpdPerDoc)
        files.cpdFroDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdFroDoc}`, files.cpdFroDoc)
        files.cpdReaDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdReaDoc}`, files.cpdReaDoc)
        files.cpdSerDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdSerDoc}`, files.cpdSerDoc)
        files.cpdBanDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdBanDoc}`, files.cpdBanDoc)
        files.cpdConDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdConDoc}`, files.cpdConDoc)
        files.cpdPayRemDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdPayRemDoc}`, files.cpdPayRemDoc)
        files.cpdComActDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdComActDoc}`, files.cpdComActDoc)
        files.cpdBudRecDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdBudRecDoc}`, files.cpdBudRecDoc)
        files.cpdBudCerDoc && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdBudCerDoc}`, files.cpdBudCerDoc)
        files.cpdRut && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdRut}`, files.cpdRut)
        files.cpdComChamber && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdComChamber}`, files.cpdComChamber)
        files.cpdInstitutionPic && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdInstitutionPic}`, files.cpdInstitutionPic)
        files.cpdWorkCertificate && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdWorkCertificate}`, files.cpdWorkCertificate)
        files.cpdBankStatement && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdBankStatement}`, files.cpdBankStatement)
        files.cpdFinancialStatements && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdFinancialStatements}`, files.cpdFinancialStatements)
        files.cpdIncomeTaxReturn && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdIncomeTaxReturn}`, files.cpdIncomeTaxReturn)
        files.cpdBoardDirectors && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdBoardDirectors}`, files.cpdBoardDirectors)
        files.cpdTradeBalance && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdTradeBalance}`, files.cpdTradeBalance)
        files.cpdRealEstateRegistration && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdRealEstateRegistration}`, files.cpdRealEstateRegistration)
        files.cpdVehicleOwnershipCard && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdVehicleOwnershipCard}`, files.cpdVehicleOwnershipCard)
        files.cpdCommercialReference && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdCommercialReference}`, files.cpdCommercialReference)
        files.cpdBankReference && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdBankReference}`, files.cpdBankReference)
        files.cpdIncomeWithholdings && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdIncomeWithholdings}`, files.cpdIncomeWithholdings)
        files.cpdCadastreAndValuation && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdCadastreAndValuation}`, files.cpdCadastreAndValuation)
        files.cpdCommercialAppraisal && await putFileS3(`companys/${dataUser.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.cpdCommercialAppraisal}`, files.cpdCommercialAppraisal)
        if (dataDocument?.length > 0) {
            dataDocument?.map(async (x, i) => {
                const cpcodId = `document[${i}].cpcodId`
                files?.[cpcodId] && await putFileS3(`companys/${dataUser?.payrollCompany?.cId}/payroll/${dataUser.pcId}/documents/${values.creditPayrollDocument?.[cpcodId]}`, files?.[cpcodId])

            })
            const dataNew = dataDocument?.map((x, i) => {
                const cpcodId = `document[${i}].cpcodId`
                return {
                    cpcodId: x?.cpcodId,
                    cpcodFileName: values?.creditPayrollDocument?.[cpcodId],
                }
            });
            const { newError } = await setDocumentNew({
                variables: {
                    input: dataNew,
                },
            })
            if (newError) return isLoggedVar({ ...isLoggedVar(), message: newError.message })
        }
        const newData = {
            cpdBanDoc: values.creditPayrollDocument?.cpdBanDoc,
            cpdBudCerDoc: values.creditPayrollDocument?.cpdBudCerDoc,
            cpdBudRecDoc: values.creditPayrollDocument?.cpdBudRecDoc,
            cpdComActDoc: values.creditPayrollDocument?.cpdComActDoc,
            cpdComChamber: values.creditPayrollDocument?.cpdComChamber,
            cpdConDoc: values.creditPayrollDocument?.cpdConDoc,
            cpdFroDoc: values.creditPayrollDocument?.cpdFroDoc,
            cpdId: values.creditPayrollDocument?.cpdId,
            cpdInstitutionPic: values.creditPayrollDocument?.cpdInstitutionPic,
            cpdPayRemDoc: values.creditPayrollDocument?.cpdPayRemDoc,
            cpdPerDoc: values.creditPayrollDocument?.cpdPerDoc,
            cpdReaDoc: values.creditPayrollDocument?.cpdReaDoc,
            cpdRut: values.creditPayrollDocument?.cpdRut,
            cpdSerDoc: values.creditPayrollDocument?.cpdSerDoc,
            cpdBankStatement: values.creditPayrollDocument?.cpdBankStatement,
            cpdFinancialStatements: values.creditPayrollDocument?.cpdFinancialStatements,
            cpdIncomeTaxReturn: values.creditPayrollDocument?.cpdIncomeTaxReturn,
            cpdBoardDirectors: values.creditPayrollDocument?.cpdBoardDirectors,
            cpdTradeBalance: values.creditPayrollDocument?.cpdTradeBalance,
            cpdRealEstateRegistration: values.creditPayrollDocument?.cpdRealEstateRegistration,
            cpdVehicleOwnershipCard: values.creditPayrollDocument?.cpdVehicleOwnershipCard,
            cpdCommercialReference: values.creditPayrollDocument?.cpdCommercialReference,
            cpdBankReference: values.creditPayrollDocument?.cpdBankReference,
            cpdIncomeWithholdings: values.creditPayrollDocument?.cpdIncomeWithholdings,
            cpdCadastreAndValuation: values.creditPayrollDocument?.cpdCadastreAndValuation,
            cpdCommercialAppraisal: values.creditPayrollDocument?.cpdCommercialAppraisal
        }
        const { data, error } = await creditPayrollDocumentMutation({
            variables: { cpdId: dataUser?.creditPayrollDocument?.cpdId, input: newData }, update(cache, { data: { setOrUpdateCreditPayrollDocument } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollDocument })
            }
        }).catch(e => ({ error: e }))
        const { err } = await creditActionState({ variables: { input: { cId: dataUser?.cId, asCode: 35, caState: 1, caSeen: 0 } } }).catch(e => ({ error: e }))
        if (err) return isLoggedVar({ ...isLoggedVar(), message: err.message })
        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })
        /** resultado */
        if (data?.setOrUpdateCreditPayrollDocument) {
            await setMessage({
                variables: {
                    input: {
                        cId: dataUser?.cId,
                        ccmMessage: valuesMessage?.ccmMessage,
                        ccmState: 1
                    }
                }
            })
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(handleStepCodebtor())
        }
    }

    return (
        <DocumentDataRegisterV
            data={{
                files,
                values,
                valuesMessage,
                loadPD,
                loadFD,
                loadRD,
                loadSD,
                loadBD,
                format,
                loadCD,
                banner,
                filePD,
                fileFD,
                fileRD,
                fileSD,
                fileBD,
                fileCD,
                fileRut,
                fileCC,
                fileIP,
                loadPRD,
                filePRD,
                fileCAD,
                fileBRD,
                fileBCD,
                loadCAD,
                loadBRD,
                loadBCD,
                loadRut,
                loadCC,
                loadIP,
                loadND,
                fileND,
                infoCamera,
                loading: (loading || loadUpd),
            }}
            handleInfo={handleInfo}
            handleButton={handleButton}
            handleSubmit={handleSubmit}
            dataDocument={dataDocument}
            handleAsd={handleAsd}
            handleChange={handleChange}
        />
    )
}

DocumentDataRegisterC.propTypes = {
    documents: PropTypes.object,
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    handleButton: PropTypes.func,
    handleAsd: PropTypes.func,
    refScroll: PropTypes.object,
    handleStepCodebtor: PropTypes.number,
}