import React from 'react'
import PropTypes from 'prop-types'
import { AntDesign } from '@expo/vector-icons'
import { useTheme } from 'styled-components/native'

// Components, Utils
import { TypeIdentities } from '../Selects/TypeIdentities'
import { Container, Form, Row, BoxButton } from './styled'
import { BannerInfoData } from '../BannerInfoData'
import { ButtonSubmit } from '../ButtonSubmit'
import { SelectHook } from '../SelectHook'
import { InputHook } from '../InputHook'
import { mq } from '../../validation'

export const InformationAdditionalCreditSarlaftV = ({
    data: {
        format,
        payroll,
        valuesWork,
    },
    errors: {
        errorFormWork,
    },
    loadings: {
        loadMutation,
    },
    handleChange,
    handleSubmit
}) => {
    const theme = useTheme()
    return (
        <Container>
            <BannerInfoData
                title='Declaraciones'
                icon={<AntDesign name='idcard' size={mq(20)} color={theme.BGColor} />}
                titleModal='Información de Declaraciones'
                iconModal={<AntDesign name='idcard' size={mq(100)} color={theme.BGColor} />}
                steps={[
                    { text: 'En esta sección debes  ingresar la siguiente información del seguro.' },
                    { text: '1. Peso.' },
                    { text: '2. Altura.' },
                    { text: '3. MAdre Cabeza de Hogar.' },
                    { text: '4. Hijos.' },
                    { text: '5. Cancer.' },
                    { text: '6. Sida.' },
                    { text: '7. Accidente Cerebrovascular.' },
                    { text: '8. Insuficiencia Renal.' },
                    { text: '9. Hipertensión Arterial.' },
                    { text: '10. Diabetes.' },
                    { text: '11. Enfermedades de las Arterias Coronarias.' },
                    { text: '12. Infarto.' },
                    { text: '13. Tiene alguna limitación física o mental congénita o adquirida.' },
                    { text: '14. Le han diagnosticado o tratado alguna enfermedad diferente a las citadas arriba.' },
                    { text: '15. Fecha de diagnóstico.' },
                    { text: '16. Tratamiento.' },
                    { text: '17. Estado actual.' },
                    { text: '18. Está Embarazada.' },
                    { text: '19. Es Primigestante.' },
                    { text: '20. Tiempo de Gestación.' },
                ]}
            />
            <Form>
                <TypeIdentities
                    disabled
                    value={payroll?.tiId}
                />
                <InputHook
                    editable={false}
                    value={payroll?.cpcIdeNum}
                    title='Número de identificación'
                />
                <Row>
                    {format?.cpfActive &&
                        <InputHook
                            format
                            numeric
                            required
                            minimum={0}
                            title='Activos *'
                            name='cpwActive'
                            maximum={2000000000}
                            onChangeText={handleChange}
                            value={valuesWork?.cpwActive}
                            error={errorFormWork?.cpwActive}
                            flex={format?.cpfPassive ? .47 : 1}
                        />
                    }
                    {format?.cpfPassive &&
                        <InputHook
                            format
                            numeric
                            required
                            minimum={0}
                            title='Pasivos *'
                            name='cpwPassive'
                            maximum={2000000000}
                            onChangeText={handleChange}
                            value={valuesWork?.cpwPassive}
                            error={errorFormWork?.cpwPassive}
                            flex={format?.cpfActive ? .47 : 1}
                        />
                    }
                </Row>
                {format?.cpfHolPubOff &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpwHolPubOff'
                        handleSelect={handleChange}
                        titleModal='Seleccionar Opción'
                        value={valuesWork.cpwHolPubOff}
                        title='Ejerce Cargos Públicos *'
                        error={errorFormWork.cpwHolPubOff}
                        data={[{ cpwHolPubOff: 1, title: 'SI' }, { cpwHolPubOff: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfHasPubRec &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpwHasPubRec'
                        handleSelect={handleChange}
                        titleModal='Seleccionar Opción'
                        value={valuesWork.cpwHasPubRec}
                        error={errorFormWork.cpwHasPubRec}
                        title='Tiene Reconocimiento Público *'
                        data={[{ cpwHasPubRec: 1, title: 'SI' }, { cpwHasPubRec: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfHasRelPubExp &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpwHasRelPubExp'
                        handleSelect={handleChange}
                        titleModal='Seleccionar Opción'
                        value={valuesWork.cpwHasRelPubExp}
                        error={errorFormWork.cpwHasRelPubExp}
                        title='Tiene relación con una persona expuesta públicamente *'
                        data={[{ cpwHasRelPubExp: 1, title: 'SI' }, { cpwHasRelPubExp: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfCIIU &&
                    <InputHook
                        numeric
                        required
                        name='cpwCIIU'
                        range={[1, 10]}
                        title='Código CIIU *'
                        onChangeText={handleChange}
                        value={valuesWork?.cpwCIIU}
                        error={errorFormWork?.cpwCIIU}
                    />
                }
                {format?.cpfManPubRes &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpwManPubRes'
                        handleSelect={handleChange}
                        titleModal='Seleccionar Opción'
                        value={valuesWork.cpwManPubRes}
                        title='Maneja Recursos Públicos *'
                        error={errorFormWork.cpwManPubRes}
                        data={[{ cpwManPubRes: 1, title: 'SI' }, { cpwManPubRes: 2, title: 'NO' }]}
                    />
                }
                <BoxButton>
                    <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loadMutation} />
                </BoxButton>
            </Form>
        </Container>
    )
}

InformationAdditionalCreditSarlaftV.propTypes = {
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    data: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    loadings: PropTypes.object.isRequired,
}