import React from 'react'
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'
import { hp, wp } from '../validation'

export const CreditRequestS = props => (
    <ContentLoader
        speed={1}
        width={wp(85)}
        height={hp(60)}
        viewBox='0 0 350 500'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
    >
        <Rect x='0' y='15' rx='10' ry='10' width='330' height='30' />
        <Rect x='0' y='5' rx='5' ry='5' width='170' height='5' />
        <Rect x='0' y='75' rx='5' ry='5' width='150' height='5' />
        <Rect x='0' y='90' rx='5' ry='5' width='100' height='5' />
        <Rect x='0' y='100' rx='10' ry='10' width='330' height='1' />
        <Rect x='0' y='120' rx='5' ry='5' width='330' height='5' />
        <Circle cx='247' cy='123' r='11' />
        <Rect x='40' y='154' rx='5' ry='5' width='70' height='5' />
        <Rect x='220' y='154' rx='5' ry='5' width='70' height='5' />
        <Rect x='25' y='164' rx='5' ry='5' width='100' height='5' />
        <Rect x='205' y='164' rx='5' ry='5' width='100' height='5' />
        <Rect x='0' y='200' rx='5' ry='5' width='150' height='5' />
        <Rect x='0' y='215' rx='5' ry='5' width='100' height='5' />
        <Rect x='0' y='225' rx='10' ry='10' width='330' height='1' />
        <Rect x='0' y='245' rx='5' ry='5' width='330' height='5' />
        <Circle cx='161' cy='248' r='11' />
        <Rect x='40' y='270' rx='5' ry='5' width='70' height='5' />
        <Rect x='220' y='270' rx='5' ry='5' width='70' height='5' />
        <Rect x='60' y='280' rx='5' ry='5' width='30' height='5' />
        <Rect x='240' y='279' rx='5' ry='5' width='30' height='5' />
        <Rect x='70' y='435' rx='15' ry='15' width='210' height='25' />
        <Rect x='130' y='320' rx='5' ry='5' width='70' height='5' />
        <Rect x='120' y='335' rx='5' ry='5' width='90' height='15' />
        <Rect x='0' y='385' rx='5' ry='5' width='330' height='5' />
        <Rect x='0' y='395' rx='5' ry='5' width='50' height='5' />
    </ContentLoader>
)

export const CreditRequestInfoS = props => (
    <ContentLoader
        speed={1}
        width={wp(85)}
        height={hp(50)}
        viewBox='0 0 350 435'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
    >
        <Rect x='0' y='10' rx='5' ry='5' width='150' height='5' />
        <Rect x='0' y='25' rx='5' ry='5' width='100' height='5' />
        <Rect x='0' y='35' rx='10' ry='10' width='330' height='1' />
        <Rect x='0' y='55' rx='5' ry='5' width='330' height='5' />
        <Circle cx='247' cy='58' r='11' />
        <Rect x='40' y='89' rx='5' ry='5' width='70' height='5' />
        <Rect x='220' y='89' rx='5' ry='5' width='70' height='5' />
        <Rect x='25' y='99' rx='5' ry='5' width='100' height='5' />
        <Rect x='205' y='99' rx='5' ry='5' width='100' height='5' />
        <Rect x='0' y='135' rx='5' ry='5' width='150' height='5' />
        <Rect x='0' y='215' rx='5' ry='5' width='100' height='5' />
        <Rect x='0' y='150' rx='10' ry='10' width='330' height='1' />
        <Rect x='0' y='170' rx='5' ry='5' width='330' height='5' />
        <Circle cx='161' cy='173' r='11' />
        <Rect x='40' y='205' rx='5' ry='5' width='70' height='5' />
        <Rect x='220' y='205' rx='5' ry='5' width='70' height='5' />
        <Rect x='60' y='215' rx='5' ry='5' width='30' height='5' />
        <Rect x='240' y='214' rx='5' ry='5' width='30' height='5' />
        <Rect x='70' y='370' rx='15' ry='15' width='210' height='25' />
        <Rect x='130' y='255' rx='5' ry='5' width='70' height='5' />
        <Rect x='120' y='270' rx='5' ry='5' width='90' height='15' />
        <Rect x='0' y='320' rx='5' ry='5' width='330' height='5' />
        <Rect x='0' y='330' rx='5' ry='5' width='50' height='5' />
    </ContentLoader>
)