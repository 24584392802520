import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import * as FileSystem from 'expo-file-system'
import { Keyboard } from 'react-native'
import Constants from 'expo-constants'
import PropTypes from 'prop-types'

// Utils, Queries, Components
import { validationErrors, base64ToArrayBuffer, extFile, putFileS3, tokenNotification, setData } from '../../validation'
import { GET_ALL_COMPANIES, ONE_COMPANY, ONE_MUTUAL, REGISTER_USER_PAYROLL_COMPANY, USER_PARAMETERS } from './queries'
import { RegisterUserPayrollV } from '../../components/RegisterUserPayroll'
import { isLoggedVar } from '../../apollo/cache'

export const RegisterUserPayrollC = ({ navigation, route }) => {
    const [errorForm, setErrorForm] = useState({ cCode: true, psFirNam: true, psFirLas: true, pwPosition: true, tcId: true, pwDatSta: true, pwSalary: true, pwNet: true, pwEmbargoes: true, })
    const [showFooter, setShowFooter] = useState(true)
    const [isVisible, setIsVisible] = useState(false)
    const [categories, setCategories] = useState([])
    const [companies, setCompanies] = useState([])
    const [document, setDocument] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [token, setToken] = useState()

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => setShowFooter(false))
        const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => setShowFooter(true))

        return () => {
            keyboardDidShowListener.remove()
            keyboardDidHideListener.remove()
        }
    }, [])

    // Mutation, Queries
    const [getOneCompany, { data: dataC, called: calC, error: errC }] = useLazyQuery(ONE_COMPANY)
    const { data: dataM } = useQuery(ONE_MUTUAL, { fetchPolicy: 'cache-and-network' });
    const { data: dataCompanies } = useQuery(GET_ALL_COMPANIES, { fetchPolicy: 'cache-and-network' });
    const [registerUser, { loading: loadReg }] = useMutation(REGISTER_USER_PAYROLL_COMPANY)
    const { data: dataUP } = useQuery(USER_PARAMETERS)
    // evento a la escucha de los documentos
    const onPicture = async ({ event, nameFile }) => {
        // verifica si viene documento para continuar
        if (!event) return

        // variables necesarias
        const ext = nameFile ? extFile(nameFile) : 'jpeg'
        let file, base64

        // verifica si es web o nativo
        if (Constants.platform.web) {
            base64 = event
            file = base64ToArrayBuffer(event.substring(event.indexOf('base64,') + 7, event.length))
        } else {
            base64 = await FileSystem.readAsStringAsync(event, { encoding: FileSystem.EncodingType.Base64 })
            file = base64ToArrayBuffer(base64)
            base64 = event
        }
        setDocument({ file, name: `${new Date().getTime()}1.${ext}`, base64 })
    }

    useEffect(() => {
        if (route.params?.event) {
            onPicture({
                event: route.params.event,
                type: route.params.type,
                typeA: route.params.typeA,
                nameFile: route.params.nameFile
            })
        } else {
            // busca el token del usuario
            tokenNotification().then(x => setToken(x))

            // recibe los parámetros
            if (route?.params) setValues({ ...values, ...route.params })
        }
    }, [navigation, route.params])
    useEffect(() => {
        setCompanies(dataCompanies?.getAllCompanies?.map(x => {
            return {
                cCode: x?.cCode,
                name: `${x?.cCode} - ${x?.cBusNam}`
            }
        }))
    }, [dataCompanies])
    // obtienes los valores de la empresa
    useEffect(() => {
        const res = dataC?.getOneCompanyId
        if (res?.companyCategories?.length && calC) setCategories(dataC?.getOneCompanyId?.companyCategories)

        return () => setCategories([])
    }, [dataC, calC, errC])

    // Cambio de los valores
    const handleChange = e => {
        setValues({ ...values, [e.name]: e?.value, ...(e.name === 'tcId' ? { tcName: e?.item?.tcName || undefined } : {}) })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }
    // Suelta focus en los inputs
    const handleBlur = e => setErrorForm({ ...errorForm, [e.name]: e.error })

    /**
     * onBlur del input
     * @param {object} e evento del input
     * @return {void}
     */
    const handleBlurCode = e => {
        setErrorForm({ ...errorForm, [e.name]: e.error })
        getOneCompany({ variables: { cCode: e.value } })
    }

    // Submit
    const handleSignIn = async () => {
        setErrors(errorForm)

        // validaciones
        if (loadReg) return isLoggedVar({ ...isLoggedVar(), message: 'Espere por favor.' })
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos.' })
        if (values?.tcName?.toLowerCase() === 'temporal' && !values.pwDatEnd) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor revise los campos obligatorios.' })
        if (dataUP?.userParameters?.[0]?.enrollmentReceipt === 1 && !document) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor, cargue el comprobante.' })
        if (categories?.length && !values?.ccId) return isLoggedVar({ ...isLoggedVar(), message: 'Es necesario seleccionar una categoría.' })
        const { data, error } = await registerUser({
            variables: {
                input: {
                    cCode: values.cCode,
                    cId: values.cId,
                    token,
                    ccId: values.ccId,
                    userInput: {
                        tiId: values.tiId,
                        uIdeNum: `${values.uIdeNum}`,
                        uPass: values.uPass
                    },
                    payrollCompanyInput: {
                        tiId: values.tiId,
                        pcIdeNum: `${values.uIdeNum}`
                    },
                    payrollStaffInput: {
                        psFirNam: values.psFirNam,
                        psSecNam: values.psSecNam,
                        psFirLas: values.psFirLas,
                        psSecLas: values.psSecLas,
                        psEmail: values.psEmail,
                        psPhoMob: values.psPhoMob
                    },
                    payrollWorkInput: {
                        pwPosition: values.pwPosition,
                        tcId: values.tcId,
                        pwDatSta: values.pwDatSta,
                        pwSalary: parseInt(values.pwSalary),
                        pwNet: parseInt(values.pwNet),
                        pwEmbargoes: values.pwEmbargoes
                    },
                    payrollDocumentInput: {
                        pdVoucher:  document?.name
                    }
                }
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        const res = data?.registerUserPayrollCompany
        // verifica la respuesta
        if (res?.pcId) {
            const { cId, uId, pcId, tokenAuth } = res

            // verifica si se enviara el documento
            if (document) {
                setLoading(true)
                await putFileS3(`companys/${cId}/payroll/${pcId}/documents/${document.name}`, document.file)
                setLoading(false)
            }

            // lo envia a confirmar su cuenta
            if (uId) {
                // lo manda activar la cuenta
                isLoggedVar({
                    state: false,
                    expired: false,
                    message: 'Se ha registrado pero es necesario activar su cuenta.'
                })
                return navigation.navigate('ConfirmEmail', { uId, psEmail: values.psEmail, tiId: values.tiId, uIdeNum: values.uIdeNum })
            }

            // reinicia los estados
            setValues({})
            setErrorForm({
                cCode: true,
                cId: true,
                psFirNam: true,
                psFirLas: true,
                pwPosition: true,
                tcId: true,
                pwDatSta: true,
                pwSalary: true,
                pwNet: true,
                pwEmbargoes: true,
                tbId: true,
                atId: true,
                pbAccNum: true
            })

            // guarda el token
            await setData('tokenAuth', tokenAuth)

            // inicia sesión
            isLoggedVar({ state: true, expired: false, message: 'Se ha registrado exitosamente.' })
            return navigation.navigate('Home')
        }
    }

    return (
        <RegisterUserPayrollV
            data={{
                errors,
                values,
                document,
                isVisible,
                navigation,
                categories,
                showFooter,
                loading: loading || loadReg,
                dataUP,
                enrollmentReceipt: dataUP?.userParameters?.[0]?.enrollmentReceipt,
            }}
            dataM={dataM}
            dataCompanies={companies}
            navigation={navigation}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSignIn={handleSignIn}
            handleBlurCode={handleBlurCode}
            handleDocument={() => setIsVisible(!isVisible)}
        />
    )
}

RegisterUserPayrollC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}