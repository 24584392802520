import React from 'react';
import { Container, ViewPayment, Header, CreditNameText, PaymentButton, Row } from './styled';
import PropTypes from 'prop-types'
import { numberFormat } from '../../validation';
import { TextInformation } from './styled';
import moment from 'moment';
import { SelectHook } from '../SelectHook';
import { View } from 'react-native';

export const PaymentSummaryV = ({ route, openCheckout, data: { dataInvoice, total, totalBalance, loadCalculateFee, calculateFee }, handleChangeSelect, dataValues, cancel }) => {
    const { creditName, consecutiveCredit, creditFirstFee, cPayDat, cState, creditOrigins, cValCou } = route.params;
    return (
        <Container>
            {cState === 6 && <View style={{ width: '90%' }}>
                <SelectHook
                    required
                    nameAtt='title'
                    name='paymentMethod'
                    title='Método de pago'
                    titleModal='Seleccionar Opción'
                    value={dataValues?.paymentMethod}
                    handleSelect={handleChangeSelect}
                    data={[{ paymentMethod: 'fee', title: 'PAGO DE CUOTA' }, { paymentMethod: 'total', title: 'PAGO TOTAL' }]}
                />
            </View>}
            <ViewPayment>
                <Header>
                    <CreditNameText>{creditName}</CreditNameText>
                    <TextInformation>Obligación {consecutiveCredit}</TextInformation>
                    <TextInformation>Vencimiento {cPayDat ? moment(cPayDat).format('DD-MM-YYYY') : moment(creditFirstFee).format('DD-MM-YYYY')}</TextInformation>
                    {dataValues?.paymentMethod === 'fee' &&
                    <Row>
                        <TextInformation>Vr cuota estimada </TextInformation>
                        <TextInformation>$ {numberFormat(cValCou)}</TextInformation>
                    </Row>
                    }
                    {dataValues?.paymentMethod === 'total' &&
                    <Row>
                        <TextInformation>Total saldo </TextInformation>
                        <TextInformation>$ {numberFormat(totalBalance)}</TextInformation>
                    </Row>
                    }
                    <Row>
                        <TextInformation>Gastos de cobranza </TextInformation>
                        <TextInformation>$ {numberFormat(calculateFee?.totalCollectionCostFee)}</TextInformation>
                    </Row>
                    <Row>
                        <TextInformation>Intereses de mora </TextInformation>
                        <TextInformation>{dataValues?.paymentMethod === 'fee' ? `$ ${numberFormat(calculateFee?.totalInterestArrears)}` : `$ ${numberFormat(calculateFee?.totalArrearsCredit)}`}</TextInformation>
                    </Row>
                    <Row>
                        <TextInformation>{dataValues?.paymentMethod === 'fee' ? 'Total cuota estimada' : 'Total a pagar'}</TextInformation>
                        <TextInformation>{dataValues?.paymentMethod === 'fee' ? `$ ${numberFormat(calculateFee?.totalFee)}` : `$ ${numberFormat(total)}`}</TextInformation>
                    </Row>
                </Header>
                <PaymentButton onPress={openCheckout} disabled={!dataInvoice || loadCalculateFee}>
                    {(cState === 6) ? <TextInformation style={{ margin: '0', color: '#ffff' }}>{dataValues?.paymentMethod === 'fee' ? `Pagar cuota $${numberFormat(calculateFee?.totalFee)}` : `Total a pagar  $${numberFormat(total)}`}</TextInformation> :
                        <TextInformation style={{ margin: '0', color: '#ffff' }}>{`Valor a pagar $${numberFormat(creditOrigins[0]?.coAmount?.toFixed())}`}</TextInformation>}
                </PaymentButton>
                <TextInformation>¡Paga fácil y rápido!</TextInformation>
                <PaymentButton style={{ backgroundColor: 'transparent', border: '1px #9094be solid' }} onPress={cancel}>
                    <TextInformation style={{ margin: '0', fontWeight: '700' }}>Cancelar</TextInformation>
                </PaymentButton>
                <TextInformation>Saldo fecha de corte.</TextInformation>
            </ViewPayment>
        </Container>
    )
}

PaymentSummaryV.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
    handleChangeSelect: PropTypes.fun,
    cancel: PropTypes.fun,
    dataValues: PropTypes.object,
    openCheckout: PropTypes.func,
    data: PropTypes.object
}