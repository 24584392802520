/**
 * calcula el valor total del crédito
 * @param {object} args argumentos para el calculo del crédito
 * @return {object} nuevos argumentos
 */
export const calculator = ({ attributes, money, mon, iva, ctModule, rPor, ratMon }) => {
    /** variables necesarias */
    let valCre = money
    let valCou = 0
    let por = 0
    let statusBond
    let statusLife
    let tlm
    /** verifica que existan atributos del crédito */
    for (let i = 0; i < attributes?.length; i++) {
        /** almacena en una variable más corta los valores del crédito */
        const { typeAttribute: ta } = attributes[i]
        if (ta?.taType === 3) continue
        /** formulas */
        if (ta.taFormula === 1) {
            /** Calcula el valor */
            const value = ta.taValue * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** iva o sin  iva */
            if (ta.taType === 1) valCre += value
            else valCou += value
        } else if (ta.taFormula === 2) {
            statusBond = true
            /** Calcula el valor */
            const value = ((ta.typeBond.bonds[0]?.bPor / 100) * money) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) valCre += value
            else valCou += value
        } else if (ta.taFormula === 3) {
            /** Calcula el valor */
            const value = (ta.salary.sValue * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) valCre += value
            else valCou += value
        } else if (ta.taFormula === 5) {
            /** Calcula el valor */
            const value = ((money * ta.taValue) / ta.taValue2) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) valCre += value
            else valCou += value
        } else if (ta.taFormula === 6) {
            /** Calcula el valor */
            const value = (money * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            if (ta.taType === 1) valCre += value
            else valCou += value
        } else if (ta.taFormula === 7) {
            /** Calcula el valor */
            let value = (money * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            const minimum = ta.taMinimum * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            value = value > ta.taMinimum ? value : minimum
            if (ta.taType === 1) valCre += value
            else valCou += value
        } //atencia estas formulas que estan abajo de este comentario se estaban recorriendo abajo
        /** formulas */
        else if (ta.taFormula === 8) {
            /** Calcula el valor */
            const value = (valCre * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** Busca el % comparado al desembolso */
            const rate = value / valCre
            if (ta.taType === 1) por += rate
            else valCou += value
        } else if (ta.taFormula === 9) {
            /** Calcula el valor */
            const value = ((valCre) * (ta.taPor / 100)) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            const minimum = ta.taMinimum * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)

            /** Busca el % comparado al desembolso */
            const rate = value > minimum ? (value / valCre) : (minimum / valCre)
            if (ta.taType === 1) por += rate
            else valCou += value > ta.taMinimum ? value : minimum
        } else if (ta.taFormula === 10) {
            statusBond = true
            /** Calcula el valor */
            const value = ((ta.typeBond.bonds[0]?.bPor / 100) * valCre) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
            /** Busca el % comparado al desembolso */
            const rate = value / valCre
            if (ta.taType === 1) por += rate
            else valCou += value
        } else if (ta.taFormula === 4) {
            /** el seguro de vida, necesita el valor del crédito final por lo que es de ultimo el proceso */
            statusLife = true
            if (ta.taType === 1) {
                const lm = ta.typeLifeMillion.lifeMillions[0]
                if (ta.typeLifeMillion.tlmFormula === 1) {
                    const value = (((valCre / lm.lmValue) * lm.lmValueTwo) * mon) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
                    const rate = value / valCre
                    if (ta.taType === 1) por += rate
                    else valCou += value
                } else if (ta.typeLifeMillion.tlmFormula === 2) {
                    const value = ((lm.lmPor / 100) * valCre * mon) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
                    const rate = value / valCre
                    if (ta.taType === 1) por += rate
                    else valCou += value
                } else if (ta.typeLifeMillion.tlmFormula === 3) {
                    const value = lm.lmValue * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
                    const rate = value / valCre
                    if (ta.taType === 1) por += rate
                    else valCou += value
                } else if (ta.typeLifeMillion.tlmFormula === 4) {
                    const value = ((lm.lmPor / 100) * valCre) * ((ta.taVat === 1) ? (1 + (iva / 100)) : 1)
                    const rate = value / valCre
                    if (ta.taType === 1) por += rate
                    else valCou += value
                }
            } else tlm = ta
        }
    }

    /** calcula el valor del crédito si existe porcentaje con nueva formula */
    valCre /= (1 - por)

    // calcula el seguro de vida si aplica a cuota, luego de calcular el valor total del crédito.
    if (tlm?.taType === 2) {
        const lm = tlm.typeLifeMillion.lifeMillions[0]
        if (tlm.typeLifeMillion.tlmFormula === 1) {
            const value = (((valCre / lm.lmValue) * lm.lmValueTwo) * mon) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            const rate = value / valCre
            if (tlm.taType === 1) por += rate
            else valCou += value
        } else if (tlm.typeLifeMillion.tlmFormula === 2) {
            const value = ((lm.lmPor / 100) * valCre * mon) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            const rate = value / valCre
            if (tlm.taType === 1) por += rate
            else valCou += value
        } else if (tlm.typeLifeMillion.tlmFormula === 3) {
            const value = lm.lmValue * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            const rate = value / valCre
            if (tlm.taType === 1) por += rate
            else valCou += value
        } else if (tlm.typeLifeMillion.tlmFormula === 4) {
            const value = ((lm.lmPor / 100) * valCre) * ((tlm.taVat === 1) ? (1 + (iva / 100)) : 1)
            const rate = value / valCre
            if (tlm.taType === 1) por += rate
            else valCou += value
        }
    }
    /** saca el valor de la cuota */
    let sumCou = 0
    if (rPor > 0) sumCou = (((rPor / 100) * ratMon) * valCre) / (1 - Math.pow((1 + ((rPor / 100) * ratMon)), (ctModule === 1 ? -1 : -mon)))
    else sumCou = valCre / mon
    valCou += sumCou
    /** retorna los valores */
    return {
        valCre: Math.round(valCre),
        valCou: valCou > 0 ? Math.round(valCou) : 0,
        statusBond,
        statusLife
    }
}