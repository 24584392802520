import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={381}
        height={381}
        fill="none"
        viewBox="0 0 980 380"
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fill="#4EB9B4"
                d="M190.71 381.38c105.21 0 190.5-85.29 190.5-190.5S295.92.38 190.71.38.21 85.67.21 190.88s85.29 190.5 190.5 190.5Z"
            />
            <Path
                fill="#2D8AC4"
                d="M190.71 346.68c86.046 0 155.8-69.754 155.8-155.8s-69.754-155.8-155.8-155.8-155.8 69.754-155.8 155.8 69.754 155.8 155.8 155.8Z"
            />
            <Path
                fill="#1E3467"
                d="M190.71 310.18c65.888 0 119.3-53.412 119.3-119.3 0-65.887-53.412-119.3-119.3-119.3-65.887 0-119.3 53.413-119.3 119.3 0 65.888 53.413 119.3 119.3 119.3Z"
            />
            <Path
                fill="#fff"
                d="M233 205.671v23.639a2.821 2.821 0 0 1-2.821 2.821h-103.92c0-14.613 11.847-26.46 26.46-26.46H233ZM233 147.577v23.64h-64.438c0-14.615 11.846-26.461 26.46-26.461h35.158a2.82 2.82 0 0 1 2.82 2.821ZM205.634 196.828c0-9.106 4.743-17.121 11.884-21.72h-44.409c-14.614 0-26.461 11.847-26.461 26.461h58.986v-4.741Z"
            />
            <Path
                fill="#fff"
                d="M233 201.569h-23.263v-4.741c0-11.996 9.725-21.72 21.72-21.72H233v26.461Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h381v381H0z" />
            </ClipPath>
        </Defs>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}