import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome, MaterialCommunityIcons, Entypo, Octicons } from '@expo/vector-icons'
/** personal imports */
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, Box } from './styled'
import { ViewPhoto } from '../ViewPhoto'
import theme from '../../../ignore/Constants'
import { hp } from '../../validation'
import { InputHookText } from '../InputHookText'

export const DocumentDataRegisterV = ({
    data: {
        banner,
        infoCamera,
        values,
        valuesMessage,
        files,
        loading,
        format,
        loadPD,
        loadFD,
        loadRD,
        loadSD,
        loadPRD,
        loadBD,
        loadCD,
        loadCAD,
        loadBRD,
        loadBCD,
        loadRut,
        loadCC,
        loadIP,
        filePD,
        fileFD,
        fileRD,
        fileSD,
        filePRD,
        fileBD,
        fileCD,
        fileCAD,
        fileBRD,
        fileBCD,
        fileRut,
        fileCC,
        fileIP,
        loadND,
        fileND,
    },
    handleInfo,
    handleSubmit,
    handleButton,
    dataDocument,
    handleChange,
}) => (
    <Container>
        {banner}
        <Form>
            {!!format?.cpfFroDoc && <ViewPhoto
                loadingDoc={loadFD}
                nameFile={values?.creditPayrollDocument?.cpdFroDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Cédula frontal'
                uri={files?.cpdFroDocA || fileFD}
                icon={<FontAwesome name='vcard' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(1)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdFroDoc', 'cpdFroDocA')}
            />}
            {!!format?.cpfReaDoc && <ViewPhoto
                loadingDoc={loadRD}
                nameFile={values?.creditPayrollDocument?.cpdReaDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Cédula por detrás'
                uri={files?.cpdReaDocA || fileRD}
                icon={<MaterialCommunityIcons name='card-bulleted' size={hp(26)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(2)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdReaDoc', 'cpdReaDocA')}
            />}
            {!!format?.cpfPerDoc && <ViewPhoto
                loadingDoc={loadPD}
                nameFile={values?.creditPayrollDocument?.cpdPerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Selfie con Cédula frontal'
                uri={files?.cpdPerDocA || filePD}
                icon={<Entypo name='v-card' size={hp(24)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(3)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdPerDoc', 'cpdPerDocA')}
            />}
            {!!format?.cpfSerDoc && <ViewPhoto
                loadingDoc={loadSD}
                nameFile={values?.creditPayrollDocument?.cpdSerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title={`Servicios Públicos${'\n'} (Luz, Agua o Gas)`}
                uri={files?.cpdSerDocA || fileSD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(4)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdSerDoc', 'cpdSerDocA')}
            />}
            {!!format?.cpfPayRemDoc && <ViewPhoto
                loadingDoc={loadPRD}
                nameFile={values?.creditPayrollDocument?.cpdPayRemDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Desprendible de Nómina'
                uri={files?.cpdPayRemDocA || filePRD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(5)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdPayRemDoc', 'cpdPayRemDocA')}
            />}
            {!!format?.ufBanDoc && <ViewPhoto
                loadingDoc={loadBD}
                nameFile={values?.payrollDocument?.pdBanDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Certificado Bancario'
                uri={files?.pdBanDocA || fileBD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(6)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdBanDoc', 'pdBanDocA')}
            />}
            {!!format?.ufConDoc && <ViewPhoto
                loadingDoc={loadCD}
                nameFile={values?.payrollDocument?.pdConDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Contrato Laboral'
                uri={files?.pdConDocA || fileCD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(7)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('pdConDoc', 'pdConDocA')}
            />}
            {!!format?.cpfComActDoc && <ViewPhoto
                loadingDoc={loadCAD}
                nameFile={values?.creditPayrollDocument?.cpdComActDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Acta de Inicio'
                uri={files?.cpdComActDocA || fileCAD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(8)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdComActDoc', 'cpdComActDocA')}
            />}
            {!!format?.cpfBudRecDoc && <ViewPhoto
                loadingDoc={loadBRD}
                nameFile={values?.creditPayrollDocument?.cpdBudRecDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Registro de Presupuesto'
                uri={files?.cpdBudRecDocA || fileBRD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(9)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdBudRecDoc', 'cpdBudRecDocA')}
            />}
            {!!format?.cpfBudCerDoc && <ViewPhoto
                loadingDoc={loadBCD}
                nameFile={values?.creditPayrollDocument?.cpdBudCerDoc}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Certificado de Presupuesto'
                uri={files?.cpdBudCerDocA || fileBCD}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(10)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdBudCerDoc', 'cpdBudCerDocA')}
            />}
            {!!format?.cpfRut && <ViewPhoto
                loadingDoc={loadRut}
                nameFile={values?.creditPayrollDocument?.cpdRut}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='RUT'
                uri={files?.cpdRutA || fileRut}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(11)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdRut', 'cpdRutA')}
            />}
            {!!format?.cpfComChamber && <ViewPhoto
                loadingDoc={loadCC}
                nameFile={values?.creditPayrollDocument?.cpdComChamber}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Camara de Comercio'
                uri={files?.cpdComChamberA || fileCC}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(12)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdComChamber', 'cpdComChamberA')}
            />}
            {!!format?.cpfInstitutionPic && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.creditPayrollDocument?.cpdInstitutionPic}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Foto de la Institución'
                uri={files?.cpdInstitutionPicA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(13)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdInstitutionPic', 'cpdInstitutionPicA')}
            />}
            {!!format?.cpfWorkCertificate && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.creditPayrollDocument?.cpdWorkCertificate}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Certificado laboral'
                uri={files?.cpdWorkCertificateA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(10)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdWorkCertificate', 'cpdWorkCertificateA')}
            />}
            {!!format?.cpfBankStatement && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdBankStatement}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Flujo de caja - Extracto bancario'
                uri={files?.pdBankStatementA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(14)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdBankStatement', 'cpdBankStatementA')}
            />}
            {!!format?.cpfFinancialStatements && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdFinancialStatements}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Estados financieros'
                uri={files?.pdFinancialStatementsA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(15)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdFinancialStatements', 'cpdFinancialStatementsA')}
            />}
            {!!format?.cpfIncomeTaxReturn && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdIncomeTaxReturn}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Declaración de renta'
                uri={files?.pdIncomeTaxReturnA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(16)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdIncomeTaxReturn', 'cpdIncomeTaxReturnA')}
            />}
            {!!format?.cpfBoardDirectors && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdBoardDirectors}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Acta junta directiva'
                uri={files?.pdBoardDirectorsA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(17)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdBoardDirectors', 'cpdBoardDirectorsA')}
            />}
            {!!format?.cpfTradeBalance && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdTradeBalance}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Balance comercial'
                uri={files?.pdTradeBalanceA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(18)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdTradeBalance', 'cpdTradeBalanceA')}
            />}
            {!!format?.cpfRealEstateRegistration && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdRealEstateRegistration}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Matricula inmobiliaria'
                uri={files?.pdRealEstateRegistrationA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(19)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdRealEstateRegistration', 'cpdRealEstateRegistrationA')}
            />}
            {!!format?.cpfVehicleOwnershipCard && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdVehicleOwnershipCard}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Tarjeta propiedad vehículo'
                uri={files?.pdVehicleOwnershipCardA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(20)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdVehicleOwnershipCard', 'cpdVehicleOwnershipCardA')}
            />}
            {!!format?.cpfCommercialReference && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdCommercialReference}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Referencia comercial'
                uri={files?.pdCommercialReferenceA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(21)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdCommercialReference', 'cpdCommercialReferenceA')}
            />}
            {!!format?.cpfBankReference && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdBankReference}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Referencia bancaria'
                uri={files?.pdBankReferenceA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(22)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdBankReference', 'cpdBankReferenceA')}
            />}
            {!!format?.cpfIncomeWithholdings && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdIncomeWithholdings}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Certificado de ingresos y retenciones'
                uri={files?.pdIncomeWithholdingsA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(23)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdIncomeWithholdings', 'cpdIncomeWithholdingsA')}
            />}
            {!!format?.cpfCadastreAndValuation && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdCadastreAndValuation}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Paz salvo catastro y valorización'
                uri={files?.pdCadastreAndValuationA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(24)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdCadastreAndValuation', 'cpdCadastreAndValuationA')}
            />}
            {!!format?.cpfCommercialAppraisal && <ViewPhoto
                loadingDoc={loadIP}
                nameFile={values?.payrollDocument?.cpdCommercialAppraisal}
                iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                title='Avaluó comercial'
                uri={files?.pdCommercialAppraisalA || fileIP}
                icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                handleInfo={() => handleInfo(25)}
                titleModal={infoCamera.title}
                iconModal={infoCamera.icon}
                steps={infoCamera.steps}
                handleButton={() => handleButton('cpdCommercialAppraisal', 'cpdCommercialAppraisalA')}
            />}
            {
                dataDocument?.map((x, i) => {
                    const cpcodIdA = `document[${i}].cpcodIdA`
                    const cpcodId = `document[${i}].cpcodId`
                    if (x?.cpcodState === 1) {
                        return (
                            <Box key={i}>
                                <ViewPhoto
                                    loadingDoc={loadND}
                                    nameFile={values?.creditPayrollDocument?.[cpcodId] || x?.cpcodFileName}
                                    iconFile={<FontAwesome name="image" size={hp(15)} color={theme.primaryC} />}
                                    title={x?.cpcodName}
                                    uri={files?.[cpcodIdA] || fileND[i]}
                                    icon={<Octicons name='file' size={hp(20)} color={theme.onVariantSurfC} />}
                                    handleInfo={() => handleInfo(10)}
                                    titleModal={infoCamera.title}
                                    iconModal={infoCamera.icon}
                                    steps={infoCamera.steps}
                                    handleButton={() => handleButton(`document[${i}].cpcodId`, `document[${i}].cpcodIdA`)}
                                />
                            </Box >
                        )
                    }
                    return null
                })
            }
            <InputHookText title='Agregar comentario' value={valuesMessage?.ccmMessage} name='ccmMessage' onChangeText={handleChange} />
            <BoxButton>
                <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

DocumentDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleButton: PropTypes.func,
    handleAdd: PropTypes.func,
    handleDelete: PropTypes.func,
    handleChange: PropTypes.func,
    dataDocument: PropTypes.array
}