import { gql, useApolloClient, useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { createContext, useState, useEffect } from 'react'
import { isLoggedVar } from './src/apollo/cache'
import { getData } from './src/validation'
export const Context = createContext()

const Provider = ({ children }) => {
    const [expired, setExpired] = useState(false)
    const [message, setMessage] = useState(false)
    const [balance, setBalance] = useState(0)
    const [timeOutActive, setTimeOutActive] = useState(false)
    const client = useApolloClient()
    const [getUserLogged, { data, called, error }] = useLazyQuery(gql`
        query IsUserLoggedIn {
            isLogged @client
        }`
    )

    useEffect(() => {
        getUserLogged()
    }, [children])

    useEffect(() => {
        if (called && data?.isLogged && !error) {
            getData('tokenAuth').then(res => {
                if (!res) client.cache.gc()
                const { expired: expiredLog, message: messageLog } = isLoggedVar()
                isLoggedVar({ expired: expiredLog, state: !!res, message: false })
                setExpired(expiredLog)
                setTimeOutActive(true)
                !message && setMessage(messageLog)
                !timeOutActive && setTimeout(() => {
                    setMessage(false)
                    setTimeOutActive(false)
                }, 3000)
            })
        }
    }, [data, called, isLoggedVar, error])

    const values = {
        setMessage,
        expired,
        message,
        balance,
        setBalance
    }

    return (
        <Context.Provider value={values}>
            {children}
        </Context.Provider>
    )
}

export default {
    Provider,
    Consumer: Context.Consumer
}

Provider.propTypes = {
    children: PropTypes.node
}