import { gql, useQuery } from '@apollo/client'

export const USE_ALL_INSURANCE_ENTITIES = ({state, order, limit}) => useQuery(gql`
        query GetAllInsuranceEntities($state: [Int], $order: [[String]], $limit: [Int]) {
        getAllInsuranceEntities(state: $state, order: $order, limit: $limit) {
          ieId
          ieName
  }
}
`, {
    variables: { state, order, limit }
})