import styled, { css } from 'styled-components/native'
import Constants from 'expo-constants'
import { mq } from '../src/validation'

export const Header = styled.View`
    width: 100%;
    height: ${mq(55) + Constants.statusBarHeight}px;
    background-color: ${props => props.theme.BGSColor};
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    padding: ${mq(5)}px ${mq(5)}px ${mq(5)}px ${mq(10)}px;
`
export const Row = styled.View`
    flex-direction: row;
`
export const OptionButton = styled.TouchableOpacity`
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    margin: ${mq(10)}px ${mq(10)}px;
    padding: ${mq(5)}px ${mq(15)}px;
    border-radius: ${mq(16)}px;

    background-color: ${ ({ active, theme }) => active ? theme.PColor : theme.BGColor };
`
export const EndBox = styled.View`
    margin-bottom: ${mq(4)}px;
    border-top-color: #f4f4f4;
    border-top-width: 1px;
`
export const IconBox = styled.View`
    width: ${mq(20)}px;
    align-items: center;
	justify-content: center;
`
export const IconBannerBox = styled.View`
    width: ${mq(40)}px;
    height: ${mq(40)}px;
    align-items: center;
	border-radius: ${mq(300)}px;
    background: ${({ bgColor, theme }) => bgColor || theme.PColor};
	justify-content: center;
    margin: 0 ${mq(10)}px;
`
export const OptionButtonMenu = styled.View`
    flex-direction: row;
    padding: ${mq(10)}px ${mq(16)}px ${mq(10)}px ${mq(10)}px;
`
export const TextBox = styled.Text`
    color: ${props => props.theme.BGColor};
    font-size: ${mq(13)}px;
    font-family: PFontLight;
`
export const TextBoxIcon = styled.View`
    align-items: center;
    justify-content: center; 
`
export const Icon = styled.View`
    margin-left: auto;
`
export const BoxCircle = styled.View`
    width: ${mq(28)}px;
    height: ${mq(28)}px;
	justify-content: center;
`
export const Text = styled.Text`
    width: 50%;
    text-align: left;
    color: ${({ theme, color }) => color || theme.SFColor};
    font-size: ${mq(13)}px;
    font-family: PFontRegular;
    margin: ${({ margin }) => margin ? margin : `${mq(10)}px ${mq(20)}px`};
`
export const Button = styled.TouchableOpacity`
    padding: ${props => props.padding || mq(4)}px;
    margin-top: ${({ marginTop }) => marginTop || mq(6)}px;
    justify-content: ${({ align }) => align || 'center'};
`
export const IconBar = styled.View`
    align-items: center;
    width: ${mq(35)}px;
    justify-content: center;
    ${ props => props.active && css`
        border-bottom-color: ${ props.theme.PColor };
        border-bottom-width: 1px;
    ` }
`
export const IconMBar = styled.View`
    position: relative;
    z-index: 99;
    align-items: center;
    justify-content: center;
    width: ${mq(65)}px;
    height: ${mq(65)}px;
    border-radius: ${mq(300)}px;
    margin-bottom: ${mq(40)}px;
    background-color: ${ props => props.theme.PColor };
`
export const OptionsBox = styled.View`
    justify-content: center;
    width: 100%;
    height: ${mq(90)}px;
    padding: ${mq(10)}px ${mq(15)}px;
    background-color: ${props => props.theme.PColor};
`
export const ContainerUser = styled.View`
    padding: ${mq(15)}px ${mq(10)}px;
    flex-direction: row;
    align-items: center;
`
export const BoxProfile = styled.View`
    width: 80%;
    margin-left: ${mq(10)}px;
    flex-direction: column;
`
export const DrawerSection = styled.View`
    margin-top: ${mq(15)}px;
`
export const Title = styled.Text`
    font-size: ${({ size }) => size ? size : mq(14)}px;
    color: ${({ color, theme }) => color ? color : `${theme.SFColor}`};
    font-family: ${({ family }) => family || 'PFontBold'};
`
export const styles = {
    gravatar: {
        width: mq(25),
        height: mq(25),
        borderRadius: mq(300),
    },
    gravatarDrawer: {
        width: mq(40),
        height: mq(40),
        borderRadius: mq(50),
    },
    tab: {
        backgroundColor: '#FFFFFF',
        paddingVertical: mq(5),
        borderTopLeftRadius: mq(6),
        borderTopRightRadius: mq(6)
    }
}

// estilo de modal alert
export const ContainerAlert = styled.View`
    flex: 1;
    background-color: ${props => props.theme.BGPColor};
    margin: ${mq(20)}px;
    border-radius: ${mq(6)}px;
    display: none;
`
export const BoxHead = styled.View`
    flex-direction: row;
    background-color: ${props => props.theme.PColor};
    justify-content: space-between;
    padding: ${mq(6)}px;
    border-top-right-radius: ${mq(6)}px;
    border-top-left-radius: ${mq(6)}px;
`
export const TitleAlert = styled.Text`
    font-size: ${mq(18)}px;
    font-family: PFontBold;
    margin-left: ${mq(18)}px;
    border-radius: ${mq(6)}px;
    color: ${props => props.theme.BGColor};
`
export const SubTitleAlert = styled.Text`
    font-size: ${mq(14)}px;
    color: ${props => props.theme.SFColor};
    margin: ${mq(6)}px 0 ${mq(20)}px ${mq(15)}px;
    font-family: PFontBold;
    text-align: center;
`
export const BoxText = styled.View`
    padding-top: ${mq(18)}px;
    justify-content: center;
    background-color: ${props => props.theme.BGColor};
    align-items: center;
`
export const RowTouchable = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
`