import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, styles, Title, Parr, Footer } from './styled'
import { ButtonSubmit } from '../ButtonSubmit'
import { hp, wp } from '../../validation'

export const ConfirmEmailV = ({
    data: {
        email,
        timeQuery,
        adoParameters
    },
    handleBack,
    handleEmail,
    handleReSend,
    handleSignUp,
    handleEnrollment,
}) => (
    <Container>
        <Box style={styles.shadow}>
            <Title>Validación de identidad:</Title>
            <Parr style={{ fontStyle: 'italic' }}>¡Estas a unos pasos de completar tu registro!</Parr>
            <Parr style={{ textAlign: 'justify' }}>{adoParameters?.idAP ? 'Por favor valide su identidad' : `Hemos enviado un correo de confirmación a: ${email}`}</Parr>
            {adoParameters?.idAP && <ButtonSubmit
                radius={100}
                title='Validar aquí'
                onPress={handleEnrollment}
                margin={`${wp(8)}px 0 ${wp(4)}px`}
            />}
            <Parr style={{ marginBottom: hp(5) }}><Parr style={{ textDecoration: 'underline' }} onPress={handleEmail}>Ir al correo</Parr>{!!timeQuery && ` - ${timeQuery} s`}</Parr>
            <Parr style={{ marginBottom: hp(10) }}><Parr style={{ textDecoration: 'underline' }} onPress={handleReSend}>Reenviar mensaje</Parr>{!!timeQuery && ` - ${timeQuery} s`}</Parr>
            {/* {
                (Constants.manifest.extra.client === 'fie' || Constants.manifest.extra.client === 'gym') && <Parr style={{ marginBottom: hp(10) }}><Parr style={{ textDecoration: 'underline' }} onPress={handleEnrollment}>Por favor, valide su identidad</Parr>{!!timeQuery && ` - ${timeQuery} s`}</Parr>
            } */}
            <Footer>
                <Parr>Si su dirección de correo está errada <Parr style={{ textDecoration: 'underline' }} onPress={handleBack}>modifique</Parr> y vuelva a confirmar.</Parr>
            </Footer>
        </Box>
        <Footer style={{ width: wp(80) }}>
            <Parr>¿Deseas ir a <Parr style={{ textDecoration: 'underline' }} onPress={handleSignUp}>Inicio de Sesión</Parr>?</Parr>
        </Footer>
    </Container>
)

ConfirmEmailV.propTypes = {
    data: PropTypes.object.isRequired,
    handleBack: PropTypes.func,
    handleEmail: PropTypes.func,
    handleReSend: PropTypes.func,
    handleSignUp: PropTypes.func,
    handleEnrollment: PropTypes.func,
}