// Modules
import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Constants from 'expo-constants'
import { useTheme } from 'styled-components/native'
import DateTimePicker from '@react-native-community/datetimepicker'

// Styles and Utils
import { Container, TextError, Title, Button, TextButton, BoxInput, LeftBorder } from './styled'
import { dateFormat, isNull, mq } from '../../validation'

/**
 * InputDateWeb Component
 * @param {any} props Properties InputDateWeb
 * @returns {ReactElement} InputDateWeb
 */
export const InputDateWeb = ({ testID, title, onChangeText, onBlur, value, name, editable = true, error, required, less, flex }) => {

    // Declarando el estado
    const theme = useTheme()
    const [focus, setFocus] = useState(false)
    const [message, setMessage] = useState(false)
    const [showDate, setShowDate] = useState(false)
    const year = new Date().getFullYear() - 17
    const web = Constants.platform.web

    // Función para activar el error
    const errorFunc = data => {
        setMessage(data)
    }

    /**
     * @description Función que para validar los campos de texto por el método onChange
     * @version 0.0.1
     * @param {object} e evento del metodo change
     * @param {string} selected evento de devolución en modo nativo
     * @return {boolean} devuleve true o false si la validación es correcta o incorrecta
     *
    */
    const validations = (e, selected) => {
        setShowDate(false)
        // Valida que el campo no sea nulo
        if (required) {
            if (isNull(web ? e.target.value : moment(selected).format('YYYY-MM-DD'))) errorFunc('Campo requerido.')
            else errorFunc(false)
        }
        e?.type !== 'dismissed' && onChangeText({ name, value: web ? e.target.value : moment(e.nativeEvent?.timestamp).format('YYYY-MM-DD'), error: !!message })
    }
    const validationsBlur = () => {
        // Valida que el campo no sea nulo
        if (required) {
            if (isNull(value)) errorFunc('Campo requerido.')
            else errorFunc(false)
        }
        setFocus(false)
        onBlur && onBlur({ name, value, error: !!message })
    }

    return (
        <Container flex={flex}>
            {<Title numberOfLines={1}>{title}</Title>}
            {web ?
                <BoxInput borderColor={focus ? theme.PColor : ((message || error) ? theme.EColor : theme.SFVColor)}>
                    <input
                        type='date'
                        data-testID={testID}
                        style={{ color: theme.SFSColor, fontFamily: 'PFontRegular', backgroundColor: 'transparent', borderWidth: 0, padding: ` ${ mq(6) }px ${mq(20)}px`, fontSize: `${mq(13)}px`, outline: 0, width: '90%' }}
                        editable={`${editable}`}
                        onBlur={validationsBlur}
                        onChange={validations}
                        value={value || ''}
                        onFocus={() => setFocus(true)}
                    />
                </BoxInput>
                :
                <Button onFocus={() => setFocus(true)} onPress={() => setShowDate(true)} borderColor={focus ? theme.PColor : ((message || error) ? theme.EColor : theme.SFVColor)}>
                    <TextButton numberOfLines={1}>{!!value && dateFormat(value)}</TextButton>
                    {!!showDate && <DateTimePicker
                        value={value ? new Date(value) : new Date()}
                        testID={testID}
                        maximumDate={less ? new Date(year, 12, 31) : undefined}
                        mode='date'
                        locale='es-ES'
                        display='default'
                        onChange={validations}
                    />}
                </Button>
            }
            <LeftBorder color={(!focus && (message || error)) ? theme.EColor : theme.SCColor} />
            {(!focus && (message || error)) && <TextError numberOfLines={1}>{message || 'Campo Requerido.'}</TextError>}
        </Container>
    )
}

InputDateWeb.propTypes = {
    testID: PropTypes.string,
    title: PropTypes.string,
    onChangeText: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    editable: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
    format: PropTypes.bool,
    flex: PropTypes.number,
    less: PropTypes.bool
}