import { gql } from '@apollo/client';

export const ONE_CREDIT_PAYROLL_COMPANY = gql`
    query getCreditPayrollCompanyById($cId: ID) {
        getCreditPayrollCompanyById(cId: $cId) {
            tiId
            pcId
            payrollCompany {
                pcId
                cId
            }
            cId
            cpcId
            typeIdentity {
                tiId
                tiName
            }
            creditPayrollCompanyOtherDocuments {
                cpcId
                cpcodFileName
                cpcodId
                cpcodName
                cpcodState
                cpcodDatCre
            }
            cpcCompanyId
            cpcBusNam
            cpcIdeNum
            cpcDatExp
            cpcDomExp
            creditPayrollStaff(state: [0, 1, 2, 3]) {
                cpcId
                cpsId
                cpsFirNam
                cpsSecNam
                cpsFirLas
                cpsSecLas
                cpsDatBir
                cpsPhoMob
                cpsPhoPer
                cpsEmail
                gId
                cId
                cpsState
            }
            creditPayrollRelationships {
                cpcId
                cprId
                rId
                cprName
                cprPercentage
                cprState
            }
            creditPayrollResidence {
                cpcId
                cprId
                cId
                taId
                cprNumDir
                cprNum
                cprNumHou
                cprInformation
                cprStratum
                thId
                dId
                alId
                msId
                cprState
            }
            creditPayrollWork {
                cpcId
                cpwId
                cpwPosition
                tcId
                cpwDatSta
                cpwDatEnd
                cpwSalary
                cpwNet
                cpwEmbargoes
                cpwOthInc
                cpwFinExp
                cpwPerExp
                cpwState
            }
            creditPayrollOPS {
                cpcId
                cpoId
                cpoContractor
                cpoObject
                cpoValCon
                cpoValDatSta
                cpoValDatEnd
                cpoState
            }
            creditPayrollReference {
                cpcId
                cprId
                cprNamFam
                cprLasFam
                rIdFam
                cprPhoFam
                cIdFam
                taIdFam
                cprNumDirFam
                cprNumFam
                cprNumHouFam
                cprInfFam
                cprNamPar
                cprLasPar
                cprPhoPar
                cIdPar
                taIdPar
                cprNumDirPar
                cprNumPar
                cprNumHouPar
                cprInfPar
                cprState
                cIdStu
                cprEmailStu
                cprFacultyStu
                cprIdeNumStu
                cprInfStu
                cprLasStu
                cprNamStu
                cprNumHouStu
                cprNumDirStu
                cprNumStu
                cprPhoStu
                gIdStu
                tiIdStu
                taIdStu
            }
            creditPayrollDocument {
                cpcId
                cpdId
                cpdPerDoc
                cpdFroDoc
                cpdReaDoc
                cpdSerDoc
                cpdPayRemDoc
                cpdBanDoc
                cpdConDoc
                cpdComActDoc
                cpdBudRecDoc
                cpdBudCerDoc
                cpdRut
                cpdComChamber
                cpdInstitutionPic
                cpdWorkCertificate
                cpdState
            }
            creditPayrollOtherInformation {
                cpoiId
                cpcId
                cpoiAffiliationNumber
                cpoiUsNationality
                cpoiUsAccount
                cpoiUsInvestmentAssets
                cpoiAmericanGreenCard
                cpoiBornUS
                cpoiLivedUS
                cpoiForeignCurrency
                cpoiActivity
                cpoiTaxObligations
                cpoiBiddingActivities
                cpoiAcceptInformation
                cpoiBreachContract
                cpoiCriminallyDeclared
                cpoiTruthfulStatements
                cpoiAcceptDocument
                cpoiInsuredValue
                cpoiPremiumValue
                cpoiInfoBiddingActivities
                cpoiDangerHisLife
                cpoiInfoDangerHisLife
                cpoiTakesMedicine
                cpoiInfoTakesMedicine
                cpoiEPS
                cpoiIPS
                cpoiTakeDrug
                cpoiInfoTakeDrug
                cpoiState
            }
            creditPayrollLifeInsurance {
                cpliId
                cpcId
                cpliCancer
                cpliVIH
                cpliStroke
                cpliRenIns
                cpliArtHyp
                cpliDiabetes
                cpliCorArtDis
                cpliHeaAtt
                cpliHasConAcqPhyMenLim
                cpliDiaTreDisOthThoMenAbo
                cpliDatDia
                cpliTreatment
                cpliActSta
                cpliIsPre
                cpliPrimitive
                cpliDisabilityPensioner
                cpliUseDrugsAlcohol
                cpliDiseaseName
                cpliConsequences
                cpliObservations
                cpliCurrentTreatment
                cpliTreatmentOrSurgery
                ptId
                cpliState
                ieId
            }
            creditPayrollEconomyActivities {
                cpeaId
                cpcId
                cpeaActivity
                cpeaCompanyName
                cpeaAddress
                cpeaTypeCompany
                cpeaWorkPhone
                cpeaEmail
                dId
                cId
                cpeaPosition
                cpeaAntiquity
                cpeaAntiquityIndependents
                cpeaBussiness
                cpeaChamberCommerce
                cpeaRUT
                cpeaPensionFund
                cpeaTypePension
                cpeaUniversity
                cpeaGenerateIncome
                cpeaState
                tcId
            }
            creditPayrollCoDebtors {
                cpcId
                cpcdId
                tiId
                cpcdIdeNum
                cpcdFirNam
                cpcdSecNam
                cpcdFirLas
                cpcdSecLas
                cpcdDatBir
                cpcdPhoMob
                cpcdPhoPer
                cpcdEmail
                gId
                cId
                taId
                cpcdNumDir
                cpcdNum
                cpcdNumHou
                cpcdInf
                alId
                msId
                thId
                dId
                cpcdCompany
                cpcdPosition
                tcId
                cpcdDatSta
                cpcdDatEnd
                cpcdSalary
                cpcdOthInc
                cpcdFinExp
                cpcdPerExp
                cpcdPerDoc
                cpcdFroDoc
                cpcdReaDoc
                cpcdSerDoc
                cpcdBanDoc
                cpcdLoaDoc
                cpcdState
            }
        }
    }
`;
export const ONE_CREDIT_PAYROLL_FORMAT = gql`
    query getCreditPayrollFormatById($cId: ID, $state: [Int]) {
        getCreditPayrollFormatById(cId: $cId, state: $state) {
            cpfId
            cpfName
            cpfTipIde
            cpfIdeNum
            cpfDatExpDoc
            cpfDomExpDoc
            cpfFirNam
            cpfSecNam
            cpfFirLas
            cpfSecLas
            cpfDatBir
            cpfPhoMob
            cpfPhoPer
            cpfEmail
            cpfGender
            cpfLocBir
            cpfLocation
            cpfAcaLev
            cpfMarSta
            cpfTypHou
            cpfDependents
            cpfPosition
            cpfOthInc
            cpfFinExp
            cpfPerExp
            cpfRefFam
            cpfRefPar
            cpfRelationship
            cpfOPS
            cpfPerDoc
            cpfFroDoc
            cpfReaDoc
            cpfSerDoc
            cpfPayRemDoc
            cpfComActDoc
            cpfBudRecDoc
            cpfBudCerDoc
            cpfRut
            cpfComChamber
            cpfInstitutionPic
            cpfWorkCertificate
            cpfBankStatement
            cpfFinancialStatements
            cpfIncomeTaxReturn
            cpfBoardDirectors
            cpfTradeBalance
            cpfRealEstateRegistration
            cpfVehicleOwnershipCard
            cpfCommercialReference
            cpfBankReference
            cpfIncomeWithholdings
            cpfCadastreAndValuation
            cpfCommercialAppraisal
            cpfLifeInsurance
            cpfOtherInformation
            cpfEconomyActivities
            cpfInsuranceEntities
            cpfState

        }
    }
`;

export const ONE_CREDIT_PAYROLL_FORMAT_CODEBTORS = gql`
    query getCreditPayrollFormatCoDebtorById($cId: ID, $state: [Int]) {
        getCreditPayrollFormatCoDebtorById(cId: $cId, state: $state) {
            cpfcdId
            cpfcdName
            cpfcdTipIde
            cpfcdIdeNum
            cpfcdFirNam
            cpfcdSecNam
            cpfcdFirLas
            cpfcdSecLas
            cpfcdDatBir
            cpfcdPhoMob
            cpfcdPhoPer
            cpfcdEmail
            cpfcdGender
            cpfcdLocation
            cpfcdAcaLev
            cpfcdMarSta
            cpfcdTypHou
            cpfcdDependents
            cpfcdCompany
            cpfcdPosition
            cpfcdTypCon
            cpfcdSalary
            cpfcdOthInc
            cpfcdPerExp
            cpfcdFinExp
            cpfcdPerDoc
            cpfcdFroDoc
            cpfcdReaDoc
            cpfcdSerDoc
            cpfcdBanDoc
            cpfcdLoaDoc
            cpfcdState
        }
    }
`;
export const EDIT_CREDIT = gql`
    mutation updateCredit($cId: ID, $input: IUpdateCredit!) {
        updateCredit(cId: $cId, input: $input) {
            cId
            cEdit
        }
    }
`;

export const USER_PARAMETERS = gql`
  query GetUserParametersById {
  getUserParametersById {
    cId
    nameButton
    embargoes
    enrollmentReceipt
    sarlaft
    lifeInsurance
  }
}
`;