import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import theme from '../../../ignore/Constants'
import { numberFormat, wp } from '../../validation'
import { BoxTitle, ButtonCard, Label, styles, LineCard, Row, BoxInfoCard } from './styled'

export const Head = ({ data: { values, seeCard }, handleFeeActive, handleCard }) => (
    <BoxTitle>
        <ButtonCard style={styles.shadow} onPress={handleFeeActive}>
            <LineCard bgColor={theme.primaryC} />
            <Row>
                <View>
                    <Label family='PFontLight'>Cuota a Incorporar</Label>
                    <Label size={wp(4.5)}>$ {numberFormat(values.fee)}</Label>
                </View>
                <BoxInfoCard bgColor={theme.primaryC} onPress={handleFeeActive}>
                    <Row>
                        <Label color={theme.backgroundC} size={wp(2.8)}>Calcular</Label>
                        <MaterialIcons name='calculate' size={wp(3)} color={theme.backgroundC} />
                    </Row>
                </BoxInfoCard>
            </Row>
        </ButtonCard>
        {seeCard &&
            <ButtonCard style={styles.shadow} onPress={handleCard}>
                <LineCard bgColor={theme.primaryC} />
                <Row>
                    <View>
                        <Label family='PFontLight'>Valor de Cartera</Label>
                        <Label size={wp(4.5)}>$ {numberFormat(values.card)}</Label>
                    </View>
                    <BoxInfoCard bgColor={theme.primaryC} onPress={handleCard}>
                        <Row>
                            <Label color={theme.backgroundC} size={wp(2.8)}>Calcular</Label>
                            <MaterialIcons name='calculate' size={wp(3)} color={theme.backgroundC} />
                        </Row>
                    </BoxInfoCard>
                </Row>
            </ButtonCard>
        }
    </BoxTitle>
)

Head.propTypes = {
    data: PropTypes.object,
    handleFeeActive: PropTypes.func,
    handleCard: PropTypes.func
}