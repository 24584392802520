import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GroupCreditV } from '../../components/GroupCredit'
import { useLazyQuery } from '@apollo/client'
import { ALL_GROUP_CREDIT_TYPES } from './queries'
import { isLoggedVar } from '../../apollo/cache'
import { numberFormat, setData } from '../../validation'
import { calculateCreditCard } from './calculateCreditCard'
import { calculateCreditFee } from './calculateCreditFee'
import { calculateCreditConsumption } from './calculateCreditConsumption'

export const GroupCreditC = ({ navigation, route }) => {
    const [fee, setFee] = useState(0)
    const [seeCard, setSeeCard] = useState(false)
    const [allCreditTypes, setAllCreditTypes] = useState([])
    const [oneCreditType, setOneCreditType] = useState({})
    const [cards, setCards] = useState([])
    const [values, setValues] = useState({})
    const [infoGroup, setInfoGroup] = useState({})
    const [errorCards, setErrorCards] = useState([])
    const [messageErrorFee, setMessageErrorFee] = useState('')
    const [messageErrorCard, setMessageErrorCard] = useState('')
    const [isVisibleFee, setIsVisibleFee] = useState(false)
    const [isVisibleCard, setIsVisibleCard] = useState(false)
    const [seePlan, setSeePlan] = useState(false)
    const [seeDetail, setSeeDetail] = useState(false)
    const [getAllGroupCreditTypes, { data: dataGCT, loading: loadGCT, error: errGCT, called: calGCT }] = useLazyQuery(ALL_GROUP_CREDIT_TYPES, { fetchPolicy: 'cache-and-network' })

    // Activa un evento para buscar las líneas de crédito
    useEffect(() => {
        if (!route?.params?.gcId) {
            isLoggedVar({ ...isLoggedVar(), message: 'Ha ocurrido un problema intente de nuevo.' })
            navigation.goBack()
        }
        // crea un evento para consultar la información de las líneas de crédito
        const unsubscribe = navigation.addListener('focus', () => {
            const { gcId, groupCreditTypes } = route.params
            getAllGroupCreditTypes({ variables: { gcId } })
            setFee(0)
            setCards([{ index: 0 }])
            setValues({})
            setErrorCards([{}])
            setMessageErrorFee('')
            setMessageErrorCard('')
            setIsVisibleFee(false)
            setIsVisibleCard(false)
            setSeePlan(false)
            setAllCreditTypes([])
            setOneCreditType({})
            setSeeCard(!!groupCreditTypes?.find(x => x.gctType === 'CARTERA'))
        })

        // elimina el evento
        return unsubscribe
    }, [navigation, getAllGroupCreditTypes, route])

    // captura la respuesta de las líneas de créditos
    useEffect(() => {
        const res = dataGCT?.groupCreditTypes

        if (!!res?.length && calGCT && !!values.fee && (seeCard ? !!values.card : true)) {
            let creditTypes = []
            let valCouDis = values.fee
            let totalReq = 0
            let totalCredit = 0
            let month = 0
            for (let i = 0; i < res.length; i++) {
                const { gctId, gctType, gctIntFee, creditType } = res[i]

                // calcula el crédito de cartera
                if (gctType === 'CARTERA') {
                    const calculateCC = calculateCreditCard({ ...creditType, totalCard: values.card })
                    creditTypes = [...creditTypes, { ...res[i], ...calculateCC, gctId, ctName: creditType?.ctName, active: i === 0 }]
                    valCouDis -= calculateCC.valCou
                    totalCredit += calculateCC.valCre
                    month = creditType?.month?.mValue

                    // calcula el crédito normal
                } else if (gctType === 'NORMAL') {
                    const calculateCC = calculateCreditFee({ ...creditType, valCou: valCouDis })
                    creditTypes = [...creditTypes, { ...res[i], ...calculateCC, gctId, ctName: creditType?.ctName, active: i === 0 }]
                    valCouDis -= calculateCC.valCou
                    totalCredit += calculateCC.valCre
                    totalReq += calculateCC.reqVal
                    month = creditType?.month?.mValue

                    // calcula el crédito por consumo
                } else if (gctType === 'CONSUMO') {
                    const calculateCC = calculateCreditConsumption({ ...creditType, gctIntFee, creditTypes, valCouFee: values.fee })
                    creditTypes = [...creditTypes, { ...res[i], ...calculateCC, gctId, ctName: creditType?.ctName, active: i === 0 }]
                    valCouDis -= calculateCC.valCou
                    totalCredit += calculateCC.valCre
                    totalReq += calculateCC.reqVal
                    month = creditType?.month?.mValue
                }
            }

            setAllCreditTypes(creditTypes)
            setOneCreditType(creditTypes.find(x => x.active))
            setInfoGroup({ totalCredit, totalReq, month })
        }

        if (errGCT) {
            navigation.goBack()
            isLoggedVar({ ...isLoggedVar(), message: 'No se ha encontrado nínguna línea de crédito combinada.' })
        }
    }, [dataGCT, errGCT, calGCT, navigation, seeCard, values])

    /**
     * Captura el evento del onChange
     * @param {object} e evento del onchange
     * @param {number} index indice del array
     * @param {string} type tipo del formulario
     * @return {void}
     */
    const handleChange = (e, index, type) => {
        // verifica los tipos de formulario
        if (type === 'fee') setFee(e.value)
        else if (type === 'card') {
            setCards(cards.map((x, i) => i === index ? { ...x, [e.name]: e.value } : x))
            setErrorCards(errorCards.map((x, i) => i === index ? { ...x, [e.name]: e.error } : x))
        }
    }

    /**
     * Captura el botón de confirmar de la cuota
     * @param {number} type tipo del formulario
     * @param {number} index indice del array
     * @return {void}
     */
    const handleIconCard = (type, index) => {
        // agrega un registro al array
        if (type) {
            setCards([...cards, { index: index + 1 }])
            setErrorCards([...errorCards, {}])
        } else { // elimina un registro al array
            setCards(cards.filter((x, i) => i !== index) || [{}])
            setErrorCards(errorCards.filter((x, i) => i !== index) || [{}])
        }
        setMessageErrorFee('')
        setMessageErrorCard('')
    }

    /**
     * Captura el botón de confirmar de la cuota
     * @return {void}
     */
    const handleConfirmFee = () => {
        // verifica si el campo es correcto
        if (!fee) return setMessageErrorFee('Por favor rellene todo los campos.')
        const { gcSalary, gcMinFeeSal, gcMaxFeeSal, payrollWork, gcMinFee, gcMaxFee } = route?.params

        // verifica el monto según el salario
        if (gcSalary === 1) {
            const minFee = (((gcMinFeeSal || 0) / 100) * payrollWork?.pwSalary)
            const maxFee = (((gcMaxFeeSal || 0) / 100) * payrollWork?.pwSalary)
            if (minFee > fee) return setMessageErrorFee(`El monto mínimo de su salario es de: $ ${numberFormat(minFee)} y su total es de: ${numberFormat(fee)}`)
            if (maxFee < fee) return setMessageErrorFee(`El monto máximo de su salario es de: $ ${numberFormat(maxFee)} y su total es de: ${numberFormat(fee)}`)
        }

        // verifica el monto según el mínimo
        if (gcMinFee > 0 && gcMinFee > fee) return setMessageErrorFee(`El monto mínimo de la cuota a incorporar es de: $ ${numberFormat(gcMinFee)} y su total es de: ${numberFormat(fee)}`)

        // verifica el monto según el máximo
        if (gcMaxFee > 0 && gcMaxFee < fee) return setMessageErrorFee(`El monto mínimo de la cuota a incorporar es de: $ ${numberFormat(gcMaxFee)} y su total es de: ${numberFormat(fee)}`)

        // actualiza el estado
        setMessageErrorFee('')
        setValues({ ...values, fee })
        setIsVisibleFee(false)
    }

    /**
     * Captura el botón de confirmar de la cuota
     * @return {void}
     */
    const handleConfirmCard = () => {
        const { gcMinWal, gcMaxWal } = route?.params
        let newErrors = []
        let errorConfirm = false
        let totalCards = 0

        // verifica los campos de la cartera
        for (let i = 0; i < errorCards.length; i++) {
            const { ccName, ccFee, ccBalance } = errorCards[i]

            // registra los nuevos valores de error
            newErrors = [...newErrors, { ccName: ccName === undefined || ccName, ccFee: ccFee === undefined || ccFee, ccBalance: ccBalance === undefined || ccBalance }]

            // verifica si hay un error en el formulario
            if ((ccName === undefined || ccName) || (ccFee === undefined || ccFee) || (ccBalance === undefined || ccBalance)) errorConfirm = true
        }

        // verifica si el campo es correcto
        if (errorConfirm) {
            setErrorCards(newErrors)
            return setMessageErrorCard('Por favor rellene todo los campos.')
        }

        // busca el total de la cartera
        for (let i = 0; i < cards.length; i++) {
            const { ccBalance } = cards[i]
            totalCards += ccBalance
        }

        // verifica si el monto mínimo de la cartera es mayor al total de la cartera
        if (gcMinWal > totalCards) return setMessageErrorCard(`El monto mínimo de la cartera es de: $ ${numberFormat(gcMinWal)} y su total es de: ${numberFormat(totalCards)}`)

        // verifica si el monto máximo de la cartera es mayor al total de la cartera
        if (gcMaxWal < totalCards) return setMessageErrorCard(`El monto máximo de la cartera es de: $ ${numberFormat(gcMaxWal)} y su total es de: ${numberFormat(totalCards)}`)

        // actualiza el estado
        setMessageErrorCard('')
        setValues({ ...values, card: totalCards })
        setIsVisibleCard(false)
    }

    /**
     * Captura el click de ver la línea de crédito
     * @param {object} item valores de la línea de crédito seleccionada
     * @return {void}
     */
    const handleCreditType = item => {
        const findCreditType = allCreditTypes.find(x => x.gctId === item.gctId)
        setAllCreditTypes(allCreditTypes.map(x => ({ ...x, active: x.gctId === item.gctId })))
        setOneCreditType(findCreditType)
    }

    /**
     * Activa si mostrar detalles del crédito
     * @param {object} item valores de la línea de crédito seleccionada
     * @return {void}
     */
    const handleSeeDetail = () => {
        // verifica que se encuentren líneas de créditos
        if (!allCreditTypes?.length) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor cargar los valores.' })
        setSeeDetail(!seeDetail)
    }

    /**
     * Activa si mostrar detalles del crédito
     * @param {object} item valores de la línea de crédito seleccionada
     * @return {void}
     */
    const handleConfirm = () => {
        // verifica que se encuentren líneas de créditos
        if (!allCreditTypes?.length) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor cargar los valores.' })

        setData('infCredit', JSON.stringify({
            group: route.params?.gcId,
            cgValFee: values?.fee,
            cgValCar: values?.card,
            cards: seeCard ? cards.map(x => ({ ccName: x.ccName, ccFee: x.ccFee, ccBalance: x.ccBalance })) : []
        }))

        navigation.navigate('RequestCreditData', {
            group: true,
            userFormat: allCreditTypes[0]?.creditType?.userFormat,
            codebtor: allCreditTypes[0]?.creditType?.codebtor,
            number: allCreditTypes[0]?.creditType?.ctNumCoDebtors
        })
    }

    return (
        <GroupCreditV
            data={{
                fee,
                cards,
                values,
                loadGCT,
                seeCard,
                seePlan,
                seeDetail,
                infoGroup,
                errorCards,
                isVisibleFee,
                isVisibleCard,
                oneCreditType,
                allCreditTypes,
                messageErrorFee,
                messageErrorCard,
                params: route?.params,
            }}
            onChange={handleChange}
            handleFeeActive={() => setIsVisibleFee(true)}
            handleFeeClose={() => setIsVisibleFee(false)}
            handleCardActive={() => setIsVisibleCard(true)}
            handleCardClose={() => setIsVisibleCard(false)}
            handleConfirmFee={handleConfirmFee}
            handleIcon={handleIconCard}
            handleConfirmCard={handleConfirmCard}
            handleConfirm={handleConfirm}
            handleCreditType={handleCreditType}
            handleSeePlan={() => setSeePlan(!seePlan)}
            handleSeeDetail={handleSeeDetail}
        />
    )
}

GroupCreditC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}