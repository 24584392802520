import { gql } from '@apollo/client'

export const GET_CODE_PASS = gql`
    mutation getCodePass($input: IGetCode!) {
        getCodePass(input: $input) {
            uId
        }
    }
`

export const HANDLE_CODE_PASS = gql`
    mutation handleCodePass($input: IConfirmCode!) {
        handleCodePass(input: $input) {
            uId
        }
    }
`

export const CHANGE_PASS = gql`
    mutation changePassUser($input: IChangePassUser!) {
        changePassUser(input: $input) {
            uId
        }
    }
`