import { gql } from '@apollo/client';

export const USE_ONE_PAYROLL_COMPANY = gql`
    query getPayrollCompanyById($pcId: ID, $state: [Int]) {
        getPayrollCompanyById(pcId: $pcId, state: $state) {
            cId
            pcId
            company {
                cId
                cBusNam
            }
            tiId
            typeIdentity {
                tiId
                tiName
            }
            pcIdeNum
            pcDatExp
            pcDomExp
            pcState
            payrollStaff {
                psId
                psFirNam
                psSecNam
                psFirLas
                psSecLas
                psDatBir
                psPhoMob
                psPhoPer
                psSons
                psWeight
                psHeight
                psEmail
                cId
                gId
                psState
            }
            payrollRelationships {
                prId
                rId
                prName
                prPercentage
                prState
            }
            payrollResidence {
                prId
                cId
                taId
                prNumDir
                prNum
                prNumHou
                prInformation
                prStratum
                thId
                dId
                alId
                msId
                prState
            }
            payrollWork {
                pwId
                pwPosition
                tcId
                pwDatSta
                pwDatEnd
                pwSalary
                pwNet
                pwEmbargoes
                pwOthInc
                pwFinExp
                pwPerExp
                pwState
                pwActive
                pwPassive
                pwCIIU
                pwHolPubOff
                pwHasPubRec
                pwHasRelPubExp
                pwManPubRes
            }
            payrollLifeInsurance {
                pliId
                pcId
                pliCancer
                pliVIH
                pliStroke
                pliArtHyp
                pliDiabetes
                pliCorArtDis
                pliHeaAtt
                pliHasConAcqPhyMenLim
                pliDiaTreDisOthThoMenAbo
                pliDatDia
                pliTreatment
                pliActSta
                pliIsPre
                pliPrimitive
                ptId
                pliDisabilityPensioner
                pliUseDrugsAlcohol
                pliDiseaseName
                pliConsequences
                pliObservations
                pliCurrentTreatment
                pliTreatmentOrSurgery
                ieId
            }
            payrollBank {
                pbId
                pcId
                tbId
                typeBank {
                    tbId
                    tbName
                }
                atId
                accountType {
                    atId
                    atName
                }
                pbAccNum
                cId
                city {
                    cId
                    dId
                    cName
                }
                pbState
            }
            payrollOtherInformation {
                poiId
                pcId
                poiAffiliationNumber
                poiUsNationality
                poiUsAccount
                poiUsInvestmentAssets
                poiAmericanGreenCard
                poiBornUS
                poiLivedUS
                poiForeignCurrency
                poiActivity
                poiTaxObligations
                poiBiddingActivities
                poiAcceptInformation
                poiBreachContract
                poiCriminallyDeclared
                poiTruthfulStatements
                poiAcceptDocument
                poiInsuredValue
                poiPremiumValue
                poiInfoBiddingActivities
                poiDangerHisLife
                poiInfoDangerHisLife
                poiTakesMedicine
                poiInfoTakesMedicine
                poiEPS
                poiIPS
                poiTakeDrug
                poiInfoTakeDrug
                poiState
            }
            payrollEconomyActivities {
                pcId
                cId
                peaId
                city {
                    cId
                    dId
                    cName
                    cCode
                    cState
                }
                tcId
                typeContract {
                    tcId
                    tcName
                    tcState
                }
                dId
                department {
                    dId
                    dCode
                    dName
                    dState
                }
                peaActivity
                peaAddress
                peaAntiquity
                peaAntiquityIndependents
                peaBussiness
                peaChamberCommerce
                peaEmail
                peaCompanyName
                peaGenerateIncome
                peaId
                peaPensionFund
                peaPosition
                peaRUT
                peaState
                peaTypeCompany
                peaTypePension
                peaUniversity
                peaWorkPhone
            }
            payrollOPS {
                poId
                poContractor
                poObject
                poValCon
                poValDatSta
                poValDatEnd
                poState
            }
            payrollReference {
                prId
                prNamFam
                prLasFam
                rIdFam
                prPhoFam
                cIdFam
                taIdFam
                prNumDirFam
                prNumFam
                prNumHouFam
                prInfFam
                prNamPar
                prLasPar
                prPhoPar
                cIdPar
                taIdPar
                prNumDirPar
                prNumPar
                prNumHouPar
                prInfPar
                prState
                cIdStu
                prEmailStu
                prFacultyStu
                prIdeNumStu
                prInfStu
                prLasStu
                prNamStu
                prNumHouStu
                prNumDirStu
                prNumStu
                prPhoStu
                gIdStu
                tiIdStu
                taIdStu
            }
            payrollDocument {
                pdId
                pdPerDoc
                pdFroDoc
                pdReaDoc
                pdSerDoc
                pdPayRemDoc
                pdBanDoc
                pdConDoc
                pdComActDoc
                pdBudRecDoc
                pdBudCerDoc
                pdRut
                pdComChamber
                pdInstitutionPic
                pdWorkCertificate
                pdBankStatement
                pdFinancialStatements
                pdIncomeTaxReturn
                pdBoardDirectors
                pdTradeBalance
                pdRealEstateRegistration
                pdVehicleOwnershipCard
                pdCommercialReference
                pdBankReference
                pdIncomeWithholdings
                pdCadastreAndValuation
                pdCommercialAppraisal
                pdState
            }
            payrollCoDebtors {
                pcdId
                tiId
                pcdIdeNum
                pcdFirNam
                pcdSecNam
                pcdFirLas
                pcdSecLas
                pcdDatBir
                pcdPhoMob
                pcdPhoPer
                pcdEmail
                gId
                cId
                taId
                pcdNumDir
                pcdNum
                pcdNumHou
                pcdInf
                alId
                msId
                thId
                dId
                pcdCompany
                pcdPosition
                tcId
                pcdDatSta
                pcdDatEnd
                pcdSalary
                pcdOthInc
                pcdFinExp
                pcdPerExp
                pcdPerDoc
                pcdFroDoc
                pcdReaDoc
                pcdSerDoc
                pcdBanDoc
                pcdLoaDoc
                pcdState
                pcdDateExp
                pcdPlaceExp
            }
        }
    }
`;

export const USER_PARAMETERS = gql`
    query GetUserParametersById {
        getUserParametersById {
            cId
            nameButton
            embargoes
            enrollmentReceipt
            sarlaft
            lifeInsurance
        }
    }
`;

export const CIRENIO_PARAMETERS = gql `
    query GetCirenioParameter($cpId: Int) {
        getCirenioParameter(cpId: $cpId) {
          cpId
          cpName
          cpPublicKey
          cpXApiKey
          cpState
          env
          cpPrivateKey
        }
    }
`