import styled from 'styled-components/native';
import theme from '../../../ignore/Constants'
import { mq } from '../../validation';

export const Container = styled.View`
    background-color: ${theme.backgroundP};
    flex: 1;
    display: 'flex';
    align-items: center;
    justify-content:  center;
`
export const ViewPayment = styled.View`
    background-color: ${props => props.theme.BGColor};
    border: 1px solid ${props => props.theme.PColor};
    border-radius: ${mq(16)}px;
    padding: ${mq(10)}px ${mq(20)}px;
    margin: ${mq(18)}px 0;
    width: 90%;
    max-width: 768px;
    display: flex;
    align-items: center;
`
export const Header = styled.View`
    width: 100%;
    display: flex;
    flex-direction: column;
`
export const TextInformation = styled.Text`
    font-size: ${mq(13)}px;
    font-family: PFontMedium;
    color: ${props => props.theme.SFColor};
    text-align-last: justify;
    margin-bottom: 10px;
`
export const CreditNameText = styled.Text`
    font-size: ${mq(16)}px;
    font-family: PFontMedium;
    color: #e85231;
    font-weight: 700;
    text-align-last: start;
    margin-bottom: 12px;
`
export const PaymentButton = styled.TouchableOpacity`
    display: 'flex';
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.PColor};
    width: ${mq(190)}px;
    height: ${mq(35)}px;
`

export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding: ${mq(2)}px 0; */
`