import React from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, StatusBar, ActivityIndicator } from 'react-native'
import { Camera } from 'expo-camera'
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import theme from '../../../ignore/Constants'
import { hp, wp } from '../../validation'
import { Container, BoxAction, BoxButtonCamera, ButtonCamera, RectangleHorizontal, RectangleVertical, CornerTopLeft, CornerTopRight, CornerBottomLeft, CornerBottomRight, styles } from './styled'

export const CameraV = ({ data: { typeCamera, flashCamera, permissionCamera, refCamera, loading }, handlePicture, handleRotate, handleFlash, handleLayout, handleLibrary, handleCameraReady }) => (
    <Container>
        <StatusBar hidden />
        {permissionCamera &&
            <Camera style={{ flex: 1, position: 'relative' }}
                ref={refCamera}
                type={typeCamera}
                flashMode={flashCamera}
                onCameraReady={handleCameraReady}
                ratio='16:9'
            >
                {/* Figura de fijación  */}
                <CornerTopLeft style={styles.corners} onLayout={handleLayout} />
                <RectangleHorizontal left='0' />
                <RectangleHorizontal right='0' />
                <RectangleVertical top={hp(8)} />
                <RectangleVertical height={hp(20)} bottom='0' />
                <CornerTopRight style={styles.corners} />
                <CornerBottomLeft style={styles.corners} />
                <CornerBottomRight style={styles.corners} />
                {/* fin figura */}

                <BoxAction>
                    <TouchableOpacity onPress={handleRotate} >
                        <MaterialIcons name='camera-front' size={wp(8)} color={theme.backgroundC} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={handleFlash} >
                        {flashCamera === Camera.Constants.FlashMode.off ? <MaterialCommunityIcons name='flash-off' size={wp(8)} color={theme.backgroundC} /> : <MaterialCommunityIcons name='flash' size={wp(8)} color={theme.backgroundC} />}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={handleLibrary} >
                        <MaterialIcons name='photo-library' size={wp(8)} color={theme.backgroundC} />
                    </TouchableOpacity>
                </BoxAction>
                <BoxButtonCamera>
                    <ButtonCamera onPress={handlePicture} testID='4cdae081-50b4-48de-a99e-a4a6e2dc9682'>
                        {loading ? <ActivityIndicator size='small' color={theme.primaryC} /> : <MaterialIcons name='camera' size={50} color='white' />}
                    </ButtonCamera>
                </BoxButtonCamera>
            </Camera>
        }
    </Container>
)

CameraV.propTypes = {
    data: PropTypes.object,
    handlePicture: PropTypes.func,
    handleRotate: PropTypes.func,
    handleFlash: PropTypes.func,
    handleLayout: PropTypes.func,
    handleLibrary: PropTypes.func,
    handleCameraReady: PropTypes.func,
}