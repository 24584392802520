import { gql } from '@apollo/client'

export const EDIT_CREDIT_PAYROLL = gql`
    mutation setOrUpdateCreditPayrollWork($cpcId: ID, $input: ISetOrUpdateCreditPayrollWork!){
        setOrUpdateCreditPayrollWork(cpcId: $cpcId, input: $input){
            cpwId
            cpcId
        }
    }
`

export const EDIT_PAYROLL = gql`
    mutation setOrUpdatePayrollWork($pcId: ID, $input: ISetOrUpdatePayrollWork!){
        setOrUpdatePayrollWork(pcId: $pcId, input: $input){
            pwId
            pcId
        }
    }
`

export const EDIT_CREDIT = gql`
    mutation updateCredit($cId: ID, $input: IUpdateCredit!){
        updateCredit(cId: $cId, input: $input){
            cId
        }
    }
`

export const ONE_CREDIT_PAYROLL_COMPANY = gql`
    query getCreditPayrollCompanyById($cId: ID) {
        getCreditPayrollCompanyById(cId: $cId) {
            cpcId
            pcId
            tiId
            typeIdentity {
                tiId
                tiName
            }
            cpcIdeNum
            creditPayrollWork {
                cpwId
                cpwActive
                cpwPassive
                cpwHolPubOff
                cpwHasPubRec
                cpwHasRelPubExp
                cpwCIIU
                cpwManPubRes
            }
        }
    }
`

export const ONE_CREDIT_PAYROLL_FORMAT = gql`
    query getCreditPayrollFormatById($cId: ID, $state: [Int]) {
        getCreditPayrollFormatById(cId: $cId, state: $state) {
            cpfId
            cpfActive
            cpfPassive
            cpfHolPubOff
            cpfHasPubRec
            cpfHasRelPubExp
            cpfCIIU
            cpfManPubRes
            cpfState
        }
    }
`