import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { EconomicDataRegisterV } from '../../components/CreditEditData/EconomicDataRegister'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { filterKeyObject, updateCache, validationErrors } from '../../validation'
import { EDIT_CREDIT_PAYROLL_ECONOMY_ACTIVITIES, EDIT_CREDIT_PAYROLL_LIFE_INSURANCE, EDIT_CREDIT_PAYROLL_RESIDENCE, EDIT_CREDIT_PAYROLL_WORK } from './queries'

export const EconomicDataRegisterC = ({ dataUser, banner, handleNext, format, refScroll, userParameters }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [creditPayrollEconomicMutation, { loading: loadPCE }] = useMutation(EDIT_CREDIT_PAYROLL_RESIDENCE)
    const [creditPayrollWorkMutation, { loading: loadPCW }] = useMutation(EDIT_CREDIT_PAYROLL_WORK)
    const [creditPayrollEconomyMutation, { loading: loadPayrollEconomy }] = useMutation(EDIT_CREDIT_PAYROLL_ECONOMY_ACTIVITIES)
    const [creditPayrollLifeInsuranceMutation, { loading: loadLife }] = useMutation(EDIT_CREDIT_PAYROLL_LIFE_INSURANCE)
    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({
            creditPayrollResidence: filterKeyObject(dataUser?.creditPayrollResidence, ['__typename']),
            creditPayrollWork: filterKeyObject(dataUser?.creditPayrollWork, ['__typename']),
            creditPayrollEconomyActivities: {
                ...filterKeyObject(dataUser?.creditPayrollEconomyActivities, ['__typename']),
                dIdDepartment: dataUser?.creditPayrollEconomyActivities?.dId
            },
            creditPayrollLifeInsurance: filterKeyObject(dataUser?.creditPayrollLifeInsurance, ['__typename']),
        })
    }, [dataUser])

    const employee = values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO'
    const independent = values?.creditPayrollEconomyActivities?.cpeaActivity === 'INDEPENDIENTE'
    const pensioner = values?.creditPayrollEconomyActivities?.cpeaActivity === 'PENSIONADO'
    const student = values?.creditPayrollEconomyActivities?.cpeaActivity === 'ESTUDIANTE'
    useEffect(() => {
        setErrorForm({
            thId: (format?.cpfTypHou && !dataUser?.creditPayrollResidence?.thId),
            dId: (format?.cpfDependents && !dataUser?.creditPayrollResidence?.dId),
            alId: (format?.cpfAcaLev && !dataUser?.creditPayrollResidence?.alId),
            msId: (format?.cpfMarSta && !dataUser?.creditPayrollResidence?.msId),
            cpeaActivity: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaActivity),
            cpeaCompanyName: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaCompanyName && employee),
            cpeaAddress: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaAddress && employee),
            dIdDepartment: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.dIdDepartment && employee),
            cpeaTypeCompany: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaTypeCompany && employee),
            cpeaWorkPhone: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaWorkPhone && employee),
            cpeaEmail: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaEmail && employee),
            cId: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cId && employee),
            cpeaPosition: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaPosition && employee),
            tcId: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.tcId && employee),
            cpeaAntiquity: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaAntiquity && employee),
            cpeaAntiquityIndependents: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaAntiquityIndependents && independent),
            cpeaBussiness: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaBussiness && independent),
            cpeaChamberCommerce: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaChamberCommerce && independent),
            cpeaRUT: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaRUT && independent),
            cpeaPensionFund: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaPensionFund && pensioner),
            cpeaTypePension: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaTypePension && pensioner),
            cpeaUniversity: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaUniversity && student),
            cpeaGenerateIncome: (format?.cpfEconomyActivities && !values?.creditPayrollEconomyActivities?.cpeaGenerateIncome && student),
        })
    }, [dataUser, employee, independent, pensioner, student])
    // Cambio en los valores
    const handleChange = (e, type) => {
        if (type === 1) {
            setValues({ ...values, creditPayrollResidence: { ...values.creditPayrollResidence, [e.name]: e.value } })
        }
        else if (type === 2) {
            setValues({ ...values, creditPayrollEconomyActivities: { ...values.creditPayrollEconomyActivities, [e.name]: e.value } })
        }else if(type === 5){
            setValues({ ...values, creditPayrollLifeInsurance: { ...values.creditPayrollLifeInsurance, [e.name]: e.value } })
        }else setValues({ ...values, creditPayrollWork: { ...values.creditPayrollWork, [e.name]: e.value || 0 } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data: dataCPR, error: errorCPE } = await creditPayrollEconomicMutation({
            variables: { input: values?.creditPayrollResidence }, update(cache, { data: { setOrUpdateCreditPayrollResidence } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollResidence })
            }
        }).catch(e => ({ error: e }))

        const { data: dataCPW, error: errorCPW } = await creditPayrollWorkMutation({
            variables: { input: values?.creditPayrollWork }, update(cache, { data: { setOrUpdateCreditPayrollWork } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollWork })
            }
        }).catch(e => ({ error: e }))

        const { data: dataCPL, error: errorCPL } = await creditPayrollLifeInsuranceMutation({
            variables: { input: values?.creditPayrollLifeInsurance }, update(cache, { data: { setOrUpdateCreditPayrollLifeInsurance } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollLifeInsurance })
            }
        }).catch(e => ({ error: e }))

        const { data: dataEconomy, error: errorEconomy } = await creditPayrollEconomyMutation({
            variables: {
                input: {
                    cpeaActivity: values?.creditPayrollEconomyActivities?.cpeaActivity,
                    cpeaCompanyName: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.cpeaCompanyName : null,
                    cpeaAddress: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.cpeaAddress : null,
                    cpeaTypeCompany: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.cpeaTypeCompany : null,
                    cpeaWorkPhone: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.cpeaWorkPhone : null,
                    cpeaEmail: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.cpeaEmail : null,
                    dId: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.dIdDepartment : null,
                    cId: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.cId : null,
                    cpeaPosition: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.cpeaPosition : null,
                    tcId: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? values?.creditPayrollEconomyActivities?.tcId : null,
                    cpeaAntiquity: values?.creditPayrollEconomyActivities?.cpeaActivity === 'EMPLEADO' ? +(values?.creditPayrollEconomyActivities?.cpeaAntiquity || 0) : 0,
                    cpeaAntiquityIndependents: values?.creditPayrollEconomyActivities?.cpeaActivity === 'INDEPENDIENTE' ? +(values?.creditPayrollEconomyActivities?.cpeaAntiquityIndependents || 0) : 0,
                    cpeaBussiness: values?.creditPayrollEconomyActivities?.cpeaActivity === 'INDEPENDIENTE' ? values?.creditPayrollEconomyActivities?.cpeaBussiness : null,
                    cpeaChamberCommerce: values?.creditPayrollEconomyActivities?.cpeaActivity === 'INDEPENDIENTE' ? values?.creditPayrollEconomyActivities?.cpeaChamberCommerce : 0,
                    cpeaRUT: values?.creditPayrollEconomyActivities?.cpeaActivity === 'INDEPENDIENTE' ? values?.creditPayrollEconomyActivities?.cpeaRUT : 0,
                    cpeaPensionFund: values?.creditPayrollEconomyActivities?.cpeaActivity === 'PENSIONADO' ? values?.creditPayrollEconomyActivities?.cpeaPensionFund : null,
                    cpeaTypePension: values?.creditPayrollEconomyActivities?.cpeaActivity === 'PENSIONADO' ? values?.creditPayrollEconomyActivities?.cpeaTypePension : null,
                    cpeaUniversity: values?.creditPayrollEconomyActivities?.cpeaActivity === 'ESTUDIANTE' ? values?.creditPayrollEconomyActivities?.cpeaUniversity : null,
                    cpeaGenerateIncome: values?.creditPayrollEconomyActivities?.cpeaActivity === 'ESTUDIANTE' ? values?.creditPayrollEconomyActivities?.cpeaGenerateIncome : 0,
                },
                cpcId: dataUser?.cpcId,
            }, update(cache, { data: { updatePayrollEconomyActivities } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: updatePayrollEconomyActivities })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (errorCPE || errorCPW || errorEconomy) return isLoggedVar({ ...isLoggedVar(), message: (errorCPE || errorCPW || errorEconomy).message })

        /** resultado */
        if (dataCPR?.setOrUpdateCreditPayrollResidence && dataCPW?.setOrUpdateCreditPayrollWork && dataEconomy?.updateCreditPayrollEconomyActivities) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(3.5)
        }
    }
    return (
        <EconomicDataRegisterV
            data={{ values, errors, format, banner, loadPCE: loadPCE || loadPCW || loadPayrollEconomy }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleChangeResidence={handleChange}
            handleSubmit={handleSubmit}
            userParameters={userParameters}
        />
    )
}

EconomicDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    userParameters: PropTypes.object,
    refScroll: PropTypes.object
}