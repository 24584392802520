/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect, Use } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = props => (
    <Svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 392 392"
        style={{
            enableBackground: 'new 0 0 392 392',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <Path
            className="st0"
            fill="#c2dbee"
            d="M386.7,195c0,103.9-84.2,188.1-188.1,188.1c-103.8,0-188-84.2-188-188.1c0-103.8,84.2-188,188-188 C302.5,7,386.7,91.2,386.7,195z"
        />
        <Path
            className="st0"
            fill= "#95c0e1"
            d="M346.3,195c0,81.5-66.1,147.7-147.7,147.7S50.9,276.6,50.9,195S117.1,47.4,198.6,47.4 C280.2,47.4,346.3,113.5,346.3,195z"
        />
        <Path
            className="st1"
            fill="#FFF"
            d="M312.8,195.1c0,63.1-51.1,114.2-114.2,114.2S84.4,258.2,84.4,195.1S135.5,80.9,198.6,80.9 S312.8,132.1,312.8,195.1z"
        />
        <G>
            <Defs>
                <Rect id="SVGID_1_" x={132.9} y={125.6} width={131.4} height={139} />
            </Defs>
            <ClipPath id="SVGID_00000034794948845736534240000011684422376275857048_">
                <Use
                    xlinkHref="#SVGID_1_"
                    style={{
                        overflow: 'visible',
                    }}
                />
            </ClipPath>
            <G
                id="Grupo_105"
                style={{
                    clipPath:
            'url(#SVGID_00000034794948845736534240000011684422376275857048_)',
                }}
            >
                <Path
                    id="Trazado_5190"
                    className="st3"
                    fill="#fb411a"
                    d="M218.6,210.3c-7.9,0-13.9,4.1-16.4,10.8c-0.5,1.4,0.2,3,1.6,3.6c0.3,0.1,0.6,0.2,1,0.2 h27.4c1.5,0,2.8-1.3,2.8-2.8c0-0.3-0.1-0.7-0.2-1C232.3,214.4,226.4,210.3,218.6,210.3"
                />
                <Path
                    id="Trazado_5191"
                    className="st3"
                    fill="#fb411a"
                    d="M232.1,196.6c11.5,3.6,18.3,12.2,20.9,22.6c1.9,7.5-2.7,15.2-10.2,17.1 c-1.1,0.3-2.3,0.4-3.4,0.4h-34c-1.5,0-2.8,1.2-2.8,2.8c0,0.4,0.1,0.8,0.3,1.2c3.1,6.4,9.7,10.1,18.7,10.1c2.6,0,5.2-0.3,7.7-1 c6.5-1.8,13.5-0.3,18.7,4c1.3,1.1,3.3,1,4.5-0.3c1.5-1.7,3.3-4.6,4.1-8.8l7.6-38.7c1-5.1-2.3-10.1-7.4-11.1 c-0.6-0.1-1.2-0.2-1.8-0.2h-23.3C230.8,194.8,229.1,195.7,232.1,196.6"
                />
                <Path
                    id="Trazado_5192"
                    className="st4"
                    fill="#0071c0"
                    d="M178.9,162l-2.9,13.8h16.5c2.7,0,5.8-0.1,2.8,5.9c-4,8.1-12.3,13.3-21.4,13.3h-1.2 l-12.4,62.3c-0.6,2.8-1,9.6-8.6,6.7c-9-3.4-14.1-12.8-12.2-22.2l9.3-46.8h-12.7c-2.3,0.1-4-1.4-2.9-4.8 c2.7-8.5,10.5-14.3,19.4-14.3l3.3-15.5c4.4-21.5,18.2-34.7,41.2-34.7c6.8,0,13.3,1.4,18.4,3.7c3.9,1.8,2.2,5.4,1.4,6.4 c-4.7,5.9-11.9,9.2-19.6,9.2C188.2,144.9,181.4,150.4,178.9,162"
                />
                <Path
                    id="Trazado_5193"
                    className="st4"
                    fill="#0071c0"
                    d="M201.3,200.4l-8.3,41.3c-2.4,11.7-11.3,22.6-22.8,22.9c-3.8,0.1-5.2-2.7-4.5-5.9l8.1-40.4 c2.4-11.7,11.3-21.6,22.7-22.9C200,195.1,202,196.6,201.3,200.4"
                />
                <Path
                    id="Trazado_5194"
                    className="st3"
                    fill="#0071c0"
                    d="M203.5,189.5c1.9-8,7.3-21.1,21.6-24.3c3.4-0.8,6.4,2.6,5.3,5.9 c-2.6,7.9-8.7,20.5-21.2,23.9c-2.4,0.7-4.9-0.8-5.6-3.2C203.3,191,203.3,190.2,203.5,189.5"
                />
            </G>
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    color2: PropTypes.any,
    color3: PropTypes.any,
    color4: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}