import React, { useState } from 'react'
import PropTypes from 'prop-types'
import theme from '../../../ignore/Constants'
import { Container, Input, TextError, Title, LeftBorder } from './styled'
import { isEmail, isNumeric, isNull, onlyLetters, rangeLength, numberFormat } from '../../validation'
import { useWindowDimensions } from 'react-native'
import { Text } from 'react-native'

export const InputHookText = ({ testID, title, returnKeyType = 'next', secureTextEntry, onChangeText, onBlur, value, name, keyboardType, maxLength, editable = true, error, required, numeric, letters, range, email, format, align, flex, equalTo, minimum, maximum, bgColor, styleContainer }) => {

    // Declarando el estado
    const [focus, setFocus] = useState(false)
    const [message, setMessage] = useState(false)
    const dimensions = useWindowDimensions()

    // Función para activar el error
    const errorFunc = data => {
        setMessage(data)
    }

    /**
     * @description Función que para validar los campos de texto por el método onChange.
     * @version 0.0.1
     * @param {object} e evento del método change
     * @return {boolean} devuelve true o false si la validación es correcta o incorrecta
     *
    */
    const validations = e => {
        let validateError = true
        let newValue = `${((format || numeric) && !!e) ? (!isNumeric(`${e}`.replace(/\./g, '')) ? parseFloat(`${e}`.replace(/\./g, '')) : '') : e}`

        if (!format && numeric) newValue = `${newValue}`

        // Valida que el campo no sea nulo
        if (required) {
            if (isNull(newValue)) {
                validateError = false
                errorFunc('Campo requerido.')
            }
        }
        // Valida que el campo sea tipo numérico
        if (numeric) {
            if (isNumeric(newValue) && !!newValue) {
                validateError = false
                errorFunc('Solo puede contener números.')
            }
        }
        // Valida que el campo sea solo letras
        if (letters) {
            if (onlyLetters(newValue)) {
                validateError = false
                errorFunc('El campo debe contener solo letras.')
            }
        }
        // Valida que el campo esté en el rango correcto
        if (!!newValue.length && !!range) {
            if (rangeLength(newValue, range[0], range[1])) {
                validateError = false
                errorFunc(`El rango de carácteres es de ${range[0]} a ${range[1]}.`)
            }
        }
        // Valida si el campo tiene un formato de email correcto
        if (email && newValue) {
            if (isEmail(newValue)) {
                validateError = false
                errorFunc('El formato de email no es válido.')
            }
        }
        // Valida que sea igual a otro valor
        if (equalTo && equalTo?.value) {
            if (newValue !== equalTo?.value) {
                validateError = false
                errorFunc(equalTo?.errorMessage)
            }
        }

        // Valida que el valor sea mayor al número mínimo
        if (minimum && newValue) {
            if (newValue < minimum) {
                validateError = false
                errorFunc(`El valor es mínimo: ${numberFormat(minimum)}.`)
            }
        }

        // Valida que el valor sea menor al número máximo
        if (maximum && newValue) {
            if (newValue > maximum) {
                validateError = false
                errorFunc(`El valor es máximo: ${numberFormat(maximum)}.`)
            }
        }

        validateError && errorFunc(false)
        onChangeText && onChangeText({ name, value: (format && newValue) ? parseInt(newValue || 0) : newValue, error: validateError ? false : !!message })
    }

    const validationsBlur = () => {
        let validateError = true
        // Valida que el campo no sea nulo
        if (required) {
            if (isNull(value)) {
                validateError = false
                errorFunc('Campo requerido.')
            }
        }
        // Valida que el campo sea tipo numérico
        if (numeric) {
            if (isNumeric(value) && !!value) {
                validateError = false
                errorFunc('Solo puede contener números.')
            }
        }
        // Valida que el campo sea solo letras
        if (letters) {
            if (onlyLetters(value)) {
                validateError = false
                errorFunc('El campo debe contener solo letras.')
            }
        }
        // Valida que el campo esté en el rango correcto
        if (`${value}` && range) {
            if (rangeLength(`${value}`, range[0], range[1])) {
                validateError = false
                errorFunc(`El rango de carácteres es de ${range[0]} a ${range[1]}.`)
            }
        }
        // Valida si el campo tiene un formato de email correcto
        if (email && value) {
            if (isEmail(value)) {
                validateError = false
                errorFunc('El formato de email no es válido.')
            }
        }
        // Valida que sea igual a otro valor
        if (equalTo && equalTo?.value) {
            if (value !== equalTo?.value) {
                validateError = false
                errorFunc(equalTo?.errorMessage)
            }
        }

        // Valida que el valor sea mayor al número mínimo
        if (minimum && value) {
            if (value < minimum) {
                validateError = false
                errorFunc(`El valor es mínimo: ${numberFormat(minimum)}.`)
            }
        }

        // Valida que el valor sea menor al número máximo
        if (maximum && value) {
            if (value > maximum) {
                validateError = false
                errorFunc(`El valor es máximo: ${numberFormat(maximum)}.`)
            }
        }
        setFocus(false)
        validateError && errorFunc(false)
        onBlur({ name, value: format ? `${value}`.replace(/\./g, '') : value, error: validateError ? false : !!message })
    }

    return (
        <Container flex={flex} style={styleContainer}>
            {<Title numberOfLines={1} dimensions={dimensions}>{title ? title : <Text style={{ color: theme.backgroundC }}>.</Text>}</Title>}
            <Input
                style={{
                    // Establece estilos adicionales según sea necesario
                    // por ejemplo, puedes establecer la altura y el padding para controlar el tamaño del área de texto
                    height: 60,
                    padding: 10,
                    borderColor: focus
                        ? theme.primaryC
                        : message || error
                            ? theme.errorC
                            : theme.onVariantSurfC,
                    borderWidth: 1,
                    fontFamily: value ? 'PFontRegular' : 'PFontLight',
                    color: theme.onVariantSurfC,
                    backgroundColor: bgColor,
                }}
                multiline={true} // Esto permite que sea un área de texto multilinea
                numberOfLines={4}
                dimensions={dimensions}
                testID={testID}
                family={value ? 'PFontRegular' : 'PFontLight'}
                borderColor={focus ? theme.primaryC : ((message || error) ? theme.errorC : theme.onVariantSurfC)}
                placeholderTextColor={theme.onVariantSurfC}
                editable={editable}
                keyboardType={keyboardType}
                returnKeyType={returnKeyType}
                maxLength={maxLength}
                secureTextEntry={secureTextEntry && secureTextEntry}
                onBlur={() => onBlur ? validationsBlur() : setFocus(false)}
                onChangeText={validations}
                value={((value && format) ? numberFormat(value) : value) || (value === 0 ? 0 : '')}
                align={align}
                bgColor={bgColor}
                onFocus={() => setFocus(true)}
            />
            <LeftBorder color={(!focus && (message || error)) ? theme.errorC : theme.successC} />
            {(!focus && (message || error)) && <>
                <TextError numberOfLines={1}>{message || 'Campo Requerido.'}</TextError>
            </>}
        </Container>
    )
}

InputHookText.propTypes = {
    title: PropTypes.string,
    returnKeyType: PropTypes.string,
    secureTextEntry: PropTypes.bool,
    onChangeText: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
    keyboardType: PropTypes.string,
    maxLength: PropTypes.array,
    editable: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
    numeric: PropTypes.bool,
    letters: PropTypes.bool,
    range: PropTypes.array,
    email: PropTypes.bool,
    format: PropTypes.bool,
    align: PropTypes.string,
    flex: PropTypes.number,
    equalTo: PropTypes.object,
    minimum: PropTypes.number,
    maximum: PropTypes.number,
    bgColor: PropTypes.string,
    testID: PropTypes.string,
    styleContainer: PropTypes.object
}