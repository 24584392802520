import React from 'react'
import { FlatList } from 'react-native'
import PropTypes from 'prop-types'
import { Entypo, AntDesign, FontAwesome } from '@expo/vector-icons'
import { numberFormat, wp } from '../../validation'
import { ContainerCard, BoxHead, Card, Row, BoxInputs, ButtonIcon, Label, BorderBottom } from './styled'
import theme from '../../../ignore/Constants'
import { ButtonSubmit } from '../ButtonSubmit'
import { InputHook } from '../InputHook'

export const Cards = ({ data: { cards, params, errorCards, messageErrorCard }, onChange, handleConfirm, handleIcon, handleClose }) => (
    <ContainerCard>
        <BoxHead>
            <Entypo name='wallet' size={wp(6.5)} color={theme.backgroundC} style={{ marginRight: wp(4) }} />
            <Label size={wp(4.5)} color={theme.backgroundC} family='PFontBold'>Cuota a Incorporar</Label>
            <ButtonIcon onPress={handleClose}>
                <AntDesign name='close' size={wp(5)} color={theme.backgroundC} />
            </ButtonIcon>
        </BoxHead>
        <FlatList
            data={cards}
            showsVerticalScrollIndicator={false}
            keyExtractor={item => `keyCardGroupCredits${item.index}`}
            ListFooterComponent={<>
                {!!messageErrorCard && <Label align='center' color={theme.errorC} >{messageErrorCard}</Label>}
                <ButtonSubmit onPress={handleConfirm} title='Registrar' />
            </>}
            renderItem={({ item, index }) => (
                <Card>
                    <Row>
                        <BoxInputs>
                            <InputHook title='Empresa' returnKeyType='next' error={errorCards[index]?.ccName} value={item?.ccName} name='ccName' required range={[3, 100]} onChangeText={e => onChange(e, index)} />
                        </BoxInputs>
                        {cards?.length > 1 &&
                            <ButtonIcon onPress={() => handleIcon(0, index)}>
                                <FontAwesome name='trash' size={wp(7)} color='#C02D2D' />
                            </ButtonIcon>
                        }
                    </Row>
                    <Row>
                        <BoxInputs>
                            <Row>
                                <InputHook flex={.47} title='Cuota' returnKeyType='next' keyboardType='numeric' error={errorCards[index]?.ccFee} value={numberFormat(item?.ccFee)} name='ccFee' required numeric format minimum={0} maximum={2000000000} onChangeText={e => onChange(e, index)} />
                                <InputHook flex={.47} title='Saldo' returnKeyType='next' keyboardType='numeric' error={errorCards[index]?.ccBalance} value={numberFormat(item?.ccBalance)} name='ccBalance' required numeric format minimum={0} maximum={params?.gcMAxWal || 2000000000} onChangeText={e => onChange(e, index)} />
                            </Row>
                        </BoxInputs>
                        {cards?.length === (index + 1) &&
                            <ButtonIcon onPress={() => handleIcon(1, index)}>
                                <AntDesign name='pluscircle' size={wp(7)} color={`${theme.primaryC}AA`} />
                            </ButtonIcon>
                        }
                    </Row>
                    {!(cards?.length === (index + 1)) && <BorderBottom />}
                </Card>
            )}
        />
    </ContainerCard>
)

Cards.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleIcon: PropTypes.func,
    handleClose: PropTypes.func
}