import styled, { css } from 'styled-components/native'
import { Animated, StyleSheet } from 'react-native'
import { mq } from '../../validation'

// Global Container
export const Container = styled.View`
    background-color: ${props => props.theme.BGSColor};
    flex: 1;
`
// Head
export const BoxHead = styled.View`
    background: ${props => props.theme.BGColor};
    border: 1px solid ${props => props.theme.PColor};
    border-radius: ${mq(16)}px;
    padding: 0 ${mq(20)}px;
    margin: ${mq(18)}px 0;
    width: 90%;
    max-width: 768px;
    align-self: center;
`
export const Content = styled.View`
    justify-content: ${({ justify }) => justify || 'flex-end'};
    margin-bottom: ${mq(12)}px;
    align-items: center;
    flex-direction:  ${({ direction }) => direction || 'row'};
    ${({ paddingTop }) => paddingTop && css`padding-top: ${paddingTop}px;`}
    ${({ borderTop, theme }) => borderTop && css`border-top-width: 1px; border-top-color: ${theme.PLColor};`}
    ${({ width }) => width && css`width: ${width};`}
`
export const BoxIcon = styled(Animated.View)`
    position: relative;
    width: ${({ width }) => width ? `${width}px` : '100%'};
    height: ${({ height }) => height ? height : mq(40)}px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    background-color: ${({ bgColor }) => bgColor};
    ${({ border, theme }) => border && css`border: 1px solid ${theme.PColor};`}
`
export const Figura = styled.View`
    background-color: ${props => props.theme.PColor};
    width: ${mq(10)}px;
    height: ${mq(18)}px;
    position: absolute;
    border: 1px solid ${props => props.theme.PColor};
    z-index: -1;
    right: -8%;
`
export const ViewMore = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    align-items: center;

    position: absolute;
    right: ${({ right }) => right || mq(30)}px;
    bottom: ${({ bottom }) => bottom || mq(20)}px;
`
export const TitleHead = styled.Text`
    font-size: ${({ size }) => size ? size : mq(12)}px;
    color: ${({ color, theme }) => color || theme.BGPColor};
    text-align: ${({ align }) => align || 'center'};
    font-family: ${({ family }) => family || 'PFontMedium'};
    ${({ marginTop }) => marginTop && css`margin-top: ${marginTop}px;`}
`
export const ContainerIcon = styled(Animated.View)`
    margin-right: ${({ marginR }) => marginR ? marginR : 0};
    width: ${({ width }) => width ? width : '18%'};
    align-items: center;
    justify-content: center;
`
export const ButtonNew = styled.TouchableOpacity`
    margin-right: ${({ marginR }) => marginR ? marginR : 0};
    width: ${({ width }) => width ? width : '18%'};
    align-items: center;
    justify-content: center;
`
// Lista
export const ViewCredit = styled.View`
    background-color: ${props => props.theme.BGColor};
    border: 1px solid ${props => props.theme.PColor};
    border-radius: ${mq(16)}px;
    padding: ${mq(10)}px ${mq(20)}px;
    margin: ${mq(18)}px 0;
    width: 90%;
    max-width: 768px;
    align-self: center;
`
export const ViewIcon = styled.View`
    background-color: #f2f2f2;
    border-radius: ${mq(8)}px;
    padding: ${mq(5)}px;
`
export const Icon = styled.View`
    height: ${mq(35)}px;
    width: ${mq(35)}px;
    justify-content: center;
    align-items: center;
    border-radius: ${mq(60)}px;
    background-color: ${props => props.theme.PColor};
 `
export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${({ paddingVertical }) => paddingVertical || mq(1)}px 0;
 `
export const BtnContent = styled.TouchableOpacity`
    padding-top: ${mq(5)}px;
    flex-direction: row;
    align-items: center;
    ${({ width }) => width && css`width: ${width};`}
`
// Footer
export const ButtonRequest = styled.TouchableOpacity`
    margin: ${mq(6)}px 0 ${mq(22)}px;
    padding: ${mq(4)}px;
    align-self: center;
    background-color: ${props => props.theme.BGAColor};
    border-radius: ${mq(18)}px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`
export const ButtonIcon = styled.View`
    height: ${mq(35)}px;
    width: ${mq(35)}px;
    justify-content: center;
    align-items: center;
    margin: ${mq(5)}px;
    border-radius: ${mq(13)}px;
    background-color: ${props => props.theme.BGColor};
`
// Otros
export const SubTitle = styled.Text`
    color: ${({ color, theme }) => color || theme.SFColor};
    font-family: ${({ family }) => family || 'PFontRegular'};
    font-size: ${({ size }) => size || mq(12)}px;
`
export const Title = styled.Text`
    font-size: ${({ size }) => size || mq(12)}px;
    font-family: PFontMedium;
    padding: 0 ${mq(2)}px;
    padding-bottom: ${({ paddingBottom }) => paddingBottom || mq(10)}px;
    color: ${({ color, theme }) => color || theme.SFColor};
`

export const PaymentButton = styled.TouchableOpacity`
    display: 'flex';
    justify-content: center;
    border-radius: 25px;
    width: 30%;
    padding: 8px 40px;
    flex-direction: row;
    align-items: center;
    ${({ width }) => width && css`width: ${width};`}
    background-color: ${props => props.theme.PColor};
`

export const styles = StyleSheet.create({
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1
        },
        shadowOpacity: 0.08,
        shadowRadius: 8.5,
        elevation: 7
    }
})