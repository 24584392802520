import React from 'react'
import Svg, { Path } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
export const LogoLogin = ({ width, height, color, style }) => (
    <Svg x='0px' y='0px' width={width} height={height} style={style} viewBox='0 0 192 53'>
        <Path fill={color} d="M6.08,24.71v-4.59h15.88v4.59h-5.1v12.9h-5.67v-12.9H6.08z"/>
        <Path fill={color} d="M23.92,29.99v-9.87h5.67v10.46c0,1.02,0.18,1.8,0.54,2.36c0.36,0.56,1.07,0.83,2.14,0.83c0.96,0,1.64-0.28,2.04-0.83s0.61-1.34,0.61-2.37V20.12h5.67v9.89c0,1.39-0.16,2.59-0.49,3.61c-0.33,1.02-0.83,1.86-1.51,2.52C37.9,36.8,37.04,37.29,36,37.6c-1.04,0.31-2.28,0.47-3.73,0.47c-1.38,0-2.58-0.14-3.6-0.42c-1.02-0.28-1.89-0.74-2.59-1.37c-0.71-0.63-1.24-1.47-1.61-2.5C24.1,32.74,23.92,31.48,23.92,29.99z"/>
        <Path fill={color} d="M65.4,25.2h-5.39c-0.09-0.56-0.36-0.96-0.81-1.2c-0.39-0.21-0.88-0.32-1.47-0.32c-1.21,0-1.81,0.34-1.81,1.01c0,0.55,0.56,0.99,1.67,1.33c1.12,0.34,2.32,0.68,3.62,1.04c1.3,0.36,2.4,0.92,3.31,1.69c0.91,0.77,1.36,1.87,1.36,3.31c0,1.32-0.34,2.42-1.01,3.32c-0.67,0.9-1.62,1.57-2.85,2.02c-1.23,0.45-2.64,0.67-4.24,0.67c-1.47,0-2.78-0.26-3.93-0.79c-1.15-0.52-2.05-1.24-2.71-2.14c-0.65-0.9-1-1.88-1.03-2.92h5.43c0.13,0.61,0.44,1.07,0.94,1.37c0.48,0.31,1.02,0.46,1.62,0.46c0.72,0,1.29-0.12,1.71-0.35c0.42-0.23,0.63-0.55,0.63-0.96c0-0.56-0.55-1.01-1.66-1.33c-1.1-0.32-2.32-0.66-3.64-1.03c-1.32-0.36-2.43-0.94-3.32-1.71c-0.9-0.78-1.34-1.92-1.34-3.44c0-0.92,0.18-1.72,0.55-2.42c0.37-0.7,0.89-1.28,1.57-1.76c0.68-0.47,1.47-0.83,2.38-1.06c0.91-0.23,1.91-0.35,3.01-0.35c1.55,0,2.86,0.24,3.92,0.73c1.06,0.49,1.87,1.14,2.43,1.97C64.9,23.19,65.25,24.13,65.4,25.2z"/>
        <Path fill={color} d="M76.21,19.66c1.97,0,3.64,0.42,5.02,1.27c1.37,0.85,2.39,1.96,3.07,3.34c0.67,1.38,1.01,2.88,1.01,4.48c0,1.93-0.39,3.6-1.18,5.01c-0.79,1.41-1.87,2.47-3.25,3.21c-1.38,0.73-2.93,1.1-4.66,1.1c-1.35,0-2.58-0.21-3.7-0.62c-1.12-0.41-2.08-1.02-2.88-1.83c-0.8-0.8-1.41-1.79-1.84-2.95c-0.43-1.17-0.65-2.49-0.65-3.96c0-1.35,0.22-2.58,0.66-3.71c0.44-1.12,1.06-2.08,1.87-2.88c0.81-0.8,1.77-1.41,2.88-1.83C73.66,19.87,74.88,19.66,76.21,19.66z M76.16,23.92c-0.8,0-1.45,0.24-1.95,0.71c-0.5,0.47-0.86,1.07-1.07,1.79c-0.22,0.73-0.32,1.5-0.32,2.33c0,0.86,0.1,1.67,0.31,2.42c0.2,0.75,0.56,1.38,1.07,1.87c0.51,0.49,1.18,0.74,2.02,0.74c1.17,0,2.03-0.45,2.58-1.35c0.56-0.9,0.83-2.13,0.83-3.69c0-1.33-0.29-2.47-0.86-3.42C78.2,24.39,77.33,23.92,76.16,23.92z"/>
        <Path fill={color} d="M101.06,37.61H87.59V20.12h5.67v13.4h7.8V37.61z"/>
        <Path fill={color} d="M103.01,29.99v-9.87h5.67v10.46c0,1.02,0.18,1.8,0.54,2.36c0.36,0.56,1.07,0.83,2.14,0.83c0.96,0,1.64-0.28,2.04-0.83c0.4-0.55,0.61-1.34,0.61-2.37V20.12h5.67v9.89c0,1.39-0.16,2.59-0.49,3.61c-0.33,1.02-0.83,1.86-1.51,2.52c-0.68,0.66-1.54,1.14-2.58,1.46c-1.04,0.31-2.28,0.47-3.73,0.47c-1.38,0-2.58-0.14-3.6-0.42c-1.02-0.28-1.89-0.74-2.59-1.37c-0.71-0.63-1.24-1.47-1.61-2.5C103.19,32.74,103.01,31.48,103.01,29.99z"/>
        <Path fill={color} d="M138.75,26.8h-5.39c-0.08-0.91-0.39-1.62-0.92-2.12c-0.54-0.51-1.25-0.76-2.16-0.76c-1.01,0-1.8,0.44-2.36,1.31c-0.56,0.87-0.85,2.14-0.85,3.8c0,1.46,0.24,2.62,0.72,3.47c0.48,0.86,1.38,1.29,2.7,1.29c0.88,0,1.55-0.27,2.03-0.81c0.48-0.54,0.77-1.26,0.88-2.16h5.46c-0.12,1.12-0.4,2.13-0.85,3.03c-0.45,0.9-1.04,1.66-1.76,2.29c-0.72,0.63-1.57,1.1-2.54,1.43c-0.97,0.33-2.02,0.49-3.14,0.49c-1.39,0-2.66-0.21-3.81-0.63c-1.14-0.42-2.12-1.03-2.92-1.84c-0.81-0.81-1.42-1.79-1.83-2.95c-0.42-1.15-0.62-2.43-0.62-3.82c0-1.4,0.22-2.67,0.66-3.79c0.44-1.13,1.05-2.09,1.83-2.89c0.78-0.8,1.7-1.41,2.77-1.84c1.06-0.43,2.19-0.64,3.38-0.64c1.31,0,2.48,0.16,3.51,0.48c1.03,0.32,1.92,0.78,2.65,1.39c0.73,0.61,1.31,1.36,1.74,2.24C138.37,24.65,138.64,25.66,138.75,26.8z"/>
        <Path fill={color} d="M146.61,37.61h-5.67V20.12h5.67V37.61z"/>
        <Path fill={color} d="M157.99,19.66c1.97,0,3.64,0.42,5.02,1.27c1.37,0.85,2.39,1.96,3.07,3.34c0.67,1.38,1.01,2.88,1.01,4.48c0,1.93-0.39,3.6-1.18,5.01c-0.79,1.41-1.87,2.47-3.25,3.21c-1.38,0.73-2.93,1.1-4.66,1.1c-1.35,0-2.58-0.21-3.7-0.62c-1.12-0.41-2.08-1.02-2.88-1.83c-0.8-0.8-1.41-1.79-1.84-2.95c-0.43-1.17-0.65-2.49-0.65-3.96c0-1.35,0.22-2.58,0.66-3.71c0.44-1.12,1.06-2.08,1.87-2.88c0.81-0.8,1.77-1.41,2.88-1.83C155.44,19.87,156.66,19.66,157.99,19.66z M157.95,23.92c-0.8,0-1.45,0.24-1.95,0.71c-0.5,0.47-0.86,1.07-1.07,1.79c-0.22,0.73-0.32,1.5-0.32,2.33c0,0.86,0.1,1.67,0.31,2.42c0.2,0.75,0.56,1.38,1.07,1.87c0.51,0.49,1.18,0.74,2.02,0.74c1.17,0,2.03-0.45,2.58-1.35c0.56-0.9,0.83-2.13,0.83-3.69c0-1.33-0.29-2.47-0.86-3.42C159.98,24.39,159.11,23.92,157.95,23.92z M155.58,18.51l2.41-3.58h5.03l-4.13,3.58H155.58z"/>
        <Path fill={color} d="M174.52,20.12l6.18,9.55l-0.21-3.99v-5.56h5.44v17.49h-5.32l-6.18-9.53l0.21,3.99v5.53h-5.44V20.12H174.52z"/>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}