// Modules
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import Constants from 'expo-constants'
import { Gravatar } from 'react-native-gravatar'
import { useTheme } from 'styled-components/native'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DrawerContentScrollView, useIsDrawerOpen } from '@react-navigation/drawer'
import { FontAwesome, FontAwesome5, AntDesign, Ionicons, MaterialIcons } from '@expo/vector-icons'

// Components
// import { Alert } from './Alert'
import { IconCredits } from '../assets/icons'
// import { Modal } from '../src/components/Modal'

//Queries and Mutations
import { isLoggedVar } from '../src/apollo/cache'
import { USE_ONE_PAYROLL_COMPANY, UPDATE_TOKEN, USE_ALL_CREDITS } from './queries'

// Styles and Utils
import { clearData, mq, numberFormat, setData } from '../src/validation'
import { OptionButton, Text, EndBox, Icon, styles, ContainerUser, Title, DrawerSection, OptionsBox, BoxProfile, IconBox } from './styled'
import { StatusBar } from 'react-native'
import { CREDIT_ALL_INFORMATION } from '../src/containers/Home/queries'

/**
 * Drawer Content
 * @param {any} props Properties Drawer Content
 * @returns {ReactElement} React Element
 */
export const DrawerContent = ({ navigation, state }) => {
    // States and Variables
    const theme = useTheme()
    const isOpen = useIsDrawerOpen()
    const [dataUser, setDataUser] = useState({})

    // Queries
    const [getOnePayroll, { data: dataPC, called }] = useLazyQuery(USE_ONE_PAYROLL_COMPANY, { fetchPolicy: 'cache-and-network' })
    const [creditInformation, { data: creditInfo }] = useLazyQuery(CREDIT_ALL_INFORMATION, { fetchPolicy: 'no-cache' })

    // Mutations
    const [updateToken, { data: dataUP, called: calledUP }] = useMutation(UPDATE_TOKEN)

    const [credits, setCredits] = useState([])
    const [getAllCredits, { data }] = useLazyQuery(USE_ALL_CREDITS, { fetchPolicy: 'cache-and-network' })

    useEffect(() => {
        getAllCredits({ variables: { state: [10], order: [['cDatCre', 'DESC']] } })
    }, [getAllCredits])

    useEffect(() => {
        creditInformation({ variables: { pcId: dataPC?.getPayrollCompanyById?.pcId } })
    }, [dataPC?.getPayrollCompanyById?.pcId])

    useEffect(() => {
        if (data?.credits?.length) {
            setCredits(data?.credits?.map(x => x)
            )
        }
    }, [data])

    /**
     * Close the active session
     * @returns {void}
     */
    const handleSignOut = () => {
        navigation.navigate('Login')
        isLoggedVar({ state: false })
    }

    // Effect Activate an event to search for credits
    useEffect(() => {
        if (state.index !== 0) {
            getOnePayroll({ variables: { state: [0, 1, 2, 3] } })
            updateToken()
        }
    }, [state.index, getOnePayroll])

    // Effect to change the background color of the status bar, when the component drawer is opened
    useEffect(() => {
        if (Constants.platform.android) {
            if (isOpen) StatusBar.setBackgroundColor('#ffffff')
            else StatusBar.setBackgroundColor('transparent')

            return () => StatusBar.setBackgroundColor('transparent')
        }
    }, [isOpen])

    useEffect(() => {
        if (called && dataPC?.getPayrollCompanyById) setDataUser(dataPC?.getPayrollCompanyById)
    }, [dataPC, called, state.index])

    // Effect to validate session
    useEffect(() => {
        const res = dataUP?.refreshUserPayrollToken
        if (calledUP && res) {
            if (res) setData('tokenAuth', res?.tokenAuth)
            else {
                clearData().then(() => {
                    navigation.navigate('Login')
                    isLoggedVar({ state: false, expired: true })
                })
            }
        }
        if (!isLoggedVar().state) {
            clearData().then(() => {
                navigation.navigate('Login')
            })
        }
    }, [dataUP, calledUP, isLoggedVar])

    return (
        <View style={{ flex: 1 }}>
            {/* <Modal isVisible={isVisibleSup} marginVertical={hp(40)}>
                <Alert data={{ phone: dataM?.getMutualById?.mPhoCal }} handleConfirm={() => setIsVisibleSup(false)}/>
            </Modal> */}
            <DrawerContentScrollView>
                <View>
                    <View>
                        <ContainerUser>
                            <Gravatar style={styles.gravatarDrawer} options={{ email: dataUser?.payrollStaff?.psEmail, parameters: { 'size': '200', 'd': 'mm' }, secure: true }} />
                            <BoxProfile>
                                <Title numberOfLines={1} color={theme.PColor} size={mq(13)}>{dataUser?.payrollStaff?.psFirNam || ''} {dataUser?.payrollStaff?.psSecNam || ''} {dataUser?.payrollStaff?.psFirLas || ''} {dataUser?.payrollStaff?.psSecLas || ''}</Title>
                                <Title numberOfLines={1} color={theme.PColor} family='PFontLight'>{dataUser?.pcIdeNum || ''}</Title>
                            </BoxProfile>
                        </ContainerUser>
                        <OptionsBox>
                            <Title size={mq(12)} family='PFontRegular' color={theme.BGColor}>Balance</Title>
                            <Title size={mq(28)} color={theme.BGColor}>$ {numberFormat(creditInfo?.CreditAllInformation?.currentBalance)}</Title>
                            {/* <OptionButtonMenu>
                                <TextBoxIcon>
                                    <IconBannerBox bgColor='#055361'>
                                        <AntDesign name='clockcircle' size={wp('5.5%')} color={backgroundC} />
                                    </IconBannerBox>
                                    <TextBox>espera</TextBox>
                                </TextBoxIcon>
                                <TextBoxIcon>
                                    <IconBannerBox bgColor='#055361'>
                                        <Entypo name='clipboard' size={wp('5.5%')} color={backgroundC} />
                                    </IconBannerBox>
                                    <TextBox>activo</TextBox>
                                </TextBoxIcon>
                                <TextBoxIcon>
                                    <IconBannerBox bgColor='#055361'>
                                        <MaterialCommunityIcons name='archive' size={wp('5.5%')} color={backgroundC} />
                                    </IconBannerBox>
                                    <TextBox>finalizado</TextBox>
                                </TextBoxIcon>
                                <TextBoxIcon>
                                    <IconBannerBox bgColor='#055361'>
                                        <MaterialIcons name='error' size={wp('6.5%')} color={backgroundC} />
                                    </IconBannerBox>
                                    <TextBox>rechazado</TextBox>
                                </TextBoxIcon>
                            </OptionButtonMenu> */}
                        </OptionsBox>
                    </View>

                    <DrawerSection>
                        <OptionItem
                            theme={theme}
                            label='Inicio'
                            onPress={() => navigation.navigate('Home')}
                            active={state.routeNames[state.index] === 'Home'}
                            icon={<Ionicons name="home-outline" size={mq(18)} color={state.routeNames[state.index] === 'Home' ? theme.BGColor : theme.PColor} />}
                        />
                        <OptionItem
                            theme={theme}
                            label='Mi Perfil'
                            onPress={() => navigation.navigate('Profile')}
                            active={state.routeNames[state.index] === 'Profile'}
                            icon={<FontAwesome name="user-o" size={mq(18)} color={state.routeNames[state.index] === 'Profile' ? theme.BGColor : theme.PColor} />}
                        />
                        <OptionItem
                            theme={theme}
                            label='Mensajes'
                            onPress={() => navigation.navigate('InBox')}
                            active={state.routeNames[state.index] === 'InBox'}
                            icon={<AntDesign name="message1" size={mq(18)} color={state.routeNames[state.index] === 'InBox' ? theme.BGColor : theme.PColor} />}
                        />
                        <OptionItem
                            theme={theme}
                            label='Solicitar Créditos'
                            onPress={() => navigation.navigate('Credits', { credits })}
                            active={state.routeNames[state.index] === 'Credits'}
                            icon={<IconCredits name='Credits' width={mq(18)} height={mq(18)} color={state.routeNames[state.index] === 'Credits' ? theme.BGColor : theme.PColor} />}
                        />
                        <OptionItem
                            theme={theme}
                            label='Términos'
                            onPress={() => navigation.navigate('Terms')}
                            active={state.routeNames[state.index] === 'Terms'}
                            icon={<FontAwesome name="exclamation" size={mq(18)} color={state.routeNames[state.index] === 'Terms' ? theme.BGColor : theme.PColor} />}
                        />
                        {/* <OptionItem
                            theme={theme}
                            label='Cirenio'
                            onPress={() => navigation.navigate('cirenio')}
                            active={state.routeNames[state.index] === 'Terms'}
                            icon={<FontAwesome name="exclamation" size={mq(18)} color={state.routeNames[state.index] === 'Terms' ? theme.BGColor : theme.PColor} />}
                        /> */}
                        <OptionItem
                            theme={theme}
                            label='Soporte'
                            // onPress={handleClick}
                            onPress={() => navigation.navigate('Support')}
                            active={state.routeNames[state.index] === 'Support'}
                            icon={<MaterialIcons name='support-agent' size={mq(18)} color={state.routeNames[state.index] === 'Support' ? theme.BGColor : theme.PColor} />}
                        />
                    </DrawerSection>

                </View>
            </DrawerContentScrollView>
            <EndBox>
                <OptionButton onPress={handleSignOut}>
                    <FontAwesome name='sign-in' size={mq(16)} color={theme.PColor} />
                    <Text color={theme.PColor} testID='7c101ac8-e369-41d0-9971-be2ae51e4f44' margin='auto 18px'>Cerrar Sesión</Text>
                </OptionButton>
            </EndBox>
        </View>
    );
}
DrawerContent.propTypes = {
    navigation: PropTypes.object,
    state: PropTypes.object
}

/**
 * Option Item
 * @param {any} props Properties from Option Item
 * @returns {ReactElement} OptionItem
 */
const OptionItem = ({ onPress, active, icon, label, theme }) => {
    return (
        <OptionButton onPress={onPress} active={active}>
            <IconBox>{icon}</IconBox>
            <Text color={active ? theme.BGColor : theme.PColor}>{label}</Text>
            <Icon>
                <FontAwesome5 name='chevron-right' size={mq(13)} color={theme.PLColor} />
            </Icon>
        </OptionButton>
    )
}

OptionItem.propTypes = {
    onPress: PropTypes.func,
    active: PropTypes.bool,
    icon: PropTypes.any,
    label: PropTypes.string,
    theme: PropTypes.object
}