import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { Terms } from '../src/pages/Terms'
import { Login } from '../src/pages/Login'
import { ScreenCamera } from '../src/Utils/containers/Camera'
import { RegisterUser, RegisterUserPayroll } from '../src/pages/Register'
import { LostPass } from '../src/pages/LostPass'
import theme from '../ignore/Constants'
import { ConfirmEmail } from '../src/pages/ConfirmEmail'
const Stack = createStackNavigator()

export const LoginStackNavigator = () => (
    <Stack.Navigator initialRouteName='Login' screenOptions={{ headerShown: false }}>
        <Stack.Screen name='Login' title={`${theme.nameApp} - Iniciar Sesión`} component={Login} />
        <Stack.Screen name='Register' title={`${theme.nameApp} - Registro`} component={RegisterUser} />
        <Stack.Screen name='RegisterPayroll' title={`${theme.nameApp} - Registro Empleado`} component={RegisterUserPayroll} />
        <Stack.Screen name='Terms' title={`${theme.nameApp} - Términos y condiciones`} component={Terms} />
        <Stack.Screen name='LostPass' title={`${theme.nameApp} - Recuperar Contraseña`} component={LostPass} />
        <Stack.Screen name='Camera' title={`${theme.nameApp} - Cámara`} component={ScreenCamera} />
        <Stack.Screen name='ConfirmEmail' title={`${theme.nameApp} - Cámara`} component={ConfirmEmail} />
    </Stack.Navigator>
)