import React from 'react'
import Svg, { ClipPath, Defs, G, Path, Use } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */

const LogoLogin = ({ width, height, props }) => (
    <Svg
        width={width}
        height={height}
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x={0}
        y={0}
        viewBox="0 0 952 292"
        style={{
            enableBackground: 'new 0 0 952 292',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <Defs>
            <Path id="SVGID_1_" d="M72.8 67.5h812.4v164H72.8z" />
        </Defs>
        <ClipPath id="SVGID_00000057864055392367764900000004608306178899752355_"
        >
            <Use
                xlinkHref="#SVGID_1_"
                style={{
                    overflow: 'visible',
                }}
            />
        </ClipPath>
        <G
            id="Grupo_301"
            fill={'#4147AF'}
            style={{
                clipPath:
            'url(#SVGID_00000057864055392367764900000004608306178899752355_)',
            }}
        >
            <Path
                id="Trazado_1262"
                className="st1"
                style={{
                    fill: '#4147AF',
                }}
                d="M72.8 148.7c8.3-.2 16.6-.3 24.9 0 .2.4.3.9.2 1.4.2 4.7.9 9.4 2.2 14 5.6 19.3 20.9 34.2 40.4 39.1 5.2 1.3 10.6 1.9 15.9 1.6 13.7-.5 26.7-6 36.5-15.5.3-.3.7-.6 1.1-.9l.8.8c5.5 5.6 11.1 11.1 16.6 16.6-1.4 1.3-2.7 2.7-4.1 3.9-9.8 8.5-21.4 14.6-34 17.7-3.7.9-7.5 1.6-11.4 1.9-2.3.2-4.7.4-7 .4-2.8 0-5.5-.2-8.3-.4-5.2-.4-10.3-1.4-15.3-2.9-15.6-4.6-29.5-13.8-39.7-26.4-8.2-10-13.9-21.8-16.6-34.4-.8-3.7-1.3-7.5-1.6-11.3 0-.6 0-1.2-.5-1.7v-3.9z"
            />
            <Path
                id="Trazado_1263"
                d="M211.4 205.9c-5.5-5.6-11.1-11.1-16.6-16.6l-.8-.8c.9-1 1.7-2 2.6-2.9 5.2-5.9 9-12.9 11.3-20.4 1.1-3.7 1.9-7.5 2.2-11.3.7-7.8-.2-15.7-2.7-23.2-6.5-19-22.5-33.1-42.2-37.1-2.9-.6-5.8-1-8.8-1-.7 0-1.4-.1-2.1-.2-.1-.3-.1-.6-.1-.9V68.1c0-.2 0-.4.1-.6h4.2c.2.3.6.2.9.3 17.2 1.1 33.6 7.7 46.8 18.8 14.3 12 24.1 28.5 27.6 46.9 1.3 6.6 1.7 13.3 1.3 20-.3 5.9-1.3 11.7-2.9 17.3-2.3 8-5.7 15.5-10.3 22.4-2.4 3.6-5 7-8 10.2-.9.8-1.7 1.6-2.5 2.5"
                style={{
                    fill: '#ffae00',
                }}
            />
            <Path
                style={{
                    fill: '#ffae00',
                }}
                id="Trazado_1264"
                className="st1"
                d="M580.6 231.5c-.2-.1-.5-.3-.7-.4-2.2-.9-3.6-3.1-3.6-5.4v-57.4c0-1 0-2.1.1-3.1.3-4.8 1.5-9.6 3.8-13.9 5-9.7 14.4-16.4 25.1-17.9 1.9-.3 3.8-.4 5.7-.4 7.6 0 15 2.4 21.1 7.1 6 4.5 10.2 10.9 12.1 18.2 1.1 4.3 1.5 8.8 1.1 13.3-.4 6.5-2.7 12.8-6.4 18.1-4.6 6.7-11.8 11.3-19.9 12.7-6.4 1.2-13 .4-19-2.4-3.8-1.7-7.2-4.3-10-7.4-.2-.3-.5-.5-.7-.8-.3.2-.2.5-.2.7V224.8c0 .7 0 1.3-.1 2-.3 2-1.6 3.7-3.4 4.5-.2.1-.5.3-.7.4h-4.3zm52.5-63.6c.1-4.4-1-8.7-3.2-12.5-2.6-4.8-7-8.4-12.2-10.1-2.7-.9-5.6-1.2-8.5-1-2.4.1-4.8.6-7 1.6-5.7 2.3-10.1 7-12.1 12.8-1.4 3.6-1.9 7.5-1.5 11.3.1 1.8.5 3.6 1 5.4 1.4 4.7 4.2 8.9 8.2 11.8 3.2 2.3 6.9 3.7 10.8 4 2.9.3 5.8 0 8.6-.8 5.7-1.7 10.5-5.7 13.2-11.1 1.9-3.5 2.8-7.4 2.7-11.4"
            />
            <Path
                id="Trazado_1265"
                className="st1"
                style={{
                    fill: '#ffae00',
                }}
                d="M884.9 185.6c-.2 1.5-.6 3-1.3 4.4-1.6 3.6-4.4 6.5-7.8 8.5-3.2 1.9-6.7 3.1-10.4 3.7-2.9.5-5.9.6-8.8.5-6.7-.2-13.3-1.8-19.3-4.7-2.5-1.2-4.8-2.8-6.8-4.7-2.3-2.3-2.3-6-.1-8.3.2-.2.4-.4.6-.5 1.1-.9 2.4-1.5 3.7-1.9 1.1-.2 2.2-.1 3.2.4.8.3 1.6.8 2.2 1.5 2.8 2.8 6.2 4.7 10 5.7 1.8.5 3.8.9 5.7 1 3 .2 6.1 0 9.1-.7 1.7-.4 3.2-1 4.6-2 1.5-1 2.6-2.5 3.1-4.2.6-2.3 0-4.7-1.6-6.4-1.3-1.3-2.8-2.4-4.5-3.1-1.9-.8-3.9-1.5-6-2-2.6-.6-5.1-1.1-7.7-1.7-4-.9-7.9-2.3-11.6-4.2-1.9-1-3.6-2.3-5.2-3.9-2.8-2.8-4.4-6.7-4.4-10.6 0-1.5.1-3.1.5-4.6.9-3.6 3-6.8 6-9 2.8-2.1 5.9-3.7 9.3-4.5 2.5-.7 5-1.1 7.6-1.2 4.8-.3 9.6.2 14.2 1.5 3.4 1 6.7 2.6 9.5 4.8 1.5 1.2 2.9 2.5 4 4.1.3.5.6 1 .8 1.5 1 2.4-.1 5.2-2.4 6.3-.5.3-1.1.4-1.7.5-2.5.3-4.9-.4-6.8-2.1-.5-.5-1.1-.9-1.6-1.4-2.7-2.2-6-3.5-9.5-3.8-1.7-.2-3.5-.3-5.2-.3-2.2.1-4.4.4-6.5 1.1-1.1.3-2.1.8-3 1.5-1.2.7-2 1.9-2.5 3.2-.7 2.6.1 5.4 2.2 7.1 1.3.9 2.7 1.6 4.1 2.1 3 1.1 6 1.9 9.1 2.5 4.1.8 8.1 1.9 12 3.4 1.8.7 3.6 1.6 5.3 2.6 3.1 1.8 5.5 4.5 7 7.7.5 1.1.9 2.3 1 3.5-.1.7-.1 1.4.3 2v2.8l-.3.6c-.1.4-.1.8-.1 1.3"
            />
            <Path
                id="Trazado_1266"
                className="st1"
                style={{
                    fill: '#ffae00',
                }}
                d="M885.2 180.8c-.3-.6-.4-1.3-.3-2h.3v2z"
            />
            <Path
                id="Trazado_1267"
                className="st1"
                style={{
                    fill: '#4147AF',
                }}
                d="M884.9 185.6v-1.4c.1-.2.1-.4.3-.6v2H884.9z"
            />
            <Path
                id="Trazado_1268"
                className="st1"
                d="M313.5 96.8c4.4 0 8.7.4 13 1.3 4.9 1.1 9.5 3 13.8 5.6 2.7 1.7 5.3 3.5 7.8 5.5 2.2 1.9 3.1 5 2 7.8-.8 2.2-2.8 3.8-5.2 4.1-2.1.4-4.2-.2-5.8-1.5-.4-.3-.9-.7-1.3-1.1-2.4-1.9-4.9-3.6-7.6-5.1-3.3-1.7-6.9-2.7-10.6-3.1-2.3-.3-4.7-.4-7-.3-6.7.1-13.3 2-19 5.5-8.7 5.3-15.1 13.5-18.2 23.2-1.8 5.5-2.6 11.4-2.2 17.2.5 9.3 3.9 18.1 9.8 25.3 5.3 6.7 12.7 11.5 21 13.7 3.4.8 6.8 1.2 10.3 1.1 8.6-.2 16.3-3.2 23.3-8.1.7-.5 1.4-1.1 2.2-1.5 1.2-.9 2.7-1.3 4.2-1.3 2.3-.1 4.5 1.3 5.4 3.5 1.5 3.1.7 6.9-2 9-6.7 5.3-14.5 9-22.8 10.8-4.9 1-10 1.3-15 1-17.7-1.1-33.6-11-42.5-26.4-3.7-6.4-6.2-13.4-7.3-20.7-.6-4.3-.8-8.7-.5-13.1.6-12.8 5.6-25.1 14.2-34.6 7.7-8.8 18.1-14.7 29.5-16.9 3.4-.7 6.9-1 10.5-.9"
            />
            <Path
                id="Trazado_1269"
                style={{
                    fill: '#4147AF',
                }}
                className="st1"
                d="M533.8 143.9V101c.1-3 2.4-5.5 5.5-5.8 1.1-.1 2.2-.1 3.2.3 2.2.7 3.8 2.6 4 4.9.1.5.1 1 .1 1.5v66.2c.1 7.6-2.4 15.1-7 21.1-5.2 7-12.9 11.7-21.5 13-7.5 1.4-15.3.2-22-3.2-9.6-4.7-16.3-13.8-18-24.3-1.4-7.4-.6-15 2.3-22 2.7-6.8 7.8-12.5 14.2-16 3.8-2.1 8.1-3.3 12.5-3.6 4.4-.4 8.8.2 12.9 1.6 4.9 1.7 9.2 4.5 12.7 8.3.3.3.6.7 1 1l.1-.1m-21.8.5c-1.7 0-3.3.1-5 .5-5.4 1.2-10.2 4.5-13.3 9.1-2.8 4.2-4.2 9.1-4.1 14.1 0 2.7.4 5.4 1.3 8 3.5 10.2 13 16.8 24.6 15 6.7-.9 12.5-5 15.6-10.9 3.2-5.8 4-12.6 2.3-19-2.2-10-11.2-17-21.4-16.8"
            />
            <Path
                id="Trazado_1270"
                className="st1"
                style={{
                    fill: '#4147AF',
                }}
                d="M417.5 172.3c.7 5.3 3.4 10.2 7.5 13.7 3.4 2.9 7.6 4.8 12 5.5 5.4.9 11 0 15.9-2.4 1.1-.6 2.2-1.2 3.2-2 1.6-1.2 3.6-1.6 5.6-1.3 2.5.6 4.4 2.7 4.6 5.2.1 1.3-.5 2.6-1.5 3.5-.6.5-1.2 1-1.9 1.5-3.5 2.4-7.5 4.2-11.6 5.3-3.6 1-7.3 1.5-11 1.4-7.6-.1-14.9-2.5-21.1-6.9-6.3-4.6-10.8-11.4-12.5-19-.9-3.8-1.2-7.7-.9-11.6.2-4 1-7.9 2.5-11.6 4.5-10.9 12.5-17.6 24.1-19.9 5.6-1.2 11.4-1 16.9.6 7.1 1.9 13.2 6.6 16.8 13 2.2 3.8 3.7 8.1 4.3 12.4.3 2.3.5 4.7.5 7 .1 3-2.3 5.6-5.4 5.6h-48zm41.7-10.2c0-.1.1-.1 0-.1-.4-3.7-1.7-7.2-3.8-10.3-2.6-3.9-6.7-6.6-11.4-7.4-3.8-.7-7.6-.5-11.3.4-3.6.9-6.8 2.8-9.3 5.5-3 3.2-5 7.1-5.7 11.4v.6c.1.1.3.1.4.1h40.7c.2-.1.3-.1.4-.2"
            />
            <Path
                id="Trazado_1271"
                className="st1"
                style={{
                    fill: '#ffae00',
                }}
                d="M710.8 172.4c.2 1.9.8 3.8 1.5 5.6 3.1 7.1 8.6 11.4 16.1 13.3 2.9.7 6 .9 9 .5 4.3-.4 8.4-2 11.8-4.5 1.6-1.2 3.7-1.7 5.7-1.4 2.9.6 4.9 3.2 4.6 6.2-.2 1.1-.8 2.1-1.7 2.8-1.9 1.6-4.1 2.9-6.3 3.9-3.7 1.7-7.6 2.9-11.7 3.6-2.9.4-5.8.5-8.7.2-2.4-.2-4.7-.6-7.1-1.2-6-1.6-11.5-4.8-15.8-9.3-4.3-4.5-7.2-10.2-8.4-16.3-.6-3-.9-6.1-.8-9.2.1-7.1 2.3-14.1 6.4-20 4.4-6.3 11-10.8 18.6-12.6 2.1-.5 4.1-.8 6.2-1 2.3-.2 4.6-.1 6.9.1 10.1 1.1 17.8 5.9 22.8 14.9 2 3.7 3.3 7.8 3.9 12 .3 2.1.5 4.3.5 6.4.2 3-2.1 5.6-5.1 5.8h-46.5c-.7.1-1.2.1-1.9.2m41.7-10.3v-1c-.6-4-2.2-7.7-4.8-10.8-2.5-3-5.9-5.1-9.7-5.9-4.1-.9-8.4-.7-12.5.4-3.1.9-6 2.5-8.3 4.8-3.1 3.1-5.3 7.1-6.1 11.5v.9c1.3.2 34 .3 40.6.1.2.1.5.1.8 0"
            />
            <Path
                id="Trazado_1272"
                className="st1"
                style={{
                    fill: '#ffae00',
                }}
                d="M798.9 202.8c-6.8 0-13.5-1.5-19.7-4.3-2.3-1-4.4-2.3-6.4-3.9-.6-.5-1.1-1-1.7-1.5-2.2-2.3-2.1-6 .2-8.2l.2-.2c.8-.7 1.7-1.2 2.6-1.6 1.6-.7 3.5-.7 5 .2.7.4 1.3.8 1.8 1.3 2.2 2.2 4.8 3.8 7.7 4.9 2.5 1 5.1 1.6 7.8 1.8 3.4.3 6.8 0 10-.9 2-.5 3.8-1.5 5.2-3 2.5-2.6 2.6-6.6.2-9.3-1.3-1.4-2.8-2.5-4.6-3.2-2.3-1-4.8-1.8-7.2-2.3-2.6-.6-5.1-1.1-7.7-1.7-3.6-.8-7.1-2.2-10.4-3.9-1.9-1-3.6-2.3-5.2-3.9-2.8-2.8-4.4-6.7-4.4-10.6-.1-3 .6-6 2-8.7 1.2-2.1 2.9-3.9 4.8-5.3 2.6-2 5.7-3.4 8.9-4.2 2.5-.7 5.1-1.1 7.8-1.2 4.7-.3 9.4.2 13.9 1.5 3.6 1 7 2.7 10 5.1 1.4 1.1 2.7 2.5 3.8 4 .6.8 1 1.8 1.1 2.9.2 2.3-1.3 4.4-3.5 5.1-2 .6-4.2.4-6-.6-.8-.4-1.6-1-2.3-1.6-2-1.9-4.5-3.3-7.1-4.1-1.4-.4-2.8-.7-4.3-.8-1.5-.1-3-.2-4.5-.2-2.2 0-4.4.4-6.5 1.1-1 .4-1.9.8-2.8 1.3-2.6 1.6-3.3 3.9-2.7 6.9.3 1.5 1.3 2.9 2.6 3.7 1.2.8 2.4 1.4 3.8 1.9 3 1.1 6 1.9 9.1 2.5 4.1.8 8 1.9 11.9 3.3 2.7 1 5.1 2.3 7.4 4 3.4 2.5 5.6 6.3 6.1 10.4.9 5.8-1 11.7-5.1 15.9-2.3 2.3-5.2 4.1-8.3 5.2-2.9 1.1-5.9 1.8-9 2-1.4 0-3 .1-4.5.2"
            />
            <Path
                id="Trazado_1273"
                className="st1"
                style={{
                    fill: '#4147AF',
                }}
                d="M371 142c.4-.4.7-.6.9-.8 3.8-4 8.8-6.7 14.2-7.8 2.7-.5 5.4-.7 8.1-.5 2 .1 3.9.4 5.8.9 1.3.4 2.5.9 3.5 1.7.6.4 1.1.9 1.6 1.4 1.3 1.5 1.5 3.6.6 5.3-.7 1.8-2.4 3-4.2 3-1.3 0-2.6-.2-3.9-.5-2.6-.4-5.2-.6-7.8-.6-2.9.1-5.8.6-8.5 1.6-1.6.6-3.1 1.4-4.5 2.4-3.6 2.5-5.8 6.5-5.8 10.9v36.7c0 .8-.1 1.6-.2 2.4-.3 2.2-2.1 3.9-4.2 4.1-1.3.3-2.7.2-4-.1-2.1-.5-3.7-2.2-3.9-4.4-.1-.8-.2-1.7-.2-2.5v-54.5c0-.8.1-1.6.2-2.4.3-2.2 2-4 4.2-4.4 1.2-.3 2.5-.3 3.8-.1 2.4.3 4.3 2.3 4.4 4.8 0 .4.1.8.1 1.3-.2.4-.2 1.1-.2 2.1"
            />
            <Path
                id="Trazado_1274"
                className="st1"
                style={{
                    fill: '#ffae00',
                }}
                d="M665.7 141.8c.4-.3.6-.5.7-.6 4.2-4.4 9.8-7.2 15.8-8.1 3.8-.6 7.7-.5 11.5.4 1.9.4 3.7 1.2 5.2 2.4.9.7 1.6 1.6 1.9 2.7.5 2-.2 4.1-1.8 5.4-.7.6-1.6.9-2.6 1-1.4 0-2.7-.2-4-.5-2.4-.4-4.8-.6-7.3-.6-3.1 0-6.2.6-9.1 1.6-2.4.8-4.5 2.2-6.3 3.9-2.4 2.3-3.8 5.5-3.9 8.8V194.3c0 .9-.1 1.9-.1 2.8 0 2.5-1.9 4.6-4.4 5-1.3.3-2.6.2-3.9-.1-2.3-.5-4.1-2.5-4.2-4.9V139.9c0-.9.1-1.9.4-2.8.5-1.6 1.8-2.8 3.4-3.3 1.7-.5 3.5-.5 5.1 0 2 .6 3.4 2.3 3.5 4.3.1.8.1 1.7.2 2.5-.2.4-.2.6-.1 1.2"
            />
            <Path
                id="Trazado_1275"
                className="st1"
                style={{
                    fill: '#4147AF',
                }}
                d="M570.4 168v27.3c0 .8-.1 1.7-.3 2.5-.7 2.5-2.9 4.3-5.4 4.4-.7.1-1.5 0-2.2-.1-2.8-.6-4.9-3-4.9-5.9v-55.6c0-.6 0-1.1.1-1.7.4-3 2.8-5.2 5.8-5.4 1-.1 2 0 2.9.4 2.3.8 4 2.9 4.1 5.4v1.5c-.1 9-.1 18.1-.1 27.2"
            />
            <Path
                id="Trazado_1276"
                className="st1"
                style={{
                    fill: '#4147AF',
                }}
                d="M555.7 114c-.2-4.3 3.8-8.3 8.4-8.3 4.5.1 8.1 3.8 8.2 8.3 0 4.6-3.7 8.3-8.3 8.3-4.6 0-8.3-3.7-8.3-8.3"
            />
            <Path
                id="Trazado_1277"
                className="st1"
                style={{
                    fill: '#4147AF',
                }}
                d="M153.5 179.4c-6.3.4-15-2.1-21.4-7.4a33.75 33.75 0 0 1-13-23.5c-2.1-19 11.7-36.2 30.7-38.3 19-2.1 36.2 11.7 38.3 30.7.1 1.2.2 2.4.2 3.7-.3.1-.6.1-.9.1-5.1-.2-10.2-.2-15.3-.1-.5 0-1 .1-1.5.1-.3 0-.5 0-.8-.1-.6-10.9-9.5-16.4-17-15.9-7.9.5-14.2 6.6-14.9 14.5-.5 4.5 1 8.9 4.1 12.2 3 3.3 7.2 5.2 11.7 5.3.2 1.3.3 12.2.2 17.8-.3.4-.4.7-.4.9"
            />
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    props: PropTypes.any
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}