import { gql } from '@apollo/client';

export const SET_SEEN_CREDIT_REPORT = gql`
    mutation SetSeenCreditReport($crId: ID) {
      setSeenCreditReport(crId: $crId) {
        crId
        cId
        crState
      }
    }
`

export const CREDIT_REPORTS = gql`
    query GetCreditReportsByPcId($pcId: ID, $order: [[String]]) {
        getCreditReportsByPcId(pcId: $pcId, order: $order) {
          crId
          cId
          nId
          credit {
            cConsecutive
            cName
          }
          crType
          crDatPen
          crComment
          crSeen
          crState
          crEdit
          crLink
          notification {
            nId
            nCode
            nName
            nTitle
            nMessage
            nState
          }
        }
    }
`