export const TerCon = ({
    name,
    nameBrand
}) => {
    return [
        {
            id: 1,
            message: `Los presentes Términos y Condiciones corresponden a los lineamientos generales para el otorgamiento de préstamos con descuento directo o libranza establecidos por CONSULTORIA Y ASESORIA INTEGRAL EMPRESARIAL LTDA., en adelante ${nameBrand}, identificada con NIT. 900.220.976-1, sociedad colombiana y legalmente constituida bajo las leyes de la República de Colombia, con domicilio en la ciudad de Bogotá, a través de su página web: www.nauwcolombia.com y su plataforma tecnológica ${name}.`,
        },
        {
            id: 2,
            title: `${name}`,
            message: `${name} es la plataforma tecnológica de ${nameBrand} que ofrece servicios financieros, préstamos en línea con recursos lícitos, bajo la modalidad de “libranza” y para la destinación de libre inversión que EL EMPLEADO de la EMPRESA ALIADA requiera para su liquidez y atención a sus necesidades y se accede a través de la página web: www.nauwcolombia.com.`,
        },
        {
            id: 3,
            title: 'CONOCIMIENTO',
            message: `Para el conocimiento de quien interese el presente documento rige el proceso de otorgamiento de préstamos con descuento directo o libranza, en todas sus etapas, partiendo desde la solicitud y hasta el efectivo pago de la misma. Para todos los efectos, quienes decidan hacer uso de los productos y servicios de ${nameBrand}, deberán conocer las condiciones aquí fijadas. `,
        },
        {
            id: 4,
            message: `Asimismo, aplica para las sociedades, empresas filiales, matrices, subsidiarias y/o terceros vinculados con ${nameBrand}. Todo lo relacionado con el cumplimiento y aplicación de los presentes términos y condiciones, se sujetará a las normas legales civiles y comerciales de la República de Colombia, en especial lo establecido en Estatuto del Consumidor Ley 1480 de 2011, y demás normas que lo modifiquen o adicionen. Si tiene alguna inquietud favor remitirla al correo radicacion.conasie@gmail.com, en días hábiles en el horario de 8:00 am a 5:00 pm.`,
        },
        {
            id: 5,
            message: `Al acceder a los servicios financieros ofrecidos por ${nameBrand}, EL EMPLEADO de la EMPRESA ALIADA se compromete a devolver incondicionalmente la suma de dinero prestada, mediante cuotas mensuales las cuales incluirán capital e intereses, costos administrativos, afianzamiento, seguros, garantías, Impuestos y demás que se requieran y sean acordados por las partes en los documentos contractuales relacionados con el préstamo. En caso de surgir controversias, las partes podrán acudir directamente ante la justicia ordinaria de la Republica de Colombia para dirimir el conflicto o ante las autoridades competentes, entiéndase Superintendencia de Industria y Comercio o quién haga sus veces.`,
        },
        {
            id: 6,
            message: `Son causales de terminación de la relación comercial entre ${nameBrand} y EL EMPLEADO o la EMPRESA ALIADA las siguientes:`,
        },
        {
            id: 7,
            message:
                '•  El incumplimiento parcial o total de los términos y condiciones aquí establecidas.',
        },
        {
            id: 8,
            message:
                '•  La terminación o el incumplimiento de los acuerdos, contratos y demás suscritos con LA EMPRESA ALIADA.',
        },
        {
            id: 9,
            message: `•  La imposibilidad de ${nameBrand} de prestar los servicios financieros o de LA EMPRESA ALIADA en cumplir con su parte por motivos de fuerza mayor, caso fortuito u otro que impidan la prestación del servicio.`,
        },
        {
            id: 10,
            message: `•  El uso indebido, fraude, intrusión u otro a la plataforma tecnológica, así como la violación a los derechos de propiedad, reproducción o aplicación de ingeniería inversa sobre la plataforma e información allí contenida, uso sin autorización, suplantación de EL EMPLEADO o de ${nameBrand} o ${name}, entre otras que ante presentarse alguna de ellas, ${nameBrand} se reserva el derecho de investigar y tomar acciones ante las autoridades correspondientes.`,
        },
        {
            id: 11,
            message: `${nameBrand} se reserva el derecho de modificar el presente documento y publicar la modificación en el momento que lo considere necesario.`,
        },
        {
            id: 12,
            message: `${nameBrand} no se responsabiliza por cualquier daño, perjuicio, fallas o dificultad en el acceso y uso de la plataforma, dificultades técnicas, fallas en los sistemas, o en el servidor o en Internet durante el uso de la página web o plataforma, así como de cualquier virus que afecte el dispositivo del usuario, por lo que no se responsabilizará de ninguna manera a ${nameBrand} ni exigir pago por lucro cesante.  Ante interrupciones que se puedan presentar por eventualidades ajenas, ${nameBrand} procurará restablecer en la brevedad posible la página o plataforma sin que ello pueda imputarle algún tipo de responsabilidad.`,
        },
        {
            id: 13,
            message: `Al ingresar, revisar y usar este sitio web y la plataforma ${name}, usted se compromete a leer, informarse y cumplir los términos y condiciones de usos aquí dispuestos, así como a conocer la Política de Tratamiento y Protección de Datos Personales disponible aquí y la Política de Préstamos disponible en el presente documento.`,
        },
        {
            id: 14,
            title: 'ACEPTACIÓN',
            message: `Quién solicita y accede a los productos y servicios comercializados por ${nameBrand}, a través del acceso y uso de la página web y de su plataforma ${name}, acepta de manera libre y voluntaria las condiciones, obligaciones y los deberes, determinadas en el presente documento, y las que se deriven o lleguen a derivar del mismo. Así mismo, toda relación contractual acordada con ${nameBrand} se rige, en lo que competa a los presentes Términos y Condiciones que se entienden aceptados por quienes suscriban acuerdos con esta, razón por la que serán plenamente vinculantes, en toda y cada una de sus directrices, obligaciones, deberes y derechos. Esta aceptación en ningún momento es una garantía de la aprobación de la solicitud de los servicios financieros o préstamos de dinero que realice.`,
        },
        {
            id: 15,
            message: `${nameBrand} podrá consultar en cualquier momento el historial crediticio, el desempeño como deudor, y capacidad de pago, en vista de lo anterior, se entiende y acepta que la consulta tiene la potencialidad de afectar de afectar su puntuación financiera.`,
        },
        {
            id: 16,
            message: `Por otro lado,  EL EMPLEADO de la EMPRESA ALIADA es consciente y acepta que atraso en el pago de las obligaciones contraídas con ${nameBrand} genera intereses de mora, gastos de cobranza & penalizaciones y reporte en las centrales de riesgo y se rige por las normas establecidas en la Política de Tratamiento y Protección de Datos Personales disponible aquí.`,
        },
        {
            id: 17,
            title: 'DEFINICIONES',
        },
        {
            id: 18,
            title: 'PRÉSTAMOS POR LIBRANZA PARA LIBRE INVERSIÓN',
            message:
                'Todos aquellos préstamos de dinero que son desembolsados directamente a EL EMPLEADO solicitante de la EMPRESA ALIADA, por transferencia electrónica a una cuenta de ahorros de la cual sea titular, y cuya destinación es de libre escogencia del mismo.',
        },
        {
            id: 19,
            title: 'AVANCE DE PRIMAS',
            message:
                'Todos aquellos préstamos que sean desembolsados a EL EMPLEADO solicitante de la EMPRESA ALIADA de hasta un cincuenta por ciento (50%) del valor de las primas que le correspondan, los cuales sólo podrán desembolsarse en el mes de marzo para las primas que conciernan al primer semestre y en el mes de septiembre para las primas del segundo semestre, con un plazo máximo de noventa (90) días.',
        },
        {
            id: 20,
            title: 'EMPRESA ALIADA',
            message: `Empresas que cuenta con un convenio de libranza suscrito con ${nameBrand} para ofrecer a través de la plataforma tecnológica ${name} a sus EMPLEADOS una alternativa de acceso a préstamos de dinero por libranza para libre inversión y por avance de primas.`,
        },
        {
            id: 21,
            title: 'EMPLEADO SOLICITANTE',
            message: `Persona natural que tiene un contrato laboral vigente con la EMPRESA ALIADA y que solicita los servicios financieros que presta ${nameBrand} a través de la plataforma tecnológica ${name}.`,
        },
        {
            id: 22,
            title: 'PLATAFORMA TECNOLÓGICA',
            message: `Software as a Service denominada ${name} que cuenta con las parametrizaciones y actualizaciones requeridas, soporte y asesoría técnica y la correcta operación y mantenimiento de la misma para asegurar el uso y seguridad en el servicio establecido para la solicitud de los servicios financieras por parte de EL EMPLEADO solicitante de la EMPRESA ALIADA y cuenta con la interface en la página web www.nauwcolombia.com.`,
        },
        {
            id: 23,
            title: 'BASE DE DATOS DE LOS EMPLEADOS',
            message: `Información básica que bajo el cumplimiento de la Ley de Protección de Datos la EMPRESA ALIADA proporciona a ${nameBrand} de LOS EMPLEADOS para que los mismos puedan acceder y solicitar los servicios financieros de manera EXCLUSIVA y solo para estos fines a través de la plataforma ${name}.`,
        },
        {
            id: 24,
            title: 'FORMULARIO DE SOLICITUD DE PRÉSTAMO',
            message:
                'Documento a través del cual se recolecta información adicional de EL EMPLEADO solicitante de la EMPRESA ALIADA para solicitar un servicio financiero y está cubierto bajo la Política de Tratamiento y Protección de Datos Personales.',
        },
        {
            id: 25,
            message:
                'Siempre que se diligencie por parte de EL EMPLEADO solicitante de la EMPRESA ALIADA un FORMULARIO DE SOLICITUD DE PRÉSTAMO, se le solicitará la aceptación de los Términos y Condiciones de Uso de LA PLATAFORMA aquí indicados con el acceso a la Política de Tratamiento y Protección de Datos Personales.',
        },
        {
            id: 26,
            message:
                'Doy fe y validez a que leí, entendí y acepto los Términos y Condiciones establecidos aquí por CONSULTORIA Y ASESORIA INTEGRAL EMPRESARIAL LTDA.',
        },
        {
            id: 27,
            title: 'POLÍTICA DE PRÉSTAMOS',
            message: `A fin de cumplir con todas las disposiciones legales y regulaciones correspondientes de los distintos organismos de vigilancia y control, en CONSULTORIA Y ASESORIA INTEGRAL EMPRESARIAL LTDA, en adelante CONAISE, se establece la presente política de préstamos con los lineamientos y compromisos de las partes que permiten un debido ejercicio de otorgamiento y desembolso de préstamos de dinero, así como una gestión de cobro en los eventos en que EL EMPLEADO de la EMPRESA ALIADA incurra en mora en el pago de las obligaciones contraídas con ${nameBrand}.`,
        },
        {
            id: 28,
            title: 'POBLACIÓN OBJETIVO',
        },
        {
            id: 29,
            message: `Con la finalidad de facilitar el proceso de estudio para el otorgamiento y desembolso de los préstamos de dinero, ${nameBrand} ha decidido enfocar sus esfuerzos en una población de mercado específica, bajo los lineamientos que se especifican a continuación:`,
        },
        {
            id: 30,
            message: `1. La población objetivo de ${nameBrand} está compuesto por Personas Naturales que ostenten calidad de Empleados con un contrato laboral suscrito con una EMPRESA ALIADA de ${nameBrand}.`,
        },
        {
            id: 31,
            message: `2. El EMPLEADO solicitante del préstamo debe contar con una estabilidad laboral y/o de ingresos comprobados provenientes de una relación laboral, con una EMPRESA con la que ${nameBrand} tenga un Convenio de pago por descuento directo o libranza.`,
        },
        {
            id: 32,
            message:
                '3. La edad mínima de EL EMPLEADO para acceder a todas las líneas de préstamo es desde los 18 años y hasta la edad máxima legal de jubilación.',
        },
        {
            id: 33,
            message:
                '4. Los Ingresos mínimos requeridos a EL EMPLEADO solicitante serán la suma de Un (1) salario mínimo legal mensual vigente (SMLV).',
        },
        {
            id: 34,
            message:
                '5. La modalidad de contratación con la que debe contar EL EMPLEADO solicitante es: Contrato a término indefinido, a término fijo por un término de un año, o contrato obra y labor siempre y cuando la EMPRESA ALIADA autorice su vinculación al Convenio para ser beneficiario de los servicios financieros ofrecidos.',
        },
        {
            id: 35,
            message:
                '6. EL EMPLEADO solicitante debe tener una antigüedad mínima de tres (3) meses en la EMPRESA ALIADA o el tiempo que se acuerde de antigüedad mínima con la EMPRESA ALIADA.',
        },
        {
            id: 36,
            message:
                '7. EL EMPLEADO solicitante debe tener la obligación y responsabilidad de acreditar que es titular de la cuenta bancaria que registre para el desembolso del préstamo y por lo tanto, dicha cuenta corresponde con el solicitante del préstamo, en el evento de no haber coincidencia entre EL EMPLEADO y el titular de la cuenta, no procederá la aprobación ni al desembolso del préstamo.',
        },
        {
            id: 37,
            title: 'LINEAMIENTOS DE PRÉSTAMO',
            message: `Además de las condiciones previas, ${nameBrand} cuenta con los siguientes lineamientos de préstamo:`,
        },
        {
            id: 38,
            message:
                '•	Los préstamos podrán ascender hasta una suma de dos (2) veces el salario devengado por EL EMPLEADO o la suma convenida con la EMPRESA ALIADA.',
        },
        {
            id: 39,
            message:
                '•	El término de los préstamos otorgados tendrá hasta una vigencia de doce (12) meses.',
        },
        {
            id: 40,
            message:
                '•	Sobre cada uno de los lineamientos podrán aplicar algunas excepciones siempre y cuando medie autorización del superior jerárquico correspondiente, es decir la EMPRESA ALIADA.',
        },
        {
            id: 41,
            title: 'VALIDACIÓN, APROBACIÓN O RECHAZO DEL PRÉSTAMO',
            message:
                'Toda solicitud de préstamo debe cumplir el proceso de estudio a través del cual se valida la información del solicitante, se evalúa la viabilidad de la solicitud de acuerdo con la capacidad de endeudamiento para el préstamo de dinero por parte de EL EMPLEADO y se emite uno de los siguientes conceptos como resultado de la evaluación:',
        },
        {
            id: 42,
            message: `•	Aprobado: Corresponde a que el resultado del proceso de validación y estudio, el solicitante cumple con las condiciones y lineamientos de préstamo aquí contenidos en la política de préstamos. Para ello, ${nameBrand} se reserva el derecho de aprobarle una suma igual o inferior a la solicitud.`,
        },
        {
            id: 43,
            message:
                '•	Aprobado: Corresponde a que el resultado del proceso de validación y estudio, el solicitante NO cumple con las condiciones y lineamientos de préstamo aquí contenidos en la política de préstamos.',
        },
        {
            id: 44,
            message: `El concepto del estudio es informado a EL EMPLEADO solicitante. Con base en la capacidad de endeudamiento y criterios, condiciones y lineamientos de préstamos, ${nameBrand} se reserva el derecho de aprobar montos menores a mayores los solicitados de conformidad con lo establecido en el artículo 3 de la ley 1527 de 2012 no podrá realizarse una retención salarial superior al cincuenta por ciento (50%) después de los descuentos de ley.`,
        },
        {
            id: 45,
            message: 'Por otra parte, EL EMPLEADO cuyo préstamo haya sido aprobado, puede:',
        },
        {
            id: 46,
            message:
                '•	Aceptar el préstamo para lo cual se procederá con la firma electrónica y cumplimiento de las garantías aquí expuestas.',
        },
        {
            id: 47,
            message: `•	Rechazar el préstamo para lo cual debe cancelar la solicitud a través de la plataforma ${name}. En el caso de requerir ese mismo préstamo u otro, en cualquier momento, debe iniciar una nueva solicitud.`,
        },
        {
            id: 48,
            title: 'INFORMACIÓN SOBRE EL PRÉSTAMO',
            message: `Todas las operaciones de préstamo que realice ${nameBrand}, que se encuentren activas deberán contener como mínimo la siguiente información:`,
        },
        {
            id: 49,
            message: '1.	Monto del préstamo.',
        },
        {
            id: 50,
            message: '2.	Tasa de interés.',
        },
        {
            id: 60,
            message: '3.	Plazo de amortización.',
        },
        {
            id: 70,
            message: '4.	Modalidad de las cuotas.',
        },
        {
            id: 71,
            message: '5.	Forma de pago.',
        },
        {
            id: 72,
            message: '6.	Periodicidad en el pago de capital y de intereses.',
        },
        {
            id: 73,
            message: '7.	Tipo y cobertura de la garantía.',
        },
        {
            id: 74,
            message: '8.	Descuentos al momento de desembolso.',
        },
        {
            id: 75,
            message:
                'En general, toda la información que resulte relevante y necesaria para facilitar la adecuada comprensión del alcance de los derechos y obligaciones del acreedor, es decir de EL EMPLEADO. La anterior información será suministrada al solicitante del préstamo antes que este firme los documentos mediante los cuales se instrumente un préstamo de libranza o manifieste su aceptación. ',
        },
        {
            id: 76,
            title: 'ANÁLISIS DE RIESGO',
            message: `El análisis del riesgo crediticio tiene como propósito principal identificar los posibles riesgos de ${nameBrand} con el otorgamiento del préstamo mencionado. Por otro lado, dicho análisis permite a ${nameBrand} el sometimiento de las solicitudes de préstamo al cumplimiento de los requisitos aquí establecidos, así como la normatividad. Para tal fin se debe evaluar y verificar cada uno de los siguientes factores y requisitos:`,
        },
        {
            id: 77,
            title: 'I.	Requisitos generales de préstamo',
            message:
                'Los Empleados que desean acceder a un préstamo deberán cumplir los requisitos descritos a continuación:',
        },
        {
            id: 78,
            message: `1)	Diligenciar el formulario de la solicitud del préstamo con sus datos personales en la plataforma tecnológica ${name} de ${nameBrand}, en el sitio web https:nauwcolombia.com/`,
        },
        {
            id: 79,
            message:
                '2)	Remitir fotografía legible de la parte frontal y posterior de la cédula física, en fondo blanco.',
        },
        {
            id: 80,
            message:
                '3)	Remitir fotografía legible en modo autofoto o selfie únicamente de EL EMPLEADO junto a la cédula física en su parte frontal (se recomienda realizar la foto con la cámara trasera del celular para mejor calidad).',
        },
        {
            id: 81,
            message: '4)	Remitir copia de último desprendible de nómina.',
        },
        {
            id: 82,
            title: 'II.	Garantías',
            message: `Las garantías que respaldan la operación en un préstamo de libranza otorgado por ${nameBrand} son las siguientes:`,
        },
        {
            id: 83,
            message:
                '1)	Pago de los valores correspondientes por descuento directo en nómina de EL EMPLEADO.',
        },
        {
            id: 84,
            message: `2)	Póliza de seguro de vida debidamente expedida por una compañía acreditada, donde conste como beneficiario ${nameBrand}.`,
        },
        {
            id: 85,
            message: '3)	Pagaré y Carta de Instrucciones.',
        },
        {
            id: 84,
            message: '4)	Desembolso del préstamo en la misma cuenta inscrita por el Empleado deudor.',
        },
        {
            id: 85,
            message: '5)	Afiliación y afianzamiento del préstamo otorgado.',
        },
        {
            id: 86,
            message: '6)	Control de incorporación de cuotas en pagaduría.',
        },
        {
            id: 87,
            title: 'DESEMBOLSO Y CUMPLIMIENTO DEL PRÉSTAMO',
            message: `Aprobado y cumplido lo establecido en el presente documento, ${nameBrand} realiza el desembolso a la cuenta bancaria de EL EMPLEADO cuyo tiempo estará sujeto a la gestión administrativa de ${nameBrand} en la inscripción y transferencia bancaria y por ende, a los tiempos que la entidad bancaria correspondiente maneje para tal fin.`,
        },
        {
            id: 88,
            message:
                'Una vez EL EMPLEADO recibe el préstamo de dinero debe cumplir en monto y tiempo los pagos de los valores correspondientes por descuento directo en nómina. En el caso de presentarse cuotas vencidas, se generarán intereses moratorios que serán cobrados a la tasa máxima legal permitida.',
        },
    ];
}