import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { hp, wp } from '../../validation'

export const Box = styled.View`
    width: 90%;
    flex-direction: row;
    align-items: center;
    align-self: center;
    background-color: ${theme.primaryC};
    padding: ${wp(2)}px ${wp(5)}px;
    justify-content: space-between;
    border-top-left-radius: ${wp(9)}px;
    border-top-right-radius: ${wp(9)}px;
`
export const Title = styled.Text`
    font-size: ${wp(3)}px;
    padding: ${wp(.2)}px 0;
    font-family: ${({ family }) => family || 'PFontBold'};
    color: ${theme.backgroundC};
`
export const IconContainer = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    width: ${wp(10)}px;
    height: ${wp(10)}px;
    border-radius: ${wp(100)}px;
    background-color: ${theme.backgroundC};
`
/* Sombra */
export const styles = StyleSheet.create({
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: hp(1)
        },
        shadowOpacity: 0.08,
        shadowRadius: 8.5,
        elevation: 7
    }
})