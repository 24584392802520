
import styled, { css } from 'styled-components/native'
import theme from '../../../ignore/Constants'
import { mq } from '../../validation'
import Constants from 'expo-constants'

/**
 * -- Container and view for login
 *
 */
export const Box = styled.View`
    flex: 1;
    justify-content: center;
    align-self: center;
    width: 90%;
    max-width: 700px;
    padding: ${Constants.statusBarHeight}px ${mq(5)}px ${mq(1)}px ${mq(5)}px;
    border-top-left-radius: ${mq(15)}px;
    border-top-right-radius: ${mq(15)}px;
`
export const BoxLogo = styled.View`
    align-self: center;
    padding: 15px;
    width: 60%;
    height: 20%;
`
/**
 * -- Buttons Styles
 *
 */
export const BtnContainers = styled.View`
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    padding: ${mq(6)}px ${mq(10)}px;
    border-radius: ${mq(18)}px;
    background-color: ${theme.backgroundP};
    border: 1px solid #EDEDEF;
    margin-bottom: ${mq(28)}px;
    align-self: center;
`
export const BtnLogin = styled.TouchableOpacity`
    background-color: ${theme.backgroundP};
    flex-direction: row;
    background-color: ${({ active }) => active ? theme.backgroundC : 'transparent'};
    padding: ${mq(16)}px ${mq(4)}px;
    width: 49%;
    border-radius: ${mq(16)}px;
    justify-content: center;
`
export const Title = styled.Text`
    font-family: ${({ family }) => family || 'PFontBold'};
    font-size: ${({ size }) => size || mq(13)}px;
    color: ${({ color }) => color || theme.primaryC};
    ${({ margin }) => margin && css`margin: ${margin};`}
`
/**
 * -- Form Styles
 *
 */
export const Form = styled.View`
    background-color: ${theme.backgroundC};
    border-radius: ${mq(8)}px;
    padding: ${mq(25)}px;
`
export const DetailsContainer = styled.View`
  background-color: white;
  box-shadow: 0px 0px 10px #00000042;
  width: 100%;
  height: 25em;
  min-height: 150px;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
`;
export const DetailsInformation = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Content = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
  padding: 0px 5px;
`;
export const PaymentButton = styled.TouchableOpacity`
    display: 'flex';
    justify-content: center;
    border-radius: 25px;
    padding: 8px 40px;
    flex-direction: row;
    align-items: center;
    ${({ width }) => width && css`width: ${width};`}
    background-color: ${props => props.theme.PColor};
`