import styled from 'styled-components/native'
import { mq } from '../../validation'

export const TitleContainer = styled.View`
    background-color: ${({ bgColor, theme }) => bgColor || theme.BGColor};
    padding: ${mq(12)}px;
    width: 96%;
    max-width: 768px;
    align-self: center;
    margin: ${({ margin }) => margin || `${mq(15)}px 0`};
    justify-content: center;
    align-items: center;
    border-radius: ${mq(13)}px;
    flex-direction: row;
    border: 1px solid ${props => props.theme.PColor};
`
export const Title = styled.Text`
    width: 80%;
    text-align: center;
    font-size: ${mq(15)}px;
    font-family: PFontBold;
    color: ${({ color, theme }) => color || theme.PColor};
`