import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { LocationDataRegisterV } from '../../components/LocationDataRegister'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { filterKeyObject, writeCache, validationErrors } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'
import { UPDATE_PAYROLL_COMPANY } from './queries'

export const LocationDataRegisterC = ({ dataUser, banner, handleNext, refScroll }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [payrollCompanyMutation, { loading }] = useMutation(UPDATE_PAYROLL_COMPANY)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({ payrollResidence: filterKeyObject(dataUser?.payrollResidence, ['__typename']) })
        setErrorForm({
            cId: !dataUser?.payrollResidence?.cId,
            taId: !dataUser?.payrollResidence?.taId,
            prNumDir: !dataUser?.payrollResidence?.prNumDir,
            prNum: !dataUser?.payrollResidence?.prNum,
            prNumHou: !dataUser?.payrollResidence?.prNumHou,
            prStratum: !dataUser?.payrollResidence?.prStratum
        })
    }, [dataUser])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ payrollResidence: { ...values.payrollResidence, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await payrollCompanyMutation({ variables: { input: values }, update(store, { data: { setOrUpdatePayrollCompany } }) {
            writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: setOrUpdatePayrollCompany, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
        } }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdatePayrollCompany) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(3)
        }
    }

    return (
        <LocationDataRegisterV
            data={{ values, errors, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

LocationDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    refScroll: PropTypes.object
}