import React from 'react'
import PropTypes from 'prop-types'
import Constants from 'expo-constants'
import { MaterialIcons, Ionicons } from '@expo/vector-icons'
import theme from './Constants'
import { Header, Button, Row, styles } from '../navigation/styled'
import { mq } from '../src/validation'
import { Gravatar } from 'react-native-gravatar'
import image from '../assets/img/image'
//import { Logo } from './Logo'

const Logo = image('LogoSecond')
export const HeaderNavigator = ({ scene: { route, descriptor: { navigation } } }) => {
    let handleBack = false
    let screen = ''
    let camera = ''

    switch (route?.name) {
    case 'RequestCreditData':
        handleBack = true
        screen = 'CreditsRequest'
        break;
    case 'InBoxDetails':
        handleBack = true
        screen = 'InBox'
        break;
    case 'RepaymentPlan':
        handleBack = true
        screen = 'Home'
        break;
    case 'GroupCredit':
        handleBack = true
        screen = 'Home'
        break;
    case 'Camera':
        camera = true
        break;
    case 'Login':
        camera = true
        break;
    case 'CreditsRequest':
        handleBack = true
        screen = 'Credits'
        break;
    case 'CreditTypeRepaymentPlan':
        handleBack = true
        screen = 'CreditsRequest'
        break;
    case 'EditCredit':
        handleBack = true
        screen = 'Home'
        break;
    case 'InformationAdditionalCreditLifeInsurance':
        handleBack = true
        screen = 'Home'
        break;
    case 'InformationAdditionalCreditSarlaft':
        handleBack = true
        screen = 'InformationAdditionalCreditLifeInsurance'
        break;
    case 'CreditsLine':
        handleBack = true
        screen = 'CreditGroupLine'
        break;
    default:
        break;
    }

    if (camera) return <></>
    return (
        <Header>
            <Button
                testID='b9bdb062-eaf4-4310-b377-0b282358c79b'
                onPress={() => handleBack ? navigation.navigate(screen, {}) : navigation.openDrawer()}
                marginTop='0'
                align='flex-end'
                style={{ backgroundColor: '#f3f3f3', borderRadius: mq(6) }}
            >
                {handleBack ?
                    <MaterialIcons
                        name='arrow-back-ios'
                        size={mq(25)}
                        color={theme.primaryC}
                    />
                    : <Ionicons name="menu-outline" size={mq(25)} color={theme.primaryC} />}
            </Button>
            <Button
                align='flex-end'
                marginTop={Constants.platform.web
                    ? mq(6) : mq(6)
                }
                style={{ paddingLeft: mq(65) }}
                onPress={() => navigation.navigate('Home')}
            >
                <Logo
                    width={mq(85)}
                    height={mq(45)}
                    color={theme.primaryC}
                />
            </Button>
            <Row>
                <Button
                    padding={`0 ${mq(6)}`}
                    marginTop={mq(10)}
                    onPress={() => navigation.navigate('InBox')}
                >
                    <Ionicons
                        name='chatbubble-ellipses'
                        size={mq(30)}
                        color={theme.primaryC}
                    />
                </Button>
                <Button
                    onPress={() => navigation.navigate('Profile')}
                >
                    <Gravatar
                        style={styles.gravatar}
                        options={{
                            email: 'luisdavid25540055@gmail.com',
                            parameters: { 'size': '180', 'd': 'mm' },
                            secure: true
                        }} />
                </Button>
            </Row>
        </Header>
    )
}

HeaderNavigator.propTypes = {
    scene: PropTypes.object
}