import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { Cities } from '../Selects/Cities'
import { TypeAddress } from '../Selects/TypeAddress'
import { Relationships } from '../Selects/Relationships'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, BoxButton, Row, Box, Title } from './styled'

export const ReferenceDataRegisterV = ({ data: { banner, errors, values, loading, format }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            {!!format?.cpfRefFam &&
                <>
                    <Box>
                        <Title>Referencia familiar</Title>
                    </Box>
                    <Row>
                        <InputHook flex={.47} title='Nombre *' returnKeyType='next' error={errors?.cprNamFam} value={values?.creditPayrollReference?.cprNamFam} name='cprNamFam' required letters range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook flex={.47} title='Apellido *' returnKeyType='next' error={errors?.cprLasFam} value={values?.creditPayrollReference?.cprLasFam} name='cprLasFam' required letters range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    </Row>
                    <Relationships error={errors?.rIdFam} value={values?.creditPayrollReference?.rIdFam} handleSelect={e => handleChange({ name: 'rIdFam', value: e.value, error: e.error })} />
                    <InputHook title='Número teléfono *' returnKeyType='next' keyboardType='numeric' error={errors?.cprPhoFam} value={values?.creditPayrollReference?.cprPhoFam} name='cprPhoFam' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                    <Cities error={errors?.cIdFam} value={values?.creditPayrollReference?.cIdFam} handleSelect={e => handleChange({ name: 'cIdFam', value: e.value, error: e.error })} />
                    <TypeAddress error={errors?.taIdFam} value={values?.creditPayrollReference?.taIdFam} handleSelect={e => handleChange({ name: 'taIdFam', value: e.value, error: e.error })} />
                    <Row>
                        <InputHook flex={.40} title='No. Dirección *' returnKeyType='next' error={errors?.cprNumDirFam} value={values?.creditPayrollReference?.cprNumDirFam} name='cprNumDirFam' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook flex={.25} title='No. *' returnKeyType='next' error={errors?.cprNumFam} value={values?.creditPayrollReference?.cprNumFam} name='cprNumFam' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook flex={.25} title='No. Casa *' returnKeyType='next' error={errors?.cprNumHouFam} value={values?.creditPayrollReference?.cprNumHouFam} name='cprNumHouFam' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    </Row>
                    <InputHook title='Información Residencial (Opcional)' returnKeyType='next' value={values?.creditPayrollReference?.cprInfFam} name='cprInfFam' range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />
                </>
            }
            {!!format?.cpfRefPar &&
                <>
                    <Box>
                        <Title>Referencia particular</Title>
                    </Box>
                    <Row>
                        <InputHook flex={.47} title='Nombre *' returnKeyType='next' error={errors?.cprNamPar} value={values?.creditPayrollReference?.cprNamPar} name='cprNamPar' required letters range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook flex={.47} title='Apellido *' returnKeyType='next' error={errors?.cprLasPar} value={values?.creditPayrollReference?.cprLasPar} name='cprLasPar' required letters range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    </Row>
                    <InputHook title='Número teléfono *' returnKeyType='next' keyboardType='numeric' error={errors?.cprPhoPar} value={values?.creditPayrollReference?.cprPhoPar} name='cprPhoPar' required numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                    <Cities error={errors?.cIdPar} value={values?.creditPayrollReference?.cIdPar} handleSelect={e => handleChange({ name: 'cIdPar', value: e.value, error: e.error })} />
                    <TypeAddress error={errors?.taIdPar} value={values?.creditPayrollReference?.taIdPar} handleSelect={e => handleChange({ name: 'taIdPar', value: e.value, error: e.error })} />
                    <Row>
                        <InputHook flex={.40} title='No. Dirección *' returnKeyType='next' error={errors?.cprNumDirPar} value={values?.creditPayrollReference?.cprNumDirPar} name='cprNumDirPar' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook flex={.25} title='No. *' returnKeyType='next' error={errors?.cprNumPar} value={values?.creditPayrollReference?.cprNumPar} name='cprNumPar' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                        <InputHook flex={.25} title='No. Casa *' returnKeyType='next' error={errors?.cprNumHouPar} value={values?.creditPayrollReference?.cprNumHouPar} name='cprNumHouPar' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                    </Row>
                    <InputHook title='Información Residencial (Opcional)' returnKeyType='next' value={values?.creditPayrollReference?.cprInfPar} name='cprInfPar' range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />
                </>
            }
            <BoxButton>
                <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

ReferenceDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
}