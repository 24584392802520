import Swal from 'sweetalert2'
import theme from '../../../../ignore/Constants'
import './styled.css'

export const AlertA = () => Swal.fire({
    text: 'Si desconoce el código de su empresa, póngase en contacto con su asesor de crédito.',
    customClass: 'swal-wide',
    type: 'info',
    confirmButtonColor: theme.primaryC,
    icon: 'info',
    iconColor: theme.primaryC,
})