import styled, { css } from 'styled-components/native'
import { mq } from '../../validation'
import theme from '../../../ignore/Constants'

export const Container = styled.View`
    flex: 1;
    justify-content: flex-end;
    background-color: ${theme.primaryC};
`
export const Box = styled.View`
    flex: .83;
    width: 100%;
    position: relative;
    border-top-left-radius: ${mq(40)}px;
    border-top-right-radius: ${mq(40)}px;
    padding: ${mq(100)}px ${mq(20)}px ${mq(5)}px;
    background-color: ${props => props.theme.BGSColor};
`
export const BoxLogo = styled.View`
    top: ${-mq(70)}px;
    align-self: center;
    position: absolute;
    width: ${mq(140)}px;
    height: ${mq(140)}px;
`
export const Title = styled.Text`
    text-align: center;
    font-size: ${({ size }) => size || mq(14)}px;
    color: ${({ color }) => color || theme.SFColor};
    ${({ margin }) => margin && css`margin: ${margin};`}
    font-family: ${({ family }) => family || 'PFontBold'};
`
export const Footer = styled.View`
    flex: .1;
    overflow: hidden;
    align-items: center;
    background-color: ${props => props.theme.BGSColor};
`
export const BoxMethod = styled.TouchableOpacity`
    width: 75%;
    flex-direction: row;
    align-items: center;
    padding: ${mq(12)}px;
    border-radius: ${mq(6)}px;
    justify-content: space-between;
    background-color: ${props => props.theme.PColor};
    ${({ marginTop }) => marginTop && css`margin-top: ${marginTop}px;`}
    ${({ marginBottom }) => marginBottom && css`margin-bottom: ${marginBottom}px;`}
`
export const BorderBottom = styled.View`
    width: 80%;
    margin-bottom: ${mq(20)}px;
    border: .5px solid ${props => props.theme.PLColor};
`