import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'

export const DocumentS = props => (
    <ContentLoader
        speed={1}
        width={200}
        height={120}
        viewBox='0 0 200 120'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}
    >
        <Rect x='10' y='10' rx='10' ry='10' width='180' height='100' />
    </ContentLoader>
)