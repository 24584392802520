import styled, { css } from 'styled-components/native'
import { hp, mq, wp } from '../../validation'
import themes from '../../../ignore/Constants'

export const ButtonPrint = styled.TouchableOpacity`
    width:8%;
    padding: 5px;
    border-radius: 50px;
    background-color: #ffb23f;
    margin-top: 10px;
`
export const InfoView = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
`

export const Container = styled.View`
    max-width: 900px;
    width: 96%;
    margin-bottom: ${mq(30)}px;
    align-self: center;
`
export const BoxLogo = styled.View`
    align-self: center;
    width: 40%;
    height: ${mq(150)}px;
    margin-top: 20%;
`
export const Title = styled.Text`
    font-size: ${wp(6)}px;
    color: ${({ theme }) => theme.PColor};
    text-align: center;
    padding-bottom: ${hp(2)}px;
    font-family: PFontBold;
`
export const SubTitle = styled.Text`
    color: ${({ color }) => color || themes.onSurfaceC};
    font-family: ${({ family }) => family || 'PFontRegular'};
    font-size: ${({ size }) => size || wp(3.5)}px;
    ${({ margin }) => margin && css`margin: ${margin};`}
    text-decoration: underline;
`
export const BoxFooter = styled.View`
    /* backgroundColor: backgroundC, */
    align-items: center;
    padding: ${hp(5)}px 0;
`
export const Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`
export const BoxFile = styled.TouchableOpacity`
    margin: ${hp(1)}px 0 ${hp(4)}px 0;
    padding: ${wp(2.5)}px ${wp(4)}px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: ${wp(10)}px;
`