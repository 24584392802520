import React from 'react'
import PropTypes from 'prop-types'
import { FlatList } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ButtonSubmit } from '../../components/ButtonSubmit'
// import { TerCon } from '../../../ignore/Conditions'
import theme from '../../../ignore/Constants'
import { HeadTitle } from '../HeadTitle'
import { wp } from '../../validation'
import { ButtonContainer, Container, Text, Title } from './styled'
import termConditions from '../../../ignore/TerCon/termConditions'

const TerCon = termConditions('TerCon')
export const TermsV = ({ navigation, route }) => (
    <Container>
        <FlatList
            data={TerCon}
            keyExtractor={item => `${item.id}`}
            ListHeaderComponent={<HeadTitle title='TÉRMINOS LEGALES' icon={<MaterialCommunityIcons name='file-document-edit' size={wp(8)} color={theme.primaryC} />} />}
            ListFooterComponent={<ButtonContainer>{route?.params?.register && <ButtonSubmit title='Continuar' onPress={() => navigation.goBack()} />}</ButtonContainer>}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => (
                <>
                    {!!item.title && <Title>{item.title}</Title>}
                    {!!item.message && <Text >{item.message}</Text>}
                </>
            )}
        />
    </Container>
)

TermsV.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}