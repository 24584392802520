import React from 'react'
import { FlatList } from 'react-native'
import PropTypes from 'prop-types'
import { Entypo, AntDesign } from '@expo/vector-icons'
import { numberFormat, wp } from '../../validation'
import { ContainerCard, BoxHead, TitleHead, Card, Row, BoxInputs, ButtonIcon, Label, BorderBottom } from './styled'
import theme from '../../../ignore/Constants'
import { ButtonSubmit } from '../ButtonSubmit'
import { InputHook } from '../InputHook'

export const Credits = ({ data: { credits, messageErrorCredit }, handleConfirm, handleCheck, handleClose }) => (
    <ContainerCard>
        <BoxHead>
            <Entypo name='credit-card' size={wp(6.5)} color={theme.backgroundC} style={{ marginRight: wp(4) }} />
            <TitleHead>Mis Créditos</TitleHead>
            <ButtonIcon onPress={handleClose}>
                <AntDesign name='close' size={wp(5)} color={theme.backgroundC} />
            </ButtonIcon>
        </BoxHead>
        <FlatList
            data={credits}
            showsVerticalScrollIndicator={false}
            keyExtractor={item => item.cId}
            ListFooterComponent={<>
                {!!messageErrorCredit && <Label align='center' color={theme.errorC} >{messageErrorCredit}</Label>}
                <ButtonSubmit onPress={handleConfirm} title='Seleccionar' />
            </>}
            renderItem={({ item, index }) => (
                <Card>
                    <Row>
                        <BoxInputs>
                            <InputHook title='Línea de Crédito' value={item?.cName} editable={false} />
                        </BoxInputs>
                    </Row>
                    <Row>
                        <BoxInputs>
                            <Row>
                                <InputHook flex={.47} title='Cuota' value={numberFormat(item?.cValCou)} editable={false} />
                                <InputHook flex={.47} title='Saldo' value={numberFormat(item?.cBalance)} editable={false} />
                            </Row>
                        </BoxInputs>
                        <ButtonIcon onPress={() => handleCheck(item)}>
                            {item.check ? <AntDesign name='checksquare' size={wp(7)} color={`${theme.primaryC}AA`} /> : <AntDesign name='checksquareo' size={wp(7)} color={`${theme.primaryC}AA`} />}
                        </ButtonIcon>
                    </Row>
                    {!(credits?.length === (index + 1)) && <BorderBottom />}
                </Card>
            )}
        />
    </ContainerCard>
)

Credits.propTypes = {
    data: PropTypes.object,
    handleConfirm: PropTypes.func,
    handleCheck: PropTypes.func,
    handleClose: PropTypes.func
}