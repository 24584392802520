import { gql } from '@apollo/client'

export const USE_ONE_PAYROLL_COMPANY = gql`
    query getOnePayrollCompany($state: [Int]) {
        getPayrollCompanyById(state: $state) {
            pcId
            tiId
            typeIdentity {
                tiName
            }
            pcIdeNum
            pcState
            payrollStaff {
                psFirNam
                psSecNam
                psFirLas
                psSecLas
                psEmail
            }
        }
    }
`

export const USE_ONE_MUTUAL = gql`
    query getMutualById {
        getMutualById {
            mId
            mPhoCal
        }
    }
`

export const UPDATE_TOKEN = gql`
    mutation updateToken {
        refreshUserPayrollToken {
            tokenAuth
        }
    }
`

export const USE_ALL_CREDITS = gql`
    query getAllCredits($state: [Int], $order: [[String]]) {
        credits(state: $state, order: $order) {
            cId
            cName
            cConsecutive
        }
    }
`