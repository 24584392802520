import { gql } from '@apollo/client';

export const UPDATE_PAYROLL_COMPANY = gql`
    mutation setOrUpdatePayrollCompany(
        $pcId: ID
        $input: ISetOrUpdatePayrollCompany!
    ) {
        setOrUpdatePayrollCompany(pcId: $pcId, input: $input) {
            pcId
            payrollResidence {
                prId
                cId
                taId
                prNumDir
                prNum
                prNumHou
                prInformation
                thId
                dId
                alId
                msId
                prState
            }
        }
    }
`;

export const CREATE_PAYROLL_BANK = gql`
    mutation SetOrUpdatePayrollBank(
        $input: ISetOrUpdatePayrollBank!
        $pcId: ID
        $conditionFind: Boolean
        $pbId: ID
    ) {
        setOrUpdatePayrollBank(
            input: $input
            pcId: $pcId
            conditionFind: $conditionFind
            pbId: $pbId
        ) {
            pbId
            pcId
            tbId
            atId
            pbAccNum
            cId
            pbState
        }
    }
`;

export const UPDATE_PAYROLL_ECONOMY_ACTIVITIES = gql`
    mutation UpdatePayrollEconomyActivities(
        $input: IUpdatePayrollEconomyActivities!
        $pcId: ID
    ) {
        updatePayrollEconomyActivities(input: $input, pcId: $pcId) {
            peaId
            pcId
        }
    }
`;

export const UPDATE_LIFE_INSURANCE = gql`
    mutation SetOrUpdatePayrollLifeInsurance($input: ISetOrUpdatePayrollLifeInsurance!, $pcId: ID, $conditionFind: Boolean) {
        setOrUpdatePayrollLifeInsurance(input: $input, pcId: $pcId, conditionFind: $conditionFind) {
            pcId
            pliId
        }
    }
`;