import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { isLoggedVar } from '../../apollo/cache';
import { EconomicDataRegisterV } from '../../components/EconomicDataRegister';
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries';
import {
    filterKeyObject,
    writeCache,
    validationErrors,
} from '../../validation';
import {
    CREATE_PAYROLL_BANK,
    UPDATE_LIFE_INSURANCE,
    UPDATE_PAYROLL_COMPANY,
    UPDATE_PAYROLL_ECONOMY_ACTIVITIES,
} from './queries';

export const EconomicDataRegisterC = ({
    dataUser,
    banner,
    handleNext,
    format,
    refScroll,
    userParameters,
    refetchPayroll,
}) => {
    const [values, setValues] = useState({});
    const [errorForm, setErrorForm] = useState({});
    const [errors, setErrors] = useState({});
    const [payrollCompanyMutation, { loading }] = useMutation(
        UPDATE_PAYROLL_COMPANY
    );
    const [payrollBankMutation /* { loading: loadingPayrollBank } */] =
    useMutation(CREATE_PAYROLL_BANK);
    const [payrollEconomyMutation, { loading: loadEconomy }] = useMutation(
        UPDATE_PAYROLL_ECONOMY_ACTIVITIES
    );
    const [payrollLifeInsuranceMutation] =
    useMutation(UPDATE_LIFE_INSURANCE);

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({
            payrollResidence: filterKeyObject(dataUser?.payrollResidence, [
                '__typename',
            ]),
            payrollStaff: filterKeyObject(dataUser?.payrollStaff, ['__typename']),
            payrollWork: filterKeyObject(dataUser?.payrollWork, ['__typename']),
            payrollEconomyActivities: {
                ...filterKeyObject(dataUser?.payrollEconomyActivities, [
                    '__typename',
                    'city',
                    'department',
                    'typeContract',
                ]),
                dIdDepartment: dataUser?.payrollEconomyActivities?.dId,
            },
            payrollBank: {
                pbId: dataUser?.payrollBank?.pbId,
                tbId: dataUser?.payrollBank?.typeBank?.tbId,
                atId: dataUser?.payrollBank?.atId,
                cityId: dataUser?.payrollBank?.city?.cId,
                pbAccNum: dataUser?.payrollBank?.pbAccNum,
            },
            payrollLifeInsurances: filterKeyObject(dataUser?.payrollLifeInsurances, [
                '__typename',
            ]),
        });
    }, [dataUser]);

    const employee = values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO';
    const independent =
    values?.payrollEconomyActivities?.peaActivity === 'INDEPENDIENTE';
    const pensioner =
    values?.payrollEconomyActivities?.peaActivity === 'PENSIONADO';
    const student =
    values?.payrollEconomyActivities?.peaActivity === 'ESTUDIANTE';
    useEffect(() => {
        setErrorForm({
            thId:
        format?.ufTypHou &&
        !dataUser?.payrollResidence?.thId &&
        !values?.payrollResidence?.thId,
            dId:
        format?.ufDependents &&
        !dataUser?.payrollResidence?.dId &&
        !values?.payrollResidence?.dId,
            alId:
        format?.ufAcaLev &&
        !dataUser?.payrollResidence?.alId &&
        !values?.payrollResidence?.alId,
            msId:
        format?.ufMarSta &&
        !dataUser?.payrollResidence?.msId &&
        !values?.payrollResidence?.msId,
            peaActivity:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaActivity,
            peaCompanyName:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaCompanyName &&
        employee,
            peaAddress:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaAddress &&
        employee,
            dIdDepartment:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.dIdDepartment &&
        employee,
            peaTypeCompany:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaTypeCompany &&
        employee,
            peaWorkPhone:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaWorkPhone &&
        employee,
            peaEmail:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaEmail &&
        employee,
            cId:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.cId &&
        employee,
            peaPosition:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaPosition &&
        employee,
            tcId:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.tcId &&
        employee,
            peaAntiquity:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaAntiquity &&
        employee,
            peaAntiquityIndependents:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaAntiquityIndependents &&
        independent,
            peaBussiness:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaBussiness &&
        independent,
            peaChamberCommerce:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaChamberCommerce &&
        independent,
            peaRUT:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaRUT &&
        independent,
            peaPensionFund:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaPensionFund &&
        pensioner,
            peaTypePension:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaTypePension &&
        pensioner,
            peaUniversity:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaUniversity &&
        student,
            peaGenerateIncome:
        format?.ufEconomyActivities &&
        !values?.payrollEconomyActivities?.peaGenerateIncome &&
        student,
            pbAccNum: !values?.payrollBank?.pbAccNum,
            atId: !values?.payrollBank?.atId,
            cityId: !values?.payrollBank?.cityId,
            tbId: !values?.payrollBank?.tbId,
        });
    }, [dataUser, values, employee, student, independent, pensioner]);

    const validationPublicPositions = values?.payrollWork?.pwHolPubOff;
    useEffect(() => {
        setValues({
            ...values,
            payrollWork: {
                ...values?.payrollWork,
                pwHolPubOff:
          values?.payrollWork?.pwHolPubOff ||
          dataUser?.payrollWork?.pwHolPubOff,
                pwHasPubRec:
          values?.payrollWork?.pwHolPubOff === 2
              ? 2
              : values?.payrollWork?.pwHasPubRec ||
            dataUser?.payrollWork?.pwHasPubRec,
                pwHasRelPubExp:
          values?.payrollWork?.pwHolPubOff === 2
              ? 2
              : values?.payrollWork?.pwHasRelPubExp ||
            dataUser?.payrollWork?.pwHasRelPubExp,
            },
        });
    }, [validationPublicPositions]);

    useEffect(() => {
        setValues({
            payrollBank: {
                ...filterKeyObject(dataUser?.payrollBank, ['__typename']),
                cityId: dataUser?.payrollBank?.city?.cId,
            },
            payrollResidence: filterKeyObject(dataUser?.payrollResidence, [
                '__typename',
            ]),
            payrollStaff: filterKeyObject(dataUser?.payrollStaff, ['__typename']),
            payrollEconomyActivities: {
                ...filterKeyObject(dataUser?.payrollEconomyActivities, [
                    '__typename',
                    'city',
                    'department',
                    'typeContract',
                ]),
                dIdDepartment: dataUser?.payrollEconomyActivities?.dId,
            },
            payrollWork: {
                ...filterKeyObject(dataUser?.payrollWork, ['__typename']),
                pwHolPubOff:
          values?.payrollWork?.pwHolPubOff ||
          dataUser?.payrollWork?.pwHolPubOff,
                pwHasPubRec:
          values?.payrollWork?.pwHolPubOff === 2
              ? 2
              : values?.payrollWork?.pwHasPubRec ||
            dataUser?.payrollWork?.pwHasPubRec,
                pwHasRelPubExp:
          values?.payrollWork?.pwHolPubOff === 2
              ? 2
              : values?.payrollWork?.pwHasRelPubExp ||
            dataUser?.payrollWork?.pwHasRelPubExp,
            },
            payrollLifeInsurance: filterKeyObject(dataUser?.payrollLifeInsurance, [
                '__typename',
            ]),
        });
    }, [dataUser]);

    // Cambio en los valores

    const handleChange = (e, type) => {
        let updatedValue = e.value;

        if (updatedValue === '') {
            updatedValue = null;
        }
        if (type === 1) {
            setValues({
                ...values,
                payrollResidence: {
                    ...values.payrollResidence,
                    [e.name]: updatedValue,
                },
            });
        } else if (type === 2) {
            setValues({
                ...values,
                payrollStaff: { ...values.payrollStaff, [e.name]: updatedValue },
            });
        } else if (type === 4) {
            setValues({
                ...values,
                payrollBank: {
                    ...(values.payrollBank || {}),
                    [e.name]: updatedValue,
                },
            });
        } else if (type === 3) {
            if (e.item?.peaActivity === 'EMPLEADO') {
                setValues({
                    ...values,
                    payrollWork: { ...values.payrollWork, pwCIIU: '0010' },
                    payrollEconomyActivities: {
                        ...values.payrollEconomyActivities,
                        [e.name]: e.value,
                    },
                });
                return '0010';
            } else if (e.item?.peaActivity === 'PENSIONADO') {
                setValues({
                    ...values,
                    payrollWork: { ...values.payrollWork, pwCIIU: '0020' },
                    payrollEconomyActivities: {
                        ...values.payrollEconomyActivities,
                        [e.name]: e.value,
                    },
                });
                return '0020';
            } else if (e.item?.peaActivity === 'INDEPENDIENTE') {
                setValues({
                    ...values,
                    payrollWork: { ...values.payrollWork, pwCIIU: '' },
                    payrollEconomyActivities: {
                        ...values.payrollEconomyActivities,
                        [e.name]: e.value,
                    },
                });
                return '0';
            } else if (e.item?.peaActivity === 'ESTUDIANTE') {
                setValues({
                    ...values,
                    payrollWork: { ...values.payrollWork, pwCIIU: '' },
                    payrollEconomyActivities: {
                        ...values.payrollEconomyActivities,
                        [e.name]: e.value,
                    },
                });
                return '0';
            }

            setValues({
                ...values,
                payrollEconomyActivities: {
                    ...values.payrollEconomyActivities,
                    [e.name]: e.value,
                },
            });
        } else if (type === 5) {
            setValues({
                ...values,
                payrollLifeInsurance: {
                    ...values.payrollLifeInsurance,
                    [e.name]: updatedValue,
                },
            });
        } else {
            setValues({
                ...values,
                payrollWork: { ...values.payrollWork, [e.name]: updatedValue },
            });
        }
        setErrorForm({ ...errorForm, [e.name]: e.error });
        setErrors({ ...errors, [e.name]: e.error });
    };

    const handleSubmit = async () => {
        setErrors(errorForm);
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) {
            return isLoggedVar({
                ...isLoggedVar(),
                message: 'Por favor rellene todo los campos',
            });
        }

        const { data, error } = await payrollCompanyMutation({
            variables: {
                input: filterKeyObject(values, [
                    'payrollEconomyActivities',
                    'payrollBank',
                    'payrollLifeInsurances',
                ]),
            },
            update(store, { data: { setOrUpdatePayrollCompany } }) {
                writeCache({
                    store,
                    query: USE_ONE_PAYROLL_COMPANY,
                    name: 'getPayrollCompanyById',
                    dataNew: setOrUpdatePayrollCompany,
                    variables: { state: [0, 1, 2, 3] },
                    typeName: 'PayrollCompany',
                });
            },
        }).catch((e) => ({ error: e }));

        await payrollBankMutation({
            variables: {
                input: {
                    pbState: 1,
                    tbId: values?.payrollBank?.tbId,
                    atId: values?.payrollBank?.atId,
                    cId: values?.payrollBank?.cityId,
                    pbAccNum: values?.payrollBank?.pbAccNum,
                },
                pcId: values?.payrollEconomyActivities?.pcId || dataUser?.pcId,
                ...(values?.payrollBank?.pbId
                    ? { pbId: values?.payrollBank?.pbId, conditionFind: true }
                    : {}),
            },
        });
        await payrollLifeInsuranceMutation({
            variables: {
                pcId:
          values?.payrollEconomyActivities?.pcId || dataUser?.pcId,
                ...(dataUser?.payrollLifeInsurances?.pliId ?
                    {
                        conditionFind: true,
                    }
                    : {}),
                input: {
                    ieId: values?.payrollLifeInsurance?.ieId,
                },
            },
        });

        const { data: dataEconomy, error: errorEconomy } =
      await payrollEconomyMutation({
          variables: {
              input: {
                  peaActivity: values?.payrollEconomyActivities?.peaActivity,
                  peaCompanyName:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.peaCompanyName
                  : null,
                  peaAddress:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.peaAddress
                  : null,
                  peaTypeCompany:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.peaTypeCompany
                  : null,
                  peaWorkPhone:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.peaWorkPhone
                  : null,
                  peaEmail:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.peaEmail
                  : null,
                  dId:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.dIdDepartment
                  : null,
                  cId:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.cId
                  : null,
                  peaPosition:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.peaPosition
                  : null,
                  tcId:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? values?.payrollEconomyActivities?.tcId
                  : null,
                  peaAntiquity:
              values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO'
                  ? +(values?.payrollEconomyActivities?.peaAntiquity || 0)
                  : 0,
                  peaAntiquityIndependents:
              values?.payrollEconomyActivities?.peaActivity === 'INDEPENDIENTE'
                  ? +(
                      values?.payrollEconomyActivities
                          ?.peaAntiquityIndependents || 0
                  )
                  : 0,
                  peaBussiness:
              values?.payrollEconomyActivities?.peaActivity === 'INDEPENDIENTE'
                  ? values?.payrollEconomyActivities?.peaBussiness
                  : null,
                  peaChamberCommerce:
              values?.payrollEconomyActivities?.peaActivity === 'INDEPENDIENTE'
                  ? values?.payrollEconomyActivities?.peaChamberCommerce
                  : 0,
                  peaRUT:
              values?.payrollEconomyActivities?.peaActivity === 'INDEPENDIENTE'
                  ? values?.payrollEconomyActivities?.peaRUT
                  : 0,
                  peaPensionFund:
              values?.payrollEconomyActivities?.peaActivity === 'PENSIONADO'
                  ? values?.payrollEconomyActivities?.peaPensionFund
                  : null,
                  peaTypePension:
              values?.payrollEconomyActivities?.peaActivity === 'PENSIONADO'
                  ? values?.payrollEconomyActivities?.peaTypePension
                  : null,
                  peaUniversity:
              values?.payrollEconomyActivities?.peaActivity === 'ESTUDIANTE'
                  ? values?.payrollEconomyActivities?.peaUniversity
                  : null,
                  peaGenerateIncome:
              values?.payrollEconomyActivities?.peaActivity === 'ESTUDIANTE'
                  ? values?.payrollEconomyActivities?.peaGenerateIncome
                  : 0,
              },
              pcId: values?.payrollEconomyActivities?.pcId || dataUser?.pcId,
          },
          update(store, { data: { updatePayrollEconomyActivities } }) {
              writeCache({
                  store,
                  query: USE_ONE_PAYROLL_COMPANY,
                  name: 'getPayrollCompanyById',
                  dataNew: updatePayrollEconomyActivities,
                  variables: { state: [0, 1, 2, 3] },
                  typeName: 'PayrollCompany',
              });
          },
      }).catch((e) => ({ error: e }));

        // verifica si hay error
        if (error || errorEconomy) {
            return isLoggedVar({
                ...isLoggedVar(),
                message: (error || errorEconomy).message,
            });
        }

        /** resultado */
        if (
            data?.setOrUpdatePayrollCompany &&
      dataEconomy?.updatePayrollEconomyActivities
        ) {
            refScroll?.current &&
        refScroll?.current.scrollTo({ x: 0, y: 0, animated: true });
            setErrorForm({});
            refetchPayroll();
            handleNext(3.5);
        }
    };
    return (
        <EconomicDataRegisterV
            data={{ values, errors, loading, loadEconomy, format, banner }}
            handleBlur={(e) => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleChangeResidence={handleChange}
            handleSubmit={handleSubmit}
            userParameters={userParameters}
        />
    );
};

EconomicDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    refScroll: PropTypes.object,
    userParameters: PropTypes.object,
    refetchPayroll: PropTypes.func,
};