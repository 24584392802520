import React from 'react'
import { IconProcessing } from '../../../assets/icons'
import { wp } from '../../validation'
import { Container, Title } from './styled'

export const NoAuthorize = () => (
    <Container>
        <IconProcessing width={wp(40.5)} height={wp(40.5)} color='#FFFF00'/>
        <Title>Temporalmente fuera de servicio. Por favor, póngase en contacto con nuestro proveedor de servicios para obtener asistencia.</Title>
    </Container>
);