import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { wp } from '../validation'

export const MoneyReportsS = props => (
    <ContentLoader
        speed={2}
        width={wp(95)}
        height={590}
        viewBox='0 0 400 590'
        backgroundColor='#e6e6e6'
        foregroundColor='#ffffff'
        {...props}
    >
        <Rect x='10' y='10' rx='5' ry='5' width='380' height='50' />
        <Rect x='10' y='75' rx='5' ry='5' width='380' height='50' />
        <Rect x='10' y='140' rx='5' ry='5' width='380' height='50' />
        <Rect x='10' y='205' rx='5' ry='5' width='380' height='50' />
        <Rect x='10' y='270' rx='5' ry='5' width='380' height='50' />
        <Rect x='10' y='335' rx='5' ry='5' width='380' height='50' />
        <Rect x='10' y='400' rx='5' ry='5' width='380' height='50' />
        <Rect x='10' y='465' rx='5' ry='5' width='380' height='50' />
        <Rect x='10' y='530' rx='5' ry='5' width='380' height='50' />
    </ContentLoader>
)