import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { G, LinearGradient, Path, RadialGradient, Stop } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ width, height, idOne, idTwo }) => (
    <Svg x='0px' y='0px' width={width} height={height} viewBox='0 0 557 544'>
        <G>
            <G><Path opacity='0.4' fill='#225180' d='M486.7,272.9c0,113.3-91.9,205.2-205.2,205.2c-113.3,0-205.2-91.9-205.2-205.2c0-113.3,91.9-205.2,205.2-205.2C394.8,67.6,486.7,159.5,486.7,272.9z'/></G>
            <LinearGradient id={idOne} gradientUnits='userSpaceOnUse' x1='443.4089' y1='89.7025' x2='133.9034' y2='441.7461'>
                <Stop offset='0' stopColor='#FFFFFF' />
                <Stop offset='0.9968' stopColor='#A0B3B8' />
            </LinearGradient>
            <Path fill={`url(#${idOne})`} d='M523,267.7c0,130.4-105.7,236.2-236.2,236.2c-130.4,0-236.2-105.7-236.2-236.2c0-130.4,105.7-236.2,236.2-236.2C417.3,31.6,523,137.3,523,267.7z'/>
            <RadialGradient id={idTwo} cx='176.8598' cy='354.2814' r='369.5171' gradientUnits='userSpaceOnUse'>
                <Stop offset='0.2618' stopColor='#F1F4F6' />
                <Stop offset='0.5795' stopColor='#E0E7EB' />
                <Stop offset='0.9968' stopColor='#CED8E0' />
            </RadialGradient>
            <Path fill={`url(#${idTwo})`} d='M515,267.7c0,126-102.1,228.1-228.1,228.1c-126,0-228.1-102.1-228.1-228.1c0-126,102.1-228.1,228.1-228.1C412.9,39.6,515,141.8,515,267.7z'/>
        </G>
        <Path fill='#2DAB66' d='M445.7,266.4c0,84.4-71.1,152.8-158.7,152.8s-158.7-68.4-158.7-152.8c0-69,47.5-127.4,112.8-146.3v55.8c-34.8,16.4-58.7,50.7-58.7,90.5c0,55.6,46.8,100.7,104.6,100.7s104.6-45.1,104.6-100.7c0-39.4-23.5-73.5-57.8-90.1v-56C398.6,139.6,445.7,197.7,445.7,266.4z'/>
        <Path fill='#0071BC' d='M340.8,286.2c0,9.1-2.4,16.7-7.2,22.9c-4.8,6.2-11.6,10.9-20.4,13.9c-8.4,2.9-18,4.5-28.8,4.6c-0.5,0-1,0-1.6,0c-10.6,0-19.9-1.8-28.2-5.4c-8.2-3.6-14.7-8.5-19.4-14.8c-4.7-6.2-7.1-13-7.4-20.1h38.9c0.9,4.2,3.2,7.4,6.7,9.5c3.2,2,6.9,3,10.9,3.1c0.2,0,0.5,0,0.7,0c5.2,0,9.3-0.8,12.3-2.4c3-1.6,4.5-3.8,4.5-6.6c0-3.9-4-6.9-11.9-9.1c-1.9-0.5-3.8-1-5.7-1.6c-6.3-1.7-13.1-3.6-20.4-5.5c-9.4-2.5-17.4-6.5-23.8-11.8c-6.4-5.4-9.6-13.3-9.6-23.7c0-6.3,1.3-11.9,4-16.7c2.6-4.8,6.4-8.8,11.2-12.1c4.9-3.3,10.5-5.7,17-7.3c1.9-0.5,3.8-0.9,5.8-1.2v14.7h36.3v-13.5c2.7,0.7,5.2,1.6,7.5,2.6c7.6,3.4,13.4,7.9,17.4,13.6c4,5.7,6.5,12.2,7.6,19.6h-38.6c-0.6-3.9-2.6-6.7-5.8-8.3c-2.4-1.2-5.2-1.9-8.6-2.1c-0.6,0-1.2-0.1-1.9-0.1c-8.6,0-13,2.3-13,6.9c0,3.8,4,6.8,12,9.2c0.9,0.3,1.9,0.5,2.9,0.8c7.2,2.1,14.9,4.2,23,6.3c9.3,2.5,17.2,6.3,23.7,11.6C337.5,268.6,340.8,276.2,340.8,286.2z'/>
        <Path fill='#FFFFFF' d='M305.8,204.1v13.5h-36.3v-14.7c4.9-0.8,10.1-1.2,15.7-1.2C292.9,201.7,299.7,202.5,305.8,204.1z'/>
        <Path fill='#2DAB66' d='M305.8,99.9v104.2c-6-1.6-12.9-2.4-20.6-2.4c-5.6,0-10.8,0.4-15.7,1.2v-103H305.8z'/>
        <Path fill='#9ACFB5' d='M444.8,266.4c0,84.4-71.1,152.8-158.7,152.8c-0.6,0-1.2,0-1.8,0v-52.1c0.6,0,1.2,0,1.8,0c57.8,0,104.6-45.1,104.6-100.7c0-39.4-23.5-73.5-57.8-90.1v-56C397.7,139.6,444.8,197.7,444.8,266.4z'/>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,

    style: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}