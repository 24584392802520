import { gql } from "@apollo/client";

export const CIRENIO_PARAMETERS = gql`
query GetCirenioParameter {
        getCirenioParameter {
            cpId
          cpName
          cpPublicKey
          cpXApiKey
          cpPrivateKey
          env
          cpState
        }
      }`


export const CIRENIO_HANDLE_EVENT = gql`
    mutation HandleCirenioEvent($input: HandleCirenioInput) {
  handleCirenioEvent(input: $input) {
    ctId
    ctExternalId
    cId
    uId
    ctIpAddress
    ctHash
    ctData
    consentsGranted
    consentsGrantedData
    banksListed
    banksListedData
    bankSelected
    bankSelectedData
    loginRequested
    loginRequestedData
    loginFailed
    loginFailedData
    loginSucceeded
    loginSucceededData
    missingBankForm
    missingBankFormData
    missingBankSent
    missingBankSentData
    flowDone
    flowDoneData
    ctState
  }
}
`