import React from 'react'
import Svg, { Path, G } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
// eslint-disable-next-line react/prop-types
export const LogoLogin = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={106.091}
        height={30.427}
        style={{
            justifyContent: 'inherit',
            alignItems: 'center',
            marginTop: '80px',
            width: 'none'
        }}
        viewBox="0 0 212.183 60.854"
        {...props}
    >
        <G fill="#32faa5" data-name="Grupo 256">
            <Path
                d="M140.673 21.577a9.57 9.57 0 0 1 2.351-.269 8.985 8.985 0 0 1 4.868 1.433v-.424h.006V4.967c0-.5.023-1-.023-1.49a3.693 3.693 0 0 0-3.693-3.463 3.422 3.422 0 0 0-3.5 3.578c-.057 5.843-.023 11.685-.04 17.534 0 .143.017.3.029.453"
                data-name="Trazado 21544"
            />
            <Path
                d="M196.553 22.374c0-6.032.017-12.069-.006-18.1-.012-2.643-1.433-4.26-3.647-4.266-2.253-.006-3.549 1.531-3.555 4.283-.017 5.659 0 11.318-.017 16.977-.006.516.293 1.221-.321 1.514-.493.229-.883-.321-1.279-.6a20.882 20.882 0 0 0-8.423-3.6c-.012-.006-.023-.006-.035-.012a19.546 19.546 0 0 0-17.671 3.9.708.708 0 0 0-.069.057c-4.346 3.873-7.682 9.423-11.515 14.952-5.654 8.155-9.373 13.5-16.725 15.633a17.183 17.183 0 0 1-7.683.511 14.419 14.419 0 0 1-11.331-7.718l-.092-.18c-.038-.076-.077-.15-.114-.226a14.358 14.358 0 0 1-1.407-6.217c0-.495.025-.983.074-1.465a14.417 14.417 0 0 1 21.732-10.914 8.844 8.844 0 0 1 4.541-4.759c-.012-.011-.029-.017-.041-.029a20.971 20.971 0 0 0-15.745-3.6 21.182 21.182 0 0 0-7.182 39.254l.037.025c7.232 4.662 17.051 2.6 17.051 2.6l.009-.007c10.395-2.53 16.5-11.343 22.016-19.646 4.363-6.571 8.532-12.677 13.968-14.736.115-.04.229-.08.35-.12s.218-.075.327-.109c.175-.057.349-.108.524-.156a14.416 14.416 0 1 1 1.161 27.665 8.9 8.9 0 0 1-3.956 5.889 19.7 19.7 0 0 0 6.467 1.422 21.21 21.21 0 0 0 22.516-20.876c.149-5.779.029-11.565.029-17.35Z"
                data-name="Trazado 21545"
            />
            <Path
                d="M32.679 18.229a21.211 21.211 0 1 0 6.061 41.793 19.647 19.647 0 0 0 10.785-6.616 3.461 3.461 0 0 0-.178-5.023c-1.428-1.364-3.2-1.359-4.856.04-.568.482-1.1 1.015-1.651 1.513a13.92 13.92 0 0 1-21.995-4.96c-.6-1.41-.476-1.99 1.239-1.967 4.724.074 9.455.023 14.179.023h12.132c6.032 0 7.006-1.13 6.1-7.1a21.079 21.079 0 0 0-21.817-17.7m12.4 17.637c-3.847.017-7.7.006-11.547.006-3.79 0-7.574-.046-11.364.023-1.52.023-2.03-.292-1.382-1.892a13.945 13.945 0 0 1 25.687-.206c.7 1.7.47 2.059-1.394 2.07"
                data-name="Trazado 21546"
            />
            <Path
                d="M100.361 33.706a21.089 21.089 0 0 0-40.94.866c-.929 4.133-.345 8.307-.536 11.423 0 4.393-.054 7.753.021 11.11a3.489 3.489 0 0 0 3.223 3.5 3.611 3.611 0 0 0 3.777-2.933 9.473 9.473 0 0 0 .146-2.038c.025-5.722-.125-11.451.085-17.166.359-9.734 11.151-16.007 19.864-11.65 5.3 2.649 7.861 7.067 7.965 12.9.089 4.975.023 9.952.027 14.928 0 .684-.018 1.37.019 2.052.13 2.4 1.571 3.945 3.618 3.912a3.556 3.556 0 0 0 3.486-3.842c.031-6.4.205-12.812-.1-19.215a18.379 18.379 0 0 0-.652-3.85"
                data-name="Trazado 21547"
            />
            <Path
                d="M6.907 2.607A3.276 3.276 0 0 0 3.863.02a3.407 3.407 0 0 0-3.5 1.981 5.476 5.476 0 0 0-.352 2.744q-.008 12.686 0 25.372v25.186c0 .684-.015 1.372.053 2.05a3.485 3.485 0 0 0 6.9.434 11.72 11.72 0 0 0 .172-2.409q.013-25.092 0-50.184a10.075 10.075 0 0 0-.229-2.585"
                data-name="Trazado 21548"
            />
            <Path
                d="M206.638 18.265a3.346 3.346 0 0 0-3.284 2.8 9.427 9.427 0 0 0-.125 2.042q-.014 8.117-.005 16.234v13.062c0 1.617-.051 3.239.058 4.849a3.483 3.483 0 0 0 3.362 3.358 3.545 3.545 0 0 0 3.622-3.1 12.247 12.247 0 0 0 .107-2.045q.009-16.047 0-32.094a7.33 7.33 0 0 0-.292-2.766 3.349 3.349 0 0 0-3.448-2.346"
                data-name="Trazado 21549"
            />
            <Path
                d="M211.809 3.378a5.4 5.4 0 0 0-10.4 1.99c0 .132.006.258.017.384a5.3 5.3 0 0 0 2.248 3.968 5.356 5.356 0 0 0 8.5-4.392 5.459 5.459 0 0 0-.373-1.949"
                data-name="Trazado 21550"
            />
            <Path
                d="M137.508 30.327c0 .132.006.258.017.384a5.326 5.326 0 0 0 3.408 4.586 5.339 5.339 0 0 0 7.343-5.01 5.457 5.457 0 0 0-.373-1.95 5.4 5.4 0 0 0-10.317 1.083 5.532 5.532 0 0 0-.078.906"
                data-name="Trazado 21551"
            />
            <Path
                d="M167.954 51.588c0-.1-.006-.206-.011-.3a5.32 5.32 0 0 0-5.292-5.011c-.113 0-.224.006-.336.012a5.294 5.294 0 0 0-5.02 4.231v.006a5.424 5.424 0 0 0-.109 1.107 5.348 5.348 0 0 0 5.315 5.332 4.549 4.549 0 0 0 .843-.057 5.3 5.3 0 0 0 4.6-5c.005-.105.013-.209.012-.316"
                data-name="Trazado 21552"
            />
        </G>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}