import styled from 'styled-components/native'
import { mq } from '../../validation'

export const Container = styled.View`
    flex: 1;
    border-radius: ${mq(17)}px;
`
export const Form = styled.View`
    padding: ${mq(12)}px;
    justify-content: center;
`
export const BoxButton = styled.View`
    margin: ${mq(13)}px 0;
    align-items: center;
`
export const ButtonPlus = styled.TouchableOpacity`
    background-color: ${props => props.theme.BGColor};
    border: 1px solid ${props => props.theme.PColor};
    padding: ${mq(6)}px ${mq(15)}px;
    margin: ${mq(5)}px;
    align-self: flex-end;
    align-items: center;
    border-radius: ${mq(17)}px;
    flex-direction: row;
`
export const TitlePlus = styled.Text`
    color: ${props => props.theme.PColor};
    font-size: ${mq(13)}px;
    font-family: PFontBold;
    padding-left: ${mq(10)}px;
`
export const BoxBorder = styled.View`
    border-bottom-color: ${props => props.theme.BGSColor};
    width: 60%;
    align-self: center;
    border-radius: 1px;
    margin-bottom: ${mq(13)}px;
`