import React from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity } from 'react-native'
import { AntDesign, FontAwesome, Entypo } from '@expo/vector-icons'
import theme from '../../../ignore/Constants'
import { ContainerModal, ButtonOption, Row, Title, BoxButton } from './styled'
import { wp } from '../../validation'
import { Modal } from '../Modal'

export const ModalCamera = ({ navigation, isVisible, data, handleClose }) => {
    // activa la cámara o la galería
    const clickCamera = type => {
        handleClose && handleClose(true)
        navigation.navigate('Camera', { ...data, document: type === 1 })
    }

    return (
        <Modal isVisible={isVisible} animationIn='fadeInUp' animationOut='fadeOutDown'>
            <ContainerModal>
                <Row justify='space-between'>
                    <Title size={wp(4)} family='PFontBold'>Seleccione un Método</Title>
                    <TouchableOpacity onPress={() => handleClose()}>
                        <AntDesign name='close' size={wp(5)} color={theme.onSurfaceC} />
                    </TouchableOpacity>
                </Row>
                <Row paddingTop={wp(2)}>
                    <BoxButton marginRight={wp(5)}>
                        <ButtonOption bgColor={theme.onSurfaceC} onPress={() => clickCamera(1)}>
                            <FontAwesome name='photo' size={wp(5)} color={theme.backgroundC} />
                        </ButtonOption>
                        <Title>Galería</Title>
                    </BoxButton>
                    <BoxButton marginLeft={wp(5)}>
                        <ButtonOption onPress={clickCamera} testID='d60426e2-35fa-4664-9ceb-f8d165000990'>
                            <Entypo name='camera' size={wp(5)} color={theme.backgroundC} />
                        </ButtonOption>
                        <Title>Cámara</Title>
                    </BoxButton>
                </Row>
            </ContainerModal>
        </Modal>
    )
}

ModalCamera.propTypes = {
    navigation: PropTypes.object,
    isVisible: PropTypes.bool,
    data: PropTypes.object,
    handleClose: PropTypes.func
}