// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { AntDesign } from '@expo/vector-icons'
import { useTheme } from 'styled-components/native'

// Components
import { InputHook } from '../InputHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { Relationships } from '../Selects/Relationships'

// Utils and Styles
import { Container, Form, BoxButton, ButtonPlus, TitlePlus, BoxBorder } from './styled'
import { mq } from '../../validation'

/**
 * RelationDataRegisterV Component
 * @param {any} props Properties of RelationDataRegisterV
 * @returns {ReactElement} RelationDataRegisterV
 */
export const RelationDataRegisterV = ({ data: { banner, values, errors, loading }, handleBlur, handleChange, handleSubmit, handlePlusRelation, totalPercentage }) => {
    // States and Variables
    const theme = useTheme()

    return (
        <Container>
            {banner}
            <ButtonPlus onPress={handlePlusRelation}>
                <AntDesign name="pluscircleo" size={mq(20)} color={theme.PColor} />
                <TitlePlus>Más Parientes</TitlePlus>
            </ButtonPlus>
            <Form>
                {!!values?.payrollRelationships?.length && values.payrollRelationships.map((x, i) => (
                    <React.Fragment key={i}>
                        <Relationships testID='edac617c-9db2-4e68-9b9e-623bba9d53e8' value={x?.rId} handleSelect={e => handleChange(e, i)} error={errors?.payrollRelationships?.length === values?.payrollRelationships?.length && errors?.payrollRelationships[i]?.rId} />
                        <InputHook testID='b7b4ed46-771e-495b-8f93-59950b4d4440' title='Nombres y Apellidos *' returnKeyType='next' value={x?.prName} error={errors?.payrollRelationships?.length === values?.payrollRelationships?.length && errors?.payrollRelationships[i]?.prName} name='prName' required letters range={[2, 50]} onBlur={handleBlur} onChangeText={e => handleChange(e, i)} />
                        <InputHook testID={`fe44e812-9b04-4b14-b8a3-6c0e2187c9b7${i}`} title='Porcentaje del beneficiario *' value={x?.prPercentage} error={errors?.payrollRelationships?.length === values?.payrollRelationships?.length && errors?.payrollRelationships[i]?.prPercentage} name='prPercentage' required numeric onBlur={handleBlur} onChangeText={e => handleChange(e, i)} />
                        <BoxBorder style={{ borderBottomWidth: (i+1) < values.payrollRelationships.length ? 1 : 0 }} />
                    </React.Fragment>
                ))}
                <BoxButton>
                    <TitlePlus style={{ color: totalPercentage > 100 ? theme.EColor : theme.PColor }}>Has asignado el {totalPercentage}%</TitlePlus>
                </BoxButton>
                <BoxButton>
                    <ButtonSubmit testID='efaaa039-e4c1-4442-8d33-05ada6527fbb' onPress={handleSubmit} title='Continuar' loading={loading} />
                </BoxButton>
            </Form>
        </Container>
    )
}

RelationDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handlePlusRelation: PropTypes.func,
    handleSubmit: PropTypes.func,
    totalPercentage: PropTypes.number
}