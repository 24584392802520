import styled, { css } from 'styled-components/native'
import Constants from 'expo-constants'
import { hp, wp } from '../../validation'
import theme from '../../../ignore/Constants'

export const Container = styled.View`
    background-color: #000;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    position: ${Constants.platform.web ? 'fixed' : 'relative'};
`
export const BoxAction = styled.View`
    height: ${hp(8)}px;
    width: ${wp(100)}px;
    padding: 0 ${wp(10)}px;
    background-color: #000000CF;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
export const BoxButtonCamera = styled.View`
    flex: 3;
    justify-content: flex-end;
    align-items: center;
`
export const ButtonCamera = styled.TouchableOpacity`
    border-radius: ${wp(100)}px;
    border-color: ${theme.backgroundC};
    border-width: 6px;
    width: 70px;
    height: 70px;
    margin-bottom: ${wp(4)}px;
    align-items: center;
    justify-content: center;
`
export const RectangleHorizontal = styled.View`
    width: ${wp(15)}px;
    height: ${hp(92)}px;
    background-color: #00000089;
    position: absolute;
    bottom: 0;
    ${({ right }) => right && css`right: ${right}px;`}
    ${({ left }) => left && css`left: ${left}px;`}
`
export const RectangleVertical = styled.View`
    width: ${wp(70)}px;
    height: ${({ height }) => height || hp(12)}px;
    background-color: #00000089;
    align-self: center;
    position: absolute;
    ${({ top }) => top && css`top: ${top}px;`}
    ${({ bottom }) => bottom && css`bottom: ${bottom}px;`}
`
export const CornerTopLeft = styled.View`
    top: ${hp(20)}px;
    left: ${wp(15)}px;
    margin: -5px 0 0 -5px;
    border-color: ${theme.backgroundC};
    border-top-width: 5px;
    border-left-width: 5px;
`
export const CornerTopRight = styled.View`
    top: ${hp(20)}px;
    right: ${wp(15)}px;
    margin: -5px -5px 0 0;
    border-color: ${theme.backgroundC};
    border-top-width: 5px;
    border-right-width: 5px;
`
export const CornerBottomLeft = styled.View`
    bottom: ${hp(20)}px;
    left: ${wp(15)}px;
    margin: 0 0 -5px -5px;
    border-color: ${theme.backgroundC};
    border-bottom-width: 5px;
    border-left-width: 5px;
`
export const CornerBottomRight = styled.View`
    bottom: ${hp(20)}px;
    right: ${wp(15)}px;
    margin: 0 -5px -5px 0;
    border-color: ${theme.backgroundC};
    border-bottom-width: 5px;
    border-right-width: 5px;
`
export const styles = {
    corners: {
        height: 20,
        width: 20,
        position: 'absolute',
        zIndex: 100,
    }
}