import moment from 'moment'
import { calculator } from './calculator'

/**
 * cambia el valor de desembolso para calcular el crédito
 * @version 0.0.1
 * @param {object} args argumentos para el calculo del crédito
 * @returns {object} valores del crédito
 */
export const handlePay = ({ params, values, valueSelect }) => {
    /** variables necesarias */
    const { mDefVal, vcDefVal } = values
    const { ctModule, ctType, vat, rate, creditTypeAttributes } = params
    let mon = mDefVal
    let ratMon = 1

    /** Verifica si es por mes o por dia */
    if (ctModule === 1) {
        /** fecha actual y fecha con los dias de anticipo mínimo */
        const dateStart = moment()
        const dateEnd = moment(valueSelect?.item?.date)

        /** calcula la diferencia en dias de pagos para la tasa */
        const days = dateEnd.diff(dateStart, 'd') + 1
        const dayCou = ctType === 1 ? days >= 30 ? 30 : days : days
        ratMon = dayCou / 30

        /** Diferencias de meses para actualizar de cuantos meses de uso */
        mon = 1
    }

    // calcula el valor del crédito
    const { valCre, valCou } = calculator({
        attributes: creditTypeAttributes,
        money: vcDefVal,
        mon,
        iva: vat.vPor,
        ctModule,
        rPor: rate.rPor,
        ratMon
    })

    /** actualiza las variables */
    return {
        valCre: Math.round(valCre),
        valCou: valCou > 0 ? Math.round(valCou) : 0,
        dateEnd: valueSelect?.item?.date,
        datPay: valueSelect?.item?.name
    }
}