import { gql } from '@apollo/client'

export const USE_ONE_PAYROLL_COMPANY = gql`
    query getOnePayrollCompany($pcId: ID, $state: [Int]) {
        getPayrollCompanyById(pcId: $pcId, state: $state) {
            pcId
            tiId
            typeIdentity {
                tiId
                tiName
            }
            pcIdeNum
            payrollStaff {
                psFirNam
                psSecNam
                psFirLas
                psSecLas
                psDatBir
                psPhoMob
                psEmail
            },
            payrollResidence {
                cId
                city {
                    cName
                }
            }
        }
    }
`