import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ size, props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Capa_1"
        x={0}
        y={0}
        width={size}
        height={size}
        style={{
            enableBackground: 'new 0 0 392 392',
        }}
        viewBox="0 0 392 392"
        {...props}
    >
        <Path
            d="M386.7 195c0 103.9-84.2 188.1-188.1 188.1-103.8 0-188-84.2-188-188.1 0-103.8 84.2-188 188-188 103.9 0 188.1 84.2 188.1 188z"
            className="st0"
            fill='#bfd0c9'
        />
        <Path
            d="M346.3 195c0 81.5-66.1 147.7-147.7 147.7S50.9 276.6 50.9 195 117.1 47.4 198.6 47.4c81.6 0 147.7 66.1 147.7 147.6z"
            className="st0"
            fill=' #90aea1'
        />
        <Path
            d="M312.8 195.1c0 63.1-51.1 114.2-114.2 114.2S84.4 258.2 84.4 195.1 135.5 80.9 198.6 80.9s114.2 51.2 114.2 114.2z"
            style={{
                fill: '#fff',
            }}
        />
        <Path
            d="M176.7 182.6c.2 2.4 1.4 4.5 3.3 5.9 1.3.9 2.8 1.4 4.5 1.5 3.5 0 6.4-2 7.5-5 .3-.9.5-1.9.5-2.9s-.2-2-.6-2.9c-1.1-2.9-3.9-5-7.3-5-4.6 0-8.1 3.4-8.1 7.9.2.1.2.3.2.5M223.5 214.5c-.2-3.9-3.2-7-7-7.4h-.8c-4 0-7.2 2.6-7.9 6.3-.1.5-.2 1.1-.2 1.6 0 4.3 3.5 7.8 7.9 7.9.4 0 .8 0 1.2-.1 3.9-.5 6.9-3.8 6.8-7.9v-.4"
            className="st2"
            fill='#014C2D'
        />
        <Path
            d="M262.8 197.6c-.5 17.8-14.9 31-32.6 30.2-3.3-.1-6.4-.9-9.4-2.1 3-1.9 5.1-4.9 5.7-8.5 1.6.4 3.3.6 5 .6 11.5 0 20.9-9.3 20.9-20.9 0-11.5-9.3-20.9-20.9-20.9-2.3 0-4.6.4-6.6 1.1-.3.1-.5.1-.8.2-.2 0-.3.1-.5.2s-.3.1-.5.2c-7.9 3-13.9 11.8-20.2 21.3-8 12-16.8 24.8-31.9 28.4s-14.2 3-24.7-3.8h-.1c-8.6-5.1-14.7-14.2-15.3-24.4-.9-16 10.2-30 25.7-32.4 8.3-1.3 15.9.4 22.8 5.2h.1c-3 1.4-5.3 3.8-6.6 6.9-3.1-1.9-6.8-2.9-10.7-2.9-10.8 0-19.7 8.2-20.8 18.7-.1.7-.1 1.4-.1 2.1 0 3.2.7 6.3 2 9 .1.1.1.2.2.3 0 .1.1.2.1.3 3.2 6.1 9.2 10.4 16.4 11.2 3.2.5 6.9.5 11.1-.7 10.6-3.1 16-10.8 24.2-22.6 5.5-8 10.4-16 16.7-21.6l.1-.1c1.1-1 11-9.5 25.6-5.7 4.3.8 8.3 2.5 12.2 5.2.2.1 13.4 8.9 12.9 25.5"
            className="st2"
            fill='#014C2D'
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}