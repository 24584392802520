import { gql } from '@apollo/client'

export const UPDATE_PAYROLL_COMPANY = gql`
mutation setOrUpdatePayrollCompany($pcId: ID, $input: ISetOrUpdatePayrollCompany!) {
    setOrUpdatePayrollCompany(pcId: $pcId, input: $input) {
        pcId
        payrollOPS {
            poId
            poContractor
            poObject
            poValCon
            poValDatSta
            poValDatEnd
            poState
        }
    }
}
`