import { gql } from '@apollo/client'

export const REGISTER_USER = gql`
    mutation registerUser($input: ICreateUserPayroll!) {
        registerUserPayroll(input: $input) {
            uId
            tokenAuth
        }
    }
`

export const REGISTER_USER_PAYROLL_COMPANY = gql`
    mutation registerUserPayrollCompany($input: ICreateUserPayrollCompany!) {
        registerUserPayrollCompany(input: $input) {
            cId
            uId
            pcId
            tokenAuth
        }
    }
`

export const USER_PARAMETERS = gql`
    query UserParameters {
        userParameters {
            cId,
            nameButton,
            enrollmentReceipt
        }
    }
`

export const ONE_COMPANY = gql`
    query getOneCompanyId($cCode: String) {
        getOneCompanyId(cCode: $cCode) {
            cId
            companyCategories {
                ccId
                ccName
            }
        }
    }
`
export const SEND_EMAIL = gql`
query Query($email: String!, $input: JSON!) {
 getSendEmail(email: $email, input: $input)
}
`;
export const ONE_MUTUAL = gql`
query GetMutualById {
  getMutualById {
    mId
    mCompanies
  }
}
`;
export const GET_ALL_COMPANIES = gql`
query GetAllCompanies {
  getAllCompanies {
    cId
    cBusNam
    cCode
  }
}
`;
export const GET_BY_ID_COMPANIES = gql`
  query ValidatePayrollUser($pcIdeNum: String) {
    validatePayrollUser(pcIdeNum: $pcIdeNum) {
      cId
      tiId
      pcIdeNum
      company {
        cAdmin
        cBusNam
        cCode
      }
      payrollStaff {
        psEmail
        psPhoMob
      }
    }
  }
`;