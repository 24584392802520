import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InboxV } from '../../components/Inbox'
import { CREDIT_REPORTS, USE_ONE_PAYROLL_COMPANY } from './queries'
import { useLazyQuery } from '@apollo/client'

export const InBoxC = ({ navigation }) => {
    const [selectedMessage, setSelectedMessage] = useState();
    const [getOnePayroll, { data: payroll }] = useLazyQuery(USE_ONE_PAYROLL_COMPANY, { fetchPolicy: 'cache-and-network' })
    const [getCreditReports, { data: creditReports }] = useLazyQuery(CREDIT_REPORTS, { fetchPolicy: 'cache-and-network' })

    useEffect(() => {
        getOnePayroll({ variables: { state: [0, 1, 2, 3] } })
    }, [ getOnePayroll])

    // Activa un evento para buscar los mensajes del usuario
    useEffect(() => {
        getCreditReports({ variables: { pcId: payroll?.getPayrollCompanyById?.pcId, order: [['crSeen', 'ASC']] } })
    }, [payroll])

    const handleMessage = item => {
        setSelectedMessage(item?.crId)
        navigation.navigate('InBoxDetails', {
            crId: item?.crId,
            notification: item?.notification?.nMessage,
            comment: item?.crComment,
            crLink: item?.crLink,
            payroll,
            cId: item?.cId,
            credit: item?.credit,
            creditReportDocuments: item?.creditReportDocuments
        })
    }

    return (
        <InboxV
            selectedMessage={selectedMessage}
            handleMessage={handleMessage}
            creditReports={creditReports?.getCreditReportsByPcId}
        />
    )
}

InBoxC.propTypes = {
    navigation: PropTypes.object
}