import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

// eslint-disable-next-line react/prop-types
const LogoLoginPass = ({ size, props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Capa_1"
        x={0}
        y={0}
        width={size} height={size}
        style={{
            enableBackground: 'new 0 0 392 392',
        }}
        viewBox="0 0 392 392"
        {...props}
    >
        <Path
            d="M384 195.9C384 299.8 299.8 384 195.9 384c-103.8 0-188-84.2-188-188.1 0-103.8 84.2-188 188-188 103.9 0 188.1 84.2 188.1 188z"
            className="st0"
            fill=' #c0d3dc'
        />
        <Path
            d="M343.7 195.9c0 81.5-66.1 147.7-147.7 147.7S48.3 277.5 48.3 195.9 114.5 48.3 196 48.3c81.6 0 147.7 66.1 147.7 147.6z"
            className="st0"
            fill='#91b2c2'
        />
        <Path
            d="M310.2 196c0 63.1-51.1 114.2-114.2 114.2S81.8 259.1 81.8 196 132.9 81.8 196 81.8 310.2 133 310.2 196z"
            style={{
                fill: '#fff',
            }}
        />
        <Path
            d="m231.7 235.7 5.5-19.6c.4-1.3-.4-2.3-1.6-2.3H211c-5.9 0-12 4.8-13.6 10.6l-6 21.6c-.2.7.2 1.3.9 1.3h24.6c6.4 0 13-5.2 14.8-11.6"
            style={{
                fill: '#005479',
            }}
        />
        <Path
            d="m155.5 226.5 21.7-77.7c.6-2.3 3-4.1 5.2-4.1h10.3c10.5 0 16.7 8.5 13.7 19.1L183.8 245c-.4 1.3-1.7 2.3-3 2.3h-10.3c-11.5 0-18.2-9.3-15-20.8"
            style={{
                fill: '#1a3c58',
            }}
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    color2: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idOne: PropTypes.any,
    idTwo: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}