import { gql } from '@apollo/client'

export const UPDATE_PAYROLL_COMPANY = gql`
mutation setOrUpdatePayrollCompany($pcId: ID, $input: ISetOrUpdatePayrollCompany!) {
    setOrUpdatePayrollCompany(pcId: $pcId, input: $input) {
        pcId
        payrollResidence {
            prId
            cId
            taId
            prNumDir
            prNum
            prNumHou
            prInformation
            thId
            dId
            alId
            msId
            prState
        }
        payrollWork {
            pwId
            pwPosition
            tcId
            pwDatSta
            pwDatEnd
            pwSalary
            pwNet
            pwEmbargoes
            pwOthInc
            pwFinExp
            pwPerExp
            pwState
        }
    }
}
`
export const PREGNANCY_TIME = gql`
  query PregnancyTimes {
    pregnancyTimes {
      ptName
      ptId
    }
  }
`;