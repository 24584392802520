// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView, Keyboard, TouchableOpacity, ImageBackground } from 'react-native'

// Components
import { InputHook } from '../InputHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { TypeIdentities } from '../Selects/TypeIdentities'

// Styles and Utils
import { mq } from '../../validation'
//import { Logo } from '../../../ignore/Logo'
import { ShadowBox } from '../../generalStyles'
//import Fondo from '../../../assets/img/fondo-login.png'
import theme from '../../../ignore/Constants'
import { Box, BoxLogo, BtnContainers, Form, Title, BtnLogin, DetailsContainer, DetailsInformation, Content, PaymentButton } from './styled'
import image from '../../../assets/img/image'

/**
 * Login Component
 * @param {any} props Login Properties
 * @returns {FC} Login Functional Component
 */
// const Fondo = image('FondoLogin')
const Logo = image('Logo')

export const LoginV = ({ data: { navigation, showFooter, values, loadLog, parseParams, documentType, loadingCreateAdoUser, dataResult, returnApp }, handleChange, handleLogIn, handleBlur }) => (
    <>
        <ImageBackground imageStyle={{ backgroundColor: theme.backgroundColor }} resizeMode='stretch' style={{ width: '100%', height: '100%' }}>
            {
                !parseParams ? <Box>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={{ padding: mq(8), ...(showFooter ? { flex: 1, justifyContent: 'center' } : {}) }}
                        keyboardShouldPersistTaps='handled'
                    >

                        {/** Logo App */}
                        {showFooter && <BoxLogo>
                            <Logo color={theme.primaryC} size='100%' />
                        </BoxLogo>}

                        {/** Button Component */}
                        <BtnContainers style={!showFooter ? { marginTop: mq(50) } : {}}>
                            <BtnLogin active style={ShadowBox}>
                                <Title testID='4e8e6130-9520-11ee-bc24-0bd3d9adbecc' family='PFontBold'>
                                Iniciar Sesión
                                </Title>
                            </BtnLogin>
                            <BtnLogin testID='f2cd66a1-7ed5-411b-a4f1-caa4a1ffec77' onPress={() => navigation?.navigate('Register')}>
                                <Title testID='6e78990d-8e2b-4a0e-8cd4-2bc1a22f6e3b' family='PFontBold' color={theme.onSurfaceV}>Regístrate</Title>
                            </BtnLogin>
                        </BtnContainers>

                        {/** Formulary */}
                        <Form style={[ShadowBox, { marginBottom: mq(40) }]}>
                            <TypeIdentities testID='4e0bd032-4d48-43ea-8760-21e17c8b3975' value={values?.tiId} handleSelect={handleChange} />
                            <InputHook testID='dc9b9c6c-6ce0-44e8-826d-dc3947f5ebc2' onSubmitEditing={Keyboard.dismiss} title='N° de Identificación *' keyboardType='numeric' value={values?.uIdeNum} name='uIdeNum' required format numeric range={[5, 15]} onBlur={handleBlur} onChangeText={handleChange} />
                            <InputHook testID='c7679544-0b38-4210-ba13-fa35c0d47903' onSubmitEditing={Keyboard.dismiss} title='Contraseña *' secureTextEntry value={values?.uPass} name='uPass' required onBlur={handleBlur} onChangeText={handleChange} />
                            <ButtonSubmit testID='19e19576-23fa-4695-a1e4-1a4be6f6d213' margin='15px auto' onPress={handleLogIn} title='Acceder' loading={loadLog} />
                            <TouchableOpacity onPress={() => navigation?.navigate('LostPass')} style={{ alignItems: 'center' }}>
                                <Title  family='PFontMedium' margin={`${mq(16)}px 0`} color={theme.onSurfaceV} size={mq(14)}>
                                ¿Olvidaste tu contraseña?
                                    <Title testID='e81493d7-5a31-4e9d-a9f0-ac2fea6d6c55'> Recuperar</Title>
                                </Title>
                            </TouchableOpacity>
                        </Form>
                    </ScrollView>
                </Box> :
                    <Box>
                        <DetailsContainer>
                            <div style={{ borderBottom: '1px solid #e4e4e4', padding: '3px', display: 'flex', justifyContent: 'center' }}>
                                <Title family='PFontBold' margin={`${mq(10)}px 0`}>Validación de identidad</Title>
                            </div>
                            {
                                loadingCreateAdoUser ? (<Title >Cargando...</Title>) : (
                                    <>
                                        <DetailsInformation>
                                            <Content>
                                                <Title family='PFontBold' margin={`${mq(12)}px 0`}>Tipo de documento</Title>
                                                <Title family='PFontMedium' margin={`${mq(10)}px 0`}>{documentType}</Title>
                                            </Content>
                                            <Content>
                                                <Title family='PFontBold' margin={`${mq(12)}px 0`}>Número de identificación</Title>
                                                <Title family='PFontMedium' margin={`${mq(10)}px 0`}>{dataResult?.documentNumber}</Title>
                                            </Content>
                                            <Content>
                                                <Title family='PFontBold' margin={`${mq(12)}px 0`}>Id de transacción</Title>
                                                <Title family='PFontMedium' margin={`${mq(10)}px 0`}>{dataResult?.transactionId}</Title>
                                            </Content>
                                        </DetailsInformation>
                                        <DetailsInformation>
                                            <Content>
                                                <Title family='PFontBold' margin={`${mq(12)}px 0`}>Tipo de transacción</Title>
                                                <Title family='PFontMedium' margin={`${mq(10)}px 0`}>{dataResult?.transactionTypeName}</Title>
                                            </Content>
                                            <Content>
                                                <Title family='PFontBold' margin={`${mq(12)}px 0`}>Nombres</Title>
                                                <Title family='PFontMedium' margin={`${mq(10)}px 0`}>{dataResult?.names}</Title>
                                            </Content>
                                            <Content>
                                                <Title family='PFontBold' margin={`${mq(12)}px 0`}>Apellidos</Title>
                                                <Title family='PFontMedium' margin={`${mq(10)}px 0`}>{dataResult?.surnames}</Title>
                                            </Content>
                                        </DetailsInformation>
                                        <DetailsInformation>
                                            <Content>
                                                <Title family='PFontBold' margin={`${mq(12)}px 0`}>Estado de transacción</Title>
                                                <Title family='PFontMedium' margin={`${mq(10)}px 0`}>{dataResult?.stateName}</Title>
                                            </Content>
                                        </DetailsInformation>
                                    </>
                                )
                            }
                        </DetailsContainer>
                        <PaymentButton
                            style={{ marginVertical: mq(15) }}
                            onPress={() => returnApp()}
                        >
                            <Title size={mq(13)} style={{ padding: 0, color: '#ffff' }}>Volver a la aplicación</Title>
                        </PaymentButton>
                    </Box>
            }
        </ImageBackground>
    </>
)

LoginV.propTypes = {
    data: PropTypes.object,
    handleChange: PropTypes.func,
    handleLogIn: PropTypes.func,
    handleBlur: PropTypes.func
}