import { gql } from '@apollo/client'

export const LOGIN_USER = gql`
    mutation login($input: IUserPayrollLogin!) {
        userPayrollLogin(input: $input) {
            uId
            tokenAuth
            uState
        }
    }
`

export const CREATE_ADO_USER_ENROLLMENT = gql`
  mutation CreateAdoUserEnrollment($input: IAdoUserEnrollments!) {
    createAdoUserEnrollment(input: $input) {
      adoStateId
      date
      payrollCompany {
        pcId
        pcIdeNum
      }
      idAUE
      pcId
      state
      transactionId
      uId
      idAP
    }
  }
`;

export const GET_BY_ID_USER_ENROLLMENT = gql`
  query AdoUserEnrollmentById($idAue: ID) {
    adoUserEnrollmentById(idAUE: $idAue) {
      adoStateId
      date
      idAP
      idAUE
      payrollCompany {
        pcId
        pcIdeNum
        cId
      }
      state
      pcId
      transactionId
      uId
    }
  }
`;

export const ADO_PARAMETERS = gql`
  query AdoParameters($state: [Int], $onRegister: [Int]) {
    adoParameters(state: $state, onRegister: $onRegister) {
      idAP
      projectName
      apiKey
      secretKey
      urlTest
      urlProd
      isProd
      brand
      onRegister
      state
    }
  }
`;

export const UPDATE_PAYROLL_DOCUMENTS = gql`
  mutation UpdatePayrollDocuments($pcId: ID, $input: ISetOrUpdatePayrollDocument!) {
    updatePayrollDocuments(pcId: $pcId, input: $input) {
      pcId
      pdId
      pdFroDoc
      pdReaDoc
      pdPerDoc
    }
  }
`;