import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { ClipPath, Defs, G, LinearGradient, Path, Polygon, Stop, Use } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ width, height, idsvOne, idsvTwo, idsvThree, idsvFour, idsvFive, idsvSix, idsvSeven, idsvEight, idsvNine, idsvTen, idsvElev, idsvTwelve }) => <Svg style={{ paddingTop: '80px' }} x='0px' y='0px' width={width} height={height} viewBox="0 0 1920 1080">
    <G>
        <G>
            <Defs>
                <Path id={idsvOne} d="M942.76,236.11c167.7,0,304.13,136.43,304.13,304.13c0,83.85-34.11,159.88-89.18,214.95l94.79,94.79
				c79.35-79.36,128.5-188.91,128.5-309.74C1381,298.59,1184.41,102,942.76,102V236.11z"/>
            </Defs>
            <ClipPath id={idsvTwo}>
                <Use xlinkHref={`#${idsvOne}`} overflow='hidden'/>
            </ClipPath>

            <LinearGradient id={idsvThree} gradientUnits="userSpaceOnUse" x1="604.8536" y1="811.6988" x2="608.0642" y2="811.6988" gradientTransform="matrix(-96.0066 -256.0182 -256.0182 96.0066 267177.75 77764.4375)">
                <Stop offset="0" stopColor="#F8AF3C"/>
                <Stop offset="1" stopColor="#F29222"/>
            </LinearGradient>
            <Polygon clipPath={`url(#${idsvTwo})`} fill={`url(#${idsvThree})`} points="996.79,994.06 696.84,194.22 1326.97,-42.08 1626.91,757.77 		"/>
        </G>
        <G>
            <Defs>
                <Path id={idsvFour} d="M504.51,540.24c0,241.65,196.59,438.24,438.24,438.24c120.83,0,230.39-49.15,309.74-128.5l-94.79-94.79
				c-55.07,55.07-131.1,89.18-214.95,89.18c-167.69,0-304.13-136.44-304.13-304.13l-69.14-0.9L504.51,540.24z"/>
            </Defs>
            <ClipPath id={idsvFive}>
                <Use xlinkHref={`#${idsvFour}`} overflow='hidden'/>
            </ClipPath>

            <LinearGradient id={idsvSix} gradientUnits="userSpaceOnUse" x1="604.8649" y1="811.1995" x2="608.0755" y2="811.1995" gradientTransform="matrix(237.7319 103.6269 103.6269 -237.7319 -227371.6406 130755.5938)">
                <Stop offset="0" stopColor="#38BEEF"/>
                <Stop offset="0.1738" stopColor="#38B9EB"/>
                <Stop offset="0.3928" stopColor="#38A9DF"/>
                <Stop offset="0.636" stopColor="#398FCD"/>
                <Stop offset="0.8951" stopColor="#3A6CB2"/>
                <Stop offset="1" stopColor="#3A5BA6"/>
            </LinearGradient>
            <Polygon clipPath={`url(#${idsvFive})`} fill={`url(#${idsvSix})`} points="623.94,265.36 1413.36,609.46 1133.07,1252.47 343.66,908.37 		"/>
        </G>
        <G>
            <Defs>
                <Path id={idsvSeven} d="M806.86,387.11l71.6,71.6c15.65-15.65,37.25-25.34,61.07-25.34c47.64,0,86.41,38.76,86.41,86.41
				l49.45-0.98l51.85,0.98c0-103.51-84.21-187.72-187.72-187.72C887.79,332.07,840.86,353.12,806.86,387.11"/>
            </Defs>
            <ClipPath id={idsvEight}>
                <Use xlinkHref={`#${idsvSeven}`} overflow='hidden'/>
            </ClipPath>

            <LinearGradient id={idsvNine} gradientUnits="userSpaceOnUse" x1="605.0437" y1="812.3253" x2="608.2543" y2="812.3253" gradientTransform="matrix(97.5718 -16.262 -16.262 -97.5718 -44960.4023 89542.25)">
                <Stop offset="0" stopColor="#3A5BA6"/>
                <Stop offset="0.1049" stopColor="#3A6CB2"/>
                <Stop offset="0.364" stopColor="#398FCD"/>
                <Stop offset="0.6072" stopColor="#38A9DF"/>
                <Stop offset="0.8262" stopColor="#38B9EB"/>
                <Stop offset="1" stopColor="#38BEEF"/>
            </LinearGradient>
            <Polygon clipPath={`url(#${idsvEight})`} fill={`url(#${idsvNine})`} points="776.42,337.14 1118.6,280.11 1157.7,514.71 815.52,571.74 		"/>
        </G>
        <G>
            <Defs>
                <Path id={idsvTen} d="M751.82,519.79c0,103.51,84.21,187.72,187.72,187.72V606.19c-47.64,0-86.41-38.76-86.41-86.41
				c0-23.83,9.69-45.42,25.33-61.07l-71.6-71.6C772.88,421.1,751.82,468.03,751.82,519.79"/>
            </Defs>
            <ClipPath id={idsvElev}>
                <Use xlinkHref={`#${idsvTen}`} overflow='hidden'/>
            </ClipPath>

            <LinearGradient id={idsvTwelve} gradientUnits="userSpaceOnUse" x1="604.6487" y1="810.7748" x2="607.8593" y2="810.7748" gradientTransform="matrix(67.8936 67.8936 67.8936 -67.8936 -95347.5781 14446.9609)">
                <Stop offset="0" stopColor="#3A5BA6"/>
                <Stop offset="0.1049" stopColor="#3A6CB2"/>
                <Stop offset="0.364" stopColor="#398FCD"/>
                <Stop offset="0.6072" stopColor="#38A9DF"/>
                <Stop offset="0.8262" stopColor="#38B9EB"/>
                <Stop offset="1" stopColor="#38BEEF"/>
            </LinearGradient>
            <Polygon clipPath={`url(#${idsvElev})`} fill={`url(#${idsvTwelve})`} points="845.68,293.25 1099.74,547.31 845.68,801.36 591.62,547.31 		"/>
        </G>
    </G>
</Svg>

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    idsvOne: PropTypes.any,
    idsvTwo: PropTypes.any,
    idsvThree: PropTypes.any,
    idsvFour: PropTypes.any,
    idsvFive: PropTypes.any,
    idsvSix: PropTypes.any,
    idsvSeven: PropTypes.any,
    idsvEight: PropTypes.any,
    idsvNine: PropTypes.any,
    idsvTen: PropTypes.any,
    idsvElev: PropTypes.any,
    idsvTwelve: PropTypes.any,
    opacity: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}