import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { USE_ALL_GENDERS } from '../../queries/Genders'
import { SelectHook } from '../SelectHook'

export const Genders = ({ testID, value, handleSelect, handleCancel, use = {}, disabled, error }) => {
    const [res, setRes] = useState([])
    const { data, loading } = USE_ALL_GENDERS({ order: [['gName', 'ASC']], ...use })

    useEffect(() => {
        if (data?.genders && !loading) setRes(data?.genders || [])
    }, [data])

    return (
        <SelectHook
            data={res}
            error={error}
            loading={loading}
            title='Género *'
            value={value}
            name='gId'
            nameAtt='gName'
            titleModal='Seleccionar Género'
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
            testID={testID}
        />
    )
}

Genders.propTypes = {
    value: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool
}