import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { FontAwesome, MaterialCommunityIcons, Entypo, Octicons } from '@expo/vector-icons'
import { DocumentDataRegisterV } from '../../components/DocumentDataRegister'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { getFileS3, hp, putFileS3, writeCache, validationErrors } from '../../validation'
import theme from '../../../ignore/Constants'
import { isLoggedVar } from '../../apollo/cache'
import { UPDATE_PAYROLL_COMPANY } from './queries'

export const DocumentDataRegisterC = ({ documents, dataUser, banner, handleNext, format, handleButton, refScroll, handleStepCodebtor, coDebtor, navigation, ctCirenio }) => {
    const [values, setValues] = useState({})
    const [files, setFiles] = useState({})
    const [filePD, setFilePD] = useState('')
    const [fileFD, setFileFD] = useState('')
    const [fileRD, setFileRD] = useState('')
    const [fileSD, setFileSD] = useState('')
    const [filePRD, setFilePRD] = useState('')
    const [fileBD, setFileBD] = useState('')
    const [fileCD, setFileCD] = useState('')
    const [fileCAD, setFileCAD] = useState('')
    const [fileBRD, setFileBRD] = useState('')
    const [fileBCD, setFileBCD] = useState('')
    const [fileRut, setFileRut] = useState('')
    const [fileCC, setFileCC] = useState('')
    const [fileIP, setFileIP] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadPD, setLoadPD] = useState(true)
    const [loadFD, setLoadFD] = useState(true)
    const [loadRD, setLoadRD] = useState(true)
    const [loadSD, setLoadSD] = useState(true)
    const [loadPRD, setLoadPRD] = useState(true)
    const [loadBD, setLoadBD] = useState(true)
    const [loadCD, setLoadCD] = useState(true)
    const [loadCAD, setLoadCAD] = useState(true)
    const [loadBRD, setLoadBRD] = useState(true)
    const [loadBCD, setLoadBCD] = useState(true)
    const [loadRut, setLoadRut] = useState(true)
    const [loadCC, setLoadCC] = useState(true)
    const [loadIP, setLoadIP] = useState(true)
    const [errorForm, setErrorForm] = useState({})
    const [infoCamera, setInfoCamera] = useState({})
    const [payrollCompanyMutation, { loadUpd }] = useMutation(UPDATE_PAYROLL_COMPANY)

    // Actualiza la información de un usuario
    useEffect(() => {
        const searchImage = () => {
            /** actualiza todas las variables */
            setValues({
                payrollDocument: {
                    pdId: dataUser?.payrollDocument?.pdId,
                    pdPerDoc: values.payrollDocument?.pdPerDoc || dataUser?.payrollDocument?.pdPerDoc,
                    pdFroDoc: values.payrollDocument?.pdFroDoc || dataUser?.payrollDocument?.pdFroDoc,
                    pdReaDoc: values.payrollDocument?.pdReaDoc || dataUser?.payrollDocument?.pdReaDoc,
                    pdSerDoc: values.payrollDocument?.pdSerDoc || dataUser?.payrollDocument?.pdSerDoc,
                    pdPayRemDoc: values.payrollDocument?.pdPayRemDoc || dataUser?.payrollDocument?.pdPayRemDoc,
                    pdBanDoc: values.payrollDocument?.pdBanDoc || dataUser?.payrollDocument?.pdBanDoc,
                    pdConDoc: values.payrollDocument?.pdConDoc || dataUser?.payrollDocument?.pdConDoc,
                    pdComActDoc: values.payrollDocument?.pdComActDoc || dataUser?.payrollDocument?.pdComActDoc,
                    pdBudRecDoc: values.payrollDocument?.pdBudRecDoc || dataUser?.payrollDocument?.pdBudRecDoc,
                    pdBudCerDoc: values.payrollDocument?.pdBudCerDoc || dataUser?.payrollDocument?.pdBudCerDoc,
                    pdRut: values.payrollDocument?.pdRut || dataUser?.payrollDocument?.pdRut,
                    pdComChamber: values.payrollDocument?.pdComChamber || dataUser?.payrollDocument?.pdComChamber,
                    pdInstitutionPic: values.payrollDocument?.pdInstitutionPic || dataUser?.payrollDocument?.pdInstitutionPic,
                    pdWorkCertificate: values.payrollDocument?.pdWorkCertificate || dataUser?.payrollDocument?.pdWorkCertificate,
                    pdBankStatement: values.payrollDocument?.pdBankStatement || dataUser?.payrollDocument?.pdBankStatement,
                    pdFinancialStatements: values.payrollDocument?.pdFinancialStatements || dataUser?.payrollDocument?.pdFinancialStatements,
                    pdIncomeTaxReturn: values.payrollDocument?.pdIncomeTaxReturn || dataUser?.payrollDocument?.pdIncomeTaxReturn,
                    pdBoardDirectors: values.payrollDocument?.pdBoardDirectors || dataUser?.payrollDocument?.pdBoardDirectors,
                    pdTradeBalance: values.payrollDocument?.pdTradeBalance || dataUser?.payrollDocument?.pdTradeBalance,
                    pdRealEstateRegistration: values.payrollDocument?.pdRealEstateRegistration || dataUser?.payrollDocument?.pdRealEstateRegistration,
                    pdVehicleOwnershipCard: values.payrollDocument?.pdVehicleOwnershipCard || dataUser?.payrollDocument?.pdVehicleOwnershipCard,
                    pdCommercialReference: values.payrollDocument?.pdCommercialReference || dataUser?.payrollDocument?.pdCommercialReference,
                    pdBankReference: values.payrollDocument?.pdBankReference || dataUser?.payrollDocument?.pdBankReference,
                    pdIncomeWithholdings: values.payrollDocument?.pdIncomeWithholdings || dataUser?.payrollDocument?.pdIncomeWithholdings,
                    pdCadastreAndValuation: values.payrollDocument?.pdCadastreAndValuation || dataUser?.payrollDocument?.pdCadastreAndValuation,
                    pdCommercialAppraisal: values.payrollDocument?.pdCommercialAppraisal || dataUser?.payrollDocument?.pdCommercialAppraisal
                }
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdPerDoc}`).then(x => {
                setFilePD(x)
                setLoadPD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdFroDoc}`).then(x => {
                setFileFD(x)
                setLoadFD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdReaDoc}`).then(x => {
                setFileRD(x)
                setLoadRD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdSerDoc}`).then(x => {
                setFileSD(x)
                setLoadSD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdPayRemDoc}`).then(x => {
                setFilePRD(x)
                setLoadPRD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdBanDoc}`).then(x => {
                setFileBD(x)
                setLoadBD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdConDoc}`).then(x => {
                setFileCD(x)
                setLoadCD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdComActDoc}`).then(x => {
                setFileCAD(x)
                setLoadCAD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdBudRecDoc}`).then(x => {
                setFileBRD(x)
                setLoadBRD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdBudCerDoc}`).then(x => {
                setFileBCD(x)
                setLoadBCD(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdRut}`).then(x => {
                setFileRut(x)
                setLoadRut(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdComChamber}`).then(x => {
                setFileCC(x)
                setLoadCC(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdInstitutionPic}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdWorkCertificate}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdBankStatement}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdFinancialStatements}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdIncomeTaxReturn}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdBoardDirectors}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdTradeBalance}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdRealEstateRegistration}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdVehicleOwnershipCard}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdCommercialReference}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdBankReference}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdIncomeWithholdings}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdCadastreAndValuation}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            getFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${dataUser?.payrollDocument?.pdCommercialAppraisal}`).then(x => {
                setFileIP(x)
                setLoadIP(false)
            })
            setErrorForm({
                pdPerDoc: (format?.ufPerDoc && !dataUser?.payrollDocument?.pdPerDoc),
                pdFroDoc: (format?.ufFroDoc && !dataUser?.payrollDocument?.pdFroDoc),
                pdReaDoc: (format?.ufReaDoc && !dataUser?.payrollDocument?.pdReaDoc),
                pdSerDoc: (format?.ufSerDoc && !dataUser?.payrollDocument?.pdSerDoc),
                pdPayRemDoc: (format?.ufPayRemDoc && !dataUser?.payrollDocument?.pdPayRemDoc),
                pdBanDoc: (format?.ufBanDoc && !dataUser?.payrollDocument?.pdBanDoc),
                pdConDoc: (format?.ufConDoc && !dataUser?.payrollDocument?.pdConDoc),
                pdComActDoc: (format?.ufComActDoc && !dataUser?.payrollDocument?.pdComActDoc),
                pdBudRecDoc: (format?.ufBudRecDoc && !dataUser?.payrollDocument?.pdBudRecDoc),
                pdBudCerDoc: (format?.ufBudCerDoc && !dataUser?.payrollDocument?.pdBudCerDoc),
                pdRut: (format?.ufRut && !dataUser?.payrollDocument?.pdRut),
                pdComChamber: (format?.ufComChamber && !dataUser?.payrollDocument?.pdComChamber),
                pdInstitutionPic: (format?.ufInstitutionPic && !dataUser?.payrollDocument?.pdInstitutionPic),
                pdWorkCertificate: (format?.ufWorkCertificate && !dataUser?.payrollDocument?.pdWorkCertificate),
                pdBankStatement: (format?.ufBankStatement && !dataUser?.payrollDocument?.pdBankStatement),
                pdFinancialStatements: (format?.ufFinancialStatements && !dataUser?.payrollDocument?.pdFinancialStatements),
                pdIncomeTaxReturn: (format?.ufIncomeTaxReturn && !dataUser?.payrollDocument?.pdIncomeTaxReturn),
                pdBoardDirectors: (format?.ufBoardDirectors && !dataUser?.payrollDocument?.pdBoardDirectors),
                pdTradeBalance: (format?.ufTradeBalance && !dataUser?.payrollDocument?.pdTradeBalance),
                pdRealEstateRegistration: (format?.ufRealEstateRegistration && !dataUser?.payrollDocument?.pdRealEstateRegistration),
                pdVehicleOwnershipCard: (format?.ufVehicleOwnershipCard && !dataUser?.payrollDocument?.pdVehicleOwnershipCard),
                pdCommercialReference: (format?.ufCommercialReference && !dataUser?.payrollDocument?.pdCommercialReference),
                pdBankReference: (format?.ufBankReference && !dataUser?.payrollDocument?.pdBankReference),
                pdIncomeWithholdings: (format?.ufIncomeWithholdings && !dataUser?.payrollDocument?.pdIncomeWithholdings),
                pdCadastreAndValuation: (format?.ufCadastreAndValuation && !dataUser?.payrollDocument?.pdCadastreAndValuation),
                pdCommercialAppraisal: (format?.ufCommercialAppraisal && !dataUser?.payrollDocument?.pdCommercialAppraisal)
            })
        }
        searchImage()
    }, [dataUser])

    // Actualiza las fotos tomadas
    useEffect(() => {
        if (documents?.name && !loadPD && !loadFD && !loadRD && !loadSD && !loadPRD) {
            setValues({ payrollDocument: { ...values?.payrollDocument, [documents.name]: `${new Date().getTime()}1.${documents.ext}` } })
            setFiles({ ...files, [documents.name]: documents.file, [documents.nameA]: documents.base64 })
            setErrorForm({ ...errorForm, [documents.name]: !documents.file })
        }
    }, [documents, loadPRD, loadSD, loadRD, loadFD, loadPD])

    // coloca la información de la cámara
    const handleInfo = type => {
        let stepsInf = []
        if (type === 1) stepsInf = [{ text: '2. Enfoque la parte frontal de su documento de identidad .' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 2) stepsInf = [{ text: '2. Enfoque la parte reversa de su documento de identidad.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 3) stepsInf = [{ text: '2. Enfoque su rostro sosteniendo el documento de identidad a la misma altura de la cabeza.' }, { text: '3. No utilizar accesorios.' }]
        else if (type === 4) stepsInf = [{ text: '2. Tome la foto de un servicio público (Agua, luz o gas).' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 5) stepsInf = [{ text: '2. Tome la foto del desprendible de nómina.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 6) stepsInf = [{ text: '2. Tome la foto del certificado bancario.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 7) stepsInf = [{ text: '2. Tome la foto del contrato laboral.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 8) stepsInf = [{ text: '2. Tome la foto del acta de inicio.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 9) stepsInf = [{ text: '2. Tome la foto del registro de presupuesto.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 10) stepsInf = [{ text: '2. Tome la foto del certificado de presupuesto.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 11) stepsInf = [{ text: '2. Tome la foto del RUT.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 12) stepsInf = [{ text: '2. Tome la foto del Cámara de Comercio.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 13) stepsInf = [{ text: '2. Tome la foto del Foto del Establecimiento.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 14) stepsInf = [{ text: '2. Anexe foto o archivo de flujo de caja - Extracto bancario.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 15) stepsInf = [{ text: '2. Anexe foto o archivo de estados financieros.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 16) stepsInf = [{ text: '2. Anexe foto o archivo de declaración de renta.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 17) stepsInf = [{ text: '2. Anexe foto o archivo de acta junta directiva.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 18) stepsInf = [{ text: '2. Anexe foto o archivo de balance comercial.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 19) stepsInf = [{ text: '2. Anexe foto o archivo de matricula inmobiliaria.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 20) stepsInf = [{ text: '2. Anexe foto o archivo de tarjeta propiedad vehículo.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 21) stepsInf = [{ text: '2. Anexe foto o archivo de referencia comercial.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 22) stepsInf = [{ text: '2. Anexe foto o archivo de referencia bancaria.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 23) stepsInf = [{ text: '2. Anexe foto o archivo de certificado de ingresos y retenciones.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 24) stepsInf = [{ text: '2. Anexe foto o archivo de paz salvo catastro y valorización.' }, { text: '3. La imagen debe ser visible y legible.' }]
        else if (type === 25) stepsInf = [{ text: '2. Anexe foto o archivo de avaluó comercial.' }, { text: '3. La imagen debe ser visible y legible.' }]

        setInfoCamera({
            title: 'Documentos personales',
            icon: type === 1 ? <FontAwesome name='vcard' size={hp(20)} color={theme.backgroundC} /> : type === 2 ? <MaterialCommunityIcons name='card-bulleted' size={hp(26)} color={theme.backgroundC} /> : type === 3 ? <Entypo name='v-card' size={hp(24)} color={theme.backgroundC} /> : <Octicons name='file' size={hp(20)} color={theme.backgroundC} />,
            steps: [
                { text: 'Sigues las instrucciones para capturar la foto.' },
                { text: '1. Capture dentro de los marcos señalados.' },
                ...stepsInf
            ]
        })
    }

    const handleSubmit = async () => {
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })
        setLoading(true)
        files.pdPerDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdPerDoc}`, files.pdPerDoc)
        files.pdFroDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdFroDoc}`, files.pdFroDoc)
        files.pdReaDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdReaDoc}`, files.pdReaDoc)
        files.pdSerDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdSerDoc}`, files.pdSerDoc)
        files.pdPayRemDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdPayRemDoc}`, files.pdPayRemDoc)
        files.pdBanDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBanDoc}`, files.pdBanDoc)
        files.pdConDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdConDoc}`, files.pdConDoc)
        files.pdComActDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdComActDoc}`, files.pdComActDoc)
        files.pdBudRecDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBudRecDoc}`, files.pdBudRecDoc)
        files.pdBudCerDoc && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBudCerDoc}`, files.pdBudCerDoc)
        files.pdRut && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdRut}`, files.pdRut)
        files.pdComChamber && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdComChamber}`, files.pdComChamber)
        files.pdInstitutionPic && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdInstitutionPic}`, files.pdInstitutionPic)
        files.pdWorkCertificate && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdWorkCertificate}`, files.pdWorkCertificate)
        files.pdBankStatement && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBankStatement}`, files.pdBankStatement)
        files.pdFinancialStatements && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdFinancialStatements}`, files.pdFinancialStatements)
        files.pdIncomeTaxReturn && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdIncomeTaxReturn}`, files.pdIncomeTaxReturn)
        files.pdBoardDirectors && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBoardDirectors}`, files.pdBoardDirectors)
        files.pdTradeBalance && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdTradeBalance}`, files.pdTradeBalance)
        files.pdRealEstateRegistration && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdRealEstateRegistration}`, files.pdRealEstateRegistration)
        files.pdVehicleOwnershipCard && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdVehicleOwnershipCard}`, files.pdVehicleOwnershipCard)
        files.pdCommercialReference && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdCommercialReference}`, files.pdCommercialReference)
        files.pdBankReference && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBankReference}`, files.pdBankReference)
        files.pdIncomeWithholdings && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdIncomeWithholdings}`, files.pdIncomeWithholdings)
        files.pdCadastreAndValuation && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdCadastreAndValuation}`, files.pdCadastreAndValuation)
        files.pdCommercialAppraisal && await putFileS3(`companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdCommercialAppraisal}`, files.pdCommercialAppraisal)

        const { data, error } = await payrollCompanyMutation({
            variables: { input: values }, update(store, { data: { setOrUpdatePayrollCompany } }) {
                writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: setOrUpdatePayrollCompany, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdatePayrollCompany) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            if (ctCirenio !== 1 && coDebtor?.quantity === 0) {
                navigation.navigate('RequestCredit', { hash: null })
            } else {
                handleNext(handleStepCodebtor() || 8)
            }
        }
    }
    return (
        <DocumentDataRegisterV
            data={{ files, values, loading: (loading || loadUpd), loadPD, loadFD, loadRD, loadSD, loadPRD, loadBD, loadCD, loadBCD, loadBRD, loadCAD, loadRut, loadCC, loadIP, infoCamera, format, banner, filePD, fileFD, fileRD, fileSD, filePRD, fileBD, fileCD, fileBCD, fileBRD, fileCAD, fileRut, fileCC, fileIP }}
            handleSubmit={handleSubmit}
            handleInfo={handleInfo}
            handleButton={handleButton}
        />
    )
}

DocumentDataRegisterC.propTypes = {
    documents: PropTypes.object,
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    handleStepCodebtor: PropTypes.number,
    format: PropTypes.object,
    ctCirenio: PropTypes.object,
    handleButton: PropTypes.func,
    refScroll: PropTypes.object,
    coDebtor: PropTypes.object,
    navigation: PropTypes.object
}