import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
    FontAwesome,
    MaterialCommunityIcons,
    Entypo,
    Octicons,
    FontAwesome5,
} from '@expo/vector-icons';
import { CoDebtorDataRegisterV } from '../../components/CreditEditData/CoDebtorDataRegister';
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries';
import {
    filterKeyObject,
    getFileS3,
    hp,
    putFileS3,
    updateCache,
    validationErrors,
} from '../../validation';
import theme from '../../../ignore/Constants';
import { isLoggedVar } from '../../apollo/cache';
import { EDIT_CREDIT_PAYROLL_CODEBTORS } from './queries';

export const CoDebtorDataRegisterC = ({
    documents,
    dataUser,
    banner,
    handleNext,
    coDebtor,
    handleButton,
    refScroll,
}) => {
    const [values, setValues] = useState({});
    const [files, setFiles] = useState({});
    const [filePD, setFilePD] = useState('');
    const [fileFD, setFileFD] = useState('');
    const [fileRD, setFileRD] = useState('');
    const [fileSD, setFileSD] = useState('');
    const [fileBD, setFileBD] = useState('');
    const [fileLD, setFileLD] = useState('');
    const [loadPD, setLoadPD] = useState(true);
    const [loadFD, setLoadFD] = useState(true);
    const [loadRD, setLoadRD] = useState(true);
    const [loadSD, setLoadSD] = useState(true);
    const [loadBD, setLoadBD] = useState(true);
    const [loadLD, setLoadLD] = useState(true);
    const [quantity, setQuantity] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorForm, setErrorForm] = useState({});
    const [infoCamera, setInfoCamera] = useState({});
    const [crediPayrollCodebtorsMutation, { loadUpd }] = useMutation(
        EDIT_CREDIT_PAYROLL_CODEBTORS
    );

    const searchImage = async count => {
        /** actualiza todas las variables */
        setValues({
            creditPayrollCoDebtors: filterKeyObject(
                dataUser?.creditPayrollCoDebtors[count],
                ['__typename']
            ),
        });
        getFileS3(
            `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.creditPayrollCoDebtors[count]?.cpcdPerDoc}`
        ).then(x => {
            setFilePD(x);
            setLoadPD(false);
        });
        getFileS3(
            `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.creditPayrollCoDebtors[count]?.cpcdFroDoc}`
        ).then(x => {
            setFileFD(x);
            setLoadFD(false);
        });
        getFileS3(
            `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.creditPayrollCoDebtors[count]?.cpcdReaDoc}`
        ).then(x => {
            setFileRD(x);
            setLoadRD(false);
        });
        getFileS3(
            `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.creditPayrollCoDebtors[count]?.cpcdSerDoc}`
        ).then(x => {
            setFileSD(x);
            setLoadSD(false);
        });
        getFileS3(
            `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.creditPayrollCoDebtors[count]?.cpcdBanDoc}`
        ).then(x => {
            setFileBD(x);
            setLoadBD(false);
        });
        getFileS3(
            `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${dataUser?.creditPayrollCoDebtors[count]?.cpcdLoaDoc}`
        ).then(x => {
            setFileLD(x);
            setLoadLD(false);
        });
        setErrorForm({
            tiId:
                coDebtor?.format?.cpfcdTipIde &&
                !dataUser?.creditPayrollCoDebtors[count]?.tiId,
            cpcdIdeNum:
                coDebtor?.format?.cpfcdIdeNum &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdIdeNum,
            cpcdFirNam:
                coDebtor?.format?.cpfcdFirNam &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdFirNam,
            cpcdFirLas:
                coDebtor?.format?.cpfcdFirLas &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdFirLas,
            cpcdDatBir:
                coDebtor?.format?.cpfcdDatBir &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdDatBir,
            cpcdPhoMob:
                coDebtor?.format?.cpfcdPhoMob &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdPhoMob,
            cpcdPhoPer:
                coDebtor?.format?.cpfcdPhoPer &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdPhoPer,
            cpcdEmail:
                coDebtor?.format?.cpfcdEmail &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdEmail,
            gId:
                coDebtor?.format?.cpfcdGender &&
                !dataUser?.creditPayrollCoDebtors[count]?.gId,
            cId:
                coDebtor?.format?.cpfcdLocation &&
                !dataUser?.creditPayrollCoDebtors[count]?.cId,
            taId:
                coDebtor?.format?.cpfcdLocation &&
                !dataUser?.creditPayrollCoDebtors[count]?.taId,
            cpcdNumDir:
                coDebtor?.format?.cpfcdLocation &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdNumDir,
            cpcdNum:
                coDebtor?.format?.cpfcdLocation &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdNum,
            cpcdNumHou:
                coDebtor?.format?.cpfcdLocation &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdNumHou,
            alId:
                coDebtor?.format?.cpfcdAcaLev &&
                !dataUser?.creditPayrollCoDebtors[count]?.alId,
            msId:
                coDebtor?.format?.cpfcdMarSta &&
                !dataUser?.creditPayrollCoDebtors[count]?.msId,
            thId:
                coDebtor?.format?.cpfcdTypHou &&
                !dataUser?.creditPayrollCoDebtors[count]?.thId,
            dId:
                coDebtor?.format?.cpfcdDependents &&
                !dataUser?.creditPayrollCoDebtors[count]?.dId,
            cpcdCompany:
                coDebtor?.format?.cpfcdCompany &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdCompany,
            cpcdPosition:
                coDebtor?.format?.cpfcdPosition &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdPosition,
            tcId:
                coDebtor?.format?.cpfcdTypCon &&
                !dataUser?.creditPayrollCoDebtors[count]?.tcId,
            cpcdDatSta:
                coDebtor?.format?.cpfcdTypCon &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdDatSta,
            cpcdSalary:
                coDebtor?.format?.cpfcdSalary &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdSalary,
            cpcdPerDoc:
                coDebtor?.format?.cpfcdPerDoc &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdPerDoc,
            cpcdFroDoc:
                coDebtor?.format?.cpfcdFroDoc &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdFroDoc,
            cpcdReaDoc:
                coDebtor?.format?.cpfcdReaDoc &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdReaDoc,
            cpcdSerDoc:
                coDebtor?.format?.cpfcdSerDoc &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdSerDoc,
            cpcdBanDoc:
                coDebtor?.format?.cpfcdBanDoc &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdBanDoc,
            cpcdLoaDoc:
                coDebtor?.format?.cpfcdLoaDoc &&
                !dataUser?.creditPayrollCoDebtors[count]?.cpcdLoaDoc,
        });
    };

    // Actualiza la información de un usuario
    useEffect(() => {
        searchImage(quantity);
    }, [dataUser]);
    // Actualiza las fotos tomadas
    useEffect(() => {
        if (documents?.name) {
            setValues({
                creditPayrollCoDebtors: {
                    ...values?.creditPayrollCoDebtors,
                    [documents.name]: `${new Date().getTime()}1.${
                        documents.ext
                    }`,
                },
            });
            setFiles({
                ...files,
                [documents.name]: documents.file,
                [documents.nameA]: documents.base64,
            });
            setErrorForm({ ...errorForm, [documents.name]: !documents.file });
        }
    }, [documents]);

    // Cambio en los valores
    const handleChange = e => {
        setValues({
            creditPayrollCoDebtors: {
                ...values.creditPayrollCoDebtors,
                [e.name]: e.value,
            },
        });
        setErrorForm({ ...errorForm, [e.name]: e.error });
    };

    // coloca la información de la cámara
    const handleInfo = type => {
        setInfoCamera({
            title: 'Documentos personales',
            icon:
                type === 1 ? (
                    <FontAwesome
                        name="vcard"
                        size={hp(20)}
                        color={theme.backgroundC}
                    />
                ) : type === 2 ? (
                    <MaterialCommunityIcons
                        name="card-bulleted"
                        size={hp(26)}
                        color={theme.backgroundC}
                    />
                ) : type === 3 ? (
                    <Entypo
                        name="v-card"
                        size={hp(24)}
                        color={theme.backgroundC}
                    />
                ) : type === 4 ? (
                    <Octicons
                        name="file"
                        size={hp(20)}
                        color={theme.backgroundC}
                    />
                ) : type === 5 ? (
                    <FontAwesome5
                        name="file-invoice"
                        size={hp(20)}
                        color={theme.backgroundC}
                    />
                ) : (
                    <MaterialCommunityIcons
                        name="file-cad-box"
                        size={hp(20)}
                        color={theme.backgroundC}
                    />
                ),
            steps: [
                { text: 'Sigues las instrucciones para capturar la foto.' },
                { text: '1. Capture dentro de los marcos señalados.' },
                { text: '2. La imagen debe ser visible y legible.' },
                ...[
                    type === 1
                        ? {
                            text: '3. Enfoque la parte frontal de su documento de identidad .',
                        }
                        : type === 2
                            ? {
                                text: '3. Enfoque la parte reversa de su documento de identidad.',
                            }
                            : type === 3
                                ? ({
                                    text: '3. Enfoque su rostro sosteniendo el documento de identidad a la misma altura de la cabeza.',
                                },
                                { text: '4. No utilizar accesorios.' })
                                : type === 4
                                    ? {
                                        text: '3. Tome la foto de un servicio público (Agua, luz o gas).',
                                    }
                                    : type === 5
                                        ? {
                                            text: '3. Tome la foto de un certificado bancario.',
                                        }
                                        : {
                                            text: '3. Tome la foto de un servicio público (Agua, luz o gas).',
                                        },
                ],
            ],
        });
    };

    const handleSubmit = async () => {
        //     /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) {
            return isLoggedVar({
                ...isLoggedVar(),
                message: 'Por favor rellene todo los campos',
            });
        }
        if (values?.creditPayrollCoDebtors?.cpcdSalary > 2147483648) {
            return isLoggedVar({
                ...isLoggedVar(),
                message: 'Salario fuera de rango',
            });
        }

        setLoading(true);
        files.cpcdPerDoc &&
            (await putFileS3(
                `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${values.creditPayrollCoDebtors?.cpcdPerDoc}`,
                files.cpcdPerDoc
            ));
        files.cpcdFroDoc &&
            (await putFileS3(
                `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${values.creditPayrollCoDebtors?.cpcdFroDoc}`,
                files.cpcdFroDoc
            ));
        files.cpcdReaDoc &&
            (await putFileS3(
                `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${values.creditPayrollCoDebtors?.cpcdReaDoc}`,
                files.cpcdReaDoc
            ));
        files.cpcdSerDoc &&
            (await putFileS3(
                `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${values.creditPayrollCoDebtors?.cpcdSerDoc}`,
                files.cpcdSerDoc
            ));
        files.cpcdBanDoc &&
            (await putFileS3(
                `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${values.creditPayrollCoDebtors?.cpcdBanDoc}`,
                files.cpcdBanDoc
            ));
        files.cpcdLoaDoc &&
            (await putFileS3(
                `companys/${dataUser.cpcCompanyId}/payroll/${dataUser.pcId}/codebtor/${values.creditPayrollCoDebtors?.cpcdLoaDoc}`,
                files.cpcdLoaDoc
            ));
        setLoading(false);

        const { data, error } = await crediPayrollCodebtorsMutation({
            variables: {
                input: {
                    ...values.creditPayrollCoDebtors,
                    cpcdIdeNum: `${values?.creditPayrollCoDebtors?.cpcdIdeNum}`,
                },
            },
            update(cache, { data: { setOrUpdateCreditPayrollCoDebtor } }) {
                updateCache({
                    cache,
                    query: ONE_CREDIT_PAYROLL_COMPANY,
                    nameFun: 'getCreditPayrollCompanyById',
                    dataNew: setOrUpdateCreditPayrollCoDebtor,
                });
            },
        }).catch(e => ({ error: e }));

        // verifica si hay error
        if (error) {
            return isLoggedVar({ ...isLoggedVar(), message: error.message });
        }

        /** resultado */
        if (data?.setOrUpdateCreditPayrollCoDebtor) {
            // Actualización de variables
            setQuantity(quantity + 1);
            setErrorForm({});
            setValues({});
            setFiles({});
            searchImage(quantity + 1);

            // verifica si se necesitan más codeudores
            if (quantity + 1 < coDebtor.quantity) {
                refScroll?.current &&
                    refScroll?.current.scrollTo({ x: 0, y: 0, animated: true });
                return isLoggedVar({
                    ...isLoggedVar(),
                    message: `Favor ingresar la información del ${
                        quantity + 2
                    }° codeudor.`,
                });
            }
            // termina enviá al proceso de el crédito
            handleNext();
        }
    };

    return (
        <CoDebtorDataRegisterV
            data={{
                files,
                values,
                loading: loading || loadUpd,
                infoCamera,
                banner,
                format: coDebtor?.format,
                fileBD,
                fileFD,
                fileLD,
                filePD,
                fileRD,
                fileSD,
                loadSD,
                loadRD,
                loadPD,
                loadLD,
                loadFD,
                loadBD,
            }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleInfo={handleInfo}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleButton={handleButton}
        />
    );
};

CoDebtorDataRegisterC.propTypes = {
    documents: PropTypes.object,
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    coDebtor: PropTypes.object,
    handleButton: PropTypes.func,
    refScroll: PropTypes.object,
};