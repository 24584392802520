import React, { useEffect, useRef, useState } from 'react'
import { Dimensions } from 'react-native'
import * as ImageManipulator from 'expo-image-manipulator'
import * as Permissions from 'expo-permissions'
import { Camera } from 'expo-camera'
import { CameraV } from '../components/CameraV'
import { ImageEditV } from '../components/ImageEditV'
import { SelectDocument } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'

export const ScreenCamera = ({ navigation, route }) => {
    const [permissionCamera, setPermissionCamera] = useState(false)
    const [displayCamera, setDisplayCamera] = useState(true)
    const [cameraReady, setCameraReady] = useState(false)
    const [photo, setPhoto] = useState({})
    const [photoManipulator, setPhotoManipulator] = useState({})
    const [loading, setLoading] = useState(false)
    const [screenXY, setScreenXY] = useState({})
    const [pageXY, setPageXY] = useState({})
    const [typeCamera, setTypeCamera] = useState(Camera.Constants.Type.back)
    const [flashCamera, setFlashCamera] = useState(Camera.Constants.FlashMode.off)
    const refCamera = useRef()

    /**
     * Enviá el documento a la ventana del usuario
     * @param {object} event base64 del documento
     * @param {string} nameFile Nombre del archivo
     * @return {void}
     */
    const onPicture = async (event, nameFile) => {
        const params = route?.params || {}
        navigation.navigate(params.screen, { ...params, event, ...(nameFile ? { nameFile } : {}) })
        setDisplayCamera(true)
    }

    /**
     * accede a la búsqueda de documentos
     * @return {void}
     */
    const handleLibrary = async () => {
        const res = await SelectDocument()
        if (res?.type === 'success') onPicture(res.uri, res.name)
    }

    /**
     * requiere los permisos de la cámara y verifica si se necesitan documentos
     * @returns {function} devuelve una llamada de búsqueda de los documentos
     */
    useEffect(() => {
        navigation?.addListener('focus', async () => {
            // Busca si hay permisos para la cámara
            if (!route?.params?.document) {
                Permissions.askAsync(Permissions.CAMERA).then(x => {
                    if (x.status !== 'granted') return isLoggedVar({ ...isLoggedVar(), message: 'Se necesitan permisos de la cámara para continuar' })
                    setDisplayCamera(true)
                    setPermissionCamera(true)
                    setScreenXY({ screenY: Dimensions.get('window').height, screenX: Dimensions.get('window').width })
                })
            }
        })
    }, [])

    // verifica si necesitan es documentos
    useEffect(() => {
        if (route?.params?.document) handleLibrary()
    }, [route])

    // verifica si se capturo un documento o foto
    useEffect(() => {
        if (photo.uri) {
            ImageManipulator.manipulateAsync(
                photo.uri,
                [{ crop : { originX: photo.posX, originY: photo.posY, width: photo.width, height: photo.height } }],
                { compress: 1, format: 'jpeg' },
            ).then(x => {
                setPhotoManipulator(x)
                setLoading(false)
                setDisplayCamera(false)
            }).catch(e => e)
        }
    }, [photo, setDisplayCamera, setLoading, setDisplayCamera])

    /**
     * Captura la Foto
     * @return {void}
     */
    const handlePicture = async () => {
        if (!cameraReady) return isLoggedVar({ ...isLoggedVar(), message: 'Espera que la cámara cargue para continuar' })
        const { screenY, screenX } = screenXY
        const { pageX, pageY } = pageXY
        if (refCamera.current) {
            setLoading(true)
            // refCamera.current.c
            const options = { quality: 1 }
            const e = await refCamera.current.takePictureAsync(options)
            /** Calculando el tamaño de la imagen en proporción a la pantalla */
            const porY = pageY / screenY
            const posY = e.height * porY
            const porX = pageX / screenX
            const posX = e.width * porX
            const rectangleWidth = e.width - (posX * 2)
            const rectangleHeight = e.height - (posY * 2)

            setPhoto({ ...e, width: rectangleWidth, height: rectangleHeight, posX, posY })
        }
    }

    if (displayCamera) {
        return (
            <CameraV
                data={{ typeCamera, flashCamera, refCamera, loading, permissionCamera }}
                handleFlash={() => setFlashCamera(flashCamera === Camera.Constants.FlashMode.off ? Camera.Constants.FlashMode.on : Camera.Constants.FlashMode.off)}
                handleRotate={() => setTypeCamera(typeCamera === Camera.Constants.Type.back ? Camera.Constants.Type.front : Camera.Constants.Type.back)}
                handlePicture={handlePicture}
                handleCameraReady={() => setCameraReady(true)}
                handleLibrary={handleLibrary}
                handleLayout={e => setPageXY({ pageX: e?.nativeEvent?.layout?.x, pageY: e?.nativeEvent?.layout?.y }) }
            />
        )
    } else {
        return (
            <ImageEditV
                photo={photoManipulator}
                onToggleModal={() => setDisplayCamera(true)}
                onPicture={onPicture}
            />
        )
    }
}