import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { filterKeyObject, writeCache, validationErrors } from '../../validation'
import { UPDATE_PAYROLL_COMPANY } from './queries'
import { OtherInformationV } from '../../components/OtherInformation'

export const OtherInformationC = ({ refetchPayroll, dataUser, banner, handleNext, format, refScroll, handleStepDocuments }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [payrollCompanyMutation, { loading }] = useMutation(UPDATE_PAYROLL_COMPANY)

    // Actualiza la información de un usuario

    // Cambio en los valores
    const handleChange = e => {
        setValues({ ...values, ...values.payrollOtherInformation, [e.name]: e.value })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    useEffect(() => {
        setValues({
            ...filterKeyObject(dataUser?.payrollOtherInformation, ['poiId', '__typename']),
            poiInfoBiddingActivities: dataUser?.payrollOtherInformation?.poiBiddingActivities === 1 ? dataUser?.payrollOtherInformation?.poiInfoBiddingActivities : null,
            poiInfoDangerHisLife: dataUser?.payrollOtherInformation?.poiDangerHisLife === 1 ? dataUser?.payrollOtherInformation?.poiInfoDangerHisLife : null,
            poiInfoTakesMedicine: dataUser?.payrollOtherInformation?.poiTakesMedicine === 1 ? dataUser?.payrollOtherInformation?.poiInfoTakesMedicine : null,
            poiInfoTakeDrug: dataUser?.payrollOtherInformation?.poiTakeDrug === 1 ? dataUser?.payrollOtherInformation?.poiInfoTakeDrug : null,
            poiUsNationality: dataUser?.payrollOtherInformation?.poiUsNationality === 1 ? dataUser?.payrollOtherInformation?.poiUsNationality : 2,
            poiUsAccount: dataUser?.payrollOtherInformation?.poiUsAccount === 1 ? dataUser?.payrollOtherInformation?.poiUsAccount : 2,
            poiUsInvestmentAssets: dataUser?.payrollOtherInformation?.poiUsInvestmentAssets === 1 ? dataUser?.payrollOtherInformation?.poiUsInvestmentAssets : 2,
            poiAmericanGreenCard: dataUser?.payrollOtherInformation?.poiAmericanGreenCard === 1 ? dataUser?.payrollOtherInformation?.poiAmericanGreenCard : 2,
            poiBornUS: dataUser?.payrollOtherInformation?.poiBornUS === 1 ? dataUser?.payrollOtherInformation?.poiBornUS : 2,
            poiLivedUS: dataUser?.payrollOtherInformation?.poiLivedUS === 1 ? dataUser?.payrollOtherInformation?.poiLivedUS : 2,
            poiForeignCurrency: dataUser?.payrollOtherInformation?.poiForeignCurrency === 1 ? dataUser?.payrollOtherInformation?.poiForeignCurrency : 2,
            poiTaxObligations: dataUser?.payrollOtherInformation?.poiTaxObligations === 1 ? dataUser?.payrollOtherInformation?.poiTaxObligations : 2,
            poiBiddingActivities: dataUser?.payrollOtherInformation?.poiBiddingActivities === 1 ? dataUser?.payrollOtherInformation?.poiBiddingActivities : 2,
            poiAcceptInformation: dataUser?.payrollOtherInformation?.poiAcceptInformation === 1 ? dataUser?.payrollOtherInformation?.poiAcceptInformation : 2,
            poiBreachContract: dataUser?.payrollOtherInformation?.poiBreachContract === 1 ? dataUser?.payrollOtherInformation?.poiBreachContract : 2,
            poiCriminallyDeclared: dataUser?.payrollOtherInformation?.poiCriminallyDeclared === 1 ? dataUser?.payrollOtherInformation?.poiCriminallyDeclared : 2,
            poiTruthfulStatements: dataUser?.payrollOtherInformation?.poiTruthfulStatements === 1 ? dataUser?.payrollOtherInformation?.poiTruthfulStatements : 2,
            poiAcceptDocument: dataUser?.payrollOtherInformation?.poiAcceptDocument === 1 ? dataUser?.payrollOtherInformation?.poiAcceptDocument : 2,
            poiDangerHisLife: dataUser?.payrollOtherInformation?.poiDangerHisLife === 1 ? dataUser?.payrollOtherInformation?.poiDangerHisLife : 2,
            poiTakesMedicine: dataUser?.payrollOtherInformation?.poiTakesMedicine === 1 ? dataUser?.payrollOtherInformation?.poiTakesMedicine : 2,
            poiTakeDrug: dataUser?.payrollOtherInformation?.poiTakeDrug === 1 ? dataUser?.payrollOtherInformation?.poiTakeDrug : 2,
        })
        setErrorForm({
        })
    }, [dataUser])

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await payrollCompanyMutation({
            variables: { input: { ...values, poiInsuredValue: Number(values?.poiInsuredValue || 0),
                poiPremiumValue: Number(values?.poiPremiumValue || 0) }, pcId: dataUser?.pcId }, update(store, { data: { updatePayrollOtherInformation } }) {
                writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: updatePayrollOtherInformation, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })
        /** resultado */
        if (data?.updatePayrollOtherInformation) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            refetchPayroll()
            handleNext(handleStepDocuments())
        }
    }
    return (
        <OtherInformationV
            data={{ banner, values, errors, loading, format }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleChangeResidence={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

OtherInformationC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    handleStepDocuments: PropTypes.number,
    format: PropTypes.object,
    refScroll: PropTypes.object,
    refetchPayroll: PropTypes.func,
}