import { gql } from '@apollo/client'

export const UPDATE_PAYROLL_COMPANY = gql`
mutation setOrUpdatePayrollCompany($pcId: ID, $input: ISetOrUpdatePayrollCompany!) {
    setOrUpdatePayrollCompany(pcId: $pcId, input: $input) {
        pcId
        payrollCoDebtors {
            pcdId
            tiId
            pcdIdeNum
            pcdFirNam
            pcdSecNam
            pcdFirLas
            pcdSecLas
            pcdDatBir
            pcdPhoMob
            pcdPhoPer
            pcdEmail
            gId
            cId
            taId
            pcdNumDir
            pcdNum
            pcdNumHou
            pcdInf
            alId
            msId
            thId
            dId
            pcdCompany
            pcdPosition
            tcId
            pcdDatSta
            pcdDatEnd
            pcdSalary
            pcdOthInc
            pcdFinExp
            pcdPerExp
            pcdPerDoc
            pcdFroDoc
            pcdReaDoc
            pcdSerDoc
            pcdBanDoc
            pcdLoaDoc
            pcdState
        }
    }
}
`