import React from 'react'
import { MaterialIcons } from '@expo/vector-icons'
import { HeadTitle } from '../HeadTitle'
import { wp } from '../../validation'
import theme from '../../../ignore/Constants'
import PropTypes from 'prop-types'
import { Container } from './styled'
import {
    FlatList,
    SafeAreaView,
    StatusBar,
    Text,
    TouchableOpacity,
} from 'react-native';
import { View } from 'react-native-web'

export const InboxV = ({ handleMessage, selectedMessage, creditReports }) => {

    const renderItem = ({ item }) => {
        const TextBold = item?.crSeen === 0 && 'bold';

        return (
            <TouchableOpacity onPress={() => handleMessage(item)}
                style={{
                    backgroundColor: item?.crSeen === 0 ? '#E8FAFF' : theme.notification,
                    padding: 20,
                    marginVertical: 8,
                    marginHorizontal: 16,
                    borderRadius: 5,
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 10px 0px'
                }}>
                <View style={{ display: 'block' }}>
                    <View style={{ display: 'flex', justifyContent: 'column' }}>
                        <Text style={{ color: 'black', fontSize: 15, fontWeight: TextBold }}>{item?.notification?.nTitle}</Text>
                        <Text style={{ color: 'black', fontSize: 15, fontWeight: TextBold }}>{item?.credit?.cConsecutive} - {item?.credit?.cName}</Text>
                    </View>
                    <Text style={{ display: 'flex', justifyContent: 'end' }}>
                        <MaterialIcons
                            style={{ display: 'flex', justifyContent: 'end' }}
                            name={item?.crSeen === 0 ? 'done' : 'done-all'}
                            size={wp(5)}
                            color={item?.crSeen === 0 ? theme.notificationSeen : theme.primaryC}
                        />
                        {item?.crSeen === 0 ?
                            <Text style={{
                                color: 'black',
                                fontSize: 14,
                                fontWeight: TextBold,
                                paddingLeft: 5
                            }}>
                                No leído
                            </Text>
                            :
                            <Text style={{
                                color: theme.primaryC,
                                fontSize: 14,
                                fontWeight: 'bold',
                                paddingLeft: 5
                            }}>
                                Visto
                            </Text>}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <Container>
            <HeadTitle color={theme.primaryC} title='MENSAJES' icon={<MaterialIcons name='message' size={wp(8)} color={theme.primaryC} />} />
            <SafeAreaView style={{
                flex: 1,
                marginTop: StatusBar.currentHeight || 0
            }}>
                <FlatList
                    showsVerticalScrollIndicator={false}
                    data={[...(creditReports || [])]?.sort((a, b) => a?.crSeen - b?.crSeen)}
                    renderItem={renderItem}
                    keyExtractor={item => item?.crId}
                    extraData={selectedMessage}
                />
            </SafeAreaView>
        </Container>
    )
}
InboxV.propTypes = {
    selectedMessage: PropTypes.any,
    handleMessage: PropTypes.func,
    creditReports: PropTypes.array
}