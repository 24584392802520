import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { filterKeyObject, updateCache, validationErrors } from '../../validation'
import { EDIT_CREDIT_PAYROLL_OTHER_INFORMATION } from './queries'
import { OtherInformationDataRegisterV } from '../../components/CreditEditData/OtherInformationDataRegister'

export const OtherInformationDataRegisterC = ({ dataUser, banner, handleNext, format, refScroll, refetchCreditPayroll, handleStepDocuments }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [creditPayrollOtherInformationMutation, { loading }] = useMutation(EDIT_CREDIT_PAYROLL_OTHER_INFORMATION)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({ creditPayrollOtherInformation: {
            ...filterKeyObject(dataUser?.creditPayrollOtherInformation, ['__typename']),
            cpoiInfoBiddingActivities: dataUser?.creditPayrollOtherInformation?.cpoiBiddingActivities === 1 ? dataUser?.creditPayrollOtherInformation?.cpoiInfoBiddingActivities : null,
            cpoiInfoDangerHisLife: dataUser?.creditPayrollOtherInformation?.cpoiDangerHisLife === 1 ? dataUser?.creditPayrollOtherInformation?.cpoiInfoDangerHisLife : null,
            cpoiInfoTakesMedicine: dataUser?.creditPayrollOtherInformation?.cpoiTakesMedicine === 1 ? dataUser?.creditPayrollOtherInformation?.cpoiInfoTakesMedicine : null,
            cpoiInfoTakeDrug: dataUser?.creditPayrollOtherInformation?.cpoiTakeDrug === 1 ? dataUser?.creditPayrollOtherInformation?.cpoiInfoTakeDrug : null,
        } })
        setErrorForm({})
    }, [dataUser])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ creditPayrollOtherInformation: { ...values.creditPayrollOtherInformation, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await creditPayrollOtherInformationMutation({
            variables: { input: { ...values.creditPayrollOtherInformation, cpoiInsuredValue: Number(values?.creditPayrollOtherInformation?.cpoiInsuredValue || 0),
                cpoiPremiumValue: Number(values?.creditPayrollOtherInformation?.cpoiPremiumValue || 0) }, cpoiId: dataUser?.creditPayrollOtherInformation?.cpoiId }, update(cache, { data: { updateCreditPayrollOtherInformation } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: updateCreditPayrollOtherInformation })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.updateCreditPayrollOtherInformation) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            refetchCreditPayroll()
            handleNext(handleStepDocuments())
        }
    }

    return (
        <OtherInformationDataRegisterV
            data={{ values, errors, format, loading, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

OtherInformationDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    refScroll: PropTypes.object,
    handleStepDocuments: PropTypes.number,
    refetchCreditPayroll: PropTypes.func,
}