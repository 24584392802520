import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery, useMutation } from '@apollo/client'
import { setData, tokenNotification, validationErrors } from '../../validation'
import { GET_BY_ID_COMPANIES, REGISTER_USER, SEND_EMAIL } from './queries'
import { RegisterUserV } from '../../components/Register'
import { isLoggedVar } from '../../apollo/cache'
import { Keyboard } from 'react-native'
import termConditions from '../../../ignore/TerCon/termConditions'

export const RegisterUserC = ({ navigation }) => {
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [showFooter, setShowFooter] = useState(true)
    const [selectDisabled, setSelectDisabled] = useState(false)
    const [errorForm, setErrorForm] = useState({ tiId: true, uIdeNum: true, psPhoMob: true, psEmail: true, uPass: true, conPass: true })
    const [token, setToken] = useState(undefined)
    const [registerUser, { loading }] = useMutation(REGISTER_USER)
    const [sendEmail] = useLazyQuery(SEND_EMAIL, { fetchPolicy: 'cache-and-network', });
    const [getCompaniesUser, { data: dataCompanies }] = useLazyQuery(GET_BY_ID_COMPANIES, { fetchPolicy: 'cache-and-network', });

    const getDataPayrollCompanies = async e => {
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
        await getCompaniesUser({
            variables: {
                pcIdeNum: e.value
            }
        })
    }
    useEffect(() => {
        const data = dataCompanies?.validatePayrollUser
        if (data) {
            setSelectDisabled(true)
            setErrorForm({ ...errorForm, psPhoMob: false, psEmail: false })
            setErrors({ ...errors, psPhoMob: false, psEmail: false })
            setValues({ ...values,
                tiId: data?.tiId,
                psPhoMob: data?.payrollStaff?.psPhoMob,
                psEmail:data?.payrollStaff?.psEmail,
            })
        } else {
            setSelectDisabled(false)
        }
    }, [dataCompanies])
    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => setShowFooter(false))
        const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => setShowFooter(true))
        // Solicita permiso de notificación
        const permissionNotification = async () => setToken(await tokenNotification())
        permissionNotification()

        return () => {
            keyboardDidShowListener.remove()
            keyboardDidHideListener.remove()
        }
    }, [])

    // Cambio de los valores
    const handleChange = e => {
        setValues({ ...values, [e.name]: e.value })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }
    // Submit
    const handleSignIn = async () => {
        setErrors(errorForm)
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })
        if (!values.terCon) return isLoggedVar({ ...isLoggedVar(), message: 'Es necesario aceptar los términos y condiciones' })

        const { data, error } = await registerUser({
            variables: {
                input: {
                    userInput: { tiId: values.tiId, uIdeNum: `${values.uIdeNum}`, uPass: values.uPass },
                    payrollStaffInput: { psPhoMob: values.psPhoMob, psEmail: values.psEmail },
                    token
                }
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })
        if (data) {
            // Envió de correo de términos y condiciones
            const TerCon = termConditions('TerCon')
            await sendEmail({
                variables: {
                    email: values.psEmail,
                    input: TerCon,
                }
            })
        }
        // respuesta
        const res = data?.registerUserPayroll
        if (res?.tokenAuth) {
            // reinicia los estados
            setValues({})
            setErrorForm({ tiId: true, uIdeNum: true, psPhoMob: true, psEmail: true, uPass: true, conPass: true })
            // guarda el token
            await setData('tokenAuth', res.tokenAuth)

            // inicia sesión
            isLoggedVar({ state: true, expired: false, message: 'Se ha registrado exitosamente.' })
            return navigation.navigate('Home')
        }
        if (res?.uId) {
            // lo manda activar la cuenta
            isLoggedVar({ state: false, expired: false, message: 'Se ha registrado pero es necesario activar su cuenta.' })
            if (dataCompanies?.validatePayrollUser){
                navigation.navigate('ConfirmEmail', { ...values, token })
            } else {
                return navigation.navigate('ConfirmEmail', { uId: res?.uId, psEmail: values.psEmail })
            }
        }
        if (dataCompanies?.validatePayrollUser){
            navigation.navigate('ConfirmEmail', { ...values, token })
        } else {
            isLoggedVar({ ...isLoggedVar(), message: 'Para continuar, necesitamos un par de datos más' })
            navigation.navigate('RegisterPayroll', { ...values, token })
        }
        // respuesta
    }

    return (
        <RegisterUserV
            data={{ errors, values, loading, navigation, showFooter, selectDisabled }}
            handleChange={handleChange}
            handleSignIn={handleSignIn}
            handleCheck={() => setValues({ ...values, terCon: !values.terCon })}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            getDataPayrollCompanies={getDataPayrollCompanies}
        />
    )
}

RegisterUserC.propTypes = {
    navigation: PropTypes.object
}