import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { isLoggedVar } from '../../apollo/cache'
import { ReferenceDataRegisterV } from '../../components/ReferenceDataRegister'
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries'
import { filterKeyObject, writeCache, validationErrors } from '../../validation'
import { UPDATE_PAYROLL_COMPANY } from './queries'

export const ReferenceDataRegisterC = ({ dataUser, banner, handleNext, format, refScroll, userParameters, handleStepDocuments }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [payrollCompanyMutation, { loading }] = useMutation(UPDATE_PAYROLL_COMPANY)

    // Actualiza la información de un usuario
    useEffect(() => {
        setValues({ payrollReference: filterKeyObject(dataUser?.payrollReference, ['__typename']) })
        setErrorForm({
            prNamFam: (format?.ufRefFam && !dataUser?.payrollReference?.prNamFam),
            prLasFam: (format?.ufRefFam && !dataUser?.payrollReference?.prLasFam),
            rIdFam: (format?.ufRefFam && !dataUser?.payrollReference?.rIdFam),
            prPhoFam: (format?.ufRefFam && !dataUser?.payrollReference?.prPhoFam),
            cIdFam: (format?.ufRefFam && !dataUser?.payrollReference?.cIdFam),
            taIdFam: (format?.ufRefFam && !dataUser?.payrollReference?.taIdFam),
            prNumDirFam: (format?.ufRefFam && !dataUser?.payrollReference?.prNumDirFam),
            prNumFam: (format?.ufRefFam && !dataUser?.payrollReference?.prNumFam),
            prNumHouFam: (format?.ufRefFam && !dataUser?.payrollReference?.prNumHouFam),
            prNamPar: (format?.ufRefPar && !dataUser?.payrollReference?.prNamPar),
            prLasPar: (format?.ufRefPar && !dataUser?.payrollReference?.prLasPar),
            prPhoPar: (format?.ufRefPar && !dataUser?.payrollReference?.prPhoPar),
            cIdPar: (format?.ufRefPar && !dataUser?.payrollReference?.cIdPar),
            taIdPar: (format?.ufRefPar && !dataUser?.payrollReference?.taIdPar),
            prNumDirPar: (format?.ufRefPar && !dataUser?.payrollReference?.prNumDirPar),
            prNumPar: (format?.ufRefPar && !dataUser?.payrollReference?.prNumPar),
            prNumHouPar: (format?.ufRefPar && !dataUser?.payrollReference?.prNumHouPar),
            tiIdStu: (format?.ufRefStu && !dataUser?.payrollReference?.tiIdStu),
            cIdStu: (format?.ufRefStu && !dataUser?.payrollReference?.cIdStu),
            taIdStu: (format?.ufRefStu && !dataUser?.payrollReference?.taIdStu),
            gIdStu: (format?.ufRefStu && !dataUser?.payrollReference?.gIdStu),
            prIdeNumStu: (format?.ufRefStu && !dataUser?.payrollReference?.prIdeNumStu),
            prNamStu: (format?.ufRefStu && !dataUser?.payrollReference?.prNamStu),
            prLasStu: (format?.ufRefStu && !dataUser?.payrollReference?.prLasStu),
            prPhoStu: (format?.ufRefStu && !dataUser?.payrollReference?.prPhoStu),
            prEmailStu: (format?.ufRefStu && !dataUser?.payrollReference?.prEmailStu),
            prFacultyStu: (format?.ufRefStu && !dataUser?.payrollReference?.prFacultyStu),
            prNumDirStu: (format?.ufRefStu && !dataUser?.payrollReference?.prNumDirStu),
            prNumStu: (format?.ufRefStu && !dataUser?.payrollReference?.prNumStu),
            prNumHouStu: (format?.ufRefStu && !dataUser?.payrollReference?.prNumHouStu),
            prInfStu: (format?.ufRefStu && !dataUser?.payrollReference?.prInfStu),
        })
    }, [dataUser])

    // Cambio en los valores
    const handleChange = e => {
        setValues({ payrollReference: { ...values.payrollReference, [e.name]: e.value } })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data, error } = await payrollCompanyMutation({
            variables: { input: values }, update(store, { data: { setOrUpdatePayrollCompany } }) {
                writeCache({ store, query: USE_ONE_PAYROLL_COMPANY, name: 'getPayrollCompanyById', dataNew: setOrUpdatePayrollCompany, variables: { state: [0, 1, 2, 3] }, typeName: 'PayrollCompany' })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

        /** resultado */
        if (data?.setOrUpdatePayrollCompany) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(userParameters?.lifeInsurance === 1 && format?.ufLifeInsurance === 1 || format?.ufOtherInformation === 1 ? 4.5 : handleStepDocuments())
        }
    }

    return (
        <ReferenceDataRegisterV
            data={{ values, errors, loading, format, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleChangeResidence={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

ReferenceDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    refScroll: PropTypes.object,
    handleStepDocuments: PropTypes.number,
    userParameters: PropTypes.array
}