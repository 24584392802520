import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { TypeIdentities } from '../../components/Selects/TypeIdentities'
import { Title, BorderBottom } from './styled'
import { wp } from '../../validation'
import { View } from 'react-native'

export const InfoCodeV = ({ data: { values, errors, loading, method, getCode }, handleBlur, handleChange, handleCode, handleSubmit }) => (
    <>
        {getCode ? <>
            <Title size={wp(5)}>No has recibido el código?</Title>
            <Title onPress={() => handleCode(true)} family='PFontRegular' margin={`0 0 ${wp(4)}px 0`}>Reenviar código</Title>
            <BorderBottom/>
        </> : <>
            <Title size={wp(5)}>Recuperar Contraseña</Title>
            <Title family='PFontRegular' margin={`0 0 ${wp(4)}px 0`}>Ingresa la información requerida.</Title>
            <BorderBottom/>
        </>}
        <View style={{ width: '100%' }}>
            <TypeIdentities value={values?.tiId} error={errors?.tiId} handleSelect={handleChange} />
        </View>
        <InputHook title='Número de Identificación *' keyboardType='numeric' error={errors?.pcIdeNum} value={values?.pcIdeNum} name='pcIdeNum' required numeric format range={[5, 30]} onBlur={handleBlur} onChangeText={handleChange} />
        {method === 1 ?
            <InputHook title='Correo Electrónico' name='psEmail' error={errors?.psEmail} value={values?.psEmail} email required range={[2, 100]} onBlur={handleBlur} onChangeText={handleChange}/>
            :
            <InputHook title='Número de Teléfono' name='psPhoMob' error={errors?.psPhoMob} value={values?.psPhoMob} keyboardType='numeric' numeric required range={[2, 30]} onBlur={handleBlur} onChangeText={handleChange}/>
        }
        {!!getCode && <InputHook title='Código' name='code' error={errors?.code} value={values?.code} required range={[1, 10]} onBlur={handleBlur} onChangeText={handleChange}/>}
        <ButtonSubmit styleButton={{ marginTop: '25px' }} onPress={() => getCode ? handleSubmit() : handleCode()} title={getCode ? 'Confirmar Código' : 'Solicitar Código'} loading={loading} />
    </>
)

InfoCodeV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleCode: PropTypes.func,
    handleSubmit: PropTypes.func
}