import React from 'react'
import PropTypes from 'prop-types'
import { AntDesign } from '@expo/vector-icons'
import { useTheme } from 'styled-components/native'

// Components, Utils
import { TypeIdentities } from '../Selects/TypeIdentities'
import { PregnancyTimes } from '../Selects/PregnancyTimes'
import { Container, Form, Row, BoxButton } from './styled'
import { BannerInfoData } from '../BannerInfoData'
import { ButtonSubmit } from '../ButtonSubmit'
import { InputDateWeb } from '../InputDateWeb'
import { SelectHook } from '../SelectHook'
import { InputHook } from '../InputHook'
import { mq } from '../../validation'

export const InformationAdditionalCreditLifeInsuranceV = ({
    data: {
        format,
        payroll,
        valuesLife,
        valuesStaff
    },
    errors: {
        errorFormLife,
        errorFormStaff
    },
    loadings: {
        loadMutation,
    },
    handleChange,
    handleSubmit
}) => {
    const theme = useTheme()
    return (
        <Container>
            <BannerInfoData
                title='Seguro de Vida'
                icon={<AntDesign name='idcard' size={mq(20)} color={theme.BGColor} />}
                titleModal='Información de Seguro de Vida'
                iconModal={<AntDesign name='idcard' size={mq(100)} color={theme.BGColor} />}
                steps={[
                    { text: 'En esta sección debes  ingresar la siguiente información del seguro.' },
                    { text: '1. Peso.' },
                    { text: '2. Altura.' },
                    { text: '3. MAdre Cabeza de Hogar.' },
                    { text: '4. Hijos.' },
                    { text: '5. Cancer.' },
                    { text: '6. Sida.' },
                    { text: '7. Accidente Cerebrovascular.' },
                    { text: '8. Insuficiencia Renal.' },
                    { text: '9. Hipertensión Arterial.' },
                    { text: '10. Diabetes.' },
                    { text: '11. Enfermedades de las Arterias Coronarias.' },
                    { text: '12. Infarto.' },
                    { text: '13. Tiene alguna limitación física o mental congénita o adquirida.' },
                    { text: '14. Le han diagnosticado o tratado alguna enfermedad diferente a las citadas arriba.' },
                    { text: '15. Fecha de diagnóstico.' },
                    { text: '16. Tratamiento.' },
                    { text: '17. Estado actual.' },
                    { text: '18. Está Embarazada.' },
                    { text: '19. Es Primigestante.' },
                    { text: '20. Tiempo de Gestación.' },
                ]}
            />
            <Form>
                <TypeIdentities
                    disabled
                    value={payroll?.tiId}
                />
                <InputHook
                    editable={false}
                    value={payroll?.cpcIdeNum}
                    title='Número de identificación'
                />
                <Row>
                    {format?.cpfWeight &&
                        <InputHook
                            required
                            title='Peso *'
                            range={[2, 30]}
                            name='cpsWeight'
                            value={valuesStaff?.cpsWeight}
                            error={errorFormStaff?.cpsWeight}
                            flex={format?.cpfHeight ? .47 : 1}
                            onChangeText={e => handleChange(e, 'staff')}
                        />
                    }
                    {format?.cpfHeight &&
                        <InputHook
                            required
                            range={[2, 30]}
                            name='cpsHeight'
                            title='Estatura *'
                            value={valuesStaff?.cpsHeight}
                            error={errorFormStaff?.cpsHeight}
                            flex={format?.cpfWeight ? .47 : 1}
                            onChangeText={e => handleChange(e, 'staff')}
                        />
                    }
                </Row>
                {format?.cpfMotHeaHou &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpsMotHeaHou'
                        title='Madre Cabeza de Hogar *'
                        titleModal='Seleccionar Opción'
                        value={valuesStaff.cpsMotHeaHou}
                        error={errorFormStaff.cpsMotHeaHou}
                        handleSelect={e => handleChange(e, 'staff')}
                        data={[{ cpsMotHeaHou: 1, title: 'SI' }, { cpsMotHeaHou: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfSons &&
                    <SelectHook
                        required
                        name='cpsSons'
                        nameAtt='title'
                        title='Hijos *'
                        value={valuesStaff.cpsSons}
                        error={errorFormStaff.cpsSons}
                        titleModal='Seleccionar Opción'
                        handleSelect={e => handleChange(e, 'staff')}
                        data={[{ cpsSons: 1, title: 'SI' }, { cpsSons: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfCancer &&
                    <SelectHook
                        required
                        nameAtt='title'
                        title='Cancer *'
                        name='cpliCancer'
                        value={valuesLife.cpliCancer}
                        titleModal='Seleccionar Opción'
                        error={errorFormLife.cpliCancer}
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliCancer: 1, title: 'SI' }, { cpliCancer: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfVIH &&
                    <SelectHook
                        required
                        title='Sida *'
                        name='cpliVIH'
                        nameAtt='title'
                        value={valuesLife.cpliVIH}
                        error={errorFormLife.cpliVIH}
                        titleModal='Seleccionar Opción'
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliVIH: 1, title: 'SI' }, { cpliVIH: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfStroke &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpliStroke'
                        value={valuesLife.cpliStroke}
                        titleModal='Seleccionar Opción'
                        error={errorFormLife.cpliStroke}
                        title='Accidente Cerebrovascular *'
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliStroke: 1, title: 'SI' }, { cpliStroke: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfRenIns &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpliRenIns'
                        title='Insuficiencia Renal *'
                        value={valuesLife.cpliRenIns}
                        titleModal='Seleccionar Opción'
                        error={errorFormLife.cpliRenIns}
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliRenIns: 1, title: 'SI' }, { cpliRenIns: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfArtHyp &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpliArtHyp'
                        value={valuesLife.cpliArtHyp}
                        title='Hipertensión Arterial *'
                        titleModal='Seleccionar Opción'
                        error={errorFormLife.cpliArtHyp}
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliArtHyp: 1, title: 'SI' }, { cpliArtHyp: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfDiabetes &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpliDiabetes'
                        title='Diabetes *'
                        titleModal='Seleccionar Opción'
                        value={valuesLife.cpliDiabetes}
                        error={errorFormLife.cpliDiabetes}
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliDiabetes: 1, title: 'SI' }, { cpliDiabetes: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfCorArtDis &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpliCorArtDis'
                        titleModal='Seleccionar Opción'
                        value={valuesLife.cpliCorArtDis}
                        error={errorFormLife.cpliCorArtDis}
                        handleSelect={e => handleChange(e, 'life')}
                        title='Enfermedades de las Arterias Coronarias *'
                        data={[{ cpliCorArtDis: 1, title: 'SI' }, { cpliCorArtDis: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfHeaAtt &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpliHeaAtt'
                        title='Infarto *'
                        value={valuesLife.cpliHeaAtt}
                        titleModal='Seleccionar Opción'
                        error={errorFormLife.cpliHeaAtt}
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliHeaAtt: 1, title: 'SI' }, { cpliHeaAtt: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfHasConAcqPhyMenLim &&
                    <SelectHook
                        required
                        nameAtt='title'
                        name='cpliHasConAcqPhyMenLim'
                        titleModal='Seleccionar Opción'
                        value={valuesLife.cpliHasConAcqPhyMenLim}
                        handleSelect={e => handleChange(e, 'life')}
                        error={errorFormLife.cpliHasConAcqPhyMenLim}
                        title='Tiene alguna limitación física o mental congénita o adquirida *'
                        data={[{ cpliHasConAcqPhyMenLim: 1, title: 'SI' }, { cpliHasConAcqPhyMenLim: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfDiaTreDisOthThoMenAbo &&
                    <SelectHook
                        required
                        nameAtt='title'
                        titleModal='Seleccionar Opción'
                        name='cpliDiaTreDisOthThoMenAbo'
                        value={valuesLife.cpliDiaTreDisOthThoMenAbo}
                        error={errorFormLife.cpliDiaTreDisOthThoMenAbo}
                        handleSelect={e => handleChange(e, 'life')}
                        title='Le han diagnosticado o tratado alguna enfermedad diferente a las citadas arriba *'
                        data={[{ cpliDiaTreDisOthThoMenAbo: 1, title: 'SI' }, { cpliDiaTreDisOthThoMenAbo: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfDatDia &&
                    <InputDateWeb
                        name='cpliDatDia'
                        title='Fecha de diagnóstico'
                        error={errorFormLife?.cpliDatDia}
                        onChangeText={e => handleChange(e, 'life')}
                        value={valuesLife?.cpliDatDia}
                    />
                }
                {format?.cpfTreatment &&
                    <InputHook
                        letters
                        range={[3, 100]}
                        title='Tratamiento'
                        name='cpliTreatment'
                        returnKeyType='next'
                        value={valuesLife?.cpliTreatment}
                        error={errorFormLife?.cpliTreatment}
                        onChangeText={e => handleChange(e, 'life')}
                    />
                }
                {format?.cpfActSta &&
                    <InputHook
                        letters
                        required
                        range={[3, 100]}
                        name='cpliActSta'
                        returnKeyType='next'
                        title='Estado actual *'
                        value={valuesLife?.cpliActSta}
                        error={errorFormLife?.cpliActSta}
                        onChangeText={e => handleChange(e, 'life')}
                    />
                }
                {format?.cpfIsPre &&
                    <SelectHook
                        nameAtt='title'
                        name='cpliIsPre'
                        title='Está Embarazada'
                        value={valuesLife.cpliIsPre}
                        error={errorFormLife.cpliIsPre}
                        titleModal='Seleccionar Opción'
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliIsPre: 1, title: 'SI' }, { cpliIsPre: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfPrimitive &&
                    <SelectHook
                        nameAtt='title'
                        name='cpliPrimitive'
                        title='Es Primigestante'
                        value={valuesLife.cpliPrimitive}
                        error={errorFormLife.cpliPrimitive}
                        titleModal='Seleccionar Opción'
                        handleSelect={e => handleChange(e, 'life')}
                        data={[{ cpliPrimitive: 1, title: 'SI' }, { cpliPrimitive: 2, title: 'NO' }]}
                    />
                }
                {format?.cpfPregnancy &&
                    <PregnancyTimes
                        value={valuesLife.ptId}
                        error={errorFormLife.ptId}
                        handleSelect={e => handleChange(e, 'life')}
                    />
                }
                <BoxButton>
                    <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loadMutation} />
                </BoxButton>
            </Form>
        </Container>
    )
}

InformationAdditionalCreditLifeInsuranceV.propTypes = {
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    data: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    loadings: PropTypes.object.isRequired,
}