import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { TypeAddress } from '../Selects/TypeAddress'
import { Cities } from '../Selects/Cities'
import { ButtonSubmit } from '../ButtonSubmit'
import { Container, Form, Row, BoxButton } from './styled'

export const LocationDataRegisterV = ({ data: { banner, errors, values, loading }, handleBlur, handleChange, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            <Cities error={errors?.cId} value={values?.creditPayrollResidence?.cId} handleSelect={handleChange} />
            <TypeAddress error={errors?.taId} value={values?.creditPayrollResidence?.taId} handleSelect={handleChange} />
            <Row>
                <InputHook flex={.40} title='No. Dirección *' returnKeyType='next' error={errors?.cprNumDir} value={values?.creditPayrollResidence?.cprNumDir} name='cprNumDir' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                <InputHook flex={.25} title='No. *' returnKeyType='next' error={errors?.cprNum} value={values?.creditPayrollResidence?.cprNum} name='cprNum' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
                <InputHook flex={.25} title='No. Casa *' returnKeyType='next' error={errors?.cprNumHou} value={values?.creditPayrollResidence?.cprNumHou} name='cprNumHou' required range={[1, 30]} onBlur={handleBlur} onChangeText={handleChange} />
            </Row>
            <InputHook title='Información Residencial (Opcional)' returnKeyType='next' error={errors?.cprInformation} value={values?.creditPayrollResidence?.cprInformation} name='cprInformation' range={[3, 100]} onBlur={handleBlur} onChangeText={handleChange} />
            <InputHook testID='06dcabd3-2f20-4f2e-b41e-f6bad7f4dfq5cb6' title='Estrato *' returnKeyType='next' error={errors?.creditPayrollResidence?.cprStratum} value={values?.creditPayrollResidence?.cprStratum} name='cprStratum' required range={[1, 10]} onBlur={handleBlur} onChangeText={handleChange} />
            <BoxButton>
                <ButtonSubmit onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

LocationDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
}