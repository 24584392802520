/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { brandUrls } from '../../lib/vars';

export const PayZenFormLyra = ({ creditParams, payroll, banner }) => {
    const credenciales = JSON.parse(localStorage.getItem('lyra'));
    const data = {
        ...creditParams,
        pcId: payroll?.pcId,
        pcIdeNum: payroll?.pcIdeNum,
        hashCirenio: credenciales?.hash
    }
    const infoCredit = encodeURIComponent(JSON.stringify(data));
    const brans = brandUrls();

    return (
        <div
            style={{
                backgroundColor: '#FFFFFF',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Helmet>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

                <script
                    type="text/javascript"
                    src="https://static.payzen.lat/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js"
                    kr-public-key={credenciales?.krPublicKey}
                    kr-post-url-success={`${brans?.bk}webhook/lyra?operation=${infoCredit}`}>
                </script>

                <link
                    rel="stylesheet"
                    href="https://static.payzen.lat/static/js/krypton-client/V4.0/ext/neon-reset.min.css"
                />
                <script
                    src="https://static.payzen.lat/static/js/krypton-client/V4.0/ext/neon.js">
                </script>
            </Helmet>
            {banner}
            <div
                style={{ paddingTop: '50px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignSelf: 'center' }}
                className="kr-smart-form"
                kr-form-token={credenciales?.formToken}
            >
                <div className="kr-pan"></div>
                <div className="kr-expiry"></div>
                <div className="kr-security-code"></div>
                <button className="kr-payment-button"></button>
                <div className="kr-form-error"></div>
            </div>
        </div>
    );
};

PayZenFormLyra.propTypes = {
    route: PropTypes.object,
    creditParams: PropTypes.object,
    payroll: PropTypes.object,
    banner: PropTypes.node,
}