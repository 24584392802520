import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { View, ScrollView } from 'react-native'
import { mq } from '../../validation'
import { SelectS } from '../../skeleton/SelectS'
import Modal from 'react-native-modal'
import theme from '../../../ignore/Constants'
import { Title, ButtonSelect, SubTitle, TextError, ContainerModal, ContainerTitle, Option, ContainerCheck, Check, ButtonCancel, ButtonText, LeftBorder } from './styled'

export const SelectHook = ({ data, loading, name, nameAtt, value, title, titleModal, breakdown, handleSelect, disabled, error, testID }) => {
    const [isVisible, setVisible] = useState(false)
    const [isData, setData] = useState()
    const [message, setMessage] = useState(false)

    const confirm = item => {
        setData(item)
        setVisible(false)
        if (item) {
            setMessage(false)
            return handleSelect({ name, value: item[name], item, error: false })
        } else setMessage('Campo requerido.')
    }

    const cancel = () => {
        setVisible(false)
        if (value) setMessage()
        else {
            // setMessage('Campo requerido.')
            return handleSelect({ name, value, error: true })
        }
    }

    return (
        <View style={{ position: 'relative', marginBottom: mq(8) }}>
            {title && <Title numberOfLines={1}>{title}</Title>}
            <ButtonSelect testID={testID} disabled={disabled} onPress={() => setVisible(true)} borderColor={(message || error) ? theme.errorC : theme.onVariantSurfC} value={value || loading}>
                <SubTitle color={value ? theme.onSurfaceV : theme.onVariantSurfC}>{loading ? 'Cargando...' : ((data || []).find(x => x[name] === value))?.[nameAtt] || ''}</SubTitle>
            </ButtonSelect>

            <LeftBorder color={(message || error) ? theme.errorC : theme.successC} />
            {(message || error) && <View style={{ width: '100%' }}><TextError>{message || 'Campo Requerido.'}</TextError></View>}
            {isVisible && <Modal isVisible={isVisible} animationOutTiming={1000} style={{ margin: 0, padding: 0, justifyContent: 'flex-end', width: mq('100%'), marginHorizontal: 'auto' }} onBackdropPress={() => setVisible(false)}>
                <ContainerModal>
                    <ContainerTitle>
                        <SubTitle fFamily='PFontMedium' size={mq(15)} color={theme.onSurfaceC}>{titleModal}</SubTitle>
                    </ContainerTitle>
                    <ScrollView>
                        {loading ?
                            <SelectS />
                            :
                            data && data.map((x, i) => {
                                let item = x
                                if (breakdown) item = x[breakdown]
                                return (
                                    <Option key={i} onPress={() => confirm(item)} testID={item[name]}>
                                        <SubTitle size={mq(13)} color={theme.onSurfaceC}>{item[nameAtt]}</SubTitle>
                                        <ContainerCheck borderColor={isData && isData[name] === item[name] ? theme.primaryC : theme.onVariantSurfC}>
                                            <Check bgColor={isData && isData[name] === item[name] ? theme.primaryC : 'transparent'} />
                                        </ContainerCheck>
                                    </Option>
                                )
                            })}
                    </ScrollView>
                    <ButtonCancel onPress={cancel}><ButtonText>Cancelar</ButtonText></ButtonCancel>
                </ContainerModal>
            </Modal>}
        </View>
    )
}

SelectHook.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    nameAtt: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    title: PropTypes.string,
    titleModal: PropTypes.string,
    breakdown: PropTypes.string,
    handleSelect: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    testID: PropTypes.string
}